import { gql } from '@apollo/client';

export const CONNECT_SIGN_IN_APP = gql`
  mutation connectSignInApp($ownerType: String!, $ownerId: ID!) {
    connectSignInApp(ownerType: $ownerType, ownerId: $ownerId) {
      id
      ownerType
      ownerId
      connectionOptions
      configurationOptions
      connected
      enabled
      newApiKey
    }
  }
`;

export const UPDATE_SIGN_IN_APP = gql`
  mutation updateSignInAppConnection(
    $id: ID!
    $connectionOptions: JSON
    $configurationOptions: JSON
    $enabled: Boolean
  ) {
    updateSignInAppConnection(
      id: $id
      connectionOptions: $connectionOptions
      configurationOptions: $configurationOptions
      enabled: $enabled
    ) {
      id
      ownerType
      ownerId
      configurationOptions
      connected
      enabled
    }
  }
`;

export const DISCONNECT_SIGN_IN_APP = gql`
  mutation disconnectSignInApp($ownerType: String!, $ownerId: ID!) {
    disconnectSignInApp(ownerType: $ownerType, ownerId: $ownerId)
  }
`;

export const QUEUE_SIA_IMPORT_NOW = gql`
  mutation queueSiaImportNow($organisationId: ID!, $discoveryMode: Boolean!) {
    queueSiaImportNow(
      organisationId: $organisationId
      discoveryMode: $discoveryMode
    )
  }
`;

export const QUEUE_ADD_ALL_PROFILES_TO_SIA = gql`
  mutation queueAddAllProfilesToSignInApp(
    $organisationId: ID!
    $groupId: String!
    $tags: [String!]
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
  ) {
    queueAddAllProfilesToSignInApp(
      organisationId: $organisationId
      groupId: $groupId
      tags: $tags
      profileTypes: $profileTypes
      ratings: $ratings
    )
  }
`;

export const QUEUE_ADD_PROFILE_TO_SIA = gql`
  mutation queueAddProfileToSignInApp(
    $organisationId: ID!
    $profileId: ID!
    $groupId: String!
  ) {
    queueAddProfileToSignInApp(
      organisationId: $organisationId
      groupId: $groupId
      profileId: $profileId
    )
  }
`;
