import { Callout } from '@blueprintjs/core';
import React from 'react';

export interface ValidationError {
  fieldPath: string;
  message: string;
  errorLevel: number;
}

interface ValidationErrorsProps {
  errors?: ValidationError[];
}

export const ValidationErrors = ({ errors }: ValidationErrorsProps) => {
  return (
    <>
      {errors?.map((error, index) => {
        return (
          <Callout
            key={index}
            intent="danger"
          >{`${error.fieldPath}: ${error.message}`}</Callout>
        );
      })}
    </>
  );
};
