import React, { useMemo } from 'react';

import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeletePopover, Select } from '@hogwarts/ui-components-core';

import { SourceField, TargetField, TargetGroup } from './types';

interface FieldMapItemProps {
  onSourceChange: (id: string) => void;
  onTargetChange: (id: string) => void;
  onTransformChange: (transform: string | null) => void;
  onReadOnlyChange: (readOnly: boolean) => void;
  onDelete: () => void;
  sourceFields: SourceField[];
  sourceField: string;
  targetFields: TargetField[];
  targetField: string;
  readOnly: boolean;
  targetGroups: TargetGroup[];
  targetGroupKey: string;
  transform: unknown;
}
export const FieldMapItem = ({
  onSourceChange,
  onTargetChange,
  onTransformChange,
  onReadOnlyChange,
  onDelete,
  sourceFields,
  sourceField,
  readOnly,
  targetField,
  targetFields,
  targetGroups,
  targetGroupKey,
  transform,
}: FieldMapItemProps) => {
  const displayedValue = useMemo(() => {
    if (sourceField && !sourceFields?.find((f) => f.id === sourceField)) {
      return `${sourceField} (Not Found on Last Import)`;
    }
    return null;
  }, [sourceFields, sourceField]);

  return (
    <div className={'d-flex flex-col justify-content-between'}>
      <Select
        fill
        valueField={'id'}
        textField={'label'}
        items={sourceFields}
        filterable
        onChange={(item) => onSourceChange(item.id)}
        value={sourceField}
        displayedValue={displayedValue!}
      />

      <div className={'pl-3 pr-3'}>
        <Popover
          content={
            <Menu>
              <MenuItem text={'Transform Steps'} disabled />
              <Menu.Divider />
              <MenuItem
                icon="arrow-right"
                text={'No Transform'}
                onClick={() => onTransformChange(null)}
              />
              <Menu.Divider />
              <MenuItem
                icon="random"
                text={'Value Exists'}
                onClick={() => onTransformChange('valueExists')}
              />
            </Menu>
          }
        >
          <FontAwesomeIcon
            icon={transform ? 'random' : 'long-arrow-right'}
            size={'2x'}
          />
        </Popover>
      </div>

      <Select
        fill
        valueField={'id'}
        textField={'label'}
        items={targetFields}
        filterable
        // @ts-ignore
        groups={targetGroups}
        groupKey={targetGroupKey}
        onChange={(item) => onTargetChange(item.id)}
        value={targetField}
      />

      <Button
        minimal
        icon={readOnly ? 'lock' : 'unlock'}
        intent={readOnly ? Intent.PRIMARY : Intent.NONE}
        onClick={() => onReadOnlyChange(!readOnly)}
      />

      <DeletePopover
        onRemove={() => onDelete()}
        buttonComponent={<Button minimal icon="trash" intent={Intent.DANGER} />}
      />
    </div>
  );
};
