import { avatars as defaultAvatars } from './default';
import { avatars as plainAvatars } from './plain';

export const allAvatars: Record<string, Record<string, any>> = {
  default: defaultAvatars,
  plain: plainAvatars,
};

export const getAvatar = (iconPath: string, iconPack: string) => {
  let avatarPack = allAvatars[iconPack];
  if (!avatarPack) {
    avatarPack = defaultAvatars;
  }
  const avatar = avatarPack[iconPath];
  if (!avatar) return avatarPack['1'];
  return avatar;
};
