import React, { useContext, useState, useEffect, useMemo } from 'react';
import { AnalyticsContext, OrganisationContext } from '@/context';
import ProseFilter from '../../../components/ProseFilter';
import { Tag } from '@hogwarts/ui-components-core';
import { useQueryParams, ArrayParam, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';

const ProfilesFilterBar = ({ onFilter }) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const { profileTypes, ratingSystems, tags } = organisation.scheme;

  const queryParams = useMemo(() => {
    const queryParams = {
      profileTypes: withDefault(
        ArrayParam,
        profileTypes.map((pt) => pt.key)
      ),
      tags: withDefault(ArrayParam, ['active', 'onboarding']),
    };
    ratingSystems.forEach((rs) => {
      queryParams[`ratingSystem_${rs.key}`] = ArrayParam;
    });
    return queryParams;
  }, [profileTypes, ratingSystems]);
  const [filterState, setQuery] = useQueryParams(queryParams);
  const ratingSystemSelections = ratingSystems.reduce((prev, curr) => {
    const key = `ratingSystem_${curr.key}`;
    const selections = {
      ...prev,
      [key]: [
        filterState[key]
          ? filterState[key][0] === 'true'
            ? true
            : false
          : false,
      ],
    };
    return selections;
  }, {});
  const [selectedFilters, setSelectedFilters] = useState({
    profileTypes: filterState.profileTypes,
    tags: filterState.tags,
    ...ratingSystemSelections,
  });
  const [activeFilters, setActiveFilters] = useState(
    Object.keys(filterState).filter((filterKey) => {
      return !!filterState[filterKey];
    })
  );
  useEffect(
    () => {
      const currentFilters = {};
      const filtersForQuery = {};
      for (const filterKey of Object.keys(selectedFilters)) {
        if (activeFilters.includes(filterKey)) {
          currentFilters[filterKey] = selectedFilters[filterKey];
          filtersForQuery[filterKey] = selectedFilters[filterKey];
        } else {
          filtersForQuery[filterKey] = undefined;
        }
      }
      onFilter(currentFilters);
      setQuery(filtersForQuery, 'replace');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilters, selectedFilters]
  );

  const terms = { prefix: t('Showing') };
  const filters = [
    {
      id: 'profileTypes',
      name: t('Profile Types'),
      available: profileTypes.map((pt) => ({
        id: pt.key,
        label: pt.label,
      })),
      selected: selectedFilters.profileTypes,
      selectOptions: {
        min: 1,
        max: null,
        removable: false,
        withApply: true,
      },
      terms: {
        allSelected: t('all profile types'),
        joining: t('of type'),
        prefix: null,
        suffix: null,
      },
    },
    {
      id: 'tags',
      name: t('Tags'),
      component: Tag,
      available: tags.map((t) => ({
        id: t.key,
        label: t.label,
        color: t.color,
      })),
      selected: selectedFilters.tags,
      selectOptions: {
        min: 1,
        max: null,
        removable: false,
        withApply: true,
      },
      terms: {
        allSelected: null,
        joining: t('with'),
        prefix: null,
        suffix: t('tags'),
      },
    },
    ...ratingSystems.map((rs, idx) => ({
      id: `ratingSystem_${rs.key}`,
      name: rs.label,
      available: [
        {
          id: false,
          label: `${t('Not')} ${rs.label}`,
        },
        {
          id: true,
          label: rs.label,
        },
      ],
      selected: selectedFilters[`ratingSystem_${rs.key}`],
      selectOptions: {
        min: 1,
        max: 1,
        removable: true,
      },
      terms: {
        allSelected: null,
        joining: idx === 0 ? t('that are') : t('and'),
        prefix: null,
        suffix: null,
      },
    })),
  ];

  return (
    <ProseFilter
      terms={terms}
      filters={filters}
      activeFilters={activeFilters}
      onSelectionChange={(type, selected) => {
        const newFilters = {
          ...selectedFilters,
          [type]: selected,
        };
        analytics.events.profiles.filtersChanged({
          newFilters,
          activeFilters,
        });
        setSelectedFilters(newFilters);
      }}
      onAddFilter={(filterId) => {
        analytics.events.profiles.filtersAdded({ filterId });
        setActiveFilters([...activeFilters, filterId]);
      }}
      onRemoveFilter={(filterId) => {
        analytics.events.profiles.filtersRemoved({ filterId });
        const newFilters = activeFilters.filter((f) => f !== filterId);
        setActiveFilters(newFilters);
      }}
    />
  );
};

export default ProfilesFilterBar;
