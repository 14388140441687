import React, { useEffect, useMemo, useState } from 'react';

import { Drawer } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import CheckOrderInfo from '../../components/UserProfile/Checks/CheckOrderInfo';
import { CheckOrder } from '../../components/UserProfile/Checks/types';
import { useFeature } from '../../hooks';
import { getRequest } from '../../services';

interface CheckOrderInfoProps {
  checkOrder?: CheckOrder;
  isOpen: boolean;
  onClose: () => void;
}

const CheckOrderInfoContainer = ({
  checkOrder,
  isOpen,
  onClose,
}: CheckOrderInfoProps) => {
  const [dbsTable, setDbsTable] = useState('');
  const [dbsError, setDbsError] = useState(false);
  const [dbsLoading, setDbsLoading] = useState(false);

  const [dbsApplicationNumber, setDbsApplicationNumber] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!checkOrder?.checks?.length) {
      return;
    }
    const dbsCheck = checkOrder.checks.find(
      (check) => !!check.extraInfo?.CheckCriminalDisclosureApplicationNumber
    );
    if (!dbsCheck) {
      return;
    }
    if (
      dbsCheck.extraInfo?.CheckCriminalDisclosureApplicationNumber &&
      checkOrder.candidate?.PersonalInfo?.DateOfBirth
    ) {
      const applicationNumber =
        dbsCheck.extraInfo.CheckCriminalDisclosureApplicationNumber;
      setDbsApplicationNumber(applicationNumber);
      const [dobYear, dobMonth, dobDay] =
        checkOrder.candidate.PersonalInfo.DateOfBirth.split('-');

      getRequest({ requireAuth: true })
        .then(async (request) => {
          if (!dbsTable) {
            setDbsLoading(true);
          }
          const result = await request.get<string>(
            `/checks/dbsstatus?an=${applicationNumber}&d=${dobDay}&m=${dobMonth}&y=${dobYear}`
          );

          setDbsLoading(false);
          setDbsTable(result.data);
        })
        .catch(() => {
          setDbsLoading(false);
          setDbsError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkOrder]);

  if (!checkOrder) return null;

  return (
    <Drawer
      enforceFocus={false}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={t('Check Order Info')}
      size="700px"
      isOpen={isOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <CheckOrderInfo
        checkOrder={checkOrder}
        dbsApplicationNumber={dbsApplicationNumber}
        dbsTableContent={dbsTable}
        dbsTableError={dbsError}
        dbsTableLoading={dbsLoading}
      />
    </Drawer>
  );
};

const useCheckOrderInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkOrder, setCheckOrder] = useState<CheckOrder>();
  const dbsInfoFeature = useFeature('checks.view_check_detail');

  const showCheckOrderInfo = (selectedOrder: CheckOrder) => {
    setCheckOrder(selectedOrder);
    setIsOpen(true);
  };

  const props = useMemo<CheckOrderInfoProps>(() => {
    return {
      checkOrder,
      isOpen,
      onClose: () => {
        setIsOpen(false);
        setCheckOrder(undefined);
      },
    };
  }, [checkOrder, isOpen]);

  if (!dbsInfoFeature) {
    return [
      () => null,
      { checkOrder, isOpen, onClose: () => {} },
      null,
    ] as const;
  }

  return [CheckOrderInfoContainer, props, showCheckOrderInfo] as const;
};

export default useCheckOrderInfo;
