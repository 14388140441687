import { gql } from '@apollo/client';

export const DATA_PROVIDER = gql`
  fragment ProviderParts on DataProvider {
    cacheKey
    key
    connected
    status
    messages {
      label
      value
    }
    connection
    fieldMap {
      source
      target
      readonly
      transform
    }
    sourceFields {
      key
      dataType
    }
    exclusions {
      providerId
      profileId
      name
    }
    locationsType
    locations
    locationMap {
      value
      organisation
    }
    profileTypeMap {
      profileTypeKey
      condition
    }
  }
`;
