import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { FormBuilder } from '@hogwarts/ui-components-forms';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const userPreferenceFields = ({
  organisations,
  timezones,
  languages,
}: {
  organisations: any[];
  timezones: any[];
  languages: any[];
}) => [
  {
    key: 'defaultDashboardId',
    type: 'textbox',
    label: 'Default Dashboard',
    readOnly: true,
    visible: false,
  },
  {
    key: 'firstname',
    type: 'textbox',
    label: 'First Name',
    validate: 'required',
    maxLength: 50,
  },
  {
    key: 'lastname',
    type: 'textbox',
    label: 'Last Name',
    validate: 'required',
    maxLength: 50,
  },
  {
    key: 'phone',
    type: 'textbox',
    label: 'Phone Number',
    maxLength: 20,
  },
  {
    key: 'defaultOrganisationKey',
    type: 'singleselect',
    label: 'Default Organisation',
    values: organisations,
    validate: 'required',
  },
  {
    key: 'defaultLanguageKey',
    type: 'singleselect',
    label: 'Interface Language',
    values: languages,
    validate: 'required',
  },
  {
    key: 'timezone',
    type: 'singleselect',
    label: 'Time Zone (for notifications)',
    values: timezones,
    validate: 'required',
  },
];

interface Preferences {
  defaultDashboardId?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  defaultOrganisationKey?: string;
  defaultLanguageKey?: string;
  timezone?: string;
}

interface UserPreferencesProps {
  firstname?: string;
  lastname?: string;
  languages: unknown[];
  organisations: unknown[];
  timezones: unknown[];
  preferences: Preferences;
  onSavePreferences: (values: any) => void;
}
export const UserPreferences = ({
  firstname,
  lastname,
  languages,
  organisations,
  timezones,
  preferences,
  onSavePreferences,
}: UserPreferencesProps) => {
  const { t } = useTranslation();
  const [values, setCurrentValues] = useState<Preferences>({
    firstname,
    lastname,
    ...preferences,
  });

  const actions = [
    {
      text: t('Save'),
      icon: 'save',
      disabled: false,
      intent: 'primary',
      onClick: () => onSavePreferences(values),
    },
  ];

  return (
    <SettingsPanel title={'User Preferences'} actions={actions}>
      <div style={{ padding: '10px' }} className="w-50">
        <FormBuilder
          initialValues={{
            firstname,
            lastname,
            ...preferences,
          }}
          fields={userPreferenceFields({
            organisations,
            timezones,
            languages,
          })}
          onValuesChanged={setCurrentValues}
        />
      </div>
    </SettingsPanel>
  );
};
