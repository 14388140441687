import Login from '@hogwarts/ui-components-login';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import authenticationService from '../../services/authentication';

const LoginCallbackContainer = () => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');

    authenticationService.handleAuthorisationCode(code, state).finally(() => {
      history.replace('/');
    });
  }, [history]);

  return <Login state={'LOGGING-IN'} />;
};

export default LoginCallbackContainer;
