import { ActionWithChildren, useDebounce } from '@hogwarts/ui-components-core';
import { AnalyticsContext, OrganisationContext } from '../../../../context';
import { ApiParams, Column, ColumnApi } from '@hogwarts/ui-components-grid';
import ConditionalPanel, {
  ConditionalPanelOnChangeContext,
} from './conditionalPanel';
import {
  DELETE_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_OPTIONS,
  UPDATE_REPORT_OPTIONS_Type,
} from '../../../../mutations';
import { GridView, PageHeader } from '../../../../components';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  StoredReport,
  getAvailableColumns,
  getColumnLabel,
} from '@hogwarts/scheme-profiles';
import {
  addConcatField,
  addProfileTypeField,
  addRatingField,
  addSchemeField,
  addTagField,
  addTemplateField,
  gridOptions as defaultGridOptions,
  useDataSourceFactory,
} from '../../../profilesGridUtils';
import { sortBy, uniq } from 'lodash';
import {
  useBuildReport,
  useIsFirstMount,
  useMutation,
  usePermission,
} from '../../../../hooks';

import { ConditionalScheme } from '../../../../components/Conditionals';
import { GET_REPORTS } from '../../../../queries';
import { GridOptions } from '@ag-grid-community/core';
import ProfilesFilterBar from './filterBar';
import { getExportTypeProps } from '../../utils';
import log from '@scrtracker/logging';
import permissions from '@hogwarts/permissions';
import styles from './styles.module.css';
import { useEditReportForm } from './useEditReportForm';
import { useHistory } from 'react-router-dom';
import { veryCleanObject } from '@hogwarts/utils';

const gridOptions: GridOptions = {
  ...defaultGridOptions,
  noRowsOverlayComponent: 'profileListNoRowsOverlay',
  rowSelection: 'single',
  // sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 150,
    filter: true,
    floatingFilter: true,
    editable: false,
    suppressMenu: false,
  },

  sideBar: {
    ...(defaultGridOptions?.sideBar as {}),
    toolPanels: [
      {
        id: 'conditional',
        labelDefault: 'Conditions',
        labelKey: 'conditional', //?
        iconKey: 'filter',
        toolPanel: 'conditionalPanel',
      },
    ],
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
  processCellForClipboard: ({ column, value }) => {
    const colDef = column.getColDef();
    if (colDef.field === 'typeKey') {
      return value?.key;
    }
    return value;
  },
};

interface InsightOptionsColumn {
  key: string;
}
interface InsightOptions {
  columns: InsightOptionsColumn[];

  // profileTypes: unknown[];

  // excel: any;
  // pdf: any;

  // // filter?
  // type: string;
  // values: any[];
  // condition?: any;

  // tags: unknown[];
  // ratings: unknown[];  // profileTypes: filters.profileTypes ?? [],
  // tags: filters.tags ?? [],
  // ratings: ratingFilter,
}

const getReportOptions = (
  currentFilter: InsightOptions,
  columnApi: ColumnApi
) => {
  //https://www.ag-grid.com/javascript-grid/column-state/
  let columnState = columnApi?.getColumnState();
  if (!columnState) return;

  const sort = sortBy(
    columnState.filter((c) => c.sort),
    'sortIndex'
  ).map((c) => ({
    field: c.colId,
    desc: c.sort !== 'asc',
  }));

  const allColumns = uniq([
    ...columnState.map((c) => c.colId),
    ...currentFilter.columns.map((c) => c.key),
  ]);

  const columns = allColumns.map((columnId) => {
    const existing = currentFilter.columns.find(
      (column) => column.key === columnId
    );

    const state = columnState.find((state) => state.colId === columnId);

    if (!state) {
      return existing;
    }

    return veryCleanObject({
      ...existing,
      key: state.colId,
      width: state.width,
      visible: state.hide !== true,
      pinned: state.pinned,
    });
  });

  return {
    ...currentFilter,
    sort,
    columns,
  };
};

const useAutoSave = ({
  enabled,
  onSave,
}: {
  enabled: boolean;
  onSave: () => void;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const markChanged = useDebounce(async () => {
    if (!enabled) return;
    console.log('Debounce: Saving Report');
    log.info('Saving Report', {});
    setIsSaving(true);
    try {
      await onSave();
    } finally {
      setIsSaving(false);
    }
  }, SAVE_DEBOUNCE_TIME);

  return {
    isSaving,
    markChanged,
  };
};

const SAVE_DEBOUNCE_TIME = 950;

const frameworkComponents = {
  conditionalPanel: ConditionalPanel,
};

const convertFormatToGridCellStyle = (
  format: any,
  wrap: boolean
): Record<string, string | number> | null => {
  const result: Record<string, string | number> = {};
  if (format?.fill) result.backgroundColor = format.fill;
  if (wrap) result['whiteSpace'] = 'pre-wrap';
  if (!Object.keys(result).length) return null;
  return result;
};

const ProfilesGrid = ({ insight }: { insight: StoredReport }) => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);

  const history = useHistory();

  const isFirstMount = useIsFirstMount();

  const hasPermission = usePermission(
    permissions.REPORT_UPDATE,
    insight.ownerId
  );

  const canEdit = !!(hasPermission && insight.options?.preventEditing !== true);

  const canExport = usePermission(permissions.PROFILE_PRINT, organisation.id);

  const [currentFilter, setCurrentFilter] = useState<any>(insight.options);

  const [{ api: gridApi, columnApi }, setGridApi] = useState<
    Partial<ApiParams>
  >({});
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(canEdit);

  const [updateReport] = useMutation(UPDATE_REPORT, {
    selector: 'updateReport',
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [saveReportState] = useMutation<UPDATE_REPORT_OPTIONS_Type>(
    UPDATE_REPORT_OPTIONS,
    {
      variables: {
        reportId: insight?.id,
        reportOptions: {},
      },
    }
  );

  const [groups, columns] = useMemo(
    () => {
      // Get a list of all the available columns using sources
      // and apply the report state
      const [groups] = getAvailableColumns(
        insight.options,
        organisation.scheme
      );

      // turn the columns into grid columns.
      let gridGroups = [];
      let gridColumns = [];

      for (const group of groups) {
        let gridGroup: any = {
          ...group,
          columns: [],
        };
        gridGroups.push(gridGroup);
        for (const column of group.columns!) {
          let gridColumn: Column;

          switch (column.type) {
            case 'tag': {
              // @ts-ignore
              gridColumn = addTagField();
              break;
            }
            case 'profileType': {
              // @ts-ignore
              gridColumn = addProfileTypeField(organisation.scheme);
              break;
            }
            case 'rating': {
              // @ts-ignore
              gridColumn = addRatingField(column.systemKey);
              break;
            }
            case 'template': {
              // @ts-ignore
              gridColumn = addTemplateField(column.references, column.template);
              break;
            }
            case 'concat': {
              // @ts-ignore
              gridColumn = addConcatField(
                column.fields || [],
                column.join || '\n'
              );
              break;
            }
            case 'field':
            default: {
              // @ts-ignore
              gridColumn = addSchemeField(
                column.key,
                organisation.scheme,
                column.dataType
              );
              break;
            }
          }

          gridColumn = {
            autoHeight: column.wrap ? true : undefined,
            wrapText: column.wrap ? true : undefined,
            cellStyle: column.wrap ? { whiteSpace: 'pre-wrap' } : undefined,
            ...gridColumn,
            key: column.key!,
            type: column.dataType,
            group: gridGroup.key,
            label: column.meta
              ? getColumnLabel(column.label, column.meta)
              : column.label!,
            width: typeof column.width === 'number' ? column.width : undefined,
            sort: column.sort,
            sortIndex: column.sortIndex,
            meta: {
              ...column.meta,
              locked: column.locked,
            },
            editable: false,
            visible: column.visible !== false,
            pinned: column.pinned,
          };

          if (column.formatSelectors) {
            gridColumn.cellStyle = ({ data: profile, value }) => {
              if (column.getCellStyle) {
                const format = column.getCellStyle(profile, value);
                const result = convertFormatToGridCellStyle(
                  format,
                  !!column.wrap
                );
                return result;
              }
            };
          }

          gridGroup.columns.push(gridColumn);
          gridColumns.push(gridColumn);
        }
      }

      return [gridGroups, gridColumns];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisation.scheme]
  );

  const dataSource = useDataSourceFactory({
    variables: {
      includeShared: insight.options.includeShared,
      dynamicCondition: insight.options.dynamicCondition,
      includeExpiringFields: insight.options.includeExpiringFields,
    },
  });

  const [editReportForm] = useEditReportForm({
    onDelete: async () => {
      await deleteReport({
        variables: {
          reportId: insight?.id,
        },
      });
      history.push(`/${organisation.key}/insights`);
    },
    onUpdate: async (values: any) => {
      const options: InsightOptions = {
        ...insight.options,
        ...values.options,
        excel: {
          ...insight.options?.excel,
          ...values.options?.excel,
        },
        pdf: {
          ...insight.options?.pdf,
          ...values.options?.pdf,
        },
      };
      await updateReport({
        variables: {
          ...values,
          options,
          reportId: insight.id,
        },
      });
      setCurrentFilter({
        ...currentFilter,
        ...options,
      });
    },
  });

  const { isSaving, markChanged } = useAutoSave({
    enabled: canEdit && autoSaveEnabled,
    onSave: () => {
      if (isFirstMount) return;
      return saveReport();
    },
  });

  useEffect(
    () => {
      if (autoSaveEnabled) {
        markChanged('AutoSave Enabled');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autoSaveEnabled]
  );

  useEffect(
    () => {
      if (isFirstMount) return;
      markChanged('Filter/Conditional Changed');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilter]
  );

  // Monitor for column changes
  useEffect(() => {
    const handler = (event: any) => markChanged(`Grid Event: ${event.type}`);

    const events = ['columnResized', 'sortChanged', 'displayedColumnsChanged'];

    for (const event of events) {
      gridApi && gridApi.addEventListener(event, handler);
    }

    // gridColumnsChanged
    // columnEverythingChanged
    return () => {
      for (const event of events) {
        gridApi && gridApi.removeEventListener(event, handler);
      }
    };
  }, [gridApi, markChanged]);

  const contextMenuItems = useCallback(
    (nodes) => {
      return [
        nodes.length === 1 && {
          name: nodes[0].data.name,
          disabled: true,
        },
        nodes.length > 1 && {
          name: { message: 'Selected {{count}} rows', count: nodes.length },
          disabled: true,
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'separator',
        nodes.length === 1 && {
          name: 'View Profile',
          action: async () => {
            let profileId = nodes[0].data.id;
            if (profileId) {
              analytics.events.profilesGrid.viewProfileClicked();
              history.push(`/${organisation.key}/profiles/${profileId}`);
            }
          },
        },
      ];
    },
    [analytics.events.profilesGrid, history, organisation.key]
  );

  const [
    exportReport,
    {
      component: BuildingReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
  ] = useBuildReport();

  const saveReport = useCallback(() => {
    // analytics.track(
    //   analytics.trackingKeys.profiles.ADVANCED_EDITOR_CLICKED
    // );

    return saveReportState({
      variables: {
        reportOptions: getReportOptions(currentFilter, columnApi!),
      },
    });
  }, [columnApi, currentFilter, saveReportState]);

  const actions = useMemo<ActionWithChildren[]>(() => {
    const actions: ActionWithChildren[] = [];
    if (canEdit) {
      actions.push({
        text: 'Edit',
        icon: 'pencil',
        allowed: canEdit,
        intent: 'none',
        onClick: () => {
          analytics.events.insights.editDetail();
          return editReportForm({
            initialValues: {
              ...insight,
              options: {
                ...insight.options,
                excel: {
                  includeHeaders: true,
                  includeSummary: true,
                  ...insight.options.excel,
                },
              },
            },
          });
        },
      });
    }
    actions.push({
      text: `Autosave ${autoSaveEnabled ? 'ON' : 'OFF'}`,
      icon: isSaving ? 'spinner' : 'save',
      spin: isSaving,
      disabled: isSaving,
      allowed: canEdit,
      intent: autoSaveEnabled ? 'success' : 'danger',
      onClick: () => {
        const newState = !autoSaveEnabled;
        analytics.events.insights.toggleAutoSave({
          enabled: newState,
        });
        setAutoSaveEnabled(newState);
      },
    });

    const exportTypes = Array.isArray(insight.exportType)
      ? insight.exportType
      : insight.exportType !== null
      ? [insight.exportType]
      : [];

    for (const exportType of exportTypes) {
      actions.push({
        // Intent, Icon + Text
        ...getExportTypeProps(exportType, canExport),
        allowed: !reportDisabled,
        onClick: () => {
          analytics.events.insights.exportViewClicked({
            insightId: insight.id,
            exportType,
          });
          return exportReport(
            insight.id,
            exportType,
            getReportOptions(currentFilter, columnApi!)
          );
        },
      });
    }

    return actions;
  }, [
    analytics.events.insights,
    autoSaveEnabled,
    canEdit,
    canExport,
    columnApi,
    currentFilter,
    editReportForm,
    exportReport,
    insight,
    isSaving,
    reportDisabled,
  ]);

  const onFilterHandler = (filters: any) => {
    const ratingFilter = [];
    for (const filterKey of Object.keys(filters)) {
      if (filterKey.startsWith('ratingSystem_')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: filters[filterKey][0],
        });
      }
    }
    setCurrentFilter({
      ...currentFilter,
      profileTypes: filters.profileTypes ?? [],
      tags: filters.tags ?? [],
      ratings: ratingFilter,
    });
  };

  const conditionalPanelProps = {
    condition: currentFilter?.condition,
    onChange: (condition: any) => {
      setCurrentFilter({
        ...currentFilter,
        condition,
      });
    },
  };

  return (
    <>
      <PageHeader noWrapper actions={actions} />

      <BuildingReportComponent {...reportProps} />
      <>
        <div className={styles.filterBar}>
          <ProfilesFilterBar
            initialFilters={insight.options}
            onFilter={onFilterHandler}
          />
        </div>

        <ConditionalPanelOnChangeContext.Provider value={conditionalPanelProps}>
          <ConditionalScheme.Provider value={organisation.scheme}>
            <GridView
              allowEdit={false}
              dataSource={dataSource}
              // @ts-ignore
              contextMenuItems={contextMenuItems}
              onGridReady={setGridApi}
              groups={groups}
              columns={columns}
              filter={currentFilter}
              frameworkComponents={frameworkComponents}
              {...gridOptions}
            />
          </ConditionalScheme.Provider>
        </ConditionalPanelOnChangeContext.Provider>
      </>
    </>
  );
};

export default ProfilesGrid;
