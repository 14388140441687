import { Button, Card } from '@blueprintjs/core';
import { Select } from '@hogwarts/ui-components-core';
import { relatives } from '@hogwarts/ui-components-forms/src/components/ControlBuilder/components/relativedate';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

interface Relative {
  group?: boolean;
  key?: string;
  label?: string;
}

interface DateComparisonOperator {
  key: string;
  label: string;
}

const dateComparisonOperators = [
  {
    key: 'exactlyOrLaterThan',
    label: 'Expires exactly or later than',
  },
  {
    key: 'exactlyOrEarlierThan',
    label: 'Expires exactly or earlier than',
  },
];

const futureDatesRelatives: Relative[] = relatives.filter((relative) => {
  const futureKeywords: string[] = ['next', 'tomorrow', 'in', 'this', 'later']; // Add more future date keywords if needed
  const lowerCaseKey: string = relative.key?.toLowerCase() ?? '';
  const lowerCaseLabel: string = relative.label?.toLowerCase() ?? '';

  // Check if the relative key or label contains any future date keywords
  return futureKeywords.some(
    (keyword) =>
      lowerCaseKey.includes(keyword) || lowerCaseLabel.includes(keyword)
  );
});

interface Group {
  key: string;
  label: string;
  condition: (arg: Relative) => boolean;
}

interface AddReportGroupProps {
  groups?: Group[];
  loading: boolean;
  onAddInsightGroup: (
    relativeDate: Relative | null,
    dateComparisonOperator: DateComparisonOperator | null
  ) => void;
}

const AddReportGroup = ({
  groups,
  loading,
  onAddInsightGroup,
}: AddReportGroupProps) => {
  const { t } = useTranslation();
  const [selectedRelativeDate, setSelectedRelativeDate] =
    useState<Relative | null>(null);
  const [selectedDateComparisonOperator, setSelectedDateComparisonOperator] =
    useState<any>(null);

  const handleAddInsightGroup = () => {
    onAddInsightGroup(selectedRelativeDate, selectedDateComparisonOperator);
    setSelectedRelativeDate(null);
    setSelectedDateComparisonOperator(null);
  };

  return (
    <div>
      <Card className={styles.cardConditionGroup}>
        <h6>{t('Create a Group')}</h6>

        <Select
          fill
          className={`${styles.select} m-1`}
          items={dateComparisonOperators}
          valueField="key"
          textField="label"
          value={selectedDateComparisonOperator?.key}
          onChange={(item) => setSelectedDateComparisonOperator(item)}
        />
        <Select
          fill
          className={`${styles.select} m-1`}
          // Only show relative dates that are not already added to the report
          items={futureDatesRelatives.filter((relative) => {
            const relativeKey = `${selectedDateComparisonOperator?.key}-${relative.key}`;
            return !groups?.find((group) => group.key === relativeKey);
          })}
          valueField="key"
          textField="label"
          value={selectedRelativeDate?.key}
          onChange={(item) => setSelectedRelativeDate(item)}
        />
        <Button
          disabled={!selectedRelativeDate || !selectedDateComparisonOperator}
          intent="success"
          icon="add"
          onClick={handleAddInsightGroup}
          loading={loading}
        >
          {t('Add Group')}
        </Button>
      </Card>
    </div>
  );
};

export default AddReportGroup;
