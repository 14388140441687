import { gql } from '@apollo/client';

export const UPDATE_ORGANISATION_ATTRIBUTES = gql`
  mutation updateOrganisationAttributes($organisationId: ID!, $values: JSON!) {
    updateOrganisationAttributes(id: $organisationId, values: $values) {
      id
      attributes
    }
  }
`;

export const UPDATE_RISKASSESSMENT_SIGNOFF = gql`
  mutation updateRiskAssessmentSignoff(
    $organisationId: ID!
    $values: RiskAssessmentSignoff!
  ) {
    updateRiskAssessmentSignoff(id: $organisationId, values: $values)
  }
`;
