// import log from '@scrtracker/logging';
import permissionsList, { types as ptypes } from '@hogwarts/permissions';
import { useContext, useMemo } from 'react';
import { UserContext } from '../context';
import { hasPermissionFactory } from '../utils';

export const usePermission = (
  permission: ptypes.Permission | ptypes.Permission[],
  ownerId?: string
): boolean => {
  const user = useContext(UserContext);

  if (ownerId && typeof ownerId !== 'string') {
    throw new Error('Invalid OwnerId. Must be a String');
  }

  return useMemo(() => {
    if (!user) {
      return false;
    }

    let permissions = Array.isArray(permission) ? permission : [permission];

    if (ownerId === user.id) {
      return true;
    }

    const hasPermission = hasPermissionFactory(user.permissions);

    permissions = permissions.filter(Boolean).map((p) => {
      if (typeof p === 'string') {
        return permissionsList[p];
      }
      return p;
    });

    if (permissions.length === 0) {
      return true;
    }

    for (const permission of permissions) {
      const allowed = hasPermission(permission, ownerId);
      if (!allowed) {
        return false;
      }
    }
    return true;
  }, [ownerId, permission, user]);
};
