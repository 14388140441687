import { gql } from '@apollo/client';

export const ADD_SIGN_OFF = gql`
  mutation addSignOff(
    $notes: String
    $ownerType: String!
    $ownerId: ID!
    $documents: [SignOffDocumentInput]
  ) {
    addSignOff(
      notes: $notes
      ownerType: $ownerType
      ownerId: $ownerId
      documents: $documents
    ) {
      id
      signedOffBy {
        id
        name
      }
      signedOffAt
      notes
      ratings {
        systemKey
        count
        valid
        score
        ready
      }
      documents {
        id
        filename
        type
        tags
        size
        filestackHandle
      }
      owner {
        type
        id
      }
    }
  }
`;
