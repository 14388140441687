import React, { useState } from 'react';
import cn from 'classnames';
import { Menu, MenuItem, Icon, Button, Classes } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const SelectionMenu = ({
  available,
  selected,
  options,
  onSelectionChange,
  onRemove,
}) => {
  const { t } = useTranslation();
  const [selectionState, setSelectionState] = useState(selected);
  const [isModified, setModified] = useState(false);

  const getNewSelection = (selectedItemId) => {
    let newSelected = [...selectionState];
    const itemIndex = selectionState.indexOf(selectedItemId);
    if (itemIndex === -1) {
      if (!options.max || newSelected.length !== options.max) {
        newSelected.push(selectedItemId);
      } else if (
        options.max &&
        options.min &&
        newSelected.length === options.max &&
        newSelected.length === options.min
      ) {
        newSelected.splice(0, 1);
        newSelected.push(selectedItemId);
      }
    } else {
      if (!options.min || newSelected.length !== options.min) {
        newSelected.splice(itemIndex, 1);
      }
    }
    return newSelected;
  };

  return (
    <div className={styles.selectionMenu}>
      <Menu>
        {available.map((item) => (
          <MenuItem
            shouldDismissPopover={!options.withApply}
            key={item.id}
            text={item.label}
            label={
              selectionState.includes(item.id) ? <Icon icon="tick" /> : null
            }
            onClick={() => {
              if (options.withApply) {
                setSelectionState(getNewSelection(item.id));
                setModified(true);
              } else {
                onSelectionChange(getNewSelection(item.id));
              }
            }}
          />
        ))}
      </Menu>
      <div className={cn(styles.menuButtons, Classes.POPOVER_DISMISS)}>
        {options.withApply && isModified && (
          <Button
            fill
            className={styles.selectionRemoveButton}
            intent="primary"
            minimal
            onClick={() => {
              onSelectionChange(selectionState);
              setModified(false);
            }}
          >
            {t('Apply')}
          </Button>
        )}
        {options.removable && onRemove && (
          <Button
            fill
            className={styles.selectionRemoveButton}
            intent="danger"
            minimal
            onClick={() => onRemove()}
          >
            {t('Remove Filter')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectionMenu;
