import permissions from '@hogwarts/permissions';
import { getAvailableColumns } from '@hogwarts/scheme-profiles';
import { useForms } from '@hogwarts/ui-components-forms';
import { useContext } from 'react';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '../../../../../../context';
import { useMutation, usePermission } from '../../../../../../hooks';
import { CREATE_REPORT } from '../../../../../../mutations';
import { GET_REPORTS } from '../../../../../../queries';

const defaultReportOptions = {
  columnSources: [
    {
      type: 'scheme',
      includeTags: true,
      includeRatings: true,
    },
  ],
  sort: [
    {
      field: 'firstname',
      desc: false,
    },
  ],
  tags: ['active', 'onboarding'],
  columns: [],
};

const AddReportForm = ({ onCreate }) => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);
  const [createReport] = useMutation(CREATE_REPORT, {
    selector: 'createReport',
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const canCreateOrganisationReports = usePermission(
    permissions.REPORT_CREATE,
    organisation?.id
  );
  const canCreateFederationReports = usePermission(
    permissions.REPORT_CREATE,
    federation?.id
  );
  const getOwnerOptions = () => {
    const options = [
      { id: 'USER', value: 'Only Me' },
      {
        id: 'ORGANISATION',
        value: organisation.name,
        disabled: !canCreateOrganisationReports,
      },
    ];
    if (federation) {
      options.push({
        id: 'FEDERATION',
        value: federation.name,
        disabled: !canCreateFederationReports,
      });
    }
    return options;
  };
  useForms({
    key: 'addReport',
    title: 'Add Report',
    saveText: 'Create',
    savingText: 'Creating',
    savedText: 'Created',
    allowCancel: false,
    initialValues: {
      ownerType: 'USER',
      options: {
        includeShared: false,
        excel: {
          singleTab: false,
          notes: true,
          includeSummary: true,
          includeHeaders: true,
        },
      },
    },
    fields: [
      {
        key: 'name',
        type: 'textbox',
        label: 'Name',
        validate: 'required',
        maxLength: 40,
      },
      {
        key: 'description',
        type: 'textarea',
        label: 'Description',
        maxLength: 120,
      },
      {
        key: 'ownerType',
        type: 'singleselect',
        label: 'Shared with',
        values: getOwnerOptions(),
        validate: 'required',
        help: {
          type: 'info',
          description:
            'Reports can be visible to users or organisations. Sharing your report will make it available for anyone else inside your organisation to view. They may need extra permissions to edit or delete them though.',
        },
      },

      {
        type: 'separator',
      },
      ...((federation?.hasSharedTeam &&
        federation?.canReadShared && [
          {
            type: 'title',
            label: 'Federation',
          },
          {
            key: 'options.includeShared',
            type: 'checkbox',
            label: 'Include Central Team Profiles',
            maxLength: 120,
            large: false,
          },
          {
            type: 'separator',
          },
        ]) ||
        []),
      {
        type: 'title',
        label: 'Excel Export',
      },
      {
        key: 'options.excel.notes',
        large: false,
        type: 'checkbox',
        label: 'Include Errors and Warnings as Notes',
      },
      {
        key: 'options.excel.singleTab',
        large: false,
        type: 'checkbox',
        label: 'Export All Profiles onto a Single Tab',
      },
      {
        key: 'options.excel.includeSummary',
        large: false,
        type: 'checkbox',
        label: 'Include Summary Page',
      },
      {
        key: 'options.excel.includeHeaders',
        large: false,
        type: 'checkbox',
        label: 'Include Section Header Row',
      },
    ],
    successToastMessage: 'Report Added',
    onSave: async (values) => {
      let ownerId;
      switch (values.ownerType) {
        case 'ORGANISATION': {
          ownerId = organisation.id;
          break;
        }
        case 'FEDERATION': {
          ownerId = federation.id;
          break;
        }
        default: {
          break;
        }
      }
      analytics.events.insights.added();

      // Couple of defaults here that feel like they
      // should potentially be on a scheme or on a
      // default report of some kind?

      const options = {
        ...defaultReportOptions,
        ...values.options,
      };

      let [, columns] = getAvailableColumns(options, organisation.scheme);
      const report = await createReport({
        variables: {
          ...values,
          sourceType: 'profiles',
          viewType: 'profiles-grid',
          exportType: ['profiles-excel'],
          options: {
            ...options,
            columns: columns.map((c) => ({
              key: c.key,
              visible: true,
              width: c.width,
            })),
          },
          ownerId,
        },
      });
      await onCreate(report.data);
    },
  });

  return null;
};

export default AddReportForm;
