import trackEvent from '../trackEvent';

export const dashboardClicked = (traits = {}) => {
  trackEvent('Header - Dashboard Clicked', traits, true);
};

export const profilesClicked = (traits = {}) => {
  trackEvent('Header - Profiles Clicked', traits, true);
};

export const insightsClicked = (traits = {}) => {
  trackEvent('Header - Insights Clicked', traits, true);
};

export const settingsClicked = (traits = {}) => {
  trackEvent('Header - Settings Clicked', traits, true);
};

export const addProfileClicked = (traits = {}) => {
  trackEvent('Header - Add Profile Clicked', traits, true);
};

export const searchClicked = (traits = {}) => {
  trackEvent('Header - Search Clicked', traits, true);
};

export const changedSchool = (traits = {}) => {
  trackEvent('Header - Changed School', traits, true);
};

export const userOrganisationClicked = (traits = {}) => {
  trackEvent('Header - User - Organisation Clicked', traits, true);
};

export const userOrganisationSettingsClicked = (traits = {}) => {
  trackEvent('Header - User - Organisation Settings Clicked', traits, true);
};

export const userLogoutClicked = (traits = {}) => {
  trackEvent('Header - User - Logout Clicked', traits, true);
};

export const helpGuideClicked = (traits = {}) => {
  trackEvent('Header - Help - Help Guide', traits, true);
};

export const addMenuClicked = (traits = {}) => {
  trackEvent('Header - Add Menu Clicked', traits, true);
};

export const helpChatClicked = (traits = {}) => {
  trackEvent('Header - Help - Chat', traits, true);
};

export const helpChatBlocked = (traits = {}) => {
  trackEvent('Header - Help - Chat Blocked', traits, true);
};
