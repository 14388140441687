import { GET_PROFILETYPE_INUSE } from '@/queries';
import { AppToaster } from '@/utils/toaster';
import { useApolloClient } from '@apollo/client';
import React, { useContext, useMemo } from 'react';
import { useProfileTypeEdit } from '../../../components/SchemeEditor/ProfileTypeEditor';
import ProfileTypesTab from '../../../components/SchemeEditor/Tabs/ProfileTypes';
import { OrganisationContext } from '../../../context';
import { sortByOrder } from '../utils';

const ProfileTypesContainer = ({
  currentScheme,
  visibilityOptions,
  onProfileTypeReorder,
  onProfileTypeUpdated,
  onAddProfileType,
  onProfileTypeDelete,
  onProfileTypeRestore,
  selectedProfileTypeKey,
  onProfileTypeSelected,
  permissions,
}) => {
  const organisation = useContext(OrganisationContext);
  const orderedProfileTypes = useMemo(() => {
    if (currentScheme.profileTypes) {
      return currentScheme.profileTypes
        .filter((pt) => visibilityOptions.showDeleted || !pt.deleted)
        .filter((pt) => visibilityOptions.showUnused || pt.inUse)
        .filter((pt) => visibilityOptions.showParentOwned || pt.meta.owned)
        .sort(sortByOrder);
    }
    return [];
  }, [
    currentScheme.profileTypes,
    visibilityOptions.showDeleted,
    visibilityOptions.showUnused,
    visibilityOptions.showParentOwned,
  ]);
  const selectedProfileType = useMemo(() => {
    let result;
    if (selectedProfileTypeKey) {
      result = currentScheme.getProfileType(selectedProfileTypeKey);
    }
    return result;
  }, [currentScheme, selectedProfileTypeKey]);

  const [showAddForm, showEditForm] = useProfileTypeEdit(
    orderedProfileTypes,
    organisation.attributes?.avatarIconPack
  );

  const apolloClient = useApolloClient();

  return (
    <ProfileTypesTab
      profileTypes={orderedProfileTypes}
      iconPack={organisation.attributes?.avatarIconPack}
      selectedProfileType={selectedProfileType}
      onProfileTypeSelected={onProfileTypeSelected}
      onProfileTypeOrderUpdate={onProfileTypeReorder}
      onProfileTypeRequestEdit={() => {
        return showEditForm(
          selectedProfileType,
          onProfileTypeUpdated,
          async () => {
            if (selectedProfileType.deleted) {
              onProfileTypeRestore(selectedProfileType.key);
              return;
            }

            const result = await apolloClient.query({
              query: GET_PROFILETYPE_INUSE,
              fetchPolicy: 'no-cache',
              variables: {
                profileTypeKey: selectedProfileType.key,
              },
            });

            const canDelete = result?.data?.profileTypeInUse === false;
            if (canDelete) {
              onProfileTypeSelected(null);
              onProfileTypeDelete(
                selectedProfileType.key,
                !selectedProfileType.deleted
              );
              AppToaster.show({
                message: 'Profile Type marked as deleted',
                intent: 'success',
              });
            } else {
              AppToaster.show({
                message: 'Profile Type is in use, it cannot be deleted',
                intent: 'warning',
              });
            }
          }
        );
      }}
      onAddProfileType={() => {
        return showAddForm(
          (values) => {
            const newProfileTypeKey = onAddProfileType(values);
            onProfileTypeSelected(newProfileTypeKey);
          },
          () => {}
        );
      }}
      permissions={permissions}
    />
  );
};

export default ProfileTypesContainer;
