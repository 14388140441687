import { environmentTypes, getEnvironment } from '@/services';
import { createLogger, transports } from '@scrtracker/logging';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
const packageJson = require('../package.json');

const environment = getEnvironment().environment;

Sentry.init({
  dsn: 'https://64f4b78ce67046459fe5bc20827763ec@performance.scrtracker.com/5542935',
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: `scrtracker-web@${packageJson.version}`,
  environment,
  beforeSend: (event) => {
    if (environment !== environmentTypes.DEVELOPMENT) {
      return event;
    }
  },
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});

const level = environment === environmentTypes.PRODUCTION ? 'error' : 'debug';
let logger = createLogger({
  level,
  transports: [transports.standardConsole(false)],
});
logger.info('Registered Default Logger');
