import { ActionBar } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useContext, useState } from 'react';
import styles from '../styles.module.css';

import { FormBuilder, FormField } from '@hogwarts/ui-components-forms';
import { AnalyticsContext } from '../../../../context';

interface ConnectionEditorProps {
  providerKey: string;
  fields: FormField[];
  connection: any;
  onSave: (values: any) => void;
}
export const ConnectionEditor = ({
  providerKey,
  fields,
  connection,
  onSave,
}: ConnectionEditorProps) => {
  const analytics = useContext(AnalyticsContext);
  const [currentValues, setCurrentValues] = useState(connection);
  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="m-1">
        <FormBuilder
          initialValues={connection}
          fields={fields}
          onValuesChanged={(values) => {
            // store values for saving
            setCurrentValues(values);
          }}
        />
      </div>
      <div className="d-flex flex-rows">
        <div className={cn(styles.titleText, 'flex-grow-1')} />
        <ActionBar
          large={false}
          actions={[
            {
              text: 'Save',
              intent: 'primary',
              icon: 'save',
              onClick: () => {
                analytics.events.dataProvider.saveConnectionClicked({
                  PROVIDER_KEY: providerKey,
                });
                return onSave(currentValues);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
