import permissions from '@hogwarts/permissions';
import { Error, Loading, PageTitle } from '@hogwarts/ui-components-core';
import React, { useContext, useMemo } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { PageHeader, TimezoneWarning } from '../../components';
import InsightsList from '../../components/Insights/InsightsList';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import {
  useBuildReport,
  useFeature,
  useMutation,
  usePermission,
  useQuery,
} from '../../hooks';
import { isFeatureEnabled } from '../../hooks/useFeature';
import {
  CREATE_REPORT,
  DELETE_REPORT,
  UPDATE_USER_PREFERENCES,
} from '../../mutations';
import {
  GET_CURRENT_USER,
  GET_REPORTS,
  GET_REPORTSResponse,
} from '../../queries';
import { H1 } from '../../ui-dom-components';
import insightComponentRegistry from './components';
import styles from './styles.module.css';
import { getExportTypeProps } from './utils';

const Insights = () => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);
  const user = useContext(UserContext);

  const viewEnabled = useFeature('reports.view');
  const duplicateEnabled = useFeature('reports.add');

  let [selectedInsightId, setSelectedInsightId] = useQueryParam(
    'id',
    StringParam
  );
  const [isNewInsight] = useQueryParam('new', BooleanParam);

  const {
    data: reports,
    loading,
    error,
  } = useQuery<GET_REPORTSResponse[]>(GET_REPORTS, {
    selector: 'organisations[0].reports',
    variables: {
      organisationKey: organisation.key,
    },
  });
  const [createReport] = useMutation(CREATE_REPORT, {
    selector: 'createReport',
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [
      { query: GET_CURRENT_USER },
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const canDeleteOrganisationReports = usePermission(
    permissions.REPORT_DELETE,
    organisation?.id
  );
  const canDeleteFederationReports = usePermission(
    permissions.REPORT_DELETE,
    federation?.id
  );

  const [
    exportReport,
    {
      component: BuildingReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
  ] = useBuildReport();

  const reports2 = useMemo(() => {
    if (!reports) return null;

    // Grab User favourites
    const favourites = user.preferences?.favourites?.reports || {};

    let result = reports
      .filter((r) => {
        const options = r.options || {};
        if (options.hidden) return false;

        if (
          options.hideIfNoSharedTeam === true &&
          (!federation?.hasSharedTeam || !federation?.canReadSharedProfiles)
        ) {
          return false;
        }

        if (
          options.featureKey &&
          !isFeatureEnabled(options.featureKey, organisation)
        ) {
          return false;
        }
        return true;
      })
      .map((report) => {
        let favourite = favourites[report.id];
        let canDelete;
        let sharedWith;
        switch (report.ownerType) {
          case 'FEDERATION': {
            canDelete = canDeleteFederationReports;
            sharedWith = 'Shared with Federation';
            break;
          }
          case 'ORGANISATION': {
            canDelete = canDeleteOrganisationReports;
            sharedWith = 'Shared with Organisation';
            break;
          }
          case 'USER': {
            sharedWith = 'Only Visible to you';
            canDelete = true;
            break;
          }
          default:
          case 'SYSTEM': {
            if (favourite == null) {
              favourite = true;
            }
            canDelete = false;
            break;
          }
        }

        if (report.options?.preventDeletion === true) {
          canDelete = false;
        }

        const Component = report.viewType
          ? insightComponentRegistry[report.viewType]
          : null;

        const exportType = report.exportType?.[0];

        return {
          ...report,
          canDelete,
          favourite: !!favourite,
          sharedWith,
          Component,
          exportButtonProps: exportType
            ? getExportTypeProps(exportType, !reportDisabled)
            : undefined,
          canDuplicate: duplicateEnabled,
          onDuplicate: duplicateEnabled
            ? () => {
                analytics.events.insights.duplicateClicked({
                  insightId: report.id,
                  insightName: report.name,
                });
                return createReport({
                  variables: {
                    ...report,
                    name: `Copy of ${report.name}`,
                    ownerType: 'USER',
                    ownerId: user.id,
                    options: {
                      ...report.options,
                      hideIfNoSharedTeam: false,
                      preventEditing: false,
                      preventDeletion: false,
                    },
                  },
                });
              }
            : null,
          onDelete: () => {
            analytics.events.insights.deleteClicked({
              insightId: report.id,
              insightName: report.name,
            });
            return deleteReport({
              variables: {
                reportId: report.id,
              },
            });
          },
          onFavourite: (value: boolean) => {
            analytics.events.insights.favouriteClicked({
              insightId: report.id,
              insightName: report.name,
              favourited: value,
            });
            return updateUserPreferences({
              variables: {
                updatedPreferences: {
                  favourites: {
                    reports: {
                      ...favourites,
                      [report.id]: value,
                    },
                  },
                },
              },
            });
          },
          onExport: exportType
            ? () => {
                analytics.events.insights.exportClicked({
                  insightId: report.id,
                  insightName: report.name,
                });
                return exportReport(report.id, exportType, {
                  organisationId: organisation?.id,
                  federationId: federation?.id,
                });
              }
            : null,
          onView:
            (!exportType || viewEnabled) && Component
              ? () => {
                  analytics.events.insights.viewClicked({
                    insightId: report.id,
                    insightName: report.name,
                  });
                  setSelectedInsightId(report.id);
                }
              : null,
        };
      });

    return [
      ...result.filter((c) => c.favourite === true),
      ...result.filter((c) => c.favourite !== true),
    ];
  }, [
    reports,
    user,
    federation,
    organisation,
    reportDisabled,
    duplicateEnabled,
    viewEnabled,
    canDeleteFederationReports,
    canDeleteOrganisationReports,
    analytics,
    createReport,
    deleteReport,
    updateUserPreferences,
    exportReport,
    setSelectedInsightId,
  ]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  if (selectedInsightId) {
    const insight = reports2!.find((report) => report.id === selectedInsightId);
    if (!insight && isNewInsight) {
      return <Loading />;
    } else if (!insight) {
      return <Error title="Unknown Insight" />;
    }

    if (!insight.Component) return <Error title="Unknown Component" />;

    return (
      <>
        <PageTitle title="Insights" />
        <PageHeader
          // @ts-ignore
          header={
            <div className={styles.headerContainer}>
              <p className={styles.headerOrganisationName}>
                {organisation.name}
              </p>
              <H1 className={styles.selectorCurrent}>{insight.name}</H1>
            </div>
          }
          notificationChildren={<TimezoneWarning />}
        />
        <BuildingReportComponent {...reportProps} />
        <insight.Component
          insight={insight}
          onExport={(params: any) =>
            exportReport(insight.id, undefined, { ...params })
          }
        />
      </>
    );
  } else {
    return (
      <>
        <PageTitle title="Insights" />
        <PageHeader
          header={organisation && organisation.name}
          actions={[]}
          notificationChildren={<TimezoneWarning />}
        />
        <BuildingReportComponent {...reportProps} />

        <div className="container-lg mt-3 h-100">
          <InsightsList insights={reports2} />
        </div>
      </>
    );
  }
};

export default Insights;
