import { keys } from '@hogwarts/utils';

interface Query {
  key?: string;
  loading?: boolean;
  error?: any;
}
interface Result {
  loading: boolean;
  error: boolean;
}

export function useQueryResults<TQueries extends Record<string, Query>>(
  queries: TQueries
): Result {
  // important, if nothing is loading, return false
  let allLoading: any = false;
  let allErrors: any = null;

  for (const query of keys(queries)) {
    const { key, loading, error } = query;

    if (error) {
      if (!allErrors) allErrors = {};
      allErrors[key] = error;
    }
    if (loading) {
      if (!allLoading) allLoading = {};
      allLoading[key] = true;
    }
  }

  return {
    loading: !!allLoading,
    error: !!allErrors,
  };
}
