import { PageHeader } from '@/components';
import { OrganisationContext } from '@/context';
import { useQuery, useTransformProfiles } from '@/hooks';
import { DATASOURCE_QUERY_PROFILES } from '@/queries';
import { Button } from '@blueprintjs/core';
import { executeQuery } from '@hogwarts/scheme-profiles';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import groupQuery from './groupQuery';
import ProfilesGrid from './ProfilesList2';

const dbsRenewalCondition = {
  variables: {
    dbs_renewal_date$: {
      type: 'date',
      source: ['field', 'dbs_renewal_date'],
    },
  },
  conditions: [
    {
      when: 'dbs_renewal_date$',
      comparison: [
        'exactlyOrEarlierThan',
        {
          range: 'in2months',
        },
      ],
    },
  ],
};

const DBSRenewalReport = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  const transformProfiles = useTransformProfiles();

  const { data, loading, error } = useQuery(DATASOURCE_QUERY_PROFILES, {
    selector: 'queryProfiles.result',
    variables: {
      // profileTypes: [],
      condition: dbsRenewalCondition,
      tags: ['active', 'onboarding'],
      organisationKey: organisation.key,
      sort: [{ field: 'firstname' }],
      skip: 0,
      limit: 0,
    },
    transform: transformProfiles,
  });

  const groups = useMemo(() => {
    if (loading || error || !data) return null;

    const { groups: result } = executeQuery(
      groupQuery,
      data,
      {},
      {
        organisation: organisation,
        timezone: organisation.timezone,
      }
    );

    return result;
  }, [loading, error, data, organisation]);

  let [selectedGroup, setSelectedGroup] = useState(null);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Unknown Error Occurred</div>;
  }

  if (!selectedGroup && groups && groups.length) {
    selectedGroup = groups[0];
  }

  const items = selectedGroup && selectedGroup.items;

  return (
    <div className="row h-100">
      <PageHeader noWrapper={false} />

      <div className="d-flex flex-column col-sm-3">
        {groups &&
          groups.map((group, index) => {
            return (
              <Button
                alignText="left"
                active={group === selectedGroup}
                minimal
                onClick={() => setSelectedGroup(group)}
                key={index}
              >
                {`${t(group.label)} (${group.items.length})`}
              </Button>
            );
          })}
      </div>
      <div className="col-sm-9">
        {!items ? (
          <div>{t('No items')}</div>
        ) : (
          <ProfilesGrid profiles={items} />
        )}
      </div>
    </div>
  );
};

export default DBSRenewalReport;
