import { ActionWithChildren } from '@hogwarts/ui-components-core';
import { useContext, useEffect } from 'react';
import PageHeaderContainer, { PageHeaderContext } from './container';

interface PageHeaderProps {
  header?: string;
  prefix?: string;
  actions?: ActionWithChildren[];
  notificationChildren?: React.ReactNode;
  noWrapper?: boolean;
  children?: React.ReactNode;
}
const PageHeader = ({
  header,
  prefix,
  actions,
  notificationChildren,
  noWrapper,
  children,
}: PageHeaderProps) => {
  const headerContext = useContext(PageHeaderContext);
  useEffect(() => {
    if (!headerContext) return;
    if (noWrapper != null && noWrapper !== headerContext.noWrapper) {
      headerContext.setNoWrapper(noWrapper);
    }
    if (header && header !== headerContext.header) {
      headerContext.setHeader(header);
    }
    if (prefix && prefix !== headerContext.prefix) {
      headerContext.setPrefix(prefix);
    }
    if (
      actions &&
      actions !== headerContext.actions &&
      Array.isArray(actions)
    ) {
      headerContext.setActions(actions);
    }
    if (children && children !== headerContext.children) {
      headerContext.setChildren(children);
    }
    if (
      notificationChildren &&
      notificationChildren !== headerContext.notificationChildren
    ) {
      headerContext.setNotificationChildren(notificationChildren);
    }
  }, [
    actions,
    children,
    header,
    headerContext,
    noWrapper,
    notificationChildren,
    prefix,
  ]);

  return null;
};

export { PageHeaderContainer };
export default PageHeader;
