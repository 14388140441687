import { NumericInput } from '@blueprintjs/core';
import React from 'react';
import { getErrorIntent } from './utils';

const parseNumber = (value) => {
  if (value == null) return 0;
  if (value == '') return 0;
  let parsed = parseFloat(value);
  if (Number.isNaN(parsed)) {
    parsed = 0;
  }
  return parsed;
};

const NumberComponent = ({
  fieldKey,
  formField,
  readOnly,
  placeHolder,
  fieldHelpers,
  fieldMeta,
  min,
  max,
}) => {
  return (
    <NumericInput
      id={fieldKey}
      intent={getErrorIntent(fieldMeta)}
      min={min}
      max={max}
      onBlur={(event) => {
        let value = parseNumber(event.target.value);
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(value, true);
      }}
      onValueChange={(value1) => {
        let value = parseNumber(value1);
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(value);
      }}
      placeholder={placeHolder}
      value={parseNumber(formField.value)}
      disabled={readOnly}
      fill
    />
  );
};

export default {
  name: 'Numeric Input',
  options: [],
  label: true,
  Component: NumberComponent,
};
