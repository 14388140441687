import { Tab, Tabs, Tag } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { groupBy } from 'lodash';
import React from 'react';
import './styles.css';
import styles from './styles.module.css';

const SearchResult = ({ profile, onClick }) => {
  return (
    <div
      className={cn('d-flex align-items-center', styles.result)}
      onClick={onClick}
    >
      <FontAwesomeIcon
        size="2x"
        icon={['far', 'user']}
        className={styles.icon}
      />
      <div className={styles.resultHeaderWrapper}>
        <p className={styles.resultHeader}>{profile.name}</p>
        <p className={styles.resultSubheader}>{profile.profileType?.label}</p>
      </div>
      <div className="ml-auto pl-3">
        {profile.tags.includes('active') && <Tag intent="primary">Active</Tag>}
        {profile.tags.includes('onboarding') && (
          <Tag intent="warning">Onboarding</Tag>
        )}
        {profile.tags.includes('leaver') && <Tag intent="none">Leaver</Tag>}
      </div>
    </div>
  );
};

const SearchResultTabs = ({
  organisationName,
  organisationProfiles,
  federationName,
  federationProfiles,
  onSelected,
}) => {
  let groupedFederationProfiles = null;
  let defaultSelectedTabId = 'org';
  if (federationProfiles && federationProfiles.length > 0) {
    groupedFederationProfiles = {};
    groupedFederationProfiles = groupBy(federationProfiles, 'organisationName');

    if (organisationProfiles && organisationProfiles.length === 0) {
      defaultSelectedTabId = 'fed';
    }
  }

  return (
    <div className={cn('mx-4 mb-4 searchdrawer__tablist', styles.tabList)}>
      <Tabs
        defaultSelectedTabId={defaultSelectedTabId}
        animate={false}
        id="result-tabs"
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id="org"
          panel={
            <div className="h-100">
              {organisationProfiles.map((p) => (
                <SearchResult
                  key={p.id}
                  profile={p}
                  onClick={onSelected.bind(null, p)}
                />
              ))}
            </div>
          }
        >
          <span className="mx-3">
            {organisationName.trunc(30, true)}
            <Tag round intent="primary" className="ml-2">
              {organisationProfiles.length}
            </Tag>
          </span>
        </Tab>
        {federationProfiles && federationProfiles.length > 0 && (
          <Tab
            id="fed"
            panel={
              <div>
                {Object.keys(groupedFederationProfiles).map((group) => {
                  return (
                    <div key={group} className="h-100">
                      <p className={styles.sectionHeading}>{group}</p>
                      {groupedFederationProfiles[group].map((p) => (
                        <SearchResult
                          key={p.id}
                          profile={p}
                          onClick={onSelected.bind(null, p)}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
            }
          >
            <span className="mx-3">
              {federationName.trunc(30, true)}
              <Tag round intent="primary" className="ml-2">
                {federationProfiles.length}
              </Tag>
            </span>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default SearchResultTabs;
