import { gql } from '@apollo/client';

export const CREATE_ORGANISATION_USER_MUTATION = gql`
  mutation createOrganisationUser(
    $organisationId: ID!
    $firstname: String!
    $lastname: String!
    $email: String!
    $roles: [ID!]!
  ) {
    createOrganisationUser(
      organisationId: $organisationId
      firstname: $firstname
      lastname: $lastname
      email: $email
      roles: $roles
    ) {
      id
      name
      firstname
      lastname
      email
      enabled
      roles {
        id
        name
      }
    }
  }
`;

export const UPDATE_ORGANISATION_USER_MUTATION = gql`
  mutation ($id: ID!, $roles: [ID!]!, $organisationId: ID!) {
    updateOrganisationUser(
      id: $id
      organisationId: $organisationId
      roles: $roles
    ) {
      id
      cacheKey
      name
      firstname
      lastname
      email
      enabled
      roles {
        id
        name
      }
    }
  }
`;

export const DELETE_ORGANISATION_USER_MUTATION = gql`
  mutation deleteOrganisationUser($id: ID!, $organisationId: ID!) {
    deleteOrganisationUser(id: $id, organisationId: $organisationId) {
      id
    }
  }
`;

export const CREATE_ORGANISATION_GROUP_MUTATION = gql`
  mutation createOrganisationGroup(
    $name: String!
    $federationId: ID!
    $enabled: Boolean!
    $organisations: [ID!]
  ) {
    createOrganisationGroup(
      federationId: $federationId
      name: $name
      enabled: $enabled
      organisations: $organisations
    ) {
      id
      name
      enabled
      includeAll
      organisations {
        id
        key
        name
      }
    }
  }
`;

export const UPDATE_ORGANISATION_GROUP_MUTATION = gql`
  mutation updateOrganisationGroup(
    $id: ID!
    $name: String!
    $enabled: Boolean!
    $organisations: [ID!]
  ) {
    updateOrganisationGroup(
      id: $id
      name: $name
      enabled: $enabled
      organisations: $organisations
    ) {
      id
      name
      enabled
      includeAll
      organisations {
        id
        key
        name
      }
    }
  }
`;

export const DELETE_ORGANISATION_GROUP_MUTATION = gql`
  mutation deleteOrganisationGroup($id: ID!) {
    deleteOrganisationGroup(id: $id) {
      id
    }
  }
`;

export const CREATE_USER_GROUP_MUTATION = gql`
  mutation createUserGroup(
    $name: String!
    $federationId: ID!
    $enabled: Boolean!
    $users: [ID!]
  ) {
    createUserGroup(
      federationId: $federationId
      name: $name
      enabled: $enabled
      users: $users
    ) {
      id
      name
      enabled
      includeAll
      users {
        id
        name
        email
        enabled
      }
    }
  }
`;

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation updateUserGroup(
    $id: ID!
    $name: String!
    $enabled: Boolean!
    $users: [ID!]
  ) {
    updateUserGroup(id: $id, name: $name, enabled: $enabled, users: $users) {
      id
      name
      enabled
      includeAll
      users {
        id
        name
        email
        enabled
      }
    }
  }
`;

export const DELETE_USER_GROUP_MUTATION = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(id: $id) {
      id
    }
  }
`;

export const CREATE_FEDERATION_USER_MUTATION = gql`
  mutation createFederationUser(
    $federationId: ID!
    $firstname: String!
    $lastname: String!
    $email: String!
    $roles: [ID!]!
  ) {
    createFederationUser(
      federationId: $federationId
      firstname: $firstname
      lastname: $lastname
      email: $email
      roles: $roles
    ) {
      id
      cacheKey
      name
      firstname
      lastname
      email
      enabled
      roles {
        id
        name
      }
    }
  }
`;

export const UPDATE_FEDERATION_USER_MUTATION = gql`
  mutation updateFederationUser($id: ID!, $federationId: ID!, $roles: [ID!]!) {
    updateFederationUser(id: $id, federationId: $federationId, roles: $roles) {
      id
      cacheKey
      name
      firstname
      lastname
      email
      enabled
      roles {
        id
        name
      }
    }
  }
`;

export const DELETE_FEDERATION_USER_MUTATION = gql`
  mutation deleteFederationUser($id: ID!, $federationId: ID!) {
    deleteFederationUser(id: $id, federationId: $federationId) {
      id
    }
  }
`;

export const ASSIGN_USERGROUP_MUTATION = gql`
  mutation assignUserGroupRoles(
    $userGroupId: ID!
    $organisationGroupId: ID!
    $roles: [ID!]!
  ) {
    assignUserGroupRoles(
      userGroupId: $userGroupId
      organisationGroupId: $organisationGroupId
      roles: $roles
    ) {
      id
      organisationGroup {
        id
        name
        enabled
        includeAll
      }
      roles {
        id
        name
      }
    }
  }
`;

export const UNASSIGN_USERGROUP_MUTATION = gql`
  mutation unassignUserGroupRoles(
    $userGroupId: ID!
    $organisationGroupId: ID!
  ) {
    unassignUserGroupRoles(
      userGroupId: $userGroupId
      organisationGroupId: $organisationGroupId
    ) {
      id
      organisationGroup {
        id
        name
        enabled
      }
      roles {
        id
        name
      }
    }
  }
`;
