import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Icon } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';

// import styles from './styles.module.css';

const DateRange = ({ startDate, endDate }) => {
  const luxonStartDate = DateTime.fromJSDate(startDate);
  const luxonEndDate = DateTime.fromJSDate(endDate);
  if (luxonStartDate.isValid) {
    return (
      <>
        {luxonStartDate.toLocaleString('DATE_SHORT')}
        <Icon icon="arrow-right" className="mx-2" />
        {luxonEndDate.isValid && luxonEndDate.toLocaleString('DATE_SHORT')}
      </>
    );
  }
  return null;
};

const DateFilter = ({ description, dateRange, setDateRange, large }) => {
  const { t } = useTranslation();

  const validatedDateRange =
    dateRange &&
    dateRange.map((date) => {
      if (date instanceof Date) return date;
      if (typeof date === 'string') return new Date(date);
      return null;
    });

  const intent =
    validatedDateRange && validatedDateRange[0] ? 'success' : 'none';
  return (
    <Popover position="bottom">
      <Button
        icon="calendar"
        rightIcon="caret-down"
        intent={intent}
        large={large}
        minimal={!intent || intent === 'none'}
        style={
          !intent || intent === 'none'
            ? {
                borderWidth: '1px',
                backgroundColor: 'white',
                borderColor: !intent ? 'lightgrey' : undefined,
                borderStyle: 'solid',
                borderRadius: '4px',
              }
            : null
        }
      >
        {validatedDateRange && validatedDateRange[0] ? (
          <DateRange
            startDate={validatedDateRange[0]}
            endDate={validatedDateRange[1]}
          />
        ) : (
          t(description)
        )}
      </Button>
      <DateRangePicker
        large={large}
        allowSingleDayRange
        value={validatedDateRange}
        onChange={setDateRange}
      />
    </Popover>
  );
};

export default DateFilter;
