import React, { useContext, useEffect } from 'react';
import { OrganisationContext } from '../../../../context';
import { useLazyQuery } from '../../../../hooks';
import { GET_BULK_IMPORT_SUMMARY } from '../../../../queries';
import { BulkImportSummaryCore, SummaryItem } from './component';

interface BulkImportSummaryProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: () => void;
}
export const BulkImportSummary = ({
  isOpen,
  onClose,
  onImport,
}: BulkImportSummaryProps) => {
  const organisation = useContext(OrganisationContext);

  const [getBulkImportProfileSummary, { loading, data: summary, error }] =
    useLazyQuery<SummaryItem[]>(GET_BULK_IMPORT_SUMMARY, {
      fetchPolicy: 'network-only',
      selector: 'organisations[0].bulkImportSummary',
      variables: {
        organisationKey: organisation.key,
      },
    });

  useEffect(() => {
    if (!isOpen) return;
    getBulkImportProfileSummary();
  }, [getBulkImportProfileSummary, isOpen]);

  return (
    <BulkImportSummaryCore
      summary={summary}
      profileTypes={organisation.scheme.profileTypes}
      error={!!error}
      onImport={onImport}
      iconPack={organisation.attributes?.avatarIconPack}
      loading={loading}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
