import { Error, Loading } from '@hogwarts/ui-components-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import FederationWelcome from '../../containers/federationWelcome';
import { UserContext } from '../../context';
import { useQuery, useQueryResults } from '../../hooks';
import { GET_USER_FEDERATIONS, GET_USER_ORGANISATIONS } from '../../queries';
import { Organisation } from '../../types';
import { Header } from './header';
import SelectedOrganisationContent from './SelectedOrganisationContent';

interface Federation {
  key: string;
  name: string;
}

interface User {
  preferences?: {
    defaultOrganisationKey?: string;
  };
}
const redirectToFavouriteOrganisation = (
  organisations: Organisation[],
  user?: User
) => {
  const defaultOrganisationKey = user?.preferences?.defaultOrganisationKey;
  if (defaultOrganisationKey) {
    let defaultOrganisation = organisations.find(
      (o) => o.key === defaultOrganisationKey
    );
    if (defaultOrganisation) {
      return <Redirect to={`/${defaultOrganisation.key}`} />;
    }
  }

  return <Redirect to={`/${organisations[0].key}`} />;
};

const LocateOrganisationContent = () => {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const { data: federations, ...federationsQuery } = useQuery<Federation[]>(
    GET_USER_FEDERATIONS,
    {
      selector: 'federations',
    }
  );

  const { data: organisations, ...organisationsQuery } = useQuery<
    Organisation[]
  >(GET_USER_ORGANISATIONS, {
    selector: 'organisations',
  });

  const { loading, error } = useQueryResults({
    federationsQuery,
    organisationsQuery,
  });

  if (loading) {
    return <Loading showLogo />;
  }

  if (error) {
    return (
      <>
        <Header showSearch={false} />
        <Error title={t('Error retrieving your Organisations')} />
      </>
    );
  }

  if (!organisations || organisations.length === 0) {
    if (federations && federations.length > 0) {
      return (
        <>
          <Header showSearch={false} />
          <FederationWelcome federations={federations} email={user.email} />
        </>
      );
    }
    return (
      <>
        <Header showSearch={false} />
        <Error
          icon="error"
          title={t('You are not a member of any Organisations.')}
          description={t(
            'Please ask your Administrator to add you to the system. Alternatively, please contact support.'
          )}
        />
      </>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={'/'}
        render={() => redirectToFavouriteOrganisation(organisations, user)}
      />
      <Route
        path={'/:organisationKey'}
        render={(props: any) => {
          const organisationKey = props.match.params.organisationKey;
          let organisation = organisations.find(
            (org) => org.key === organisationKey
          );
          if (!organisation) {
            return <Redirect to={`/`} />;
          }
          let federationAccess = false;
          if (organisation.federation) {
            const federation = federations?.find(
              (fed) => fed.key === organisation!.federation!.key
            );
            federationAccess = federation != null;
          }
          return (
            <SelectedOrganisationContent
              selectedOrganisation={organisation}
              organisations={organisations}
              hasFederationAccess={federationAccess}
            />
          );
        }}
      />
    </Switch>
  );
};

export default LocateOrganisationContent;
