import {
  Button,
  Callout,
  Classes,
  Dialog,
  Intent,
  Radio,
} from '@blueprintjs/core';
import { ProgressBar } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const toString = (value: any): string | null => {
  if (value == null) return null;
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value.toString === 'function') {
    return value.toString();
  }
  if (Array.isArray(value)) return '(Array)';
  if (typeof value === 'object') return '(Object)';
  return String(value);
};

type Profile = Record<string, unknown> & {
  name: string;
  profileType: string;
  ratings: Record<string, number>;
};

interface RatingSystem {
  key: string;
  label: string;
  readyColor: string;
  notReadyColor: string;
}

interface Field {
  key: string;
  label: string;
}

interface ProfileMergeProps {
  isOpen: boolean;
  leftProfile: Profile;
  rightProfile: Profile;
  error?: string;
  ratingSystems: RatingSystem[];
  fields: Field[];
  onClose: () => void;
  onMerge: (leftProfile: Profile, rightProfile: Profile) => void;
}
const ProfileMerge = ({
  isOpen,
  onClose,
  leftProfile,
  rightProfile,
  error,
  fields,
  ratingSystems,
  onMerge,
}: ProfileMergeProps) => {
  const { t } = useTranslation();
  const [preserveLeft, setPreserveLeft] = useState(true);

  return (
    <Dialog
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      usePortal
      title={t('Merge Profiles')}
      isOpen={isOpen}
      onClose={onClose}
      className={styles.mergeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        {error && (
          <Callout
            intent={Intent.DANGER}
            title={t('Error occurred whilst merging')}
          >
            {t(error)}
          </Callout>
        )}
        <table
          className={cn(
            'bp3-html-table bp3-html-table-condensed',
            styles.mergeTable
          )}
        >
          <thead>
            <tr>
              <th>{t('Name')}</th>
              <th>{toString(leftProfile.name)}</th>
              <th>{toString(rightProfile.name)}</th>
            </tr>
          </thead>
          <tbody>
            <tr key="profileType">
              <td>{t('Profile Type')}</td>
              <td>{toString(leftProfile.profileType)}</td>
              <td>{toString(rightProfile.profileType)}</td>
            </tr>

            {fields.map(({ key, label }, index) => (
              <tr key={index}>
                <td>{t(label)}</td>
                <td>{toString(leftProfile[key])}</td>
                <td>{toString(rightProfile[key])}</td>
              </tr>
            ))}

            {ratingSystems?.map((r) => {
              const leftValue = leftProfile.ratings[r.key];
              let leftColor = r.readyColor;
              if (leftValue < 1) {
                leftColor = r.notReadyColor;
              }

              const rightValue = rightProfile.ratings[r.key];
              let rightColor = r.readyColor;
              if (rightValue < 1) {
                rightColor = r.notReadyColor;
              }

              return (
                <tr key={`ratings_${r.key}`}>
                  <td>{r.label}</td>
                  <td>
                    <ProgressBar
                      minimum={30}
                      border
                      color={leftColor}
                      round
                      value={leftValue * 100}
                    >{`${Math.trunc(leftValue * 100)}%`}</ProgressBar>
                  </td>
                  <td>
                    <ProgressBar
                      minimum={30}
                      border
                      color={rightColor}
                      round
                      value={rightValue * 100}
                    >{`${Math.trunc(rightValue * 100)}%`}</ProgressBar>
                  </td>
                </tr>
              );
            })}

            <tr key="conflict" className={styles.mergeConflictRow}>
              <td>{t('In case of conflict')}</td>
              <td>
                <Radio
                  checked={preserveLeft}
                  onChange={() => setPreserveLeft(true)}
                  label={t('Preserve these values')}
                />
              </td>
              <td>
                <Radio
                  checked={!preserveLeft}
                  onChange={() => setPreserveLeft(false)}
                  label={t('Preserve these values')}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => {
              setPreserveLeft(true);
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              onMerge(
                preserveLeft ? leftProfile : rightProfile,
                preserveLeft ? rightProfile : leftProfile
              );
            }}
            large
            intent={Intent.DANGER}
          >
            {t('Merge')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileMerge;
