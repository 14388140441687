import './styles.css';

import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
} from '@ag-grid-community/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ApiParams,
  Components,
  GridViewProps,
  MenuItemDefExt,
} from '../../types';

import { AgGridReact } from '@ag-grid-community/react';
import { useTranslation } from 'react-i18next';
import frameworkComponents from './frameworkComponents';
import { getColumnDefsFromColumns } from './getColumnDefsFromColumns';
import { useDebounceStack } from './useDebounceStack';

const DEFAULT_THEME = 'ag-theme-alpine';
// const THEME = 'ag-theme-balham';

// TODO: Must be a way of getting the font awesome in here?
const lockIcon = `
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" class="svg-inline--fa fa-lock fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
    <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
  </svg>`;

const DEBOUNCE_TIME = 250;

const defaultGetRowNodeId = (data: any): string => data?.cacheKey || data?.id;

const gridOptions: GridOptions = {
  maintainColumnOrder: true,
  groupDisplayType: 'groupRows',
  suppressCopyRowsToClipboard: true,
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
  },
};

// TODO: Events we could use to persist
// sortChanged, filterChanged, columnVisible, columnPinned, columnResized, columnMoved
// we could debounce these into one event and passed back the column state?
// If we store that state locally and keep it _merged_ with an existing state, this would allow removal of a column
// and then re-adding it (think scheme changes when columns change)

const GridView = ({
  timezone,
  contextMenuItems,
  onPostSort,
  getRowNodeId,
  theme = DEFAULT_THEME,
  allowEdit = false,
  onGridReady,
  onCellValueChanged,
  onBatchCellValueChanged,
  components: customFrameworkComponents,

  containerClassName = 'h-100',

  groups,
  columns,
  rows,
  dataSource,

  sizeColumnsToFit,
  filter,

  parseDate,

  ...rest
}: GridViewProps) => {
  const { t } = useTranslation();

  const [{ api, columnApi }, setApi] = useState<Partial<ApiParams>>({});
  // Note, I'm doing this because some of the Grid (such as postSort) methods do
  // not get updated after the initial set. This makes it impossible to pass down
  // the API due to closures.
  // By using a ref I can deal with this but I still want the above for proper
  // state management.
  const apiReference = useRef<ApiParams | null>(null);
  if (api && columnApi) {
    apiReference.current = { api, columnApi };
  } else {
    apiReference.current = null;
  }

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(() => {
    const [columnDefs, allColumnDefs] = getColumnDefsFromColumns(parseDate!)(
      groups || [],
      columns,
      timezone
    );

    if (!allowEdit) {
      for (const columnDef of allColumnDefs) {
        if ('editable' in columnDef) {
          columnDef.editable = false;
        }
      }
    }

    // // grab the existing state (this could also be passed in from server)
    // const columnState = columnApi && columnApi.getColumnState();
    // if (columnState) {
    //   for (const colState of columnState) {
    //     const columnDef = allColumnDefs.find(
    //       (c) => 'colId' in c && c.colId === colState.colId
    //     ) as ColDef;
    //     if (columnDef) {
    //       // apply the existing state to new items
    //       columnDef.width = colState.width;
    //       columnDef.hide = colState.hide == null ? undefined : colState.hide;
    //       columnDef.sort = colState.sort;
    //       // sortindex working?
    //       columnDef.sortIndex = colState.sortIndex;
    //       // order?
    //       // pinned?
    //     }
    //   }
    // }
    return columnDefs;
  }, [groups, columns, timezone, columnApi]);

  // Enable/disable menu item based on conditions
  const shouldDisableAction = (actionName: string, selectedNodes: any[]) => {
    switch (actionName) {
      case 'Reset MFA':
        return selectedNodes.some((node) => !node.data.mfaEnabled);
      // Add other cases as needed
      default:
        return false;
    }
  };

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      if (!contextMenuItems) return [];

      // Excel like behaviour
      // if the row clicked on, isnt inthe selected nodes,
      // then deselect the rows
      let selectedNodes = params.api.getSelectedNodes();
      selectedNodes = selectedNodes.filter((n) => n.data);

      const rowClickedOn = params.node;

      if (!rowClickedOn) {
        return [];
      }

      const selectedRowIsContained = !!selectedNodes.find(
        (node) => node.id === rowClickedOn.id
      );

      if (!selectedRowIsContained) {
        // select row. deselect all others
        rowClickedOn.setSelected(true, true);
      }

      // update the list
      selectedNodes = params.api.getSelectedNodes();
      selectedNodes = selectedNodes.filter((n) => n.data);
      if (selectedNodes.length === 0) return [];

      // build the menu up
      let items: (string | MenuItemDefExt)[];
      if (typeof contextMenuItems === 'function') {
        items = contextMenuItems(selectedNodes, params.api);
      } else {
        items = contextMenuItems;
      }

      // remove empty items
      items = items?.filter(Boolean);
      if (!items || items.length === 0) return [];

      return items.map((item) => {
        if (typeof item === 'string') return item;

        let name = item.name;
        if (name !== null && typeof name === 'object') {
          // @ts-ignore
          const { message, ...options } = name;
          name = t(message, options);
        } else {
          name = t(name);
        }

        return {
          ...item,
          name,
          disabled: shouldDisableAction(name, selectedNodes),
          action: () => {
            if (!item.action) return;
            item.action(selectedNodes, params.api);
          },
          icon: item.locked ? lockIcon : undefined,
        };
      });
    },
    [contextMenuItems, t]
  );

  useEffect(() => {
    if (api && columnApi) {
      if (dataSource && dataSource.rowModelType === 'serverSide') {
        api.setServerSideDatasource(dataSource);
      }
      if (onGridReady) {
        onGridReady({ type: 'gridReady', api, columnApi });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, dataSource]);

  // useEffect(() => {
  //   if (api) {
  //     api.refreshCells({
  //       force: true,
  //     });
  //   }
  // }, [api, columnDefs]);

  useEffect(() => {
    if (dataSource) {
      if (api && dataSource.rowModelType === 'serverSide') {
        api.refreshServerSideStore({
          purge: true,
        });
      }
      if (dataSource.setFilter && filter) {
        dataSource.setFilter(filter);
      }
    }
  }, [api, dataSource, filter]);

  const onCellValueChangedDebounce = useDebounceStack<CellValueChangedEvent>(
    (allParams) => {
      if (allParams.length === 0) return;
      if (allParams.length === 1) {
        if (onCellValueChanged) {
          onCellValueChanged(allParams[0]);
        }
      } else if (onBatchCellValueChanged) {
        onBatchCellValueChanged(allParams, allParams[0].api);
      }
    },
    DEBOUNCE_TIME
  );

  const allFrameworkComponents = useMemo<Components>(
    () => ({ ...customFrameworkComponents, ...frameworkComponents }),
    [customFrameworkComponents]
  );

  return (
    <div className={`d-flex ${containerClassName}`}>
      <div className={`gridContainer ${theme || DEFAULT_THEME} flex-grow-1`}>
        <AgGridReact
          {...gridOptions}
          postSort={(rows) => {
            if (!apiReference.current) return;
            if (typeof onPostSort === 'function') {
              onPostSort(rows, apiReference.current);
            }
          }}
          onGridSizeChanged={() => {
            if (api && sizeColumnsToFit) {
              api.sizeColumnsToFit();
            }
          }}
          onGridReady={(event) => {
            if (event.type === 'gridReady') {
              setApi(event);
              if (sizeColumnsToFit) {
                event.api.sizeColumnsToFit();
              }
            }
          }}
          columnDefs={columnDefs}
          rowData={rows}
          onCellValueChanged={onCellValueChangedDebounce}
          getContextMenuItems={getContextMenuItems}
          getRowNodeId={getRowNodeId || defaultGetRowNodeId}
          {...dataSource}
          {...rest}
          components={allFrameworkComponents}
          // sortChanged, filterChanged, columnVisible, columnPinned, columnResized, columnMoved
        />
      </div>
    </div>
  );
};

export default GridView;
