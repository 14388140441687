import { gql } from '@apollo/client';

export const TOGGLE_WORKFLOW = gql`
  mutation toggleWorkflow($id: ID!, $enabled: Boolean!) {
    toggleWorkflow(id: $id, enabled: $enabled) {
      id
      cacheKey
      enabled
    }
  }
`;

export const CREATE_WORKFLOW = gql`
  mutation createWorkflow(
    $workflowType: String!
    $triggerType: String!
    $triggerParams: JSON!
    $actionType: String!
    $actionParams: JSON!
    $ownerType: String!
    $ownerId: ID
    $meta: JSON
  ) {
    createWorkflow(
      workflowType: $workflowType
      triggerType: $triggerType
      triggerParams: $triggerParams
      actionType: $actionType
      actionParams: $actionParams
      ownerType: $ownerType
      ownerId: $ownerId
      meta: $meta
    ) {
      id
      type
      triggerType
      triggerParams
      actionType
      actionParams
      meta
    }
  }
`;
