import { getScores } from './score';

const formatField = (fieldDefinition, fieldData) => {
  if (fieldDefinition.inputType === 'toggle') {
    return fieldData ? true : false;
  }
  return fieldData;
};

export const getSections = (profile, scheme) => {
  return scheme.sections
    .filter((section) => section.visible === true)
    .map((section) => {
      const scores = getScores(section.ratings, scheme);
      return {
        key: section.key,
        label: section.label,
        color: section.color,
        icon: section.icon,
        scores,
        canPrint: section.features?.sectionPrint?.enabled,
        fields: section.fields
          .filter((field) => field.visible === true)
          .map((field) => ({
            name: field.label,
            value: formatField(field, profile.data[field.key]),
            preview: !!(field.preview && profile.data[field.key]),
          }))
          .filter((field) => field.value !== null && field.value !== undefined),
      };
    });
};
