import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuditLog from '../../components/AuditLog';
import { SettingsPanel } from '../../components/Settings/SettingsPanel';
import { OrganisationContext } from '../../context';
import { useQuery } from '../../hooks';
import { GET_AUDIT_LOG } from '../../queries';
import FiltersContainer from './filters';

const AuditLogContainer = () => {
  const organisation = useContext(OrganisationContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreAvailable, setMoreAvailable] = useState(true);
  const [filter, setFilter] = useState(null);
  const history = useHistory();

  const auditLogVariables = {
    organisationKey: organisation.key,
    limit: 100,
    filter,
  };

  const auditLogSelector = 'organisations[0].auditLog';
  const { loading, error, data, fetchMore } = useQuery(GET_AUDIT_LOG, {
    variables: auditLogVariables,
    fetchPolicy: 'network-only',
    selector: auditLogSelector,
  });

  const loadMore = async () => {
    if (!data) return;
    const lastLog = data[data.length - 1];
    const cursor = lastLog.id;
    try {
      setLoadingMore(true);
      await fetchMore({
        variables: {
          ...auditLogVariables,
          cursor,
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newRecords = get(fetchMoreResult, auditLogSelector);
          if (newRecords.length < auditLogVariables.limit) {
            setMoreAvailable(false);
          }
          const currentRecords = get(previousResult, auditLogSelector);
          const combinedLogs = [...currentRecords, ...newRecords];
          const currentOrganisation = get(previousResult, 'organisations[0]');
          return {
            organisations: [{ ...currentOrganisation, auditLog: combinedLogs }],
          };
        },
      });
    } finally {
      setLoadingMore(false);
    }
  };

  const goToProfile = (profileId: string) => {
    history.push(`/${organisation.key}/profiles/${profileId}`);
  };

  return (
    <SettingsPanel title="Audit Log" error={!!error} loading={loading}>
      {Array.isArray(data) && (
        <>
          <FiltersContainer
            onFilter={(filter) => {
              setFilter(filter);
              setMoreAvailable(true);
            }}
          />
          <AuditLog
            timezone={organisation.timezone}
            records={data}
            loadMore={
              data?.length >= auditLogVariables.limit && moreAvailable
                ? loadMore
                : undefined
            }
            loadingMore={loadingMore}
            goToProfile={goToProfile}
          />{' '}
        </>
      )}
    </SettingsPanel>
  );
};

export default AuditLogContainer;
