import { schemeEditorFactory } from '@hogwarts/scheme-profiles';
import { set } from 'lodash';
import { sortByOrder } from './utils';

export const initialState = {
  initialised: false,
  changeSet: {},
  scheme: null,
  profileTypeKey: null,
};

const reorderThings = (items) => (movedKey, bumpedKey) => {
  let newOrder = [...(items || []).sort(sortByOrder)];

  const movedIndex = newOrder.findIndex((f) => f.key === movedKey);
  const bumpedIndex = newOrder.findIndex((f) => f.key === bumpedKey);

  const [movedItem] = newOrder.splice(movedIndex, 1);
  newOrder.splice(bumpedIndex, 0, movedItem);

  newOrder = newOrder.map((s, idx) => ({
    ...s,
    order: idx * 10,
  }));

  let orderPatch = {};
  for (const item of newOrder) {
    set(orderPatch, item.meta.order.path, item.order);
  }

  return orderPatch;
};

export const reducer = (userId, profileTypeKey) => (originalState, action) => {
  let state = {
    ...originalState,
  };

  if (action.type === 'SCHEME_SETUP') {
    // if (state.initialised) return;
    const { scheme, schemeName, schemeOptions, hasChanges, changeSet } = action;
    state = {
      ...state,
      hasChanges,
      scheme,
      schemeName,
      schemeOptions,
      changeSet,
    };
  } else if (action.type === 'DISCARD_CHANGESET') {
    state = {
      ...state,
      changeSet: {},
    };
  }

  const editor = schemeEditorFactory(
    state.scheme,
    state.changeSet,
    profileTypeKey
  );

  const { type } = action;
  switch (type) {
    case 'SCHEME_SETUP': {
      const updatedState = editor.refresh();
      return {
        ...state,
        ...updatedState,
        initialised: true,
      };
    }
    case 'DISCARD_CHANGESET': {
      const updatedState = editor.refresh();
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'PROFILE_TYPE_CHANGED': {
      const updatedState = editor.refresh();
      return {
        ...state,
        ...updatedState,
        profileTypeKey: action.profileTypeKey,
      };
    }
    case 'SECTION_ADD': {
      const updatedState = editor.addSection(action);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'SECTION_UPDATED':
    case 'FIELD_UPDATED':
    case 'PROFILE_TYPE_UPDATED':
    case 'RATING_SYSTEM_UPDATED': {
      const updatedState = editor.applyPatch(action.updated);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'FIELD_ADD': {
      const updatedState = editor.addField(action);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'ARRAYFIELD_ADD': {
      const updatedState = editor.addArrayField(action);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'FIELD_RESTORE': {
      const { key } = action;
      const updatedState = editor.restoreField(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'FIELD_DELETE': {
      const { key } = action;
      const updatedState = editor.deleteField(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'SECTION_DELETE': {
      const { key } = action;
      const updatedState = editor.deleteSection(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'SECTION_RESTORE': {
      const { key } = action;
      const updatedState = editor.restoreSection(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'PROFILE_TYPE_ADD': {
      const updatedState = editor.addProfileType(action);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'PROFILE_TYPE_DELETE': {
      const { key } = action;
      const updatedState = editor.deleteProfileType(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'PROFILE_TYPE_RESTORE': {
      const { key } = action;
      const updatedState = editor.restoreProfileType(key, userId);
      return {
        ...state,
        ...updatedState,
      };
    }

    case 'SECTION_REORDER': {
      const { movedKey, bumpedKey } = action;
      const orderPatch = reorderThings(state.scheme.sections)(
        movedKey,
        bumpedKey
      );
      const updatedState = editor.applyPatch(orderPatch, true);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'FIELD_REORDER': {
      const { section, movedKey, bumpedKey } = action;
      const orderPatch = reorderThings(
        state.scheme.getSection(section)?.fields
      )(movedKey, bumpedKey);
      const updatedState = editor.applyPatch(orderPatch, true);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'ARRAYFIELD_REORDER': {
      const { fieldKey, movedKey, bumpedKey } = action;

      const orderPatch = reorderThings(
        state.scheme.getField(fieldKey)?.arrayFields
      )(movedKey, bumpedKey);
      const updatedState = editor.applyPatch(orderPatch, true);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'PROFILE_TYPE_REORDER': {
      const { movedKey, bumpedKey } = action;
      const orderPatch = reorderThings(state.scheme.profileTypes)(
        movedKey,
        bumpedKey
      );
      const updatedState = editor.applyPatch(orderPatch, true);
      return {
        ...state,
        ...updatedState,
      };
    }
    case 'RATING_SYSTEM_REORDER': {
      const { movedKey, bumpedKey } = action;
      const orderPatch = reorderThings(state.scheme.ratingSystems)(
        movedKey,
        bumpedKey
      );
      const updatedState = editor.applyPatch(orderPatch, true);
      return {
        ...state,
        ...updatedState,
      };
    }
    default: {
      return state;
    }
  }
};
