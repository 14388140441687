import { useFormState } from '@hogwarts/ui-components-forms';
import LockableFormDecorator from '../LockableFormDecorator';
import { convertFields } from '../utils';

const customDecoratorRegistry = {
  lockable: LockableFormDecorator,
};

const getFields = (profileType, { profileTypes, iconPack }) =>
  [
    !profileType && {
      key: 'parent',
      type: 'singleselect',
      label: 'Parent Profile Type',
      values: profileTypes
        .filter((p) => !p.parent)
        .map((p) => ({
          id: p.key,
          value: p.label,
        })),
      validate: 'required',
      showEmpty: false,
    },
    profileType?.parent && {
      type: 'label',
      label: `Part of ${profileType.parentItem?.label}`,
    },
    {
      key: 'label',
      label: 'Name',
      type: 'textbox',
      maxLength: 30,
      validate: 'required',
    },
    {
      key: 'description',
      type: 'textarea',
      label: 'Description',
      maxLength: 120,
    },
    {
      key: 'avatar',
      type: 'iconpicker',
      label: 'Avatar',
      componentProps: {
        type: 'profile-type-avatar',
        heading: 'Avatar Selector',
        iconPack,
      },
    },
    {
      key: 'color',
      type: 'colorpicker',
      label: 'Colour',
    },
    {
      key: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      defaultValue: true,
    },
    profileType && {
      type: 'separator',
    },
    profileType && {
      type: 'label',
      label: `Identifier: ${profileType.key}`,
    },
    profileType && {
      type: 'label',
      label: `Owner: ${profileType.meta.owner}`,
    },
  ].filter(Boolean);

export const useProfileTypeEdit = (profileTypes, avatarIconPack) => {
  const forms = useFormState();

  const showEditForm = (profileType, onEdit, onDelete) => {
    const [initialValues, fields] = convertFields(
      getFields(profileType, { profileTypes, iconPack: avatarIconPack }),
      profileType
    );

    const allowDelete = profileType.meta.owned === true;

    forms.showForm({
      title: 'Edit Profile Type',
      initialValues,
      fields,
      decorators: customDecoratorRegistry,
      size: 512,
      onSave: async (values) => {
        return onEdit(values);
      },
      allowDelete,
      onDelete,
      deleteText: profileType.deleted ? 'Restore' : 'Delete',
    });
  };

  const showAddForm = (onAdd) => {
    const [initialValues, fields] = convertFields(
      getFields(null, { profileTypes, iconPack: avatarIconPack })
    );

    forms.showForm({
      title: 'Add Profile Type',
      initialValues,
      fields,
      decorators: customDecoratorRegistry,
      size: 512,
      onSave: async (values) => {
        return onAdd(values);
      },
    });
  };

  return [showAddForm, showEditForm];
};
