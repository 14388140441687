import { NonIdealState } from '@blueprintjs/core';
import { useForms } from '@hogwarts/ui-components-forms';
import join from 'proper-url-join';
import qs from 'qs';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ProfileSummaryWidget from '../../../components/Dashboards/Widgets/ProfileSummary';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useQuery } from '../../../hooks';
import { DATASOURCE_QUERY_PROFILE_SUMMARY } from '../../../queries';

const ProfileSummaryContainer = ({ settings, onSettingsUpdate, ...rest }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },
    ],
    initialValues: settings,
  });
  const { loading, error, data } = useQuery(DATASOURCE_QUERY_PROFILE_SUMMARY, {
    selector: 'queryProfileSummary.result.profileType',
    variables: {
      organisationKey: organisation.key,
    },
    pollInterval: organisation.demo ? 20000 : 60000,
  });
  const countData = useMemo(() => {
    if (data) {
      return Object.keys(data).reduce((prev, curr) => {
        if (
          !settings.includedProfileTypes ||
          settings.includedProfileTypes[curr]
        ) {
          const newScores = {};
          Object.keys(data[curr]).forEach((tagKey) => {
            if (settings.includedTags[tagKey]) {
              newScores[tagKey] = data[curr][tagKey];
            }
          });
          prev[curr] = newScores;
        }
        return prev;
      }, {});
    }
  }, [data, settings.includedProfileTypes, settings.includedTags]);

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      {Object.keys(countData || {}).length ? (
        <ProfileSummaryWidget
          tags={organisation.scheme.tags}
          profileTypes={organisation.scheme.profileTypes}
          profileCounts={countData}
          iconPack={organisation.attributes?.avatarIconPack}
          onProfileTypeClick={(profileKey, tagKey) => {
            analytics.events.dashboard.widget.profilesSummary.profileTypeSelected(
              {
                type: profileKey,
                tags: tagKey,
              }
            );
            const query = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            query.profileTypes = profileKey;
            query.tags = tagKey;
            history.push(
              join(
                location.pathname,
                'profiles',
                qs.stringify(query, {
                  addQueryPrefix: true,
                  arrayFormat: 'repeat',
                })
              )
            );
          }}
        />
      ) : (
        <NonIdealState
          icon="cog"
          title={t('Nothing to show')}
          description={t('You may need to adjust your widget settings')}
        />
      )}
    </WidgetWrapper>
  );
};

export default {
  type: 'profile-summary',
  name: 'Profile Types Summary',
  description:
    'Display the profile type avatars so that you can quickly see profile counts and navigate to those specific types.',
  widgetComponent: ProfileSummaryContainer,
  defaultSettings: (scheme) => ({
    title: 'Profiles Summary',
    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,
  }),
};
