import { Button } from '@blueprintjs/core';
import { Loading } from '@hogwarts/ui-components-core';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import logo from '../../../assets/logo/White-Background@2x.png';
import { getRequest } from '../../../services';
import styles from './verifyCandidate.module.css';

interface CheckDetails {
  id: string;
  isVerifying: boolean;
  isOpen: boolean;
  candidate: {
    name: string;
  };
  organisation: {
    name: string;
  };
}

const CheckFound = ({
  checkDetails,
  onRejected,
  onAccepted,
}: {
  checkDetails: CheckDetails;
  onRejected: () => void;
  onAccepted: () => void;
}) => {
  console.log(checkDetails);
  if (!checkDetails.isOpen) {
    return (
      <>
        <p>This order has been closed.</p>
        <p>No further action is required, you may now close this window.</p>
      </>
    );
  }
  if (!checkDetails.isVerifying && checkDetails.isOpen) {
    return CheckAccepted();
  }
  return (
    <>
      <p>
        Hi, <b>{checkDetails.candidate.name}</b>.
      </p>
      <p>
        You have reached this page because we have been asked to perform
        pre-employment checks by <b>{checkDetails.organisation.name}</b>.
      </p>
      <p>
        You should have been expecting to receive this message, if so could you
        please click accept below and we will begin to process the checks.
      </p>
      <p>
        If we have reached the wrong person, or you weren't expecting{' '}
        <b>{checkDetails.organisation.name}</b> to order these checks then
        please click reject below and we will let them know that something has
        gone wrong.
      </p>
      <div className={styles.verifyButtons}>
        <Button large intent="danger" icon="thumbs-down" onClick={onRejected}>
          Reject Checks
        </Button>
        <Button large intent="success" icon="thumbs-up" onClick={onAccepted}>
          Accept Checks
        </Button>
      </div>
    </>
  );
};

const CheckError = () => {
  return (
    <>
      <p>Something has gone wrong, please try following the link again.</p>
      <p>
        If you continue to experience issues please contact us via email at:{' '}
        <a href="mailto:help@scrtracker.com">help@scrtracker.com</a>
      </p>
    </>
  );
};

const CheckRejected = () => {
  return (
    <>
      <p>We have now stopped the check process. Sorry for any inconvenience.</p>
      <p>
        If you need to contact us for any reason please do so via email at:{' '}
        <a href="mailto:help@scrtracker.com">help@scrtracker.com</a>
      </p>
      <p>You can now close this window.</p>
    </>
  );
};

const CheckAccepted = () => {
  return (
    <>
      <p>Thank you, the order is now confirmed.</p>
      <p>
        We work with{' '}
        <a
          href="https://www.verifile.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Verifile
        </a>{' '}
        to provide our pre-employment checks. You should hear from them shortly
        and they will walk you through the process of completing your checks.
      </p>
      <p>You can now close this window.</p>
    </>
  );
};

const VerifyCandidate = () => {
  const [loading, setLoading] = useState(false);
  const [checkDetails, setCheckDetails] = useState<CheckDetails>();
  const [checkRejected, setCheckRejected] = useState(false);
  const [checkAccepted, setCheckAccepted] = useState(false);
  const [query] = useQueryParams({
    key: StringParam,
  });

  useEffect(() => {
    if (query.key) {
      setLoading(true);
      getRequest({ requireAuth: false })
        .then((request) => {
          return request({
            url: '/checks/verifycandidate',
            params: {
              key: query.key,
            },
          }).then((value) => {
            if (value?.data) {
              setCheckDetails(value.data);
            }
            setLoading(false);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [query.key]);

  return (
    <div className={styles.verifyContainer}>
      <div className={styles.verifyContent}>
        <img className={styles.logo} src={logo} alt="" />
        {loading && <Loading />}
        {!loading && !checkRejected && !checkAccepted && checkDetails && (
          <CheckFound
            checkDetails={checkDetails}
            onRejected={() => {
              setLoading(true);
              getRequest({ requireAuth: false })
                .then((request) => {
                  return request({
                    url: '/checks/verifycandidate/reject',
                    params: {
                      key: query.key,
                    },
                  }).then(() => {
                    setCheckRejected(true);
                    setLoading(false);
                  });
                })
                .catch(() => {
                  setLoading(false);
                });
            }}
            onAccepted={() => {
              getRequest({ requireAuth: false })
                .then((request) => {
                  return request({
                    url: '/checks/verifycandidate/accept',
                    params: {
                      key: query.key,
                    },
                  }).then(() => {
                    setCheckAccepted(true);
                    setLoading(false);
                  });
                })
                .catch(() => {
                  setLoading(false);
                });
            }}
          />
        )}
        {!loading && checkRejected && <CheckRejected />}
        {!loading && checkAccepted && <CheckAccepted />}
        {!loading && !checkRejected && !checkAccepted && !checkDetails && (
          <CheckError />
        )}
      </div>
    </div>
  );
};

export default VerifyCandidate;
