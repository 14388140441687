import { NonIdealState } from '@blueprintjs/core';
import { MultiSelect } from '@hogwarts/ui-components-core';
import { rowFactory } from '@hogwarts/ui-components-list/src/utils';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import { OrganisationContext } from '../../../context';
import styles from './styles.module.css';

export type FetchMethod = (...args: any[]) => Promise<any>;

const useLastFetch = (fetch: FetchMethod): [FetchMethod, boolean] => {
  const currentInvocation = useRef(0);
  const abort = () => {
    currentInvocation.current++;
    setFetching(false);
  };
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    abort();
  }, [fetch]);
  const wrappedFetch = useCallback(
    async (...args) => {
      abort();
      const identifier = currentInvocation.current;
      setFetching(true);
      try {
        const result = await fetch(...args);
        if (currentInvocation.current !== identifier) {
          throw new Error('Aborted');
        }
        return result;
      } finally {
        if (currentInvocation.current === identifier) {
          setFetching(false);
        }
      }
    },
    [fetch]
  );

  return [wrappedFetch, fetching];
};

const DefaultEmptyComponent = () => {
  return <NonIdealState title={'No items available'} />;
};

interface FilterableInfiniteListViewProps {
  className?: string;
  component: any;
  componentProps: any;
  emptyComponent: React.ReactNode;
  spreadRowItem?: boolean;
  border?: boolean;
  divider?: boolean;
  pageSize?: number;
  fetchMore: FetchMethod;
  persistOptions?: (options: object) => void;
  defaultFilters?: String[];
}

const FilterableInfiniteListView = ({
  className,
  component,
  componentProps,
  emptyComponent,
  spreadRowItem = false,
  border = false,
  divider = false,
  pageSize = 25,
  fetchMore: fetchMoreCore,
  persistOptions = () => {},
  defaultFilters = [],
}: FilterableInfiniteListViewProps) => {
  const [rows, setRows] = useState<any[] | null>(null);
  const [filteredRows, setFilteredRows] = useState<any[] | null>(null);
  const [uniqueFields, setUniqueFields] = useState<any[] | null>(null);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [fetchMore, loading] = useLastFetch(fetchMoreCore);
  const organisation = useContext(OrganisationContext);
  const [selectedFields, setSelectedFields] =
    useState<String[]>(defaultFilters);

  const row = useMemo(
    () =>
      rowFactory(component, filteredRows || [], componentProps, {
        divider,
        spreadRowItem,
      }),
    [divider, component, componentProps, filteredRows, spreadRowItem]
  );

  useEffect(() => {
    setRows(null);
    setFilteredRows(null);
    setFullyLoaded(false);
    fetchMore(0, pageSize)
      .then((nextRows) => {
        if (Array.isArray(nextRows) && nextRows.length) {
          const updatedRows = nextRows.map((row) => {
            const expirationField = organisation.scheme.getField(row.fieldKey);
            const expirationFieldLabel =
              expirationField && expirationField.sectionItem
                ? `${expirationField.sectionItem.label}: ${
                    expirationField.label || ''
                  }`
                : '';

            return {
              ...row,
              sectionLabel: expirationField?.sectionItem?.label,
              expirationFieldLabel: expirationFieldLabel,
            };
          });

          // Filter for unique fieldKeys
          const uniqueFields = uniqBy(updatedRows, 'fieldKey');
          setUniqueFields(uniqueFields);
          setRows(updatedRows);
        }
      })
      .catch((e) => {
        // Ignore for now
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, organisation.scheme]);

  useEffect(() => {
    if (rows) {
      const filtered =
        selectedFields.length > 0
          ? rows.filter((row) => selectedFields.includes(row.fieldKey))
          : rows;
      setFilteredRows(filtered);
    }
  }, [selectedFields, rows]);

  const tryGetMoreRows = useCallback(async () => {
    if (loading || fullyLoaded || !rows) return;
    let nextRows;
    try {
      nextRows = await fetchMore(rows.length, pageSize);
    } catch (e) {
      // Ignore for now.
      return;
    }
    if (Array.isArray(nextRows)) {
      if (nextRows.length < pageSize) {
        setFullyLoaded(true);
      }
      if (nextRows.length) {
        setRows([...rows, ...nextRows]);
      }
    } else {
      setFullyLoaded(true);
    }
  }, [fetchMore, fullyLoaded, loading, pageSize, rows]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      {rows && (
        <MultiSelect
          items={uniqueFields}
          valueField="fieldKey"
          textField="expirationFieldLabel"
          onChange={(selectedItems: any[]) => {
            setSelectedFields(selectedItems);
            persistOptions({ filters: selectedItems });
          }}
          placeholder="Select fields..."
          selectedItems={selectedFields}
        />
      )}
      <Virtuoso
        data={filteredRows || []}
        className={cn(styles.list, border && styles.listBorders, className)}
        itemContent={row}
        endReached={tryGetMoreRows}
        overscan={pageSize}
        emptyComponent={emptyComponent ?? DefaultEmptyComponent}
        components={{
          Footer: () => {
            if (!loading || fullyLoaded) return null;
            return (
              <div
                style={{
                  padding: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Loading...
              </div>
            );
          },
        }}
      />
    </div>
  );
};

export default FilterableInfiniteListView;
