import React from 'react';
import { SelectList } from 'react-widgets';

const valueField = 'id';

const MultiselectList = ({
  formField,
  fieldHelpers,
  readOnly,
  values: unparsedValues,
}) => {
  const values = unparsedValues.filter(Boolean).map((v) => {
    if (typeof v === 'string') {
      return {
        id: v,
        value: v,
      };
    }
    return v;
  });
  return (
    <SelectList
      style={{ minHeight: '150px', maxHeight: '300px' }}
      multiple
      valueField={valueField}
      textField={'value'}
      defaultValue={[]}
      closeOnSelect={false}
      data={values}
      onChange={(changedValues) => {
        const values2 = changedValues
          .filter((v) => {
            if (v == null) return false;
            if (typeof v === 'string') return false;
            return true;
          })
          .map((v) => v[valueField]);
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(values2);
      }}
      onBlur={formField.onBlur}
      readOnly={readOnly}
      value={formField.value || []} // requires value to be an array
    />
  );
};

export default {
  name: 'Multi-select List',
  options: [],
  label: true,
  Component: MultiselectList,
};
