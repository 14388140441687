import React from 'react';
import { Intent, Callout, Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST } from '@/queries';
import { useHistory } from 'react-router-dom';

const TransfersWarning = ({ organisationId, organisationKey }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useQuery(GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST, {
    variables: { targetOrganisationId: organisationId },
  });
  if (
    data &&
    data.profileOrganisationChangeRequests &&
    data.profileOrganisationChangeRequests.length > 0
  ) {
    return (
      <Callout intent={Intent.WARNING}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {t('This organisation has profile transfers awaiting approval.')}
          </div>
          <div>
            <Button
              intent={Intent.PRIMARY}
              onClick={() => {
                history.push(`/${organisationKey}/profiles/transfers`);
              }}
              style={{ minWidth: '120px' }}
            >
              {t('View Transfers')}
            </Button>
          </div>
        </div>
      </Callout>
    );
  }
  return null;
};

export default TransfersWarning;
