import { NonIdealState } from '@blueprintjs/core';
import React from 'react';
import {
  SettingCategory,
  SettingMenuItem,
  SettingsMenu,
} from '../SettingsMenu';

export interface SettingItem extends SettingMenuItem {
  Component?: React.FC | React.FC[];
}
interface SettingsLayoutProps {
  categories: SettingCategory[];
  items: SettingItem[];
  selected?: string;
  onSelected: (key: string) => void;
}
export const SettingsLayout = ({
  categories,
  items,
  selected,
  onSelected,
  ...props
}: SettingsLayoutProps) => {
  const selectedItem =
    selected && items.find((i) => `${i.category}.${i.key}` === selected);

  return (
    <div className={'d-flex flex-row'}>
      <div className={'mr-3'}>
        <SettingsMenu
          categories={categories}
          items={items}
          onSelected={onSelected}
          selected={selected}
        />
      </div>

      <div className={'flex-grow-1 justify-content-start flex-column w-100'}>
        {!selectedItem ? (
          <NonIdealState
            icon="info-sign"
            title={'Nothing Selected'}
            description={'Select a menu item on the left'}
          />
        ) : selectedItem.restricted ? (
          <NonIdealState
            icon="lock"
            title={'Sorry! Access Denied'}
            description={
              'You do not have the required permissions to view this page'
            }
          />
        ) : selectedItem.Component ? (
          Array.isArray(selectedItem.Component) ? (
            selectedItem.Component.map((Component) => <Component {...props} />)
          ) : (
            <selectedItem.Component {...props} />
          )
        ) : (
          <NonIdealState icon="warning-sign" title={'No Component Specified'} />
        )}
      </div>
    </div>
  );
};
