import { GridView } from '@/components';
import { OrganisationContext } from '@/context';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  dateFormatter,
  gridOptions as defaultGridOptions,
} from '../../../profilesGridUtils';

const gridOptions = {
  ...defaultGridOptions,
  rowSelection: 'single',
  sideBar: false,
  sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 100,
    filter: false,
    editable: false,
    suppressMenu: true,
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
};

const getRowNodeId = (data) => data.cacheKey || data.id;

const createFields = (organisation, t) => {
  function ratingGetterFactory(ratingSystemKey) {
    return function getter({ data }) {
      const ratings = data?.ratings;
      if (ratings) {
        const system = ratings.find((r) => r.key === ratingSystemKey);
        if (system) {
          return system.ready;
        }
      }
      return false;
    };
  }

  const { ratingSystems } = organisation.scheme;
  return [
    {
      key: 'tags',
      label: 'Tags',
      width: 75,
      cellRenderer: 'tagRenderer',
      cellStyle: {
        lineHeight: '25px',
        padding: '1px',
      },
    },
    {
      key: 'firstname',
      label: 'First Name',
      dataField: true,
      sort: 'asc',
    },
    {
      key: 'lastname',
      label: 'Last Name',
      dataField: true,
    },
    {
      key: 'typeKey',
      label: 'Profile Type',
      width: 150,
      valueFormatter: (params) => {
        const typeKey = params.value;
        const profileType = organisation.scheme.getProfileType(typeKey);
        if (!profileType) return typeKey;
        return profileType.label;
      },
    },
    {
      key: 'dbs_renewal_date',
      valueFormatter: dateFormatter,
      label: 'Renewal Date',
      width: 150,
      dataField: true,
    },
    ...ratingSystems.map((ratingSystem) => ({
      key: `rs_${ratingSystem.key}`,
      valueGetter: ratingGetterFactory(ratingSystem.key),
      label: t(ratingSystem.label),
      cellRenderer: 'booleanRenderer',
      filter: 'agTextColumnFilter',
      minWidth: 50,
      maxWidth: 125,
      suppressSorting: true,
    })),
  ].map((field) => {
    if (!field.dataField) return field;
    return {
      ...field,
      valueGetter: ({ data: rowData }) => {
        let profileData = rowData?.data;
        if (!profileData) {
          return null;
        }
        return profileData[field.key];
      },
    };
  });
};

const ProfilesList = ({ profiles }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const organisation = useContext(OrganisationContext);
  const fields = useMemo(
    () => createFields(organisation, t),
    [organisation, t]
  );

  const showProfilePage = (params) => {
    const { id } = params.data || {};
    if (id) {
      history.push(`/${organisation.key}/profiles/${id}`);
    }
  };

  return (
    <GridView
      getRowNodeId={getRowNodeId}
      onRowClicked={showProfilePage}
      groups={[]}
      columns={fields}
      rows={profiles}
      showSideBar={false}
      {...gridOptions}
    />
  );
};

export default ProfilesList;
