import React, { useCallback, useContext } from 'react';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMemoizeArgs, useTransformProfiles } from '../../../hooks';

import { useApolloClient } from '@apollo/client';
import { useForms } from '@hogwarts/ui-components-forms';
import { useHistory } from 'react-router-dom';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import ProfilesListWidget from '../../../components/Dashboards/Widgets/ProfilesList';
import { DATASOURCE_QUERY_PROFILES_WITHOUT_ORG } from '../../../queries';

const ProfilesListContainer = ({
  settings: proposedSettings,
  onSettingsUpdate,
  ...rest
}) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const apollo = useApolloClient();

  const settings = useMemoizeArgs(proposedSettings);

  const transformProfiles = useTransformProfiles();

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        key: 'condition',
        type: 'conditional',
        label: 'Filter Logic',
        scheme: organisation?.scheme,
      },
      {
        type: 'separator',
      },
      {
        key: 'show_avatar',
        type: 'checkbox',
        label: 'Show Avatar',
        large: false,
      },
      {
        type: 'separator',
      },
      // {
      //   key: 'show_startdate',
      //   type: 'checkbox',
      //   label: 'Show Start Date',
      //   large: false,
      // },
      // {
      //   key: 'show_leavedate',
      //   type: 'checkbox',
      //   label: 'Show Leave Date',
      //   large: false,
      // },
      {
        key: 'show_field_1',
        type: 'fieldselect',
        label: 'Show Optional Field 1',
        scheme: organisation?.scheme,
      },
      {
        key: 'show_field_2',
        type: 'fieldselect',
        label: 'Show Optional Field 2',
        scheme: organisation?.scheme,
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },

      {
        type: 'separator',
      },

      ...organisation.scheme.ratingSystems.reduce((prev, rs) => {
        return [
          ...prev,
          {
            type: 'title',
            label: rs.label,
          },
          {
            key: `ratings.${rs.key}`,
            type: 'singleselect',
            validate: 'required',
            values: [
              {
                id: 'all',
                value: 'Show All',
              },
              {
                id: 'exclude',
                value: 'Show not ready',
              },
              {
                id: 'include',
                value: 'Show Ready',
              },
            ],
          },
        ];
      }, []),

      // {
      //   key: 'additional_field1',
      //   label: 'Show Additional Field',
      //   type: 'fieldselect',
      //   scheme: organisation?.scheme,
      //   itemsDescription: 'Nothing selected',
      //   validate: null,
      // },
    ],
    initialValues: {
      includeRatingSystems: {},
      ...settings,
    },
    // onShow: () => {
    //   if (stopPolling) {
    //     stopPolling();
    //   }
    // },
    // onHide: () => {
    //   if (startPolling) {
    //     startPolling();
    //   }
    // },
  });

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) return;
      const variables = {
        organisationKey: organisation.key,
        condition: settings.condition,

        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((tagKey) =>
            settings.includedProfileTypes[tagKey] ? tagKey : null
          )
          .filter(Boolean),

        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),

        ratings: Object.keys(settings.ratings || {})
          .map((systemKey) => {
            switch (settings.ratings[systemKey]) {
              case 'exclude': {
                return {
                  key: systemKey,
                  include: false,
                };
              }
              case 'include': {
                return {
                  key: systemKey,
                  include: true,
                };
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean),

        skip,
        limit,
        includeDeleted: false,
        sort: [{ field: 'name', desc: false }],
      };

      let profiles = await apollo.query({
        query: DATASOURCE_QUERY_PROFILES_WITHOUT_ORG,
        fetchPolicy: 'no-cache',
        variables: {
          ...variables,
          skip,
          limit,
          // sort,
        },
      });
      profiles = profiles?.data?.queryProfiles?.result;
      profiles = transformProfiles(profiles);
      return profiles;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings]
  );

  let fields = [];

  // Legacy support
  if (settings.show_startdate) {
    fields.push('startdate');
  }
  if (settings.show_leavedate) {
    fields.push('leavedate');
  }

  if (settings.show_field_1) {
    fields.push(settings.show_field_1);
  }

  if (settings.show_field_2) {
    fields.push(settings.show_field_2);
  }

  return (
    <WidgetWrapper
      title={settings.title}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      disableScroll
      {...rest}
    >
      <ProfilesListWidget
        organisationKey={organisation.key}
        fetchMore={fetchMore}
        fields={fields}
        showAvatar={settings.show_avatar}
        iconPack={organisation.attributes?.avatarIconPack}
        onProfileSelected={(profileId) => {
          analytics.events.search.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}`);
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'profiles-list',
  name: 'Profiles List',
  description:
    'Displays a list of profiles that match the specified filter logic.',
  widgetComponent: ProfilesListContainer,
  defaultSettings: (scheme) => ({
    title: 'Profile List',
    condition: {
      conditions: [],
      operator: 'and',
      enabled: true,
    },
    show_avatar: true,
    show_field_1: null,
    show_field_2: null,

    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,

    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
};
