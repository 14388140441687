import { gql } from '@apollo/client';

export interface EXPORT_REPORT_Type {
  reportId: string;
  exportType?: string;
  reportParams?: any;
}

export interface exportExternalChecksReportType {
  ownerId: string;
  ownerType: string;
  dateRange: [Date, Date];
}
export const EXPORT_REPORT = gql`
  mutation exportReport(
    $reportId: ID!
    $exportType: String
    $reportParams: JSON
  ) {
    exportReport(id: $reportId, exportType: $exportType, params: $reportParams)
  }
`;

export const EXPORT_EXTERNAL_CHECKS_REPORT = gql`
  mutation exportExternalChecksReport(
    $ownerId: String!
    $ownerType: String!
    $dateRange: [DateTime]!
  ) {
    exportExternalChecksReport(
      ownerId: $ownerId
      ownerType: $ownerType
      dateRange: $dateRange
    )
  }
`;

export const CREATE_REPORT = gql`
  mutation createReport(
    $name: String!
    $description: String
    $sourceType: String!
    $viewType: String!
    $exportType: [String!]
    $ownerType: String!
    $ownerId: ID
    $options: JSON!
  ) {
    createReport(
      name: $name
      description: $description
      sourceType: $sourceType
      viewType: $viewType
      exportType: $exportType
      ownerType: $ownerType
      ownerId: $ownerId
      options: $options
    ) {
      id
      name
      description
      sourceType
      viewType
      exportType
      options
      ownerType
      ownerId
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation updateReport(
    $reportId: ID!
    $name: String
    $description: String
    $ownerType: String
    $ownerId: ID
    $options: JSON
  ) {
    updateReport(
      id: $reportId
      name: $name
      description: $description
      ownerType: $ownerType
      ownerId: $ownerId
      options: $options
    ) {
      id
      name
      description
      sourceType
      viewType
      exportType
      options
      ownerType
      ownerId
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteReport($reportId: ID!) {
    deleteReport(id: $reportId)
  }
`;

export interface UPDATE_REPORT_OPTIONS_Type {
  reportId: string;
  reportOptions: any;
}
export const UPDATE_REPORT_OPTIONS = gql`
  mutation updateReport($reportId: ID!, $reportOptions: JSON!) {
    updateReport(id: $reportId, options: $reportOptions) {
      id
      name
      description
      sourceType
      viewType
      exportType
      options
      ownerType
      ownerId
    }
  }
`;
