import React from 'react';
import { Spinner } from '@blueprintjs/core';

const LoadingComponent = () => {
  return <Spinner intent="primary" size="30" />;
};

export default {
  name: 'Loading',
  Component: LoadingComponent,
};
