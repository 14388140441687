import { Divider } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './styles.css';
import styles from './styles.module.css';

interface Props {
  children: (props: { openMenu: () => void }) => React.ReactNode;
  options: {
    heading: string;
    description: string;
    icon: any;
    onClick: () => void;
    restricted?: boolean | string;
  }[];
}

const AddMenu = ({ children, options }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover2
      position="bottom"
      popoverClassName="addMenuPopover"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <div className={styles.addMenu}>
          <p className={styles.addMenuHeading}>Add</p>
          <Divider />
          {options &&
            options.filter(Boolean).map((option, idx) => (
              <Tooltip2
                key={idx}
                content={
                  typeof option.restricted === 'string'
                    ? option.restricted
                    : undefined
                }
              >
                <div
                  className={
                    option.restricted ? styles.disabledOption : styles.addOption
                  }
                  onClick={() => {
                    if (option.restricted) return;
                    setIsOpen(false);
                    option.onClick();
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    fixedWidth
                    icon={option.restricted ? ['far', 'lock'] : option.icon}
                    className={styles.addOptionIcon}
                  />
                  <div className={styles.addOptionTextWrapper}>
                    <p className={styles.addOptionHeading}>{option.heading}</p>
                    <p className={styles.addOptionDescription}>
                      {option.description}
                    </p>
                  </div>
                </div>
              </Tooltip2>
            ))}
        </div>
      }
    >
      {children({ openMenu: () => setIsOpen(true) })}
    </Popover2>
  );
};

export default AddMenu;
