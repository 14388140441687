import { Popover } from '@blueprintjs/core';
import React, { useState } from 'react';
import ProfileTypeIcon from '../ProfileTypeIcon';
import styles from './styles.module.css';

const ProfileTypeOption = ({
  name,
  avatar,
  iconPack,
  parentName,
  description,
  onClick,
}) => {
  return (
    <div className={styles.profileTypeOption} onClick={onClick}>
      <ProfileTypeIcon avatarKey={avatar} iconPack={iconPack} width="70px" />
      <div className={styles.profileTypeText}>
        <p className={styles.profileTypeName}>{name}</p>
        {parentName && (
          <p className={styles.profileTypeParentName}>part of {parentName}</p>
        )}
        <p className={styles.profileTypeDescription}>{description}</p>
      </div>
    </div>
  );
};

const ProfileTypePicker = ({
  profileTypes,
  iconPack,
  position = 'bottom',
  children,
  onSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      position={position}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      fill
      popoverClassName={styles.popover}
    >
      {children({ openMenu: () => setIsOpen(true) })}
      <div className={styles.pickerOptionsContainer}>
        {profileTypes &&
          profileTypes.map((profileType) => (
            <ProfileTypeOption
              key={profileType.key}
              name={profileType.name}
              avatar={profileType.avatar}
              iconPack={iconPack}
              description={profileType.description}
              parentName={profileType.parentName}
              onClick={() => {
                onSelected(profileType.key);
                setIsOpen(false);
              }}
            />
          ))}
      </div>
    </Popover>
  );
};

export default ProfileTypePicker;
