import { Tag } from '@blueprintjs/core';
import React from 'react';

function getContrastingTextColor(hexColor: string) {
  if (!hexColor) {
    return '';
  }
  if (!hexColor.match(/^#([0-9a-f]{3}){1,2}$/i)) {
    return '';
  }

  // Remove any leading '#' character if present
  hexColor = hexColor.replace(/^#/, '');

  // Parse the hex color into RGB components
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate brightness using the same formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 150 ? 'black' : 'white';
}

interface Props {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

const ActivityTag = ({ color, className, children }: Props) => {
  return (
    <Tag
      className={className}
      style={{
        backgroundColor: color,
        color: color ? getContrastingTextColor(color) : '',
      }}
    >
      {children}
    </Tag>
  );
};

export default ActivityTag;
