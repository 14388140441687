import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import SchemeListItem from '../../SchemeListItem';

const SchemeSettings = ({ loading, error, schemes, onSchemeSelected }) => {
  const { t } = useTranslation();

  // TODO: Need a permission check!
  const allowed = true;

  return (
    <SettingsPanel loading={loading} error={error} title="Template Editing">
      <p>
        {t(`Templates give you control over the fine details of how your SCR
        instance is configured. This is an advanced feature so it would
        be best to read our help guides on this topic to see
        what you're able to achieve and how you might approach it.`)}
      </p>
      {schemes?.length > 1 && (
        <p>
          {t(`You have multiple Templates that can be edited, please
          select the one you need to modify below.`)}
        </p>
      )}
      <div>
        {schemes?.map((scheme) => (
          <SchemeListItem
            key={scheme.id}
            name={scheme.name}
            allowed={allowed}
            onEdit={() => onSchemeSelected(scheme.id)}
          />
        ))}
      </div>
    </SettingsPanel>
  );
};

export default SchemeSettings;
