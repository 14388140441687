import React from 'react';
import ReactJson from 'react-json-view';

const ObjectComponent = ({ formField, readOnly }) => {
  return (
    <ReactJson
      className="form-control"
      onBlur={formField.onBlur}
      src={formField.value || {}}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'JSON Object',
  options: [],
  label: true,
  Component: ObjectComponent,
};
