import { useApolloClient, useMutation } from '@apollo/client';
import { NonIdealState } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { Error } from '@hogwarts/ui-components-core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context';
import { useQuery } from '../../hooks';
import { UPDATE_USER_LAST_AUTH } from '../../mutations';
import { GET_CURRENT_USER, GET_CURRENT_USER_Response } from '../../queries';
import authenticationService from '../../services/authentication';
import { User } from '../../types';
import { hasPermissionFactory } from '../../utils';
import Login from '../login';
import Header from './header';
import LocateOrganisationContent from './LocateOrganisationContent';

const App = () => {
  const { t, i18n } = useTranslation();
  const client = useApolloClient();

  const isAuthenticated = authenticationService.isAuthenticated();

  const {
    loading,
    data: userQuery,
    error,
  } = useQuery<GET_CURRENT_USER_Response>(GET_CURRENT_USER, {
    skip: !isAuthenticated,
    fetchPolicy: 'network-only',
    selector: 'user',
  });

  const [updateUserLastAuth] = useMutation(UPDATE_USER_LAST_AUTH);

  const user = useMemo(
    () => (isAuthenticated ? userQuery : undefined),
    [isAuthenticated, userQuery]
  );

  const userContext = useMemo<User | undefined>(() => {
    if (!user) return;
    const hasPermission = hasPermissionFactory(user.permissions);
    return {
      ...user,
      hasPermission,
      isSupportAdmin: hasPermission(permissions.SUPPORT_ADMINISTRATOR),
    };
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated) {
      client?.clearStore();
    }
  }, [client, isAuthenticated]);

  useEffect(() => {
    const defaultLanguageKey = user?.preferences?.defaultLanguageKey;
    if (!defaultLanguageKey) return;
    i18n.changeLanguage(defaultLanguageKey);
  }, [i18n, user]);

  useEffect(() => {
    if (isAuthenticated && user) {
      updateUserLastAuth();
    }
  }, [isAuthenticated, updateUserLastAuth, user]);

  if (!isAuthenticated || loading) {
    return <Login loading={loading} />;
  }

  if (error) {
    return (
      <>
        <Header showSearch={false} />
        <Error
          icon="error"
          title={t('Error retrieving your User Information')}
        />
      </>
    );
  }

  if (!user) {
    // Is this still possible?
    return (
      <>
        <Header showSearch={false} />
        <NonIdealState
          icon="error"
          title={t('Your account has become unlinked')}
          description={t<string>(
            'Please refresh, if this continues then contact support'
          )}
        />
      </>
    );
  }

  if (!user.enabled) {
    return (
      <>
        <Header showSearch={false} />
        <NonIdealState
          icon="error"
          title={t('Your account is currently disabled')}
          description={t<string>(
            'Please refresh, if this continues then contact support'
          )}
        />
      </>
    );
  }

  return (
    <UserContext.Provider value={userContext!}>
      <LocateOrganisationContent />
    </UserContext.Provider>
  );
};

export default App;
