import React from 'react';

import { HTMLTable } from '@blueprintjs/core';
import styles from '../styles.module.css';

interface StatusTabProps {
  connected: boolean;
  provider: any;
  messages?: {
    label: string;
    value: string;
  }[];
}
export const StatusTab = ({
  connected,
  provider,
  messages,
}: StatusTabProps) => {
  return (
    <div style={{ padding: '10px' }}>
      <>
        <div className={styles.profileTypeDetails}>
          <img src={provider.logo} style={{ height: '75px' }} alt="" />
        </div>
        {connected && (
          <HTMLTable condensed className="mt-3">
            <tbody>
              {messages &&
                messages.map(({ label, value }, index) => (
                  <tr key={index}>
                    <td className="text-nowrap">
                      <b>{label}:</b>
                    </td>
                    <td>
                      <div className={styles.messageValueContainer}>
                        <div className={styles.messageValue}>{value}</div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </HTMLTable>
        )}
      </>
    </div>
  );
};
