import { Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import {
  // @ts-ignore
  sortableContainer,
  SortableElement,
  // @ts-ignore
  sortableHandle,
} from 'react-sortable-hoc';
import { FieldMapItem } from './FieldMapItem';
import styles from './styles.module.css';

import { Field, MappedField } from './types';

const arrayMoveMutate = <T,>(array: T[], from: number, to: number): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMove = <T,>(array: T[], from: number, to: number): T[] => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

const DragHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

const SortableItem = SortableElement((props: any) => {
  return (
    <Card className={'d-flex flex-row p-2'}>
      <DragHandle />
      <div className={'pl-3 w-100'}>
        <FieldMapItem {...props} />
      </div>
    </Card>
  );
});

const SortableContainer = sortableContainer(
  ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
  }
);

const iconLookup: Record<string, string> = {
  string: 'font',
  number: 'numerical',
  date: 'calendar',
  boolean: 'segmented-control',
  array: 'array',
};
const defaultIcon = 'help';

const applyIcons = (fields: Field[]) => {
  return fields.map((f) => {
    let icon = defaultIcon;
    if (f.dataType) {
      icon = iconLookup[f.dataType];
      if (!icon) {
        icon = defaultIcon;
      }
    }
    return { ...f, icon };
  });
};

interface FieldMapListProps {
  sourceFields: Field[];
  targetFields: Field[];
  targetGroups: unknown[];
  targetGroupKey: string;
  items: MappedField[];
  onChanged: (items: MappedField[]) => void;
}
export const FieldMapList = ({
  sourceFields: initialSourceFields,
  targetFields: initialTargetFields,
  targetGroups,
  targetGroupKey,
  items: initialItems,
  onChanged,
}: FieldMapListProps) => {
  const sourceFields = useMemo(
    () => applyIcons(initialSourceFields),
    [initialSourceFields]
  );
  const targetFields = useMemo(
    () => applyIcons(initialTargetFields),
    [initialTargetFields]
  );

  const [items, setItems] = useState<MappedField[]>(initialItems);

  useEffect(() => {
    // Initial Items changed so we reset it
    setItems(initialItems);
  }, [initialItems]);

  const updateItems = (updatedItems: MappedField[]) => {
    setItems(updatedItems);
    if (onChanged) {
      onChanged(updatedItems);
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const updatedItems = arrayMove(items, oldIndex, newIndex);
    updateItems(updatedItems);
  };

  const onSourceChanged = (index: number) => (sourceField: string) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      sourceField,
    };
    updateItems(updatedItems);
  };
  const onTargetChanged = (index: number) => (targetField: string) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      targetField,
    };
    updateItems(updatedItems);
  };
  const onReadOnlyChanged = (index: number) => (readOnly: boolean) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      readOnly,
    };
    updateItems(updatedItems);
  };
  const onDeleted = (index: number) => () => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    updateItems(updatedItems.filter(Boolean));
  };
  const onTransformChanged =
    (index: number) => (transform: string | undefined) => {
      const updatedItems = [...items];
      updatedItems[index] = {
        ...updatedItems[index],
        transform,
      };
      updateItems(updatedItems);
    };
  return (
    <SortableContainer
      helperClass={styles.sortableHelper}
      useDragHandle
      axis="y"
      onSortEnd={onSortEnd}
    >
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          sourceFields={sourceFields}
          targetFields={targetFields}
          targetGroups={targetGroups}
          targetGroupKey={targetGroupKey}
          onSourceChange={onSourceChanged(index)}
          onTargetChange={onTargetChanged(index)}
          onReadOnlyChange={onReadOnlyChanged(index)}
          onTransformChange={onTransformChanged(index)}
          onDelete={onDeleted(index)}
          {...item}
        />
      ))}
    </SortableContainer>
  );
};
