import trackEvent from '../trackEvent';

export const hideChecksClicked = (traits = {}) => {
  trackEvent('Advanced Editor - Hide Checks Clicked', traits, true);
};

export const turnOffReadOnlyClicked = (traits = {}) => {
  trackEvent('Advanced Editor - Turn Off ReadOnly Clicked', traits, true);
};

export const deleteSingleProfileClicked = (traits = {}) => {
  trackEvent('Advanced Editor - Delete Single Profile Clicked', traits, true);
};

export const deleteMultipleProfileClicked = (traits = {}) => {
  trackEvent(
    'Advanced Editor - Delete Multiple Profiles Clicked',
    traits,
    true
  );
};

export const mergeProfilesClicked = (traits = {}) => {
  trackEvent('Advanced Editor - Merge Profiles Clicked', traits, true);
};

export const viewProfileClicked = (traits = {}) => {
  trackEvent('Advanced Editor - View Profile Clicked', traits, true);
};
