import {
  Button,
  IconName,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core';
import React, { useState } from 'react';

interface Action {
  key: string;
  intent: Intent;
  icon: IconName;
  label: string;
  onClick: (value?: any) => void;
}
interface ActionsRendererProps {
  data: Record<string, any>;
  colDef: {
    field: string;
    meta: {
      actions: Action[] | ((value: any, data: Record<string, any>) => Action[]);
    };
  };
}
const ActionsRenderer = ({ data, colDef }: ActionsRendererProps) => {
  const { meta } = colDef;
  const [actions, setActions] = useState<Action[]>([]);
  const refreshActions = () => {
    if (typeof meta.actions === 'function') {
      setActions(meta.actions(data[colDef.field], data));
    } else {
      setActions(meta.actions);
    }
  };

  return (
    <Popover
      onOpening={refreshActions}
      content={
        <Menu>
          {actions?.length ? (
            actions.map((action, index) => (
              <MenuItem
                key={action.key || `Action_${index}`}
                intent={action.intent}
                icon={action.icon}
                text={action.label}
                onClick={() => {
                  action.onClick(data);
                }}
              />
            ))
          ) : (
            <MenuItem text={'No Items'} disabled={true} />
          )}
        </Menu>
      }
      position={Position.RIGHT_TOP}
    >
      <Button icon="more" />
    </Popover>
  );
};

export default ActionsRenderer;
