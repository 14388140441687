import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { NonIdealState } from '@blueprintjs/core';
import cn from 'classnames';
import { Virtuoso } from 'react-virtuoso';
import styles from './styles.module.css';
import { rowFactory } from './utils';

export type FetchMethod = (...args: any[]) => Promise<any>;
const useLastFetch = (fetch: FetchMethod): [FetchMethod, boolean] => {
  const currentInvocation = useRef(0);
  const abort = () => {
    currentInvocation.current++;
    setFetching(false);
  };
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    abort();
  }, [fetch]);
  const wrappedFetch = useCallback(
    async (...args) => {
      abort();
      const identifier = currentInvocation.current;
      setFetching(true);
      try {
        const result = await fetch(...args);
        if (currentInvocation.current !== identifier) {
          throw new Error('Aborted');
        }
        return result;
      } finally {
        if (currentInvocation.current === identifier) {
          setFetching(false);
        }
      }
    },
    [fetch]
  );

  return [wrappedFetch, fetching];
};

const DefaultEmptyComponent = () => {
  return <NonIdealState title={'No items available'} />;
};

interface InfiniteListViewProps {
  className?: string;
  component: any;
  componentProps: any;
  emptyComponent?: React.ComponentType;
  spreadRowItem?: boolean;
  border?: boolean;
  divider?: boolean;
  pageSize?: number;
  fetchMore: FetchMethod;
}
export const InfiniteListView = ({
  className,
  component,
  componentProps,
  emptyComponent = DefaultEmptyComponent,
  spreadRowItem = false,
  border = false,
  divider = false,
  pageSize = 25,
  fetchMore: fetchMoreCore,
}: InfiniteListViewProps) => {
  const [rows, setRows] = useState<any[] | null>();
  const [fullyLoaded, setFullyLoaded] = useState(false);

  const [fetchMore, loading] = useLastFetch(fetchMoreCore);

  const row = useMemo(
    () =>
      rowFactory(component, rows || [], componentProps, {
        divider,
        spreadRowItem,
      }),
    [divider, component, componentProps, rows, spreadRowItem]
  );

  useEffect(() => {
    setRows(null);
    setFullyLoaded(false);
    fetchMore(0, pageSize)
      .then((nextRows) => {
        if (Array.isArray(nextRows) && nextRows.length) {
          setRows([...nextRows]);
        }
      })
      .catch((e) => {
        // Ignore for now
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore]);

  const tryGetMoreRows = useCallback(async () => {
    if (loading || fullyLoaded || !rows) return;
    let nextRows;
    try {
      nextRows = await fetchMore(rows.length, pageSize);
    } catch (e) {
      // Ignore for now.
      return;
    }
    if (Array.isArray(nextRows)) {
      if (nextRows.length < pageSize) {
        setFullyLoaded(true);
      }
      if (nextRows.length) {
        setRows([...rows, ...nextRows]);
      }
    } else {
      setFullyLoaded(true);
    }
  }, [fetchMore, fullyLoaded, loading, pageSize, rows]);

  return (
    <Virtuoso
      data={rows || []}
      className={cn(styles.list, border && styles.listBorders, className)}
      itemContent={row}
      endReached={tryGetMoreRows}
      overscan={pageSize}
      components={{
        EmptyPlaceholder: emptyComponent,
        Footer: () => {
          if (!loading || fullyLoaded) return null;
          return (
            <div
              style={{
                padding: '2rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Loading...
            </div>
          );
        },
      }}
    />
  );
};
