import { Card, NonIdealState } from '@blueprintjs/core';
import React, { useContext, useMemo, useState } from 'react';

import AddButton from '../AddButton';
import FieldLibrary from '../FieldLibrary';
import { OrganisationContext } from '../../../context';
import ProfileTypeSelector from '../ProfileTypeSelector';
import SectionButton from '../SectionButton';
import SectionEditor from '../SectionEditor';
import SortableList from '../SortableList';
import TabWrapper from './TabWrapper';
import { UserContext } from '@/context';
import { isFeatureEnabled } from '../../../hooks';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const ProfileSectionsTab = ({
  currentScheme,
  sections,
  selectedSection,
  selectedField,
  selectedFieldKey,
  ratingSystems,
  fieldComponents,
  profileTypes,
  selectedProfileType,
  onProfileTypeChange,
  onSectionSelected,
  onSectionOrderUpdate,
  onAddSection,
  onFieldEdit,
  onFieldSelected,
  onFieldOrderUpdate,
  onSectionEdit,
  onSectionUpdate,
  onFieldUpdate,
  onSectionDelete,
  onSectionRestore,
  onFieldDelete,
  onFieldRestore,
  visibilityOptions,
}) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);

  const arrayTypeFieldText = useMemo(() => {
    if (!selectedFieldKey) return 'FIELDS';

    const [fieldKey, parentKey] = selectedFieldKey?.split('.');

    const field = parentKey
      ? currentScheme.getField(parentKey)
      : currentScheme.getField(fieldKey);

    if (field?.dataType === 'array') {
      return `ARRAYFIELD.${parentKey || fieldKey}`;
    }
    return 'FIELDS';
  }, [currentScheme, selectedFieldKey]);

  const enabledFieldComponents = useMemo(() => {
    return fieldComponents.filter((component) => {
      if (component.featureKey) {
        return isFeatureEnabled(component.featureKey, organisation);
      }
      return true;
    });
  }, [fieldComponents, organisation]);

  return (
    <>
      <SortableList
        header={
          user.isSupportAdmin ? (
            <ProfileTypeSelector
              profileTypes={profileTypes}
              selectedProfileType={selectedProfileType}
              onProfileTypeChange={onProfileTypeChange}
            />
          ) : null
        }
        items={sections.map((section) => ({
          component: SectionButton,
          key: section.key,
          name: section.label,
          icon: section.icon,
          deleted: section.deleted,
          sectionDisabled: !section.inUse, // enabled,
          color: section.color,
          selected: section.key === selectedSection?.section?.key,
          onClick: () => onSectionSelected(section.key),
          onDoubleClick: () => onSectionEdit(section.key),
        }))}
        extraItems={<AddButton height="80px" onClick={onAddSection} />}
        onOrderUpdate={({ oldIndex, newIndex }) => {
          const movedKey = sections[oldIndex].key;
          const bumpedKey = sections[newIndex].key;
          onSectionOrderUpdate(movedKey, bumpedKey);
        }}
      />
      {!selectedSection && (
        <Card elevation="1" className={styles.fullSideBar}>
          <NonIdealState
            style={{ justifyContent: 'start' }}
            icon="info-sign"
            description={t(
              "Select a section on the left to edit it's configuration"
            )}
          />
        </Card>
      )}
      {selectedSection && (
        <div className={styles.fullSideBar}>
          <Card className={styles.halfSideBar}>
            <SectionEditor
              key={`${selectedSection.section.key}/${selectedProfileType}`}
              section={selectedSection.section}
              field={selectedField}
              selectedFieldKey={selectedFieldKey}
              fields={selectedSection.fields}
              ratingSystems={ratingSystems}
              profileTypes={profileTypes}
              onFieldEdit={onFieldEdit}
              onFieldSelected={onFieldSelected}
              onFieldOrderUpdate={({ oldIndex, newIndex }) => {
                const movedKey = selectedSection.fields[oldIndex].key;
                const bumpedKey = selectedSection.fields[newIndex].key;
                onFieldOrderUpdate(movedKey, bumpedKey);
              }}
              onFieldDelete={onFieldDelete}
              onFieldRestore={onFieldRestore}
              onSectionUpdate={onSectionUpdate}
              onSectionDelete={onSectionDelete}
              onSectionRestore={onSectionRestore}
              onSectionSelected={onSectionSelected}
              visibilityOptions={visibilityOptions}
            />
          </Card>
          <Card className={styles.halfSideBar}>
            <FieldLibrary
              components={enabledFieldComponents}
              arrayTypeFieldText={arrayTypeFieldText}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default TabWrapper(ProfileSectionsTab);
