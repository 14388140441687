import permissions from '@hogwarts/permissions';
import React, { useContext } from 'react';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '../../../context';
import {
  isFeatureEnabled,
  useMutation,
  usePermission,
  useQuery,
} from '../../../hooks';
import {
  CHANGE_FEDERATION_PROVIDER,
  FEDERATION_DATA_PROVIDER_IMPORT_NOW,
  UPDATE_FEDERATION_PROVIDER_CONFIG,
} from '../../../mutations';
import {
  GET_FEDERATION_ORGANISATIONS,
  GET_FEDERATION_PROVIDER,
  GET_ORGANISATIONS,
} from '../../../queries';
import allProviders from './providers';

import { Loading } from '@hogwarts/ui-components-core';
import DataProviderContainer from './DataProviderContainer';

const useUpdateFederationProviderConfig = () => {
  const federation = useContext(FederationContext);

  const [updateFederationProviderConfig] = useMutation(
    UPDATE_FEDERATION_PROVIDER_CONFIG,
    {
      variables: {
        federationId: federation.id,
      } as {
        federationId?: string;
        config?: any;
      },
      refetchQueries: [
        {
          query: GET_FEDERATION_PROVIDER,
          variables: {
            federationKey: federation.key,
          },
        },
      ],
    }
  );
  return (config: any) =>
    updateFederationProviderConfig({
      variables: {
        config,
      },
    });
};

const useChangeFederationProvider = () => {
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);
  const [changeFederationProvider] = useMutation(CHANGE_FEDERATION_PROVIDER, {
    variables: {
      federationId: federation.id,
    } as {
      federationId: string;
      providerKey: string;
      config: any;
    },
    refetchQueries: [
      {
        query: GET_ORGANISATIONS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const changeProvider = async (providerKey: string) => {
    analytics.events.dataProvider.changedProvider({
      providerKey,
    });

    const current = federation.provider;
    if (current && current?.key === providerKey) {
      return;
    }

    return await changeFederationProvider({
      variables: {
        federationId: federation.id,
        providerKey,
        config: {},
      },
    });
  };

  return changeProvider;
};

const FederationDataProviderContainer = () => {
  const federation = useContext(FederationContext);
  const organisation = useContext(OrganisationContext);

  const [queueFederationDataProviderImport] = useMutation(
    FEDERATION_DATA_PROVIDER_IMPORT_NOW,
    {
      variables: {
        federationId: federation.id,
        discoveryMode: false,
      },
    }
  );

  const { data: provider, loading } = useQuery(GET_FEDERATION_PROVIDER, {
    variables: {
      federationKey: federation.key,
    },
    selector: 'federations[0].provider',
    pollInterval: 3000,
  });
  const changeProvider = useChangeFederationProvider();
  const updateConfig = useUpdateFederationProviderConfig();

  const { loading: loading2, data: organisations } = useQuery(
    GET_FEDERATION_ORGANISATIONS,
    {
      selector: 'organisations[0].federation.organisations',
      variables: {
        organisationKey: organisation.key,
      },
    }
  );

  const canImportNow = usePermission(
    permissions.PROVIDER_IMPORT,
    organisation.id
  );
  const canEditConfig = usePermission(
    permissions.PROVIDER_CONFIG_UPDATE,
    organisation.id
  );

  if (loading || loading2) {
    return <Loading />;
  }

  const providers = allProviders.filter((provider) =>
    isFeatureEnabled(`providers.${provider.key}`, federation)
  );

  return (
    <DataProviderContainer
      changeProvider={changeProvider}
      scheme={organisation.scheme}
      showOrganisationMap={true}
      canImportNow={canImportNow}
      canDisconnect={canEditConfig}
      organisations={organisations}
      providers={providers}
      config={provider}
      selectedProviderKey={provider?.key}
      onImport={() => {
        return queueFederationDataProviderImport({
          variables: {
            federationId: federation.id,
            discoveryMode: false,
          },
        });
      }}
      onImportDiscovery={() => {
        return queueFederationDataProviderImport({
          variables: {
            federationId: federation.id,
            discoveryMode: true,
          },
        });
      }}
      onSaveLocationMap={(locationMap) => {
        return updateConfig({
          locationMap,
        });
      }}
      onSaveProfileTypeMap={(profileTypeMap) => {
        return updateConfig({
          profileTypeMap,
        });
      }}
      onSaveFieldMap={(fieldMap) => {
        return updateConfig({
          fieldMap,
        });
      }}
      onSaveConnection={(connection) => {
        return updateConfig({
          connection,
        });
      }}
      onDisconnect={() => {
        return updateConfig({
          connection: {},
        });
      }}
    />
  );
};

export default FederationDataProviderContainer;
