import {
  ASSIGN_USERGROUP_MUTATION,
  UNASSIGN_USERGROUP_MUTATION,
} from '@/mutations';
import {
  GET_FEDERATIONS,
  GET_FEDERATION_ROLES,
  GET_FEDERATION_USER_GROUP_PERMISSIONS,
} from '@/queries';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  isFeatureEnabled,
  useMutation,
  useQuery,
  useQueryResults,
} from '../../hooks';

import { FederationContext } from '../../context';
import { GridView } from '@/components';
import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { useFormState } from '@hogwarts/ui-components-forms';
import { useTranslation } from 'react-i18next';

const UserGroupPolicy = () => {
  const { t } = useTranslation();
  const federation = useContext(FederationContext);

  const permissionsQuery = useQuery(GET_FEDERATION_USER_GROUP_PERMISSIONS, {
    selector: 'federations[0].groupPermissions',
    variables: {
      federationKey: federation.key,
    },
  });

  const organisationGroupsQuery = useQuery(GET_FEDERATIONS, {
    selector: 'federations[0].organisationGroups',
    variables: {
      federationKey: federation.key,
    },
  });

  const userGroupsQuery = useQuery(GET_FEDERATIONS, {
    selector: 'federations[0].userGroups',
    variables: {
      federationKey: federation.key,
    },
  });

  const rolesQuery = useQuery(GET_FEDERATION_ROLES, {
    selector: 'federations[0].roles',
    variables: {
      federationKey: federation.key,
    },
    transform: (roles) => {
      return roles.filter((r) => {
        if (r.resourceType !== 'ORGANISATION' || !r.selectable) {
          return false;
        }
        if (r.featureKey && !isFeatureEnabled(r.featureKey, federation)) {
          return false;
        }
        return true;
      });
    },
  });

  const { loading, error } = useQueryResults({
    permissionsQuery,
    organisationGroupsQuery,
    userGroupsQuery,
    rolesQuery,
  });

  const [assignGroup] = useMutation(ASSIGN_USERGROUP_MUTATION, {
    variables: {
      federationId: federation.id,
    },
    refetchQueries: [
      {
        query: GET_FEDERATION_USER_GROUP_PERMISSIONS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const [unAssignGroup] = useMutation(UNASSIGN_USERGROUP_MUTATION, {
    variables: {
      federationId: federation.id,
    },
    refetchQueries: [
      {
        query: GET_FEDERATION_USER_GROUP_PERMISSIONS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const forms = useFormState();

  const userGroups = userGroupsQuery.data;
  const organisationGroups = organisationGroupsQuery.data;
  const permissions = permissionsQuery.data;
  const roles = rolesQuery.data;

  const columns = useMemo(() => {
    return [
      {
        key: 'organisationGroup',
        label: 'Organisation Group',
        width: 200,
        cellRenderer: 'indentCellRenderer',
        valueGetter: ({ data }) => {
          return data?.organisationGroup.name;
        },
      },
      {
        key: 'roles',
        label: 'Roles',
        valueGetter: ({ data }) => {
          if (data?.roles.length === 0) {
            return 'None';
          }
          return data?.roles.map((r) => t(r.name)).join(' | ');
        },
        width: 400,
      },
      {
        key: 'userGroup',
        label: 'User Group',
        valueGetter: ({ data }) => {
          return data?.userGroup.name;
        },
        rowGroup: true,
        hide: true,
      },
    ];
  }, [t]);

  useEffect(() => {
    forms.register('addUserGroupPolicy', {
      title: 'Assign Permissions',
      fields: [
        {
          key: 'userGroupId',
          label: 'User Group',
          values:
            userGroups &&
            userGroups.map((g) => ({
              id: g.id,
              value: g.name,
            })),
          type: 'singleselect',
        },
        {
          key: 'organisationGroupId',
          label: 'Organisation Group',
          values:
            organisationGroups &&
            organisationGroups.map((g) => ({
              id: g.id,
              value: g.name,
            })),
          type: 'singleselect',
        },
        {
          key: 'federationId',
          type: 'hidden',
        },
        {
          key: 'roles',
          label: 'Roles',
          type: 'roles',
          roles,
        },
      ],
      initialValues: {
        federationId: federation.id,
        roles: {},
        organisationGroupId:
          organisationGroups?.length && organisationGroups[0].id,
        userGroupId: userGroups?.length && userGroups[0].id,
      },
      onSave: async (values) => {
        const { roles, ...rest } = values;
        await assignGroup({
          variables: {
            ...rest,
            roles: Object.keys(roles)
              .filter((key) => roles[key] === true)
              .reduce((prev, key) => [...prev, key], []),
          },
        });
      },
    });

    forms.register('updateGroupPolicy', {
      title: 'Update Permissions',
      fields: [
        {
          key: 'userGroupId',
          label: 'User Group',
          values:
            userGroups &&
            userGroups.map((g) => ({
              id: g.id,
              value: g.name,
            })),
          type: 'singleselect',
          readOnly: true,
        },
        {
          key: 'organisationGroupId',
          label: 'Organisation Group',
          values:
            organisationGroups &&
            organisationGroups.map((g) => ({
              id: g.id,
              value: g.name,
            })),
          type: 'singleselect',
          readOnly: true,
        },
        {
          key: 'federationId',
          type: 'hidden',
        },
        {
          key: 'roles',
          label: 'Roles',
          type: 'roles',
          roles,
        },
      ],
      onSave: async (values) => {
        const { roles, ...rest } = values;
        await assignGroup({
          variables: {
            ...rest,
            roles: Object.keys(roles)
              .filter((key) => roles[key] === true)
              .reduce((prev, key) => [...prev, key], []),
          },
        });
      },
    });

    return () => {
      forms.unregister('addUserGroupPolicy');
      forms.unregister('updateGroupPolicy');
    };
  }, [
    assignGroup,
    federation.id,
    forms,
    organisationGroups,
    roles,
    userGroups,
  ]);

  return (
    <SettingsPanel
      loading={loading}
      error={error}
      title="Group Permissions"
      actions={[
        {
          text: 'Assign Permissions',
          icon: 'link',
          onClick: () => {
            forms.showForm('addUserGroupPolicy');
          },
        },
      ]}
      fixedHeight="750px"
    >
      <GridView
        showSideBar={false}
        groupDefaultExpanded={1}
        supressCellSelection
        contextMenuItems={(nodes) => {
          if (nodes.length !== 1) return null;

          return [
            {
              name: 'Delete Policy',
              action: (rows) => {
                const item = rows[0].data;
                // TODO: Need an "Are you sure?" confirmation
                return unAssignGroup({
                  variables: {
                    userGroupId: item.userGroup.id,
                    organisationGroupId: item.organisationGroup.id,
                  },
                });
              },
            },
          ];
        }}
        rows={permissions}
        columns={columns}
        onRowDoubleClicked={(row) => {
          if (!row.data) return;
          const { organisationGroup, userGroup, roles } = row.data;
          forms.showForm('updateGroupPolicy', {
            initialValues: {
              organisationGroupId: organisationGroup.id,
              userGroupId: userGroup.id,
              roles: roles.reduce(
                (prev, role) => ({
                  ...prev,
                  [role.id]: true,
                }),
                {}
              ),
            },
          });
        }}
      />
    </SettingsPanel>
  );
};

export default UserGroupPolicy;
