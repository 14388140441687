import UserSupportSettings from '@/components/Settings/UserSupport';
import { AnalyticsContext, UserContext } from '@/context';
import { useMutation } from '@/hooks';
import { CLOSE_SUPPORT, OPEN_SUPPORT } from '@/mutations';
import { GET_CURRENT_USER } from '@/queries';
import { AppToaster } from '@/utils/toaster';
import { useLazyQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { OrganisationContext } from '../../../context';

const UserSupportContainer = () => {
  const user = useContext(UserContext);
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    // Note, this is vital to ensure cache is being updated
    fetchPolicy: 'network-only',
  });
  const [openSupport] = useMutation(OPEN_SUPPORT, {});
  const [closeSupport] = useMutation(CLOSE_SUPPORT, {});
  return (
    <UserSupportSettings
      caseOpen={user.support}
      onCloseSupport={async () => {
        await closeSupport();
        analytics.events.users.impersonationClose();
        AppToaster.show({
          intent: 'success',
          icon: 'tick',
          message: 'Impersonation has been disabled',
        });
        await getCurrentUser();
      }}
      onOpenSupport={async () => {
        await openSupport({
          variables: {
            organisationId: organisation.id,
          },
        });
        analytics.events.users.impersonationOpen();
        AppToaster.show({
          intent: 'success',
          icon: 'tick',
          message: 'Impersonation has been enabled',
        });
        await getCurrentUser();
      }}
    />
  );
};

export default UserSupportContainer;
