import { ActionBar, Button } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';

const InsightListItem = ({
  name,
  description,
  canView = true,
  onView,
  favourite,
  exportButtonProps,
  onFavourite,
  canDuplicate = true,
  onDuplicate,
  canDelete = true,
  onDelete,
  canExport = true,
  onExport,
  sharedWith,
}) => {
  const [isOver, setIsOver] = useState(false);

  const actions = [
    onView && {
      text: 'View',
      icon: ['fal', 'table'],
      allowed: canView,
      onClick: onView,
    },
    onExport && { ...exportButtonProps, onClick: onExport },
    {
      icon: 'ellipsis-h',
      minimal: true,
      caret: false,
      actions: [
        canDuplicate && {
          text: 'Duplicate',
          allowed: canDuplicate,
          icon: ['fal', 'clone'],
          onClick: () => onDuplicate(),
        },
        {
          text: 'Delete',
          allowed: canDelete,
          icon: ['fal', 'trash'],
          intent: 'danger',
          onClick: () => onDelete(),
        },
      ],
    },
  ];

  return (
    <div
      className="d-flex w-100 m-3 align-items-center"
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => {
        setIsOver(false);
      }}
    >
      <Button
        minimal
        className="mr-2"
        iconSize="lg"
        icon={favourite ? ['fa', 'star'] : ['far', 'star']}
        iconColor={favourite ? '#fecc00' : isOver ? 'gray' : 'lightgray'}
        onClick={() => onFavourite(!favourite)}
      />
      <div>
        <h5 className="m-0">{name}</h5>
        {description && <p className="text-muted mt-1 mb-1">{description}</p>}
        {sharedWith && (
          <p className="text-muted mt-1 mb-1">{`${sharedWith}`}</p>
        )}
      </div>
      <div className="flex-grow-1" style={{ width: '20px' }} />

      <ActionBar actions={actions} />
    </div>
  );
};

export default InsightListItem;
