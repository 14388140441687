import { Callout } from '@blueprintjs/core';
import Bowser from 'bowser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ValidBrowserBanner = () => {
  const { t } = useTranslation();

  const [isVisible] = useState(() => {
    if (window && window.navigator) {
      const browser = Bowser.getParser(window.navigator.userAgent);
      const isValidBrowser = browser.satisfies({
        chrome: '>72',
        firefox: '>67',
        edge: '>79',
        safari: '>10.1',
      });
      return !isValidBrowser;
    }
    return false;
  });

  if (!isVisible) return null;

  return (
    <Callout intent="danger" className="d-flex mb-2">
      <div>
        {t(
          'This application is not optimised for your current browser and may not function correctly. For a better experience we recommend upgrading to a modern browser such as Google Chrome, Firefox or the latest Microsoft Edge and making sure it is up to date.'
        )}
      </div>
    </Callout>
  );
};
