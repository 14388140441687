import React, { useCallback, useContext, useState } from 'react';
import {
  useFeature,
  useJobMonitor,
  useMutation,
  useQuery,
  useTransformProfiles,
} from '../../../hooks';
import {
  QUEUE_ADD_ALL_PROFILES_TO_SIA,
  QUEUE_ADD_PROFILE_TO_SIA,
} from '../../../mutations';
import {
  DATASOURCE_QUERY_PROFILES_WITHOUT_ORG,
  GET_ORGANISATION_SIAGROUPS,
} from '../../../queries';

import { useApolloClient } from '@apollo/client';
import { NonIdealState } from '@blueprintjs/core';
import { useMemoizeArgs } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import BulkSyncSignInAppComponent from '../../../components/Settings/BulkSyncSignInApp';
import { OrganisationContext } from '../../../context';
import ProfilesFilterBar from '../../profiles/profileListQuery/filterBar';
import { SettingsPanel } from './SettingsPanel';

interface Rating {
  key: string;
  include: boolean;
}

interface Filter {
  tags?: string[];
  profileTypes?: string[];
  ratings: Rating[];
}

const getSiaIdNotExistsCondition = () => {
  return {
    variables: {
      attributeName$: {
        type: 'string',
        source: ['attribute', 'sia.id'],
      },
    },
    conditions: [
      {
        when: 'attributeName$',
        comparison: 'isNull',
      },
    ],
    enabled: true,
    operator: 'and',
  };
};

const BulkSyncSignInApp = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const apollo = useApolloClient();

  const isSiaEnabled = useFeature('sia');

  const [filters, setFilters] = useState<Filter>(
    useMemoizeArgs<Filter>({
      ratings: [],
    })
  );

  const onFilterHandler = useCallback((currentFilters) => {
    const ratingFilter: Rating[] = [];
    for (const filterKey of Object.keys(currentFilters)) {
      if (filterKey.includes('ratingSystem')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: currentFilters[filterKey][0],
        });
      }
    }

    setFilters(() => ({
      profileTypes: currentFilters.profileTypes,
      tags: currentFilters.tags,
      ratings: ratingFilter,
    }));
  }, []);

  const transformProfiles = useTransformProfiles();

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) return;

      const { tags, ratings, profileTypes } = filters;

      const variables = {
        organisationKey: organisation.key,
        profileTypes,
        tags,
        ratings,
        skip,
        limit,
        includeDeleted: false,
        sort: [{ field: 'lastname', desc: false }],
        condition: getSiaIdNotExistsCondition(),
      };

      const profiles = await apollo.query({
        query: DATASOURCE_QUERY_PROFILES_WITHOUT_ORG,
        fetchPolicy: 'no-cache',
        variables,
      });

      return transformProfiles(profiles?.data?.queryProfiles?.result);
    },
    [filters, organisation, transformProfiles, apollo]
  );

  const { data: siaGroups } = useQuery<any[]>(GET_ORGANISATION_SIAGROUPS, {
    variables: {
      organisationKey: organisation.key,
    },
    selector: 'organisations[0].siaGroups',
    skip: !isSiaEnabled,
  });

  const [addProfileToSia] = useMutation(QUEUE_ADD_PROFILE_TO_SIA);
  const [queuePushAllToSia] = useMutation(QUEUE_ADD_ALL_PROFILES_TO_SIA, {
    selector: 'queueAddAllProfilesToSignInApp',
  });

  const [selectedGroup, setSelectedGroup] = useState<{
    id: string;
    label: string;
  }>();

  const [pushAllJobId, setPushAllJobId] = useState<string | undefined>();
  const pushAllStatus = useJobMonitor(pushAllJobId);

  let pushAllJobRunning = false;
  switch (pushAllStatus?.status) {
    case 'queued':
    case 'inprogress': {
      pushAllJobRunning = true;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <>
      <div>
        <SettingsPanel
          title={t('Sign In App Upload')}
          onGroupSelected={setSelectedGroup}
          currentGroup={selectedGroup}
          groups={
            siaGroups?.map((s) => ({
              id: s.id,
              label: s.name,
            })) || []
          }
          disabled={pushAllJobRunning}
          onPushAllToGroupClicked={async () => {
            if (pushAllJobRunning) {
              return;
            }
            const { tags, ratings, profileTypes } = filters;
            const result = await queuePushAllToSia({
              variables: {
                organisationId: organisation.id,
                groupId: selectedGroup?.id,
                tags,
                profileTypes,
                ratings,
              },
            });
            if (result) {
              setPushAllJobId(result.data);
              return result.data;
            }
            return null;
          }}
        >
          {!isSiaEnabled ? (
            <NonIdealState title={t('Sign In App is not Enabled')} />
          ) : pushAllJobRunning ? (
            <NonIdealState title={t('Pushing to Sign In App...')}>
              <div style={{ height: '750px' }}>
                <Loader
                  type={'ThreeDots'}
                  color={'#70d955'}
                  height={'50'}
                  width={'50'}
                />
              </div>
            </NonIdealState>
          ) : (
            <>
              <ProfilesFilterBar
                onFilter={onFilterHandler}
                initialFilters={[]}
              />
              <div style={{ height: '750px' }}>
                <BulkSyncSignInAppComponent
                  disabled={pushAllJobRunning || !selectedGroup}
                  fetchMore={fetchMore}
                  groupName={selectedGroup?.label}
                  onPushProfile={async (profileId) => {
                    const result = await addProfileToSia({
                      variables: {
                        organisationId: organisation.id,
                        profileId,
                        groupId: selectedGroup?.id,
                      },
                    });
                    return result.data;
                  }}
                />
              </div>
            </>
          )}
        </SettingsPanel>
      </div>
    </>
  );
};

export default BulkSyncSignInApp;
