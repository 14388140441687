import person1Avatar from './1.png';
import person10Avatar from './10.png';
import person11Avatar from './11.png';
import person12Avatar from './12.png';
import person13Avatar from './13.png';
import person14Avatar from './14.png';
import person15Avatar from './15.png';
import person16Avatar from './16.png';
import person17Avatar from './17.png';
import person18Avatar from './18.png';
import person19Avatar from './19.png';
import person2Avatar from './2.png';
import person20Avatar from './20.png';
import person21Avatar from './21.png';
import person22Avatar from './22.png';
import person3Avatar from './3.png';
import person4Avatar from './4.png';
import person5Avatar from './5.png';
import person6Avatar from './6.png';
import person7Avatar from './7.png';
import person8Avatar from './8.png';
import person9Avatar from './9.png';

export const avatars: Record<string, any> = {
  '1': person1Avatar,
  '2': person2Avatar,
  '3': person3Avatar,
  '4': person4Avatar,
  '5': person5Avatar,
  '6': person6Avatar,
  '7': person7Avatar,
  '8': person8Avatar,
  '9': person9Avatar,
  '10': person10Avatar,
  '11': person11Avatar,
  '12': person12Avatar,
  '13': person13Avatar,
  '15': person15Avatar,
  '14': person14Avatar,
  '16': person16Avatar,
  '17': person17Avatar,
  '18': person18Avatar,
  '19': person19Avatar,
  '20': person20Avatar,
  '21': person21Avatar,
  '22': person22Avatar,
};
