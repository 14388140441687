import { Card } from '@blueprintjs/core';
import { useFormState } from '@hogwarts/ui-components-forms';
import { Column } from '@hogwarts/ui-components-grid';
import { capitalize } from '@hogwarts/utils';
import { DocumentNode } from 'graphql';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GridView, TabBar } from '../../../components';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useAlert, useFeature, useMutation, useQuery } from '../../../hooks';
import {
  ADD_CATEGORY,
  ADD_SEVERITY,
  ADD_STATUS,
  ADD_TAG,
  DELETE_CATEGORY,
  DELETE_SEVERITY,
  DELETE_STATUS,
  DELETE_TAG,
  UPDATE_CATEGORY,
  UPDATE_SEVERITY,
  UPDATE_STATUS,
  UPDATE_TAG,
} from '../../../mutations';
import { GET_ORGANISATION_ACTIVITY_ATTRIBUTES } from '../../../queries';
import styles from './styles.module.css';

type AttributeType = 'tags' | 'categories' | 'severities' | 'statuses';

type AttributeItem = {
  key: AttributeType;
  name: string;
  addMutation?: { name: string; mutation: DocumentNode };
  updateMutation?: { name: string; mutation: DocumentNode };
  deleteMutation?: { name: string; mutation: DocumentNode };
};

const columns: Column[] = ['name', 'description'].map((key) => ({
  key,
  valueGetter: (params: any) => params.data[key],
  label: capitalize(key),
  width: 200,
}));

columns.push({
  key: 'color',
  valueGetter: (params: any) => params.data.color,
  label: 'Color',
  width: 75,
  cellRenderer: 'colorRenderer',
});

const IncidentAttributeEditor: React.FC = () => {
  const isEnabled = useFeature('timeline.incident_tracking');
  const { t } = useTranslation();
  const [selectedTabId, setSelectedTabId] = useState<AttributeType>('tags');
  const forms = useFormState();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);

  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'Are you sure you want to delete this item? This operation cannot be undone.'
        )}
      </p>
    ),
    onConfirm: (values: any) => {
      submitMutation(values);
      analytics.events.activities.deleteAttributeConfirmed({
        id: values.id,
        type: selectedTabId,
      });
    },
  });

  const {
    data: activityAttribute,
    loading,
    error,
  } = useQuery(GET_ORGANISATION_ACTIVITY_ATTRIBUTES, {
    selector: 'organisations[0]',
    variables: {
      organisationKey: organisation.key,
      skip: 0,
      limit: 0,
    },
  });

  // Render the grid view for the selected tab
  const RenderGridView: React.FC<{ tabId: string }> = ({ tabId }) => {
    return (
      <>
        <ConfirmDeleteAlert />

        <GridView
          sizeColumnsToFit
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
          }}
          showSideBar={false}
          rows={activityAttribute[tabId]}
          columns={columns}
          onRowDoubleClicked={(row) => {
            analytics.events.activities.editAttributeClicked({
              id: row.data.id,
              type: selectedTabId,
            });
            forms.showForm(`edit${selectedTabId}`, {
              initialValues: {
                id: row.data.id,
                name: row.data.name,
                description: row.data.description,
                color: row.data.color,
                mutation: `edit${selectedTabId}`,
              },
            });
          }}
          contextMenuItems={(nodes) => {
            if (nodes.length !== 1) return [];

            let menuItems = [
              {
                name: `Delete`,
                action: (attribute: any) => {
                  analytics.events.activities.deleteAttributeClicked({
                    id: attribute[0].data.id,
                    type: selectedTabId,
                  });
                  showConfirmDeleteAlert({
                    id: attribute[0].data.id,
                    mutation: `delete${selectedTabId}`,
                  });
                },
              },
            ];

            return menuItems;
          }}
        />
      </>
    );
  };

  const attributeTypes: AttributeItem[] = useMemo(
    () => [
      {
        key: 'tags',
        name: 'Tags',
        addMutation: { name: 'addTag', mutation: ADD_TAG },
        updateMutation: { name: 'updateTag', mutation: UPDATE_TAG },
        deleteMutation: { name: 'deleteTag', mutation: DELETE_TAG },
      },
      {
        key: 'categories',
        name: 'Categories',
        addMutation: { name: 'addCategory', mutation: ADD_CATEGORY },
        updateMutation: { name: 'updateCategory', mutation: UPDATE_CATEGORY },
        deleteMutation: { name: 'deleteCategory', mutation: DELETE_CATEGORY },
      },
      {
        key: 'severities',
        name: 'Severities',
        addMutation: { name: 'addSeverity', mutation: ADD_SEVERITY },
        updateMutation: { name: 'updateSeverity', mutation: UPDATE_SEVERITY },
        deleteMutation: { name: 'deleteSeverity', mutation: DELETE_SEVERITY },
      },
      {
        key: 'statuses',
        name: 'Statuses',
        addMutation: { name: 'addStatus', mutation: ADD_STATUS },
        updateMutation: { name: 'updateStatus', mutation: UPDATE_STATUS },
        deleteMutation: { name: 'deleteStatus', mutation: DELETE_STATUS },
      },
    ],
    []
  );

  // Create mutation hook for each attribute type
  const CreateAttributeMutation = (mutation: DocumentNode) => {
    const [mutate] = useMutation(mutation, {
      refetchQueries: [
        {
          query: GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
          variables: {
            organisationKey: organisation.key,
          },
        },
      ],
    });

    return (variables: Record<string, any>) => {
      return mutate({ variables });
    };
  };

  // Creates mutation hooks for each attribute type
  // The mutations are recreated on every rerender, but this is fine
  // TODO: Fix this eslint error
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const attributeMutations: Record<string, any> = {};

  for (const type in attributeTypes) {
    const addMutation = attributeTypes[type].addMutation?.mutation;
    const updateMutation = attributeTypes[type].updateMutation?.mutation;
    const deleteMutation = attributeTypes[type].deleteMutation?.mutation;
    console.log('addMutation', addMutation);

    attributeMutations[`add${attributeTypes[type].key}`] =
      CreateAttributeMutation(addMutation!);
    attributeMutations[`edit${attributeTypes[type].key}`] =
      CreateAttributeMutation(updateMutation!);
    attributeMutations[`delete${attributeTypes[type].key}`] =
      CreateAttributeMutation(deleteMutation!);
  }

  const submitMutation = useCallback(
    async (values: Record<string, any>) => {
      const { id, name, description, color, mutation } = values;

      if (mutation.startsWith('delete')) {
        await attributeMutations[mutation]({ id });
      } else if (mutation.startsWith('edit')) {
        const response = await attributeMutations[mutation]({
          values: {
            id,
            name,
            description,
            color,
          },
        });
        analytics.events.activities.attributeEdited(response.data);
      } else if (mutation.startsWith('add')) {
        const response = await attributeMutations[mutation]({
          values: {
            owner: { id: organisation.id, type: 'ORGANISATION' },
            name,
            description,
            color,
          },
        });
        analytics.events.activities.attributeAdded(response.data);
      }
    },
    [attributeMutations, analytics, organisation.id]
  );

  useEffect(() => {
    // Register forms for adding and editing attributes
    attributeTypes.forEach((type: AttributeItem) => {
      const fields = [
        {
          key: 'name',
          label: 'Name',
          validate: 'required',
          maxLength: 20,
          type: 'textbox',
        },
        {
          key: 'description',
          label: 'Description',
          maxLength: 255,
          type: 'textarea',
        },
        {
          key: 'color',
          label: 'Color',
          type: 'colorpicker',
        },
        {
          key: 'mutation',
          type: 'hidden',
        },
        {
          key: 'id',
          type: 'hidden',
        },
      ];

      forms.register(`add${type.key}`, {
        title: `Add ${type.name}`,
        fields,
        onSave: submitMutation,
      });

      forms.register(`edit${type.key}`, {
        title: `Edit ${type.name}`,
        fields,
        onSave: submitMutation,
      });
    });

    return () => {
      // Unregister forms when the component unmounts
      attributeTypes.forEach((type) => {
        forms.unregister(`add${type.key}`);
        forms.unregister(`edit${type.key}`);
      });
    };
  }, [attributeTypes, attributeMutations, forms, submitMutation]);

  if (!isEnabled) {
    return null;
  }

  return (
    <SettingsPanel
      title="Preferences"
      loading={loading}
      error={!!error}
      fixedHeight="750px"
      actions={[
        {
          text: `Add ${selectedTabId}`,
          icon: 'plus',
          onClick: () => {
            analytics.events.activities.addAttributeClicked({
              type: selectedTabId,
            });
            forms.showForm(`add${selectedTabId}`, {
              initialValues: {
                id: '',
                mutation: `add${selectedTabId}`,
              },
            });
          },
        },
      ]}
    >
      <div className={styles.card}>
        <TabBar
          activeTab={selectedTabId}
          tabs={attributeTypes.map((type) => ({
            key: type.key,
            name: type.name,
          }))}
          onTabChange={(tabId) => setSelectedTabId(tabId as AttributeType)}
        />
        <Card className={styles.card}>
          <RenderGridView tabId={selectedTabId} />
        </Card>
      </div>
    </SettingsPanel>
  );
};

export default IncidentAttributeEditor;
