import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FederationContext, OrganisationContext } from '@/context';
import { useQuery } from '@/hooks';
import { GET_FEDERATION_SCHEMES } from '@/queries';
import SchemeSettings from '../../../components/Settings/Scheme';

const OrganisationSchemeSettingsContainer = () => {
  const federation = useContext(FederationContext);
  const organisation = useContext(OrganisationContext);
  const history = useHistory();
  const {
    loading,
    data: schemes,
    error,
  } = useQuery(GET_FEDERATION_SCHEMES, {
    selector: 'federations[0].schemes',
    variables: {
      federationKey: federation.key,
    },
  });

  return (
    <SchemeSettings
      error={error}
      loading={loading}
      schemes={schemes}
      onSchemeSelected={(schemeId) => {
        history.push(`/${organisation.key}/settings/template/${schemeId}`);
      }}
    />
  );
};

export default OrganisationSchemeSettingsContainer;
