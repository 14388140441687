import { getTypeGroups } from '../../../components/UserProfile/Documents/mimetypes';

export const getDocumentFilters = (profile, scheme) => {
  return [
    {
      type: 'select',
      id: 'sectionType',
      icon: 'folder-close',
      description: 'Section Type',
      items: scheme.sections.map((section) => ({
        id: section.key,
        value: section.label,
      })),
    },
    {
      type: 'select',
      id: 'documentType',
      icon: 'document',
      description: 'Document Type',
      items: getTypeGroups(),
    },
    {
      type: 'date',
      id: 'uploadDateRange',
      description: 'Upload Date',
    },
  ];
};
