import React, { useContext, useImperativeHandle, forwardRef } from 'react';

import { Conditionals } from '@/components';
import { ConditionalScheme } from '@/components/Conditionals';

export const ConditionalPanelOnChangeContext = React.createContext(null);

export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getReactContainerClasses() {
      return ['w-100'];
    },
  }));

  // useEffect(
  //   () => {
  //     props.api.addEventListener('modelUpdated', updateTotals);

  //     return () => props.api.removeEventListener('modelUpdated', updateTotals);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [props.api]
  // );

  const { condition, onChange } = useContext(ConditionalPanelOnChangeContext);

  let scheme = useContext(ConditionalScheme);
  if (!scheme) {
    return <div>{'Error. Scheme not provided'}</div>;
  }
  return (
    <div className="p-3 w-100 h-100">
      <Conditionals
        scheme={scheme}
        // excludeFields={excludeFields}
        condition={condition}
        readOnly={false}
        onChange={async (condition) => {
          if (onChange) {
            onChange(condition);
          }
        }}
      />
    </div>
  );
});
