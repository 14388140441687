import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../../components';
import { AnalyticsContext, OrganisationContext } from '../../../../context';
import { useFeature, useQuery } from '../../../../hooks';
import { DATASOURCE_QUERY_ACTIVITIES } from '../../../../queries';
import IncidentsGridView from './gridView';

const IncidentsReport = ({ insight, onExport }: any) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const incidentTrackingEnabled = useFeature('timeline.incident_tracking');

  const { loading, data, error } = useQuery(DATASOURCE_QUERY_ACTIVITIES, {
    selector: 'queryActivities.result',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const actions = useMemo(() => {
    if (insight.exportType) {
      return [
        {
          ...insight.exportButtonProps,
          onClick: () => {
            analytics.events.insights.exportViewClicked();
            return onExport();
          },
        },
      ];
    }
  }, [insight, analytics, onExport]);

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  if (error) {
    return <div>{t('Unknown Error Occurred')}</div>;
  }

  if (!incidentTrackingEnabled) {
    return null;
  }

  return (
    <div className="row h-100">
      <PageHeader noWrapper={false} actions={actions} />
      <div className="w-100">
        <IncidentsGridView incidents={data} />
      </div>
    </div>
  );
};

export default IncidentsReport;
