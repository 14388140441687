import { Callout } from '@blueprintjs/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useDateFormatter } from '../../../hooks';
import { candidateEmail, candidateName } from '../../CandidateIdentity/Helpers';
import { CheckStatusTag } from '../../CheckStatusTag';
import styles from './CheckOrderInfo.module.css';
import { CheckOrder } from './types';

interface CheckOrderInfoProps {
  checkOrder: CheckOrder;
  dbsApplicationNumber?: string;
  dbsTableContent?: string;
  dbsTableError?: boolean;
  dbsTableLoading?: boolean;
}

const CheckOrderInfo = ({
  checkOrder,
  dbsApplicationNumber,
  dbsTableContent,
  dbsTableError,
  dbsTableLoading,
}: CheckOrderInfoProps) => {
  const formatDate = useDateFormatter();
  const { t } = useTranslation();

  return (
    <div className={styles.checkOrderInfoContainer}>
      <div className={styles.sectionContainer}>
        <p className={styles.sectionHeading}>{t('Candidate')}</p>
        <div>
          <strong>{t('Name: ')}</strong>
          {candidateName(checkOrder.candidate)}
        </div>
        <div>
          <strong>{t('Email: ')}</strong>
          {candidateEmail(checkOrder.candidate)}
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <p className={styles.sectionHeading}>{t('Order Details')}</p>
        <div>
          <strong>{t('Ordered On: ')}</strong>
          {formatDate.medium(checkOrder.orderedAt)}
        </div>
        <div>
          <strong>{t('Internal ID: ')}</strong>
          {checkOrder.uniqueKey}
        </div>
        {checkOrder.orderId && (
          <div>
            <strong>{t('Verifile ID: ')}</strong>
            {checkOrder.orderId}
          </div>
        )}
        <strong>{t('Checks Ordered:')}</strong>
        {checkOrder.checks.map((check) => (
          <div key={check.cacheKey} className={styles.checksList}>
            <div>{check.name}</div>
            <CheckStatusTag status={check.status} />
          </div>
        ))}
      </div>
      {dbsTableLoading && (
        <div className={styles.loader}>
          <Loader type="ThreeDots" color={'#70d955'} />
        </div>
      )}
      {dbsTableError && (
        <Callout intent="warning" title={t('Error Loading DBS Status')}>
          <p>
            {t(
              'We were unable to load the status of this order from the The Disclosure and Barring Service.'
            )}
          </p>
          {dbsApplicationNumber && (
            <>
              <p>
                {t(
                  `Neither SCR Tracker or Verifile can contact the DBS directly. 
                  The candidate can contact them with the Application Number: {{dbsApplicationNumber}} and request the order status`,
                  {
                    dbsApplicationNumber,
                  }
                )}
              </p>
              <p>
                <Trans>
                  For more infomation please{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.gov.uk/government/organisations/disclosure-and-barring-service#org-contacts"
                  >
                    click here.
                  </a>
                </Trans>
              </p>
            </>
          )}
        </Callout>
      )}
      {dbsTableContent && (
        <div className={styles.sectionContainer}>
          <p className={styles.sectionHeading}>{t('DBS Status')}</p>
          <table
            className={styles.dbsStatusTable}
            dangerouslySetInnerHTML={{ __html: dbsTableContent }}
          ></table>
        </div>
      )}
    </div>
  );
};

export default CheckOrderInfo;
