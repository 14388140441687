import { FieldMap, SourceField } from '../../../../types';
import React, { useState } from 'react';

import { ActionBar } from '@hogwarts/ui-components-core';
import { FormBuilder } from '@hogwarts/ui-components-forms';
import { Scheme } from '@hogwarts/utils-schemes';
import cn from 'classnames';
import styles from '../styles.module.css';
import { useMemo } from 'react';
import uuid from 'uuid/v4';

const mapToComponent = (fieldMap: FieldMap[]) => {
  return fieldMap.map((m) => ({
    id: uuid(),
    sourceField: m.source,
    targetField: m.target,
    readOnly: m.readonly,
    transform: m.transform,
  }));
};

const mapFromComponent = (
  fieldMap: {
    id: string;
    sourceField?: string;
    targetField?: string;
    readOnly?: boolean;
    transform?: any;
  }[]
) => {
  return fieldMap.map((m: any) => ({
    source: m.sourceField,
    target: m.targetField,
    readonly: m.readOnly,
    transform: m.transform,
  }));
};

type onSaveHandler = (fieldMap: FieldMap[]) => void;

interface FieldMapProps {
  sourceFields: SourceField[];
  fieldMap: FieldMap[];
  onSave: onSaveHandler;
  scheme: Scheme;
}

export const FieldMapEditor = ({
  scheme,
  sourceFields: initialSourceFields,
  fieldMap: initialFieldMap,
  onSave,
}: FieldMapProps) => {
  const sourceFields = useMemo(
    () =>
      initialSourceFields?.map((f) => ({
        id: f.key,
        label: f.key,
        dataType: f.dataType,
      })) || [],
    [initialSourceFields]
  );
  const initialFieldMapComponent = useMemo(
    () => mapToComponent(initialFieldMap),
    [initialFieldMap]
  );
  const [currentValues, setCurrentValues] = useState(initialFieldMapComponent);

  const [targetFields, targetGroups] = useMemo(() => {
    const a = scheme.fields
      .filter(
        (f) => f.dataType !== 'none' && f.dataType !== 'array' && !f.calculation
      )
      .map((f) => ({
        id: f.key,
        // @ts-ignore
        label: (f.label || f.key).trunc(45),
        dataType: f.dataType,
        sectionKey: f.section,
      }));
    const b = scheme.sections.map((s) => ({
      id: s.key,
      label: s.label,
    }));
    return [a, b];
  }, [scheme.fields, scheme.sections]);

  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="m-1">
        <FormBuilder
          initialValues={{
            fieldMap: initialFieldMapComponent,
          }}
          fields={[
            {
              key: 'fieldMap',
              type: 'fieldMap',

              sourceFields,
              targetFields,
              targetGroupKey: 'sectionKey',
              targetGroups,
              readOnlyDefault: true,
            },
          ]}
          onValuesChanged={(values) => {
            // store values for saving
            setCurrentValues(values.fieldMap);
          }}
        />
      </div>
      <div className="d-flex flex-rows">
        <div className={cn(styles.titleText, 'flex-grow-1')} />
        <ActionBar
          large={false}
          actions={[
            {
              text: 'Save',
              intent: 'primary',
              icon: 'save',
              onClick: () => {
                return onSave(mapFromComponent(currentValues));
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
