import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery as useQueryApollo,
} from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';

type ExtendedOptions = {
  key?: string;
  transform?: any;
  selector?: string;
};

export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & ExtendedOptions
): QueryResult<TData, TVariables> {
  const { key, transform, selector, ...options2 } = options || {};

  const selectAndTransformDataBlock = useCallback(
    (data: TData | undefined, options: ExtendedOptions) => {
      const { transform, selector } = options;

      if (selector && data) {
        data = get(data, selector);
      }

      if (data && typeof transform === 'function') {
        data = transform(data, options);
      }

      return data;
    },
    []
  );

  const queryResult = useQueryApollo(query, options2);

  const data = useMemo<TData | undefined>(
    () => {
      return selectAndTransformDataBlock(queryResult.data, {
        key,
        transform,
        selector,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryResult.data, selectAndTransformDataBlock, selector]
  );

  return useMemo(
    () => ({ ...queryResult, key, data }),
    [data, key, queryResult]
  );
}
