import { currentDateForTimezone, parseDate } from '@hogwarts/validation';
import React, { useCallback, useContext } from 'react';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMemoizeArgs, useTransformProfiles } from '../../../hooks';

import { useApolloClient } from '@apollo/client';
import { useForms } from '@hogwarts/ui-components-forms';
import { useHistory } from 'react-router-dom';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import ProfilesListWidget from '../../../components/Dashboards/Widgets/ProfilesExpiredDatesList';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import { DATASOURCE_QUERY_EXPIRY_DATES } from '../../../queries';

const WIDGET_FEATUREKEY = 'widgets.expiredchecksbyprofile';

const Container = ({
  settings: proposedSettings,
  onSettingsUpdate,
  ...rest
}) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const apollo = useApolloClient();

  const settings = useMemoizeArgs(proposedSettings);

  const days = 14;

  const transform = useTransformProfiles(
    (profile, { scheme, organisation }) => {
      const expirationField = scheme.getField(profile.fieldKey);
      const expirationDate = parseDate(profile.expirationDate);
      const currentDate = currentDateForTimezone(organisation.timezone);

      const expired = expirationDate < currentDate;
      const expiringSoon =
        !expired && days && expirationDate.minus({ days }) < currentDate;

      if (!expired && !expiringSoon) return null;

      const profileExpiringFields = [
        {
          key: profile.fieldKey,
          sectionKey: expirationField?.section,
          sectionLabel: expirationField?.sectionItem?.label,
          label: expirationField?.label,
          expiryDate: expirationDate,
          expiringSoon,
          expired,
        },
      ];

      const expiringFields = profileExpiringFields.reduce(
        (prev, { sectionKey, sectionLabel, ...field }) => {
          if (!prev[sectionKey]) {
            prev[sectionKey] = {
              key: sectionKey,
              label: sectionLabel,
              fields: [],
            };
          }
          prev[sectionKey].fields.push(field);
          return prev;
        },
        {}
      );

      return {
        ...profile,
        expiringFields,
      };
    }
  );

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        key: 'condition',
        type: 'conditional',
        label: 'Filter Logic',
        scheme: organisation?.scheme,
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },

      {
        type: 'separator',
      },

      ...organisation.scheme.ratingSystems.reduce((prev, rs) => {
        return [
          ...prev,
          {
            type: 'title',
            label: rs.label,
          },
          {
            key: `ratings.${rs.key}`,
            type: 'singleselect',
            validate: 'required',
            values: [
              {
                id: 'all',
                value: 'Show All',
              },
              {
                id: 'exclude',
                value: 'Show not ready',
              },
              {
                id: 'include',
                value: 'Show Ready',
              },
            ],
          },
        ];
      }, []),

      // {
      //   key: 'additional_field1',
      //   label: 'Show Additional Field',
      //   type: 'fieldselect',
      //   scheme: organisation?.scheme,
      //   itemsDescription: 'Nothing selected',
      //   validate: null,
      // },
    ],
    initialValues: {
      includeRatingSystems: {},
      ...settings,
    },
  });

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) return;
      const variables = {
        days,
        organisationKey: organisation.key,
        condition: settings.condition,

        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((tagKey) =>
            settings.includedProfileTypes[tagKey] ? tagKey : null
          )
          .filter(Boolean),

        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),

        ratings: Object.keys(settings.ratings || {})
          .map((systemKey) => {
            switch (settings.ratings[systemKey]) {
              case 'exclude': {
                return {
                  key: systemKey,
                  include: false,
                };
              }
              case 'include': {
                return {
                  key: systemKey,
                  include: true,
                };
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean),
      };

      let profiles = await apollo.query({
        query: DATASOURCE_QUERY_EXPIRY_DATES,
        fetchPolicy: 'no-cache',
        variables: {
          ...variables,
          skip,
          limit,
        },
      });
      return transform(profiles?.data?.queryExpiryDates?.result);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings]
  );

  return (
    <WidgetWrapper
      title={settings.title}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      disableScroll
      {...rest}
    >
      <ProfilesListWidget
        organisationKey={organisation.key}
        fetchMore={fetchMore}
        iconPack={organisation.attributes?.avatarIconPack}
        onProfileSelected={(profileId) => {
          analytics.events.dashboard.widget.expiredChecksByProfile.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}`);
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'expired-checks-by-profile',
  name: 'Profiles with Expiring Checks List',
  description: 'Displays a list of profiles that have expiring checks.',
  widgetComponent: Container,
  defaultSettings: (scheme) => ({
    title: 'Profiles with Expiring Checks',
    condition: {
      conditions: [],
      operator: 'and',
      enabled: true,
    },

    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,

    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
  restricted: (organisation) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
