import { gql } from '@apollo/client';

export const ADD_GROUP_TO_REPORT = gql`
  mutation addGroupToReport($reportId: ID!, $groups: [ReportGroupInput!]) {
    updateReport(id: $reportId, groups: $groups) {
      id
      groups {
        key
        label
        condition
      }
    }
  }
`;

export const DELETE_REPORT_GROUP = gql`
  mutation deleteReportGroup($reportId: ID!, $groupKey: String!) {
    deleteReportGroup(id: $reportId, groupKey: $groupKey) {
      id
      groups {
        key
        label
        condition
      }
    }
  }
`;
