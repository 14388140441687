import { ActionBar, Select } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsContext } from '../../../../context';
import { LocationMap } from '../../../../types';
import styles from '../styles.module.css';

export interface Location {
  id: string;
  parent?: string;
  label: string;
}
export interface OrganisationItem {
  id: string;
  name: string;
}
export interface LocationEditorProps {
  locationsType?: string; // default | tree
  locations?: string[] | Location[];
  locationMap: LocationMap;
  organisations?: OrganisationItem[];
  onSave: (locationMap: LocationMap) => void;
}

interface LocationEditorItemProps {
  className?: string;
  label: string;
  organisations: OrganisationItem[];
  selected?: string;
  onChange: (value: string) => void;
}
const LocationEditorItem = ({
  className,
  label,
  organisations,
  selected,
  onChange,
}: LocationEditorItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div>{label || '(unknown location)'}</div>
      <Select
        showEmpty
        large={false}
        items={organisations}
        value={selected}
        fill
        valueField="id"
        textField="name"
        itemsDescription={t('- Not Selected')}
        popoverProps={{
          minimal: true,
        }}
        onChange={(value) => {
          onChange(value.id);
        }}
      />
    </div>
  );
};

export const LocationEditor = ({
  locationsType = 'default',
  locations: initialLocations,
  locationMap: initialLocationMap,
  organisations,
  onSave,
}: LocationEditorProps) => {
  const analytics = useContext(AnalyticsContext);

  const allLocations = useMemo<Location[]>(() => {
    if (!initialLocations?.length) {
      return [];
    }

    let locations1 =
      typeof initialLocations[0] === 'string'
        ? (initialLocations.map((l) => ({ id: l, label: l })) as Location[])
        : (initialLocations as Location[]);

    return locations1;
  }, [initialLocations]);

  const initialValues = useMemo<any>(() => {
    if (!allLocations?.length) {
      return {};
    }

    return allLocations.reduce((prev, cur, index) => {
      const organisation = initialLocationMap.find(
        (s) => s.value === cur.id
      )?.organisation;
      if (organisation) {
        return {
          ...prev,
          [cur.id]: organisation,
        };
      }
      return prev;
    }, {});
  }, [initialLocationMap, allLocations]);

  const [currentValues, setCurrentValues] = useState<any>(initialValues);

  const locationEditorItems = useMemo(() => {
    const results: any[] = [];

    const getLocationItems = (locations: Location[], level: number = 0) => {
      if (locations.length === 0) return;

      for (const location of locations) {
        results.push(
          <div style={{ marginLeft: `${level * 10}px` }}>
            <LocationEditorItem
              className="mb-3"
              label={location.label}
              organisations={organisations!}
              key={location.id}
              selected={currentValues[location.id]}
              onChange={(value) => {
                setCurrentValues({
                  ...currentValues,
                  [location.id]: value,
                });
              }}
            />
          </div>
        );

        getLocationItems(
          allLocations.filter((l) => l.parent === location.id),
          level + 1
        );
      }
    };

    getLocationItems(allLocations.filter((l) => !l.parent));

    return results;
  }, [allLocations, currentValues, organisations]);

  return (
    <div className="d-flex flex-column overflow-hidden">
      {/* <ReactJson
        src={{
          currentValues,
          organisations,
          allLocations,
        }}
      /> */}

      <div className="m-1">{locationEditorItems}</div>
      <div className="d-flex flex-rows">
        <div className={cn(styles.titleText, 'flex-grow-1')} />
        <ActionBar
          large={false}
          actions={[
            {
              text: 'Save',
              intent: 'primary',
              icon: 'save',
              disabled: !allLocations?.length,
              onClick: () => {
                analytics.events.dataProvider.saveLocationMapClicked();
                const locationMap =
                  allLocations?.reduce<LocationMap>((prev, location, index) => {
                    const organisation = currentValues[location.id];
                    return [
                      ...prev,
                      {
                        value: location.id,
                        organisation,
                      },
                    ];
                  }, []) || [];
                return onSave(locationMap);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
