import trackEvent from '../trackEvent';

export const profileAdded = (traits = {}) => {
  trackEvent('Profiles - Profile Added', traits);
};

export const advancedEditorClicked = (traits = {}) => {
  trackEvent('Profiles - Advanced Editor Clicked', traits, true);
};

export const exportExcelClicked = (traits = {}) => {
  trackEvent('Profiles - Export Excel Clicked', traits, true);
};

export const filtersChanged = (traits = {}) => {
  trackEvent('Profiles - Filters Changed', traits, true);
};

export const filtersAdded = (traits = {}) => {
  trackEvent('Profiles - Filter Added', traits, true);
};

export const filtersRemoved = (traits = {}) => {
  trackEvent('Profiles - Filter Removed', traits, true);
};

export const transfersClicked = (traits = {}) => {
  trackEvent('Profiles - Profile Transfers Clicked', traits, true);
};
