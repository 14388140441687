import log from '@scrtracker/logging';
import { useContext } from 'react';
import { FederationContext, OrganisationContext } from '../context';

interface ItemWithFeatures {
  features?: {
    id: string;
    enabled: boolean;
  }[];
}
export const isFeatureEnabled = (key: string, item?: ItemWithFeatures) => {
  if (!item) {
    return false;
  }
  if (!item.features) {
    throw new Error('Must supply item with Features array');
  }
  const feature = item.features.find((f) => f.id === key.toLowerCase());

  if (!feature) {
    log.warn(`Unknown feature Key [${key}]`);
    return false;
  }

  return feature.enabled;
};

export const useFeature = (key: string) => {
  const organisation = useContext(OrganisationContext);
  return isFeatureEnabled(key, organisation);
};

export const useFederationFeature = (key: string) => {
  const federation = useContext(FederationContext);
  return isFeatureEnabled(key, federation);
};
