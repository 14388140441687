import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@blueprintjs/core';
import { parseBool } from '@hogwarts/validation';

const CheckBox = ({
  fieldHelpers,
  formField,
  fieldMeta,
  readOnly,
  label,
  large,
  alignIndicator,
}) => {
  const { t } = useTranslation();
  const value = parseBool(formField.value);
  if (fieldMeta.error) {
    // show this in red?
  }
  return (
    <Checkbox
      style={{
        marginBottom: 0,
      }}
      alignIndicator={alignIndicator}
      disabled={readOnly}
      checked={!!value}
      large={large == null ? true : large}
      onChange={async (args) => {
        const checked = args.target.checked;
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(checked);
      }}
      label={t(label)}
    />
  );
};

export default {
  name: 'CheckBox',
  options: [],
  decorators: {
    label: false,
  },
  Component: CheckBox,
};
