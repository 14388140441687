import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card, ButtonGroup, Button, Tag } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import Score from '../Score';

import styles from './styles.module.css';
import SectionTypeIcon from '../../SectionTypeIcon';

const UserProfileSection = ({
  label,
  icon,
  color,
  scores,
  documents,
  parentDragActive,
  allowEdit = true,
  allowHideChecks = false,
  onEdit,
  canPrint = false,
  onPrint,
  onSettings,
  allowUpload,
  onDocumentsClick,
  onDocumentsUpload,
}) => {
  const { t } = useTranslation();
  const dragging = useMemo(
    () => parentDragActive && allowUpload,
    [allowUpload, parentDragActive]
  );
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (files) => onDocumentsUpload({ files }),
  });

  return (
    <div
      {...getRootProps({
        className: cn(styles.sectionContainer, `section-${label}-dropzone`),
      })}
    >
      <Card
        elevation={1}
        className={cn(styles.sectionCard, {
          [styles.dropzoneActive]: isDragActive,
        })}
      >
        <div className={styles.sectionWrapper}>
          {/* <div
            className={styles.sectionColour}
            style={{ backgroundColor: color }}
          /> */}
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.sectionLogoGroup}>
                <SectionTypeIcon
                  className={styles.sectionLogo}
                  iconKey={icon}
                  color={color}
                  // iconStyle="fill"
                />
                {!!documents && !dragging && (
                  <Tag
                    large
                    minimal
                    className={styles.documents}
                    onClick={onDocumentsClick}
                    icon={<FontAwesomeIcon icon={['far', 'folders']} />}
                  >
                    {documents}
                  </Tag>
                )}
              </div>
              <div className={styles.mainContent}>
                <p className={styles.sectionHeading}>{label}</p>
                {dragging && (
                  <div className={styles.dropzone}>
                    <FontAwesomeIcon icon={['far', 'upload']} size="2x" />
                    <p className={styles.dropzoneText}>
                      {t('Drop Here to Upload')}
                    </p>
                  </div>
                )}
              </div>
              {!dragging && (
                <ButtonGroup large className={styles.buttons}>
                  {allowHideChecks && onSettings && (
                    <Button className={styles.button} onClick={onSettings}>
                      <FontAwesomeIcon icon={'cog'} />
                    </Button>
                  )}
                  {canPrint && onPrint && (
                    <Button className={styles.button} onClick={onPrint}>
                      <FontAwesomeIcon icon={'print'} />
                    </Button>
                  )}
                  {onEdit && (
                    <Button className={styles.button} onClick={onEdit}>
                      <FontAwesomeIcon icon={allowEdit ? 'pencil' : 'eye'} />
                    </Button>
                  )}
                </ButtonGroup>
              )}
            </div>
          </div>
        </div>
        {!dragging && (
          <div>
            {scores &&
              scores.map((score, idx) => <Score key={idx} {...score} />)}
          </div>
        )}
      </Card>
    </div>
  );
};

export default UserProfileSection;
