import { Button, InputGroup, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';

import { useDebounce } from '@hogwarts/ui-components-core';
import { getErrorIntent } from './utils';

const DEFAULT_DEBOUNCE_TIME = 250;

interface PasswordComponentProps {
  fieldKey: string;
  formField: { value: any };
  readOnly: boolean;
  placeHolder: string;
  fieldHelpers: any;
  fieldMeta: any;
  maxLength?: number;
  disableShowPassword?: boolean;
}
const PasswordComponent = ({
  fieldKey,
  formField,
  readOnly,
  placeHolder,
  fieldHelpers,
  fieldMeta,
  maxLength,
  disableShowPassword,
}: PasswordComponentProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const [value, setValue] = useState<string | null>(null);
  let showValue = value === null ? formField.value : value;
  if (showValue == null) showValue = '';

  const onChangeHandler = useDebounce((value) => {
    fieldHelpers.setTouched(true, false);
    fieldHelpers.setValue(value);
    setValue(null);
  }, DEFAULT_DEBOUNCE_TIME);

  return (
    <InputGroup
      id={fieldKey}
      intent={getErrorIntent(fieldMeta) as Intent}
      onBlur={(event) => {
        let value = event.target.value;
        if (value !== null) {
          fieldHelpers.setTouched(true, false);
          fieldHelpers.setValue(value, true);
          setValue(null);
        }
      }}
      // onFocus={() => {
      //   onChangeHandler('');
      //   setValue('');
      // }}
      onChange={(event) => {
        let value = event.target.value;
        onChangeHandler(value);
        setValue(value);
      }}
      autoComplete="off"
      maxLength={maxLength}
      placeholder={placeHolder}
      value={showValue}
      disabled={readOnly}
      type={showPassword ? 'text' : 'password'}
      rightElement={
        <Button
          minimal
          icon={showPassword ? 'unlock' : 'lock'}
          intent="warning"
          disabled={disableShowPassword}
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        />
      }
    />
  );
};

export default {
  name: 'Password Box',
  options: [],
  label: true,
  Component: PasswordComponent,
};
