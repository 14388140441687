import { DateTime } from 'luxon';

const CONFIDENTIAL_TAG = 'confidential';
const VERIFILE_TAG = 'verifile';

const mapTag = (tag: string) => {
  switch (tag) {
    case CONFIDENTIAL_TAG: {
      return {
        id: tag,
        label: 'Confidential',
        color: '#dd0000',
      };
    }
    case VERIFILE_TAG: {
      return {
        id: tag,
        label: 'Verifile',
        color: '#3B5E93',
      };
    }
    default: {
      return {
        id: tag,
        label: tag,
      };
    }
  }
};

export const getDocuments = (documents: any, scheme: any) => {
  const docs = [...documents];
  return (
    docs
      ?.sort((a, b) => b.createdAt - a.createdAt)
      .map((file) => ({
        id: file.id,
        name: file.filename,
        tags: file.tags?.map(mapTag),
        type: file.type,
        uploadDate: DateTime.fromMillis(+file.createdAt).toLocaleString(
          DateTime.DATE_FULL
        ),
        confidential: file.tags?.includes(CONFIDENTIAL_TAG),
        uploadTimestamp: file.createdAt,
        filestackHandle: file.filestackHandle,
        size: file.size,
        filename: file.filename,
        sectionKey: file.meta?.sectionKey ?? '',
        sectionName: file.meta?.sectionKey
          ? scheme.getSection(file.meta.sectionKey)?.label ??
            file.meta.sectionKey
          : '',
        uploadedBy: file.uploadedBy?.name,
      })) || []
  );
};
