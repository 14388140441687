import trackEvent from '../trackEvent';

export const termSearched = (traits = {}) => {
  trackEvent('Search - Term Searched', traits, true);
};

export const profileSelected = (traits = {}) => {
  trackEvent('Search - Profile Selected', traits, true);
};

export const searchCleared = (traits = {}) => {
  trackEvent('Search - Search Cleared', traits, true);
};
