import React from 'react';
import { TabBar } from '../../../../components';

interface ProfileTypeSelectorCoreProps {
  profileTypes: {
    key: string;
    label: string;
  }[];
  profileTypeKey?: string;
  onChange?: (profileTypeKey: string) => void;
}
export const ProfileTypeSelectorCore = ({
  profileTypes,
  profileTypeKey,
  onChange,
}: ProfileTypeSelectorCoreProps) => {
  if (!profileTypes.length) {
    return (
      <TabBar
        activeTab="error"
        tabs={[
          {
            key: 'error',
            name: 'Error: No Profile Types',
          },
        ]}
      />
    );
  }

  return (
    <TabBar
      activeTab={profileTypeKey}
      onTabChange={onChange}
      tabs={profileTypes.map((profileType) => ({
        key: profileType.key,
        name: profileType.label,
      }))}
    />
  );
};
