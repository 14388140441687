import { ListView } from '@hogwarts/ui-components-list';
import React from 'react';
import InsightsListItem from '../InsightsListItem';

const InsightsList = ({ insights }) => {
  return (
    <ListView
      className="w-100 h-100"
      border
      divider
      rows={insights}
      component={InsightsListItem}
      scrollToBottomEnabled={true}
      spreadRowItem
    />
  );
};

export default InsightsList;
