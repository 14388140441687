import React from 'react';

const SeparatorComponent = () => {
  return <hr />;
};

export default {
  name: 'Separator',
  options: [],
  decorators: {
    label: false,
  },
  Component: SeparatorComponent,
};
