import { useForms } from '@hogwarts/ui-components-forms';
import React, { useContext } from 'react';
import SignOffsListWidget from '../../../components/Dashboards/Widgets/SignOffsList';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { OrganisationContext } from '../../../context';
import { useFeature, useQuery } from '../../../hooks';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import { DATASOURCE_QUERY_SIGNOFFS, SignOffsResponse } from '../../../queries';

const WIDGET_FEATUREKEY = 'signoff';

interface SignOffsListProps {
  settings: any;
  onSettingsUpdate: any;
}
const SignOffsList = ({
  settings,
  onSettingsUpdate,
  ...rest
}: SignOffsListProps) => {
  const isRestricted = !useFeature(WIDGET_FEATUREKEY);
  const organisation = useContext(OrganisationContext);

  const { loading, data, error, refetch } = useQuery<SignOffsResponse[]>(
    DATASOURCE_QUERY_SIGNOFFS,
    {
      selector: 'querySignOffs.result',
      variables: {
        organisationKey: organisation.key,
      },
      pollInterval: organisation.demo ? 20000 : 60000,
    }
  );
  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: async (values: any) => {
      await onSettingsUpdate(values);
      refetch();
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
    ],
    initialValues: settings,
  });

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={!!error}
      unavailable={isRestricted}
      message={
        isRestricted
          ? 'This widget is not enabled for your Organisation.'
          : undefined
      }
      onSettingsClick={onSettingsUpdate ? () => showForm() : undefined}
      {...rest}
    >
      <SignOffsListWidget signOffs={data!} />
    </WidgetWrapper>
  );
};

export default {
  type: 'sign-offs-list',
  name: 'Sign Off List',
  description: 'List of Sign Offs for the organisation',
  widgetComponent: SignOffsList,
  defaultSettings: () => ({
    title: 'Sign Offs',
  }),
  restricted: (organisation: any) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
