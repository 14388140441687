import { AppToaster } from '@/utils/toaster';
import { Card, NonIdealState } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FilterBar from '../../../components/Filters/Bar';
import { TimelineActivityFilters } from '../../../containers/userProfile/utils/timelineFilters';
import { AnalyticsContext } from '../../../context';
import { useAlert } from '../../../hooks';
import ActivityItem, { Activity } from '../Activity';
import styles from './styles.module.css';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

interface TimelineProps {
  activities: Activity[];
  allowTimeline: boolean;
  removeActivity: Function;
  editActivity: Function;
  timelineFilters?: TimelineActivityFilters[];
  onFilter: (filters: Record<string, any>) => void;
  showUpdateActivityForm: (name: string, values: Record<string, any>) => void;
}

const Timeline = ({
  activities,
  allowTimeline,
  removeActivity,
  timelineFilters,
  onFilter,
  editActivity,
  showUpdateActivityForm,
}: TimelineProps) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert<{
    activityId: string;
    activityType: string;
  }>({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'This will permanently delete this activity, are you sure you wish to continue?'
        )}
      </p>
    ),
    onConfirm: async ({ activityId, activityType }) => {
      const result = await removeActivity({
        variables: { id: activityId },
      });

      const success = result?.data?.deleteActivity;

      AppToaster.show({
        message: t(success ? 'Activity Deleted' : 'Error Deleting Activity'),
        intent: success ? 'success' : 'danger',
        icon: success ? 'tick' : 'error',
      });

      analytics?.events.profile.activityDeleted({
        success,
        activityId,
        activityType,
      });
    },
  });

  if (!allowTimeline) {
    return (
      <Card>
        <NonIdealState
          className="my-5"
          icon={
            <FontAwesomeIcon color="red" icon={['far', 'exclamation-circle']} />
          }
          title={t('Access Denied')}
          description={t<string>(
            'You do not have permission to view Timeline Activities. Please contact your system administrator'
          )}
        />
      </Card>
    );
  }
  console.log('activities', activities);
  return (
    <>
      <ConfirmDeleteAlert />
      <Card elevation={1} className={styles.timeline}>
        {timelineFilters && (
          <div className={styles.filtersContainer}>
            <FilterBar filters={timelineFilters} onFilter={onFilter} />
          </div>
        )}
        {activities.length > 0 ? (
          <VerticalTimeline
            layout="1-column-left"
            lineColor="rgb(221, 221, 221)"
          >
            {activities.map(
              ({ id, documents, data, tags, type, date, owner }: Activity) => (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    boxSizing: 'border-box',
                    border: '1px solid rgb(221, 221, 221)',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0)',
                    padding: '0px',
                    borderRadius: '3px',
                  }}
                  shadowSize="medium"
                  contentArrowStyle={{
                    borderRight: '7px solid rgb(221, 221, 221)',
                  }}
                  icon={
                    <FontAwesomeIcon
                      size="2x"
                      icon={['far', 'triangle-exclamation']}
                      color={data.severity?.color ?? '#137cbd'}
                    />
                  }
                  iconStyle={{
                    background: '#fff',
                    color: '#fff',
                    boxShadow: '0px 0px 0px 4px rgb(221, 221, 221)',
                  }}
                >
                  <ActivityItem
                    key={id}
                    id={id}
                    documents={documents}
                    data={data}
                    tags={tags}
                    type={type}
                    date={date}
                    owner={owner}
                    showConfirmDeleteAlert={showConfirmDeleteAlert}
                    editActivity={editActivity}
                    showUpdateActivityForm={showUpdateActivityForm}
                  />
                </VerticalTimelineElement>
              )
            )}
          </VerticalTimeline>
        ) : (
          <NonIdealState
            icon={<FontAwesomeIcon icon={['far', 'file-alt']} />}
            title={t('No Activities Found')}
            description={t<string>(
              "It looks like you haven't recorded any activities for this profile yet, or you've filtered them all out."
            )}
          />
        )}
      </Card>
    </>
  );
};

export default Timeline;
