import { convertValue } from '@hogwarts/scheme-profiles';
import { Column, ColumnGroup } from '@hogwarts/ui-components-grid';
import { Scheme } from '@hogwarts/utils-schemes';
import { emptyValue } from '@hogwarts/validation';
import { getAllColumnsForScheme } from '../../profilesGridUtils';

const developer = false && process.env.NODE_ENV === 'development';

export const getBulkImporterColumns = (
  scheme: Scheme
): [ColumnGroup[], Column[]] => {
  const [schemeGroups, schemeFields] = getAllColumnsForScheme(scheme, {
    dontIncludeCalculatedFields: true,
    //@ts-ignore
    valueSetter: ({ colDef: { meta }, data: profile, oldValue, newValue }) => {
      if (newValue === oldValue) {
        return false;
      }
      let converted = convertValue(meta.type, newValue, {
        timezone: 'utc',
      });
      profile.data[meta.key] = converted;
      return true;
    },
    cellRendererSelector: ({ value, data: profile, context: field }) => {
      // We dont want to show errors for empty values, they can worry about this later.
      if (!profile || emptyValue(value)) return;
      if (profile.valid) return;

      const reasons = profile.reasons?.filter(
        (r: any) => r.field.key === field.key
      );

      if (!reasons || reasons.length === 0) {
        return;
      }
      return {
        component: 'warningRenderer',
        params: {
          reasons: reasons,
        },
      };
    },
  });

  if (developer) {
    schemeGroups?.unshift({
      key: '__developer',
      label: 'Developer',
    });
    schemeFields?.unshift(
      {
        key: 'index',
        label: 'Index',
        group: '__developer',
        editable: false,
        valueGetter: ({ data }) => {
          return data.index;
        },
        // lockPosition: true,
        // pinned: true,
        // lockPinned: true,
      },
      {
        key: 'key',
        label: 'Key',
        group: '__developer',
        editable: false,
        valueGetter: ({ data }) => {
          return data.key;
        },
      },
      {
        key: 'id',
        label: 'ID',
        group: '__developer',
        editable: false,
        valueGetter: ({ data }) => {
          return data.id;
        },
      }
    );
  }

  return [schemeGroups, schemeFields];
};
