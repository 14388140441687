import React from 'react';
import wondeLogo from '../../../../assets/providers/wonde.png';
import { ProviderMeta } from '../types';

const PROVIDER_KEY = 'wonde';

const HelpBlock = () => {
  return (
    <div>
      <p>To authorise a Wonde connection please click Book Installation.</p>

      <p>
        For further help on this, please see our{' '}
        <a href="https://go.scrtracker.com/3AtI4VY" target="blank">
          help guides.
        </a>
      </p>
    </div>
  );
};

interface ActionsProps {
  status: string;
  connected: boolean;
  children: (actions?: any[]) => JSX.Element;
}
const Actions = ({ connected, status, children }: ActionsProps) => {
  let actions: any[] = [];
  if (!connected) {
    // TODO: Wonde Auth Flow?!
    // Show a button for getting the School and DfE code?
    // actions.push({
    //   intent: 'primary',
    //   icon: 'sign-in',
    //   text: 'Authorise',
    //   onClick: () => {
    //     return showAuthorise();
    //   },
    // });
    return children(actions);
  }

  return children(actions);
};

export default {
  key: PROVIDER_KEY,
  name: 'Wonde',
  logo: wondeLogo,
  url: 'https://go.scrtracker.com/3wug03x',
  showOptions: {
    showConnection: false,
    showProfileTypeMap: true,
  },
  Actions,
  HelpBlock,
} as ProviderMeta;
