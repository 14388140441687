import React from 'react';

// this is our distinct toggle control throughout the app
// can we skin the blueprint one to match?
import Toggle from 'react-toggle';
import { parseBool } from '@hogwarts/validation';

const ToggleComponent = ({ fieldHelpers, formField, fieldMeta, readOnly }) => {
  const value = parseBool(formField.value);
  if (fieldMeta.error) {
    // show this in red?
  }
  return (
    <Toggle
      onBlur={formField.onBlur}
      onChange={(event) => {
        const checked = event.target.checked;
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(checked);
      }}
      checked={!!value}
      icons={true}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'Toggle',
  options: [],
  label: true,
  Component: ToggleComponent,
};
