import { gql } from '@apollo/client';

export const CREATE_API_KEY = gql`
  mutation {
    createUserApiKey
  }
`;

export const UPDATE_USER_PREFERENCES = gql`
  mutation updateUserPreferences(
    $firstname: String
    $lastname: String
    $updatedPreferences: JSON!
  ) {
    updateUserPreferences(
      firstname: $firstname
      lastname: $lastname
      updatedPreferences: $updatedPreferences
    )
  }
`;

export const ENABLE_REFERRAL_CODE = gql`
  mutation {
    enableReferralCode
  }
`;

export const OPEN_SUPPORT = gql`
  mutation openSupport($organisationId: ID!) {
    openSupport(organisationId: $organisationId)
  }
`;

export const CLOSE_SUPPORT = gql`
  mutation closeSupport($userId: String) {
    closeSupport(userId: $userId)
  }
`;

export const UPDATE_USER_LAST_AUTH = gql`
  mutation {
    updateUserLastAuth
  }
`;

export const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation {
    acceptTermsAndConditions
  }
`;
