import { Button, Classes, Dialog, Intent, Spinner } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { Error } from '@hogwarts/ui-components-core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationContext } from '../context';
import { useJobMonitor, useMutation } from '../hooks';
import { EXPORT_REPORT, EXPORT_REPORT_Type } from '../mutations';
import { getRequest } from '../services/request';
import { AppToaster } from '../utils/toaster';
import { usePermission } from './usePermission';

interface ReportDialogProps {
  opened?: boolean;
  status: string;
  result?: {
    id: string;
    filename: string;
    url: string;
  };
  setOpened: (state: boolean) => void;
}
export const ReportDialog = ({
  opened,
  status,
  result,
  setOpened,
}: ReportDialogProps) => {
  const { t } = useTranslation();
  let inProgress = !opened && status === 'inprogress';
  let isQueued = !opened && status === 'queued';
  let ready = !opened && status === 'completed';
  let errored = !opened && status === 'error';
  const emptyReport = ready && !result?.filename;
  const download = result?.filename?.endsWith('.xlsx');
  return (
    <Dialog
      icon={emptyReport ? 'error' : 'info-sign'}
      title={'Report Builder'}
      isOpen={errored || isQueued || inProgress || ready}
      onClose={() => setOpened(true)}
    >
      <div className={Classes.DIALOG_BODY}>
        {(isQueued || inProgress) && (
          <div>
            {isQueued && t('Your report is queued, please wait...')}
            {inProgress && t('Your report is being built...')}
            <Spinner className={'mt-4'} size={50} />
          </div>
        )}
        {ready && !emptyReport && <div>{t('Your report is ready!')}</div>}
        {ready && emptyReport && (
          <div>{t('Your report produced no results.')}</div>
        )}
        {errored && (
          <Error title={t('An error occurred generating your report.')} />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {(inProgress || isQueued) && (
            <Button
              intent={Intent.DANGER}
              onClick={() => {
                setOpened(true);
              }}
            >
              {t('Cancel')}
            </Button>
          )}
          {ready && (
            <>
              <Button
                intent={Intent.NONE}
                onClick={() => {
                  setOpened(true);
                }}
              >
                {t('Close')}
              </Button>
              {!emptyReport && (
                <Button
                  intent={Intent.SUCCESS}
                  onClick={async () => {
                    if (result?.id) {
                      const request = await getRequest();
                      const policyResponse = await request.get(
                        `/documents/policy/${result.id}/download`
                      );
                      if (policyResponse.status === 200) {
                        const secureUrl = `${result.url}?policy=${policyResponse.data.policy}&signature=${policyResponse.data.signature}`;
                        if (download) {
                          // @ts-ignore
                          window.location = secureUrl;
                        } else {
                          let win = window.open(secureUrl, '_blank');
                          if (win) {
                            win.focus();
                          }
                        }
                        setOpened(true);
                      } else {
                        AppToaster.show({
                          icon: 'error',
                          intent: 'danger',
                          message: t('Error starting download'),
                        });
                      }
                    }
                  }}
                >
                  {t(download ? 'Download' : 'Open')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export const useBuildReport = (): [
  (reportId: string, exportType?: string, reportParams?: any) => void,
  {
    disabled: boolean;
    component: typeof ReportDialog;
    props: ReportDialogProps;
  },
  boolean
] => {
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const organisation = useContext(OrganisationContext);

  const canPrintReports = usePermission(
    permissions.PROFILE_PRINT,
    organisation.id
  );

  const [opened, setOpened] = useState<boolean | undefined>(undefined);

  const [exportReport] = useMutation<string, EXPORT_REPORT_Type>(
    EXPORT_REPORT,
    {
      selector: 'exportReport',
      onCompleted: (newJobId) => {
        setJobId(newJobId);
      },
    }
  );

  const { status, result } = useJobMonitor(jobId);

  const start = async (
    reportId: string,
    exportType?: string,
    reportParams?: any
  ) => {
    if (!canPrintReports) return;
    setOpened(false);
    await exportReport({
      variables: {
        reportId,
        exportType,
        reportParams: {
          ...reportParams,
          organisationId: organisation.id,
          organisationKey: organisation.key,
        },
      },
    });
  };

  return [
    start,
    {
      disabled: !canPrintReports || status === 'queued',
      component: ReportDialog,
      props: { opened, status, result, setOpened },
    },
    canPrintReports,
  ];
};
