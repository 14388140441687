import { OrganisationContext } from '@/context';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface RiskAssessmentNotificationProps {
  label: string;
}
const RiskAssessmentNotification = ({
  label: role,
}: RiskAssessmentNotificationProps) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  // TODO: Need to grab the correct attribute (and check federation?)
  // Does this propogate?
  const email = get(organisation, `attributes.cognita_signoff_${role}`);
  if (!email) {
    return <div>{`Notifications: No email specified`}</div>;
  }

  return (
    <div>
      {t('Email reminders will be sent to {{email}}', {
        email,
      })}
    </div>
  );
};

export default {
  name: 'Risk Assessment Notification',
  options: [],
  decorators: {
    label: false,
  },
  Component: RiskAssessmentNotification,
};
