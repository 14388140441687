import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@blueprintjs/core';

import DateFilter from '../Date';
import MultiSelectFilter from '../MultiSelect';
import SelectFilter from '../Select';

import styles from './styles.module.css';

import { StoreQueryParams } from './store-query-params';

const filterComponentRegistry = {
  date: ({ id, selectedItem, storage, large, ...props }) => (
    <DateFilter
      large={!!large}
      key={id}
      dateRange={selectedItem}
      setDateRange={(range) =>
        storage.set(
          id,
          range.map((date) => date?.toISOString())
        )
      }
      {...props}
    />
  ),
  select: ({ id, selectedItem, storage, large, ...props }) => (
    <SelectFilter
      large={!!large}
      key={id}
      selectedItem={selectedItem}
      setSelectedItem={(item) => {
        storage.set(id, item.id);
      }}
      {...props}
    />
  ),
  multiselect: ({ id, selectedItem, storage, large, ...props }) => (
    <MultiSelectFilter
      large={!!large}
      key={id}
      selectedItems={Object.values(storage.get(id) || {}).map((item) => item)}
      setSelectedItems={(items) => {
        storage.set(id, items);
      }}
      onClear={() => {
        storage.clear(id);
      }}
      {...props}
    />
  ),
};

export const FilterBar = ({ onFilter, filters, large, storage }) => {
  // const { t } = useTranslation();

  const filterComponents = useMemo(() => {
    return filters.map((filter) => {
      const { type, id, ...props } = filter;
      const selectedItem = storage.get(id);
      let FilterComponent = filterComponentRegistry[type];
      if (!FilterComponent) {
        return null;
      }
      return (
        <ButtonGroup key={id} className="mr-1">
          <FilterComponent
            id={id}
            storage={storage}
            large={large}
            selectedItem={selectedItem}
            {...props}
          />
          {selectedItem != null && type !== 'multiselect' && (
            <Button
              intent={selectedItem ? 'success' : 'none'}
              large={!!large}
              icon={large ? 'cross' : 'small-cross'}
              onClick={() => {
                storage.clear(id);
              }}
            />
          )}
        </ButtonGroup>
      );
    });
  }, [filters, large, storage]);

  useEffect(
    () => {
      if (storage) {
        const filter = storage.getAll();
        let filtersSet = false;
        Object.keys(filter).forEach((key) => {
          if (key) filtersSet = true;
        });
        if (onFilter && filtersSet) {
          onFilter(filter);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.filterBar}>
      {filterComponents}
      {/* {storage.hasState && (
        <Button
          large={large}
          intent="primary"
          onClick={() => {
            storage.clear();
          }}
        >
          {t('Clear')}
        </Button>
      )} */}
    </div>
  );
};

FilterBar.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  large: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {
  onFilter: () => {},
};

export default StoreQueryParams(FilterBar);
