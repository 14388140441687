import React from 'react';

import { Card } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import cn from 'classnames';

import { Button } from '@hogwarts/ui-components-core';

const SchemeListItem = ({ name, allowed, onEdit }) => {
  const { t } = useTranslation();

  return (
    <Card className={'d-flex flex-row align-content-center'}>
      <div className={cn(styles.name, styles.alignMiddle)}>{name}</div>
      <div className={styles.alignMiddle}>
        <Button
          intent="PRIMARY"
          icon="pencil"
          large
          allowed={allowed}
          onClick={onEdit}
        >
          {t('Edit')}
        </Button>
      </div>
    </Card>
  );
};

export default SchemeListItem;
