import * as activities from './activities';
import * as bulkImporter from './bulkimporter';
import * as checks from './checks';
import * as dashboard from './dashboard';
import * as dataProvider from './dataprovider';
import * as documents from './documents';
import * as header from './header';
import * as insights from './insights';
import * as profile from './profile';
import * as profiles from './profiles';
import * as profilesGrid from './profilesgrid';
import * as search from './search';
import * as settings from './settings';
import * as sia from './sia';
import * as signoff from './signoff';
import * as transfers from './transfers';
import * as users from './users';

export default {
  sia,
  bulkImporter,
  dashboard,
  search,
  documents,
  header,
  insights,
  profile,
  profiles,
  profilesGrid,
  dataProvider,
  settings,
  transfers,
  users,
  signoff,
  checks,
  activities,
};
