import { gql } from '@apollo/client';

export const UPDATE_SCHEME_DRAFT = gql`
  mutation updateSchemeDraft($id: ID!, $data: JSON!) {
    updateSchemeDraft(id: $id, data: $data) {
      id
      cacheKey
      name
      mixin
      parent
      data
    }
  }
`;

export const PUBLISH_SCHEME_DRAFT = gql`
  mutation publishSchemeDraft($id: ID!) {
    publishSchemeDraft(id: $id) {
      id
      cacheKey
      name
      mixin
      parent
      data
    }
  }
`;

export const DISCARD_SCHEME_DRAFT = gql`
  mutation discardSchemeDraft($id: ID!) {
    discardSchemeDraft(id: $id) {
      id
      cacheKey
      name
      mixin
      parent
      data
    }
  }
`;
