import React, { useContext, useMemo } from 'react';
import { PageHeader } from '../../../../components';
import { AnalyticsContext, OrganisationContext } from '../../../../context';
import { useQuery } from '../../../../hooks';
import { DATASOURCE_QUERY_SIGNOFFS } from '../../../../queries';
import SignOffsGridView from './gridView';

const SignOffReport = ({ insight, onExport }: any) => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);

  const { loading, data, error } = useQuery(DATASOURCE_QUERY_SIGNOFFS, {
    selector: 'querySignOffs.result',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const actions = useMemo(() => {
    if (insight.exportType) {
      return [
        {
          // Intent, Icon + Text
          ...insight.exportButtonProps,
          onClick: () => {
            analytics.events.insights.exportViewClicked();
            return onExport();
          },
        },
      ];
    }
  }, [insight, analytics, onExport]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Unknown Error Occurred</div>;
  }

  return (
    <div className="row h-100">
      <PageHeader noWrapper={false} actions={actions} />
      <div className="w-100">
        <SignOffsGridView signOffs={data} />
      </div>
    </div>
  );
};

export default SignOffReport;
