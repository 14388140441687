import { parseDuration } from '@hogwarts/conditionals';
import { useForms } from '@hogwarts/ui-components-forms';
import { DateTime } from 'luxon';
// since v1.0 stringify is not exported from 'use-query-params',
// so you must install the 'query-string' package in case you need it
import { stringify } from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrayParam,
  encodeQueryParams,
  JsonParam,
  StringParam,
} from 'use-query-params';
import ExpiredDatesCheckList from '../../../components/Dashboards/Widgets/ExpiredDatesCheckList';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useLazyQuery } from '../../../hooks';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import { DATASOURCE_QUERY_EXPIRY_DATES_BY_CHECK } from '../../../queries';

const WIDGET_FEATUREKEY = 'widgets.expiredchecksbycheck';

const Container = ({ settings, onSettingsUpdate, ...rest }) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);

  const days = 14;

  const [
    getProfiles,
    { startPolling, stopPolling, loading, error, data: checks },
  ] = useLazyQuery(DATASOURCE_QUERY_EXPIRY_DATES_BY_CHECK, {
    selector: 'queryExpiryDatesByCheck.result',
    pollInterval: organisation.demo ? 20000 : 60000,
  });
  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      // {
      //   key: 'condition',
      //   type: 'conditional',
      //   label: 'Filter Logic',
      //   scheme: organisation?.scheme,
      // },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },

      // {
      //   type: 'separator',
      // },

      // ...organisation.scheme.ratingSystems.reduce((prev, rs) => {
      //   return [
      //     ...prev,
      //     {
      //       type: 'title',
      //       label: rs.label,
      //     },
      //     {
      //       key: `ratings.${rs.key}`,
      //       type: 'singleselect',
      //       validate: 'required',
      //       values: [
      //         {
      //           id: 'all',
      //           value: 'Show All',
      //         },
      //         {
      //           id: 'exclude',
      //           value: 'Show not ready',
      //         },
      //         {
      //           id: 'include',
      //           value: 'Show Ready',
      //         },
      //       ],
      //     },
      //   ];
      // }, []),
    ],
    initialValues: {
      includeRatingSystems: {},
      ...settings,
    },
    onShow: () => {
      if (stopPolling) {
        stopPolling();
      }
    },
    onHide: () => {
      if (startPolling) {
        startPolling();
      }
    },
  });

  useEffect(() => {
    if (!organisation) return;
    getProfiles({
      variables: {
        days,
        organisationKey: organisation.key,
        condition: settings.condition,

        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((tagKey) =>
            settings.includedProfileTypes[tagKey] ? tagKey : null
          )
          .filter(Boolean),

        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),

        ratings: Object.keys(settings.ratings || {})
          .map((systemKey) => {
            switch (settings.ratings[systemKey]) {
              case 'exclude': {
                return {
                  key: systemKey,
                  include: false,
                };
              }
              case 'include': {
                return {
                  key: systemKey,
                  include: true,
                };
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean),
      },
    });
  }, [organisation, getProfiles, settings]);

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      disableScroll
      {...rest}
    >
      <ExpiredDatesCheckList
        organisationKey={organisation.key}
        checks={checks}
        onCheckSelected={({ key: fieldKey, duration: durationText }) => {
          const duration = parseDuration(durationText);

          const cutoffdate = DateTime.utc()
            .minus(duration)
            .plus({ days })
            .startOf('day')
            .toJSDate();

          const condition = {
            variables: {
              cutoffdate$: {
                type: 'date',
                value: cutoffdate,
              },
              field$: {
                type: 'date',
                source: ['field', fieldKey],
              },
            },
            conditions: [
              {
                when: 'field$',
                comparison: [
                  'earlierThan',
                  {
                    range: 'exact',
                    value: 'cutoffdate$',
                  },
                ],
              },
            ],
          };

          const field = organisation.scheme.getField(fieldKey);

          const encodedQuery = encodeQueryParams(
            {
              condition: JsonParam,
              columns: ArrayParam,
              tags: ArrayParam,
              profileTypes: ArrayParam,
              title: StringParam,
            },
            {
              title: `Expired Dates: ${field.sectionItem.label} - ${field.label}`,
              columns: [
                'tags',
                'rating_std',
                'rating_dfe',
                'firstname',
                'lastname',
                'typeKey',
                'employment_position',
                'startdate',
                fieldKey,
              ],
              condition,
              profileTypes: Object.keys(settings.includedProfileTypes || {})
                .map((tagKey) =>
                  settings.includedProfileTypes[tagKey] ? tagKey : null
                )
                .filter(Boolean),
              tags: Object.keys(settings.includedTags || {})
                .map((tagKey) =>
                  settings.includedTags[tagKey] ? tagKey : null
                )
                .filter(Boolean),
            }
          );

          history.push(
            `/${organisation.key}/profilesquery?${stringify(encodedQuery)}`
          );

          analytics.events.dashboard.widget.expiredChecksByCheck.profileSelected();
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'expired-checks-by-check',
  name: 'List of Expiring Checks',
  description: 'Displays a list of expiring checks with their status.',
  widgetComponent: Container,
  defaultSettings: (scheme) => ({
    title: 'Expiring Checks',
    condition: {
      conditions: [],
      operator: 'and',
      enabled: true,
    },

    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,

    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
  restricted: (organisation) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
