import colorpicker from './ColorPicker2';
import colorpicker_hex from './ColorPicker3';
import bpmultiselect from './bpmultiselect';
import button from './button';
import callout from './callout';
import checkbox from './checkbox';
import checklist from './checklist';
import datepicker from './datepicker';
import documentlist from './documentlist';
import helptext from './helptext';
import hyperlink from './hyperlink';
import inlinetextarea from './inlinetextarea';
import inlinetextbox from './inlinetextbox';
import label from './label';
import loading from './loading';
import markdown from './markdown';
import multiselect from './multiselect';
import multiselectlist from './multiselectlist';
import multisuggest from './multisuggest';
import numeric from './numeric';
import object from './object';
import password from './password';
import roles from './roles';
import separator from './separator';
import singleselect from './singleselect';
import switch_ from './switch';
import tabs from './tabs';
import textarea from './textarea';
import textbox from './textbox';
import time from './time';
import title from './title';
import toggle from './toggle';
import valueslist from './valueslist';
// component:
// example fields:
// dbs_meta_dbs_renewal
// ra_meta_0
// ra_3way_open_ht_confirm_notify

// tabs: ra_reviews

interface FormComponent {
  name: string;
  options?: any[];
  decorators?: Record<string, boolean>;
  Component: React.FC<any>;
}

const builtInComponents: Record<string, FormComponent> = {
  title,

  markdown,

  roles,

  tabs,
  button,

  label,
  separator,
  helptext,
  callout,

  checkbox,
  switch: switch_,
  toggle,

  numeric,

  password,
  textbox,
  inlinetextbox,
  inlinetextarea,
  textarea,
  object,
  datepicker,
  time,
  singleselect,
  multiselect,
  multiselectlist,
  checklist,

  colorpicker,
  colorpicker_hex,

  loading,
  hyperlink,

  valueslist,
  multisuggest,
  bpmultiselect,
  documentlist,
};

const registeredComponents: Record<string, FormComponent> = {};

export const registerComponent = (key: string, component: FormComponent) => {
  if (registeredComponents[key]) {
    throw new Error(`Component [${key}] aleady registered`);
  }
  if (!component) {
    throw new Error(`Component for [${key}] cannot be null`);
  }

  // if (component.label === true) {
  //   registeredComponents[key] = {
  //     ...component,
  //     Component: LabelDecorator(component.Component),
  //   };
  //   return;
  // }

  registeredComponents[key] = component;
};

for (const key of Object.keys(builtInComponents)) {
  let component = builtInComponents[key];
  registerComponent(key, component);
}

export default registeredComponents;
