import { gql } from '@apollo/client';

export interface ProfileResponse {
  id: string;
  name: string;
  typeKey: string;
  data: Record<string, any>;
  dataError: string;
  attributes: Record<string, any>;
  tags: string[];
  ratings: {
    cacheKey: string;
    systemKey: string;
    score: number;
  };
  meta: Record<string, any>;
  // created: string;
  // updated: string;
}

export type UPDATE_PROFILE_DATA_MUTATION_Response = ProfileResponse;
export const UPDATE_PROFILE_DATA_MUTATION = gql`
  mutation updateProfileData(
    $profileId: ID!
    $data: JSON
    $meta: JSON
    $trace: [JSON!]
  ) {
    updateProfileData(
      profileId: $profileId
      data: $data
      meta: $meta
      trace: $trace
    ) {
      id
      typeKey
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      data
      dataError
      attributes
      meta
    }
  }
`;

export type BATCH_PROFILE_UPDATE_MUTATION_Response = ProfileResponse[];
export const BATCH_PROFILE_UPDATE_MUTATION = gql`
  mutation batchProfileUpdate(
    $organisationKey: String!
    $updates: [ProfileUpdate!]
  ) {
    batchProfileUpdate(organisationKey: $organisationKey, updates: $updates) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      created
      updated
    }
  }
`;

export type MERGE_PROFILES_MUTATION_Response = ProfileResponse[];
export const MERGE_PROFILES_MUTATION = gql`
  mutation mergeProfiles($preserveProfileId: ID!, $duplicateProfileId: ID!) {
    mergeProfiles(
      preserveProfileId: $preserveProfileId
      duplicateProfileId: $duplicateProfileId
    ) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      attributes
      created
      updated
    }
  }
`;

export const QUEUE_BULK_IMPORT_MUTATION = gql`
  mutation queueBulkImport($organisationKey: String!) {
    queueBulkImport(organisationKey: $organisationKey)
  }
`;

export const DELETE_BULK_IMPORT_PROFILES_MUTATION = gql`
  mutation deleteBulkImportProfiles(
    $organisationKey: String!
    $keys: [String!]!
  ) {
    deleteBulkImportProfiles(organisationKey: $organisationKey, keys: $keys)
  }
`;

export const DELETE_ALL_BULK_IMPORT_PROFILES_MUTATION = gql`
  mutation deleteAllBulkImportProfiles($organisationKey: String!) {
    deleteAllBulkImportProfiles(organisationKey: $organisationKey)
  }
`;

export const UPDATE_BULK_IMPORT_PROFILES_MUTATION = gql`
  mutation updateBulkImportProfiles(
    $organisationKey: String!
    $profiles: [BulkImportProfileUpdate!]!
  ) {
    updateBulkImportProfiles(
      organisationKey: $organisationKey
      profiles: $profiles
    ) {
      id
      key
      index
      typeKey
      data
    }
  }
`;

export const CREATE_PROFILE_MUTATION = gql`
  mutation createProfile(
    $organisationKey: String!
    $profileTypeKey: String!
    $profileData: JSON!
  ) {
    createProfile(
      organisationKey: $organisationKey
      profileTypeKey: $profileTypeKey
      profileData: $profileData
    ) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      created
      updated
    }
  }
`;

export const DELETE_PROFILE_MUTATION = gql`
  mutation deleteProfile($id: ID!, $organisationKey: String!) {
    deleteProfile(id: $id, organisationKey: $organisationKey) {
      id
    }
  }
`;

export interface DELETE_MULTIPLE_PROFILES_MUTATION_Response {
  id: string;
  deleted: boolean;
  error: string;
}
export const DELETE_MULTIPLE_PROFILES_MUTATION = gql`
  mutation deleteMultipleProfiles(
    $profileIds: [ID!]!
    $organisationKey: String!
  ) {
    deleteMultipleProfiles(
      profileIds: $profileIds
      organisationKey: $organisationKey
    ) {
      id
      deleted
      error
    }
  }
`;

export const COPY_PROFILE_TO_ORGANISATION_MUTATION = gql`
  mutation copyProfileToOrganisation($profileId: ID!, $organisationId: ID!) {
    copyProfileToOrganisation(
      profileId: $profileId
      organisationId: $organisationId
    ) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      created
      updated
    }
  }
`;

export const CHANGE_PROFILE_ORGANISATION_MUTATION = gql`
  mutation changeProfileOrganisation($profileId: ID!, $organisationId: ID!) {
    changeProfileOrganisation(
      profileId: $profileId
      organisationId: $organisationId
    ) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      created
      updated
    }
  }
`;

export const REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION = gql`
  mutation requestChangeProfileOrganisation(
    $profileId: ID!
    $organisationId: ID!
  ) {
    requestChangeProfileOrganisation(
      profileId: $profileId
      organisationId: $organisationId
    ) {
      profileId
    }
  }
`;

export const CANCEL_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION = gql`
  mutation cancelRequestChangeProfileOrganisation($profileId: ID!) {
    cancelRequestChangeProfileOrganisation(profileId: $profileId)
  }
`;

export const ACCEPT_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION = gql`
  mutation acceptRequestChangeProfileOrganisation(
    $profileId: ID!
    $organisationId: ID!
  ) {
    acceptRequestChangeProfileOrganisation(
      profileId: $profileId
      organisationId: $organisationId
    ) {
      id
      name
      organisation {
        key
        name
      }
    }
  }
`;

export const CHANGE_PROFILE_TYPE_MUTATION = gql`
  mutation changeProfileType($profileId: ID!, $profileTypeKey: String!) {
    changeProfileType(profileId: $profileId, profileTypeKey: $profileTypeKey) {
      id
      name
      typeKey
      data
      dataError
      attributes
      tags
      ratings {
        cacheKey
        systemKey
        score
      }
      meta
      created
      updated
    }
  }
`;

export const ROLLBACK_PROFILE_MUTATION = gql`
  mutation rollbackProfile($profileId: ID!, $versionId: ID!) {
    rollbackProfile(profileId: $profileId, versionId: $versionId)
  }
`;
