import React from 'react';
import { Select } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';

import styles from './style.module.css';

const ProfileTypeSelector = ({
  profileTypes,
  selectedProfileType,
  onProfileTypeChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectorContainer}>
      <Select
        large
        items={[
          { key: null, label: t('Default Settings') },
          ...profileTypes.filter((p) => p.usable),
        ]}
        valueField="key"
        textField="label"
        popoverProps={{
          portalClassName: 'profileTypeSelector',
          minimal: true,
        }}
        itemsDescription={t('Default Settings')}
        value={selectedProfileType}
        onChange={({ key }) => onProfileTypeChange(key)}
      />
    </div>
  );
};

export default ProfileTypeSelector;
