import { TabBar } from '@hogwarts/ui-components-core';
import React, { useContext, useMemo } from 'react';
import { AnalyticsContext, OrganisationContext } from '../../../context';

interface TabBarContainerProps {
  brandColor: string;
}
const TabBarContainer = ({ brandColor }: TabBarContainerProps) => {
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);

  const tabs = useMemo(() => {
    let result: any = [];
    if (!organisation) return result;
    result.push({
      title: 'Dashboard',
      link: `/${organisation.key}`,
      exact: true,
      onClick: () => {
        analytics.events.header.dashboardClicked();
      },
    });
    result.push({
      title: 'Profiles',
      link: `/${organisation.key}/profiles`,
      exact: false,
      onClick: () => {
        analytics.events.header.profilesClicked();
      },
      isActive: (_: unknown, location?: { pathname: string }) => {
        return (
          location &&
          (location.pathname.includes('/profiles') ||
            location.pathname.includes('/editor'))
        );
      },
    });
    result.push({
      title: 'Insights',
      link: `/${organisation.key}/insights`,
      exact: true,
      onClick: () => {
        analytics.events.header.insightsClicked();
      },
    });
    result.push({
      title: 'Settings',
      link: `/${organisation.key}/settings?setting=user.notifications`,
      exact: false,
      onClick: () => {
        analytics.events.header.settingsClicked();
      },
    });
    return result;
  }, [analytics, organisation]);

  return <TabBar brandColor={brandColor} tabs={tabs} />;
};

export default TabBarContainer;
