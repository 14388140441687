import { Intent, Tag } from '@blueprintjs/core';
import { toTitleCase } from '@hogwarts/utils';
import React from 'react';

interface CheckStatusProps {
  status: string;
}
export const CheckStatusTag = ({ status }: CheckStatusProps) => {
  let intent: Intent;

  if (!status) {
    intent = 'none';
    status = 'Queued';
  } else if (status === 'COMPLETED' || status.startsWith('COMPLETED - GREEN')) {
    intent = 'success';
    status = 'Completed';
  } else if (status.startsWith('COMPLETED - AMBER')) {
    intent = 'warning';
    status = 'Completed';
  } else if (status.startsWith('COMPLETED - RED')) {
    intent = 'danger';
    status = 'Completed';
  } else if (status.startsWith('CANCELLED')) {
    intent = 'danger';
  } else if (status.startsWith('WAITING FOR CLIENT TO CONFIRM ID')) {
    intent = 'primary';
  } else {
    // switch (status) {
    //   default:
    //   case 'AWAITING CANDIDATE ENTRY':
    //   case 'APPLICATION':
    //   case 'INFORMATION REQUIRED':
    //   case 'PAYMENT APPROVAL REQUIRED':
    //   case 'PROCESSING':
    //   case 'APPLICATION ACCEPTED BY DBS':
    //   case 'QUALITY CHECKING': {
    //     break;
    //   }
    // }
    intent = 'none';
  }

  return <Tag intent={intent}>{toTitleCase(status)}</Tag>;
};
