import { Classes, Drawer } from '@blueprintjs/core';
import React, { useContext, useMemo, useState } from 'react';

import { Error as ErrorComponent } from '@hogwarts/ui-components-core';
import { CandidateIdentity } from '../../components/CandidateIdentity';
import { Candidate } from '../../components/CandidateIdentity/Helpers';
import { OrganisationContext } from '../../context';
import { useMutation } from '../../hooks';
import { CONFIRM_IDENTITY } from '../../mutations';
import { GET_PROFILE_CHECK_ORDERS } from '../../queries';
import { request } from '../../services';

interface Check {
  awaitingConfirmId: boolean;
  documents: string[];
}

interface CheckOrder {
  id: string;
  orderId?: string;
  checks: Check[];
  awaitingConfirmId: boolean;
  candidate?: Candidate;
  jobRoleId?: string;
}

type Document = { id: string; description: string } & Record<string, string>;
interface IdentityDocumentResponse {
  primary: Document[];
  primary1a: Document[];
  secondary: Document[];
  financial: Document[];
}

interface ConfirmIdentityProps {
  isOpen: boolean;
  order?: CheckOrder;
  onClose: () => void;
  documents?: string[];
  documentTypes?: any;
  error?: boolean;
  validationErrors?: string[];
  onConfirmIdentity: (args: {
    firstName: string;
    lastName: string;
    documents: string[];
    candidate: any;
  }) => void;
}
const ConfirmIdentity = ({
  isOpen,
  documentTypes,
  documents,
  order,
  onClose,
  onConfirmIdentity,
  validationErrors,
  error,
}: ConfirmIdentityProps) => {
  return (
    <Drawer
      enforceFocus={false}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={'Confirm Candidate Identity'}
      size={'500px'}
      isOpen={isOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {error ? (
            <ErrorComponent />
          ) : (
            <CandidateIdentity
              candidate={order?.candidate}
              documents={documents}
              documentTypes={documentTypes}
              onConfirmIdentity={onConfirmIdentity}
              validationErrors={validationErrors}
            />
          )}

          {/* {mode === 'ORDER-ERRORS' && <ValidationErrors errors={errors} />} */}
        </div>
      </div>
    </Drawer>
  );
};

interface ConfirmIdentityResponse {
  confirmed: boolean;
  validationErrors: {
    errorLevel: number;
    fieldPath: string;
    message: string;
  }[];
}

const useConfirmIdentity = (
  profileId: string
): [
  React.FC<ConfirmIdentityProps>,
  ConfirmIdentityProps,
  (order: CheckOrder) => Promise<void>
] => {
  const organisation = useContext(OrganisationContext);

  const [confirmIdentityAction] = useMutation<ConfirmIdentityResponse>(
    CONFIRM_IDENTITY,
    {
      selector: 'confirmIdentity',
      refetchQueries: [
        {
          query: GET_PROFILE_CHECK_ORDERS,
          variables: {
            organisationKey: organisation.key,
            profileId,
          },
        },
      ],
    }
  );

  const [validationErrors, setValidationErrors] = useState<string[]>();
  const [order, setOrder] = useState<CheckOrder | null>();
  const [dbsDocumentList, setDbsDocumentList] =
    useState<IdentityDocumentResponse>();

  const [error, setError] = useState(false);

  const showConfirmIdentity = async (order: CheckOrder) => {
    setOrder(order);

    const candidateNationality =
      order.candidate?.Identity?.PassportDocument?.PassportIssuingCountry ||
      order.candidate?.PersonalInfo?.CurrentNationality ||
      'GB';

    // Given the candidate nationality and job role, get the document types
    try {
      const response = await request<IdentityDocumentResponse>(
        `checks/verifile/identityDocuments?nationality=${candidateNationality}&jobRoleId=${order.jobRoleId}`,
        {
          retries: 3,
          timeout: 30000,
        }
      );
      if (response.status === 200) {
        let dbsDocumentList = response.data;
        setDbsDocumentList(dbsDocumentList);
      } else {
        setError(true);
      }
    } catch (ex) {
      setError(true);
    }
  };

  const props = useMemo<any>(() => {
    if (!order) {
      return {
        error,
        isOpen: false,
        onClose: () => {
          setOrder(null);
        },
      };
    }

    if (!dbsDocumentList) {
      return {
        error,
        isOpen: true,
        order,
        documents: undefined,
        onClose: () => {
          setOrder(null);
        },
      };
    }

    const documentTypes = [
      ...(dbsDocumentList.primary || []).map((p) => ({
        ...p,
        type: 'primary',
      })),
      ...(dbsDocumentList.primary1a || []).map((p) => ({
        ...p,
        type: 'primary',
      })),
      ...(dbsDocumentList.secondary || []).map((p) => ({
        ...p,
        type: 'secondary',
      })),
      ...(dbsDocumentList.financial || []).map((p) => ({
        ...p,
        type: 'financial',
      })),
    ].filter(Boolean);
    const documents = order.checks.find((c) => c.awaitingConfirmId)?.documents;

    const onConfirmIdentity = async ({
      documents,
      candidate,
      firstName,
      lastName,
    }: {
      documents: string[];
      candidate: any;
      firstName: string;
      lastName: string;
    }) => {
      if (!dbsDocumentList || !order) return;

      const primaryDocuments = [
        ...(dbsDocumentList.primary || [])
          .filter((d) => documents.includes(d.id))
          .map((d) => d.id),
        ...(dbsDocumentList.primary1a || [])
          .filter((d) => documents.includes(d.id))
          .map((d) => d.id),
      ];
      const secondaryDocuments = (dbsDocumentList.secondary || [])
        .filter((d) => documents.includes(d.id))
        .map((d) => d.id);
      const financialDocuments = (dbsDocumentList.financial || [])
        .filter((d) => documents.includes(d.id))
        .map((d) => d.id);

      const confirmResult = await confirmIdentityAction({
        variables: {
          organisationId: organisation.id,
          orderId: order.orderId,
          candidateChanges: candidate,
          primaryDocuments,
          secondaryDocuments,
          financialDocuments,
          checkedByFirstName: firstName,
          checkedByLastName: lastName,
          codeOfPracticeConfirmed: true,
        },
      });

      if (confirmResult.data!.confirmed) {
        setOrder(null);
      } else {
        setValidationErrors(
          confirmResult.data!.validationErrors.map((v) => v.message)
        );
      }

      return confirmResult;
    };
    return {
      onConfirmIdentity,
      error,
      documentTypes,
      documents,
      isOpen: true,
      order,
      validationErrors,
      onClose: () => {
        setOrder(null);
      },
    };
  }, [
    confirmIdentityAction,
    dbsDocumentList,
    error,
    order,
    organisation.id,
    validationErrors,
  ]);

  return [ConfirmIdentity, props, showConfirmIdentity];
};

export default useConfirmIdentity;
