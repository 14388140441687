import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { PermissionsViewer, TabBar } from '../../../components';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '../../../context';
import { useMutation, usePermission, useQuery } from '../../../hooks';
import {
  CONNECT_SIGN_IN_APP,
  QUEUE_SIA_IMPORT_NOW,
  UPDATE_SIGN_IN_APP,
} from '../../../mutations';
import {
  GET_FEDERATIONS,
  GET_ORGANISATION_DETAIL,
  GET_ORGANISATION_SIAGROUPS,
  GET_SIA_IMPORT_STATUS,
} from '../../../queries';

import { Card } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { ActionWithChildren } from '@hogwarts/ui-components-core';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import SignInAppConnected from '../../../components/Settings/SignInApp/Connected';
import SignInAppNotConnected from '../../../components/Settings/SignInApp/NoConnection';
import { DISCONNECT_SIGN_IN_APP } from '../../../mutations';
import { ProfileTypeMap } from '../../../types';
import { ConnectionEditor } from './components/ConnectionEditor';
import { FieldMapEditor } from './components/FieldMapEditor';
import { ProfileTypeMapEditor } from './components/ProfileTypeMapEditor';

interface AppliedPermissionsResponse {
  identity: string; // "employee.read",
  name: string; // "Employee read",
  description?: string; // "View employee data",
  parent?: string;
  group: string; // "Employee",
  active_from: {
    date: string; // "2023-06-16 10:12:38.000000",
    timezone_type: 3;
    timezone: string; // "Europe/London"
  };
  optional: boolean;
  approved: boolean;
  declined: boolean;
}

interface SignInAppConnectionOptions {
  apiKey?: any;
  siaApiKey?: string;
}

interface NewConnectionDetails {
  id: string;
  ownerType: string;
  ownerId: string;
  integrationKey: string;
  connected: boolean;
  connectionOptions?: SignInAppConnectionOptions;
  configurationOptions?: Record<string, any>;
  enabled: boolean;
  newApiKey: string;
}

interface Tab<T = any> {
  key: string;
  name: string;
  Component: (props: T) => JSX.Element;
}
interface SIAGroup {
  id: string;
  name: string;
  type: string;
}

const SignInAppContainer = () => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);

  const organisationPermission = usePermission(
    permissions.SIA_CONFIGURE,
    organisation?.id
  );
  const federationPermission = usePermission(
    permissions.SIA_CONFIGURE,
    federation?.id
  );

  const [newConnectionDetails, setNewConnectionDetails] =
    useState<NewConnectionDetails | null>(null);

  const connection = newConnectionDetails || organisation.siaConnection;

  const { data: siaGroups } = useQuery<SIAGroup[]>(GET_ORGANISATION_SIAGROUPS, {
    variables: {
      organisationKey: organisation.key,
    },
    selector: 'organisations[0].siaGroups',
  });

  const { data: siaImportStatus, startPolling: startRefreshingImportStatus } =
    useQuery<{
      busy: boolean;
      status: { label: string; value: string }[];
    }>(GET_SIA_IMPORT_STATUS, {
      variables: {
        organisationKey: organisation.key,
      },
      selector: 'organisations[0].siaImportStatus',
      fetchPolicy: 'network-only',
    });

  const [queueSiaImportNow] = useMutation(QUEUE_SIA_IMPORT_NOW, {
    refetchQueries: [
      {
        query: GET_SIA_IMPORT_STATUS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [connectSignInApp] = useMutation(CONNECT_SIGN_IN_APP, {
    selector: 'connectSignInApp',
  });

  const [updateSignInApp] = useMutation(UPDATE_SIGN_IN_APP, {
    selector: 'updateSignInApp',
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation.key,
        },
      },
      federation && {
        query: GET_FEDERATIONS,
        variables: {
          federationKey: federation?.key,
        },
      },
    ].filter(Boolean),
  });

  const [disconnectSignInApp] = useMutation(DISCONNECT_SIGN_IN_APP, {
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation.key,
        },
      },
      federation && {
        query: GET_FEDERATIONS,
        variables: {
          federationKey: federation?.key,
        },
      },
    ].filter(Boolean),
  });

  const availableActions: ActionWithChildren[] = useMemo(() => {
    if (connection) {
      const canImportNow = true;
      let disabled = siaImportStatus?.busy === true;

      const standardActions: ActionWithChildren[] = [];
      standardActions.push({
        intent: 'primary',
        text: 'Import',
        icon: 'sync',
        allowed: canImportNow,
        disabled,
        onClick: () => {},
        actions: [
          {
            intent: 'primary',
            text: 'Import Now',
            allowed: canImportNow,
            icon: 'sync',
            disabled,
            onClick: async () => {
              analytics.events.dataProvider.importNowClicked({
                PROVIDER_KEY: 'sia',
              });
              await queueSiaImportNow({
                variables: {
                  organisationId: organisation.id,
                  discoveryMode: false,
                },
              });
            },
          },
          {
            intent: 'primary',
            text: 'Discovery Mode',
            allowed: canImportNow,
            icon: 'sync',
            disabled,
            onClick: async () => {
              analytics.events.dataProvider.importDiscoveryClicked({
                PROVIDER_KEY: 'sia',
              });
              await queueSiaImportNow({
                variables: {
                  organisationId: organisation.id,
                  discoveryMode: true,
                },
              });
            },
          },
        ],
      });

      if (connection.ownerType === 'ORGANISATION') {
        standardActions.push({
          text: 'Disconnect',
          allowed: organisationPermission,
          icon: 'link-slash',
          intent: 'danger',
          confirm: true,
          disabled,
          onClick: async () => {
            await disconnectSignInApp({
              variables: {
                ownerType: 'ORGANISATION',
                ownerId: organisation.id,
              },
            });
            setNewConnectionDetails(null);
          },
        });
      } else if (connection.ownerType === 'FEDERATION') {
        standardActions.push({
          text: 'Disconnect',
          allowed: federationPermission,
          icon: 'link-slash',
          intent: 'danger',
          confirm: true,
          disabled,
          onClick: async () => {
            await disconnectSignInApp({
              variables: {
                ownerType: 'FEDERATION',
                ownerId: federation.id,
              },
            });
            setNewConnectionDetails(null);
          },
        });
      }
      return standardActions;
    } else {
      // When Disconnected
      const actions: ActionWithChildren[] = [
        {
          text: 'Connect Organisation',
          icon: 'link',
          allowed: organisationPermission,
          onClick: async () => {
            const newOrganisationConnection = await connectSignInApp({
              variables: {
                ownerType: 'ORGANISATION',
                ownerId: organisation.id,
              },
            });
            setNewConnectionDetails({
              ...organisation.siaConnection,
              ...newOrganisationConnection?.data,
            });
          },
        },
      ];
      if (federation) {
        actions.push({
          text: 'Connect Federation',
          icon: 'link',
          allowed: federationPermission,
          onClick: async () => {
            const newFederationConnection = await connectSignInApp({
              variables: {
                ownerType: 'FEDERATION',
                ownerId: federation.id,
              },
            });
            setNewConnectionDetails({
              ...organisation.siaConnection,
              ...newFederationConnection?.data,
            });
          },
        });
      }
      return actions;
    }
  }, [
    connection,
    siaImportStatus,
    analytics,
    queueSiaImportNow,
    organisation,
    organisationPermission,
    disconnectSignInApp,
    federationPermission,
    federation,
    connectSignInApp,
  ]);

  const tabs = useMemo<Tab[]>(() => {
    return [
      !connection && {
        key: 'welcome',
        name: 'Welcome',
        Component: SignInAppNotConnected,
      },
      connection && {
        key: 'status',
        name: 'Status',
        Component: () => (
          <SignInAppConnected
            federation={connection?.ownerType === 'FEDERATION'}
            apiKey={newConnectionDetails?.newApiKey}
            status={siaImportStatus?.status}
          />
        ),
      },
      connection && {
        key: 'connect',
        name: 'Connection',
        Component: () => (
          <ConnectionEditor
            connection={{
              connectionOptions: {
                siaApiKey: connection.connected ? 'hiddenhidden' : '',
              },
              configurationOptions: {
                siaWondeEnabled:
                  !!connection.configurationOptions?.siaWondeEnabled,
                siaBaseUrl: connection.configurationOptions?.siaBaseUrl,
              },
            }}
            fields={[
              {
                key: 'connectionOptions.siaApiKey',
                label: 'API Key (from the SIA Portal)',
                type: 'password',
                meta: {
                  disableShowPassword: true,
                },
              },
              {
                key: 'configurationOptions.siaBaseUrl',
                label: 'SIA Domain',
                type: 'textbox',
                validate: 'URL',
              },
              {
                key: 'configurationOptions.siaWondeEnabled',
                label: 'Use SIA Wonde Connection (if available)',
                type: 'toggle',
              },
            ]}
            onSave={async (params) => {
              analytics.events.sia.saveConnectionClicked({});
              await updateSignInApp({
                variables: {
                  id: connection.id,
                  ...params,
                },
              });
            }}
          />
        ),
      },
      connection &&
        connection.configurationOptions?.wondeScopes && {
          key: 'wondescope',
          name: 'Wonde Scopes',
          Component: () => {
            const scopes = connection.configurationOptions!
              .wondeScopes as AppliedPermissionsResponse[];
            return (
              <PermissionsViewer
                permissions={scopes.map((s) => ({
                  key: s.identity,
                  label: s.name,
                  group: s.group,
                  granted: !s.declined && s.approved,
                  scrRequired: !s.optional,
                }))}
              />
            );
          },
        },
      connection && {
        key: 'fieldmap',
        name: 'Field Mapping',
        Component: () => {
          return (
            <FieldMapEditor
              scheme={organisation.scheme}
              fieldMap={connection.configurationOptions?.fieldMap || []}
              sourceFields={connection.configurationOptions?.sourceFields}
              onSave={async (fieldMap) => {
                analytics.events.sia.saveFieldMapClicked({});
                await updateSignInApp({
                  variables: {
                    id: connection.id,
                    configurationOptions: {
                      fieldMap,
                    },
                  },
                });
              }}
            />
          );
        },
      },

      connection && {
        key: 'profiletypemap',
        name: 'Profile Type Map',
        Component: () => (
          <ProfileTypeMapEditor
            profileTypeMap={connection.configurationOptions?.profileTypeMap}
            profileTypes={organisation.scheme.profileTypes}
            sourceFields={connection.configurationOptions?.sourceFields!}
            siaGroups={[...(siaGroups || [])]}
            onSave={async (profileTypeKey, condition) => {
              let existingMap = connection.configurationOptions
                ?.profileTypeMap as ProfileTypeMap;
              if (!existingMap) {
                existingMap = [];
              } else {
                existingMap = existingMap.map((e) => ({
                  profileTypeKey: e.profileTypeKey,
                  condition: e.condition,
                }));
              }
              const existingItem = existingMap.find(
                (pt) => pt.profileTypeKey === profileTypeKey
              );
              if (!existingItem) {
                existingMap = [
                  ...existingMap,
                  {
                    profileTypeKey,
                    condition,
                  },
                ];
              } else {
                existingMap = [
                  ...existingMap.filter(
                    (e) => e.profileTypeKey !== profileTypeKey
                  ),
                  {
                    profileTypeKey,
                    condition,
                  },
                ];
              }

              analytics.events.sia.saveProfileTypeMapClicked({});
              await updateSignInApp({
                variables: {
                  id: connection.id,
                  configurationOptions: {
                    profileTypeMap: existingMap,
                  },
                },
              });
            }}
          />
        ),
      },
    ].filter(Boolean) as Tab[];
  }, [
    connection,
    newConnectionDetails,
    siaImportStatus,
    analytics,
    updateSignInApp,
    organisation,
    siaGroups,
  ]);

  useEffect(() => {
    if (!siaImportStatus) {
      return;
    }
    if (siaImportStatus.busy) {
      startRefreshingImportStatus(750);
    } else if (!siaImportStatus?.busy) {
      startRefreshingImportStatus(5000);
    }
  }, [startRefreshingImportStatus, siaImportStatus]);

  const [activeTabKey, setActiveTabKey] = useQueryParam('tab', StringParam);
  const activeTab = tabs.find((t) => t.key === activeTabKey) ?? tabs[0];

  return (
    <SettingsPanel title="Sign In App Integration" actions={availableActions}>
      <TabBar
        activeTab={activeTab.key}
        tabs={tabs}
        onTabChange={setActiveTabKey}
      />
      <Card>
        <activeTab.Component
        // provider={provider!}
        // config={config}
        // scheme={scheme!}
        // onSaveFieldMap={onSaveFieldMap}
        // onSaveLocationMap={onSaveLocationMap}
        // onSaveProfileTypeMap={onSaveProfileTypeMap}
        // onSaveConnection={onSaveConnection}
        />
      </Card>
    </SettingsPanel>
  );
};

export default SignInAppContainer;
