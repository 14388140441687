import { Button } from '@blueprintjs/core';
import { Error } from '@hogwarts/ui-components-core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RenderNotFound = () => {
  const history = useHistory();
  return (
    <Error
      title="Oops! Page Not Found"
      action={
        <Button onClick={() => history.push('/')} intent="primary">
          Dashboard
        </Button>
      }
    />
  );
};

export default RenderNotFound;
