import { gql } from '@apollo/client';

export const GET_AUDIT_LOG = gql`
  query auditLog(
    $organisationKey: String!
    $cursor: String
    $limit: Int
    $filter: AuditLogFilter
  ) {
    organisations(key: $organisationKey) {
      id
      auditLog(cursor: $cursor, limit: $limit, filter: $filter) {
        id
        event
        time
        user {
          name
          email
        }
        metadata
        params
      }
    }
  }
`;

export const GET_AUDIT_EVENTS = gql`
  query auditEvents {
    auditEvents
  }
`;
