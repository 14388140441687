import { AnalyticsContext, UserContext } from '@/context';
import { useMutation, useQuery } from '@/hooks';
import { GET_CURRENT_USER, GET_USER_SUPPORT } from '@/queries';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import React, { useContext } from 'react';

import { CLOSE_SUPPORT } from '@/mutations';
import authenticationService from '@/services/authentication';
import { AppToaster } from '@/utils/toaster';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useSupportMenu = () => {
  const user = useContext(UserContext);

  const isImpersonating = !!user?.support?.impersonating;
  const isSupportAdmin = user?.isSupportAdmin || isImpersonating;

  const {
    data: tickets,
    loading,
    error,
    refetch,
  } = useQuery(GET_USER_SUPPORT, {
    skip: !isSupportAdmin,
    selector: 'support',
    pollInterval: 20000,
  });

  if (!user || loading || error) return null;

  if (isImpersonating) {
    return {
      component: StopImpersonationMenu,
      componentProps: {},
      name: 'StopImpersonation',
      icon: 'headset',
      color: 'yellow',
    };
  } else if (isSupportAdmin) {
    if (!tickets?.length) {
      return null;
    }

    return {
      component: SupportMenu,
      componentProps: {
        tickets: tickets?.map((ticket) => ({
          id: ticket.id,
          userId: ticket.user.id,
          email: ticket.user.email,
          name: ticket.user.name,
          organisation: ticket.user.organisation,
        })),
        refetchTickets: refetch,
      },
      name: 'Support',
      icon: 'headset',
      color: 'yellow',
    };
  } else if (user.support) {
    return {
      component: CloseSupportMenu,
      componentProps: {},
      name: 'CloseMySupport',
      icon: 'headset',
      color: 'yellow',
    };
  } else {
    return null;
  }
};

const StopImpersonationMenu = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Popover position={Position.BOTTOM}>
      {children}
      <Menu>
        <MenuItem
          text={t(`Stop Impersonation`)}
          onClick={() => {
            authenticationService.impersonate(null);
            history.replace('/');
            window.location.reload();
          }}
        />
      </Menu>
    </Popover>
  );
};

const CloseSupportMenu = ({ children }) => {
  const analytics = useContext(AnalyticsContext);
  const { t } = useTranslation();
  const [closeSupport] = useMutation(CLOSE_SUPPORT, {});

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    // Note, this is vital to ensure cache is being updated
    fetchPolicy: 'network-only',
  });
  return (
    <Popover position={Position.BOTTOM}>
      {children}
      <Menu>
        <MenuItem
          text={t(`Stop Impersonation`)}
          onClick={async () => {
            await closeSupport();
            analytics.events.users.impersonationCloseIcon();
            AppToaster.show({
              intent: 'success',
              icon: 'tick',
              message: t('Impersonation has been disabled.'),
            });
            await getCurrentUser();
          }}
        />
      </Menu>
    </Popover>
  );
};

const SupportMenu = ({ children, tickets, refetchTickets }) => {
  const analytics = useContext(AnalyticsContext);
  const [closeSupport] = useMutation(CLOSE_SUPPORT, {});
  const { t } = useTranslation();

  const handleImpersonate = async (ticketId) => {
    authenticationService.impersonate(ticketId);
    window.location.reload();
  };

  const handleDisableImpersonation = async (userId) => {
    try {
      await closeSupport({
        variables: {
          userId,
        },
      });
      analytics.events.users.disableImpersonationButton({
        userId,
      });
      AppToaster.show({
        intent: 'success',
        icon: 'tick',
        message: t('Impersonation has been disabled.'),
      });
      await refetchTickets();
    } catch (error) {
      AppToaster.show({
        intent: 'danger',
        icon: 'error',
        message: t('An error occurred while disabling impersonation.'),
      });
    }
  };

  return (
    <Popover position={Position.BOTTOM_RIGHT}>
      {children}
      <Menu>
        {tickets?.map((ticket) => (
          <>
            <MenuItem
              key={ticket.id}
              icon="user"
              text={
                <>
                  {ticket.organisation.federation ? (
                    <>
                      <b>{ticket.organisation.name}</b> ({t('part of')}{' '}
                      <b>{ticket.organisation.federation.name})</b>
                    </>
                  ) : (
                    <b>{ticket.organisation.name}</b>
                  )}
                  <div>
                    {ticket.name} - {ticket.email}
                  </div>
                </>
              }
              onClick={
                ticket.id ? () => handleImpersonate(ticket.id) : undefined
              }
              children={
                <>
                  <MenuItem
                    intent="primary"
                    icon="user"
                    text={t('Impersonate User')}
                    onClick={
                      ticket.id ? () => handleImpersonate(ticket.id) : undefined
                    }
                  />
                  <MenuItem
                    intent="danger"
                    icon="remove"
                    text={t('Disable Impersonation')}
                    onClick={
                      ticket.userId
                        ? () => handleDisableImpersonation(ticket.userId)
                        : undefined
                    }
                  />
                </>
              }
            />
          </>
        ))}
      </Menu>
    </Popover>
  );
};

export default SupportMenu;
