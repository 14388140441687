import { useApolloClient } from '@apollo/client';
import { NonIdealState } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { useMemoizeArgs } from '@hogwarts/ui-components-core';
import { InfiniteGalleryView } from '@hogwarts/ui-components-list';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UploadProfilePicture } from '../../../components/DocumentUpload';
import BulkProfileImages from '../../../components/Settings/BulkProfileImages';
import { OrganisationContext } from '../../../context';
import {
  TransformedProfile,
  usePermission,
  useTransformProfiles,
} from '../../../hooks';
import { DATASOURCE_QUERY_PROFILES_WITHOUT_ORG } from '../../../queries';
import { Profile } from '../../../types';
import ProfilesFilterBar from '../../profiles/profileListQuery/filterBar';
import styles from './styles.module.css';

interface Rating {
  key: string;
  include: boolean;
}

interface Filter {
  tags?: string[];
  profileTypes?: string[];
  includedProfileTypes?: {
    [key: string]: boolean;
    active: boolean;
    onboarding: boolean;
  };
  includedTags?: {
    [key: string]: boolean;
    active: boolean;
    onboarding: boolean;
  };
  ratings: Rating[];
}

const BulkProfileImagesWithUpload = UploadProfilePicture(BulkProfileImages);

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

const BulkProfileImagesContainer = () => {
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
  });

  const organisation = useContext(OrganisationContext);
  const apollo = useApolloClient();

  const allowProfileEdit = usePermission(
    permissions.PROFILE_UPDATE,
    organisation.id
  );

  const [filters, setFilters] = useState<Filter>(
    useMemoizeArgs<Filter>({
      includedTags: { active: true, onboarding: true },
      ratings: [],
    })
  );

  const onFilterHandler = useCallback((currentFilters) => {
    const ratingFilter: Rating[] = [];
    for (const filterKey of Object.keys(currentFilters)) {
      if (filterKey.includes('ratingSystem')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: currentFilters[filterKey][0],
        });
      }
    }

    setFilters(() => ({
      profileTypes: currentFilters.profileTypes,
      tags: currentFilters.tags,
      ratings: ratingFilter,
    }));
  }, []);

  const transformProfiles = useTransformProfiles();

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) return;

      const {
        tags: currentFilterTags,
        includedTags,
        ratings,
        includedProfileTypes,
        profileTypes: currentProfileTypes,
      } = filters;

      const variables = {
        organisationKey: organisation.key,
        profileTypes: includedProfileTypes
          ? Object.keys(includedProfileTypes || {})
              .map(
                (profileKey) => includedProfileTypes[profileKey] && profileKey
              )
              .filter(Boolean)
          : currentProfileTypes,
        tags: includedTags
          ? Object.keys(includedTags || {})
              .map((tagKey) => includedTags[tagKey] && tagKey)
              .filter(Boolean)
          : currentFilterTags,
        ratings,
        skip,
        limit,
        includeDeleted: false,
        sort: [{ field: 'lastname', desc: false }],
      };

      const profiles = await apollo.query({
        query: DATASOURCE_QUERY_PROFILES_WITHOUT_ORG,
        fetchPolicy: 'no-cache',
        variables,
      });

      return transformProfiles(profiles?.data?.queryProfiles?.result).map(
        ({
          id,
          tags,
          ratings,
          typeKey,
          name,
          data,
          meta,
        }: TransformedProfile & {
          meta?: Profile['meta'];
        }) => ({
          id,
          tags,
          ratings,
          typeKey,
          name,
          image: data?.picture,
          meta,
        })
      );
    },
    [filters, organisation, transformProfiles, apollo]
  );

  return (
    <>
      <ProfilesFilterBar onFilter={onFilterHandler} initialFilters={[]} />
      <div {...getRootProps()}>
        <InfiniteGalleryView
          className={styles.galleryContainer}
          fetchMore={fetchMore}
          emptyComponent={NoRows}
          useWindowScroll
          component={BulkProfileImagesWithUpload}
          withControlledProps={true}
          componentProps={{
            isParentDragActive: isDragActive,
            allowEdit: allowProfileEdit,
            skipProfileVersionRefetch: true,
          }}
        />
      </div>
    </>
  );
};

export default BulkProfileImagesContainer;
