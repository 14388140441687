import { useApolloClient } from '@apollo/client';
import permissions from '@hogwarts/permissions';
import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dialogs } from '../../../components';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../../context';
import { useMutation, usePermission, useQuery } from '../../../hooks';
import {
  CHANGE_PROFILE_ORGANISATION_MUTATION,
  REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
} from '../../../mutations';
import {
  GET_FEDERATION_ORGANISATIONS,
  GET_PROFILETYPE_EXISTS,
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
  GET_USER_ORGANISATIONS,
} from '../../../queries';
import { AppToaster } from '../../../utils/toaster';

const TransferProfileContainer = ({
  children,
  profile: { id: profileId, typeKey: profileTypeKey, name: profileName },
}) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const apolloClient = useApolloClient();
  const history = useHistory();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const userContext = useContext(UserContext);
  const { loading, error, data } = useQuery(
    federation ? GET_FEDERATION_ORGANISATIONS : GET_USER_ORGANISATIONS,
    {
      variables: federation ? { organisationKey: organisation.key } : {},
    }
  );

  const [isOpen, setIsOpen] = useState(false);
  const [targetOrg, setTargetOrg] = useState(null);
  const canReadTargetOrgProfiles = usePermission(
    permissions.PROFILE_READ,
    targetOrg?.id
  );

  const [transferProfile] = useMutation(CHANGE_PROFILE_ORGANISATION_MUTATION, {
    selector: 'changeProfileOrganisation.id',
    variables: {
      profileId: profileId,
      organisationId: targetOrg?.id,
    },
  });

  const [requestTransferProfileCall] = useMutation(
    REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
    {
      selector: 'requestChangeProfileOrganisation',
    }
  );

  const requestTransferProfile = async (profileId, targetOrganisationId) => {
    const profileTypeExists = get(
      await apolloClient.query({
        query: GET_PROFILETYPE_EXISTS,
        fetchPolicy: 'no-cache',
        variables: {
          organisationId: targetOrganisationId,
          profileTypeKey,
        },
      }),
      'data.profileTypeExists'
    );

    if (!profileTypeExists) {
      analytics.events.profile.profileTypeDoesNotExistOnTarget();
      AppToaster.show({
        message: t(
          'Cannot transfer when the Profile Type does not exist on the Target Organisation'
        ),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
      return;
    }

    const requestTransferResult = await requestTransferProfileCall({
      variables: {
        profileId,
        organisationId: targetOrganisationId,
      },
      refetchQueries: [
        {
          query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
          variables: { profileId },
        },
        // Only do this if have READ permission to the TargetOrganisation!
        canReadTargetOrgProfiles && {
          query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
          variables: { targetOrganisationId },
        },
      ].filter(Boolean),
    });
    if (requestTransferResult.data) {
      analytics.events.transfers.transferRequested();
      AppToaster.show({
        message: t('{{ profileName }} transfer requested successfully!', {
          profileName,
        }),
        intent: 'success',
        icon: 'tick',
      });
    } else {
      AppToaster.show({
        message: t('Error requesting profile transfer!'),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
    }
  };

  const onTransfer = async () => {
    const profileTypeExists = get(
      await apolloClient.query({
        query: GET_PROFILETYPE_EXISTS,
        fetchPolicy: 'no-cache',
        variables: {
          organisationId: targetOrg.id,
          profileTypeKey,
        },
      }),
      'data.profileTypeExists'
    );

    if (!profileTypeExists) {
      analytics.events.profile.profileTypeDoesNotExistOnTarget();
      AppToaster.show({
        message: t(
          'Cannot transfer when the Profile Type does not exist on the Target Organisation'
        ),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
      return;
    }

    const transferResult = await transferProfile(profileId, targetOrg.id);
    if (transferResult.data) {
      analytics.events.transfers.transferSuccessful();
      AppToaster.show({
        message: t('{{ profileName }} transferred successfully!', {
          profileName,
        }),
        intent: 'success',
        icon: 'tick',
      });
      if (canReadTargetOrgProfiles) {
        history.push(`/${targetOrg.key}/profiles/${transferResult.data}`);
      }
    } else {
      AppToaster.show({
        message: t('Error transferring profile!'),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
    }
  };

  const onRequestTransfer = async () => {
    try {
      await requestTransferProfile(profileId, targetOrg.id);
    } catch (error) {
      AppToaster.show({
        message: t('Error requesting profile transfer! {{error}}', {
          error: error.message,
        }),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
    } finally {
      setIsOpen(false);
    }
  };

  let organisations;
  let mappedOrganisations;
  if (data && data.organisations) {
    organisations = federation
      ? // TODO: This is so brittle
        data.organisations[0].federation.organisations
      : data.organisations;
    mappedOrganisations = (Array.isArray(organisations) ? organisations : [])
      .filter((org) => !org.attributes?.suspended)
      .map((org) => {
        const mappedOrg = {
          id: org.id,
          key: org.key,
          name: org.name,
        };
        if (org.key === organisation.key) {
          mappedOrg.disabled = true;
          mappedOrg.label = t('Current Organisation');
        }
        if (
          !userContext.hasPermission(
            permissions.PROFILE_ACCEPT_TRANSFER,
            mappedOrg.id
          )
        ) {
          mappedOrg.requiresRequest = true;
        }
        return mappedOrg;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <>
      {children({ openDialog: () => setIsOpen(true) })}
      <Dialogs.TransferProfile
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        targetOrg={targetOrg}
        onTargetOrgSelected={(target) => setTargetOrg(target)}
        onTransfer={onTransfer}
        onRequestTransfer={onRequestTransfer}
        loading={loading}
        error={error}
        profileName={profileName}
        organisations={mappedOrganisations}
      />
    </>
  );
};

export default TransferProfileContainer;
