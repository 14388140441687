import { Intent } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
  allowed?: boolean;
  disabled?: boolean;
  canSave?: boolean;
  isSaving?: boolean;
  onClick: () => void;
  savingText?: string;
  saveText?: string;
  savedText?: string;
}
const SaveButton = ({
  allowed,
  disabled,
  canSave,
  isSaving,
  onClick,
  savingText,
  saveText,
  savedText,
}: SaveButtonProps) => {
  const { t } = useTranslation();

  let intent: Intent = Intent.PRIMARY;
  let buttonContent;
  if (isSaving) {
    intent = Intent.PRIMARY;
    buttonContent = (
      <>
        <FontAwesomeIcon icon="spinner" spin />{' '}
        {`${t(savingText || 'Saving')}...`}
      </>
    );
  } else if (canSave) {
    intent = Intent.PRIMARY;
    buttonContent = (
      <>
        <FontAwesomeIcon icon="save" className={'pr-1'} />
        {`${t(saveText || 'Save changes')}`}
      </>
    );
  } else {
    intent = Intent.SUCCESS;
    buttonContent = (
      <>
        <FontAwesomeIcon icon="save" className={'pr-1'} />
        {`${t(savedText || 'Saved')}`}
      </>
    );
  }

  return (
    <Button
      id="save"
      type="submit"
      large
      intent={intent}
      disabled={disabled || allowed === false || !canSave}
      onClick={onClick}
    >
      {allowed === false && <FontAwesomeIcon icon="lock" className={'pr-1'} />}
      {buttonContent}
    </Button>
  );
};

export default SaveButton;
