import React from 'react';

import { Intent, Position, Tooltip } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const WarningCellRenderer = (params) => {
  const { t } = useTranslation();
  let { valueFormatted: value, reasons } = params;
  let icon, color, text, intent;

  if (reasons?.length > 0) {
    text = reasons[0].message;
    switch (reasons[0].severity) {
      case 'error': {
        icon = 'exclamation-circle';
        intent = Intent.DANGER;
        color = '#ff6666';
        break;
      }
      case 'warning': {
        icon = 'exclamation-triangle';
        text = reasons[0].message;
        intent = Intent.WARNING;
        color = '#ffb266';
        break;
      }
      default:
      case 'information': {
        icon = ['fal', 'info-circle'];
        text = reasons[0].message;
        // intent = Intent.PRIMARY;
        // color = '#ffb266';
        break;
      }
    }
  }
  if (value != null) {
    if (Array.isArray(value)) {
      value = '[Array]';
    } else if (typeof value === 'object') {
      value = '[Object]';
    }
  }
  return (
    <div className={'row no-gutters'}>
      <div className={'col'}> {value}</div>
      <div className={'col-auto'}>
        <Tooltip
          position={Position.BOTTOM_RIGHT}
          intent={intent}
          content={t(text)}
        >
          <FontAwesomeIcon
            icon={icon}
            color={color}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default WarningCellRenderer;
