import React from 'react';
import DBSRenewalReport from './dbs-renewal';
import IncidentsReport from './incidents';
import IncompleteProfiles from './incomplete-profiles';
import ListGroup from './list-group';
import ProfilesGrid from './profiles-grid';
import SignOffsReport from './sign-offs';

// View Types (viewType on the insight)
const insightRegistry: Record<string, React.FC<any>> = {
  listgroup: ListGroup,
  'dbs-renewal': DBSRenewalReport,
  'incomplete-profiles': IncompleteProfiles,
  'profiles-grid': ProfilesGrid,
  'sign-offs': SignOffsReport,
  incidents: IncidentsReport,
};

export default insightRegistry;
