import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.css';

function H1({ style, className, children }) {
  return (
    <h1 style={{ ...style }} className={cn(styles.h1, className)}>
      {children}
    </h1>
  );
}

H1.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default H1;
