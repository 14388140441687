import { gql } from '@apollo/client';

export const ADD_SEVERITY = gql`
  mutation addSeverity($values: SeverityInput!) {
    addSeverity(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const UPDATE_SEVERITY = gql`
  mutation updateSeverity($values: UpdateSeverityInput!) {
    updateSeverity(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const DELETE_SEVERITY = gql`
  mutation deleteSeverity($id: ID!) {
    deleteSeverity(id: $id)
  }
`;
