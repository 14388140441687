import { gql } from '@apollo/client';

export const ADD_DOCUMENT = gql`
  mutation addDocument($document: DocumentInput!) {
    addDocument(document: $document) {
      id
      filename
      filestackHandle
      size
      type
      meta
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentId: String!) {
    deleteDocument(documentId: $documentId)
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($document: DocumentUpdateInput!) {
    updateDocument(document: $document) {
      id
      filename
      filestackHandle
      size
      type
      meta
    }
  }
`;

export const DELETE_PROFILE_PICTURE = gql`
  mutation deleteProfilePicture($profileId: String!) {
    deleteProfilePicture(profileId: $profileId)
  }
`;
