import { Organisation, Profile } from '../types';
import { keys, notEmpty } from '@hogwarts/utils';
import { useCallback, useContext } from 'react';

import { OrganisationContext } from '../context';
import { Scheme } from '@hogwarts/utils-schemes';

export interface TransformedProfile {
  id: string;
  typeKey: string;
  data: Record<string, unknown>;
  name: string;
  organisation: {
    name: string;
  };
  profileType?: {
    key: string;
    label: string;
  };
  tags?: {
    key: string;
    label: string;
    color: string;
  }[];
  ratings: {
    key: string;
    label: string;
    ready: boolean;
    readyColor: string;
    notReadyColor: string;
  }[];
}

type AdditionalProcessingFunc = (
  result: TransformedProfile,
  args: { scheme: Scheme; organisation: Organisation }
) => TransformedProfile;
export const useTransformProfiles = (
  additionalProcessing?: AdditionalProcessingFunc
): ((profiles: Profile[]) => TransformedProfile[]) => {
  const organisation = useContext(OrganisationContext);

  return useCallback(
    (profiles) => {
      if (!organisation || !Array.isArray(profiles)) {
        return [];
      }

      return profiles
        .map((profile) => {
          if (profile.dataError) return null;

          let [profileScheme1, validity, profileRating, , refreshedProfile] =
            organisation.scheme
              .applyProfileMeta(profile.typeKey, profile.meta)
              .applyProfile(profile, organisation.timezone);

          const tags = profile.tags
            .map((tagKey) => {
              const tag = profileScheme1.getTag(tagKey);
              if (!tag) return null;
              const { key, label, order, color } = tag;
              // TODO: If not enabled?
              return {
                key,
                label,
                order,
                color,
              };
            })
            .filter(Boolean);

          const profileType = profileScheme1.getProfileType(profile.typeKey);

          const ratings = keys(profileRating)
            .map((rating) => {
              const ratingScheme = profileScheme1.getRatingSystem(rating.key);
              if (!ratingScheme) return null;
              // TODO: If not enabled?
              return {
                key: rating.key,
                label: ratingScheme.label,
                readyColor: ratingScheme.readyColor,
                notReadyColor: ratingScheme.notReadyColor,
                score: rating.score,
                ready: rating.ready,
              };
            })
            .filter(Boolean);

          const result: TransformedProfile = {
            ...refreshedProfile,
            ...validity,
            profileType,
            tags,
            ratings,
            scheme: profileScheme1,
            organisation: profile.organisation || {
              name: organisation.name,
            },
          };

          if (typeof additionalProcessing === 'function') {
            const updatedResult = additionalProcessing(result, {
              scheme: organisation.scheme,
              organisation,
            });
            return updatedResult;
          }

          return result;
        })
        .filter(notEmpty);
    },
    [additionalProcessing, organisation]
  );
};
