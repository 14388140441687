import React, { useEffect, useMemo, useState } from 'react';
import { fieldComponents, inputMetaFields } from '../fieldComponents';

import { DragDropContext } from 'react-beautiful-dnd';
import ProfileSectionsTab from '../../../components/SchemeEditor/Tabs/ProfileSections';
import { sortByOrder } from '../utils';
import useFieldEdit from '../useFieldEdit';
import useSectionEdit from '../useSectionEdit';

const ProfileSectionsContainer = ({
  currentScheme,
  visibilityOptions,
  profileTypes,
  ratingSystems,
  addField,
  addArrayField,
  sectionDelete,
  sectionRestore,
  fieldDelete,
  fieldRestore,
  fieldReorder,
  arrayFieldReorder,
  addSection,
  sectionReorder,
  sectionUpdated,
  fieldUpdated,
  selectedProfileType: selectedProfileTypeKey,
  onSelectedProfileTypeChange,
  permissions,
}) => {
  const showFieldEditor = useFieldEdit({
    selectedProfileTypeKey,
    currentScheme,
    profileTypes,
    ratingSystems,
    inputMetaFields,
    onFieldDelete: (key) => {
      setSelectedFieldKey(null);
      return fieldDelete(key);
    },
    onFieldRestore: (key) => {
      return fieldRestore(key);
    },
  });

  const showSectionEditor = useSectionEdit({
    selectedProfileTypeKey,
    currentScheme,
    profileTypes,
    ratingSystems,
    onSectionDelete: (key) => {
      setSelectedFieldKey(null);
      setSelectedSectionKey(null);
      sectionDelete(key);
    },
    onSectionRestore: (key) => {
      sectionRestore(key);
    },
  });

  const [editFieldKey, setEditFieldKey] = useState(null);
  const [selectedSectionKey, setSelectedSectionKey] = useState(null);
  const [selectedFieldKey, setSelectedFieldKey] = useState(null);
  const orderedSections = useMemo(() => {
    if (currentScheme.sections) {
      return currentScheme.sections
        .filter((s) => visibilityOptions.showDeleted || !s.deleted || s.canUse)
        .filter((s) => visibilityOptions.showUnused || s.inUse)
        .filter((s) => visibilityOptions.showParentOwned || s.meta.owned)
        .sort(sortByOrder);
    }
    return [];
  }, [
    currentScheme.sections,
    visibilityOptions.showDeleted,
    visibilityOptions.showUnused,
    visibilityOptions.showParentOwned,
  ]);
  const selectedSection = useMemo(() => {
    let result;
    if (selectedSectionKey) {
      const section = currentScheme.getSection(selectedSectionKey);
      // Occurs on a discard when a new section is selected
      if (!section) return result;
      result = {
        section,
        fields: section.fields
          .filter(
            (f) => visibilityOptions.showDeleted || !f.deleted || f.canUse
          )
          .filter((f) => visibilityOptions.showUnused || f.inUse)
          .filter((f) => visibilityOptions.showParentOwned || f.meta.owned)
          .sort(sortByOrder),
      };
    }
    return result;
  }, [
    currentScheme,
    selectedSectionKey,
    visibilityOptions.showDeleted,
    visibilityOptions.showParentOwned,
    visibilityOptions.showUnused,
  ]);
  const selectedField = useMemo(() => {
    let result = null;
    if (selectedFieldKey) {
      const [fieldKey, parentKey] = selectedFieldKey.split('.');
      if (parentKey) {
        const parentField = currentScheme.getField(parentKey);
        if (parentField) {
          result = parentField.arrayFields.find((f) => f.key === fieldKey);
        }
      } else {
        result = currentScheme.getField(fieldKey);
      }
    }
    return result;
  }, [currentScheme, selectedFieldKey]);

  useEffect(() => {
    if (!editFieldKey) return;
    const field = currentScheme.getField(editFieldKey);
    if (!field) return;
    setEditFieldKey(null);

    showFieldEditor(field, ({ updated }) => {
      fieldUpdated(updated);
    });
  }, [currentScheme, editFieldKey, fieldUpdated, showFieldEditor]);

  return (
    <DragDropContext
      onDragEnd={(payload) => {
        if (!payload.destination || !payload.source) {
          return;
        }

        const [sourceType, sourceId] = payload.source.droppableId.split('.');

        if (sourceType === 'ARRAYFIELD') {
          const field = selectedSection.fields.find((f) => f.key === sourceId);
          if (field) {
            const source = field.arrayFields[payload.source.index];
            const dest = field.arrayFields[payload.destination.index];
            arrayFieldReorder(
              selectedSectionKey,
              field.key,
              source.key,
              dest.key
            );
          }
        } else if (sourceType === 'FIELDS') {
          // Reordered Fields
          const source = selectedSection.fields[payload.source.index];
          const dest = selectedSection.fields[payload.destination.index];
          fieldReorder(selectedSectionKey, source.key, dest.key);
        } else if (sourceType === 'FIELD_TEMPLATES') {
          const component = fieldComponents.find(
            (c) => c.id === payload.draggableId
          );
          if (component) {
            const [fieldType, fieldKey] =
              payload.destination.droppableId.split('.');

            if (fieldType === 'ARRAYFIELD') {
              const parentField = selectedSection.fields.find(
                (f) => f.key === fieldKey
              );
              if (parentField) {
                const appearAfter =
                  parentField.arrayFields.length &&
                  parentField.arrayFields[payload.destination.index - 1];

                const newFieldKey = addArrayField(
                  parentField.key,
                  component.dataType,
                  component.inputType,
                  component.inputMeta,
                  `New ${component.label}`,
                  appearAfter?.key
                );
                setSelectedFieldKey(`${newFieldKey}.${parentField.key}`);
                setEditFieldKey(newFieldKey);
              }
            } else {
              // Work out which field we should appear after (null will go to the top)
              const appearAfter =
                selectedSection.fields.length &&
                selectedSection.fields[payload.destination.index - 1];
              // This is still a bit slow and clunky
              // Presumably the scheme meta data generation
              const newFieldKey = addField(
                selectedSectionKey,
                component.dataType,
                component.inputType,
                component.inputMeta,
                `New ${component.label}`,
                appearAfter?.key
              );
              setSelectedFieldKey(newFieldKey);
              setEditFieldKey(newFieldKey);
            }
          }
        }
      }}
    >
      <ProfileSectionsTab
        currentScheme={currentScheme}
        selectedSection={selectedSection}
        selectedField={selectedField}
        selectedFieldKey={selectedFieldKey}
        sections={orderedSections}
        ratingSystems={currentScheme.ratingSystems}
        profileTypes={profileTypes}
        fieldComponents={fieldComponents}
        inputMetaFields={inputMetaFields}
        selectedProfileType={selectedProfileTypeKey}
        visibilityOptions={visibilityOptions}
        onProfileTypeChange={(key) => {
          if (key === selectedProfileTypeKey) return;
          onSelectedProfileTypeChange(key);
        }}
        onSectionSelected={(sectionKey) => {
          setSelectedSectionKey(sectionKey);
          setSelectedFieldKey(null);
        }}
        onSectionOrderUpdate={sectionReorder}
        onAddSection={() => {
          const newSectionKey = addSection();
          setSelectedSectionKey(newSectionKey);
          setSelectedFieldKey(null);
        }}
        onSectionEdit={(sectionKey) => {
          const section = currentScheme.getSection(sectionKey);
          if (section) {
            showSectionEditor(section, ({ updated }) => {
              sectionUpdated(updated);
            });
          }
        }}
        onFieldEdit={(fieldKey) => {
          const field = currentScheme.getField(fieldKey);
          if (field) {
            showFieldEditor(field, ({ updated }) => {
              fieldUpdated(updated);
              setSelectedFieldKey(null);
            });
          }
        }}
        onFieldSelected={(compoundKey) => {
          const [fieldKey, parentKey] = compoundKey.split('.');

          // Why does it need this check?
          const field = parentKey
            ? currentScheme.getField(parentKey)
            : currentScheme.getField(fieldKey);

          if (field) {
            setSelectedFieldKey(compoundKey);
          }
        }}
        onFieldUpdate={({ updated }) => {
          fieldUpdated(updated);
        }}
        permissions={permissions}
      />
    </DragDropContext>
  );
};

export default ProfileSectionsContainer;
