/* eslint-disable */
import React, { useContext } from 'react';
import Search from '@/containers/search';
import { AnalyticsContext } from '@/context';

const SearchMenu = ({ showSearch, children }) => {
  if (!showSearch) return null;

  const analytics = useContext(AnalyticsContext);

  return (
    <Search>
      {({ openDrawer }) => (
        <>
          {React.cloneElement(children, {
            onClick: () => {
              analytics.events.header.searchClicked();
              openDrawer();
            },
          })}
        </>
      )}
    </Search>
  );
};

export default SearchMenu;
