import { Card, Classes, Drawer, Intent } from '@blueprintjs/core';
import { ProfileTypeMap, SourceField } from '../../../../types';
import React, { useContext, useState } from 'react';

import { AnalyticsContext } from '../../../../context';
import { Button } from '@hogwarts/ui-components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormBuilder } from '@hogwarts/ui-components-forms';
import { fieldStructureFactory } from '../../../../components/Conditionals/utils';
import { isEmptyCondition } from '@hogwarts/conditionals';
import { useMemo } from 'react';

type SaveHandler = (
  profileTypeKey: string,
  condition: any
) => void | Promise<void>;

interface ProfileTypeMapConditionEditProps {
  profileTypeKey: string;
  profileTypeLabel: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: SaveHandler;
  structure: any;
  initialCondition: any;
}
const ProfileTypeMapConditionEdit = ({
  profileTypeKey,
  profileTypeLabel,
  initialCondition,
  isOpen,
  onClose,
  onSave,
  structure,
}: ProfileTypeMapConditionEditProps) => {
  const analytics = useContext(AnalyticsContext);

  let dirty = false;
  const initialValues = useMemo(
    () => ({
      condition: initialCondition,
    }),
    [initialCondition]
  );

  const [currentValues, setCurrentValues] = useState<any>(initialValues);

  return (
    <Drawer
      enforceFocus={false}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={'Edit ProfileType Map'}
      size={'500px'}
      isOpen={isOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <FormBuilder
            initialValues={initialValues}
            fields={[
              {
                type: 'title',
                label: profileTypeLabel,
              },
              {
                key: 'condition',
                type: 'conditional2',
                meta: {
                  structure: structure,
                },
              },
            ]}
            onValuesChanged={(values) => {
              setCurrentValues(values);
            }}
          />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button
          onClick={async () => {
            analytics.events.dataProvider.saveProfileTypeMapClicked();
            if (currentValues.condition) {
              await onSave(profileTypeKey, currentValues.condition);
            }
            onClose();
          }}
          className="mr-2"
          intent={Intent.PRIMARY}
          // disabled={saving || !dirty}
          large
        >
          {'Save'}
        </Button>
        {dirty && (
          <Button onClick={onClose} intent={Intent.DANGER} large>
            {'Cancel'}
          </Button>
        )}
        {!dirty && (
          <Button onClick={onClose} large>
            {'Close'}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

interface SIAGroup {
  id: string;
  name: string;
  type: string;
}

const useProfileTypeMapConditionEdit = (
  sourceFields: SourceField[],
  siaGroups: SIAGroup[],
  onSave: SaveHandler
): [
  (profileTypeKey: string, profileTypeLabel: string, condition: any) => void,
  {
    Component: typeof ProfileTypeMapConditionEdit;
    props: ProfileTypeMapConditionEditProps;
  }
] => {
  // Formdrawer thing with a condition and magic structure?
  const [isOpen, setIsOpen] = useState(false);

  const [initialCondition, setInitialCondition] = useState<any>();

  const [profileType, setProfileType] = useState<{
    profileTypeKey: string;
    profileTypeLabel: string;
  }>();

  const onClose = () => {
    setIsOpen(false);
  };

  const mapDataTypeToInputType = (dataType: string) => {
    switch (dataType) {
      case 'date': {
        return 'date';
      }
      default:
      case 'string': {
        return 'text';
      }
    }
  };

  const mapDataType = (dataType: string) => {
    switch (dataType) {
      case 'date': {
        return 'date';
      }
      default:
      case 'any': {
        return 'string';
      }
    }
  };

  const structure = useMemo(() => {
    const fields = [
      {
        key: `get/group_id/string`,
        dataType: 'string',
        inputType: 'singleselect',
        label: 'Sign In App - Group ID',
        values: siaGroups.map((s) => ({
          id: s.id,
          value: s.name,
        })),
      },
      ...(sourceFields?.map((s) => ({
        inputType: mapDataTypeToInputType(mapDataType(s.dataType)),
        dataType: mapDataType(s.dataType),
        key: `get/${s.key}/string`,
        label: s.key,
      })) || []),
    ];

    const result = fieldStructureFactory(fields);
    return result;
  }, [siaGroups, sourceFields]);

  return [
    (profileTypeKey: string, profileTypeLabel: string, condition: any) => {
      setProfileType({
        profileTypeKey,
        profileTypeLabel,
      });
      setInitialCondition(condition);
      setIsOpen(true);
    },
    {
      Component: ProfileTypeMapConditionEdit,
      props: {
        profileTypeKey: profileType?.profileTypeKey!,
        profileTypeLabel: profileType?.profileTypeLabel!,
        initialCondition,
        structure,
        isOpen,
        onSave,
        onClose,
      },
    },
  ];
};

interface ProfileTypeMapEditorProps {
  profileTypes: { key: string; label: string }[];
  sourceFields: SourceField[];
  siaGroups: SIAGroup[];
  profileTypeMap?: ProfileTypeMap;
  onSave: SaveHandler;
}
export const ProfileTypeMapEditor = ({
  profileTypes,
  sourceFields,
  siaGroups,
  profileTypeMap,
  onSave,
}: ProfileTypeMapEditorProps) => {
  // TODO: Could we build a component that can plain-text up a condition?

  const [showEditor, ProfileTypeMapEditorParts] =
    useProfileTypeMapConditionEdit(sourceFields, siaGroups, onSave);

  return (
    <div className="d-flex flex-column">
      <ProfileTypeMapEditorParts.Component
        {...ProfileTypeMapEditorParts.props}
      />
      {profileTypes.map((pt) => {
        const condition = profileTypeMap?.find(
          (p) => p.profileTypeKey === pt.key
        )?.condition;
        return (
          <Card className="w-100" key={pt.key}>
            <div className="d-flex w-100 flex-rows">
              <div className="flex-grow-1">{pt.label}</div>
              <div className="m-1 mr-3">
                {!isEmptyCondition(condition) ? (
                  <FontAwesomeIcon icon="check" />
                ) : (
                  <FontAwesomeIcon icon="times" />
                )}
              </div>
              <div>
                <Button
                  onClick={() => {
                    showEditor(pt.key, pt.label, condition);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
