import { useCallback, useRef } from 'react';

type DebounceHandler<T> = (args: T) => void;

export function useDebounceStack<T = any>(
  callback: DebounceHandler<T[]>,
  delay: number
): DebounceHandler<T> {
  const timer = useRef<{
    timer?: NodeJS.Timer;
    stack: T[];
  }>({
    timer: undefined,
    stack: [],
  });
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback(
    (args: T) => {
      clearTimeout(timer.current.timer!);
      timer.current = {
        stack: [...timer.current?.stack, args] || [args],
        timer: setTimeout(() => {
          const callstack = timer.current!.stack;
          timer.current = {
            stack: [],
          };
          if (callbackRef.current) {
            callbackRef.current(callstack);
          }
        }, delay),
      };
    },
    [delay]
  );
}
