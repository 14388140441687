import permissions from '@hogwarts/permissions';
import { groupBy } from 'lodash';
import React, { useContext } from 'react';
import { UsersList } from '../../components';
import { SettingsPanel } from '../../components/Settings/SettingsPanel';
import { FederationContext } from '../../context';
import {
  useBuildReport,
  usePermission,
  useQuery,
  useQueryResults,
} from '../../hooks';
import { DATASOURCE_QUERY_USERS, GET_FEDERATION_ROLES } from '../../queries';

const UserManagement = () => {
  const federation = useContext(FederationContext);

  const canExport = usePermission(permissions.ADMIN, federation.id);

  const { data: users, ...federationUsersQuery } = useQuery(
    DATASOURCE_QUERY_USERS,
    {
      selector: 'queryFederationUsersByUser.result',
      variables: {
        federationId: federation.id,
      },
      transform: (results) => {
        const users = [];
        for (const { user, roles } of results) {
          const organisationsWithRoles = [];
          const rolesGroupedByOrg = groupBy(roles, (r) => r.organisationId);
          for (const organisationId of Object.keys(rolesGroupedByOrg)) {
            const organisation = federation.organisations.find(
              (o) => o.id === organisationId
            );

            organisationsWithRoles.push({
              id: organisationId,
              // This occurs if org is disabled or deleted
              name: organisation?.name || `Unknown ${organisationId}`,
              roles: rolesGroupedByOrg[organisationId].map((r) => ({
                id: r.roleId,
                name: r.roleName,
              })),
            });
          }
          users.push({
            ...user,
            organisations: organisationsWithRoles,
          });
        }
        return users;
      },
    }
  );

  const { data: roles, ...federationRolesQuery } = useQuery(
    GET_FEDERATION_ROLES,
    {
      selector: 'federations[0].roles',
      variables: {
        federationKey: federation.key,
      },
      transform: (roles) => {
        return roles.filter((r) => r.resourceType === 'FEDERATION');
      },
    }
  );

  const [
    exportReport,
    {
      component: ReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
  ] = useBuildReport();

  const { loading, error } = useQueryResults({
    federationUsersQuery,
    federationRolesQuery,
  });

  return (
    <>
      <ReportComponent {...reportProps} />

      <SettingsPanel
        loading={loading}
        error={error}
        title={`All Users`}
        fixedHeight="750px"
        actions={[
          {
            text: 'Export',
            icon: 'file-excel',
            intent: 'success',
            disabled: reportDisabled,
            lock: canExport,
            onClick: () => {
              if (reportDisabled) return;

              // analytics.events.profile.sectionPrintClicked({ sectionKey });

              return exportReport('users_by_organisation', null, {
                federationId: federation.id,
              });
            },
          },
        ]}
      >
        <UsersList users={users} />
      </SettingsPanel>
    </>
  );
};

export default UserManagement;
