import { gql } from '@apollo/client';

export interface GET_REPORTSResponse {
  id: string;
  name: string;
  description?: string;
  sourceType?: string;
  viewType?: string;
  exportType?: string[];
  options?: any;
  ownerType: string;
  ownerId?: string;
}
export const GET_REPORTS = gql`
  query getReports($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      reports {
        id
        name
        description
        sourceType
        viewType
        exportType
        options
        ownerType
        ownerId
      }
    }
  }
`;
