// import log from '@scrtracker/logging';
import React from 'react';

import { Conditionals } from '../../components/Conditionals/Conditionals';

const RenderConditional = ({
  formField,
  readOnly,
  fieldHelpers,
  structure,
  excludeFields,
  prioritySection,
}) => {
  return (
    <Conditionals
      defaultOperator={'or'}
      childGroupOperator={'and'}
      structure={structure}
      excludeFields={excludeFields}
      prioritySection={prioritySection}
      condition={formField.value}
      variables={formField.value?.variables}
      readOnly={readOnly}
      onChange={async (condition) => {
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(condition, true);
      }}
    />
  );
};

export default {
  name: 'Conditionals2',
  options: [],
  Component: RenderConditional,
};
