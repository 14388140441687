import { get } from 'lodash';
import { countryCodes } from './identityValues';

type DocumentType = 'primary' | 'secondary' | 'financial';
export type DocumentItem = {
  id: string;
  description: string;
  type: DocumentType;
} & Record<string, string>;

interface PreviousName {
  Title: string;
  FirstName: string;
  LastName: string;
  MiddleNames: string;
  FullName: string;
  IsFromBirth: boolean;
  KnownFromDate?: string;
  KnownToDate: string;
}

export interface Address {
  Address: {
    BusinessName: string;
    FlatNumber: string;
    HouseNumber: string; // e.g. 52';
    HouseName: string;
    StreetName: string; // e.g. Harbour Hill Road';
    Locality: string;
    Town: string; // e.g. Poole';
    County: string;
    State: string;
    Postcode: string; // e.g. BH15 3PZ';
    AddressString: string; // e.g. 52 Harbour Hill Road Poole BH15 3PZ GB';
    Country: string; // e.g. GB';
  };
  DateMovedToThisAddress: string; // e.g. 2015-09-11';
  DateLeftThisAddress: string;
  IsCurrentAddress: boolean;
}

export interface Candidate {
  CurrentName: {
    Title: string; // e.g. Mr';
    FirstName: string; // e.g. Gary';
    LastName: string; // e.g. Le Sueur';
    MiddleNames: string; // e.g. William';
    FullName: string; // e.g. Mr Gary William Le Sueur';
    IsFromBirth: boolean;
    KnownFromDate: string;
  };
  PersonalInfo: {
    MobilePhone: {
      PhoneNumber: string; // e.g. 07814415926';
      CountryCode: string; // e.g. GB';
    };
    HomePhone: {
      PhoneNumber: string;
      CountryCode: string; // e.g. GB';
    };
    Gender: string; // e.g. Male';
    DateOfBirth: string; // e.g. 1980-08-19';
    PurchaseOrderNumber: string;
    OtherReference: string;
    PositionAppliedFor: string;
    ApplicantNotes: string;
    FSAApproved: boolean;
    HasValidDrivingLicense: boolean;
    ApplicantType: string; // e.g. Candidate';
    CurrentNationality: string;
    CandidateEmail: string; // e.g. gary+testdbscandidate1@scrtracker.com';
    BirthCertificationNumber: string; // e.g. 123456789';
    MotherMaidenName: string;
    NationalityAtBirth: string; // e.g. GB';
    CountryOfBirth: string; // e.g. GB';
    TownOfBirth: string; // e.g. Weymouth';
  };
  Identity: {
    PassportDocument: {
      PassportIssuingCountry: string; // e.g. GB';
      PassportNumber: string; // e.g. 123456789';
      PassportIssueDate: string; // e.g. 2017-01-01';
      PassportExpiryDate: string; // e.g. 2027-01-01';
      PassportDateOfBirth: string; // e.g. 1980-08-19';
    };
    DrivingDocument: {
      DriverLicenseType: string; // e.g. Photo';
      DriverLicenseIssuingCountry: string; // e.g. GB';
      DriverLicenseNumber: string; // e.g. 12345678';
      DriverLicenseDate: string; // e.g. 2017-01-01';
      DriverLicenseDateOfBirth: string; // e.g. 1980-08-19';
    };
    NationalInsuranceNumberDocument: {
      OverseasIdentityNumber: {
        IssuingCountry: string;
        IdentityNumber: string;
      };
      NationalInsuranceNumber: string; // e.g. JG103759A';
    };
  };
  PreviousNames: PreviousName[];
  AddressList: Address[];
}

export interface Field {
  path: string;
  type: string;
  minlength?: number;
  values?: string[];
}

export const candidateFieldInfo: Record<string, Field> = {
  passportIssuingCountry: {
    path: 'Identity.PassportDocument.PassportIssuingCountry',
    type: 'country',
  },
  passportNumber: {
    path: 'Identity.PassportDocument.PassportNumber',
    type: 'string',
    minlength: 9,
  },
  passportIssueDate: {
    path: 'Identity.PassportDocument.PassportIssueDate',
    type: 'date',
  },
  passportExpiryDate: {
    path: 'Identity.PassportDocument.PassportExpiryDate',
    type: 'date',
  },
  passportDateOfBirth: {
    path: 'Identity.PassportDocument.PassportDateOfBirth',
    type: 'date',
  },

  driverLicenceType: {
    path: 'Identity.DrivingDocument.DriverLicenseType',
    type: 'select',
    values: ['Paper', 'Photo'],
  },
  driverLicenceIssuingCountry: {
    path: 'Identity.DrivingDocument.DriverLicenseIssuingCountry',
    type: 'country',
  },
  driverLicenceNumber: {
    path: 'Identity.DrivingDocument.DriverLicenseNumber',
    type: 'string',
  },
  driverLicenceDate: {
    path: 'Identity.DrivingDocument.DriverLicenseDate',
    type: 'date',
  },
  driverLicenceDateOfBirth: {
    path: 'Identity.DrivingDocument.DriverLicenseDateOfBirth',
    type: 'date',
  },

  birthCertificateNumber: {
    path: 'PersonalInfo.BirthCertificationNumber',
    type: 'string',
  },

  issuingCountry: {
    path: 'Identity.NationalInsuranceNumberDocument.OverseasIdentityNumber.IssuingCountry',
    type: 'country',
  },
  identityNumber: {
    path: 'Identity.NationalInsuranceNumberDocument.OverseasIdentityNumber.IdentityNumber',
    type: 'string',
  },
};

export const getCandidateValue = (candidate: Candidate, field: string) => {
  const item = candidateFieldInfo[field];
  const value = get(candidate, item.path);
  return value;
};

export const getCandidateDisplayValue = (
  candidate: Candidate,
  field: string,
  dateFormatter: (date: string) => string | null
): string => {
  const item = candidateFieldInfo[field];
  const value = get(candidate, item.path);
  switch (item.type) {
    case 'date':
      return dateFormatter(value) || value;
    case 'country': {
      return countryCodes.find((c) => c.id === value)?.value || value;
    }
    default: {
      return value;
    }
  }
};

export const documentFieldMap: Record<string, string[]> = {
  Current_Valid_Passport: [
    'passportIssuingCountry',
    'passportNumber',
    'passportIssueDate',
    'passportExpiryDate',
    'passportDateOfBirth',
  ],
  Passport_With_IndefiniteOrCurrent_Leave: [
    'passportIssuingCountry',
    'passportNumber',
    'passportIssueDate',
    'passportExpiryDate',
    'passportDateOfBirth',
  ],
  Current_Driving_Licence: [
    'driverLicenceType',
    'driverLicenceIssuingCountry',
    'driverLicenceNumber',
    'driverLicenceDate',
    'driverLicenceDateOfBirth',
  ],
  Current_Driving_Licence_NonUk: [
    'driverLicenceType',
    'driverLicenceIssuingCountry',
    'driverLicenceNumber',
    'driverLicenceDate',
    'driverLicenceDateOfBirth',
  ],
  Current_Driving_Licence_Old_Style: [
    'driverLicenceType',
    'driverLicenceIssuingCountry',
    'driverLicenceNumber',
    'driverLicenceDate',
    'driverLicenceDateOfBirth',
  ],
  Birth_Certificate_At_Birth: ['birthCertificateNumber'],
  Birth_Certificate_After_Birth: ['birthCertificateNumber'],

  EU_National_ID_Card: ['issuingCountry', 'identityNumber'],
};

export const candidateName = (candidate: Candidate): string => {
  if (candidate.CurrentName?.FullName) {
    return candidate.CurrentName?.FullName;
  }
  const fullName = [
    candidate.CurrentName?.Title,
    candidate.CurrentName?.FirstName,
    candidate.CurrentName?.MiddleNames,
    candidate.CurrentName?.LastName,
  ]
    .filter(Boolean)
    .join(' ');
  if (fullName) {
    return fullName;
  }
  return 'Not Specified';
};

export const candidateEmail = (candidate: Candidate): string => {
  return candidate.PersonalInfo?.CandidateEmail || 'Not Specified';
};

export const previousName = (previousName: PreviousName): string => {
  return previousName.FullName || 'Not Specified';
};

export const candidateCurrentAddress = (candidate: Candidate): string => {
  const address = candidate.AddressList?.find((a) => a.IsCurrentAddress);
  return address?.Address?.AddressString || 'Not Specified';
};

export const previousAddress = (previous: Address): string => {
  return previous.Address.AddressString || 'Not Specified';
};

export const candidateDateOfBirth = (candidate: Candidate): string => {
  return candidate.PersonalInfo.DateOfBirth;
};

export const sensibleCase = (field: string): string => {
  const s = field.replace(/([A-Z])/g, function (x, y) {
    return ' ' + y;
  });
  return s.charAt(0).toUpperCase() + s.slice(1);
};
