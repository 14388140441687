import { gql } from '@apollo/client';

export const DATASOURCE_QUERY_USERS = gql`
  query queryFederationUsersByUser($federationId: String!) {
    queryFederationUsersByUser(federationId: $federationId) {
      id
      success
      error
      type
      result {
        user {
          id
          name
          email
        }
        roles {
          organisationId
          roleId
          roleName
          policyId
          organisationGroupId
          userGroupId
        }
      }
    }
  }
`;

export const DATASOURCE_QUERY_INSIGHT_GROUPS = gql`
  query queryInsightGroups(
    $organisationKey: String!
    $reportId: ID!
    $condition: JSON
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $tags: [String!]
  ) {
    queryInsightGroups(
      organisationKey: $organisationKey
      reportId: $reportId
      condition: $condition
      profileTypes: $profileTypes
      ratings: $ratings
      tags: $tags
    ) {
      id
      type
      success
      params
      result {
        key
        label
        count
        condition
      }
    }
  }
`;

export const DATASOURCE_QUERY_EXPIRY_DATES = gql`
  query queryExpiryDates(
    $organisationKey: String!
    $condition: JSON
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $tags: [String!]
    $groupCondition: JSON
    $skip: Int
    $limit: Int
  ) {
    queryExpiryDates(
      organisationKey: $organisationKey
      groupCondition: $groupCondition
      condition: $condition
      profileTypes: $profileTypes
      ratings: $ratings
      tags: $tags
      skip: $skip
      limit: $limit
    ) {
      id
      type
      success
      params
      result {
        id
        cacheKey
        name
        typeKey
        tags
        fieldKey
        originalDate
        expirationDate
      }
    }
  }
`;

export const DATASOURCE_QUERY_EXPIRY_DATES_BY_CHECK = gql`
  query queryExpiryDatesByCheck(
    $organisationKey: String!
    $condition: JSON
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $tags: [String!]
    $days: Int!
  ) {
    queryExpiryDatesByCheck(
      organisationKey: $organisationKey
      condition: $condition
      profileTypes: $profileTypes
      ratings: $ratings
      tags: $tags
      days: $days
    ) {
      id
      type
      success
      params
      result {
        key
        heading
        label
        duration
        expiredCount
        expiringSoonCount
      }
    }
  }
`;

export const DATASOURCE_QUERY_COGNITA_RA_ATTRIBUTES = gql`
  query queryCognitaSignoffs {
    queryCognitaSignoffs {
      id
      type
      success
      params
      result {
        id
        name
        location
        signoff1
        signoff2
        signoff3
        signoff4
      }
    }
  }
`;

export const DATASOURCE_QUERY_ACTIVITIES = gql`
  query queryActivities(
    $filter: ActivityFilterInput
    $organisationKey: String!
    $profileId: String
  ) {
    queryActivities(
      filter: $filter
      organisationKey: $organisationKey
      profileId: $profileId
    ) {
      id
      type
      success
      params
      result {
        profile {
          id
          name
        }
        owner {
          id
          type
        }
        id
        type
        date
        tags {
          id
          name
          color
        }
        data
        documents {
          id
          filename
          size
          type
          filestackHandle
        }
      }
    }
  }
`;

export const DATASOURCE_QUERY_PROFILES_WITH_EXTERNAL_CHECKS = gql`
  query queryChecksByProfile(
    $federationKey: String
    $organisationKey: String
    $tags: [String!]
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $duration: String
    $skip: Int
    $limit: Int
  ) {
    queryChecksByProfile(
      federationKey: $federationKey
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
      ratings: $ratings
      duration: $duration
      skip: $skip
      limit: $limit
    ) {
      id
      type
      success
      params
      result {
        id
        name
        typeKey
        tags
        ratings {
          cacheKey
          systemKey
          score
        }
        organisation {
          id
          key
          name
        }
        checkOrders(duration: $duration) {
          id
          orderedAt
          open
          status
          orderId
          jobRoleId
          document
          checks {
            id
            cacheKey
            key
            type
            price {
              value
              currency
            }
            status
            name
          }
          price {
            value
            currency
          }
        }
        data
        dataError
        attributes
        meta
        created
        updated
      }
    }
  }
`;

export const DATASOURCE_QUERY_PROFILES_WITH_EXTERNAL_CHECKS_WITHOUT_ORG = gql`
  query queryChecksByProfile(
    $federationKey: String
    $organisationKey: String
    $tags: [String!]
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $duration: String
    $skip: Int
    $limit: Int
  ) {
    queryChecksByProfile(
      federationKey: $federationKey
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
      ratings: $ratings
      duration: $duration
      skip: $skip
      limit: $limit
    ) {
      id
      type
      success
      params
      result {
        id
        name
        typeKey
        tags
        ratings {
          cacheKey
          systemKey
          score
        }
        checkOrders(duration: $duration) {
          id
          orderedAt
          open
          status
          orderId
          jobRoleId
          document
          checks {
            id
            cacheKey
            key
            type
            price {
              value
              currency
            }
            status
            name
          }
          price {
            value
            currency
          }
        }
        data
        dataError
        attributes
        meta
        created
        updated
      }
    }
  }
`;

export const DATASOURCE_QUERY_PROFILES = gql`
  query queryProfiles(
    $federationKey: String
    $organisationKey: String
    $tags: [String!]
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $condition: JSON
    $dynamicCondition: JSON
    $includeExpiringFields: Boolean
    $skip: Int
    $limit: Int
    $includeDeleted: Boolean
    $includeShared: Boolean
    $sort: [SortQuery!]
  ) {
    queryProfiles(
      federationKey: $federationKey
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
      ratings: $ratings
      condition: $condition
      dynamicCondition: $dynamicCondition
      includeExpiringFields: $includeExpiringFields
      skip: $skip
      limit: $limit
      includeShared: $includeShared
      includeDeleted: $includeDeleted
      sort: $sort
    ) {
      id
      type
      success
      params
      result {
        id
        name
        typeKey
        tags
        ratings {
          cacheKey
          systemKey
          score
        }
        organisation {
          id
          key
          name
        }
        data
        expiry
        dataError
        attributes
        meta
        created
        updated
      }
    }
  }
`;

export const DATASOURCE_QUERY_PROFILES_WITHOUT_ORG = gql`
  query queryProfiles(
    $federationKey: String
    $organisationKey: String
    $tags: [String!]
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $condition: JSON
    $dynamicCondition: JSON
    $skip: Int
    $limit: Int
    $includeDeleted: Boolean
    $includeShared: Boolean
    $sort: [SortQuery!]
  ) {
    queryProfiles(
      federationKey: $federationKey
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
      ratings: $ratings
      condition: $condition
      dynamicCondition: $dynamicCondition
      skip: $skip
      limit: $limit
      includeShared: $includeShared
      includeDeleted: $includeDeleted
      sort: $sort
    ) {
      id
      type
      success
      params
      result {
        id
        name
        typeKey
        tags
        ratings {
          cacheKey
          systemKey
          score
        }
        data
        dataError
        attributes
        meta
        created
        updated
      }
    }
  }
`;

export const DATASOURCE_QUERY_PROFILE_SUMMARY = gql`
  query queryProfileSummary(
    $organisationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryProfileSummary(
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export const DATASOURCE_QUERY_FEDERATION_PROFILE_SUMMARY = gql`
  query queryFederationProfileSummary(
    $federationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryFederationProfileSummary(
      federationKey: $federationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export const DATASOURCE_QUERY_FEDERATION_PROFILESCORESBYRATING = gql`
  query queryFederationProfileScoresByRating(
    $federationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryFederationProfileScoresByRating(
      federationKey: $federationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export const DATASOURCE_QUERY_PROFILESCORESBYRATING = gql`
  query queryProfileScoresByRating(
    $organisationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryProfileScoresByRating(
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export const DATASOURCE_QUERY_FEDERATIONORGANISATIONS = gql`
  query queryFederationOrganisations(
    $federationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryFederationOrganisations(
      federationKey: $federationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export const DATASOURCE_QUERY_COMBINEDPROFILESCORES = gql`
  query queryCombinedProfileScores(
    $organisationKey: String!
    $tags: [String!]
    $profileTypes: [String!]
  ) {
    queryCombinedProfileScores(
      organisationKey: $organisationKey
      tags: $tags
      profileTypes: $profileTypes
    ) {
      id
      type
      success
      params
      result
    }
  }
`;

export type DataSourceQueryResult<T> = {
  id: string;
  type: string;
  success: boolean;
  params: unknown;
  result: T;
};

export interface SignOffsResponse {
  id: string;
  signedOffBy: {
    id: string;
    email: string;
    name: string;
  };
  signedOffAt: string;
  notes: string;
  ratings: {
    systemKey: string;
    score: number;
    count: number;
    valid: number;
    ready: string;
  };
  documents?: {
    id: string;
    tags: string[];
    filename: string;
    size: number;
    type: string;
    filestackHandle: string;
  }[];
}
export const DATASOURCE_QUERY_SIGNOFFS = gql`
  query querySignOffs($organisationKey: String!) {
    querySignOffs(organisationKey: $organisationKey) {
      id
      type
      success
      params
      result {
        id
        signedOffBy {
          id
          email
          name
        }
        signedOffAt
        notes
        ratings {
          systemKey
          score
          count
          valid
          ready
        }
        documents {
          id
          filename
          type
          tags
          size
          filestackHandle
        }
      }
    }
  }
`;

export const DATASOURCE_QUERY_LATE_DATES = gql`
  query queryLateDates(
    $organisationKey: String!
    $federationKey: String
    $profileTypes: [String!]
    $ratings: [RatingQuery!]
    $tags: [String!]
    $condition: JSON!
    $dynamicCondition: JSON!
    $skip: Int
    $limit: Int
  ) {
    queryLateDates(
      organisationKey: $organisationKey
      federationKey: $federationKey
      condition: $condition
      dynamicCondition: $dynamicCondition
      profileTypes: $profileTypes
      ratings: $ratings
      tags: $tags
      skip: $skip
      limit: $limit
    ) {
      id
      type
      success
      params
      result {
        id
        cacheKey
        name
        typeKey
        tags
        data {
          startdate
        }
        checks {
          fieldKey
          checkDate
        }
      }
    }
  }
`;
