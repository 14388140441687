export default {
  defaults: {
    when: 'value$',
    compute: 'daysDifference',
  },
  settings: {
    singleGroup: true,
  },
  variables: {
    value$: {
      type: 'date',
      source: ['field', 'dbs_renewal_date'],
    },
  },
  groups: [
    {
      key: 'leavers',
      label: 'Leavers',
      hidden: true,
      variables: {
        value$: {
          type: 'date',
          source: ['field', 'leavedate'],
        },
      },
      conditions: [
        {
          when: 'value$',
          compute: 'daysDifference',
          comparison: [
            'lessThan',
            {
              value: 0,
            },
          ],
        },
      ],
    },
    {
      key: 'notset',
      label: 'no value',
      hidden: true,
      conditions: [
        {
          compute: 'timestamp',
          comparison: [
            'equals',
            {
              value: 0,
            },
          ],
        },
      ],
    },
    {
      key: 'due2months',
      label: 'Due 60 days from today',
      conditions: [
        {
          comparison: [
            'lessThan',
            {
              value: 60,
            },
          ],
        },
        {
          comparison: [
            'greaterThan',
            {
              value: 30,
            },
          ],
        },
      ],
    },
    {
      key: 'due1month',
      label: 'Due 30 days from today',
      conditions: [
        {
          comparison: [
            'lessThan',
            {
              value: 31,
            },
          ],
        },
        {
          comparison: [
            'greaterThan',
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      key: 'duetomorrow',
      label: 'Due Tomorrow',
      conditions: [
        {
          comparison: [
            'equals',
            {
              value: 1,
            },
          ],
        },
      ],
    },
    {
      key: 'duetoday',
      label: 'Due Today',
      conditions: [
        {
          comparison: [
            'equals',
            {
              value: 0,
            },
          ],
        },
      ],
    },
    {
      key: 'overdue',
      label: 'Overdue',
      conditions: [
        {
          comparison: [
            'lessThan',
            {
              value: 0,
            },
          ],
        },
      ],
    },
  ],
};
