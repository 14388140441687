import permissions from '@hogwarts/permissions';
import { get } from 'lodash';
import React from 'react';
import Dashboards from '../containers/dashboards';
import Insights from '../containers/insights';
import {
  BulkImporter,
  ProfileGridEditor,
  ProfileList,
  ProfileListQuery,
  ProfileTransfers,
} from '../containers/profiles';
import BulkProfileImages from '../containers/settings/BulkProfileImages';
import UserProfile from '../containers/userProfile';
import { isFeatureEnabled } from '../hooks/useFeature';
import { Organisation } from '../types';
import { Route } from './types';

export const organisationRoutes = (organisation: Organisation): Route[] => [
  {
    path: `/${organisation.key}`,
    permission: [permissions.PROFILE_READ],
    Component: (props: any) => <Dashboards {...props} />,
  },
  {
    path: `/${organisation.key}/profiles/transfers`,
    permission: [permissions.READ],
    // eslint-disable-next-line
    Component: ({ query, ...props }: any) => {
      return <ProfileTransfers {...props} />;
    },
  },
  {
    path: `/${organisation.key}/import`,
    permission: [permissions.BULK_IMPORT_READ, permissions.BULK_IMPORT_UPDATE],
    hideHeader: true,
    Component: ({ query, ...props }: any) => {
      return <BulkImporter profileTypeKey={query.type} {...props} />;
    },
    visible: () => {
      return isFeatureEnabled('functions.bulkimporter', organisation);
    },
  },
  {
    path: `/${organisation.key}/profiles/:profileId/:tabId?`,
    permission: [permissions.PROFILE_READ],
    Component: (props: any) => {
      const profileId = get(props, 'match.params.profileId');
      const tabId = get(props, 'match.params.tabId');
      return <UserProfile profileId={profileId} tabId={tabId} />;
    },
  },
  {
    path: `/${organisation.key}/editor`,
    permission: [permissions.PROFILE_READ],
    // eslint-disable-next-line
    Component: ({ query, ...props }: any) => {
      return <ProfileGridEditor {...props} />;
    },
  },
  {
    path: `/${organisation.key}/profiles`,
    permission: [permissions.PROFILE_READ],
    // eslint-disable-next-line
    Component: ({ query, ...props }: any) => {
      return <ProfileList {...props} />;
    },
  },
  {
    path: `/${organisation.key}/profilesquery`,
    permission: [permissions.PROFILE_READ],
    // eslint-disable-next-line
    Component: ({ query, ...props }: any) => {
      return <ProfileListQuery {...props} />;
    },
  },
  {
    path: `/${organisation.key}/insights`,
    permission: [permissions.PROFILE_READ],
    Component: (props: any) => <Insights {...props} />,
  },
  {
    path: `/${organisation.key}/bulkprofileimages`,
    permission: [
      permissions.PROFILE_FILE_LIST,
      permissions.PROFILE_FILE_CREATE,
    ],
    Component: BulkProfileImages,
    visible: () =>
      isFeatureEnabled('functions.bulkprofileimages', organisation),
  },
];
