import React from 'react';
import logo from '../../../../assets/providers/accessgroup.png';
import { ProviderMeta } from '../types';

const PROVIDER_KEY = 'accesspeople';

const HelpBlock = () => {
  return (
    <div>
      <p>To connect to Access People please contact us.</p>

      <p>
        For further help on this, please see our{' '}
        <a href="https://go.scrtracker.com/3TbyGPj" target="blank">
          help guides.
        </a>
      </p>
    </div>
  );
};

const connectionFields = [
  {
    key: 'environmentId',
    label: 'Environment Id',
    type: 'textbox',
  },
  {
    key: 'clientId',
    label: 'Client ID',
    type: 'textbox',
  },
  {
    key: 'clientSecret',
    label: 'Client Secret',
    type: 'password',
  },
  // https://preprodaccesspeople.accessacloud.com/SCRSecure
  {
    key: 'url',
    label: 'URL',
    type: 'textbox',
  },
];

export default {
  key: PROVIDER_KEY,
  name: 'Access People',
  logo: logo,
  url: 'https://go.scrtracker.com/3wwCmkV',
  connectionFields,
  showOptions: {
    showFieldMap: true,
    showConnection: true,
    showProfileTypeMap: true,
  },
  HelpBlock,
} as ProviderMeta;
