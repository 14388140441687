import { Intent } from '@blueprintjs/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const getExportTypeProps = (
  exportType: string,
  allowed: boolean
): {
  icon?: IconProp;
  text: string;
  intent: Intent;
  allowed: boolean;
} => {
  if (exportType?.endsWith('-excel')) {
    return {
      icon: 'file-excel',
      text: 'Export',
      intent: 'success',
      allowed,
    };
  } else if (exportType?.endsWith('-pdf')) {
    return {
      icon: 'file-pdf',
      text: 'Export',
      intent: 'danger',
      allowed,
    };
  }
  return {
    text: exportType,
    intent: 'primary',
    allowed,
  };
};
