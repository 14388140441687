import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '@/context';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const HelpMenu = ({ children }) => {
  const analytics = useContext(AnalyticsContext);
  const [chatBlocked, setChatBlocked] = useState(true);
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  // Open chatlio if trial
  const isTrial =
    organisation?.attributes?.trial || federation?.attributes?.trial;
  const [chatOpen, setChatOpen] = useState(isTrial);

  useEffect(() => {
    if (!window._chatlio) {
      setChatBlocked(true);
    } else {
      setChatBlocked(false);
      if (chatOpen) {
        window._chatlio.open();
      }
    }
  }, []);

  useEffect(() => {
    const handleVisChange = (event) => {
      const vis = event.data.visibility === 'open';
      setChatOpen(vis);
    };
    document.addEventListener('chatlio.visibilityChange', handleVisChange);
    return () => {
      document.removeEventListener('chatlio.visibilityChange', handleVisChange);
    };
  }, []);

  if (chatBlocked) {
    return (
      <Popover
        position={Position.BOTTOM}
        onOpened={() => {
          analytics.events.header.helpChatBlocked();
        }}
      >
        {children}
        <div style={{ maxWidth: '400px', padding: '20px' }}>
          <h4>Help Chat Blocked</h4>
          <p>
            It appears your device is blocking our help chat from loading. This
            is normally due to an ad-blocker or firewall, if you speak with your
            IT team you may be able to get this unblocked.
          </p>
          <p>
            You can still contact us via email at{' '}
            <a href="mailto:help@signincentralrecord.com">
              help@signincentralrecord.com
            </a>{' '}
            and you can read our help articles{' '}
            <a href="https://go.scrtracker.com/help">here.</a>
          </p>
        </div>
      </Popover>
    );
  } else {
    return (
      <Popover position={Position.BOTTOM}>
        {children}
        <Menu>
          <MenuItem
            icon="git-repo"
            text={t('Help Articles')}
            href="https://go.scrtracker.com/help"
            target="_blank"
          />
          <MenuItem
            icon="chat"
            text={chatOpen ? t('Close Chat') : t('Support Chat')}
            onClick={() => {
              if (chatOpen) {
                window._chatlio.hide();
                setChatOpen(false);
              } else {
                window._chatlio.open();
                setChatOpen(true);
              }
            }}
          />
        </Menu>
      </Popover>
    );
  }
};

export default HelpMenu;
