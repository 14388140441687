import React from 'react';
import { NonIdealState, Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const UserProfileNotFound = ({ onProfilesClick }) => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon={<FontAwesomeIcon icon={['far', 'user']} />}
      title={t('Profile Not Found')}
      description={
        <div>
          <p>
            {t(
              'The profile you just requested may have been deleted, or may not belong to this school.'
            )}
          </p>
          <p>
            {t(
              'If you think this is our mistake, please contact support at help@scrtracker.com'
            )}
          </p>
        </div>
      }
      action={
        <Button intent="primary" onClick={onProfilesClick}>
          {t('View Profiles')}
        </Button>
      }
    />
  );
};

export default UserProfileNotFound;
