import { Button } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export interface SettingCategory {
  key: string;
  label: string;
}
export interface SettingMenuItem {
  key: string;
  label: string;
  category: string;
  restricted?: boolean;
}
interface SettingsMenuProps {
  categories: SettingCategory[];
  items: SettingMenuItem[];
  selected?: string;
  onSelected: (key: string) => void;
}
export const SettingsMenu = ({
  categories,
  items,
  selected,
  onSelected,
}: SettingsMenuProps) => {
  const { t } = useTranslation();

  return (
    <>
      {categories.map((category, categoryIndex) => {
        const categoryItems = items.filter(
          (item) => item.category === category.key
        );

        if (!categoryItems.length) return null;

        return (
          <div key={category.key} className={cn('d-flex flex-column')}>
            <div
              className={cn(
                'text-uppercase',
                'font-weight-bold',
                styles.categoryText,
                'mb-2',
                categoryIndex > 0 && 'mt-3'
              )}
            >
              {t(category.label)}
            </div>

            <div className={cn('d-flex flex-column')}>
              {categoryItems.map((item, itemIndex) => {
                const key = `${category.key}.${item.key}`;
                const active = !item.restricted && selected === key;
                return (
                  <Button
                    alignText="left"
                    key={key}
                    active={active}
                    disabled={item.restricted}
                    icon={item.restricted && ['far', 'lock']}
                    className={cn(
                      active && 'font-weight-bold',
                      itemIndex > 0 && 'mt-1'
                    )}
                    intent={active ? 'primary' : 'none'}
                    onClick={() => {
                      if (item.restricted) return;
                      if (onSelected) {
                        onSelected(key);
                      }
                    }}
                    minimal
                  >
                    {t(item.label)}
                  </Button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
