import { gql } from '@apollo/client';

export const REQUEST_ACCOUNT_SETUP = gql`
  mutation requestAccountSetup($values: JSON!) {
    requestAccountSetup(values: $values)
  }
`;

export const ORDER_CHECK = gql`
  mutation orderCheck(
    $organisationId: ID!
    $profileId: ID!
    $checkKeys: [String!]!
    $values: JSON!
    $purchaseOrder: String
    $agreedToTerms: Boolean!
    $notes: String
  ) {
    orderCheck(
      organisationId: $organisationId
      profileId: $profileId
      checkKeys: $checkKeys
      values: $values
      purchaseOrder: $purchaseOrder
      agreedToTerms: $agreedToTerms
      notes: $notes
    ) {
      id
      validationErrors {
        errorLevel
        fieldPath
        message
      }
    }
  }
`;

export const CONFIRM_IDENTITY = gql`
  mutation confirmIdentity(
    $organisationId: ID!
    $orderId: Int!
    $candidateChanges: JSON!
    $primaryDocuments: [String!]!
    $secondaryDocuments: [String!]!
    $financialDocuments: [String!]!
    $checkedByFirstName: String!
    $checkedByLastName: String!
    $codeOfPracticeConfirmed: Boolean!
  ) {
    confirmIdentity(
      organisationId: $organisationId
      orderId: $orderId
      candidateChanges: $candidateChanges
      primaryDocuments: $primaryDocuments
      secondaryDocuments: $secondaryDocuments
      financialDocuments: $financialDocuments
      checkedByFirstName: $checkedByFirstName
      checkedByLastName: $checkedByLastName
      codeOfPracticeConfirmed: $codeOfPracticeConfirmed
    ) {
      confirmed
      validationErrors {
        errorLevel
        fieldPath
        message
      }
    }
  }
`;

export const CANCEL_CHECK_ORDER = gql`
  mutation cancelCheckOrder($uniqueKey: String!) {
    cancelCheckOrder(uniqueKey: $uniqueKey)
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder($id: ID!, $purchaseOrder: String) {
    updatePurchaseOrder(id: $id, purchaseOrder: $purchaseOrder) {
      id
      orderId
      profileId
      orderedAt
      purchaseOrder
      open
      status
      awaitingConfirmId
      checks {
        id
        cacheKey
        key
        type
        name
        price {
          value
          currency
        }
        status
      }
      price {
        value
        currency
      }
    }
  }
`;

export const TOGGLE_CHECK_ACCOUNT_PREFERENCES = gql`
  mutation toggleCheckAccountPreferences($organisationId: ID!, $key: String!) {
    toggleCheckAccountPreferences(organisationId: $organisationId, key: $key)
 }
`;

