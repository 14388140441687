import React, { useContext, useMemo } from 'react';
import FilterBar from '../../components/Filters/Bar';
import { OrganisationContext } from '../../context';
import { useQuery } from '../../hooks';
import {
  GET_AUDIT_EVENTS,
  GET_ORGANISATION_USERS,
  GET_ORGANISATION_USERSResponse,
} from '../../queries';

interface AuditLogFiltersContainerProps {
  onFilter: (filter: any) => void;
}
const AuditLogFiltersContainer = ({
  onFilter,
}: AuditLogFiltersContainerProps) => {
  const organisation = useContext(OrganisationContext);
  const { loading: usersLoading, data: usersData } = useQuery<
    GET_ORGANISATION_USERSResponse[]
  >(GET_ORGANISATION_USERS, {
    selector: 'organisations[0].users',
    variables: {
      organisationKey: organisation.key,
    },
  });
  const users = useMemo(() => {
    if (usersData) {
      return usersData
        .filter((user) => user.enabled)
        .map((user) => ({ id: user.id, value: user.name }));
    }
  }, [usersData]);
  const { loading: eventsLoading, data: eventsData } = useQuery<string[]>(
    GET_AUDIT_EVENTS,
    {
      selector: 'auditEvents',
    }
  );
  const events = useMemo(() => {
    if (eventsData) {
      return eventsData.map((event) => ({ id: event, value: event }));
    }
  }, [eventsData]);

  const filters = [
    {
      type: 'date',
      id: 'dateRange',
      description: 'Date Range',
    },
    {
      type: 'select',
      id: 'userId',
      icon: 'user',
      description: 'User',
      loading: usersLoading,
      items: users,
    },
    {
      type: 'select',
      id: 'event',
      icon: 'pulse',
      description: 'Event',
      loading: eventsLoading,
      items: events,
    },
  ];

  return <FilterBar filters={filters} onFilter={onFilter} />;
};

export default AuditLogFiltersContainer;
