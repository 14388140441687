import React from 'react';
import cn from 'classnames';
import { ButtonGroup } from '@blueprintjs/core';
import { Button } from '@hogwarts/ui-components-core';

import styles from './styles.module.css';

const RatingItem = ({
  ratingSystem,
  disabled,
  data,
  onEnabledChange,
  onLockChange,
  onFilterChange,
}) => {
  const { enabled, lock, locked } = data || {};

  disabled = disabled || locked;
  return (
    <div className={'d-flex justify-content-center align-items-center'}>
      <ButtonGroup>
        <Button
          disabled={disabled}
          // iconColor={!disabled && '#2e2cc0'}
          icon={enabled ? 'check' : 'times'}
          minimal
          onClick={() => onEnabledChange(!enabled)}
        />
        <Button
          disabled={disabled}
          icon={lock || locked ? 'lock' : 'unlock'}
          minimal
          tooltip={
            'Locking prevents any child Templates from overriding this value'
          }
          onClick={() => onLockChange(!lock)}
        />
        {/* Will add this in vNext */}
        {/* <Button disabled={disabled} icon="filter" minimal /> */}
      </ButtonGroup>
    </div>
  );
};

const HeaderRow = ({ ratingSystems }) => {
  return (
    <thead>
      <tr>
        <th className={styles.headerCell} colSpan="2">
          Visibility
        </th>
        {ratingSystems &&
          ratingSystems.map((ratingSystem) => {
            return (
              <th
                key={ratingSystem.key}
                className={cn(
                  'text-nowrap',
                  styles.ratingSystemHeader,
                  styles.headerCell
                )}
              >
                {ratingSystem.label}
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

const VisibilityAndRatingItem = ({
  profileType,
  ratingSystems,
  data,
  onEnabledChange,
  onLockChange,
  onFilterChange,
}) => {
  const { enabled, lock, locked, ratings = {} } = data || {};
  return (
    <tr className={styles.row}>
      <td>
        <div className={'d-flex'}>
          <ButtonGroup>
            <Button
              disabled={locked}
              minimal
              icon={enabled ? 'eye' : 'eye-slash'}
              // iconColor={!locked && enabled && '#2e2cc0'}
              onClick={() => {
                if (onEnabledChange) {
                  return onEnabledChange({
                    profileTypeKey: profileType.key,
                    enabled: !enabled,
                  });
                }
              }}
            />
            <Button
              disabled={locked}
              minimal
              icon={lock || locked ? 'lock' : 'unlock'}
              // iconColor={!locked && lock && '#2e2cc0'}
              tooltip={
                'Locking prevents any child Templates from overriding this value'
              }
              onClick={() => {
                if (onLockChange) {
                  return onLockChange({
                    profileTypeKey: profileType.key,
                    lock: !lock,
                  });
                }
              }}
            />
          </ButtonGroup>
        </div>
      </td>
      <td className="w-100">{profileType.label}</td>
      {ratingSystems &&
        ratingSystems.map((ratingSystem) => {
          return (
            <td key={ratingSystem.key} className={styles.ratingSystemItem}>
              <RatingItem
                key={ratingSystem.key}
                disabled={!enabled}
                ratingSystem={ratingSystem}
                data={ratings && ratings[ratingSystem.key]}
                onEnabledChange={(enabled) => {
                  if (onEnabledChange) {
                    return onEnabledChange({
                      enabled,
                      ratingSystemKey: ratingSystem.key,
                      profileTypeKey: profileType.key,
                    });
                  }
                }}
                onLockChange={(lock) => {
                  if (onLockChange) {
                    onLockChange({
                      lock,
                      ratingSystemKey: ratingSystem.key,
                      profileTypeKey: profileType.key,
                    });
                  }
                }}
                onFilterChange={onFilterChange}
              />
            </td>
          );
        })}
    </tr>
  );
};

const VisibilityAndRatings = ({
  profileTypes,
  ratingSystems,
  values,
  onLockChange,
  onEnabledChange,
  onFilterChange,
}) => {
  return (
    <table className={styles.table}>
      <HeaderRow ratingSystems={ratingSystems} />
      <tbody>
        {profileTypes &&
          profileTypes.map((profileType) => {
            return (
              <VisibilityAndRatingItem
                key={profileType.key}
                profileType={profileType}
                onLockChange={onLockChange}
                onEnabledChange={onEnabledChange}
                onFilterChange={onFilterChange}
                data={values && values[profileType.key]}
                ratingSystems={ratingSystems}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default VisibilityAndRatings;
