import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldTab } from './fields';
import styles from './styles.module.css';

const SectionEditor = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className={styles.editorContainer}>
      <div className={styles.headerContainer}>
        <p className={styles.editorHeader}>{t('Section Fields')}</p>
      </div>
      <FieldTab {...props} />
    </div>
  );
};

export default SectionEditor;
