import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputGroup,
} from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement,
  SortableHandle as sortableHandle,
} from 'react-sortable-hoc';
import { ChoosableColumn, Column, ColumnGroup } from '../../types';
import styles from './styles.module.css';

const DragHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

interface ColumnItemProps {
  columnKey: string;
  label: string;
  visible: boolean;
  locked: boolean;
  onVisibleChange: (key: string, visible: boolean) => void;
}
const ColumnItem = SortableElement(
  ({ columnKey, locked, visible, label, onVisibleChange }: ColumnItemProps) => {
    return (
      <li
        className={
          'list-group-item d-flex flex-row p-2 align-items-center mr-2'
        }
      >
        <Checkbox
          className="mb-0 mr-1"
          checked={!!visible}
          disabled={locked}
          onClick={() => onVisibleChange(columnKey, !visible)}
        />
        <DragHandle />
        <div className={'w-100 pl-3 d-flex flex-row align-items-center'}>
          {/* Idiot, need to use the Field Icons from Input Type? */}
          {/* <FontAwesomeIcon className="mr-1" icon={'question-circle'} /> */}
          <div>{label}</div>

          <div className="flex-grow-1" />
          {/* <FontAwesomeIcon className="mr-1" icon={'times'} /> */}
        </div>
      </li>
    );
  }
);

interface SortableContainerProps {
  children: React.ReactNode;
}
const SortableContainer = sortableContainer(
  ({ children }: SortableContainerProps) => {
    return <ul className="list-group">{children}</ul>;
  }
);

interface ColumnGroupProps {
  label: string;
  expanded: boolean;
  columns: ChoosableColumn[];
  onExpandChange: (expanded: boolean) => void;
  onVisibleChange: (keys: string[], visible: boolean) => void;
  onOrderChange: (a: Column, b: Column) => void;
}
export const ColumnGroup2 = ({
  label,
  columns,
  expanded,
  onExpandChange,
  onVisibleChange,
  onOrderChange,
}: ColumnGroupProps) => {
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    return onOrderChange(columns[oldIndex], columns[newIndex]);
  };

  let allVisible = true;
  let allHidden = true;
  for (const column of columns) {
    if (column.visible) {
      allHidden = false;
    } else {
      allVisible = false;
    }
  }
  let overallCheckedState = null;
  if (allVisible) overallCheckedState = true;
  if (allHidden) overallCheckedState = false;

  return (
    <>
      <div className="d-flex flex-columns align-items-center">
        <Button
          icon={expanded ? 'caret-down' : 'caret-right'}
          minimal
          onClick={() => onExpandChange(!expanded)}
        />
        <Checkbox
          className="mb-0"
          checked={!!overallCheckedState}
          indeterminate={overallCheckedState === null}
          onClick={() => {
            const columns2 = columns.filter((c) => !c.locked);
            const allVisible = columns2.every((c) => c.visible);
            return onVisibleChange(
              columns2.map((c) => c.key),
              !allVisible
            );
          }}
        />
        <div className="mb-1 font-weight-bold">{label}</div>
      </div>
      <Collapse
        isOpen={expanded}
        className={cn(styles.groupCollapse, expanded && 'mb-2')}
        keepChildrenMounted
      >
        <SortableContainer
          helperClass={styles.sortableHelper}
          useDragHandle
          axis="y"
          onSortEnd={onSortEnd}
        >
          {columns.map((column, index) => (
            <ColumnItem
              key={column.key || index}
              index={index}
              columnKey={column.key}
              visible={!!column.visible}
              label={column.label}
              locked={column.locked}
              onVisibleChange={() =>
                onVisibleChange([column.key], !column.visible)
              }
            />
          ))}
        </SortableContainer>
      </Collapse>
    </>
  );
};

interface ColumnChooserProps {
  groups: ColumnGroup[];
  columns: ChoosableColumn[];
  onVisibleChange: (keys: string[], visible: boolean) => void;
  onOrderChange: (a: Column, b: Column) => void;
}
export const ColumnChooser = ({
  groups,
  columns,
  onVisibleChange,
  onOrderChange,
}: ColumnChooserProps) => {
  /* TODO: 
    Collapse all.
    Config Button (Cog for showing a drawer etc?)
  */

  const [searchFilter, setSearchFilter] = useState('');

  const [groupsExpanded, setGroupsExpanded] = useState<Record<string, boolean>>(
    {}
  );

  const filteredGroups = useMemo(() => {
    const result: (ColumnGroup & {
      expanded: boolean;
      columns: ChoosableColumn[];
    })[] = [];
    for (const group of groups) {
      result.push({
        ...group,
        expanded: groupsExpanded[group.key] !== false,
        columns: columns.filter((c) => {
          if (c.group !== group.key) return false;
          if (searchFilter && c.label) {
            return c.label.toLowerCase().includes(searchFilter.toLowerCase());
          }
          return true;
        }),
      });
    }
    return result.filter((g) => g.columns.length);
  }, [columns, groups, groupsExpanded, searchFilter]);

  let allVisible = true;
  let allHidden = true;
  let allCollapsed = true;
  let allExpanded = true;
  for (const group of filteredGroups) {
    if (groupsExpanded[group.key] !== false) {
      allCollapsed = false;
    } else {
      allExpanded = false;
    }
    for (const column of group.columns) {
      if (column.visible) {
        allHidden = false;
      } else {
        allVisible = false;
      }
    }
  }
  let overallCheckedState = null;
  if (allVisible) overallCheckedState = true;
  if (allHidden) overallCheckedState = false;

  let overallExpandedState: boolean | null = null;
  if (allExpanded) overallExpandedState = true;
  if (allCollapsed) overallExpandedState = false;

  return (
    <div className="w-100 mt-3">
      <div className="d-flex flex-columns align-items-center mb-2">
        <Button
          icon={
            overallExpandedState === true
              ? 'caret-down'
              : overallExpandedState === false
              ? 'caret-right'
              : 'small-minus'
          }
          minimal
          onClick={() => {
            setGroupsExpanded(
              groups.reduce(
                (prev, group) => ({
                  ...prev,
                  [group.key]: !overallExpandedState,
                }),
                {}
              )
            );
          }}
        />
        <Checkbox
          className="mb-0"
          checked={!!overallCheckedState}
          indeterminate={overallCheckedState === null}
          onClick={() => {
            const columns = filteredGroups.reduce<ChoosableColumn[]>(
              (prev, cur) => [...prev, ...cur.columns.filter((c) => !c.locked)],
              []
            );

            const allVisible = columns.every((c) => c.visible);
            onVisibleChange(
              columns.map((c) => c.key),
              !allVisible
            );
          }}
        />
        <InputGroup
          className="mr-2"
          leftIcon="filter"
          fill
          placeholder="Search..."
          value={searchFilter}
          onChange={(event) => {
            const e = event as React.ChangeEvent<HTMLInputElement>;
            return setSearchFilter(e.target.value);
          }}
        />
      </div>
      <Divider />

      <div className="d-flex flex-column">
        {filteredGroups.map((group, index) => (
          <React.Fragment key={group.key || index}>
            <ColumnGroup2
              label={group.label}
              columns={group.columns}
              expanded={group.expanded}
              onVisibleChange={(keys, state) => onVisibleChange(keys, state)}
              onOrderChange={onOrderChange}
              onExpandChange={(state) => {
                setGroupsExpanded({
                  ...groupsExpanded,
                  [group.key]: state,
                });
              }}
            />
            {index < groups.length && <div className="mt-0"> </div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
