import permissions from '@hogwarts/permissions';
import { Loading } from '@hogwarts/ui-components-core';
import React, { useContext } from 'react';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMutation, usePermission, useQuery } from '../../../hooks';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import {
  CHANGE_ORGANISATION_PROVIDER,
  ORGANISATION_DATA_PROVIDER_IMPORT_NOW,
  UPDATE_ORGANISATION_PROVIDER_CONFIG,
} from '../../../mutations';
import { GET_ORGANISATIONS, GET_ORGANISATION_PROVIDER } from '../../../queries';
import DataProviderContainer from './DataProviderContainer';
import allProviders from './providers';

const useUpdateOrganisationProviderConfig = () => {
  const organisation = useContext(OrganisationContext);

  const [updateOrganisationProviderConfig] = useMutation(
    UPDATE_ORGANISATION_PROVIDER_CONFIG,
    {
      variables: {
        organisationId: organisation.id,
      } as {
        organisationId?: string;
        config?: any;
      },
      refetchQueries: [
        {
          query: GET_ORGANISATIONS,
          variables: {
            organisationKey: organisation.key,
          },
        },
      ],
    }
  );
  return (config: any) =>
    updateOrganisationProviderConfig({
      variables: {
        config,
      },
    });
};

const useChangeOrganisationProvider = () => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const [changeOrganisationProvider] = useMutation(
    CHANGE_ORGANISATION_PROVIDER,
    {
      variables: {
        organisationId: organisation.id,
      } as {
        organisationId: string;
        providerKey: string;
        config: any;
      },
      refetchQueries: [
        {
          query: GET_ORGANISATIONS,
          variables: {
            organisationKey: organisation.key,
          },
        },
      ],
    }
  );

  const changeProvider = async (providerKey: string) => {
    analytics.events.dataProvider.changedProvider({
      providerKey,
    });

    const current = organisation.provider;
    if (current?.key === providerKey) {
      return;
    }

    return await changeOrganisationProvider({
      variables: {
        organisationId: organisation.id,
        providerKey,
        config: {},
      },
    });
  };

  return changeProvider;
};

const OrganisationDataProviderContainer = () => {
  const organisation = useContext(OrganisationContext);

  const [queueOrganisationDataProviderImport] = useMutation(
    ORGANISATION_DATA_PROVIDER_IMPORT_NOW,
    {
      variables: {
        organisationId: organisation.id,
        discoveryMode: false,
      },
    }
  );

  const { data: provider, loading } = useQuery(GET_ORGANISATION_PROVIDER, {
    variables: {
      organisationKey: organisation.key,
    },
    selector: 'organisations[0].provider',
    pollInterval: 3000,
  });

  const providers = allProviders.filter((provider) =>
    isFeatureEnabled(`providers.${provider.key}`, organisation)
  );

  const changeProvider = useChangeOrganisationProvider();
  const updateConfig = useUpdateOrganisationProviderConfig();

  const canImportNow = usePermission(
    permissions.PROVIDER_IMPORT,
    organisation.id
  );
  const canEditConfig = usePermission(
    permissions.PROVIDER_CONFIG_UPDATE,
    organisation.id
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <DataProviderContainer
      showOrganisationMap={false}
      scheme={organisation.scheme}
      changeProvider={changeProvider}
      providers={providers}
      config={provider}
      selectedProviderKey={provider?.key}
      canImportNow={canImportNow}
      canDisconnect={canEditConfig}
      onImport={() => {
        return queueOrganisationDataProviderImport({
          variables: {
            organisationId: organisation.id,
            discoveryMode: false,
          },
        });
      }}
      onImportDiscovery={() => {
        return queueOrganisationDataProviderImport({
          variables: {
            organisationId: organisation.id,
            discoveryMode: true,
          },
        });
      }}
      onSaveLocationMap={(locationMap: any) => {
        throw new Error('Not Supported for Organisation');
      }}
      onSaveFieldMap={(fieldMap: any) => {
        // analytics.events.dataProvider.saveFieldMapClicked({ PROVIDER_KEY });
        return updateConfig({
          fieldMap,
        });
      }}
      onSaveProfileTypeMap={(profileTypeMap) => {
        return updateConfig({
          profileTypeMap,
        });
      }}
      onSaveConnection={(connection) => {
        return updateConfig({
          connection,
        });
      }}
      onDisconnect={() => {
        return updateConfig({
          connection: {},
        });
      }}
    />
  );
};

export default OrganisationDataProviderContainer;
