import React from 'react';
import {
  Classes,
  Dialog,
  Button,
  Intent,
  Callout,
  Tooltip,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { OrganisationSelector } from '@/components';
import styles from './styles.module.css';
import { Loading } from '@hogwarts/ui-components-core';

const CopyProfileDialog = ({
  isOpen,
  onClose,
  targetOrg,
  onTargetOrgSelected,
  onCopy,
  loading,
  error,
  profileName,
  organisations,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      // https://github.com/palantir/blueprint/issues/3854
      enforceFocus={false}
      usePortal
      onClose={onClose}
      title={t('Copy Profile')}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        {loading && (
          <div className="pt-5">
            <Loading />
          </div>
        )}
        {error && (
          <Callout
            intent={Intent.WARNING}
            title={t('Unable to load organisations')}
          >
            {t('Please try again later')}
          </Callout>
        )}
        {organisations && (
          <>
            <p>
              <strong>
                {t(
                  'Please select the Organisation you wish to copy {{ profileName }} to:',
                  { profileName }
                )}
              </strong>
            </p>
            <div className="d-flex justify-content-center my-4">
              <OrganisationSelector
                small
                organisations={organisations}
                onSelected={onTargetOrgSelected}
              >
                <Button
                  className={styles.select}
                  rightIcon="caret-down"
                  text={targetOrg ? targetOrg.name : t('Select Organisation')}
                />
              </OrganisationSelector>
            </div>
            <p>
              {t(
                "If the organisation isn't listed, you need be granted permission by that organisation first."
              )}
            </p>
            <p>{t('Limitation: This does NOT copy Documents')}</p>
            <p>{t('Audit logs will be created.')}</p>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>{t('Close')}</Button>
          <Tooltip
            disabled={targetOrg}
            content={t('You must first select a target organisation')}
          >
            <Button
              intent={Intent.PRIMARY}
              disabled={!targetOrg}
              onClick={onCopy}
            >
              {t('Copy')}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Dialog>
  );
};

export default CopyProfileDialog;
