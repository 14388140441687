import React from 'react';
import cn from 'classnames';
import styles from '../styles.module.css';

import { useTranslation } from 'react-i18next';

export const ProfilesList = ({ profiles }) => {
  const { t } = useTranslation();
  let previous = {};
  let alternate = true;
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th className={styles.headerCell}>{t('Profile Name')}</th>
          <th className={styles.headerCell}>{t('Section')}</th>
          <th className={styles.headerCell}>{t('Field')}</th>
        </tr>
        {profiles.length === 0 && (
          <tr>
            <td className={styles.cell} colSpan={3}>{`${t(
              'No Incomplete Profiles Found'
            )}!`}</td>
          </tr>
        )}
        {profiles.map(({ profile, section, field }, index) => {
          let rowStyle;
          if (previous.profile !== profile) {
            rowStyle = styles.rowSeparator;
            alternate = !alternate;
          }
          const result = (
            <tr
              key={index}
              className={cn(rowStyle, {
                [styles.alternateRow]: alternate,
              })}
            >
              <td className={cn(styles.cell)}>
                {profile !== previous.profile && profile.name}
              </td>
              <td className={cn(styles.cell)}>
                {section !== previous.section && t(section.label)}
              </td>
              <td className={cn(styles.cell)}>
                {t(field.label) || field.dataKey}
              </td>
            </tr>
          );
          previous.profile = profile;
          previous.section = section;
          previous.field = field;
          return result;
        })}
      </tbody>
    </table>
  );
};
