import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faClone as falClone,
  faInfoCircle as falInfoCircle,
  faTable as falTable,
  faTimesCircle as falTimesCircle,
  faTrash as falTrash,
} from '@fortawesome/pro-light-svg-icons';
import {
  faFileSignature as falFileSignature,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faClipboardCheck as farClipboardCheck,
  faClock as farClock,
  faCog as farCog,
  faColumns as farColumns,
  faExclamationCircle as farExclamationCircle,
  faExclamationTriangle as farExclamationTriangle,
  faFile as farFile,
  faFileAlt as farFileAlt,
  faFileArchive as farFileArchive,
  faFileAudio as farFileAudio,
  faFileExcel as farFileExcel,
  faFileImage as farFileImage,
  faFileInvoice as farFileInvoice,
  faFileLines as farFileLines,
  faFilePdf as farFilePdf,
  faFilePowerpoint as farFilePowerpoint,
  faFileSignature as farFileSignature,
  faFileVideo as farFileVideo,
  faFileWord as farFileWord,
  faFolders as farFolders,
  faInfoCircle as farInfoCircle,
  faLock as farLock,
  faShieldCheck as farShieldCheck,
  faStar as farStar,
  faThumbsUp as farThumbsUp,
  faTimes as farTimes,
  faTrash as farTrash,
  faTrashAlt as farTrashAlt,
  faUpload as farUpload,
  faUser as farUser,
  faUserPlus as farUserPlus,
  faUserSecret as farUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowAltDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faCamera,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCloudUpload,
  faCog,
  faCogs,
  faCommentQuestion,
  faCommentXmark,
  faCopy,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFilter,
  faFolderOpen,
  faFunction,
  faGripVertical,
  faHeadset,
  faInfoCircle,
  faLambda,
  faLineColumns,
  faLink,
  faLinkSlash,
  faLock,
  faLockAlt,
  faLockOpen,
  faLongArrowRight,
  faPaperPlane,
  faPencil,
  faPlayCircle,
  faPlus,
  faPoundSign,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRandom,
  faSave,
  faSchool,
  faSearch,
  faSigma,
  faSignIn,
  faSignOut,
  faSitemap,
  faSpinner,
  faSquare,
  faStar,
  faSync,
  faTable,
  faTag,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTrashAlt,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  faUserCheck,
  faUserHardHat,
  faUserPlus,
  faUserSecret,
  faUserTag,
} from '@fortawesome/pro-solid-svg-icons';

export default () => {
  library.add(
    farFileInvoice,
    farFileLines,
    faPoundSign,
    faUserCheck,
    faUserHardHat,
    falInfoCircle,
    faHeadset,
    falClone,
    falTable,
    faLineColumns,
    faCamera,
    faStar,
    faFunction,
    faSigma,
    faLambda,
    faLink,
    falTrash,
    falTimesCircle,
    faCloudUpload,
    faFileUpload,
    faUpload,
    faUserTag,
    faExclamationTriangle,
    faTable,
    faFileExport,
    faEye,
    faPrint,
    faUndo,
    faSave,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileArchive,
    faFileImage,
    faFileExcel,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faPaperPlane,
    faSync,
    faEnvelope,
    faBars,
    faQuestion,
    faSignIn,
    faSignOut,
    faBell,
    faLongArrowRight,
    faRandom,
    faFolderOpen,
    faCog,
    faCogs,
    faSpinner,
    faLock,
    faLockOpen,
    faUnlock,
    faPlayCircle,
    faTimesCircle,
    faInfoCircle,
    faPencil,
    faFilter,
    faLockAlt,
    faCheck,
    faCheckCircle,
    faTimes,
    faTrash,
    faPlus,
    faSchool,
    faTools,
    faUser,
    faQuestionCircle,
    faCommentQuestion,
    faCommentXmark,
    faUserPlus,
    faCopy,
    faExchange,
    faSitemap,
    faSquare,
    faSearch,
    farUser,
    farInfoCircle,
    faChevronDown,
    faChevronUp,
    farShieldCheck,
    farFolders,
    faExclamationCircle,
    farExclamationCircle,
    farExclamationTriangle,
    farFile,
    farFilePdf,
    farFileWord,
    farFileExcel,
    farFilePowerpoint,
    farFileImage,
    farFileVideo,
    farFileAlt,
    farCheck,
    farTimes,
    farUserPlus,
    farClock,
    farColumns,
    farLock,
    faArrowAltDown,
    farStar,
    farTrash,
    farCog,
    faTrashAlt,
    farTrashAlt,
    faUpload,
    farUpload,
    farFileAudio,
    farFileArchive,
    faGripVertical,
    faArrowLeft,
    faEyeSlash,
    faEllipsisH,
    faTag,
    faArrowRight,
    farClipboardCheck,
    farChevronDown,
    farChevronUp,
    farThumbsUp,
    faUserSecret,
    farUserSecret,
    faLinkSlash,
    faEdit,
    falFileSignature,
    farFileSignature
  );
};
