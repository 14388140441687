import permissions from '@hogwarts/permissions';
import { Button } from '@hogwarts/ui-components-core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useJobMonitor,
  useLazyQuery,
  useMutation,
  usePermission,
} from '../../../hooks';
import { ROLLBACK_PROFILE_MUTATION } from '../../../mutations';
import { GET_USER_PROFILE } from '../../../queries';
import { AppToaster } from '../../../utils';

interface HistoryRollbackProps {
  profileId: string;
  versionId: string;
  refetch: () => Promise<void>;
}

const HistoryRollback = ({
  profileId,
  versionId,
  refetch: refetchProfileVersions,
}: HistoryRollbackProps) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const { rollbackSuccess, rollbackError } = analytics.events.profile;
  const canRollback = usePermission(
    permissions.PROFILE_ROLLBACK,
    organisation.id
  );
  const [jobId, setJobId] = useState<string>();
  const { status } = useJobMonitor(jobId);
  const [refetchProfileData] = useLazyQuery(GET_USER_PROFILE);

  const refetchProfileVersionData = useCallback(async () => {
    await refetchProfileData({
      fetchPolicy: 'network-only',
      variables: {
        organisationKey: organisation.key,
        profileId: profileId,
      },
    });
    refetchProfileVersions();
  }, [organisation.key, profileId, refetchProfileData, refetchProfileVersions]);

  useEffect(() => {
    if (status && status !== 'none') {
      switch (status) {
        case 'completed': {
          refetchProfileVersionData();
          AppToaster.show({
            message: t('Rollback successful'),
            intent: 'success',
          });
          rollbackSuccess();
          break;
        }
        case 'queued':
        case 'queued-retry':
        case 'inprogress': {
          AppToaster.show({
            message: t('Rolling back profile'),
            intent: 'none',
          });
          break;
        }
        default: {
          AppToaster.show({
            message: t('Error restoring profile'),
            intent: 'danger',
          });
          rollbackError();
          break;
        }
      }
    }
  }, [status, refetchProfileVersionData, t, rollbackSuccess, rollbackError]);

  const [rollbackProfile] = useMutation(ROLLBACK_PROFILE_MUTATION, {
    selector: 'rollbackProfile',
    onCompleted: (id) => {
      if (id) {
        setJobId(id);
      }
    },
    onError: () => {
      AppToaster.show({
        message: t('Error restoring profile'),
        intent: 'danger',
      });
      analytics.events.profile.rollbackError();
    },
  });

  return (
    <Button
      confirm
      confirmText={t('Are you sure? THIS CANNOT BE UNDONE')}
      intent="danger"
      allowed={canRollback}
      onClick={() => {
        analytics.events.profile.rollbackClicked();
        rollbackProfile({
          variables: {
            profileId,
            versionId,
          },
        });
      }}
      disabled={!canRollback}
    >
      {t('Rollback to this point')}
    </Button>
  );
};

export default HistoryRollback;
