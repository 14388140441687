import {
  Button as ButtonBP,
  Card,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Spinner,
} from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SelectedTags } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import SectionSelectionDialog from '../../../components/Dialogs/SectionSelection';
import FilterBar from '../../../components/Filters/Bar';
import { useAlert } from '../../../hooks';
import { getDocumentIcon } from './mimetypes';
import styles from './styles.module.css';

interface Document {
  id: string;
  type: string;
  name: string;
  confidential: boolean;
  sectionName: string;
  tags: {
    id: string;
    label: string;
    color?: string;
  }[];
  uploadDate: string;
  uploadedBy: string;
}
interface Section {
  key: string;
  label: string;
}
interface UserProfileDocumentsProps {
  documents: Document[];
  documentsError: any;
  documentFilters: any;
  sections: Section[];
  allowDocumentDelete: boolean;
  allowDocumentList: boolean;
  allowDocumentOpen: boolean;
  allowSecureDocumentOpen: boolean;
  enableSecureFiles: boolean;
  allowUpload: boolean;
  parentDragActive: boolean;
  onFilter: any;
  onOpen: any;
  onDelete: any;
  onChangeSection: any;
  onDocumentsUpload: any;
  onChangeConfidential: any;
}
const UserProfileDocuments = ({
  documents,
  documentsError,
  documentFilters,
  allowDocumentList,
  allowDocumentOpen,
  allowSecureDocumentOpen,
  allowDocumentDelete,
  enableSecureFiles,
  sections = [],
  allowUpload,
  parentDragActive,
  onFilter,
  onOpen,
  onDelete,
  onChangeSection,
  onDocumentsUpload,
  onChangeConfidential,
}: UserProfileDocumentsProps) => {
  const { t } = useTranslation();

  const [sectionSelectionDocId, setSectionSelectionDocId] = useState<
    string | null
  >(null);
  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert<{
    documentId: string;
  }>({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'This will permanently delete this file, are you sure you wish to continue?'
        )}
      </p>
    ),
    onConfirm: ({ documentId }) => onDelete({ documentId }),
  });

  const availableSections = useMemo(
    () => [
      { key: 'none', name: 'No Section' },
      ...sections.map((section) => ({ key: section.key, name: section.label })),
    ],
    [sections]
  );
  const { getRootProps } = useDropzone({
    noClick: true,
  });
  const { getRootProps: getNormalRootProps, isDragActive: isNormalDragAction } =
    useDropzone({
      noClick: true,
      onDrop: (files) => {
        onDocumentsUpload({ files });
      },
    });
  const { getRootProps: getSecureRootProps, isDragActive: isSecureDragActive } =
    useDropzone({
      noClick: true,
      onDrop: (files) => {
        onDocumentsUpload({ files, confidential: true });
      },
    });

  if (!allowDocumentList) {
    return (
      <Card>
        <NonIdealState
          className="my-5"
          icon={
            <FontAwesomeIcon color="red" icon={['far', 'exclamation-circle']} />
          }
          title={t('Access Denied')}
          description={t<string>(
            'You do not have permission to list Profile Documents. Please contact your system administrator'
          )}
        />
      </Card>
    );
  }

  return (
    <>
      <ConfirmDeleteAlert />
      <SectionSelectionDialog
        sections={availableSections}
        isOpen={!!sectionSelectionDocId}
        onClose={() => setSectionSelectionDocId(null)}
        onSelected={(sectionKey: string) => {
          onChangeSection({ documentId: sectionSelectionDocId, sectionKey });
          setSectionSelectionDocId(null);
        }}
      />
      <div
        {...getRootProps({
          className: cn(`documents-dropzone`),
        })}
      >
        {(!parentDragActive || (parentDragActive && !allowUpload)) && (
          <Card elevation={1} className={styles.documentsCard}>
            {documentFilters && (
              <div className={styles.filtersContainer}>
                <FilterBar
                  filters={documentFilters}
                  onFilter={(filters: any) => onFilter({ filters })}
                />
              </div>
            )}
            {documents?.length > 0 &&
              documents.map((doc) => (
                <div key={doc.id} className={styles.documentItem}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={['far', getDocumentIcon(doc.type)]}
                    className={styles.documentIcon}
                  />
                  <div className={styles.documentDetailsContainer}>
                    <div className={styles.documentDetails}>
                      <p className={styles.documentName}>{doc.name}</p>
                      <p>{doc.sectionName}</p>
                      {doc.tags && <SelectedTags selectedTags={doc.tags} />}
                    </div>
                    <div className={styles.documentUploadDetails}>
                      <p>{doc.uploadDate}</p>
                      <p>{doc.uploadedBy}</p>
                    </div>
                  </div>
                  <div className={styles.documentButtons}>
                    <Button
                      intent="primary"
                      className="mr-2"
                      allowed={
                        doc.confidential
                          ? allowSecureDocumentOpen
                          : allowDocumentOpen
                      }
                      onClick={onOpen.bind(this, { documentId: doc.id })}
                    >
                      {t('Open')}
                    </Button>
                    <Popover
                      position="bottom-right"
                      content={
                        <Menu>
                          <MenuItem
                            icon="exchange"
                            text={t('Change Section')}
                            onClick={() => setSectionSelectionDocId(doc.id)}
                          />
                          {!doc.confidential && (
                            <MenuItem
                              icon="lock"
                              text={t('Make Confidential')}
                              onClick={() =>
                                onChangeConfidential({ documentId: doc.id })
                              }
                            />
                          )}

                          <MenuItem
                            icon={allowDocumentDelete ? 'trash' : 'lock'}
                            text={t('Delete')}
                            disabled={!allowDocumentDelete}
                            onClick={() =>
                              showConfirmDeleteAlert({ documentId: doc.id })
                            }
                          />
                        </Menu>
                      }
                    >
                      <ButtonBP icon="more" />
                    </Popover>
                  </div>
                </div>
              ))}

            {!documents && !documentsError && <Spinner className="my-5" />}

            {documentsError && (
              <NonIdealState
                className="my-5"
                icon={
                  <FontAwesomeIcon
                    color="red"
                    icon={['far', 'exclamation-circle']}
                  />
                }
                title={t('Error Loading Documents')}
                description={t<string>(
                  'Try reloading the page, or contact us at help@scrtracker.com if the problem persists.'
                )}
              />
            )}

            {allowDocumentOpen && documents && documents.length === 0 && (
              <NonIdealState
                className="my-5"
                icon={<FontAwesomeIcon icon={['far', 'file-alt']} />}
                title={t('No Documents Found')}
                description={t<string>(
                  "It looks like you haven't uploaded any documents for this profile yet, or you've filtered them all out."
                )}
              />
            )}
          </Card>
        )}
        {allowDocumentOpen && parentDragActive && allowUpload && (
          <div className={styles.dropzonesContainer}>
            <div
              {...getNormalRootProps({
                className: cn(`documents-dropzone`, 'w-100'),
              })}
            >
              <Card
                elevation={1}
                className={cn(styles.documentsCard, {
                  [styles.dropzoneActive]: isNormalDragAction && allowUpload,
                })}
              >
                <div className={styles.dropzone}>
                  <FontAwesomeIcon icon={['far', 'upload']} size="2x" />
                  <p
                    className={styles.dropzoneText}
                  >{`Drop Here to Upload File`}</p>
                </div>
              </Card>
            </div>
            {enableSecureFiles && (
              <div
                {...getSecureRootProps({
                  className: cn(`documents-dropzone`, 'w-100'),
                })}
              >
                <Card
                  elevation={1}
                  className={cn(styles.documentsCard, {
                    [styles.dropzoneActive]: isSecureDragActive && allowUpload,
                  })}
                >
                  <div className={styles.dropzone}>
                    <FontAwesomeIcon icon={['far', 'user-secret']} size="2x" />
                    <p
                      className={styles.dropzoneText}
                    >{`Drop Here to Upload Confidential File`}</p>
                  </div>
                </Card>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfileDocuments;
