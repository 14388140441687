import React from 'react';
import {
  Classes,
  Dialog,
  Button,
  Intent,
  Callout,
  Tooltip,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { OrganisationSelector } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.css';
import { Loading } from '@hogwarts/ui-components-core';

const TransferProfileDialog = ({
  isOpen,
  onClose,
  targetOrg,
  onTargetOrgSelected,
  onRequestTransfer,
  onTransfer,
  loading,
  error,
  profileName,
  organisations,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      autoFocus
      // https://github.com/palantir/blueprint/issues/3854
      enforceFocus={false}
      usePortal
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      title={t('Transfer Profile')}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        {loading && (
          <div className="pt-5">
            <Loading />
          </div>
        )}
        {error && (
          <Callout
            intent={Intent.WARNING}
            title={t('Unable to load organisations')}
          >
            {t('Please try again later')}
          </Callout>
        )}
        {organisations && (
          <>
            <p>
              <strong>
                {t(
                  'Please select the Organisation you wish to transfer {{ profileName }} to:',
                  { profileName }
                )}
              </strong>
            </p>
            <div className="d-flex justify-content-center my-4">
              <OrganisationSelector
                small
                organisations={organisations}
                onSelected={onTargetOrgSelected}
              >
                <Button
                  className={styles.select}
                  rightIcon="caret-down"
                  text={targetOrg ? targetOrg.name : t('Select Organisation')}
                />
              </OrganisationSelector>
            </div>
            <p>
              {t(
                "If the organisation isn't listed, you need be granted permission by that organisation first."
              )}
            </p>
            <p>
              {t(
                'Profiles can be transferred back by users with appropriate permissions.'
              )}
            </p>
            <p>{t('Audit logs will be created.')}</p>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>{t('Close')}</Button>
          <Tooltip
            disabled={targetOrg}
            content={t('You must first select a target organisation')}
          >
            <div>
              <Button
                intent={Intent.PRIMARY}
                disabled={!targetOrg}
                onClick={onRequestTransfer}
              >
                {t('Request Transfer')}
              </Button>
              <Tooltip
                disabled={
                  !targetOrg || (targetOrg && !targetOrg.requiresRequest)
                }
                content={t(
                  "You don't have permission to transfer immediately, you must request a transfer."
                )}
              >
                <Button
                  intent={Intent.PRIMARY}
                  disabled={!targetOrg || targetOrg.requiresRequest}
                  onClick={onTransfer}
                >
                  {targetOrg && targetOrg.requiresRequest && (
                    <FontAwesomeIcon icon={'lock-alt'} className={'mr-2'} />
                  )}
                  {t('Transfer')}
                </Button>
              </Tooltip>
            </div>
          </Tooltip>
        </div>
      </div>
    </Dialog>
  );
};

export default TransferProfileDialog;
