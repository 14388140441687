export const inputMetaFields = {
  singleselect: [
    {
      key: 'values',
      type: 'valueslist',
      title: 'Selectable Values',
      lockable: true,
    },
    {
      key: 'showEmpty',
      type: 'toggle',
      title: 'Show Empty Option',
      defaultValue: true,
      lockable: true,
    },
  ],
  tabs: [
    {
      key: 'showLabel',
      type: 'toggle',
      title: 'Show Label',
      defaultValue: true,
      lockable: true,
    },
    {
      key: 'tabTitle',
      type: 'textbox',
      title: 'Tab Title',
      lockable: true,
    },
    {
      key: 'reverseOrder',
      type: 'toggle',
      title: 'Reverse Order',
      defaultValue: false,
      lockable: true,
    },
    {
      key: 'emptyTabTitle',
      type: 'textbox',
      title: 'Empty Tab Title',
      lockable: true,
    },
    {
      key: 'emptyTabContent',
      type: 'textarea',
      title: 'Empty Tab Content',
      lockable: true,
    },
    {
      key: 'addButtonHidden',
      type: 'toggle',
      title: 'Hide Add Button',
      defaultValue: false,
      lockable: true,
    },
    {
      key: 'addButtonLabel',
      type: 'textbox',
      title: 'Add Button Label',
      defaultValue: 'Add',
      lockable: true,
    },
    {
      key: 'deleteButtonHidden',
      type: 'toggle',
      title: 'Hide Delete Button',
      defaultValue: false,
      lockable: true,
    },
    {
      key: 'deleteButtonLabel',
      type: 'textbox',
      title: 'Delete Button Label',
      defaultValue: 'Delete',
      lockable: true,
    },
  ],
};

export const fieldComponents = [
  // TODO: Make sure cant drop this on ArrayField!
  {
    inputType: 'tabs',
    dataType: 'array',
    label: 'Repeating Group',
    description: 'A repeating group of fields',
    featureKey: 'functions.arrayfields',
    inputMeta: {
      tabTitle: 'Audit {{index}}',
      reverseOrder: true,
      emptyTabTitle: 'Items',
      emptyTabContent: 'There no currently no Items',
    },
  },
  {
    inputType: 'textbox',
    dataType: 'string',
    label: 'Text Box',
    description: 'A textbox allows a single line of text to be entered.',
  },
  {
    inputType: 'textarea',
    dataType: 'string',
    label: 'Text Area',
    description: 'A text area allows multiple lines of text to be entered.',
  },
  {
    inputType: 'numeric',
    dataType: 'number',
    label: 'Number',
    description: 'A text box that only allows number entries.',
  },
  {
    inputType: 'singleselect',
    dataType: 'string',
    label: 'Single Select',
    description:
      'A single select box shows a list of potential options the user can choose from.',
  },
  {
    inputType: 'datepicker',
    dataType: 'date',
    label: 'Date Picker',
    description:
      'A date picker allows the user to select a single date from a calendar.',
  },
  {
    inputType: 'toggle',
    dataType: 'boolean',
    label: 'Toggle',
    description:
      'A toggle allows the user to select a simple yes or no option.',
  },
  // TODO: Template type idea
  // {
  //   inputType: 'textbox',
  //   dataType: 'string',
  //   label: 'Email Address',
  //   description: 'A text box that is validated to be an Email address',
  //   validation: { type: 'rule', rule: 'valid_email', }
  // },
  {
    inputType: 'title',
    dataType: 'none',
    label: 'Title',
    description: 'A title allows you to put a bold heading onto the form.',
  },
  {
    inputType: 'label',
    dataType: 'none',
    label: 'Label',
    description:
      'A label allows you to put some useful text onto the form to aid the user.',
  },
  {
    inputType: 'separator',
    dataType: 'none',
    label: 'Separator',
    description:
      'A separator allows you to break up a form with a horizontal break.',
  },
  // {
  //   inputType: 'helptext',
  //   dataType: 'none',
  //   label: 'Help Text',
  //   description: 'Some description about what help text is.',
  // },
  // {
  //   id: 'hidden',
  //   dataType: 'string',
  //   label: 'Hidden Field',
  //   description:
  //     "A hidden field allows the section to store data that isn't shown on the editor.",
  //
  // },
].map((component, index) => ({
  id: `${index}`,
  ...component,
}));
