import { Classes, Dialog, NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ImportingModalProps {
  isOpen: boolean;
}
export const ImportingModal = ({ isOpen }: ImportingModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <NonIdealState
          icon="import"
          title={t('Currently Importing')}
          description={t<string>(
            'Your data is currently importing. When it is complete, this dialog will close!'
          )}
        />
      </div>
    </Dialog>
  );
};
