import permissions from '@hogwarts/permissions';
import { schemeHelperFactory } from '@hogwarts/scheme-profiles';
import { Error, Loading } from '@hogwarts/ui-components-core';
import { isCentralTeam } from '@hogwarts/utils';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Analytics } from '../../components';
import {
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import { useQuery, useQueryResults } from '../../hooks';
import { GET_FEDERATIONS, GET_ORGANISATION_DETAIL } from '../../queries';
import { Organisation } from '../../types';
import ContentRouteSelector from './ContentRouteSelector';
import { Header } from './header';

interface FederationQueryResult {
  id: string;
  key: string;
  name: string;
  enabled: boolean;
  domain: string;
  branding: any;
  organisations: Organisation[];
}
interface SelectedOrganisationContentProps {
  selectedOrganisation: Organisation;
  organisations: Organisation[];
  hasFederationAccess: boolean;
}
const SelectedOrganisationContent = ({
  selectedOrganisation,
  organisations,
  hasFederationAccess,
}: SelectedOrganisationContentProps) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const { data: organisation, ...organisationQuery } = useQuery(
    GET_ORGANISATION_DETAIL,
    {
      selector: 'organisations[0]',
      skip: !selectedOrganisation,
      variables: {
        organisationKey: selectedOrganisation?.key,
      },
    }
  );

  const { data: federation, ...federationQuery } =
    useQuery<FederationQueryResult>(GET_FEDERATIONS, {
      selector: 'federations[0]',
      skip:
        !hasFederationAccess ||
        !selectedOrganisation ||
        !selectedOrganisation.federation,
      variables: {
        federationKey: selectedOrganisation?.federation?.key,
      },
    });

  const { loading, error } = useQueryResults({
    organisationQuery,
    federationQuery,
  });

  const organisationContext = useMemo(() => {
    if (loading) return null;
    if (organisation) {
      const scheme = schemeHelperFactory(organisation.scheme, {
        calculateUsage: true,
      });
      return {
        ...organisation,
        demo: !!organisation.attributes?.demo_organisation,
        scheme,
      };
    }
    return null;
  }, [loading, organisation]);

  const federationContext = useMemo(() => {
    if (loading) return null;
    if (organisation && federation) {
      const sharedOrganisation = federation.organisations.find(
        (o) => o.enabled && isCentralTeam(o)
      );

      if (!organisation.federation) {
        return null;
      }

      if (organisation.federation.key !== federation.key) {
        return null;
      }

      const canReadShared = !!(
        sharedOrganisation &&
        user.hasPermission(permissions.READ, sharedOrganisation.id)
      );
      const canReadSharedProfiles = !!(
        sharedOrganisation &&
        canReadShared &&
        user.hasPermission(permissions.PROFILE_READ, sharedOrganisation.id)
      );

      return {
        ...federation,
        hasSharedTeam: !!sharedOrganisation,
        canReadShared,
        canReadSharedProfiles,
      };
    }
    return null;
  }, [federation, loading, organisation, user]);

  if (loading) {
    return <Loading showLogo />;
  }

  if (error) {
    return (
      <>
        <Header showSearch={false} />
        <Error />
      </>
    );
  }

  if (organisationContext && !organisationContext.scheme) {
    return (
      <>
        <Header showSearch={false} />
        <Error title={t('Scheme not configured')} />
      </>
    );
  }

  return (
    <FederationContext.Provider value={federationContext!}>
      <OrganisationContext.Provider value={organisationContext}>
        <Analytics>
          <ContentRouteSelector organisations={organisations} />
        </Analytics>
      </OrganisationContext.Provider>
    </FederationContext.Provider>
  );
};

export default SelectedOrganisationContent;
