import { ApolloError } from '@apollo/client';
import permissions from '@hogwarts/permissions';
import { Scheme } from '@hogwarts/utils-schemes';
import { get } from 'lodash';
import { set } from 'lodash/fp';
import { usePermission, useQuery } from '../../../hooks';
import { GET_USER_PROFILE_VERSIONS } from '../../../queries';
import {
  ServerVersion,
  transformProfileVersionData,
} from './transformProfileVersionData';

const versionSelector = 'organisations[0].profiles[0].versions';

interface Profile {
  id: string;
}
interface Organisation {
  id: string;
  key: string;
  timezone?: string;
}

interface Version {}

export const useProfileHistory = (
  profile: Profile,
  scheme: Scheme,
  organisation: Organisation
) => {
  const permission = usePermission(
    permissions.PROFILE_VERSION_READ,
    organisation?.id
  );

  const versionVariables = {
    organisationKey: organisation?.key,
    profileId: profile?.id,
    limit: 15,
    changes: ['profileTypeKey', 'organisation', 'data', 'add'],
  };

  const {
    data: versions,
    loading: loadingInitial,
    error: errorLoadingInitial,
    refetch,
    fetchMore: fetchMoreData,
  }: {
    data?: Version[];
    loading?: boolean;
    error?: ApolloError;
    refetch?: () => void;
    fetchMore?: any;
  } = useQuery(GET_USER_PROFILE_VERSIONS, {
    variables: {
      ...versionVariables,
      cursor: null, // previous cursor
    },
    selector: versionSelector,
    transform: transformProfileVersionData(scheme),
  });

  const fetchMore = async (cursor: any) => {
    if (loadingInitial || errorLoadingInitial || !fetchMoreData) {
      return;
    }
    const updatedVersions = await fetchMoreData({
      variables: {
        ...versionVariables,
        cursor,
      },

      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const previousVersions: ServerVersion[] = get(
          previousResult,
          versionSelector,
          []
        );
        const newVersions: ServerVersion[] = get(
          fetchMoreResult,
          versionSelector,
          []
        );

        const combinedVersions = [...previousVersions, ...newVersions];
        const updatedCache = set(
          versionSelector,
          combinedVersions,
          previousResult
        );
        return updatedCache;
      },
    });
    return transformProfileVersionData(scheme)(
      get(updatedVersions?.data, versionSelector, [])
    );
  };

  return {
    refetch,
    loading: loadingInitial,
    permission,
    timezone: organisation.timezone,
    fetchMore,
    changes: versions,
  };
};
