import { Callout } from '@blueprintjs/core';
import React from 'react';

interface OrderErrorProps {
  error?: string;
}

export const OrderError = ({ error }: OrderErrorProps) => {
  return <Callout intent="danger">{`${error}`}</Callout>;
};
