import { InputGroup } from '@blueprintjs/core';
import { ControlBuilder } from '@hogwarts/ui-components-forms';
import React, { useMemo, useState } from 'react';
import { FlatCondition, getFieldList } from './utils';

interface ConditionProps {
  structure: any;
  readOnly?: boolean;
  condition: FlatCondition;
  onChange: (condition: FlatCondition) => void;
}
export const ConditionItem = ({
  structure,
  condition: initialValues,
  onChange,
  readOnly,
}: ConditionProps) => {
  const [values, setValues] = useState(initialValues);

  const fields = useMemo(() => {
    return getFieldList(structure, values);
  }, [structure, values]);

  if (values?.compute && values.compute[0]) {
    return (
      <InputGroup
        readOnly={true}
        fill
        value={`Compute ${values.compute[0]} not editable`}
      />
    );
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <ControlBuilder
        fields={fields}
        initialValues={initialValues}
        readOnly={readOnly}
        onValuesChanged={(values: any) => {
          setValues(values);
          if (onChange) {
            onChange(values);
          }
        }}
      />
    </div>
  );
};
