import { gql } from '@apollo/client';

export const GET_SCHEME_TREE_AND_DRAFT = gql`
  query getSchemeDraft($id: ID!) {
    schemeTree(id: $id) {
      id
      cacheKey
      name
      mixin
      parent
      version
      data
      options
    }
    schemeDraft(id: $id) {
      id
      cacheKey
      name
      mixin
      parent
      version
      data
    }
  }
`;

export const GET_ORGANISATION_SCHEMES = gql`
  query getOrganisationSchemes($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      schemes {
        id
        version
        cacheKey
        name
        mixin
        parent
        data
      }
    }
  }
`;

export const GET_FEDERATION_SCHEMES = gql`
  query getOrganisationSchemes($federationKey: String!) {
    federations(key: $federationKey) {
      id
      schemes {
        id
        version
        cacheKey
        name
        mixin
        parent
        data
      }
    }
  }
`;
