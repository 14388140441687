import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '@/context';
import { useQuery } from '@/hooks';
import { DATASOURCE_QUERY_PROFILESCORESBYRATING } from '@/queries';
import { NonIdealState } from '@blueprintjs/core';
import { useForms } from '@hogwarts/ui-components-forms';
import qs from 'qs';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PieChartWidget from '../../../components/Dashboards/Widgets/PieChart';

const PieChartContainer = ({
  widgetId,
  settings,
  onSettingsUpdate,
  ...rest
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const { loading, data, error, refetch } = useQuery(
    DATASOURCE_QUERY_PROFILESCORESBYRATING,
    {
      selector: 'queryProfileScoresByRating.result',
      variables: {
        organisationKey: organisation.key,
        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),
      },
      pollInterval: organisation.demo ? 20000 : 60000,
    }
  );
  const [showForm] = useForms({
    title: 'Pie Chart Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: async (values) => {
      await onSettingsUpdate(values);
      refetch();
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedRatingSystems',
        type: 'checklist',
        label: 'Include Rating Systems',
        selectionMinimum: 1,
        selectionLimit: 2,
        values: organisation.scheme.ratingSystems.map((rs) => ({
          id: rs.key,
          value: rs.label,
        })),
      },
      // {
      //   type: 'separator',
      // },
      // {
      //   key: 'includedProfileTypes',
      //   type: 'checklist',
      //   label: 'Include Profile Types',
      //   showSelectAll: true,
      //   selectionMinimum: 1,
      //   values: organisation.scheme.profileTypes.map((pt) => ({
      //     id: pt.key,
      //     value: pt.label,
      //   })),
      // },
    ],
    initialValues: settings,
  });
  const chartData = useMemo(() => {
    if (data) {
      return Object.keys(data).reduce((prev, curr) => {
        if (
          !settings.includedRatingSystems ||
          settings.includedRatingSystems[curr]
        ) {
          prev[curr] = data[curr];
        }
        return prev;
      }, {});
    }
  }, [data, settings.includedRatingSystems]);

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      {Object.keys(chartData || {}).length ? (
        <PieChartWidget
          scores={chartData}
          ratingSystems={organisation.scheme.ratingSystems}
          onRatingClick={({ rating }) => {
            analytics.events.dashboard.widget.pieChart.ratingSelected({
              rating,
            });
            const queryFields = {
              tags: Object.keys(settings.includedTags).filter(
                (t) => !!settings.includedTags[t]
              ),
              [`ratingSystem_${rating.key}`]: rating.state,
            };
            const query = `/${organisation.key}/profiles${qs.stringify(
              queryFields,
              {
                addQueryPrefix: true,
                arrayFormat: 'repeat',
              }
            )}`;
            history.push(query);
          }}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={t('Nothing to Display')}
          description={t('You may need to adjust your Widget settings')}
        />
      )}
    </WidgetWrapper>
  );
};

export default {
  type: 'pie-chart',
  name: 'Profile Scores Pie Chart',
  description:
    'Display a pie chart that gives you an overall score for your organisation.',
  widgetComponent: PieChartContainer,
  defaultSettings: (scheme) => ({
    title: 'Profile Scores',
    includedTags: {
      active: true,
      onboarding: true,
    },
    includedRatingSystems: scheme.ratingSystems
      .slice(0, 2)
      .reduce((prev, curr) => {
        prev[curr.key] = true;
        return prev;
      }, {}),
    includedProfileTypes: null,
  }),
};
