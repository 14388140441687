const pdfTypes = ['application/pdf'];

const spreadsheetTypes = [
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const slideshowTypes = [
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const documentTypes = [
  'application/x-abiword',
  'application/vnd.amazon.ebook',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/epub+zip',
  'application/vnd.oasis.opendocument.text',
];

const textTypes = ['text/csv', 'application/rtf', 'text/plain'];

const audioTypes = [
  'audio/aac',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
];

const videoTypes = [
  'video/x-msvideo',
  'video/mpeg',
  'video/ogg',
  'video/mp2t',
  'video/webm',
  'video/mp4',
];

const archiveTypes = [
  'application/x-freearc',
  'application/octet-stream',
  'application/x-bzip',
  'application/x-bzip2',
  'application/gzip',
  'application/vnd.rar',
  'application/x-tar',
  'application/zip',
  'application/x-7z-compressed',
];

const imageTypes = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

export const getDocumentIcon = (mimeType = '') => {
  const normalisedType = mimeType.toLowerCase();
  if (pdfTypes.includes(normalisedType)) {
    return 'file-pdf';
  } else if (spreadsheetTypes.includes(normalisedType)) {
    return 'file-excel';
  } else if (slideshowTypes.includes(normalisedType)) {
    return 'file-powerpoint';
  } else if (documentTypes.includes(normalisedType)) {
    return 'file-word';
  } else if (textTypes.includes(normalisedType)) {
    return 'file-alt';
  } else if (audioTypes.includes(normalisedType)) {
    return 'file-audio';
  } else if (videoTypes.includes(normalisedType)) {
    return 'file-video';
  } else if (archiveTypes.includes(normalisedType)) {
    return 'file-archive';
  } else if (imageTypes.includes(normalisedType)) {
    return 'file-image';
  } else {
    // Unknown
    return 'file';
  }
};

export const getTypeGroups = () => {
  return [
    { id: 'file-word', value: 'Document' },
    { id: 'file-excel', value: 'Spreadsheet' },
    { id: 'file-powerpoint', value: 'Slideshow' },
    { id: 'file-image', value: 'Image' },
    { id: 'file-audio', value: 'Audio' },
    { id: 'file-video', value: 'Video' },
    { id: 'file-archive', value: 'Archive' },
    // { id: 'file-pdf', value: 'PDF ' },
    // { id: 'file-text', value: 'Text File' },
  ];
};

export const getDocumentTypeGroupItems = (groupType = '') => {
  const normalisedGroupType = groupType.toLowerCase();
  if (normalisedGroupType === 'file-word')
    return [...documentTypes, ...pdfTypes, ...textTypes];
  if (normalisedGroupType === 'file-excel') return spreadsheetTypes;
  if (normalisedGroupType === 'file-powerpoint') return slideshowTypes;
  if (normalisedGroupType === 'file-image') return imageTypes;
  if (normalisedGroupType === 'file-audio') return audioTypes;
  if (normalisedGroupType === 'file-video') return videoTypes;
  if (normalisedGroupType === 'file-archive') return archiveTypes;
  // if (normalisedGroupType === 'file-pdf') return pdfTypes;
  // if (normalisedGroupType === 'file-text') return textTypes;
  return null;
};
