import AddMenuComponent from '@/components/AddMenu';
import { AnalyticsContext, OrganisationContext } from '@/context';
import { useFeature, usePermission } from '@/hooks';
import permissions from '@hogwarts/permissions';
import { useFormState } from '@hogwarts/ui-components-forms';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UploadOrganisationDocument } from '../../../../../../components/DocumentUpload';
import AddDashboardForm from './AddDashboardForm';
import AddIncidentForm from './AddIncidentForm';
import AddProfileForm from './AddProfileForm';
import AddReportForm from './AddReportForm';
import AddSignOffForm from './AddSignOffForm';

const SignOffFormWithUpload = UploadOrganisationDocument(AddSignOffForm);
const AddIncidentFormWithUpload = UploadOrganisationDocument(AddIncidentForm);

const AddMenu = ({ children }) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const newProfilePermission = usePermission(
    permissions.PROFILE_CREATE,
    organisation?.id
  );
  const signOffPermission = usePermission(
    permissions.SIGN_OFF,
    organisation?.id
  );

  const incidentTrackingPermission = usePermission(
    permissions.PROFILE_ACTIVITY_READ,
    organisation?.id
  );
  const forms = useFormState();
  const addEnabled = useFeature('reports.add');
  const signoffEnabled = useFeature('signoff');
  const incidentTrackingEnabled = useFeature('timeline.incident_tracking');
  const restrictProfileCreation = useFeature('sia.restrictProfileCreation');

  if (!organisation) return null;
  return (
    <>
      <AddProfileForm
        onCreate={(profile) => {
          history.push(`/${organisation.key}/profiles/${profile.id}`);
        }}
      />
      <AddDashboardForm
        onCreate={(dashboard) => {
          history.push(`/${organisation.key}?dash=${dashboard.id}`);
        }}
      />
      <AddReportForm
        onCreate={(report) => {
          history.push(`/${organisation.key}/insights?id=${report.id}&new=1`);
        }}
      />
      <SignOffFormWithUpload />
      <AddIncidentFormWithUpload
        onCreate={(profileId) => {
          history.push(`/${organisation.key}/profiles/${profileId}/timeline`);
        }}
      />
      <AddMenuComponent
        options={[
          {
            icon: ['far', 'user-plus'],
            heading: 'Profile',
            description: 'Add a new profile to your current organisation.',
            restricted:
              !newProfilePermission || restrictProfileCreation
                ? 'You must create profiles in Sign In App when the integration is enabled.'
                : false,
            onClick: () => {
              analytics.events.header.addProfileClicked();
              forms.showForm('addProfile');
            },
          },
          {
            icon: ['far', 'columns'],
            heading: 'Dashboard',
            description: 'Add a new dashboard to get insights on your data.',
            restricted: false,
            onClick: () => {
              analytics.events.dashboard.addDashboardClicked();
              forms.showForm('addDashboard');
            },
          },
          addEnabled && {
            icon: ['fal', 'table'],
            heading: 'Report',
            description: 'Add a new report to filter and export your data.',
            restricted: false,
            onClick: () => {
              analytics.events.insights.addClicked();
              forms.showForm('addReport');
            },
          },
          signoffEnabled && {
            icon: ['far', 'thumbs-up'],
            heading: 'Sign Off',
            description:
              'Add your sign off to the current state of your organisation.',
            restricted: !signOffPermission,
            onClick: () => {
              analytics.events.signoff.signOffClicked();
              forms.showForm('addSignOff');
            },
          },
          incidentTrackingEnabled &&
            incidentTrackingPermission && {
              icon: ['far', 'exclamation-triangle'],
              heading: 'Incident',
              description:
                'Add a new incident related to a profile in your organisation.',
              restricted: false,
              onClick: () => {
                analytics.events.activities.addIncidentClicked();
                forms.showForm('addIncident', {
                  initialValues: {
                    documents: [],
                  },
                });
              },
            },
        ]}
      >
        {({ openMenu }) =>
          React.cloneElement(children, {
            onClick: () => {
              analytics.events.header.addMenuClicked();
              openMenu();
            },
          })
        }
      </AddMenuComponent>
    </>
  );
};

export default AddMenu;
