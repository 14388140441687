import permissions from '@hogwarts/permissions';
import { useForms } from '@hogwarts/ui-components-forms';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMutation, usePermission, useQuery } from '../../../hooks';
import { DELETE_DOCUMENT } from '../../../mutations';
import {
  GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
  GET_ORGANISATION_USERS,
} from '../../../queries';
import { Document, Organisation } from '../../../types';
import { Activity } from './types';

const getOptions = (data: { id: string; name: string }[]) => {
  return data?.map(({ id, name }) => ({
    id,
    value: name,
  }));
};
interface UpdateIncidentFormProps {
  onCreate: (activity: Activity) => {};
  onOpenDocumentPicker: () => boolean;
  isUploadSuccessful: boolean;
  skipDelete: (flag?: boolean) => void;
  documents: Document[];
  resetDocuments: () => void;
  editActivity: Function;
  onRemoveDocument: (document: Document) => void;
}

const UpdateIncidentForm = ({
  onCreate,
  onOpenDocumentPicker,
  documents,
  resetDocuments,
  editActivity,
  onRemoveDocument,
}: UpdateIncidentFormProps) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext<Organisation>(OrganisationContext);
  const [preloadedDocuments, setPreloadedDocuments] = useState(documents);
  const [hasReadValueBeenCalled, setHasReadValueBeenCalled] = useState(false);
  let isFormSaved = false;

  const { data: users } = useQuery(GET_ORGANISATION_USERS, {
    selector: 'organisations[0].users',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const { data: activityMeta } = useQuery(
    GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
    {
      selector: 'organisations[0]',
      variables: {
        organisationKey: organisation.key,
        skip: 0,
        limit: 0,
      },
    }
  );

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    selector: 'deleteDocument',
    onCompleted: () => {
      analytics?.events.activities.documentRemoved();
    },
  });

  const allowDeleteFile = usePermission(
    permissions.ORGANISATION_FILE_DELETE,
    organisation.id
  );

  const removeDocuments = async () => {
    resetDocuments();
    if (!isFormSaved) {
      for (const document of documents) {
        if (allowDeleteFile) {
          await deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
        }
      }
    }
  };

  useForms({
    key: 'updateIncident',
    title: 'Update Incident',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Incident Updated',
    allowCancel: true,
    canSave: true,
    fields: [
      {
        key: 'notes',
        type: 'textarea',
        label: t('Notes'),
        validate: 'required',
      },
      {
        key: 'profile',
        type: 'hidden',
      },
      {
        key: 'assignedTo',
        type: 'bpmultiselect',
        label: t('Assigned To'),
        values: getOptions(users),
      },
      {
        key: 'category',
        type: 'singleselect',
        label: t('Category'),
        values: getOptions(activityMeta?.categories),
      },
      {
        key: 'tags',
        type: 'bpmultiselect',
        label: t('Tags'),
        values: getOptions(activityMeta?.tags),
      },
      {
        key: 'date',
        type: 'datepicker',
        label: t('Date'),
        validate: 'required',
      },
      {
        key: 'status',
        type: 'singleselect',
        label: t('Status'),
        values: getOptions(activityMeta?.statuses),
      },
      {
        key: 'severity',
        type: 'singleselect',
        label: t('Severity'),
        values: getOptions(activityMeta?.severities),
      },
      {
        type: 'separator',
      },
      {
        key: 'selectLabel',
        type: 'label',
        label: t('Select documents to attach to this incident.'),
      },
      {
        key: 'selectButton',
        type: 'button',
        label: t('Select'),
        onClickHandler() {
          analytics.events.profile.uploadActionClicked();
          onOpenDocumentPicker();
        },
      },
      {
        key: 'documentsUploaded',
        type: 'documentlist',
        visible: true,
        className: 'withLineBreaks',
        label: t('Documents'),
        allowDelete: allowDeleteFile,
        documents: [
          ...preloadedDocuments,
          ...Array.from(documents.values()),
        ].filter(
          (document, index, array) =>
            array.findIndex((d) => d.id === document.id) === index
        ),
        onRemoveDocument: (document: Document) => {
          analytics.events.activities.removeDocumentClicked({
            documentId: document.id,
          });
          onRemoveDocument(document);
          setPreloadedDocuments(
            preloadedDocuments.filter(
              (preloadedDocument) => preloadedDocument.id !== document.id
            )
          );
          deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
        },
        readValue: (value) => {
          if (!hasReadValueBeenCalled) {
            setHasReadValueBeenCalled(true);
            setPreloadedDocuments(value);
            return value;
          }
        },
      },
      {
        key: 'documents',
        type: 'hidden',
      },
      {
        key: 'id',
        type: 'hidden',
      },
    ],
    onSave: async (values) => {
      analytics?.events.profile.activityUpdateClicked({ id: values.id });

      const variables = {
        activity: {
          id: values.id,
          tags: values.tags,
          date: values.date,
          // upload both preloaded and newly uploaded documents
          documents: [
            ...preloadedDocuments.map((document) => document.id),
            ...documents.map((document) => document.id),
          ],
          data: {
            notes: values.notes,
            status: values.status,
            severity: values.severity,
            category: values.category,
            assignedTo: values.assignedTo,
          },
        },
      };
      const incident = await editActivity({
        variables,
      });

      isFormSaved = !!incident.data;

      if (onCreate) {
        onCreate(incident.data);
      }
      analytics?.events.profile.activityUpdated({ id: values.id });
    },
    onClose: removeDocuments,
    onReset: removeDocuments,
  });

  return null;
};

export default UpdateIncidentForm;
