import {
  CalculatedProfileRatings,
  IndividualProfileScheme,
} from '@hogwarts/utils-schemes';

export const getScores = (
  ratings: CalculatedProfileRatings,
  scheme: IndividualProfileScheme
) => {
  return scheme.ratingSystems.map((system) => {
    const rating = ratings && ratings[system.key];
    return {
      name: system.label,
      required: rating?.required,
      score: rating?.score || 0,
      readyColor: system.readyColor,
      notReadyColor: system.notReadyColor,
    };
  });
};
