import React, { useContext } from 'react';
import { ValidBrowserBanner } from '../../banners/BrowserWarning';
import { OverdueInvoiceBanner } from '../../banners/OverdueInvoice';
import { ComponentErrorAlert, PageHeaderContainer } from '../../components';
import { OrganisationContext } from '../../context';
import Header from './header';

interface ContentProps {
  hideHeader: boolean;
  children: JSX.Element;
  organisations?: any[];
  enforceMode?: boolean;
}
const Content = ({
  hideHeader,
  children,
  organisations,
  enforceMode,
}: ContentProps) => {
  const organisation = useContext(OrganisationContext);
  return (
    <>
      {!hideHeader && (
        <Header
          organisations={organisations}
          showSearch
          enforceMode={enforceMode}
        />
      )}
      <PageHeaderContainer
        hideHeader={hideHeader}
        hideHeaderChildren={true}
        notificationChildren={
          <>
            <ValidBrowserBanner />
            <OverdueInvoiceBanner organisation={organisation} />
          </>
        }
        enforceMode={enforceMode}
      >
        <ComponentErrorAlert>{children}</ComponentErrorAlert>
      </PageHeaderContainer>
    </>
  );
};

export default Content;
