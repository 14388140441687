import { GridView, PageHeader } from '@/components';
import { ConditionalScheme } from '@/components/Conditionals';
import { AnalyticsContext, OrganisationContext } from '@/context';
import { getAvailableColumns, getColumnLabel } from '@hogwarts/scheme-profiles';
import { veryCleanObject } from '@hogwarts/utils';
import { sortBy } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrayParam,
  JsonParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import {
  addProfileTypeField,
  addRatingField,
  addSchemeField,
  addTagField,
  addTemplateField,
  gridOptions as defaultGridOptions,
  useDataSourceFactory,
} from '../../profilesGridUtils';
import ConditionalPanel, {
  ConditionalPanelOnChangeContext,
} from './conditionalPanel';

const gridOptions = {
  ...defaultGridOptions,
  noRowsOverlayComponent: 'profileListNoRowsOverlay',
  rowSelection: 'single',
  sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 100,
    filter: false,
    floatingFilter: false,
    editable: false,
    suppressMenu: false,
  },

  sideBar: {
    // ...defaultGridOptions?.sideBar,
    // toolPanels: [
    //   {
    //     id: 'conditional',
    //     labelDefault: 'Conditions',
    //     iconKey: 'filter',
    //     toolPanel: 'conditionalPanel',
    //   },
    // ],
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
  processCellForClipboard: ({ column, value }) => {
    const { colDef } = column;
    if (colDef.field === 'typeKey') {
      return value?.key;
    }
    return value;
  },
};

const frameworkComponents = {
  conditionalPanel: ConditionalPanel,
};

const convertFormatToGridCellStyle = (format) => {
  if (!format) return null;
  const { fill } = format;
  return veryCleanObject({
    backgroundColor: fill,
  });
};

const ProfilesGrid = ({
  title,
  columns: visibleColumns,
  tags,
  profileTypes,
  ratings,
  condition,
  onConditionChange,
}) => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);

  const history = useHistory();

  const filter = useMemo(
    () => ({
      tags: tags,
      profileTypes,
      ratings,
      condition,
    }),
    [tags, profileTypes, ratings, condition]
  );

  const [groups, columns] = useMemo(
    () => {
      // Get a list of all the available columns using sources
      // and apply the report state
      const [groups] = getAvailableColumns(
        {
          columnSources: [
            {
              type: 'scheme',
              includeRatings: true,
              includeTags: true,
            },
          ],

          // TODO: Pass this list in?
          columns: visibleColumns?.map((key, order) => ({
            key,
            visible: true,
            order,
          })),
        },
        organisation.scheme
      );

      // turn the columns into grid columns.
      let gridGroups = [];
      let gridColumns = [];

      for (const group of groups) {
        let gridGroup = {
          ...group,
          columns: [],
        };
        gridGroups.push(gridGroup);
        for (const column of group.columns) {
          let gridColumn;

          switch (column.type) {
            case 'tag': {
              // @ts-ignore
              gridColumn = addTagField();
              break;
            }
            case 'profileType': {
              // @ts-ignore
              gridColumn = addProfileTypeField(organisation.scheme);
              break;
            }
            case 'rating': {
              // @ts-ignore
              gridColumn = addRatingField(column.systemKey);
              break;
            }
            case 'template': {
              // @ts-ignore
              gridColumn = addTemplateField(column.references, column.template);
              break;
            }
            case 'field':
            default: {
              // @ts-ignore
              gridColumn = addSchemeField(
                column.key,
                organisation.scheme,
                column.dataType
              );
              break;
            }
          }

          gridColumn = {
            ...gridColumn,
            key: column.key,
            type: column.dataType,
            group: gridGroup.key,
            label: column.meta
              ? getColumnLabel(column.label, column.meta)
              : column.label,
            order: column.order,
            width: column.width,
            sort: column.sort,
            sortIndex: column.sortIndex,
            meta: {
              ...column.meta,
              locked: column.locked,
            },
            editable: false,
            visible: column.visible !== false,
            pinned: column.pinned,
          };

          if (column.formatSelectors) {
            gridColumn.cellStyle = ({ data: profile, value }) => {
              const format = column.getCellStyle(profile, value);
              return convertFormatToGridCellStyle(format);
            };
          }

          gridGroup.columns.push(gridColumn);
          gridColumns.push(gridColumn);
        }
      }

      return [gridGroups, sortBy(gridColumns, 'order')];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisation.scheme]
  );

  const dataSource = useDataSourceFactory({
    variables: {
      includeShared: false,
    },
  });

  const contextMenuItems = useCallback(
    (nodes) => {
      return [
        nodes.length === 1 && {
          name: nodes[0].data.name,
          disabled: true,
        },
        nodes.length > 1 && {
          name: { message: 'Selected {{count}} rows', count: nodes.length },
          disabled: true,
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'separator',
        nodes.length === 1 && {
          name: 'View Profile',
          action: async () => {
            let profileId = nodes[0].data.id;
            if (profileId) {
              analytics.events.profilesGrid.viewProfileClicked();
              history.push(`/${organisation.key}/profiles/${profileId}`);
            }
          },
        },
      ];
    },
    [analytics.events.profilesGrid, history, organisation.key]
  );

  const conditionalPanelProps = {
    condition,
    onChange: (condition) => {
      if (onConditionChange) {
        onConditionChange(condition);
      }
    },
  };

  return (
    <>
      <PageHeader header={title} noWrapper actions={[]} />
      <>
        {/* <div className={styles.filterBar}>
          <ProfilesFilterBar initialFilters={{}} onFilter={onFilterHandler} />
        </div> */}

        <ConditionalPanelOnChangeContext.Provider value={conditionalPanelProps}>
          <ConditionalScheme.Provider value={organisation.scheme}>
            <GridView
              allowEdit={false}
              dataSource={dataSource}
              contextMenuItems={contextMenuItems}
              groups={groups}
              columns={columns}
              filter={filter}
              {...gridOptions}
              showSideBar
              collapseSideBar
              frameworkComponents={frameworkComponents}
            />
          </ConditionalScheme.Provider>
        </ConditionalPanelOnChangeContext.Provider>
      </>
    </>
  );
};

const ProfilesGridWithQueryParams = () => {
  const organisation = useContext(OrganisationContext);

  const [query, setQuery] = useQueryParams({
    condition: withDefault(JsonParam, {}),
    columns: withDefault(ArrayParam, [
      'tags',
      'rating_std',
      'rating_dfe',
      'firstname',
      'lastname',
      'typeKey',
      'employment_position',
      'startdate',
      'leavedate',
    ]),
    tags: withDefault(ArrayParam, ['active']),
    profileTypes: withDefault(ArrayParam, []),
    title: withDefault(StringParam, organisation.name),
  });

  const onConditionChange = (condition) => {
    setQuery({
      condition,
    });
  };

  return (
    <ProfilesGrid
      onConditionChange={onConditionChange}
      condition={query.condition}
      columns={query.columns}
      tags={query.tags}
      profileTypes={query.profileTypes}
      title={query.title}
    />
  );
};

export default ProfilesGridWithQueryParams;
