import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequest } from '../services';
import { AppToaster } from '../utils/toaster';

export const useDownloadPolicy = () => {
  const { t } = useTranslation();
  const getDownloadPolicy = useCallback(
    async (documentId) => {
      const request = await getRequest();
      const policyResponse = await request.get(
        `/documents/policy/${documentId}/download`
      );
      if (policyResponse.status === 200) {
        return policyResponse.data;
      } else {
        AppToaster.show({
          icon: 'error',
          intent: 'danger',
          message: t('Error starting download'),
        });
        return null;
      }
    },
    [t]
  );

  return (documentId: string) => getDownloadPolicy(documentId);
};
