import { H1 } from '@/ui-dom-components';
import { ActionBar, ActionWithChildren } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.css';

interface PageHeaderContextProps {
  noWrapper: boolean;
  setNoWrapper: (noWrapper: boolean) => void;

  prefix?: React.ReactNode;
  setPrefix: (prefix: React.ReactNode) => void;

  header?: string | React.ReactNode;
  setHeader: (header: string | React.ReactNode) => void;

  actions?: ActionWithChildren[];
  setActions: (actions: ActionWithChildren[]) => void;

  children?: React.ReactNode;
  setChildren: (children: React.ReactNode) => void;

  notificationChildren?: React.ReactNode;
  setNotificationChildren: (children: React.ReactNode) => void;
}
export const PageHeaderContext =
  React.createContext<PageHeaderContextProps | null>(null);

interface PageHeaderContainerProps {
  hideHeader?: boolean;
  hideHeaderChildren?: boolean;
  notificationChildren?: React.ReactNode;
  children: React.ReactNode;
  enforceMode?: boolean;
}
const PageHeaderContainer = ({
  hideHeader,
  notificationChildren,
  children,
}: PageHeaderContainerProps) => {
  const [header, setHeader] = useState<string | React.ReactNode>(null);
  const [prefix, setPrefix] = useState<React.ReactNode>(null);
  const [actions, setActions] = useState<ActionWithChildren[] | undefined>();
  const [noWrapper, setNoWrapper] = useState(false);
  const [headerChildren, setHeaderChildren] = useState<React.ReactNode>(null);
  const [headerNotificationChildren, setHeaderNotificationChildren] =
    useState<React.ReactNode>(null);

  const ctx = useMemo(
    () => ({
      header,
      setHeader,
      prefix,
      setPrefix,
      actions,
      setActions,
      noWrapper,
      setNoWrapper,
      children: headerChildren,
      setChildren: setHeaderChildren,
      notificationChildren: headerNotificationChildren,
      setNotificationChildren: setHeaderNotificationChildren,
    }),
    [
      actions,
      header,
      headerChildren,
      headerNotificationChildren,
      noWrapper,
      prefix,
    ]
  );

  return (
    <PageHeaderContext.Provider value={ctx}>
      {!hideHeader && (
        <section className="bridgeUpper p-0">
          <div className="mt-2 mx-2 mx-lg-5">
            {notificationChildren}
            {headerNotificationChildren}
          </div>
          <div
            style={{ minHeight: header ? '64px' : '0px' }}
            className="d-flex flex-row justify-content-between px-2 px-lg-5"
          >
            <div className="d-flex flex-column justify-content-center">
              {typeof header === 'string' ? (
                <H1 className="m-0">
                  {prefix}
                  {header}
                </H1>
              ) : (
                <div className="d-flex align-items-center">
                  {prefix}
                  {header}
                </div>
              )}
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex flex-wrap justify-content-end">
                {actions && (
                  <div
                    style={{ minHeight: '50px' }}
                    className={styles.actionBar}
                  >
                    <ActionBar actions={actions} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {headerChildren && (
            <div className="px-2 px-lg-5">{headerChildren}</div>
          )}
        </section>
      )}
      <section
        className={cn('bridgeLower flex-grow-1', {
          'p-2 px-lg-5': !noWrapper,
          'p-0': noWrapper,
        })}
      >
        {children}
      </section>
    </PageHeaderContext.Provider>
  );
};

export default PageHeaderContainer;
