import React from 'react';
import cn from 'classnames';
import { Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortableHandle } from 'react-sortable-hoc';
import SectionTypeIcon from '../../SectionTypeIcon';

import styles from './styles.module.css';

const DraggableHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

const SectionButton = ({
  name,
  icon,
  selected,
  dragActive,
  deleted,
  color,
  sectionDisabled,
  onClick,
  onDoubleClick,
}) => {
  return (
    <div
      className={cn(styles.sectionContainer, {
        [styles.sectionDeleted]: deleted,
      })}
    >
      <Card
        elevation={1}
        className={cn(styles.sectionCard, {
          [styles.buttonHover]: !dragActive,
          [styles.buttonSelected]: selected && !dragActive,
          [styles.sectionDisabled]: sectionDisabled,
        })}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <div
          className={styles.sectionColour}
          style={{ backgroundColor: color }}
        />
        <div className={styles.sectionContent}>
          <SectionTypeIcon
            className={styles.sectionLogo}
            iconKey={icon}
            color={color}
            // iconStyle="fill"
          />
          <p
            className={cn(styles.sectionHeading, {
              [styles.sectionHeadingDisabled]: sectionDisabled,
            })}
          >
            {name}
          </p>
          <DraggableHandle />
        </div>
      </Card>
    </div>
  );
};

export default SectionButton;
