import { FederationContext, OrganisationContext, UserContext } from '@/context';
import { useMutation, useQuery } from '@/hooks';
import { CREATE_WORKFLOW, TOGGLE_WORKFLOW } from '@/mutations';
import { Card, Drawer, HTMLTable } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';

import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { GET_CURRENT_USER_WORKFLOWS } from '@/queries';
import { AppToaster } from '@/utils/toaster';
import { useDrawerSize } from '@hogwarts/ui-components-core';
import { FormBuilder } from '@hogwarts/ui-components-forms';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { isFeatureEnabled } from '../../hooks/useFeature';

// import getConditionalData from '@/conditionals';
// TODO: This was simply the 'structure' from conditions
// with potentially some additions such as
// Profile Type or Tag comparisons
const getConditionalData = {};

let hours = [];
for (let i = 0; i < 24; i++) {
  hours.push(i);
}

const scheduleFields = [
  {
    type: 'callout',
    title: 'Schedule',
    message:
      'Select the approx. date and time that you would like to receive the notification',
  },
  {
    key: 'weekdays',
    type: 'multiselect',
    label: 'Day(s) of Week',
    default: [],
    values: [
      { id: 1, value: 'Monday' },
      { id: 2, value: 'Tuesday' },
      { id: 3, value: 'Wednesday' },
      { id: 4, value: 'Thursday' },
      { id: 5, value: 'Friday' },
      { id: 6, value: 'Saturday' },
      { id: 7, value: 'Sunday' },
    ],
  },
  {
    key: 'timeOfDay',
    type: 'time',
    label: 'Time of Day',
    default: '07:00',
  },
];

const workflowNotificationTemplates = [
  {
    title: 'School Summary',
    description: 'Get a summary of the selected schools based on a schedule',
    fields: [
      ...scheduleFields.map((s, index) => ({
        key: `field${index}`,
        ...s,
      })),
      {
        key: 'conditions',
        type: 'conditional',
        default: {
          required: {
            // TODO: In theory, only what is selected
            // could apply here?
            // organisationType: true,
            // organisation: true,
            // sectionTypes: true,
            profileType: true,
            profile: true,
            values: true,
          },
          enabled: true,
          conditions: [],
        },
      },
    ],

    workflow: (form, user) => {
      const [hour, minute] = form.timeOfDay.split(':');
      return {
        workflowType: 'notification',
        triggerType: 'schedule',
        triggerParams: {
          type: 'daily',
          weekdays: form.weekdays,
          hour,
          minute,
        },
        ownerType: 'USER',
        ownerId: user.id,
        actionType: 'job',
        actionParams: {
          jobType: 'notifications/send_data_email',
          notification: {
            dataSourceKey: 'organisationRatings',
            template: 'ratings',
            subject: 'School Ratings Report',
            title: 'School Ratings Report',
          },
        },
        meta: {
          name: 'Summary',
          description: 'Sends a report of all your schools current scores',
        },
      };
    },
  },
  {
    title: 'Date approaching',
    description:
      'Select a Date check and get notified when it is approaching or has elapsed',
    fields: [
      {
        // input selector
        // need all fields relevant to this school that are date fields!
        // need to know how many days before hand to give the notification
        // 1 day, 3 days, 1 week, 2 weeks, 1 month ?
        // or just a number of days picker? (numeric picker)
      },
    ],
  },
  {
    title: 'Date has passed',
    description:
      'Select a Date check and get notified when it is approaching or has elapsed',
  },
  {
    title: 'Profile Added',
    description:
      'Occurs when a Profile is Added by anyone to the selected schools',
  },
  {
    title: 'Profile Deleted',
    description:
      'Occurs when a Profile is Deleted by anyone to the selected schools',
  },
  {
    title: 'Profile Changed',
    description:
      'Occurs when the selected fields are changed by anyone to a Profile in the selected schools',
  },
];

const TemplateSelector = ({ templates, onClick }) => {
  return templates.map((template, index) => (
    <Card key={index} interactive={true} onClick={() => onClick(template)}>
      <h5>{template.title}</h5>
      <p>{template.description}</p>
    </Card>
  ));
};

// eslint-disable-next-line
const CreateWorkflow = ({ title, children, sectionTypes, profileTypes }) => {
  /*
TODO: 
Enhance the Form Builder to only show fields if others
are selected?
OR
Enhance formbuilder for a multi-page style approach (wizard) which mimics the current intended
style. So the first page is a component selecting the notification type, but
its just setting a field.
*/

  const { t } = useTranslation();
  const user = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [lastActionError, setLastActionError] = useState(null);
  const [item, setItem] = useState(null);
  const [context, setContext] = useState(null);
  const [template, setTemplate] = useState(null);
  const [createWorkflow] = useMutation(CREATE_WORKFLOW);
  const [conditionalMetaData, setConditionalMetaData] = useState(null);
  const size = useDrawerSize();

  // TODO: Interesting. We need to get sectionTypes
  // but only for the current school or user?
  useEffect(() => {
    setConditionalMetaData(getConditionalData({ profileTypes, sectionTypes }));
  }, [profileTypes, sectionTypes]);

  const showTemplateSelector = () => {
    setItem({});
    setContext(context);
    setLastActionError(null);
  };

  return (
    <>
      <Drawer
        title={title}
        isOpen={item}
        canEscapeKeyClose
        enforceFocus
        canOutsideClickClose
        isCloseButtonShown
        size={size}
        onClose={() => {
          setTemplate(null);
          setItem(null);
        }}
      >
        {!template ? (
          <TemplateSelector
            templates={workflowNotificationTemplates}
            onClick={(template) => setTemplate(template)}
          />
        ) : (
          <FormBuilder
            fields={template.fields}
            initialValues={template.fields.reduce(
              (prev, cur) => ({
                ...prev,
                [cur.key]: cur.default,
              }),
              {}
            )}
            componentProps={{
              conditionalMetaData,
            }}
            onSave={async (values) => {
              try {
                const workflow = template.workflow(values, user);
                await createWorkflow({
                  variables: {
                    ...workflow,
                  },
                });
                AppToaster.show({
                  message: t(`Save successful`),
                  intent: 'success',
                  icon: 'tick',
                });
                setItem(null);
                setTemplate(null);
                setLastActionError(null);
              } catch (e) {
                setLastActionError(e);
                throw e;
              }
            }}
          />
        )}
      </Drawer>
      {children && children(showTemplateSelector)}
    </>
  );
};

const UserNotifications = () => {
  const { t } = useTranslation();

  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  const {
    loading,
    data: workflows,
    error,
  } = useQuery(GET_CURRENT_USER_WORKFLOWS, {
    selector: 'user.workflows',
    variables: {
      // should pass org and fed keys here I think ?
      // then we can list any specific ones
      // plus maybe a filter on the type?
      workflowType: 'notification',
      organisationId: organisation.id,
    },
    transform: (workflows) => {
      return workflows.filter(
        (w) =>
          !w.meta?.featureKey ||
          isFeatureEnabled(w.meta.featureKey, organisation)
      );
    },
  });

  const [toggleWorkflow] = useMutation(TOGGLE_WORKFLOW);

  const disable_dbsrenewal =
    get(organisation, 'attributes.disable_dbsrenewal') === true ||
    get(federation, 'attributes.disable_dbsrenewal') === true;

  return (
    <SettingsPanel title={'Notifications'} loading={loading} error={error}>
      {/* <CreateWorkflow
        title={'Add Notification'}
        fields={[]}
        sectionTypes={sectionTypes}
        profileTypes={profileTypes}
      >
        {(open) => <Button onClick={() => open()}>Create...</Button>}
      </CreateWorkflow> */}

      <HTMLTable interactive striped style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Description')}</th>
            <th>{t('Email')}</th>
          </tr>
        </thead>
        <tbody>
          {workflows?.length === 0 && (
            <tr>
              <td colSpan="3">
                {t('There appear to be no notifications to subscribe to.')}
              </td>
            </tr>
          )}
          {workflows
            ?.map((workflow) => {
              if (disable_dbsrenewal && workflow.meta.key === 'dbsrenewal') {
                return null;
              }

              return (
                <tr key={workflow.id}>
                  <td>{t(workflow.meta.name)}</td>
                  <td>{t(workflow.meta.description)}</td>
                  <td>
                    <Toggle
                      checked={workflow.enabled}
                      onChange={() =>
                        toggleWorkflow({
                          variables: {
                            id: workflow.id,
                            enabled: !workflow.enabled,
                          },
                        })
                      }
                    />
                  </td>
                </tr>
              );
            })
            .filter(Boolean)}
        </tbody>
      </HTMLTable>
    </SettingsPanel>
  );
};

export default UserNotifications;
