import { ConditionGroup } from '@hogwarts/conditionals';
import { Scheme } from '@hogwarts/utils-schemes';
import React, { useMemo } from 'react';
import { Conditionals } from './Conditionals';
import { fieldStructureFactory } from './utils';

const trimLength = (text: string): string => {
  const maxlength = 45;

  if (!text) return text;

  if (text.length > maxlength - 3) {
    return `${text.substring(0, maxlength)}...`;
  }

  return text;
};

const iconLookup: Record<string, string> = {
  string: 'font',
  number: 'numerical',
  date: 'calendar',
  boolean: 'segmented-control',
  array: 'array',
};
const defaultIcon = 'help';

export interface ConditionalsForSchemeProps {
  condition?: ConditionGroup;
  onChange: (condition: ConditionGroup) => void;
  scheme?: Scheme;
  readOnly?: boolean;
  prioritySection?: string;
  excludeFields?: string[];
}
export const ConditionalsForScheme = ({
  condition: initialCondition,
  onChange,
  scheme,
  readOnly,
  prioritySection,
  excludeFields,
}: ConditionalsForSchemeProps) => {
  const structure = useMemo(() => {
    if (!scheme) return {};

    let groupList = scheme.sections
      .filter((s) => s.canUse !== false)
      .map((section) => ({
        key: section.key,
        label: section.label,
      }));

    if (prioritySection) {
      const section = groupList.find((s) => s.key === prioritySection);
      if (section) {
        groupList = groupList.filter((s) => s.key !== prioritySection);
        groupList.unshift(section);
      }
    }

    const fieldList = scheme.fields
      .filter((f) => {
        if (excludeFields?.includes(f.key)) {
          return false;
        }
        return (
          f.dataType !== 'none' && f.dataType !== 'array' && f.canUse !== false
        );
      })
      .map((field) => {
        return {
          sectionKey: field.section,
          key: `field/${field.key}/${field.dataType}`,
          label: trimLength(field.label),
          dataType: field.dataType,
          inputType: field.inputType,
          values: field.inputMeta?.values,
          icon: iconLookup[field.dataType] || defaultIcon,
        };
      });

    return fieldStructureFactory(fieldList, groupList);
  }, [excludeFields, prioritySection, scheme]);

  return (
    <Conditionals
      defaultOperator={'or'}
      childGroupOperator={'and'}
      structure={structure}
      onChange={onChange}
      condition={initialCondition}
      variables={initialCondition?.variables}
      readOnly={readOnly}
    />
  );
};
