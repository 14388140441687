import cn from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FederationContext, OrganisationContext } from '../../../context';
import IconBarContainer from './IconBar';
import TabBarContainer from './TabBar';
import LogoContainer from './logo';
import styles from './styles.module.css';

const DEFAULT_HEADING_COLOR = '#404040';

interface HeaderProps {
  organisations?: unknown[];
  showSearch?: boolean;
  enforceMode?: boolean;
}
export const Header = ({
  organisations,
  showSearch,
  enforceMode,
}: HeaderProps) => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  const brandColor = useMemo(() => {
    if (organisation?.branding?.color) {
      return organisation.branding.color;
    }
    if (federation?.branding?.color) {
      return federation.branding.color;
    }
    return DEFAULT_HEADING_COLOR;
  }, [organisation, federation]);

  const lg = useMediaQuery({
    query: '(min-width: 992px)',
  });

  return (
    <div
      className={cn(styles.header, 'px-2 px-lg-5')}
      style={{ backgroundColor: brandColor }}
    >
      {lg && (
        <div className="d-flex justify-content-between">
          <LogoContainer />
          {!enforceMode && <TabBarContainer brandColor={brandColor} />}
          <IconBarContainer
            showSearch={showSearch}
            organisations={organisations}
            enforceMode={enforceMode}
          />
        </div>
      )}
      {!lg && (
        <div style={{ paddingTop: '8px' }}>
          <div className="d-flex justify-content-between">
            <LogoContainer />
            <IconBarContainer
              showSearch={showSearch}
              organisations={organisations}
              enforceMode={enforceMode}
            />
          </div>
          {!enforceMode && <TabBarContainer brandColor={brandColor} />}
        </div>
      )}
    </div>
  );
};

export default Header;
