import {
  Error,
  Loading,
  getCurrencyString,
} from '@hogwarts/ui-components-core';
import { useFormState } from '@hogwarts/ui-components-forms';
import { sortBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  CheckOrder,
  CheckOrders,
} from '../../../components/Settings/CheckOrders';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useLazyQuery, useMutation, useQuery } from '../../../hooks';
import { UPDATE_PURCHASE_ORDER } from '../../../mutations';
import {
  GET_CHECK_ORDERS_STATUSES,
  GET_UNINVOICED_CHECK_ORDERS,
  GET_UNINVOICED_CHECK_ORDERS_Response,
} from '../../../queries';
import { useGetCheckAccountQuery } from '../CheckAccount';

interface FilterItem {
  value: string;
  id: string;
}

const CheckOrdersContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const forms = useFormState();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const [statusItems, setStatusItems] = useState<FilterItem[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Record<string, any>>({});
  const [moreAvailable, setMoreAvailable] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const { data: checkAccount } = useGetCheckAccountQuery();
  const [status, setCheckOrdersStatus] = useQueryParam('status', StringParam);

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {
    refetchQueries: [GET_UNINVOICED_CHECK_ORDERS],
  });

  const { data: statusesData } = useQuery(GET_CHECK_ORDERS_STATUSES, {
    selector: 'organisations[0].checkOrdersStatuses',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const [getCheckOrders, { loading, data: checkOrders, error, fetchMore }] =
    useLazyQuery(GET_UNINVOICED_CHECK_ORDERS, {
      selector: 'organisations[0].checkOrders',
      transform(checkOrders: GET_UNINVOICED_CHECK_ORDERS_Response[]) {
        return sortBy(checkOrders, 'orderedAt').reverse();
      },
    });

  const checkOrdersVariables = useMemo(
    () => ({
      limit: 25,
      organisationKey: organisation.key,
      filter: {
        status: selectedFilter?.status,
      },
    }),
    [organisation.key, selectedFilter]
  );

  const getFilteredData = useCallback(
    (filter: Record<string, any> = {}) => {
      setSelectedFilter(filter);
      setMoreAvailable(true);
      getCheckOrders({
        variables: {
          ...checkOrdersVariables,
          filter,
        },
      });
    },
    [checkOrdersVariables, getCheckOrders]
  );

  useEffect(() => {
    if (statusesData) {
      setStatusItems(
        statusesData.map((statusItem: [string, string]) => ({
          value: statusItem[0],
          id: statusItem[1],
        }))
      );
      setCheckOrdersStatus(
        statusesData.find(
          (statusItem: [string, string]) => statusItem[0] === 'PENDING'
        )[1],
        'pushIn'
      );
    }
  }, [setCheckOrdersStatus, statusesData]);

  // This updates the UI as browser navigation occurs
  useEffect(() => {
    if (status !== selectedFilter?.status) {
      if (status) {
        getFilteredData({ status });
      } else {
        getFilteredData();
      }
    }
  }, [getFilteredData, selectedFilter?.status, status]);

  if (!checkAccount?.checkAccount) {
    return null;
  }

  if (loading) {
    return (
      <SettingsPanel title={t('Orders')} fixedHeight="500px">
        <Loading />
      </SettingsPanel>
    );
  }
  if (error) {
    return (
      <SettingsPanel title={t('Orders')} fixedHeight="500px">
        <Error />
      </SettingsPanel>
    );
  }

  const loadMore = async () => {
    if (!checkOrders) return;
    const lastOrder = checkOrders[checkOrders.length - 1];
    const cursor = lastOrder.id;

    setLoadingMore(true);

    await fetchMore({
      variables: {
        ...checkOrdersVariables,
        cursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newRecords = fetchMoreResult.organisations[0].checkOrders;
        if (newRecords.length < checkOrdersVariables.limit) {
          setMoreAvailable(false);
        }
        const currentRecords = previousResult.organisations[0].checkOrders;
        const combinedOrders = [...currentRecords, ...newRecords];
        const currentOrganisation = previousResult.organisations[0];
        return {
          organisations: [
            { ...currentOrganisation, checkOrders: combinedOrders },
          ],
        };
      },
    });

    setLoadingMore(false);
  };

  return (
    <SettingsPanel title={t('Orders')} fixedHeight="500px">
      <CheckOrders
        onOrderUpdate={(order: CheckOrder) => {
          analytics.events.settings.updateOrderClicked();
          forms.showForm('updateCheckOrder', {
            key: 'updateCheckOrder',
            title: t('Update Order'),
            saveText: t('Save'),
            savingText: t('Saving'),
            savedText: t('Saved'),
            successToastMessage: t('Order Updated'),
            allowCancel: true,
            canSave: true,
            initialValues: {
              orderId: order.orderId,
              orderedAt: order.orderedAt,
              status: order.status,
              price: getCurrencyString(order.price.currency, order.price.value),
              checks: order.checks,
              purchaseOrder: order.purchaseOrder,
            },
            fields: [
              {
                key: 'purchaseOrder',
                type: 'textbox',
                label: t('Purchase Order'),
              },
              {
                key: 'orderId',
                type: 'textbox',
                label: t('Order Id'),
                readOnly: true,
              },
              {
                key: 'orderedAt',
                type: 'datepicker',
                label: t('Order Date'),
                readOnly: true,
              },
              {
                key: 'status',
                type: 'textbox',
                label: t('Status'),
                readOnly: true,
              },
              {
                key: 'price',
                type: 'textbox',
                label: t('Price'),
                readOnly: true,
              },
              {
                type: 'separator',
              },
              {
                key: 'checks',
                type: 'title',
                label: t('Checks'),
              },
              ...order.checks?.map((check: { id: string; name: string }) => {
                return {
                  key: check.name,
                  type: 'label',
                  label: `#${check.id} - ${check.name}`,
                };
              }),
            ],
            onSave: async (values: Record<string, any>) => {
              await updatePurchaseOrder({
                variables: {
                  id: order.id,
                  purchaseOrder: values.purchaseOrder,
                },
              });

              analytics.events.settings.orderUpdated({
                id: values.id,
                purchaseOrder: values.purchaseOrder,
              });
            },
          });
        }}
        filters={[
          {
            type: 'select',
            id: 'status',
            icon: 'automatic-updates',
            description: t('Status'),
            items: statusItems,
            loading: loading,
          },
        ]}
        onFilter={getFilteredData}
        onShowProfileChecks={(profileId: string) => {
          history.push(`/${organisation.key}/profiles/${profileId}/checks`);
        }}
        loadMore={
          checkOrders?.length >= checkOrdersVariables.limit && moreAvailable
            ? loadMore
            : undefined
        }
        loadingMore={loadingMore}
        checkOrders={checkOrders}
      />
    </SettingsPanel>
  );
};

export default CheckOrdersContainer;
