import React from 'react';
import logo from '../../../../assets/providers/cornerstone.png';
import { ProviderMeta } from '../types';

const PROVIDER_KEY = 'talend';

const HelpBlock = () => {
  return (
    <div>
      <p>To connect to Cornerstone please contact us.</p>
    </div>
  );
};

const connectionFields: any[] = [];

export default {
  key: PROVIDER_KEY,
  name: 'Cornerstone',
  logo: logo,
  url: 'https://www.cornerstoneondemand.com/uk/',
  connectionFields,
  HelpBlock,
  showOptions: {
    showConnection: false,
    showProfileTypeMap: false,
    showOrganisationMap: false,
  },
} as ProviderMeta;
