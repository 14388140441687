import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { ProfileTypeSelectorCore } from './component';

interface ProfileTypeSelectorProps {
  profileTypes: {
    key: string;
    label: string;
  }[];
}
export const ProfileTypeSelector = ({
  profileTypes,
}: ProfileTypeSelectorProps) => {
  let [profileTypeKey, setProfileTypeKey] = useQueryParam('type', StringParam);
  if (!profileTypeKey && profileTypes.length) {
    profileTypeKey = profileTypes[0].key;
  }

  return (
    <ProfileTypeSelectorCore
      profileTypeKey={profileTypeKey!}
      profileTypes={profileTypes}
      onChange={(profileTypeKey) => {
        setProfileTypeKey(profileTypeKey, 'replaceIn');
      }}
    />
  );
};
