import { gql } from '@apollo/client';

export const GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT = gql`
  query profileOrganisationChangeRequest($profileId: ID!) {
    profileOrganisationChangeRequest(profileId: $profileId) {
      cacheKey
      targetOrganisation {
        id
        name
      }
    }
  }
`;

export const GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST = gql`
  query profileOrganisationChangeRequests($targetOrganisationId: ID!) {
    profileOrganisationChangeRequests(
      targetOrganisationId: $targetOrganisationId
    ) {
      cacheKey
      currentOrganisation {
        name
      }
      profileId
      profileTypeKey
      profileMetadata
    }
  }
`;

export const GET_PROFILETYPE_INUSE = gql`
  query profileTypeInUse($profileTypeKey: String!) {
    profileTypeInUse(profileTypeKey: $profileTypeKey)
  }
`;

export const GET_PROFILETYPE_EXISTS = gql`
  query profileTypeExists($organisationId: ID!, $profileTypeKey: String!) {
    profileTypeExists(
      organisationId: $organisationId
      profileTypeKey: $profileTypeKey
    )
  }
`;

export const GET_USER_PROFILE_VERSIONS = gql`
  query userProfileVersionsQuery(
    $organisationKey: String!
    $profileId: ID!
    $cursor: DateTime
    $limit: Int
    $changes: [String!]
  ) {
    organisations(key: $organisationKey) {
      id
      key
      profiles(id: $profileId) {
        id
        name
        versions(query: { changes: $changes, cursor: $cursor, limit: $limit }) {
          id
          organisationChanged
          organisation {
            id
            key
            name
          }
          profileTypeChanged
          previousProfileTypeKey
          profileTypeKey
          data
          meta
          source
          user {
            id
            email
            name
          }
          date
          previousDifferences {
            field
            index
            action
            from
            to
          }
          first
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query userProfileQuery($organisationKey: String!, $profileId: ID!) {
    organisations(key: $organisationKey) {
      id
      key
      profiles(id: $profileId) {
        id
        name
        typeKey
        tags
        ratings {
          cacheKey
          systemKey
          score
        }
        data
        dataError
        attributes
        meta
        created
        updated
      }
    }
  }
`;

export const GET_USER_PROFILE_DOCUMENTS = gql`
  query userProfileQuery(
    $organisationKey: String!
    $profileId: ID!
    $documentsFilter: DocumentsFilter
  ) {
    organisations(key: $organisationKey) {
      id
      key
      profiles(id: $profileId) {
        id
        documents(filter: $documentsFilter) {
          id
          filename
          type
          tags
          size
          filestackHandle
          createdAt
          meta
          uploadedBy {
            id
            name
            email
            enabled
          }
        }
      }
    }
  }
`;

export const GET_BULK_IMPORT_SUMMARY = gql`
  query bulkImportSummary($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      key
      name
      enabled
      bulkImportSummary {
        typeKey
        added
        updated
        invalid
      }
    }
  }
`;

export const GET_BULK_IMPORT_PROFILES = gql`
  query getOrganisationBulkImportProfiles(
    $organisationKey: String!
    $typeKey: String
  ) {
    organisations(key: $organisationKey) {
      id
      key
      name
      enabled
      bulkImportProfiles(typeKey: $typeKey) {
        id
        key
        index
        typeKey
        data
      }
    }
  }
`;

export const PROFILE_SEARCH = gql`
  query profileSearch(
    $searchTerm: String!
    $organisationKey: String!
    $federationKey: String
  ) {
    profileSearch(
      filter: {
        searchTerm: $searchTerm
        organisationKey: $organisationKey
        federationKey: $federationKey
      }
    ) {
      id
      typeKey
      name
      organisationKey
      organisationName
      federation
      tags
    }
  }
`;
