import { NonIdealState } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListView } from '@hogwarts/ui-components-list';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const OrganisationItem = ({
  organisation,
  onOrganisationSelected,
  currentOrganisationKey,
  showRatingPercentages,
  showRatings,
  last,
}) => {
  return (
    <div
      key={organisation.key}
      className={cn(
        styles.organisationOption,
        last && styles.organisationOptionLast
      )}
      onClick={onOrganisationSelected.bind(null, organisation.key)}
    >
      <p
        className={cn('flex-grow-1', styles.organisationHeader, {
          [styles.currentOrganisation]:
            currentOrganisationKey === organisation.key,
        })}
      >
        {organisation.name}
      </p>
      <div className={styles.scoresContainer}>
        {showRatings &&
          organisation.ratings?.map((rating) => (
            <p
              key={`rating-${rating.key}`}
              className={cn(styles.organisationScore, 'text-nowrap')}
              style={{
                color:
                  rating.score === 1 ? rating.readyColor : rating.notReadyColor,
              }}
            >
              {showRatingPercentages
                ? `${rating.label}: ${Math.floor(rating.score * 100)}%`
                : rating.label}
              {!showRatingPercentages && rating.score === 1 && (
                <FontAwesomeIcon
                  icon={'check'}
                  size={'1x'}
                  className="ml-2"
                  color={rating.readyColor}
                />
              )}
              {!showRatingPercentages && rating.score < 1 && (
                <FontAwesomeIcon
                  icon={'times'}
                  size={'1x'}
                  className="ml-2"
                  color={rating.notReadyColor}
                />
              )}
            </p>
          ))}
      </div>
    </div>
  );
};

const OrganisationsListWidget = ({
  currentOrganisationKey,
  organisations,
  showRatings,
  showRatingPercentages,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();

  if (!organisations || organisations.length === 0) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={t('No Organisations found')}
        description={t('There are no organisations available to list')}
      />
    );
  }
  return (
    <ListView
      className="w-100 h-100"
      componentProps={{
        currentOrganisationKey,
        showRatingPercentages,
        showRatings,
        onOrganisationSelected,
      }}
      rows={organisations}
      component={({ item, ...props }) => (
        <OrganisationItem organisation={item} {...props} />
      )}
      spreadRowItem={false}
    />
  );
};

export default OrganisationsListWidget;
