import cn from 'classnames';
import React from 'react';
import styles from './styles.module.css';

interface ComponentProps {
  index: number;
  last: boolean;
  item?: unknown;
}
export const rowFactory =
  (
    Component: React.FC<ComponentProps>,
    rows: any[],
    componentProps: Partial<Omit<ComponentProps, 'index'>> = {},
    {
      spreadRowItem,
      divider,
    }: {
      spreadRowItem: boolean;
      divider: boolean;
    }
  ) =>
  (index: number) => {
    if (!rows) return null;
    const last = rows.length - 1 === index;
    return (
      <div
        className={cn(
          'w-100',
          styles.listItem,
          divider && styles.listItemBorders
        )}
      >
        {spreadRowItem ? (
          <Component
            index={index}
            {...componentProps}
            {...rows[index]}
            last={last}
          />
        ) : (
          <Component
            index={index}
            {...componentProps}
            last={last}
            item={rows[index]}
          />
        )}
      </div>
    );
  };
