import { gql } from '@apollo/client';

export const ADD_TAG = gql`
  mutation addTag($values: TagInput!) {
    addTag(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($values: UpdateTagInput!) {
    updateTag(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id)
  }
`;
