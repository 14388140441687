import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query getLocations {
    locations {
      code
      name
      timezones
    }
  }
`;

export const GET_TIMEZONES = gql`
  query getTimezones {
    timezones {
      code
      country
      offset
    }
  }
`;

export const GET_ALL_LANGUAGES = gql`
  query getAllLanguages {
    languages {
      id
      key
      name
      enabled
      order
    }
  }
`;

export const GET_INSIGHTS = gql`
  query insights($type: String!, $params: JSON) {
    insights(type: $type, params: $params) {
      id
      type
      success
      params
      result
    }
  }
`;

export interface GET_JOB_Response {
  id: string;
  type: string;
  params?: any;
  status: string;
  started: string;
  finished: string;
  result?: any;
}
export const GET_JOB = gql`
  query getJob($id: ID!) {
    jobs(id: $id) {
      id
      type
      params
      status
      started
      finished
      result
    }
  }
`;
