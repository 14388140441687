import { AnalyticsContext, FederationContext } from '@/context';
import {
  CREATE_ORGANISATION_GROUP_MUTATION,
  DELETE_ORGANISATION_GROUP_MUTATION,
  UPDATE_ORGANISATION_GROUP_MUTATION,
} from '@/mutations';
import { sortBy, uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryResults } from '../../hooks';

import { GridView } from '@/components';
import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { GET_FEDERATIONS } from '@/queries';
import { useFormState } from '@hogwarts/ui-components-forms';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../hooks';

const OrganisationGroups = () => {
  const federation = useContext(FederationContext);
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  const organisationsQuery = useQuery(GET_FEDERATIONS, {
    selector: 'federations[0].organisations',
    variables: {
      federationKey: federation.key,
    },
  });

  const organisationGroupsQuery = useQuery(GET_FEDERATIONS, {
    selector: 'federations[0].organisationGroups',
    variables: {
      federationKey: federation.key,
    },
  });

  const { loading, error } = useQueryResults({
    organisationsQuery,
    organisationGroupsQuery,
  });

  const [addGroup] = useMutation(CREATE_ORGANISATION_GROUP_MUTATION, {
    variables: {
      federationKey: federation.key,
    },
    refetchQueries: [
      {
        query: GET_FEDERATIONS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const [editGroup] = useMutation(UPDATE_ORGANISATION_GROUP_MUTATION);

  const [deleteGroup] = useMutation(DELETE_ORGANISATION_GROUP_MUTATION, {
    variables: {
      federationKey: federation.key,
    },
    refetchQueries: [
      {
        query: GET_FEDERATIONS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'Are you sure you want to delete this group? This operation cannot be undone.'
        )}
      </p>
    ),
    onConfirm: ({ id }) => {
      deleteGroup({ variables: { id } });
      analytics.events.groups.deleteOrganisationGroupClicked({ id });
    },
  });

  const forms = useFormState();

  const organisations = organisationsQuery.data;
  const organisationGroups = organisationGroupsQuery.data;

  useEffect(() => {
    if (!organisations) return;

    // TODO: This needs to include the selected values as well
    // as we've had issues where there are users in the policy but
    // not in the list
    // and it looks like they arent selected.
    // This is why uniqBy exists.
    let organisationValues = sortBy(
      uniqBy(organisations.filter(Boolean), (u) => u.id),
      (u) => u.name
    ).map((organisation) => ({
      id: organisation.id,
      value: `${organisation.name}`,
    }));

    forms.register('addGroup', {
      title: 'Add Group',
      fields: [
        {
          key: 'federationId',
          type: 'hidden',
        },
        {
          key: 'name',
          label: 'Name',
          validate: 'required',
        },
        {
          key: 'organisations',
          label: 'Organisations',
          type: 'multiselectlist',
          values: organisationValues,
        },
        {
          key: 'enabled',
          label: 'Enabled',
          type: 'toggle',
        },
      ],
      initialValues: {
        federationId: federation.id,
        enabled: true,
        users: [],
      },
      onSave: async (values) => {
        await addGroup({
          variables: {
            ...values,
          },
        });
      },
    });

    forms.register('editGroup', {
      title: 'Edit Group',
      fields: [
        {
          key: 'id',
          type: 'hidden',
        },
        {
          key: 'name',
          label: 'Name',
          validate: 'required',
        },
        {
          key: 'organisations',
          label: 'Organisations',
          type: 'multiselectlist',
          values: organisationValues,
        },
        {
          key: 'enabled',
          label: 'Enabled',
          type: 'toggle',
        },
      ],
      onSave: async (values) => {
        await editGroup({
          variables: {
            ...values,
          },
        });
      },
    });
    return () => {
      forms.unregister('addGroup');
      forms.unregister('editGroup');
    };
  }, [
    organisations,
    organisationGroups,
    forms,
    federation.id,
    addGroup,
    editGroup,
  ]);

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        valueGetter: ({ data }) => {
          return data?.name;
        },
        label: 'Group Name',
        width: 300,
      },
      {
        key: 'count',
        valueGetter: ({ data }) => {
          return data?.organisations?.length;
        },
        label: 'Number of Organisations',
        width: 150,
      },
      {
        key: 'enabled',
        label: 'Enabled',
        valueGetter: ({ data }) => data?.enabled,
        width: 150,
      },
    ];
  }, []);

  return (
    <>
      <ConfirmDeleteAlert />
      <SettingsPanel
        loading={loading}
        error={error}
        title="Organisation Groups"
        actions={[
          {
            text: 'Add Group',
            icon: 'user-plus',
            onClick: () => {
              forms.showForm('addGroup');
            },
          },
        ]}
        fixedHeight="750px"
      >
        <GridView
          showSideBar={false}
          sizeColumnsToFit
          contextMenuItems={(nodes) => {
            if (nodes.length !== 1) return null;
            return [
              {
                name: 'Delete Group',
                action: (groups) => {
                  showConfirmDeleteAlert({ id: groups[0].data.id });
                },
              },
            ];
          }}
          rows={organisationGroups}
          columns={columns}
          onRowDoubleClicked={(row) => {
            const { id, name, organisations, enabled, includeAll } = row.data;
            if (includeAll) {
              return;
            }
            forms.showForm('editGroup', {
              initialValues: {
                id,
                name,
                organisations: organisations.map((o) => o.id),
                enabled,
              },
            });
          }}
        />
      </SettingsPanel>
    </>
  );
};

export default OrganisationGroups;
