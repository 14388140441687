import { Button } from '@blueprintjs/core';
import React from 'react';
import { Provider } from '../types';

function openWindow(url: string) {
  const newwindow = window.open(url);
  if (newwindow?.focus) {
    newwindow.focus();
  }
  return false;
}
interface NoDataProviderProps {
  providers: Provider[];
}

const NoDataProvider = ({ providers }: NoDataProviderProps) => {
  const hasProviders = !!providers?.length;

  return (
    <div>
      <p>
        Using a Data Provider, you can connect to an MIS system to synchronise
        your list of staff on a daily basis.
      </p>
      <p>
        If you already have a provider installed, please use the Change Provider
        button and follow the prompts.
      </p>

      {hasProviders && (
        <div className={'my-3'}>
          <p>
            The following providers are supported. Click one to go to their
            website to read more.
          </p>
          <div>
            {providers
              .filter((p) => p.allowNew !== false)
              .map((provider) => (
                <Button
                  key={provider.key}
                  className="p-3"
                  onClick={() => openWindow(provider.url)}
                  minimal
                >
                  <img
                    src={provider.logo}
                    style={{ height: '40px' }}
                    alt={provider.name}
                  />
                </Button>
              ))}
          </div>
        </div>
      )}

      <p>
        For further help on this, please see our{' '}
        <a href="https://go.scrtracker.com/3TbyiQR" target="blank">
          help guides.
        </a>
      </p>
    </div>
  );
};

export default NoDataProvider;
