import React, { useContext } from 'react';
import {
  ProfileListItem as ProfileListItemCore,
  ProfileRatings as ProfileRatingsCore,
} from '../../components';
import { OrganisationContext } from '../../context';
import { useDateFormatter } from '../../hooks';

export interface Profile {
  id: string;
  typeKey: string;
  data: Record<string, unknown>;
  name: string;
  organisation: {
    name: string;
  };
  tags?: {
    key: string;
    label: string;
    color: string;
  }[];
  ratings: {
    key: string;
    label: string;
    ready: boolean;
    readyColor: string;
    notReadyColor: string;
  }[];
}
interface ProfileListItemProps {
  profile: Profile;
  onSelected?: (id: string, item: Profile) => void;
  children: React.ReactNode;
  last?: boolean;
  fields?: string[];
  showAvatar?: boolean;
  iconPack?: string;
  isVerticallyStacked?: boolean;
  className?: string;
}
export const ProfileListItem = ({
  profile,
  onSelected,
  children,
  last,
  fields,
  showAvatar,
  iconPack,
  isVerticallyStacked,
  className,
}: ProfileListItemProps) => {
  const organisation = useContext(OrganisationContext);
  const formatDate = useDateFormatter();

  const profileType = organisation.scheme.getProfileType(profile.typeKey);

  let fieldValues: { key: string; label: string; value: any }[] | undefined;
  if (fields?.length) {
    fieldValues = [];
    for (const fieldKey of fields) {
      const fieldItem = organisation.scheme.getField(fieldKey);
      if (!fieldItem) {
        continue;
      }
      let value = profile.data[fieldItem.key];
      if (value == null) continue;

      // TODO: I feel there should be a hook/other for formatting these values
      // depending on their inputType rather than simply datatype?
      switch (fieldItem.dataType) {
        case 'date': {
          value = formatDate.medium(value as string);
          break;
        }
        default: {
          value = `${value}`;
          break;
        }
      }
      fieldValues.push({
        key: fieldKey,
        label: fieldItem.label,
        value,
      });
    }
  }

  return (
    <ProfileListItemCore
      id={profile.id}
      name={profile.name}
      orgName={profile.organisation?.name}
      type={profileType?.label}
      fields={fieldValues}
      avatar={showAvatar ? profileType?.avatar : undefined}
      tags={profile.tags}
      iconPack={iconPack}
      isVerticallyStacked={isVerticallyStacked}
      // ratings={profile.ratings}
      onSelected={
        onSelected ? () => onSelected(profile.id, profile) : undefined
      }
      last={last}
      className={className}
    >
      {children}
    </ProfileListItemCore>
  );
};

export const ProfileRatings = ProfileRatingsCore;
