import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  Action,
  Column,
  ColumnGroup,
  DateParser,
  FilterOverride,
} from '../../types';

const getFilterForType = (
  type?: string,
  params?: {
    parseDate: DateParser;
    timezone?: string;
  }
): [string | boolean, any] => {
  const { parseDate, timezone } = params || {};
  let filter: string | boolean, filterParams: any;
  switch (type) {
    case 'boolean': {
      filter = 'agSetColumnFilter';
      filterParams = {
        values: [true, false],
      };
      break;
    }
    case 'string': {
      filter = 'agTextColumnFilter';
      break;
    }
    case 'number': {
      filter = 'agNumberColumnFilter';
      break;
    }
    case 'date': {
      filter = 'agDateColumnFilter';
      filterParams = {
        buttons: ['clear'],
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const filterDate = parseDate!(filterLocalDateAtMidnight, timezone);
          const cellDate = parseDate!(cellValue, timezone);

          if (!filterDate.isValid || !cellDate.isValid) {
            return -1;
          }

          if (cellDate < filterDate) {
            return -1;
          } else if (cellDate > filterDate) {
            return 1;
          } else {
            return 0;
          }
        },
      };
      break;
    }
    default: {
      filter = false;
      break;
    }
  }
  return [filter, filterParams];
};

const getFilter = (
  type?: string,
  filterOverride?: FilterOverride,
  options?: {
    parseDate: DateParser;
    timezone?: string;
  }
) => {
  if (filterOverride === false) {
    return [false, {}];
  }

  let [filter, filterParams] = getFilterForType(type, options);

  if (filterOverride != null && filterOverride !== true) {
    if (!filterParams) filterParams = {};
    if (filterOverride.values) {
      filter = 'agSetColumnFilter';
      filterParams.values = filterOverride.values;
    } else if (filterOverride.type) {
      filter = filterOverride.type;
    }
  }

  return [filter, filterParams];
};

const getColumnDefForType = (
  type: string | undefined,
  {
    actions,
    filter: filterOverride,
    parseDate,
    timezone,
    label,
    intent,
    onClick,
    disabled,
  }: {
    label: string;
    parseDate: DateParser;
    intent?: string;
    timezone?: string;
    disabled?: boolean;
    actions?: Action[] | ((item?: any) => Action[]);
    filter?: FilterOverride;
    floatingFilter?: boolean;
    onClick?: (value: any) => void;
  }
): ColDef => {
  switch (type) {
    case 'actions': {
      return {
        cellRenderer: 'actionsRenderer',
        // @ts-ignore
        meta: {
          actions,
          intent,
        },
      };
    }
    case 'button':
    case 'deletebutton': {
      return {
        floatingFilter: false,
        cellRenderer: 'buttonRenderer',
        // @ts-ignore
        meta: {
          label,
          intent,
          disabled,
          deleteConfirm: type === 'deletebutton',
          // icon ?
          onClick,
        },
      };
    }
    default: {
      const [filter, filterParams] = getFilter(type, filterOverride, {
        parseDate,
        timezone,
      });

      return {
        filter,
        filterParams,
      };
    }
  }
};

export const getColumnDefsFromColumns =
  (parseDate: DateParser) =>
  (
    groups: ColumnGroup[],
    columns: Column[],
    timezone?: string
  ): [(ColDef | ColGroupDef)[], ColDef[], ColGroupDef[]] => {
    const allColumnDefs: ColDef[] = [];
    const allColumnGroups: ColGroupDef[] = [];
    const columnDefs: (ColDef | ColGroupDef)[] = [];

    let keyedGroups: Record<string, ColGroupDef> = {};
    if (Array.isArray(groups)) {
      for (const group of groups) {
        const columnDef = (keyedGroups[group.key] = {
          // colId: group.key,
          groupId: group.key,
          headerName: group.label,
          children: [],
          marryChildren: true,
        });
        allColumnGroups.push(columnDef);
        columnDefs.push(columnDef);
      }
    }

    if (Array.isArray(columns)) {
      for (const column of columns) {
        const {
          group,
          intent,
          type,
          key,
          label,
          meta,
          disabled,
          onClick,
          visible,
          actions,
          filter,
          ...rest
        } = column;

        let columnDef: ColDef = {
          ...rest,
          colId: key,
          field: key,
          headerName: label,
          hide: visible === false,

          // @ts-ignore
          meta: {
            ...meta,
            key,
            type,
          },
        };

        columnDef = {
          ...columnDef,
          ...getColumnDefForType(type, {
            filter,
            parseDate,
            timezone,
            disabled,
            actions,
            intent,
            label,
            onClick,
          }),
        };

        allColumnDefs.push(columnDef);
        if (group && keyedGroups[group]) {
          keyedGroups[group].children.push(columnDef);
          continue;
        }
        columnDefs.push(columnDef);
      }
    }

    return [columnDefs, allColumnDefs, allColumnGroups];
  };
