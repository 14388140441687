import trackEvent from '../trackEvent';

export const transferApproved = (traits = {}) => {
  trackEvent('Profile Transfers - Transfer Approved', traits, true);
};

export const transferRejected = (traits = {}) => {
  trackEvent('Profile Transfers - Transfer Rejected', traits, true);
};

export const transferRequested = (traits = {}) => {
  trackEvent('Profile Transfers - Transfer Requested', traits, true);
};

export const transferSuccessful = (traits = {}) => {
  trackEvent('Profile Transfers - Transfer Successful', traits, true);
};
