import React, { useContext, useState } from 'react';

import permissions from '@hogwarts/permissions';
import { parseDate } from '@hogwarts/validation';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ProfileMerge } from '../components';
import { OrganisationContext } from '../context';
import { AppToaster } from '../utils/toaster';
import { usePermission } from './usePermission';

interface Rating {
  key: string;
  score: number;
}

interface Profile {
  id: string;
  typeKey: string;
  name: string;
  data: Record<string, any>;
  profileType: string;
  ratings: Rating[];
}

type SlimProfile = Record<string, unknown> & {
  name: string;
  profileType: string;
  ratings: Record<string, number>;
};

interface RatingSystem {
  key: string;
  label: string;
  readyColor: string;
  notReadyColor: string;
}

interface Field {
  key: string;
  dataType: string;
  label: string;
}

interface ProfileMergeContainerProps {
  profiles: SlimProfile[];
  ratingSystems: RatingSystem[];
  isOpen: boolean;
  fields: Field[];
  onClose: () => void;
  onMerge: (leftProfile: SlimProfile, rightProfile: SlimProfile) => void;
}
const ProfileMergeContainer = ({
  profiles,
  ratingSystems,
  isOpen,
  onClose,
  onMerge,
  fields,
}: ProfileMergeContainerProps) => {
  if (!fields || !profiles || !ratingSystems || profiles.length !== 2)
    return null;

  const [leftProfile, rightProfile] = profiles;

  return (
    <ProfileMerge
      leftProfile={leftProfile}
      rightProfile={rightProfile}
      ratingSystems={ratingSystems}
      isOpen={isOpen}
      onClose={onClose}
      onMerge={onMerge}
      fields={fields}
    />
  );
};

const formatField = (field: Field, value: unknown): any => {
  switch (field.dataType) {
    case 'date': {
      if (value == null) return null;
      let date = parseDate(value);
      if (date.isValid) {
        return date.toLocaleString(DateTime.DATE_FULL);
      }
      return value;
    }
    case 'boolean': {
      if (value === true) return 'true';
      if (value === false) return 'false';
      return value;
    }
    default:
    case 'string': {
      return value;
    }
  }
};

export const useMergeProfiles = (
  onMerge: (
    leftProfile: SlimProfile,
    rightProfile: SlimProfile
  ) => Promise<void>
): [
  (profiles: Profile[]) => void,
  {
    Component: typeof ProfileMergeContainer;
    props: ProfileMergeContainerProps;
  },
  boolean
] => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  const canMergeProfiles = usePermission(
    [permissions.PROFILE_READ, permissions.PROFILE_MERGE],
    organisation.id
  );

  const [data, setCurrentData] = useState<null | {
    ratingSystems: RatingSystem[];
    profiles: SlimProfile[];
  }>();

  const fields = sortBy(
    organisation.scheme.fields.filter((f) => f.features?.profileMerge?.enabled),
    (f) => f.features.profileMerge.order
  );

  const showMergeProfiles = (profiles: Profile[]) => {
    if (profiles.length !== 2) {
      return null;
    }

    const ratingSystems = organisation.scheme.ratingSystems.map((r) => ({
      key: r.key,
      label: r.label,
      notReadyColor: r.notReadyColor,
      readyColor: r.readyColor,
    }));

    const slimProfiles: SlimProfile[] = profiles.map((p) => {
      return {
        id: p.id,
        name: p.data.name,
        profileType: organisation.scheme.getProfileType(p.typeKey)?.label,
        ratings: p.ratings.reduce(
          (prev, rating) => ({
            ...prev,
            [rating.key]: rating.score,
          }),
          {}
        ),
        ...fields.reduce((prev, field) => {
          return {
            ...prev,
            [field.key]: formatField(field, p.data[field.key]),
          };
        }, {}),
      } as SlimProfile;
    });

    setCurrentData({
      profiles: slimProfiles,
      ratingSystems,
    });
  };

  return [
    showMergeProfiles,
    {
      Component: ProfileMergeContainer,
      props: {
        isOpen: data != null,
        ...data!,
        fields,
        onMerge: async (
          leftProfile: SlimProfile,
          rightProfile: SlimProfile
        ) => {
          if (onMerge) {
            await onMerge(leftProfile, rightProfile);
          }

          setCurrentData(null);
          AppToaster.show({
            message: t('Profiles successfully merged'),
            intent: 'success',
            icon: 'tick',
          });
        },
        onClose: () => {
          setCurrentData(null);
        },
      },
    },
    canMergeProfiles,
  ];
};
