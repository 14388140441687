import { SideBarDef, ToolPanelDef } from '@ag-grid-community/core';
import {
  GridReadyEvent,
  GridView as GridViewCore,
  GridViewProps,
} from '@hogwarts/ui-components-grid';
import React, { useMemo } from 'react';
import { ColumnChooser, ColumnChooserContext } from './ColumnChooser';

import { parseDate } from '@hogwarts/validation';
import { frameworkComponents } from './frameworkComponents';

function setSidebarWidth(width: number) {
  const panelWrappers = document.querySelectorAll('.ag-tool-panel-wrapper');
  // @ts-ignore
  for (const panelGui of panelWrappers) {
    panelGui.style.width = width + 'px';
  }
}

export const GridView = ({
  frameworkComponents: customFrameworkComponents,
  onGridReady: onGridReadyParent,
  showSideBar,
  collapseSideBar,
  groups,
  columns,
  sideBar,
  ...props
}: GridViewProps) => {
  const onGridReady = (params: GridReadyEvent) => {
    setSidebarWidth(500);
    if (onGridReadyParent) {
      return onGridReadyParent(params);
    }
  };
  const allFrameworkComponents = useMemo(
    () => ({
      ...frameworkComponents,
      ...customFrameworkComponents,
      ColumnChooser,
    }),
    [customFrameworkComponents]
  );
  const sideBarOptions = useMemo<SideBarDef | boolean>(() => {
    if (showSideBar === false) return false;

    let toolPanels: (ToolPanelDef | string)[] | undefined;
    if (
      typeof sideBar !== 'string' &&
      typeof sideBar !== 'boolean' &&
      !Array.isArray(sideBar)
    ) {
      toolPanels = sideBar?.toolPanels;
    }

    return {
      ...(typeof sideBar === 'object' ? sideBar : undefined),
      toolPanels: [
        {
          id: 'columnChooser',
          labelDefault: 'Fields',
          iconKey: 'columns',
          toolPanel: 'ColumnChooser',
        },
        ...(toolPanels || []),
      ],
      defaultToolPanel: collapseSideBar ? null : 'columnChooser',
    } as SideBarDef;
  }, [collapseSideBar, showSideBar, sideBar]);

  return (
    <ColumnChooserContext.Provider
      value={{
        groups,
        columns,
      }}
    >
      <GridViewCore
        {...props}
        groups={groups}
        columns={columns}
        sideBar={sideBarOptions}
        groupHeaderHeight={30}
        headerHeight={75}
        onGridReady={onGridReady}
        parseDate={parseDate}
        frameworkComponents={allFrameworkComponents}
      />
    </ColumnChooserContext.Provider>
  );
};
