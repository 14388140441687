import React, { useContext, useState } from 'react';
import { AnalyticsContext, OrganisationContext } from '@/context';
import ProseFilter from '@/components/ProseFilter';
import { Tag } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';

const ProfilesFilterBar = ({ onFilter, initialFilters }) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const { profileTypes, ratingSystems, tags } = organisation.scheme;

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const { ratings, ...rest } = initialFilters;
    return {
      profileTypes: profileTypes.map((p) => p.key),
      tags: ['active', 'onboarding'],
      ...rest,
      ...(ratings &&
        ratings.reduce(
          (prev, cur) => ({
            ...prev,
            [`ratingSystem_${cur.key}`]: [cur.include],
          }),
          {}
        )),
    };
  });

  const onFiltersChanged = (filters) => {
    setSelectedFilters(filters);
    if (onFilter) {
      onFilter(filters);
    }
  };

  const terms = { prefix: t('Showing') };
  const filters = [
    {
      id: 'profileTypes',
      name: t('Profile Types'),
      available: profileTypes.map((pt) => ({
        id: pt.key,
        label: pt.label,
      })),
      selected: selectedFilters.profileTypes,
      selectOptions: {
        min: 1,
        max: null,
        removable: false,
        withApply: true,
      },
      terms: {
        allSelected: t('all profile types'),
        joining: t('of type'),
        prefix: null,
        suffix: null,
      },
    },
    {
      id: 'tags',
      name: t('Tags'),
      component: Tag,
      available: tags.map((t) => ({
        id: t.key,
        label: t.label,
        color: t.color,
      })),
      selected: selectedFilters.tags,
      selectOptions: {
        min: 1,
        max: null,
        removable: false,
        withApply: true,
      },
      terms: {
        allSelected: null,
        joining: t('with'),
        prefix: null,
        suffix: t('tags'),
      },
    },
    ...ratingSystems.map((rs, idx) => ({
      id: `ratingSystem_${rs.key}`,
      name: rs.label,
      available: [
        {
          id: false,
          label: `${t('Not')} ${rs.label}`,
        },
        {
          id: true,
          label: rs.label,
        },
      ],
      selected: selectedFilters[`ratingSystem_${rs.key}`],
      selectOptions: {
        min: 1,
        max: 1,
        removable: true,
      },
      terms: {
        allSelected: null,
        joining: idx === 0 ? t('that are') : t('and'),
        prefix: null,
        suffix: null,
      },
    })),
  ];

  return (
    <ProseFilter
      terms={terms}
      filters={filters}
      activeFilters={Object.keys(selectedFilters)}
      onSelectionChange={(type, selected) => {
        const newFilters = {
          ...selectedFilters,
          [type]: selected,
        };
        analytics.events.profiles.filtersChanged({ newFilters });
        onFiltersChanged(newFilters);
      }}
      onAddFilter={(filterId) => {
        // analytics.track(analytics.trackingKeys.profiles.FILTER_ADDED, {
        //   filterId,
        // });
        const filter = filters.find((f) => f.id === filterId);
        onFiltersChanged({
          ...selectedFilters,
          [filterId]: [filter.available[0].id],
        });
      }}
      onRemoveFilter={(filterId) => {
        // analytics.track(analytics.trackingKeys.profiles.FILTER_REMOVED, {
        //   filterId,
        // });
        const newFilters = { ...selectedFilters };
        delete newFilters[filterId];
        //  activeFilters.filter((f) => f !== filterId);
        onFiltersChanged(newFilters);
      }}
    />
  );
};

export default ProfilesFilterBar;
