import { Button as ButtonBP, InputGroup } from '@blueprintjs/core';
import { Select } from '@hogwarts/ui-components-core';
import { DatePicker } from '@hogwarts/ui-components-forms';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { candidateFieldInfo } from './Helpers';
import { countryCodes } from './identityValues';

interface EditorProps {
  value: string;
  onChange: (value: string) => void;
}
export const TextEditor = ({ value, onChange }: EditorProps) => {
  return (
    <InputGroup
      onBlur={(event) => {
        let value = event.target.value;
        if (value !== null) {
          onChange(value);
        }
      }}
      defaultValue={value}
      type="text"
    />
  );
};

type DateEditorProps = EditorProps & {
  values: { id: string; value: string }[];
};
export const DateEditor = ({
  value: currentValue,
  onChange,
}: DateEditorProps) => {
  return (
    <DatePicker
      canClearSelection={false}
      fill
      shortcuts
      onChange={(date: Date) => {
        let text = null;
        if (date) {
          text = DateTime.fromJSDate(date)
            .setZone('utc', { keepLocalTime: true })
            .startOf('day')
            .toJSDate()
            .toISOString()
            .substring(0, 10);
        }
        onChange(text || '');
      }}
      currentDate={currentValue}
    />
  );
};

type SingleSelectProps = EditorProps & {
  values: { id: string; value: string }[];
};
export const SingleSelect = ({
  value,
  values,
  onChange,
}: SingleSelectProps) => {
  return (
    // @ts-ignore
    <Select
      valueField={'id'}
      textField={'value'}
      onChange={(item: any) => {
        onChange(item.id);
      }}
      items={values}
      large={false}
      fill
      value={value}
    />
  );
};

interface EditableItemProps {
  value: string;
  displayValue: string;
  field: string;
  onSave: (value: string) => void;
}
export const EditableItem = ({
  value,
  displayValue,
  field,
  onSave,
}: EditableItemProps) => {
  // types.
  // country. string. date
  // validation?
  // passport is 9 digits for UK. 11 elsewhere
  // DriverLicenseType is from valueset of 2 values
  // national insurance is 8 to 9 chars

  const [isEditing, setIsEditing] = useState(false);

  const [proposedValue, setProposedValue] = useState<string | null>();

  const fieldInfo = candidateFieldInfo[field];

  if (isEditing) {
    let Component: React.FC<EditorProps>;
    let componentProps: any;
    switch (fieldInfo.type) {
      case 'country': {
        // @ts-ignore
        Component = SingleSelect;
        componentProps = {
          values: countryCodes,
        };
        break;
      }
      case 'select': {
        // @ts-ignore
        Component = SingleSelect;
        componentProps = {
          values: fieldInfo.values?.map((v) => ({
            id: v,
            value: v,
          })),
        };
        break;
      }
      case 'date': {
        // @ts-ignore
        Component = DateEditor;
        break;
      }
      default: {
        Component = TextEditor;
        break;
      }
    }
    return (
      <div className="d-flex">
        <div className="ml-2 flex-grow-1">
          <Component
            {...componentProps}
            onChange={(value) => {
              setProposedValue(value);
            }}
            value={proposedValue || value}
          />
        </div>
        <ButtonBP
          icon="cross"
          intent="danger"
          onClick={() => {
            setIsEditing(false);
            setProposedValue(null);
          }}
        />
        <ButtonBP
          icon="tick"
          intent="success"
          onClick={() => {
            setIsEditing(false);
            onSave(proposedValue || '');
            setProposedValue(null);
          }}
        />
      </div>
    );
  }

  return (
    <div className="d-flex">
      <div className="ml-2 flex-grow-1">{displayValue || value}</div>
      <ButtonBP
        icon="edit"
        onClick={() => {
          setIsEditing(true);
        }}
      />
    </div>
  );
};
