import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Measure from 'react-measure';
import { NonIdealState } from '@blueprintjs/core';

import styles from './styles.module.css';

const TabWrapperHoC = (TabComponent) =>
  function TabWrapper({ ...props }) {
    const { t } = useTranslation();
    const [currentSize, setCurrentSize] = useState(null);

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          setCurrentSize(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            {currentSize && currentSize.width >= 1024 && (
              <div className={styles.pageContainer}>
                <TabComponent {...props} />
              </div>
            )}
            {currentSize && currentSize.width < 1024 && (
              <NonIdealState
                className="m-3"
                icon="minimize"
                title={t('Device Too Small')}
                description={t(
                  "This tool requires a larger screen size than you have, if you're using a tablet you could try rotating it."
                )}
              />
            )}
          </div>
        )}
      </Measure>
    );
  };

export default TabWrapperHoC;
