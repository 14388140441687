import { Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import permissions from '@hogwarts/permissions';
import { Error } from '@hogwarts/ui-components-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationContext } from '../../context';
import { useBuildReport, usePermission } from '../../hooks';

const REPORT_ID = 'everything';

const ExportDataButton = () => {
  const organisation = useContext(OrganisationContext);
  const { t } = useTranslation();
  const allowed = usePermission(
    [permissions.PROFILE_PRINT, permissions.PROFILE_READ],
    organisation?.id
  );

  const [
    exportReport,
    {
      component: BuildingReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
  ] = useBuildReport();

  return (
    <>
      <BuildingReportComponent {...reportProps} />
      <Button
        large
        intent={'primary'}
        disabled={!allowed || reportDisabled}
        onClick={() => {
          if (!exportReport) return;
          return exportReport(REPORT_ID);
        }}
      >
        <>
          {!allowed && <FontAwesomeIcon className="mr-md-2" icon={'lock'} />}
          {t('Export')}
        </>
      </Button>
    </>
  );
};

const RenderSuspended = () => {
  const organisation = useContext(OrganisationContext);
  return (
    <Error
      icon="lock"
      title="Account Suspended"
      description={`This account (${organisation.name}) has been automatically suspended.`}
      action={<ExportDataButton />}
    />
  );
};

export default RenderSuspended;
