import permissions from '@hogwarts/permissions';
import { useForms } from '@hogwarts/ui-components-forms';
import { FormikBag, FormikConfig } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsContext,
  OrganisationContext,
} from '../../../../../../context';
import { useMutation, usePermission, useQuery } from '../../../../../../hooks';
import { DELETE_DOCUMENT } from '../../../../../../mutations';
import { ADD_ACTIVITY } from '../../../../../../mutations/activities';
import {
  DATASOURCE_QUERY_ACTIVITIES,
  DATASOURCE_QUERY_PROFILES,
  GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
  GET_ORGANISATION_USERS,
} from '../../../../../../queries';
import { Document } from '../../../../../../types';

const getOptions = (data: { id: string; name: string }[]) => {
  return data?.map(({ id, name }) => ({
    id,
    value: name,
  }));
};

interface AddIncidentFormProps {
  onCreate: (incident: any) => {};
  onOpenDocumentPicker: () => boolean;
  isUploadSuccessful: boolean;
  skipDelete: (flag?: boolean) => void;
  documents: Document[];
  resetDocuments: () => void;
  onRemoveDocument: (document: Document) => void;
}

const AddIncidentForm = ({
  onCreate,
  onOpenDocumentPicker,
  documents,
  resetDocuments,
  onRemoveDocument,
}: AddIncidentFormProps) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const [isDocumentAttached, setIsDocumentAttached] = useState(false);
  const [isProfileIncident, setIsProfileIncident] = useState(false);
  const [formik, setFormik] = useState<FormikBag<FormikConfig<any>, any>>();
  let isFormSaved = false;

  const readMetadata = usePermission(
    permissions.ORGANISATION_ACTIVITY_METADATA_READ,
    organisation.id
  );

  const [addActivity] = useMutation(ADD_ACTIVITY, {
    selector: 'addActivity',
  });

  const { data: profiles } = useQuery(DATASOURCE_QUERY_PROFILES, {
    selector: 'queryProfiles.result',
    variables: {
      tags: ['active', 'onboarding'],
      organisationKey: organisation.key,
      sort: [{ field: 'firstname' }],
      skip: 0,
      limit: 0,
    },
  });

  const { data: users } = useQuery(GET_ORGANISATION_USERS, {
    selector: 'organisations[0].users',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const { data: incidentMetadata } = useQuery(
    GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
    {
      skip: !readMetadata,
      selector: 'organisations[0]',
      variables: {
        organisationKey: organisation.key,
        skip: 0,
        limit: 0,
      },
    }
  );

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    selector: 'deleteDocument.data',
    onCompleted: () => {
      analytics.events.activities.documentRemoved();
    },
  });

  const allowDeleteFile = usePermission(
    permissions.ORGANISATION_FILE_DELETE,
    organisation.id
  );

  const removeDocuments = async () => {
    resetDocuments();
    if (!isFormSaved) {
      for (const document of documents) {
        if (allowDeleteFile) {
          await deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
          setIsDocumentAttached(false);
        }
      }
    }
  };

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(
        'documents',
        documents.map((document) => document.id)
      );
    }
    setIsProfileIncident(false);
    setIsDocumentAttached(!!documents.length);
  }, [formik, documents, setIsDocumentAttached]);

  useForms({
    key: 'addIncident',
    title: 'Add Incident',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Incident Saved',
    allowCancel: true,
    canSave: true,
    fields: [
      {
        key: 'notes',
        type: 'textarea',
        label: t('Notes'),
        validate: 'required',
      },
      {
        key: 'profile',
        type: !!isProfileIncident ? 'hidden' : 'singleselect',
        label: t('Profile'),
        values: getOptions(profiles),
        readValue: (profileId: string) => {
          if (profileId) {
            setIsProfileIncident(true);
          }
          return profileId;
        },
        validate: 'required',
      },
      {
        key: 'assignedTo',
        type: 'bpmultiselect',
        label: t('Assigned To'),
        values: getOptions(users),
      },
      {
        key: 'category',
        type: 'singleselect',
        label: t('Category'),
        values: getOptions(incidentMetadata?.categories),
        validate: 'required',
      },
      {
        key: 'tags',
        type: 'bpmultiselect',
        label: t('Tags'),
        values: getOptions(incidentMetadata?.tags),
      },
      {
        key: 'date',
        type: 'datepicker',
        label: t('Date'),
        validate: 'required',
      },
      {
        key: 'status',
        type: 'singleselect',
        label: t('Status'),
        values: getOptions(incidentMetadata?.statuses),
        validate: 'required',
      },
      {
        key: 'severity',
        type: 'singleselect',
        label: t('Severity'),
        values: getOptions(incidentMetadata?.severities),
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'selectLabel',
        type: 'label',
        label: t('Select documents to attach to this incident.'),
      },
      {
        key: 'selectButton',
        type: 'button',
        label: t('Select'),
        onClickHandler({ formik: formikBag }: { formik: FormikBag<any, any> }) {
          setFormik(formikBag);
          analytics.events.profile.uploadActionClicked();
          onOpenDocumentPicker();
        },
      },
      {
        key: 'documentsUploaded',
        type: 'documentlist',
        visible: isDocumentAttached,
        allowDelete: allowDeleteFile,
        className: 'withLineBreaks',
        label: t('Documents'),
        documents,
        onRemoveDocument: (document: Document) => {
          analytics.events.activities.removeDocumentClicked({
            documentId: document.id,
          });
          onRemoveDocument(document);
          deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
        },
      },
      {
        key: 'documents',
        type: 'hidden',
      },
    ],
    onSave: async (values) => {
      analytics.events.activities.saveClicked();

      const {
        notes,
        assignedTo,
        category,
        date,
        profile: profileId,
        severity,
        status,
        tags,
        documents,
      } = values;

      const activity = await addActivity({
        variables: {
          activity: {
            organisationKey: organisation.key,
            ownerType: 'PROFILE',
            ownerId: profileId,
            type: 'incident',
            tags,
            documents,
            date,
            data: {
              notes,
              assignedTo,
              category,
              severity,
              status,
            },
          },
        },
        refetchQueries: [
          {
            query: DATASOURCE_QUERY_ACTIVITIES,
            variables: {
              profileId,
              organisationKey: organisation.key,
            },
          },
        ],
      });

      isFormSaved = !!activity.data;

      analytics.events.activities.activityAdded({ id: activity.data?.id });
      if (onCreate) {
        onCreate(profileId);
      }
    },
    onClose: removeDocuments,
    onReset: removeDocuments,
  });

  return null;
};

export default AddIncidentForm;
