import { InputGroup } from '@blueprintjs/core';
import { useDebounce } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';
import { getErrorIntent } from './utils';

const DEFAULT_DEBOUNCE_TIME = 250;

const TextComponent = ({
  fieldKey,
  formField,
  readOnly,
  password,
  placeHolder,
  fieldHelpers,
  fieldMeta,
  maxLength,
}) => {
  const [value, setValue] = useState(null);
  let showValue = value === null ? formField.value : value;
  if (showValue == null) showValue = '';

  const onChangeHandler = useDebounce((value) => {
    fieldHelpers.setTouched(true, false);
    fieldHelpers.setValue(value);
    setValue(null);
  }, DEFAULT_DEBOUNCE_TIME);

  return (
    <InputGroup
      id={fieldKey}
      intent={getErrorIntent(fieldMeta)}
      onBlur={(event) => {
        let value = event.target.value;
        if (value !== null) {
          fieldHelpers.setTouched(true, false);
          fieldHelpers.setValue(value, true);
          setValue(null);
        }
      }}
      onChange={(event) => {
        let value = event.target.value;
        onChangeHandler(value);
        setValue(value);
      }}
      maxLength={maxLength}
      placeholder={placeHolder}
      value={showValue}
      disabled={readOnly}
      type={password ? 'password' : 'text'}
    />
  );
};

export default {
  name: 'Text Box',
  options: [],
  label: true,
  Component: TextComponent,
};
