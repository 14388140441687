import React from 'react';
import ProfileTransfer from '../ProfileTransfer';

import styles from './styles.module.css';

const ProfileTransfersList = ({
  destinationOrganisation,
  transfers,
  canAcceptTransfers,
  onApprove,
  onReject,
}) => {
  return (
    <div className={styles.profileListContainer}>
      {transfers.map((transfer) => (
        <ProfileTransfer
          key={transfer.profileId}
          sourceOrganisation={transfer.currentOrganisation.name}
          destinationOrganisation={destinationOrganisation}
          profile={transfer.profileMetadata}
          canAcceptTransfers={canAcceptTransfers}
          onApprove={onApprove.bind(this, transfer.profileId)}
          onReject={onReject.bind(this, transfer.profileId)}
        />
      ))}
    </div>
  );
};

export default ProfileTransfersList;
