import { ColumnApi, ColumnState, GridApi } from '@ag-grid-community/core';
import {
  ChoosableColumn,
  Column,
  ColumnChooser as ColumnChooserCore,
  ColumnGroup,
} from '@hogwarts/ui-components-grid';
import { get, sortBy, uniqBy } from 'lodash';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const arrayMoveMutate = <T,>(array: T[], from: number, to: number): void => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMove = <T,>(array: T[], from: number, to: number): T[] => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

const mapColumns = (
  columns: Column[],
  columnStates: ColumnState[]
): ChoosableColumn[] => {
  return sortBy(
    columns
      .map((column, order) => {
        return {
          ...column,

          locked: column.meta?.locked,

          inputType: !!get(column, 'meta.field.inputType'),
          dataType: !!get(column, 'meta.field.dataType'),
          inUse: !!get(column, 'meta.field.inUse'),
          canUse: !!get(column, 'meta.field.canUse'),
          order,
        };
      })
      .map((column) => {
        const columnStateIndex = columnStates.findIndex(
          (c) => c.colId === column.key
        );
        if (columnStateIndex === -1) return column;
        const columnState = columnStates[columnStateIndex];
        return {
          ...column,
          visible: !columnState.hide,
          order: columnStateIndex,
        };
      }),
    'order'
  );
};

const mapGroups = (groups: ColumnGroup[] | undefined, columns: Column[]) => {
  if (!groups) return [];
  const sortedGroupKeys = uniqBy(
    columns.map((col) => col.group),
    (g) => g
  );
  return sortBy(
    groups.map((g) => {
      const order = sortedGroupKeys.findIndex((key) => g.key === key);
      return {
        ...g,
        order,
      };
    }),
    'order'
  );
};

interface ColumnChooserContextType {
  groups?: ColumnGroup[];
  columns: Column[];
}

export const ColumnChooserContext = React.createContext(
  null as unknown as ColumnChooserContextType
);

interface ColumnChooserProps {
  api: GridApi;
  columnApi: ColumnApi;
}

export const ColumnChooser = forwardRef(
  ({ api, columnApi }: ColumnChooserProps, ref) => {
    useImperativeHandle(ref, () => ({
      getReactContainerClasses() {
        return ['w-100'];
      },
    }));

    const { groups: initialGroups, columns: initialColumns } =
      useContext(ColumnChooserContext);

    const [columnStates, setColumnStates] = useState(() => {
      return columnApi?.getColumnState();
    });

    const [{ columns, groups }, setColumnState] = useState(() => {
      const updatedColumns = mapColumns(initialColumns, columnStates);
      return {
        groups: mapGroups(initialGroups, updatedColumns),
        columns: updatedColumns,
      };
    });

    useEffect(() => {
      const updatedColumns = mapColumns(initialColumns, columnStates);
      setColumnState({
        groups: mapGroups(initialGroups, updatedColumns),
        columns: updatedColumns,
      });
    }, [columnStates, initialColumns, initialGroups]);

    useEffect(() => {
      api?.addEventListener('displayedColumnsChanged', () =>
        setColumnStates(columnApi.getColumnState())
      );
      // gridColumnsChanged
      // columnEverythingChanged
      return () => {
        api?.removeEventListener('displayedColumnsChanged', () =>
          setColumnStates(columnApi.getColumnState())
        );
      };
    }, [api, columnApi]);

    const onVisibleChange = (keys: string[], visible: boolean) => {
      columnApi.applyColumnState({
        state: keys.map((key) => ({
          colId: key,
          hide: !visible,
        })),
      });
    };

    const onOrderChange = (oldCol: Column, newCol: Column) => {
      const oldIndex = columnStates.findIndex((c) => c.colId === oldCol.key);
      const newIndex = columnStates.findIndex((c) => c.colId === newCol.key);
      setColumnState({
        groups,
        columns: arrayMove(columns, oldIndex, newIndex),
      });
      columnApi.moveColumn(oldCol.key, newIndex);
    };

    return (
      <ColumnChooserCore
        groups={groups}
        columns={columns}
        onVisibleChange={onVisibleChange}
        onOrderChange={onOrderChange}
      />
    );
  }
);
