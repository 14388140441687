import { Tag } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileTypeIcon } from '../../../../components';
import styles from './styles.module.css';

const ProfileSummaryWidget = ({
  tags,
  profileTypes,
  profileCounts,
  onProfileTypeClick,
  iconPack,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.profileSummaryContainer}>
      {profileCounts &&
        Object.keys(profileCounts).map((profileKey, idx) => {
          const profileType = profileTypes.find((p) => p.key === profileKey);
          if (!profileType) return null;
          const profileScores = profileCounts[profileKey];
          return (
            <div
              key={idx}
              className={styles.profileTypeContainer}
              onClick={() => {
                if (onProfileTypeClick) {
                  onProfileTypeClick(
                    profileKey,
                    Object.keys(profileScores || {})
                  );
                }
              }}
            >
              <ProfileTypeIcon
                avatarKey={profileType.avatar}
                iconPack={iconPack}
                width="80px"
              />
              <div className={styles.contentContainer}>
                <p className={styles.profileName}>{t(profileType.label)}</p>
                <div className={styles.tagsContainer}>
                  {profileScores &&
                    Object.keys(profileScores).map((tagKey) => {
                      const currentTag = tags.find((t) => t.key === tagKey);
                      if (!currentTag) return null;
                      return (
                        <Tag
                          key={`tag-${tagKey}`}
                          label={`${profileScores[tagKey]} ${currentTag.label}`}
                          color={currentTag.color}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onProfileTypeClick) {
                              onProfileTypeClick(profileKey, [tagKey]);
                            }
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ProfileSummaryWidget;
