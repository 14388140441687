import { ReactComponent as BarredList } from './barred.svg';
import { ReactComponent as ChildcareDisqualification } from './childcaredisqualification.svg';
import { ReactComponent as DBSChecks } from './dbs.svg';
import { ReactComponent as EEASanctions } from './eea.svg';
import { ReactComponent as EmergencyContact } from './emergency.svg';
import { ReactComponent as EmploymentDetails } from './employment.svg';
import { ReactComponent as IdentityChecks } from './identity.svg';
import { ReactComponent as MedicalChecks } from './medical.svg';
import { ReactComponent as PreviousLiving } from './previousliving.svg';
import { ReactComponent as ProfileDetails } from './profile.svg';
import { ReactComponent as ProhibitionFromTeaching } from './prohibition.svg';
import { ReactComponent as ProfessionalQualification } from './qualification.svg';
import { ReactComponent as ReferenceChecks } from './reference.svg';
import { ReactComponent as RightToWork } from './righttowork.svg';
import { ReactComponent as Section128 } from './section128.svg';
import { ReactComponent as Additional } from './additional.svg';
import { ReactComponent as Badge } from './badge.svg';
import { ReactComponent as COVID } from './covid.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as MapPin } from './map.svg';
import { ReactComponent as Notes } from './notes.svg';
import { ReactComponent as World } from './world.svg';

export default {
  // TODO: Migrate Keys
  '/icons/additional.png': Additional,
  '/icons/childcare.png': ChildcareDisqualification,
  '/icons/covid-19.png': COVID,
  '/icons/dbs.png': DBSChecks,
  '/icons/emergency.png': EmergencyContact,
  '/icons/employment.png': EmploymentDetails,
  '/icons/identity.png': IdentityChecks,
  '/icons/medical.png': MedicalChecks,
  '/icons/notes.png': Notes,
  '/icons/outside-uk.png': PreviousLiving,
  '/icons/profile.png': ProfileDetails,
  '/icons/prohibition.png': ProhibitionFromTeaching,
  '/icons/qualification.png': ProfessionalQualification,
  '/icons/reference.png': ReferenceChecks,
  '/icons/right-to-work.png': RightToWork,
  '/icons/eea-sanctions.png': EEASanctions,
  '/icons/section-128.png': Section128,
  '/icons/barredlist.png': BarredList,
  world: World,
  badge: Badge,
  envelope: Envelope,
  map: MapPin,
  default: Additional,
};
