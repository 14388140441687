import { Label, NonIdealState } from '@blueprintjs/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Button } from '@hogwarts/ui-components-core';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { RenderFields } from '../RenderFields';
import styles from '../styles.module.css';

const behaviours = {
  ra_cognita_risk_assessment: ({ formik, items }) => {
    const closeStatus = formik.values['ra_user_closed'];

    // TODO: Create a computed field to state if this is closed!
    const closed = closeStatus === 'yes' || closeStatus === 'yes3';

    return items.map((item, index) => {
      const readOnly = closed || index !== 0;
      return { ...item, readOnly };
    });
  },
  ra2_cognita_risk_assessment: ({ formik, items }) => {
    const closeStatus = formik.values['ra2_user_closed'];

    // TODO: Create a computed field to state if this is closed!
    const closed = closeStatus === 'yes' || closeStatus === 'yes3';

    return items.map((item, index) => {
      const readOnly = closed || index !== 0;
      return { ...item, readOnly };
    });
  },
};

const AddButton = ({ formik, target, label }) => {
  return (
    <Button
      intent={'PRIMARY'}
      className="mt-2"
      onClick={() => {
        let existingArray = formik.values[target];
        if (!Array.isArray(existingArray)) {
          existingArray = [];
        }
        existingArray.push({});
        formik.setFieldValue(target, existingArray);
        formik.setFieldTouched(target);
      }}
    >
      {label || 'Add'}
    </Button>
  );
};

const DeleteButton = ({ formik, target, arrayHelpers, arrayIndex, label }) => {
  return (
    <Button
      className="mt-2"
      onClick={() => {
        arrayHelpers.remove(arrayIndex);
      }}
      intent="DANGER"
    >
      {label || 'Delete'}
    </Button>
  );
};

const TabComponent = ({
  formik,
  formField,
  children,
  isSubmitting,
  tabTitle,
  reverseOrder,
  behaviour,
  decorators,
  errors,
  decoratorRegistry,
  emptyTabTitle,
  emptyTabContent,
  addButtonHidden,
  addButtonLabel,
  deleteButtonHidden,
  deleteButtonLabel,
  label,
  showLabel,
  ...componentProps
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  let values = formField.value;
  if (!Array.isArray(values)) values = [];

  useEffect(() => {
    if (selectedIndex < 0 && values.length > 0) {
      setSelectedIndex(0);
    } else if (selectedIndex >= values.length) {
      setSelectedIndex(values.length - 1);
    }
  }, [values, values && values.length, selectedIndex]);

  let tabItems = useMemo(() => {
    let items = values.reduce((prev, item, index) => {
      return [...prev, { item, index }];
    }, []);

    if (reverseOrder) {
      items.reverse();
    }

    const behaviourFunction = behaviours[behaviour];
    if (behaviourFunction) {
      items = behaviourFunction({ formik, items });
    }

    return items;
  }, [values, values?.length, formik, behaviour, reverseOrder]);

  return (
    <FieldArray
      name={formField.name}
      render={(arrayHelpers) => {
        if (tabItems.length === 0) {
          return (
            <>
              {showLabel !== false && (
                <Label
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <b>{label}</b>
                </Label>
              )}
              <Tabs selectedIndex={0}>
                <TabList className={styles.tabList}>
                  <Tab
                    key={0}
                    className={styles.tab}
                    selectedClassName={styles.tabSelected}
                  >
                    {t(emptyTabTitle || 'No Items')}
                  </Tab>
                </TabList>

                <TabPanel
                  className={styles.tabPanel}
                  selectedClassName={styles.tabPanelSelected}
                  key={0}
                  tabId={0}
                >
                  <NonIdealState
                    className="my-3"
                    // icon="zoom-out"
                    title={t(emptyTabContent || 'No items available')}
                  />
                </TabPanel>
              </Tabs>
              {addButtonHidden ? null : (
                <AddButton
                  label={addButtonLabel}
                  target={formField.name}
                  formik={formik}
                />
              )}
            </>
          );
        }

        return (
          <>
            {showLabel !== false && (
              <Label
                style={{
                  marginBottom: 10,
                }}
              >
                <b>{label}</b>
              </Label>
            )}
            <Tabs
              selectedIndex={selectedIndex}
              onSelect={(index) => {
                setSelectedIndex(index);
              }}
            >
              <TabList className={styles.tabList}>
                {tabItems.map(({ index }) => {
                  return (
                    <Tab
                      key={index}
                      className={styles.tab}
                      selectedClassName={styles.tabSelected}
                    >
                      {t(tabTitle || `Item {{index}}`, {
                        index: index + 1,
                      })}
                    </Tab>
                  );
                })}
              </TabList>

              {tabItems.map(({ item, index, ...behaviourProps }) => {
                // Re-point them at the required key
                const fields = children
                  .map((field) => {
                    const visible =
                      !field.visible || field.visible[index] !== false;
                    // const reasons = field.reasons[index]
                    // const valid = field.valid[index]
                    return {
                      ...field,
                      visible,
                      key: `${formField.name}[${index}].${field.key}`,
                    };
                  })
                  .filter((field) => {
                    return field.visible;
                  });

                return (
                  <TabPanel
                    className={styles.tabPanel}
                    selectedClassName={styles.tabPanelSelected}
                    key={index}
                    tabId={`${index}`}
                  >
                    <RenderFields
                      formikProps={componentProps?.formikProps}
                      fields={fields}
                      componentProps={{
                        ...componentProps,
                        ...behaviourProps,
                        arrayIndex: index,
                        arrayHelpers,
                      }}
                      decoratorRegistry={decoratorRegistry}
                    />
                    {deleteButtonHidden ? null : (
                      <DeleteButton
                        label={deleteButtonLabel}
                        arrayHelpers={arrayHelpers}
                        arrayIndex={index}
                      />
                    )}
                  </TabPanel>
                );
              })}
            </Tabs>
            {addButtonHidden ? null : (
              <AddButton
                label={addButtonLabel}
                target={formField.name}
                formik={formik}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default {
  name: 'Tabs',
  options: [
    {
      key: 'tabTitle',
    },
    {
      key: 'reverseOrder',
    },
    {
      key: 'behaviour',
    },
  ],
  decorators: {
    label: false,
  },
  Component: TabComponent,
};
