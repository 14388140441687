import React from 'react';

const HelpTextComponent = ({ label }) => {
  return <b>{label}</b>;
};

export default {
  name: 'Help Text',
  options: [],
  decorators: {
    label: false,
  },
  Component: HelpTextComponent,
};
