import createPermission from './create';
import resourceTypes from './resourceTypes';

const create = (key: string, description: string) =>
  createPermission(key, description, [resourceTypes.SYSTEM]);

const permissions = {
  SYSTEM_SERVICE: create(
    'SYSTEM_SERVICE',
    'Assigned to the System wide Service Account'
  ),

  DIAGNOSTIC_VIEW: create('DIAGNOSTIC_VIEW', 'Allow Viewing of extra data'),

  QUEUE_JOB_ANY_ORGANISATION: create(
    'QUEUE_JOB_ANY_ORGANISATION',
    'Can queue a job for any organisation'
  ),

  SUPPORT_ADMINISTRATOR: create(
    'SUPPORT_ADMINISTRATOR',
    'Can access any Organisation open for Support'
  ),

  DEMONSTRATOR: create(
    'DEMONSTRATOR',
    'Used for sales staff and people who wish to demo the application'
  ),

  OPEN_FOR_SUPPORT: create(
    'OPEN_FOR_SUPPORT',
    'Support Admin - Can Open any Organisation for Support. Emergency use only.'
  ),

  SYSTEM_EXPORT_DATA: create(
    'SYSTEM_EXPORT_DATA',
    'Can Export Data from Admin Panel'
  ),

  SCHEME_CREATE: create('SCHEME_CREATE', 'Can create a new Scheme'),
  SCHEME_UPDATE: create('SCHEME_UPDATE', 'Allow updating of a Scheme'),
  SCHEME_DELETE: create('SCHEME_DELETE', 'Allow deleting of a Scheme'),

  GET_ALL_ATTRIBUTES: create(
    'GET_ALL_ATTRIBUTES',
    'Retrieve all Attributes in the System'
  ),
  GET_ALL_ROLES: create('GET_ALL_ROLES', 'Retrieve all Roles in the System'),
  GET_ALL_USERS: create('GET_ALL_USERS', 'Retrieve all Users in the System'),
  GET_ALL_ORGANISATIONS: create(
    'GET_ALL_ORGANISATIONS',
    'Retrieve all Organisations in the System'
  ),
  GET_ALL_FEDERATIONS: create(
    'GET_ALL_FEDERATIONS',
    'Retrieve all Federations in the System'
  ),
  GET_ALL_SCHEMES: create(
    'GET_ALL_SCHEMES',
    'Retrieve all Schemes in the System'
  ),
  GET_ALL_JOBS: create('GET_ALL_JOBS', 'Retrieve all Jobs in the System'),
  GET_ALL_WORKFLOWS: create(
    'GET_ALL_WORKFLOWS',
    'Retrieve all Workflows in the System'
  ),
  GET_ALL_FEATURES: create(
    'GET_ALL_FEATURES',
    'Retrieve all Features in the System'
  ),
  GET_ALL_CHECK_ACCOUNTS: create(
    'GET_ALL_CHECK_ACCOUNTS',
    'Retrieve all Check Accounts in the System'
  ),
  GET_ALL_CHECKORDERS: create(
    'GET_ALL_CHECKORDERS',
    'Retrieve all CheckOrders in the System'
  ),
  GET_ALL_INTEGRATIONS: create(
    'GET_ALL_INTEGRATIONS',
    'Retrieve all Integrations in the System'
  ),

  USER_CREATE: create('USER_CREATE', 'Can create a new User'),
  USER_UPDATE: create('USER_UPDATE', 'Allow updating of a User'),
  USER_DELETE: create('USER_DELETE', 'Allow deleting of a User'),

  // TODO: This should also be checked during Auth
  USER_API_KEY: create('USER_API_KEY', 'Allow User to have an API Key'),

  USER_CREATE_LOGIN: create(
    'USER_CREATE_LOGIN',
    'Can create a new User Authentication Account'
  ),
  USER_DELETE_LOGIN: create(
    'USER_DELETE_LOGIN',
    'Can delete a Users Authentication Account'
  ),
  USER_CREATE_RESET_PASSWORD_TICKET: create(
    'USER_CREATE_RESET_PASSWORD_TICKET',
    'Allow creating of a change password ticket for a User'
  ),
  USER_SEND_RESET_PASSWORD_TICKET: create(
    'USER_SEND_RESET_PASSWORD_TICKET',
    'Allow sending of a change password ticket for a User'
  ),

  ROLE_CREATE: create('ROLE_CREATE', 'Create a new Role'),
  ROLE_UPDATE: create('ROLE_UPDATE', 'Edit a Role'),
  ROLE_DELETE: create('ROLE_DELETE', 'Delete a Role'),

  CHANGE_FEDERATION: create(
    'CHANGE_FEDERATION',
    'Allow changing of the Federation of an Organisation'
  ),

  // no unit tests:
  QUEUE_JOB: create('QUEUE_JOB', 'Allow Queuing of Jobs'),
  LIST_JOB_TYPES: create('LIST_JOB_TYPES', 'Allow Listing of Job Types'),
  EXECUTE_IMMEDIATE_JOB: create(
    'EXECUTE_IMMEDIATE_JOB',
    'Allow Immediate Executing of Jobs'
  ),
  EXECUTE_IMMEDIATE_WORKFLOW: create(
    'EXECUTE_IMMEDIATE_WORKFLOW',
    'Allow Immediate Execution of any Workflow'
  ),
  EXECUTE_MIGRATION_TASKS: create(
    'EXECUTE_MIGRATION_TASKS',
    'Allow Executing of Migration Tasks'
  ),
  SEND_EMAIL: create('SEND_EMAIL', 'Allow Sending of Emails'),

  CREATE_SSO_USER: create('CREATE_SSO_USER', 'Allow creating of SSO Users'),
};

export default permissions;
