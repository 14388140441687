import React from 'react';
import { EditableList } from '@hogwarts/ui-components-core';

const ValuesList = ({
  fieldKey,
  formField,
  readOnly,
  fieldMeta,
  fieldHelpers,
  validate,
}) => {
  let values = formField.value;
  return (
    <EditableList
      onChange={(items) => {
        const changed = items.map((i) => i.value);
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(changed);
      }}
      items={values}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'Values List',
  options: [],
  label: true,
  Component: ValuesList,
};
