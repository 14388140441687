import { NonIdealState, Spinner } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getRequest } from '../../services/request';

const FS_PREFIX = 'https://cdn.filestackcontent.com/';

interface SecureImageProps {
  src: string;
  fallbackSrc: string;
  small?: boolean;
  noLoading: boolean;
  noError?: boolean;
  className?: string;
}
const SecureImage = ({
  src,
  fallbackSrc,
  small = false,
  noLoading,
  noError = false,
  ...props
}: SecureImageProps) => {
  const [secureUrl, setSecureUrl] = useState<string>();
  const [error, setError] = useState<boolean>();
  useEffect(() => {
    // If image is deleted, we need to clear the secure url so that it will be reloaded
    if (!src) {
      setSecureUrl('');
      return;
    }
    if (src.startsWith(FS_PREFIX)) {
      const handle = src.substring(FS_PREFIX.length);
      getRequest().then((request) => {
        return request
          .get(`/documents/policy/view/${handle}`)
          .then((policyResponse) => {
            if (policyResponse.status === 200) {
              setSecureUrl(
                `${src}?policy=${policyResponse.data.policy}&signature=${policyResponse.data.signature}`
              );
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true));
      });
    } else {
      console.warn('Secure image used with non-filestack src', src);
      setSecureUrl(src);
    }
  }, [src]);

  const addDefaultImageSource = (event: any) => {
    if (fallbackSrc) {
      event.target.src = fallbackSrc;
    }
  };

  if (error && !noError) {
    if (small) {
      return (
        <div className={'my-2'}>
          <FontAwesomeIcon icon="lock" className={'pr-1'} />
          <span>Unable to Display Secure Image</span>
        </div>
      );
    }
    return (
      <NonIdealState
        className="my-2"
        icon="lock"
        description="Unable to Display Secure Image"
      />
    );
  }
  if (!secureUrl && !noLoading) {
    return <Spinner className="my-2" />;
  }
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      onError={addDefaultImageSource}
      src={secureUrl || fallbackSrc}
      {...props}
    />
  );
};

export default SecureImage;
