import trackEvent from '../trackEvent';

export const importClicked = (traits = {}) => {
  trackEvent('Bulk Import - Import Clicked', traits);
};

export const importSummaryClicked = (traits = {}) => {
  trackEvent('Bulk Import - Import Summary Clicked', traits, true);
};

export const clearAllExecuted = (traits = {}) => {
  trackEvent('Bulk Import - Clear All Executed', traits, true);
};

export const clearAllClicked = (traits = {}) => {
  trackEvent('Bulk Import - Clear All Clicked', traits, true);
};

export const deleteRowClicked = (traits = {}) => {
  trackEvent('Bulk Import - Delete Row Clicked', traits, true);
};
export const deleteRowExecuted = (traits = {}) => {
  trackEvent('Bulk Import - Delete Row Executed', traits, true);
};

export const closeClicked = (traits = {}) => {
  trackEvent('Bulk Import - Close Clicked', traits, true);
};
