import { Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import ProfileTypeIcon from '../../ProfileTypeIcon';
import styles from './styles.module.css';

const DraggableHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

const ProfileTypeButton = ({
  name,
  description,
  selected,
  profileTypeDisabled,
  avatar,
  iconPack,
  color,
  parentType,
  deleted,
  onClick,
  onDoubleClick,
}) => {
  return (
    <div
      className={cn(styles.profileTypeContainer, {
        [styles.profileTypeDeleted]: deleted,
      })}
    >
      <Card
        elevation={1}
        className={cn(styles.profileTypeCard, {
          [styles.profileTypeCardSelected]: selected,
          [styles.profileTypeCardDisabled]: profileTypeDisabled,
        })}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <div
          className={styles.profileTypeColour}
          style={{ backgroundColor: color }}
        />
        <div
          className={cn(styles.profileTypeContent, {
            [styles.profileTypeContentDisabled]: profileTypeDisabled,
          })}
        >
          <ProfileTypeIcon
            avatarKey={avatar}
            width="80px"
            iconPack={iconPack}
          />
          <div className={styles.profileTypeText}>
            <p className={styles.profileTypeHeading}>{name}</p>
            {parentType && (
              <p className={styles.parentTypeHeading}>
                part of{' '}
                <span className={styles.parentTypeName}>{parentType}</span>
              </p>
            )}
            {description && <p className={styles.description}>{description}</p>}
          </div>
          <DraggableHandle />
        </div>
      </Card>
    </div>
  );
};

export default ProfileTypeButton;
