import { Button, Classes, Drawer, Intent } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { useDrawerSize } from '@hogwarts/ui-components-core';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldSelection } from '../components';
import { OrganisationContext } from '../context';
import { AppToaster } from '../utils/toaster';
import { usePermission } from './usePermission';

interface Section {
  key: string;
  label: string;
  fields: {
    key: string;
    label: string;
    locked: boolean;
    visible: boolean;
  }[];
}
interface TurnOffReadOnlyProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (changes: Record<string, any>) => Promise<void>;
  sections: Section[];
}
const TurnOffReadOnly = ({
  isOpen,
  onClose,
  onSave,
  sections,
}: TurnOffReadOnlyProps) => {
  const { t } = useTranslation();
  const [changes, setChanges] = useState<Record<string, any>>({});
  const [saving, setSaving] = useState(false);
  const onClose2 = () => {
    setChanges({});
    setSaving(false);
    onClose();
  };
  const dirty = Object.keys(changes).length > 0;
  const size = useDrawerSize();
  return (
    <Drawer
      enforceFocus={false}
      canEscapeKeyClose={!dirty}
      canOutsideClickClose={!dirty}
      isCloseButtonShown={!dirty}
      title={t('Remove Read-only')}
      size={size}
      isOpen={isOpen}
      onClose={onClose2}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <FieldSelection
            sections={sections}
            onChange={setChanges}
            emptyText={t('There are no Read Only fields to turn off')}
          />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button
          onClick={async () => {
            setSaving(true);
            try {
              await onSave(changes);
            } finally {
              setSaving(false);
              onClose2();
            }
          }}
          className="mr-2"
          intent={Intent.PRIMARY}
          disabled={saving || !dirty}
          large
        >
          {t('Save')}
        </Button>
        {dirty && (
          <Button onClick={onClose2} intent={Intent.DANGER} large>
            {t('Cancel')}
          </Button>
        )}
        {!dirty && (
          <Button onClick={onClose2} large>
            {t('Close')}
          </Button>
        )}
      </div>
    </Drawer>
  );
};

interface FieldMeta {
  readOnly?: boolean;
}
interface Profile {
  id: string;
  typeKey: string;
  meta?: Record<string, FieldMeta>;
}

interface Changes {
  profileId: string;
  meta: Record<string, { readOnly?: boolean }>;
}

export const useTurnOffReadOnly = (
  onSave: (changes: Changes[]) => Promise<void>
): [
  (profiles: Profile[] | Profile, sectionKey?: string) => void,
  { Component: typeof TurnOffReadOnly; props: TurnOffReadOnlyProps },
  boolean
] => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  const [state, setState] = useState<{
    sections?: Section[];
    profiles?: Profile[];
  }>({});

  const allowHideChecks = usePermission(
    permissions.PROFILE_UPDATE_FIELD_META_READONLY,
    organisation.id
  );

  const showDialog = useCallback(
    (profiles: Profile[] | Profile, sectionKey?: string) => {
      if (!allowHideChecks) return;

      let organisationScheme = organisation.scheme;
      if (!Array.isArray(profiles)) {
        organisationScheme = organisationScheme.switchProfileType(
          profiles.typeKey
        );
        profiles = [profiles];
      }

      let fields: Record<
        string,
        {
          key: string;
          field: unknown;
          section: unknown;
          readOnly: number;
        }
      > = {};

      for (const profile of profiles) {
        const profileTypeScheme = organisationScheme.switchProfileType(
          profile.typeKey
        );
        // const profileScheme = profileTypeScheme.applyProfileMeta(
        //   profile.typeKey,
        //   profile.meta
        // );
        for (const section of profileTypeScheme.sections) {
          if (!section.enabled) continue;
          if (sectionKey && section.key !== sectionKey) continue;

          for (const field of section.fields) {
            if (!field.enabled) {
              continue;
            } else if (field.dataType === 'none') {
              continue;
            } else if (field.inputType === 'hidden') {
              continue;
            }

            // We dont check locked because we're already looking at the parent of the
            // profile scheme
            // const locked = field.lock?.enabled;
            const readOnly = !!profile.meta?.[field.key]?.readOnly;

            let fieldResult = fields[field.key];
            if (!fieldResult) {
              fieldResult = fields[field.key] = {
                key: field.key,
                field,
                section,
                readOnly: 0,
              };
            }

            if (readOnly) {
              fieldResult.readOnly++;
            }
          }
        }
      }

      const sections: Section[] = organisationScheme.sections
        .map((section) => {
          return {
            key: section.key,
            label: section.label,
            fields: section.fields
              .filter((f) => fields[f.key] && fields[f.key].readOnly)
              .map((field) => {
                const result = fields[field.key];
                return {
                  key: field.key,
                  label: field.label,
                  locked: false,
                  visible: result.readOnly > 0,
                };
              })
              .filter(Boolean),
          };
        })
        .filter((s) => s.fields.length > 0);

      setState({
        profiles,
        sections,
      });
    },
    [allowHideChecks, organisation.scheme]
  );

  return [
    showDialog,
    {
      Component: TurnOffReadOnly,
      props: {
        isOpen: state.sections != null,
        onSave: async (changes: Record<string, boolean>) => {
          const updates: Changes[] = [];
          if (Array.isArray(state.profiles)) {
            for (const profile of state.profiles) {
              updates.push({
                profileId: profile.id,
                meta: Object.keys(changes).reduce(
                  (prev, fieldKey) => ({
                    ...prev,
                    [fieldKey]: {
                      // Important: Null will delete the value rather than overriding it
                      readOnly: changes[fieldKey] === true ? null : false,
                    },
                  }),
                  {}
                ),
              });
            }
          }

          if (onSave) {
            await onSave(updates);
          }

          setState({});

          AppToaster.show({
            message: t('Profiles successfully updated'),
            intent: 'success',
            icon: 'tick',
          });
        },
        sections: state.sections!,
        onClose: () => {
          setState({});
        },
      },
    },
    allowHideChecks,
  ];
};
