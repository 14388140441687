import { Organisation } from '../types';
import { organisationRoutes } from './organisationRoutes';
import { settingsRoutes } from './settingsRoutes';

export { settingsRoutes, organisationRoutes };

export const routesFactory = (organisation: Organisation) =>
  [...settingsRoutes(organisation), ...organisationRoutes(organisation)].filter(
    (route) => {
      if (typeof route.visible === 'function') {
        return route.visible();
      }
      return true;
    }
  );
