import CombinedScoresChartWidget from '@/components/Dashboards/Widgets/CombinedScoresChart';
import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '@/context';
import { useFeature, useQuery } from '@/hooks';
import { isFeatureEnabled } from '@/hooks/useFeature';
import { DATASOURCE_QUERY_COMBINEDPROFILESCORES } from '@/queries';
import { useForms } from '@hogwarts/ui-components-forms';
import qs from 'qs';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const WIDGET_FEATUREKEY = 'widgets.combinedscoreschart';

const CombinedScoresChart = ({ settings, onSettingsUpdate, ...rest }) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const isRestricted = !useFeature(WIDGET_FEATUREKEY);
  const { loading, data, error, refetch } = useQuery(
    DATASOURCE_QUERY_COMBINEDPROFILESCORES,
    {
      selector: 'queryCombinedProfileScores.result',
      variables: {
        organisationKey: organisation.key,
        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),
        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((profileTypeKey) =>
            settings.includedProfileTypes[profileTypeKey]
              ? profileTypeKey
              : null
          )
          .filter(Boolean),
      },
      pollInterval: organisation.demo ? 20000 : 60000,
    }
  );
  const { ratingSystems, profileTypes } = organisation.scheme;
  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: async (values) => {
      await onSettingsUpdate(values);
      refetch();
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        showSelectAll: true,
        selectionMinimum: 1,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },
    ],
    initialValues: settings,
  });

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      unavailable={isRestricted}
      message={
        isRestricted && 'This widget is not enabled for your Organisation.'
      }
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      <CombinedScoresChartWidget
        profileTypes={profileTypes.filter((profileType) => {
          return (
            !settings.includedProfileTypes ||
            settings.includedProfileTypes[profileType.key]
          );
        })}
        ratingSystems={ratingSystems}
        scores={data}
        onProfileClick={({ profileTypeKey, ratings }) => {
          analytics.events.dashboard.widget.combinedScoresChart.profileTypeSelected(
            {
              type: profileTypeKey,
              ratings,
            }
          );
          const queryFields = {
            profileTypes: profileTypeKey,
            tags: Object.keys(settings.includedTags).filter(
              (t) => !!settings.includedTags[t]
            ),
            ...Object.keys(ratings).reduce(
              (prev, key) => ({
                ...prev,
                [`ratingSystem_${key}`]: ratings[key],
              }),
              {}
            ),
          };
          const query = `/${organisation.key}/profiles${qs.stringify(
            queryFields,
            {
              addQueryPrefix: true,
              arrayFormat: 'repeat',
            }
          )}`;
          history.push(query);
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'combined-scores-chart',
  name: 'Combined Profile Scores Chart',
  description: 'Custom bar chart with scores combined into a single stack.',
  widgetComponent: CombinedScoresChart,
  defaultSettings: (scheme) => ({
    title: 'Combined Profile Scores',
    includedTags: {
      active: true,
      onboarding: true,
    },
    includedRatingSystems: scheme.ratingSystems.reduce((prev, curr) => {
      prev[curr.key] = true;
      return prev;
    }, {}),
    includedProfileTypes: null,
  }),
  restricted: (organisation) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
