import { useApolloClient } from '@apollo/client';
import { useForms } from '@hogwarts/ui-components-forms';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ProfilesOutstandingChecksList from '../../../components/Dashboards/Widgets/ProfilesOutstandingChecksList';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMemoizeArgs, useTransformProfiles } from '../../../hooks';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import { DATASOURCE_QUERY_PROFILES_WITH_EXTERNAL_CHECKS_WITHOUT_ORG } from '../../../queries';

const WIDGET_FEATUREKEY = 'widgets.outstandingchecksbyprofilelist';

const Container = ({
  settings: proposedSettings,
  onSettingsUpdate,
  ...rest
}) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const apollo = useApolloClient();

  const settings = useMemoizeArgs(proposedSettings);

  const transform = useTransformProfiles();

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },

      {
        type: 'separator',
      },

      ...organisation.scheme.ratingSystems.reduce((prev, rs) => {
        return [
          ...prev,
          {
            type: 'title',
            label: rs.label,
          },
          {
            key: `ratings.${rs.key}`,
            type: 'singleselect',
            validate: 'required',
            values: [
              {
                id: 'all',
                value: 'Show All',
              },
              {
                id: 'exclude',
                value: 'Show not ready',
              },
              {
                id: 'include',
                value: 'Show Ready',
              },
            ],
          },
        ];
      }, []),
    ],
    initialValues: {
      includeRatingSystems: {},
      ...settings,
    },
  });

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) return;
      const variables = {
        organisationKey: organisation.key,
        condition: settings.condition,

        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((tagKey) =>
            settings.includedProfileTypes[tagKey] ? tagKey : null
          )
          .filter(Boolean),

        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),

        duration: 'weeks:2',

        ratings: Object.keys(settings.ratings || {})
          .map((systemKey) => {
            switch (settings.ratings[systemKey]) {
              case 'exclude': {
                return {
                  key: systemKey,
                  include: false,
                };
              }
              case 'include': {
                return {
                  key: systemKey,
                  include: true,
                };
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean),
      };

      let profiles = await apollo.query({
        query: DATASOURCE_QUERY_PROFILES_WITH_EXTERNAL_CHECKS_WITHOUT_ORG,
        fetchPolicy: 'no-cache',
        variables: {
          ...variables,
          open: true,
          skip,
          limit,
        },
      });
      return transform(profiles?.data?.queryChecksByProfile?.result);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings]
  );

  return (
    <WidgetWrapper
      title={settings.title}
      // loading={loading}
      // error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      <ProfilesOutstandingChecksList
        fetchMore={fetchMore}
        iconPack={organisation.attributes?.avatarIconPack}
        onProfileSelected={(profileId, profile) => {
          analytics.events.dashboard.widget.expiredChecksByProfile.profileSelected();
          history.push(
            `/${
              profile.organisation?.key || organisation.key
            }/profiles/${profileId}/checks`
          );
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'outstanding-checks-by-profile',
  name: 'External Checks',
  description: 'Displays a list of profiles that have recent external checks.',
  widgetComponent: Container,
  defaultSettings: (scheme) => ({
    title: 'External Checks',
    condition: {
      conditions: [],
      operator: 'and',
      enabled: true,
    },

    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,

    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
  restricted: (organisation) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
