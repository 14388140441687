import { Callout } from '@blueprintjs/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface OverdueInvoiceBannerProps {
  organisation: {
    attributes?: {
      payment_overdue?: boolean;
    };
  };
}
export const OverdueInvoiceBanner = ({
  organisation,
}: OverdueInvoiceBannerProps) => {
  const { t } = useTranslation();

  const isVisible = useMemo(() => {
    return organisation?.attributes?.payment_overdue === true;
  }, [organisation]);

  if (!isVisible) return null;

  return (
    <Callout intent="warning" className="d-flex mb-2">
      <div>
        {t(
          'You have an overdue Invoice. To avoid any disruption to your service, please arrange payment immediately. Please contact us if you have any questions.'
        )}
      </div>
    </Callout>
  );
};
