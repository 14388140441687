import { FederationContext, OrganisationContext } from '@/context';
import { usePermission } from '@/hooks';
import permissions from '@hogwarts/permissions';
import { useForms } from '@hogwarts/ui-components-forms';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const useEditReportForm = ({ onUpdate, onDelete }) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const canAddOrganisationReports = usePermission(
    permissions.REPORT_CREATE,
    organisation?.id
  );
  const canAddFederationReports = usePermission(
    permissions.REPORT_CREATE,
    federation?.id
  );
  const getOwnerOptions = () => {
    const options = [
      { id: 'USER', value: t('Only Me') },
      {
        id: 'ORGANISATION',
        value: organisation.name,
        disabled: !canAddOrganisationReports,
      },
    ];
    if (federation) {
      options.push({
        id: 'FEDERATION',
        value: federation.name,
        disabled: !canAddFederationReports,
      });
    }
    return options;
  };
  return useForms({
    title: t('Edit Report'),
    allowCancel: false,
    initialValues: {
      ownerType: 'USER',
    },
    fields: [
      {
        key: 'name',
        type: 'textbox',
        label: t('Name'),
        validate: 'required',
        maxLength: 40,
      },
      {
        key: 'description',
        type: 'textarea',
        label: t('Description'),
        maxLength: 120,
      },

      {
        key: 'ownerType',
        type: 'singleselect',
        label: t('Shared with'),
        values: getOwnerOptions(),
        validate: 'required',
        help: {
          type: 'info',
          description: t(
            'Reports can be visible to users or organisations. Sharing your report will make it available for anyone else inside your organisation to view. They may need extra permissions to edit or delete them though.'
          ),
        },
      },

      {
        type: 'separator',
      },
      ...((federation?.hasSharedTeam &&
        federation?.canReadShared && [
          {
            type: 'title',
            label: t('Federation'),
          },
          {
            key: 'options.includeShared',
            type: 'checkbox',
            label: t('Include Central Team Profiles'),
            maxLength: 120,
            large: false,
          },
          {
            type: 'separator',
          },
        ]) ||
        []),
      {
        type: 'title',
        label: t('Excel Export'),
      },
      {
        key: 'options.excel.notes',
        large: false,
        type: 'checkbox',
        label: t('Include Errors and Warnings as Notes'),
      },
      {
        key: 'options.excel.singleTab',
        large: false,
        type: 'checkbox',
        label: t('Export All Profiles onto a Single Tab'),
      },
      {
        key: 'options.excel.includeSummary',
        large: false,
        type: 'checkbox',
        label: t('Include Summary Page'),
      },
      {
        key: 'options.excel.includeHeaders',
        large: false,
        type: 'checkbox',
        label: t('Include Section Header Row'),
      },
    ],
    successToastMessage: t('Report Updated'),
    onSave: async (values) => {
      let ownerId;
      switch (values.ownerType) {
        case 'ORGANISATION': {
          ownerId = organisation.id;
          break;
        }
        case 'FEDERATION': {
          ownerId = federation.id;
          break;
        }
        default: {
          break;
        }
      }
      // analytics.track(analytics.trackingKeys.reports.REPORT_UPDATED);
      await onUpdate({
        ...values,
        ownerId,
      });
    },
    allowDelete: !!onDelete,
    onDelete: async () => {
      return onDelete();
    },
    // deleteText: section.deleted ? 'Restore' : 'Delete',
  });
};
