import {
  CellValueChangedEvent,
  GridOptions,
  RowNode,
} from '@ag-grid-community/core';
import permissions from '@hogwarts/permissions';
import {
  ApiParams,
  ContextMenuItems,
  FilterOverride,
  GridApi,
} from '@hogwarts/ui-components-grid';
import { keys } from '@hogwarts/utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridView, PageHeader, TimezoneWarning } from '../../../components';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useAlert,
  useHideChecks,
  useMergeProfiles,
  useMutation,
  usePermission,
} from '../../../hooks';
import { useTurnOffReadOnly } from '../../../hooks/useTurnOffReadOnly';
import {
  BATCH_PROFILE_UPDATE_MUTATION,
  BATCH_PROFILE_UPDATE_MUTATION_Response,
  CHANGE_PROFILE_TYPE_MUTATION,
  DELETE_MULTIPLE_PROFILES_MUTATION,
  DELETE_MULTIPLE_PROFILES_MUTATION_Response,
  DELETE_PROFILE_MUTATION,
  MERGE_PROFILES_MUTATION,
  ProfileResponse,
  UPDATE_PROFILE_DATA_MUTATION,
} from '../../../mutations';
import { Organisation } from '../../../types';
import {
  editingGridOptions,
  getAllColumns,
  useDataSourceFactory,
} from '../../profilesGridUtils';
import ProfilesFilterBar from '../filterBar';
import styles from './styles.module.css';

interface ProfileChange {
  profileId: string;
}

const gridOptions: GridOptions = {
  ...editingGridOptions,
  processCellForClipboard: ({ column, value }) => {
    const colDef = column.getColDef();
    if (colDef.field === 'typeKey') {
      return value?.key;
    }
    return value;
  },
};

const useDeleteProfile = (organisation: Organisation) => {
  return useMutation(DELETE_PROFILE_MUTATION, {
    variables: {
      organisationKey: organisation.key,
      id: '',
    },
  });
};

const useDeleteMultipleProfiles = (organisation: Organisation) => {
  return useMutation<DELETE_MULTIPLE_PROFILES_MUTATION_Response>(
    DELETE_MULTIPLE_PROFILES_MUTATION,
    {
      variables: {
        organisationKey: organisation.key,
      },
    }
  );
};

const updateGridProfile = (
  organisation: Organisation,
  updatedProfile: ProfileResponse,
  api: GridApi
) => {
  // This updates the entire row in grid with the latest profile data
  // First it has to build the scheme up though, note that it uses
  // the existing scheme, we assume that it doesnt change in between edits
  var rowNode = api.getRowNode(updatedProfile.id);
  // If the row doesnt exist in the grid, we just skip it
  if (rowNode) {
    const [profileScheme, , profileRating] = organisation.scheme
      .applyProfileMeta(updatedProfile.typeKey, updatedProfile.meta)
      .applyProfile(updatedProfile, organisation.timezone);

    // const profileRatingKeyed = keys(profileRating, {
    //   outputKeyName: 'systemKey',
    // });

    const tags = updatedProfile.tags
      .map((tagKey) => {
        const tag = profileScheme.getTag(tagKey);
        if (!tag) return null;
        const { key, label, order, color } = tag;
        // TODO: If not enabled?
        return {
          key,
          label,
          order,
          color,
        };
      })
      .filter(Boolean);

    const ratings = keys(profileRating)
      .map((rating) => {
        const ratingScheme = profileScheme.getRatingSystem(rating.key);
        if (!ratingScheme) return null;
        // TODO: If not enabled?
        return {
          key: rating.key,
          label: ratingScheme.label,
          readyColor: ratingScheme.readyColor,
          notReadyColor: ratingScheme.notReadyColor,
          score: rating.score,
          ready: rating.ready,
        };
      })
      .filter(Boolean);

    rowNode.setData({
      ...updatedProfile,
      tags,
      ratings,
      scheme: profileScheme,
    });
  }
  return rowNode;
};

const useUpdateProfileType = (
  organisation: Organisation,
  gridApi?: GridApi
) => {
  return useMutation(CHANGE_PROFILE_TYPE_MUTATION, {
    selector: 'changeProfileType',
    onCompleted: (profile) => {
      if (!gridApi) return;
      const rowNode = updateGridProfile(organisation, profile, gridApi);
      if (rowNode) {
        gridApi?.refreshCells({
          rowNodes: [rowNode],
          force: true,
        });
      }
    },
    onError: () => {},
  });
};

const useUpdateProfileData = (
  organisation: Organisation,
  gridApi?: GridApi
) => {
  return useMutation(UPDATE_PROFILE_DATA_MUTATION, {
    selector: 'updateProfileData',
    onCompleted: (profile) => {
      if (!gridApi) return;
      const rowNode = updateGridProfile(organisation, profile, gridApi);
      if (rowNode) {
        gridApi?.refreshCells({
          rowNodes: [rowNode],
          force: true,
        });
      }
    },
    onError: () => {},
  });
};

const useBatchProfileUpdate = (
  organisation: Organisation,
  gridApi?: GridApi
) => {
  return useMutation<BATCH_PROFILE_UPDATE_MUTATION_Response>(
    BATCH_PROFILE_UPDATE_MUTATION,
    {
      selector: 'batchProfileUpdate',
      onCompleted: (profiles) => {
        if (!gridApi) return;
        let rowNodes: RowNode[] = [];
        for (const profile of profiles) {
          const node = updateGridProfile(organisation, profile, gridApi);
          if (node) {
            rowNodes.push(node);
          }
        }
        if (rowNodes.length) {
          gridApi?.refreshCells({
            rowNodes,
            force: true,
          });
        }
      },
      onError: () => {},
    }
  );
};

const ProfilesGridEditor = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const [groups, fields] = useMemo(
    () => getAllColumns(organisation.scheme),
    [organisation]
  );
  const [condition, setCondition] = useState<FilterOverride>();
  const [{ api: gridApi }, setGridApi] = useState<Partial<ApiParams>>({});
  const [
    updateProfileData,
    { loading: updateProfileDataLoading, error: updateProfileDataError },
  ] = useUpdateProfileData(organisation, gridApi);

  const [
    batchProfileUpdate,
    { loading: batchProfileUpdateLoading, error: batchProfileUpdateError },
  ] = useBatchProfileUpdate(organisation, gridApi);

  const [
    updateProfileType,
    { loading: updateProfileTypeLoading, error: updateProfileTypeError },
  ] = useUpdateProfileType(organisation, gridApi);

  // TODO: Can we capture if these are in progress?
  // Maybe show something useful?

  const [deleteProfile] = useDeleteProfile(organisation);
  const [deleteMultiProfiles] = useDeleteMultipleProfiles(organisation);

  const [showReadOnlyEdit, ReadOnlyEdit, allowReadOnlyEdit] =
    useTurnOffReadOnly(async (updates) => {
      await batchProfileUpdate({
        variables: {
          organisationKey: organisation.key,
          updates,
        },
      });
    });

  const [showHideChecks, HideChecks, allowHideChecks] = useHideChecks(
    async (updates) => {
      await batchProfileUpdate({
        variables: {
          organisationKey: organisation.key,
          updates,
        },
      });
    }
  );

  const allowProfileEdit = usePermission(
    permissions.PROFILE_UPDATE,
    organisation.id
  );

  const [mergeProfiles] = useMutation(MERGE_PROFILES_MUTATION, {
    onCompleted: () => {
      gridApi?.refreshServerSideStore();
    },
  });

  const [showMergeProfiles, MergeProfiles, canMergeProfiles] = useMergeProfiles(
    async (leftProfile, rightProfile) => {
      await mergeProfiles({
        variables: {
          preserveProfileId: leftProfile.id,
          duplicateProfileId: rightProfile.id,
        },
      });
    }
  );

  const [DeleteProfileAlert, confirmDeleteProfile] = useAlert<{
    profileName: string;
    profileId: string;
    gridApi: GridApi;
  }>({
    onConfirm: async ({ profileId, gridApi }) => {
      if (profileId) {
        await deleteProfile({
          variables: {
            organisationKey: organisation.key,
            id: profileId,
          },
        });
        gridApi!.refreshServerSideStore();
      }
    },
    Content: ({ profileName }) => (
      <>
        <p>
          {t("Are you sure you want to delete {{profileName}}'s profile?", {
            profileName,
          })}
        </p>
        <p>
          {t(
            'You will not be able to restore it later, this cannot be undone.'
          )}
        </p>
      </>
    ),
  });

  const [DeleteMultipleProfileAlert, confirmMultiDeleteProfile] = useAlert<{
    profileCount: number;
    profileIds: string[];
    gridApi: GridApi;
  }>({
    onConfirm: async ({ profileIds, gridApi }) => {
      await deleteMultiProfiles({
        variables: {
          profileIds,
        },
      });
      gridApi!.refreshServerSideStore();
    },
    Content: ({ profileCount }) => (
      <>
        <p>
          {t('Are you sure you want to delete {{profileCount}} profiles?', {
            profileCount,
          })}
        </p>
        <p>
          {t(
            'You will not be able to restore these later, this cannot be undone.'
          )}
        </p>
      </>
    ),
  });

  const isSaving =
    updateProfileDataLoading ||
    batchProfileUpdateLoading ||
    updateProfileTypeLoading;
  const savedError =
    updateProfileDataError || batchProfileUpdateError || updateProfileTypeError;

  const onBatchCellValueChanged = async (
    allParams: CellValueChangedEvent[]
  ) => {
    let batch: Record<string, ProfileChange> = {};

    const trace: Record<string, any[]> = {};

    // group all the changes by the profile id
    for (const params of allParams) {
      const {
        data,
        // @ts-ignore
        colDef: { meta },
      } = params;
      const id = data.id;
      let newValue = params.newValue;
      if (typeof meta.formatForSave === 'function') {
        newValue = meta.formatForSave(newValue);
      }

      if (!trace[id]) {
        trace[id] = [];
      }
      trace[id].push({
        column: meta.key,
        oldValue: params.oldValue,
        newValue: params.newValue,
      });

      batch = {
        ...batch,
        [id]: {
          ...batch[id],
          [meta.key]: newValue,
        },
      };
    }

    // format it for the mutation
    const updates = Object.keys(batch).reduce<ProfileChange[]>(
      (prev, profileId) => [
        ...prev,
        {
          trace: {
            component: 'ProfilesGridEditor',
            event: 'onBatchCellValueChanged',
            detail: trace[profileId],
          },
          data: batch[profileId],
          profileId,
        },
      ],
      []
    );
    batchProfileUpdate({
      variables: {
        organisationKey: organisation.key,
        updates,
      },
    });
  };

  const onCellValueChanged = async (params: CellValueChangedEvent) => {
    // @ts-ignore
    const { meta } = params.colDef;
    if (!meta) return;
    const profile = params.data;
    if (meta.key === 'typeKey') {
      if (!params.newValue) return;
      updateProfileType({
        variables: {
          profileId: profile.id,
          profileTypeKey: params.newValue.key,
        },
      });
    } else if (meta.key) {
      let newValue = params.newValue;
      if (typeof newValue === 'undefined') {
        newValue = null;
      }
      updateProfileData({
        variables: {
          profileId: profile.id,
          data: {
            [meta.key]: newValue,
          },
          trace: [
            {
              component: 'ProfilesGridEditor',
              event: 'onCellValueChanged',
              detail: {
                column: meta.key,
                oldValue: params.oldValue,
                newValue: params.newValue,
              },
            },
          ],
        },
      });
    }
  };

  const contextMenuItems = useCallback(
    (nodes: RowNode[], gridApi: GridApi) => {
      return [
        nodes.length === 1 && {
          name: nodes[0].data.name,
          disabled: true,
        },
        nodes.length > 1 && {
          name: { message: 'Selected {{count}} rows', count: nodes.length },
          disabled: true,
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'separator',
        nodes.length === 1 && {
          name: 'View Profile',
          action: async () => {
            let profileId = nodes[0].data.id;
            if (profileId) {
              analytics.events.profilesGrid.viewProfileClicked();
              history.push(`/${organisation.key}/profiles/${profileId}`);
            }
          },
        },
        allowHideChecks && {
          name: 'Hide Checks',
          action: () => {
            analytics.events.profilesGrid.hideChecksClicked();
            showHideChecks(nodes.map((n) => n.data));
          },
        },
        allowReadOnlyEdit && {
          name: 'Remove Read-only',
          action: () => {
            analytics.events.profilesGrid.turnOffReadOnlyClicked();
            showReadOnlyEdit(nodes.map((n) => n.data));
          },
        },
        allowProfileEdit &&
          nodes.length === 1 && {
            name: 'Delete Profile',
            action: async () => {
              analytics.events.profilesGrid.deleteSingleProfileClicked();

              let profileId = nodes[0].data.id;

              await confirmDeleteProfile({
                profileId,
                profileName: nodes[0].data.data.name,
                gridApi,
              });
            },
          },
        allowProfileEdit &&
          nodes.length > 1 && {
            name: { message: 'Delete {{count}} profiles', count: nodes.length },
            action: async (nodes: RowNode[]) => {
              analytics.events.profilesGrid.deleteMultipleProfileClicked({
                count: nodes.length,
              });

              const profileIds = nodes.map((n) => n.data.id);

              await confirmMultiDeleteProfile({
                profileIds,
                profileCount: profileIds.length,
                gridApi,
              });
            },
          },
        canMergeProfiles &&
          nodes.length === 2 && {
            name: { message: 'Merge {{count}} profiles', count: nodes.length },
            action: async () => {
              analytics.events.profilesGrid.mergeProfilesClicked({
                count: nodes.length,
              });

              await showMergeProfiles(nodes.map((n) => n.data));
            },
          },
      ] as ContextMenuItems;
    },
    [
      allowHideChecks,
      allowReadOnlyEdit,
      allowProfileEdit,
      canMergeProfiles,
      analytics,
      history,
      organisation.key,
      showHideChecks,
      showReadOnlyEdit,
      confirmDeleteProfile,
      confirmMultiDeleteProfile,
      showMergeProfiles,
    ]
  );

  const onFilterHandler = useCallback((filters) => {
    const ratingFilter = [];
    for (const filterKey of Object.keys(filters)) {
      if (filterKey.includes('ratingSystem')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: filters[filterKey][0],
        });
      }
    }
    setCondition({
      profileTypes: filters.profileTypes ?? [],
      tags: filters.tags ?? [],
      ratings: ratingFilter,
      condition: null,
    });
  }, []);

  const dataSource = useDataSourceFactory();

  if (!fields) return null;

  return (
    <>
      <MergeProfiles.Component {...MergeProfiles.props} />
      <HideChecks.Component {...HideChecks.props} />

      <ReadOnlyEdit.Component {...ReadOnlyEdit.props} />
      <DeleteProfileAlert />
      <DeleteMultipleProfileAlert />
      <PageHeader
        noWrapper
        header={organisation && organisation.name}
        actions={[]}
        notificationChildren={<TimezoneWarning />}
      />
      <>
        <div className={styles.filterBar}>
          <ProfilesFilterBar onFilter={onFilterHandler} />
          {isSaving && <div>Saving...</div>}
          {savedError && <div>Error Saving.</div>}
        </div>
        <GridView
          dataSource={dataSource}
          allowEdit={!!allowProfileEdit}
          contextMenuItems={contextMenuItems}
          onGridReady={setGridApi}
          groups={groups}
          columns={fields}
          filter={condition}
          {...gridOptions}
          onBatchCellValueChanged={onBatchCellValueChanged}
          onCellValueChanged={onCellValueChanged}
        />
      </>
    </>
  );
};

export default ProfilesGridEditor;
