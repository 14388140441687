import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import styles from './styles.module.css';

const SortableListItems = SortableContainer(
  ({ items, extraItems, draggingId }) => {
    return (
      <div className={styles.profileTypesContainer}>
        {items &&
          items.map(({ component, ...props }, idx) => {
            const SortableComponent = SortableElement(component);
            return (
              <SortableComponent
                index={idx}
                dragActive={!!draggingId}
                {...props}
              />
            );
          })}
        {extraItems}
      </div>
    );
  }
);

const SortableList = ({
  header,
  items,
  extraItems,
  useDragHandle = true,
  onOrderUpdate,
}) => {
  const [draggingId, setDraggingId] = useState(null);

  return (
    <div className={styles.profileTypeListContainer}>
      {header}
      <SortableListItems
        items={items}
        extraItems={extraItems}
        axis="xy"
        useDragHandle={useDragHandle}
        draggingId={draggingId}
        onSortStart={({ index }) => {
          setDraggingId(index);
        }}
        onSortEnd={({ oldIndex, newIndex }) => {
          setDraggingId(null);
          onOrderUpdate({ oldIndex, newIndex });
        }}
      />
    </div>
  );
};

export default SortableList;
