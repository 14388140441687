import React, { useContext } from 'react';
import { DATASOURCE_QUERY_PROFILES } from '@/queries';
import { OrganisationContext } from '@/context';

import { useQuery } from '@/hooks';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import { Filters } from './Filters';
import { ProfilesList } from './ProfilesList';
import { Loading, Error } from '@hogwarts/ui-components-core';
import { PageHeader } from '@/components';

const IncompleteProfiles = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  let [selectedProfileType] = useQueryParam('profileType', StringParam);
  let [selectedRatingSystem] = useQueryParam('ratingSystem', StringParam);

  const showAllProfileTypes =
    selectedProfileType == null || selectedProfileType === 'all';

  // can we paginate on the profiles here?
  // we need active and onboarder profiles that arent 100%
  // filtered by profile type (or skip if All selected)

  // can this be some sort of report magic?

  // i think the user would love to populate the section here if that
  // were in some way possible as well.

  let {
    data: profiles,
    loading,
    error,
  } = useQuery(DATASOURCE_QUERY_PROFILES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationKey: organisation.key,
      tags: ['active', 'onboarding'],
      profileTypes: showAllProfileTypes ? null : [selectedProfileType],
      sort: [{ field: 'firstname' }],
    },
    selector: 'queryProfiles.result',
    transform: (profiles) => {
      // TODO: This is lifted from DataSourceFactory
      return profiles.map((profile) => {
        const [profileScheme1, validity] = organisation.scheme
          .applyProfileMeta(profile.typeKey, profile.meta)
          .applyProfile(profile, organisation.timezone);
        return {
          ...profile,
          ...validity,
          originalScheme: profile.scheme,
          scheme: profileScheme1,
        };
      });
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  let results = [];
  for (const profile of profiles) {
    const { scheme } = profile;
    if (!scheme) {
      continue;
    }

    for (const section of scheme.sections) {
      if (!section.visible) continue;
      for (const field of section.fields) {
        if (!field.visible) continue;

        const validReasons = field.reasons
          ? field.reasons.filter((r) => r.failRatings || r.failInput)
          : [];

        if (Object.keys(field.ratings || {}).length && validReasons.length) {
          if (selectedRatingSystem != null) {
            if (!field.ratings[selectedRatingSystem].enabled) {
              continue;
            }
          }

          results.push({
            profile,
            section,
            field,
          });
        }
      }
    }
  }

  return (
    <div>
      <PageHeader noWrapper={false} />

      <Filters
        profileTypes={organisation.scheme.profileTypes}
        ratingSystems={organisation.scheme.ratingSystems}
      />

      {results.length === 0 ? (
        <div className={'mt-2'}>{t('No Incomplete Profiles Found')}</div>
      ) : (
        <ProfilesList
          profiles={results}
          profileType={selectedProfileType}
          ratingSystem={selectedRatingSystem}
        />
      )}
    </div>
  );
};

export default IncompleteProfiles;
