import React from 'react';

import { FieldMapList } from '@/components/FieldMapping';
import uuid from 'uuid/v4';
import { Button } from '@blueprintjs/core';

const RenderFieldMap = ({
  formField,
  readOnly,
  fieldHelpers,
  sourceFields,
  targetFields,
  targetGroupKey,
  targetGroups,
  readOnlyDefault,
}) => {
  return (
    <>
      <FieldMapList
        items={formField.value}
        sourceFields={sourceFields}
        readOnly={readOnly}
        targetFields={targetFields}
        targetGroupKey={targetGroupKey}
        targetGroups={targetGroups}
        onChanged={async (fieldMap) => {
          await fieldHelpers.setTouched(true, false);
          await fieldHelpers.setValue(fieldMap, true);
        }}
      />

      <Button
        className="mt-3"
        icon="plus"
        onClick={async () => {
          await fieldHelpers.setTouched(true, false);
          await fieldHelpers.setValue(
            [
              ...formField.value,
              {
                id: uuid(),
                sourceField: null,
                targetField: null,
                readOnly: readOnlyDefault,
              },
            ],
            true
          );
        }}
      >
        Add New Mapping
      </Button>
    </>
  );
};

export default {
  name: 'FieldMap',
  options: [],
  Component: RenderFieldMap,
};
