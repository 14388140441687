import { Callout, Checkbox } from '@blueprintjs/core';
import { Button, Currency } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';

import { currency } from '@hogwarts/utils';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styles from './styles.module.css';

interface SelectedChecks {
  [key: string]: boolean;
}
interface CheckType {
  key: string;
  name: string;
  price: {
    value: number;
    currency: string;
  };
  exclusiveGroup?: string;
  description: string;
}

interface MarkdownProps {
  description: string;
}
const Markdown = ({ description }: MarkdownProps) => {
  return (
    <ReactMarkdown
      linkTarget={'_blank'}
      components={{
        a: ({ node, children, ...props }) => {
          const linkProps = props;
          if (props.target === '_blank') {
            linkProps['rel'] = 'noopener noreferrer';
          }
          return <a {...linkProps}>{children}</a>;
        },
      }}
    >
      {description}
    </ReactMarkdown>
  );
};

interface DbsCheckProps {
  allowChange: boolean;
  checkType: CheckType;
  onSelectCheckType: () => void;
}
const DbsCheck = ({
  checkType,
  onSelectCheckType,
  allowChange,
}: DbsCheckProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Markdown description={checkType.description} />
      {allowChange && (
        <Button intent={'none'} onClick={() => onSelectCheckType()}>
          {t('Change DBS Check Type')}
        </Button>
      )}
    </div>
  );
};

interface CheckTypeContentProps extends DbsCheckProps {
  checkType: CheckType;
}
const CheckTypeContent = ({ checkType, ...props }: CheckTypeContentProps) => {
  switch (checkType.exclusiveGroup) {
    case 'dbs': {
      return <DbsCheck checkType={checkType} {...props} allowChange />;
    }
    default: {
      return <Markdown description={checkType.description} />;
    }
  }
};

interface ExternalCheckSelectorProps {
  checkTypes: CheckType[];
  selected?: SelectedChecks;
  allowDbsChange?: boolean;
  onChange: (selected: SelectedChecks) => void;
  onSelectDbsCheckType: () => void;
}
export const ExternalCheckSelector = ({
  checkTypes,
  selected: initialSelected,
  allowDbsChange,
  onChange,
  onSelectDbsCheckType,
}: ExternalCheckSelectorProps) => {
  const [selected, setSelected] = useState<SelectedChecks>(
    initialSelected || {}
  );
  const totalPrice = checkTypes.reduce((prev, checkType) => {
    if (selected[checkType.key]) {
      return currency.add(prev, checkType.price);
    }
    return prev;
  }, currency.ZERO);

  return (
    <div>
      {checkTypes.map((checkType) => (
        <Callout className="mb-2" key={checkType.key}>
          <Checkbox
            className={cn('d-flex flex-grow-1 align-items-center')}
            checked={!!selected[checkType.key]}
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              const checked = target.checked;

              let newSelected;
              if (checked && checkType.exclusiveGroup) {
                // Remove the others from selection
                newSelected = {
                  ...selected,
                  ...checkTypes
                    .filter(
                      (ct) => ct.exclusiveGroup === checkType.exclusiveGroup
                    )
                    .reduce(
                      (prev, checkType) => ({
                        ...prev,
                        [checkType.key]: false,
                      }),
                      {}
                    ),
                  [checkType.key]: checked,
                };
              } else {
                newSelected = {
                  ...selected,
                  [checkType.key]: checked,
                };
              }

              setSelected(newSelected);
              if (onChange) {
                onChange(newSelected);
              }
            }}
          >
            <span
              className={cn(
                'flex-grow-1 font-weight-bold',
                styles.checkboxItem
              )}
            >
              {checkType.name}
            </span>
            <Currency {...checkType.price} className={styles.checkboxItem} />
          </Checkbox>

          <div className={cn('flex-row', styles.block)}>
            <CheckTypeContent
              checkType={checkType}
              onSelectCheckType={onSelectDbsCheckType}
              allowChange={!!allowDbsChange}
            />
          </div>
        </Callout>
      ))}
      <div>
        {`Total (ex VAT): `}
        <Currency {...totalPrice} />
      </div>
    </div>
  );
};
