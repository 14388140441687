import permissions from '@hogwarts/permissions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PreferenceConfig,
  PreferencesPanel,
} from '../../components/Settings/PreferencesPanel';
import { SettingsPanel } from '../../components/Settings/SettingsPanel';
import { OrganisationContext } from '../../context';
import {
  useFeature,
  useFederationFeature,
  useMutation,
  usePermission,
} from '../../hooks';
import { GET_ORGANISATION_DETAIL } from '../../queries';
import { TOGGLE_FEATURE } from '../../queries/features';

export const OrganisationUsersPreferences = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const orgFeatureControlPermission = usePermission(
    permissions.FEATURE_CONTROL,
    organisation.id
  );
  const orgEnabledEnforceMFA = useFeature('security.enforcemfa');
  const fedEnabledEnforceMFA = useFederationFeature('security.enforcemfa');

  const [setOrgFeature] = useMutation(TOGGLE_FEATURE, {
    selector: 'toggleFeature',
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const settings: PreferenceConfig[] = [
    {
      key: 'toggleEnforceMFA',
      label: t('Enforce MFA'),
      description: t(
        'Require users enable MFA for their accounts to access this organisation.'
      ),
      value: fedEnabledEnforceMFA || orgEnabledEnforceMFA,
      disabled: !orgFeatureControlPermission || fedEnabledEnforceMFA,
      onUpdate: () => {
        setOrgFeature({
          variables: {
            ownerType: 'ORGANISATION',
            ownerId: organisation.id,
            key: 'security.enforcemfa',
            enabled: !orgEnabledEnforceMFA,
          },
        });
      },
    },
  ];

  return (
    <SettingsPanel title={t('Preferences')}>
      <PreferencesPanel settings={settings} disabled={false} />
    </SettingsPanel>
  );
};
