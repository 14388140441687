import {
  Button as ButtonBP,
  Callout,
  Checkbox,
  Divider,
  InputGroup,
  Label,
  NonIdealState,
} from '@blueprintjs/core';
import { Button, Loading } from '@hogwarts/ui-components-core';
import { arraySame } from '@hogwarts/utils';
import cn from 'classnames';
import { set } from 'lodash';
import { set as setFp } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';
import Toggle from 'react-toggle';
import { useDateFormatter } from '../../hooks';
import { DocumentSelection } from './DocumentSelection';
import { EditableItem } from './Editors';
import {
  Candidate,
  candidateCurrentAddress,
  candidateDateOfBirth,
  candidateFieldInfo,
  candidateName,
  documentFieldMap,
  DocumentItem,
  getCandidateDisplayValue,
  getCandidateValue,
  previousAddress,
  previousName,
  sensibleCase,
} from './Helpers';

interface ItemConfirmProps {
  className?: string;
  label: string;
  value?: string;
  light?: boolean;
  children?: React.ReactNode;
  onCheck?: (value: boolean) => void;
  checked?: boolean;
}
const ItemConfirm = ({
  className,
  label,
  value,
  light,
  onCheck,
  checked,
  children,
}: ItemConfirmProps) => {
  return (
    <div className={cn('d-flex flex-column', className)}>
      <div
        className={cn('mb-2', light ? 'text-secondary' : 'font-weight-bold')}
      >
        {label}
      </div>
      <div className="d-flex flex-row">
        <div className="ml-2 flex-grow-1">{children || value}</div>
        {onCheck && (
          <div className="ml-2">
            <Checkbox
              style={{ marginBottom: 0, marginTop: '4px' }}
              checked={!!checked}
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                const checked = target.checked;
                onCheck(checked);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface ConfirmationProps {
  checkListComplete: boolean;
  onConfirm: (args: {
    agreed: boolean;
    firstName: string;
    lastName: string;
  }) => void;
}
const Confirmation = ({ checkListComplete, onConfirm }: ConfirmationProps) => {
  const url = 'https://www.gov.uk/government/publications/dbs-code-of-practice';

  const [currentState, setCurrentState] = useState({
    agreed: false,
    firstName: '',
    lastName: '',
  });

  const isAllowed =
    checkListComplete &&
    currentState.agreed &&
    currentState.firstName?.length > 1 &&
    currentState.lastName?.length > 1;

  return (
    <div>
      <div className="font-weight-bold my-2">Confirmation</div>
      <div className="mb-2">
        I hereby confirm that the candidate's identity has been verified in line
        with the DBS Code of Practice.
      </div>
      <div className="mb-2">
        <a href={url} target="blank">
          {url}
        </a>
      </div>
      <Toggle
        className="mb-2"
        onChange={(event: any) => {
          const agreed = !!event.target.checked;
          setCurrentState({
            ...currentState,
            agreed,
          });
        }}
        checked={currentState.agreed}
        icons={true}
      />
      <Label
        style={{
          marginBottom: '5px',
        }}
      >
        Identity Checked By
      </Label>
      <InputGroup
        className="mb-2"
        onChange={(event) => {
          const e = event as React.ChangeEvent<HTMLInputElement>;
          let value = e.target.value;
          if (value !== null) {
            setCurrentState({
              ...currentState,
              firstName: value,
            });
          }
        }}
        placeholder={'First Name'}
        defaultValue={currentState.firstName}
        type="text"
      />
      <InputGroup
        className="mb-2"
        onChange={(event) => {
          const e = event as React.ChangeEvent<HTMLInputElement>;
          let value = e.target.value;
          if (value !== null) {
            setCurrentState({
              ...currentState,
              lastName: value,
            });
          }
        }}
        placeholder={'Last Name'}
        defaultValue={currentState.lastName}
        type="text"
      />

      {/*
        this is only enabled if we have agreed, there is a 
        first+last name and all the ticks are ticked
        at which point we fire off  
      */}

      {/* @ts-ignore */}
      <Button
        intent="primary"
        disabled={!isAllowed}
        large
        onClick={() => onConfirm(currentState)}
      >
        Confirm Identity
      </Button>
    </div>
  );
};

interface DocumentDetailsProps {
  candidate: Candidate;
  document: string;
  documentType?: DocumentItem;
  onCandidateUpdate: (field: string, value: string) => void;
  onChecked: (field: string, checked: boolean) => void;
  checkList: Record<string, boolean>;
}
const DocumentDetails = ({
  candidate,
  document,
  documentType,
  checkList,
  onCandidateUpdate,
  onChecked,
}: DocumentDetailsProps) => {
  // need the document label
  //
  const dateFormatter = useDateFormatter();

  const fields = documentFieldMap[document];

  // if no fields, just list it out
  // otherwise, list each field value with a checkbox

  if (!documentType) {
    return (
      <Callout key={document} className="mb-1 font-weight-bold" intent="danger">
        Unknown Document ({document})
      </Callout>
    );
  }

  return (
    <Callout key={document} className="mb-1">
      <div className="d-flex flex-column">
        {fields?.length ? (
          <>
            <div className="font-weight-bold mb-2">
              {documentType?.description || document}
            </div>
            {fields.map((field) => (
              <ItemConfirm
                key={field}
                className="ml-2"
                light
                label={sensibleCase(field)}
                onCheck={(checked) => onChecked(field, checked)}
                checked={checkList[field]}
              >
                <EditableItem
                  field={field}
                  value={getCandidateValue(candidate, field)}
                  displayValue={getCandidateDisplayValue(
                    candidate,
                    field,
                    dateFormatter.medium
                  )}
                  onSave={(value) => onCandidateUpdate(field, value)}
                />
              </ItemConfirm>
            ))}
          </>
        ) : (
          <div className="font-weight-bold mb-2">
            {documentType?.description || document}
          </div>
        )}
      </div>
    </Callout>
  );
};

const isCheckListComplete = (
  documents: string[],
  documentCheckList: Record<string, boolean>
): boolean => {
  for (const document of documents) {
    const fields = documentFieldMap[document];
    if (!fields) continue;
    for (const field of fields) {
      if (!documentCheckList[field]) {
        return false;
      }
    }
  }
  return true;
};

type CandidateIdentityProps = {
  candidate?: Candidate;
  documents?: string[];
  documentTypes?: DocumentItem[];
  validationErrors?: string[];
  onConfirmIdentity: (args: {
    firstName: string;
    lastName: string;
    documents: string[];
    candidate: any;
  }) => void;
};
export const CandidateIdentity = ({
  candidate: originalCandidate,
  documents: initialDocuments,
  documentTypes,
  onConfirmIdentity,
  validationErrors,
}: CandidateIdentityProps) => {
  // TODO: Would prefer this wasn't used directly in here.
  // Maybe the components could have a context for utils
  const dateFormatter = useDateFormatter();

  const [documents, setDocuments] = useState(initialDocuments);
  const [currentMode, setCurrentMode] = useState('VIEW');

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  const [candidateChanges, setCandidateChanges] = useState<
    Record<string, string>
  >({});

  const [checkList, setCheckList] = useState<Record<string, boolean>>({
    address: false,
    dateofbirth: false,
    name: false,
  });
  const [documentCheckList, setDocumentCheckList] = useState<
    Record<string, boolean>
  >({});

  const candidate = useMemo(() => {
    if (!originalCandidate) return null;
    let result = originalCandidate;
    for (const field of Object.keys(candidateChanges)) {
      const value = candidateChanges[field];
      const path = candidateFieldInfo[field].path;
      result = setFp(path, value, result);
    }
    return result;
  }, [candidateChanges, originalCandidate]);

  useEffect(() => {
    setDocumentCheckList({});
  }, [initialDocuments, documents]);

  useEffect(() => {
    setCheckList({
      address: false,
      dateofbirth: false,
      name: false,
    });
    setDocumentCheckList({});
  }, [originalCandidate]);

  if (!candidate) {
    return (
      <NonIdealState
        icon="user"
        title="Order Issue"
        description={
          <>
            <p>
              Apologies, there appears to be an issue with the order, no
              candidate information has been provided.
            </p>
            <p>
              Please contact support and quote the order number so we can get
              this resolved.
            </p>
          </>
        }
      />
    );
  }

  if (initialDocuments && initialDocuments.length === 0) {
    return (
      <NonIdealState
        icon="document"
        title="Order Document Issue"
        description={
          <>
            <p>
              Apologies, there appears to be an issue with the candidate order,
              no documents were specified.
            </p>
            <p>
              Please contact support and quote the order number so we can get
              this resolved.
            </p>
          </>
        }
      />
    );
  }

  if (!documentTypes?.length || !documents?.length) {
    return <Loading showLogo={false} />;
  }

  const anyUnChecked =
    Object.keys(checkList).filter((key) => checkList[key] === false).length > 0;

  const checkListComplete =
    anyUnChecked === false && isCheckListComplete(documents, documentCheckList);

  if (currentMode === 'DOCUMENT-SELECT') {
    return (
      <DocumentSelection
        documents={documents}
        documentTypes={documentTypes}
        onSave={(selected) => {
          if (!arraySame(selected, documents)) {
            setDocuments(selected);
          }
          setCurrentMode('VIEW');
        }}
      />
    );
  }

  return (
    <div>
      {validationErrors && (
        <Callout intent="danger">{validationErrors.join(', ')}</Callout>
      )}

      <ItemConfirm
        label="Current Name"
        value={candidateName(candidate)}
        onCheck={(value) => {
          setCheckList({
            ...checkList,
            name: value,
          });
        }}
        checked={checkList.name}
      />
      <Divider />

      {candidate.PreviousNames?.map((previous, index) => (
        <>
          <ItemConfirm
            key={`previous_name_${index}`}
            label={`Previous Name ${
              previous.IsFromBirth
                ? '(from Birth)'
                : previous.KnownFromDate
                ? `(since ${dateFormatter.medium(previous.KnownFromDate)})`
                : ''
            }`}
            value={previousName(previous)}
          />
          <Divider />
        </>
      ))}

      <ItemConfirm
        label="Current Address"
        value={candidateCurrentAddress(candidate)}
        onCheck={(value) => {
          setCheckList({
            ...checkList,
            address: value,
          });
        }}
        checked={checkList.address}
      />
      <Divider />

      {candidate.AddressList?.filter((a) => !a.IsCurrentAddress).map(
        (previous, index) => (
          <>
            <ItemConfirm
              key={`previous_address_${index}`}
              label={`Previous Address (${dateFormatter.medium(
                previous.DateMovedToThisAddress
              )} to ${dateFormatter.medium(previous.DateLeftThisAddress)})`}
              value={previousAddress(previous)}
            />
            <Divider />
          </>
        )
      )}

      <ItemConfirm
        label="Date of Birth"
        value={
          dateFormatter.long(candidateDateOfBirth(candidate)) || 'Not Specified'
        }
        onCheck={(value) => {
          setCheckList({
            ...checkList,
            dateofbirth: value,
          });
        }}
        checked={checkList.dateofbirth}
      />
      <Divider />

      <div className="d-flex flex-row my-2">
        <div className="font-weight-bold flex-grow-1">
          The following Documents were selected by the candidate
        </div>
        <div>
          <ButtonBP
            icon="document"
            intent="primary"
            onClick={() => {
              setCurrentMode('DOCUMENT-SELECT');
            }}
          >
            Change Documents
          </ButtonBP>
        </div>
      </div>

      <div className="d-flex flex-column">
        {documents.map((document) => (
          <DocumentDetails
            key={document}
            candidate={candidate}
            document={document}
            checkList={documentCheckList}
            documentType={documentTypes.find(
              (t) => t.id.toLowerCase() === document.toLowerCase()
            )}
            onChecked={(field, checked) => {
              setDocumentCheckList({
                ...documentCheckList,
                [field]: checked,
              });
            }}
            onCandidateUpdate={(field, value) => {
              setCandidateChanges({
                ...candidateChanges,
                [field]: value,
              });
            }}
          />
        ))}
      </div>

      <Divider />

      <Confirmation
        checkListComplete={checkListComplete}
        onConfirm={(confirmationArgs) => {
          // Only get the relevant candidate changes
          // for the selected documents
          const candidateChangesExpanded: any = {};
          for (const document of documents) {
            const fields = documentFieldMap[document];
            if (!fields) continue;
            for (const field of fields) {
              const value = candidateChanges[field];
              if (typeof value !== 'undefined') {
                const path = candidateFieldInfo[field].path;
                set(candidateChangesExpanded, path, value);
              }
            }
          }

          return onConfirmIdentity({
            ...confirmationArgs,
            documents,
            candidate: candidateChangesExpanded,
          });
        }}
      />
    </div>
  );
};
