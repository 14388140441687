import { Dialog } from '@blueprintjs/core';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { allAvatars, getAvatar } from '../../../assets/people';
import sectionIcons from '../../../assets/sections/line';
import SectionTypeIcon from '../../SectionTypeIcon';
import styles from './styles.module.css';

const IconPicker = ({ formField, fieldHelpers, readOnly, componentProps }) => {
  const availableIcons = useMemo(() => {
    if (componentProps.type === 'section-icon') {
      return sectionIcons;
    }
    if (componentProps.type === 'profile-type-avatar') {
      return allAvatars[componentProps.iconPack || 'default'];
    }
    return [];
  }, [componentProps.iconPack, componentProps.type]);
  const getCurrentIcon = useCallback(() => {
    if (componentProps.type === 'section-icon') {
      return sectionIcons[formField.value] ?? sectionIcons['default'];
    }
    if (componentProps.type === 'profile-type-avatar') {
      return getAvatar(formField.value, componentProps.iconPack);
    }
  }, [formField.value, componentProps.type, componentProps.iconPack]);

  const IconComponent = ({
    iconKey,
    icon: Icon,
    className,
    onClick,
    ...props
  }) => {
    if (Icon.render) {
      return (
        <SectionTypeIcon
          iconKey={iconKey}
          className={className}
          onClick={onClick}
        />
      );
    } else {
      return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img src={Icon} className={className} onClick={onClick} {...props} />
      );
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <IconComponent
        alt=""
        iconKey={formField.value}
        icon={getCurrentIcon()}
        className={cn(styles.currentIcon, {
          [styles.currentIconDisabled]: readOnly,
        })}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        autoFocus
        canEscapeKeyClose
        canOutsideClickClose
        enforceFocus
        usePortal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={componentProps.heading}
      >
        <div className={styles.iconSelectorContainer}>
          {Object.keys(availableIcons).map((iconKey) => {
            return (
              <IconComponent
                alt=""
                key={iconKey}
                iconKey={iconKey}
                className={styles.availableIcon}
                icon={availableIcons[iconKey]}
                onClick={async () => {
                  await fieldHelpers.setTouched(iconKey);
                  await fieldHelpers.setValue(iconKey);
                  setIsOpen(false);
                }}
              />
            );
          })}
        </div>
      </Dialog>
    </div>
  );
};

export default {
  name: 'Icon Picker',
  options: [],
  label: true,
  Component: IconPicker,
};
