import React from 'react';
import logo from '../../../../assets/providers/itrent.png';
import { ProviderMeta } from '../types';

const PROVIDER_KEY = 'itrent';

const HelpBlock = () => {
  return (
    <div>
      <p>To connect to iTrent please contact us.</p>

      <p>
        For further help on this, please see our{' '}
        <a href="https://go.scrtracker.com/3QP1hbt" target="blank">
          help guides.
        </a>
      </p>
    </div>
  );
};

const connectionFields = [
  {
    key: 'username',
    label: 'Username',
    type: 'textbox',
  },
  {
    key: 'password',
    label: 'Password',
    type: 'password',
  },
  {
    key: 'partyName',
    label: 'Party Name',
    type: 'textbox',
  },
  {
    key: 'udfCategories',
    label: 'UDF Categories',
    type: 'valueslist',
  },
  {
    key: 'url',
    label: 'URL',
    type: 'textbox',
  },
];

export default {
  key: PROVIDER_KEY,
  name: 'iTrent',
  logo: logo,
  url: 'https://go.scrtracker.com/3R62dIN',
  connectionFields,
  HelpBlock,
} as ProviderMeta;
