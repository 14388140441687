import { Button, HTMLTable, InputGroup, Intent } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryResults } from '../../../hooks';
import {
  DATASOURCE_QUERY_COGNITA_RA_ATTRIBUTES,
  GET_FEDERATION_USERS_LIGHT_Query,
} from '../../../queries';

import { Select } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { FederationContext } from '../../../context';
import { UPDATE_RISKASSESSMENT_SIGNOFF } from '../../../mutations';

interface SignoffOrganisation {
  id: string;
  name: string;
  location: string;
  signoff1: string;
  signoff2: string;
  signoff3: string;
  signoff4: string;
}
interface SignoffEditorProps {
  organisations: SignoffOrganisation[];
  users: { id: string; name: string; email: string; status: boolean }[];
  onSave: (
    id: string,
    signoff1: string,
    signoff2: string,
    signoff3: string,
    signoff4: string
  ) => void;
}
const SignoffEditor = ({
  organisations,
  users,
  onSave,
}: SignoffEditorProps) => {
  return (
    <HTMLTable striped className="w-100">
      <thead>
        <tr>
          <th>{'School Name'}</th>
          <th>{'Location'}</th>
          <th>{'Signoffs'}</th>
        </tr>
      </thead>
      <tbody>
        {organisations?.map((o) => {
          const items = users.map((u) => ({
            id: u.email,
            value: `${u.name} (${u.email})`,
            intent: u.status ? undefined : Intent.DANGER,
          }));
          const user1 = items.find((u) => u.id === o.signoff1);
          if (o.signoff1 && !user1) {
            items.unshift({
              id: o.signoff1,
              value: o.signoff1,
              intent: undefined,
            });
          }
          const user2 = items.find((u) => u.id === o.signoff2);
          if (o.signoff2 && !user2) {
            items.unshift({
              id: o.signoff2,
              value: o.signoff2,
              intent: undefined,
            });
          }
          const user3 = items.find((u) => u.id === o.signoff3);
          if (o.signoff3 && !user3) {
            items.unshift({
              id: o.signoff3,
              value: o.signoff3,
              intent: undefined,
            });
          }
          const user4 = items.find((u) => u.id === o.signoff4);
          if (o.signoff4 && !user4) {
            items.unshift({
              id: o.signoff4,
              value: o.signoff4,
              intent: undefined,
            });
          }
          return (
            <tr key={o.id}>
              <td>{o.name}</td>
              <td>{o.location}</td>

              <td>
                <HTMLTable className="w-100" bordered={false}>
                  <tbody>
                    {/* sign off 1 */}
                    <tr className="w-100">
                      <td>Signoff 1</td>
                      <td>
                        <Select
                          itemsDescription="Not selected"
                          valueField="id"
                          textField="value"
                          showEmpty
                          intent={user1?.intent}
                          onChange={async (item) => {
                            return onSave(
                              o.id,
                              item.id,
                              o.signoff2,
                              o.signoff3,
                              o.signoff4
                            );
                          }}
                          large={false}
                          fill
                          value={o.signoff1}
                          items={items}
                        />
                      </td>
                    </tr>

                    {/* sign off 2 */}
                    <tr>
                      <td>Signoff 2</td>
                      <td>
                        <Select
                          itemsDescription="Not selected"
                          valueField="id"
                          textField="value"
                          showEmpty
                          intent={user2?.intent}
                          onChange={async (item) => {
                            return onSave(
                              o.id,
                              o.signoff1,
                              item.id,
                              o.signoff3,
                              o.signoff4
                            );
                          }}
                          large={false}
                          fill
                          value={o.signoff2}
                          items={items}
                        />
                      </td>
                    </tr>

                    {/* sign off 3 */}
                    <tr>
                      <td>Signoff 3</td>
                      <td>
                        <Select
                          itemsDescription="Not selected"
                          valueField="id"
                          textField="value"
                          showEmpty
                          intent={user3?.intent}
                          onChange={async (item) => {
                            return onSave(
                              o.id,
                              o.signoff1,
                              o.signoff2,
                              item.id,
                              o.signoff4
                            );
                          }}
                          large={false}
                          fill
                          value={o.signoff3}
                          items={items}
                        />
                      </td>
                    </tr>

                    {/* sign off 4 */}
                    <tr>
                      <td>Signoff 4</td>
                      <td>
                        <Select
                          itemsDescription="Not selected"
                          valueField="id"
                          textField="value"
                          showEmpty
                          intent={user4?.intent}
                          onChange={async (item) => {
                            return onSave(
                              o.id,
                              o.signoff1,
                              o.signoff2,
                              o.signoff3,
                              item.id
                            );
                          }}
                          large={false}
                          fill
                          value={o.signoff4}
                          items={items}
                        />
                      </td>
                    </tr>
                  </tbody>
                </HTMLTable>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

const SignoffEditorContainer = () => {
  const { t } = useTranslation();

  const federation = useContext(FederationContext);

  const dataSourceQuery = useQuery(DATASOURCE_QUERY_COGNITA_RA_ATTRIBUTES);

  const usersQuery = GET_FEDERATION_USERS_LIGHT_Query(federation.key);

  const { loading, error } = useQueryResults({
    usersQuery,
    dataSourceQuery,
  });

  const users = usersQuery.data?.map((u) => ({
    ...u,
    status: u.lastEmail?.status == null || u.lastEmail.status === 'delivered',
  }));

  const [updateRiskAssessmentSignoff] = useMutation(
    UPDATE_RISKASSESSMENT_SIGNOFF,
    {
      selector: 'updateRiskAssessmentSignoff',
      refetchQueries: [
        {
          query: DATASOURCE_QUERY_COGNITA_RA_ATTRIBUTES,
        },
      ],
    }
  );

  const [organisationSearch, setOrganisationSearch] = useState('');

  return (
    <SettingsPanel
      loading={loading}
      error={error}
      title="Risk Assessment Signoff"
      actions={[]}
    >
      <div className="flex flex-col">
        <InputGroup
          className="mb-1"
          large
          autoFocus
          placeholder={t('Search Organisations...')}
          leftIcon="search"
          rightElement={
            organisationSearch ? (
              <Button
                disabled={!organisationSearch}
                icon="cross"
                intent="none"
                minimal={true}
                onClick={() => {
                  setOrganisationSearch('');
                }}
              />
            ) : undefined
          }
          value={organisationSearch}
          onChange={(e) => {
            setOrganisationSearch(e.target.value);
            // onSearchHandler(e.target.value);
          }}
        />
      </div>
      <SignoffEditor
        organisations={dataSourceQuery.data?.queryCognitaSignoffs?.result.filter(
          (o: any) => {
            if (organisationSearch) {
              return o.name
                .toLowerCase()
                .includes(organisationSearch.toLowerCase());
            }
            return true;
          }
        )}
        users={users!}
        onSave={(organisationId, signoff1, signoff2, signoff3, signoff4) => {
          return updateRiskAssessmentSignoff({
            variables: {
              organisationId,
              values: {
                signoff1,
                signoff2,
                signoff3,
                signoff4,
              },
            },
          });
        }}
      />
    </SettingsPanel>
  );
};

export default SignoffEditorContainer;
