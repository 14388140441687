import React, { useMemo } from 'react';
import { DataProvider, LocationMap, ProfileTypeMap } from '../../../types';

import { Card } from '@blueprintjs/core';
import { ActionWithChildren } from '@hogwarts/ui-components-core';
import { notEmpty } from '@hogwarts/utils';
import { Scheme } from '@hogwarts/utils-schemes';
import { useTranslation } from 'react-i18next';
import { useFeature } from '../../../hooks';
import { TabBar } from '../../TabBar';
import { SettingsPanel } from '../SettingsPanel';
import { ConnectionEditor } from './components/ConnectionEditor';
import { FieldMapEditor } from './components/FieldMapEditor';
import { LocationEditor } from './components/LocationEditor';
import NoDataProvider from './components/NoProvider';
import { ProfileTypeMapEditor } from './components/ProfileTypeMapEditor';
import { StatusTab } from './components/StatusTab';
import { Provider } from './types';

export * from './types';

const EmptyLocations = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t('No Locations have been Discovered. Try running a Discovery Import!')}
    </div>
  );
};

const EmptyFields = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t(
        'No Source Fields have been Discovered. Try running a Discovery Import!'
      )}
    </div>
  );
};

interface TabProps {
  provider: Provider;
  config?: DataProvider;
  scheme: Scheme;
  onSaveFieldMap: (fieldMap: any) => void;
  onSaveLocationMap: (locationMap: LocationMap) => void;
  onSaveProfileTypeMap: (profileTypeMap: ProfileTypeMap) => void;
  onSaveConnection: (connection: any) => void;
}

interface DataProviderTab {
  key: string;
  name: string;
  Component: React.FC<TabProps>;
  showHelpBlock?: boolean;
}
const tabFactory = (options: {
  organisations?: {
    id: string;
    name: string;
  }[];
  showConnection: boolean;
  showFieldMap: boolean;
  showOrganisationMap: boolean;
  showProfileTypeMap: boolean;
}): DataProviderTab[] =>
  [
    {
      key: 'status',
      name: 'Status',
      Component: (props: TabProps) => (
        <StatusTab
          provider={props.provider}
          connected={!!props.config?.connected}
          messages={props.config?.messages}
        />
      ),
      showHelpBlock: true,
    },
    options.showConnection
      ? {
          key: 'connection',
          name: 'Connection',
          Component: (props: TabProps) => {
            return (
              <ConnectionEditor
                providerKey={props.provider.key}
                fields={props.provider.connectionFields!}
                connection={props.config?.connection}
                onSave={props.onSaveConnection}
              />
            );
          },
        }
      : null,
    options.showOrganisationMap
      ? {
          key: 'organisations',
          name: 'Organisation Map',
          Component: (props: TabProps) => {
            if (!props.config?.locations) {
              return <EmptyLocations />;
            }
            return (
              <LocationEditor
                organisations={options.organisations}
                locationsType={props.config?.locationsType}
                locations={props.config?.locations}
                locationMap={props.config?.locationMap || []}
                onSave={props.onSaveLocationMap}
              />
            );
          },
        }
      : null,
    // {
    //   key: 'fieldconfig',
    //   name: 'Field Config',
    //   Component: () => <div>TODO</div>,
    // },
    options.showFieldMap
      ? {
          key: 'fieldmap',
          name: 'Field Map',
          Component: (props: TabProps) => {
            if (!props.config?.sourceFields?.length) {
              return <EmptyFields />;
            }
            return (
              <FieldMapEditor
                scheme={props.scheme}
                fieldMap={props.config?.fieldMap!}
                sourceFields={props.config?.sourceFields!}
                onSave={(fieldMap) => {
                  return props.onSaveFieldMap(fieldMap);
                }}
              />
            );
          },
        }
      : null,
    options.showProfileTypeMap
      ? {
          key: 'profiletypemap',
          name: 'Profile Type Map',
          Component: (props: TabProps) => (
            <ProfileTypeMapEditor
              profileTypeMap={props.config?.profileTypeMap}
              profileTypes={props.scheme.profileTypes}
              sourceFields={props.config?.sourceFields!}
              onSave={(profileTypeKey, condition) => {
                let existingMap = props.config?.profileTypeMap;
                if (!existingMap) {
                  existingMap = [];
                } else {
                  existingMap = existingMap.map((e) => ({
                    profileTypeKey: e.profileTypeKey,
                    condition: e.condition,
                  }));
                }
                const existingItem = existingMap.find(
                  (pt) => pt.profileTypeKey === profileTypeKey
                );
                if (!existingItem) {
                  existingMap = [
                    ...existingMap,
                    {
                      profileTypeKey,
                      condition,
                    },
                  ];
                } else {
                  existingMap = [
                    ...existingMap.filter(
                      (e) => e.profileTypeKey !== profileTypeKey
                    ),
                    {
                      profileTypeKey,
                      condition,
                    },
                  ];
                }

                return props.onSaveProfileTypeMap(existingMap);
              }}
            />
          ),
        }
      : null,
    // {
    //   key: 'conditionsync',
    //   name: 'Conditional Sync',
    //   Component: () => <div>TODO</div>,
    // },
    // {
    //   key: 'exclusions',
    //   name: 'Exclusions',
    //   Component: () => <div>TODO</div>,
    // },
    // {
    //   key: 'history',
    //   name: 'History',
    //   Component: () => <div>TODO</div>,
    // },
  ].filter(notEmpty);

interface DataProviderProps {
  activeTab?: string;
  connected?: boolean;
  config?: DataProvider;
  showConnection?: boolean;
  showOrganisationMap?: boolean;
  showFieldMap?: boolean;
  showProfileTypeMap?: boolean;
  organisations?: {
    id: string;
    name: string;
  }[];
  providers: Provider[];
  providerKey?: string;
  actions: ActionWithChildren[];
  HelpBlock?: React.ReactNode;
  scheme?: Scheme;
  onTabChange: (key: string) => void;
  onSaveFieldMap: (fieldMap: any) => void;
  onSaveLocationMap: (locationMap: LocationMap) => void;
  onSaveProfileTypeMap: (profileTypeMap: ProfileTypeMap) => void;
  onSaveConnection: (connection: any) => void;
}
export const DataProviderComponent = ({
  activeTab: activeTabKey,
  connected,
  providers,
  providerKey,
  showConnection,
  showOrganisationMap,
  showFieldMap,
  showProfileTypeMap,
  actions,
  organisations,
  config,
  HelpBlock,
  onSaveFieldMap,
  onSaveLocationMap,
  onSaveProfileTypeMap,
  onSaveConnection,
  onTabChange,
  scheme,
}: DataProviderProps) => {
  const provider = providerKey
    ? providers?.find((p) => p.key === providerKey)
    : null;

  const profileTypeMapEnabled =
    useFeature(`providers.${providerKey}.profileTypeMap`) &&
    showProfileTypeMap !== false;

  const tabs = useMemo(
    () =>
      tabFactory({
        organisations,
        showConnection: showConnection !== false,
        showOrganisationMap: showOrganisationMap !== false,
        showFieldMap: showFieldMap !== false,
        showProfileTypeMap: profileTypeMapEnabled,
      }),
    [
      organisations,
      showConnection,
      showOrganisationMap,
      showFieldMap,
      profileTypeMapEnabled,
    ]
  );

  const activeTab = tabs.find((t) => t.key === activeTabKey) ?? tabs[0];

  if (!provider) {
    return (
      <SettingsPanel title={'MIS / Provider Integration'} actions={actions}>
        <NoDataProvider providers={providers} />
      </SettingsPanel>
    );
  }

  return (
    <SettingsPanel title={'MIS / Provider Integration'} actions={actions}>
      <TabBar activeTab={activeTab.key} tabs={tabs} onTabChange={onTabChange} />
      <Card>
        <activeTab.Component
          provider={provider!}
          config={config}
          scheme={scheme!}
          onSaveFieldMap={onSaveFieldMap}
          onSaveLocationMap={onSaveLocationMap}
          onSaveProfileTypeMap={onSaveProfileTypeMap}
          onSaveConnection={onSaveConnection}
        />
        {activeTab.showHelpBlock ? HelpBlock : null}
      </Card>
    </SettingsPanel>
  );
};
