import React from 'react';
import { useTranslation } from 'react-i18next';
import { H1 } from '../../ui-dom-components';

interface FederationWelcomeProps {
  federations: {
    name: string;
  }[];
  email: string;
}
const FederationWelcome = (props: FederationWelcomeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <section className="bridgeUpper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <H1>{t('Welcome to Sign In Central Record')}</H1>
            </div>
          </div>
        </div>
      </section>
      <section className="bridgeLower">
        <div className="container mt-5">
          <div className="row no-gutters">
            <div className="col-sm-12">
              <p>
                {t(
                  'Your account is setup and you are a member of the following federations'
                )}
                : {props.federations.map((federation) => federation.name)}.
              </p>
              <p>
                {t(
                  'Currently you are not a member of any organisation within the federation.'
                )}
              </p>
              {props.email && (
                <p>
                  {t(
                    'The account you are logged into has the following email address: {{email}}',
                    {
                      email: props.email,
                    }
                  )}
                </p>
              )}
              <p>
                {t(
                  'Please speak with your Federation Administrator to be added to the correct organisation.'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FederationWelcome;
