import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import LoginCallback from '@/containers/login/callback';
import PasswordReset from '@/containers/login/password-reset';
import { Loading } from '@hogwarts/ui-components-core';
import { Forms } from '@hogwarts/ui-components-forms';
import { Route, Switch } from 'react-router-dom';
import App from './app';
import VerifyCandidate from './checks/verifyCandidate/verifyCandidate';

const AppContainer = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <meta charSet={'utf-8'} />
      <title>Sign In Central Record</title>
      <meta
        name={'description'}
        content={
          'Discover Sign In Central Record, an online hub for schools to manage checks, inspector evaluations, and teaching staff effortlessly.'
        }
      />
    </Helmet>
    <Suspense fallback={<Loading showLogo />}>
      <Forms>{children}</Forms>
    </Suspense>
  </React.Fragment>
);

const PageRoot = () => (
  <Switch>
    <Route exact path={'/callback'}>
      <AppContainer>
        <LoginCallback />
      </AppContainer>
    </Route>
    <Route exact path={'/passwordreset'}>
      <AppContainer>
        <PasswordReset />
      </AppContainer>
    </Route>
    <Route exact path={'/checks/verifycandidate'}>
      <VerifyCandidate />
    </Route>
    <Route>
      <AppContainer>
        <App />
      </AppContainer>
    </Route>
  </Switch>
);

export default PageRoot;
