import {
  dateFormatter,
  gridOptions as defaultGridOptions,
} from '../../../profilesGridUtils';

import { Column } from '@hogwarts/ui-components-grid';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridView } from '../../../../components';
import ActivityTag from '../../../../components/ActivityTag/ActivityTag';
import DocumentIcon from '../../../../components/DocumentIcon/DocumentIcon';
import { Activity } from '../../../../components/UserProfile/Activity';
import styles from './styles.module.css';

const gridOptions = {
  ...defaultGridOptions,
  rowSelection: 'single',
  sideBar: false,
  sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 100,
    filter: false,
    editable: false,
    suppressMenu: true,
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
};

const IncidentsGridView = ({ incidents }: any) => {
  const { t } = useTranslation();

  const fields: Column[] = [
    {
      key: 'date',
      label: t('Date'),
      valueFormatter: dateFormatter,
      width: 150,
    },
    {
      key: 'profile.name',
      label: t('Profile'),
      width: 200,
    },
    {
      key: 'data.status.name',
      label: t('Status'),
      width: 150,
    },
    {
      key: 'data.category.name',
      label: t('Category'),
      width: 150,
    },
    {
      key: 'data.severity.name',
      label: t('Severity'),
      width: 100,
    },
    {
      key: 'data.notes',
      label: t('Notes'),
      wrapText: true,
      autoHeight: true,
      width: 400,
    },
    {
      key: 'data.assignedTo.name',
      label: t('Assigned To'),
      width: 150,
      cellRenderer: ({ data: incident }: { data: Activity }) => {
        const { assignedTo } = incident.data;

        if (!assignedTo) return null;
        return assignedTo.map(({ name }: any) => name).join(', ');
      },
      filter: 'agTextColumnFilter',
    },
    {
      key: 'documents',
      label: t('Documents'),
      cellRenderer: ({ data: incident }: { data: Activity }) => {
        const { documents } = incident;

        if (!documents?.length) return null;
        return (
          <div className={styles.iconWrapper}>
            {documents.map((document) => (
              <DocumentIcon
                document={document}
                className={styles.documentIcon}
                hasTooltip
              />
            ))}
          </div>
        );
      },
      filter: 'agTextColumnFilter',
      minWidth: 50,
      maxWidth: 125,
    },
    {
      key: 'tags',
      label: t('Tags'),
      cellRenderer: ({ data: incident }: { data: Activity }) => {
        const { tags } = incident;

        if (!tags?.length) return null;
        return (
          <div className={cn(styles.iconWrapper, styles.tags)}>
            {tags.map(({ name, color }) => (
              <ActivityTag color={color}>{name}</ActivityTag>
            ))}
          </div>
        );
      },
      filter: 'agTextColumnFilter',
      minWidth: 150,
      maxWidth: 250,
      wrapText: true,
    },
  ];

  return (
    <GridView
      {...gridOptions}
      getRowNodeId={(data: any) => data.id}
      groups={[]}
      columns={fields}
      rows={incidents}
      showSideBar={false}
    />
  );
};

export default IncidentsGridView;
