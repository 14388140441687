import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React from 'react';
import ProfileTypeIcon from '../ProfileTypeIcon';
import styles from './styles.module.css';

interface ProfileRatingsProps {
  ratings: {
    key: string;
    label: string;
    ready: boolean;
    readyColor: string;
    notReadyColor: string;
  }[];
}
export const ProfileRatings = ({ ratings }: ProfileRatingsProps) => {
  if (!ratings) return null;
  return (
    <div className="flex-column">
      {ratings.map((rating) => (
        <p
          key={`rating-${rating.key}`}
          className={cn(styles.profileScore, 'text-nowrap', 'flex-nowrap')}
          style={{
            color: rating.ready ? rating.readyColor : rating.notReadyColor,
          }}
        >
          {rating.label}
          {rating.ready && (
            <FontAwesomeIcon
              icon={'check'}
              size={'1x'}
              className="ml-2"
              color={rating.readyColor}
            />
          )}
          {!rating.ready && (
            <FontAwesomeIcon
              icon={'times'}
              size={'1x'}
              className="ml-2"
              color={rating.notReadyColor}
            />
          )}
        </p>
      ))}
    </div>
  );
};

interface ProfileListItemProps {
  id: string;
  name?: string;
  type?: string;
  orgName?: string;
  avatar?: string;
  iconPack?: string;
  fields?: {
    key: string;
    label: string;
    value: string;
  }[];
  tags?: {
    key: string;
    label: string;
    color: string;
  }[];
  onSelected?: (id: string) => void;
  last?: boolean;
  children?: React.ReactNode;
  isVerticallyStacked?: boolean;
  className?: string;
}
export const ProfileListItem = ({
  id,
  name,
  type,
  orgName,
  avatar,
  iconPack,
  fields,
  tags,
  last = false,
  onSelected,
  children,
  isVerticallyStacked = false,
  className,
}: ProfileListItemProps) => {
  return (
    <div
      key={id}
      className={cn(
        styles.profileContainer,
        last && styles.profileContainerLast,
        className
      )}
      onClick={() => onSelected && onSelected(id)}
    >
      <div className="d-flex w-100 flex-row align-items-center">
        {avatar && (
          <div>
            <ProfileTypeIcon
              width={'64px'}
              style={{ marginRight: '3px' }}
              avatarKey={avatar}
              iconPack={iconPack}
            />
          </div>
        )}

        <div className="flex-grow-1 flex-column">
          <div
            className={cn(
              isVerticallyStacked ? 'flex-column' : 'flex-row',
              'd-flex'
            )}
          >
            <div
              className={cn(
                isVerticallyStacked && 'align-items-center text-center',
                'flex-column d-flex flex-grow-1'
              )}
            >
              {name && <div className={cn(styles.profileName)}>{name}</div>}
              {type && <div className={styles.profileTypeLabel}>{type}</div>}
              {orgName && (
                <div className={styles.profileTypeLabel}>{orgName}</div>
              )}

              {tags && (
                <div className={styles.tagsContainer}>
                  {tags?.map((tag) => (
                    <Tag key={tag.key} label={tag.label} color={tag.color} />
                  ))}
                </div>
              )}
            </div>

            {children && (
              <div className={styles.childrenContainer}>{children}</div>
            )}
          </div>
          {fields && (
            <div className="flex-column w-100">
              <table className="w-100">
                <tbody>
                  {fields.map((field) => (
                    <tr className={cn(styles.field)} key={`field-${field.key}`}>
                      <td className={styles.fieldName}>{`${field.label}`}</td>
                      <td className={styles.fieldValue}>{field.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
