import cn from 'classnames';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Profile, ProfileListItem } from '../../../containers/profileListItem';
import ProfilePicture from '../../UserProfile/ProfilePicture';
import styles from './styles.module.css';

interface BulkProfileImagesProps {
  onOpenProfilePicturePicker: (profile: Profile) => void;
  onProfilePictureUpload: ({ files }: any) => void;
  onDeleteProfilePicture: () => void;
  updatedImage: string;
  allowEdit: boolean;
  item: Profile;
  isParentDragActive: boolean;
}

const BulkProfileImages = ({
  allowEdit,
  item: profile,
  updatedImage,
  onDeleteProfilePicture,
  onOpenProfilePicturePicker,
  onProfilePictureUpload,
  isParentDragActive,
}: BulkProfileImagesProps) => {
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      if (allowEdit) {
        onProfilePictureUpload({ files });
      }
    },
  });

  return (
    <div
      {...getRootProps({
        className: `d-flex w-100 profile-${profile.id}-dropzone`,
      })}
    >
      <ProfileListItem
        profile={profile}
        fields={[]}
        isVerticallyStacked
        className={cn(styles.profileListItem, {
          [styles.dropzoneActive]: isDragActive && allowEdit,
        })}
      >
        <ProfilePicture
          image={updatedImage}
          allowEdit={allowEdit}
          onEdit={onOpenProfilePicturePicker}
          isDragActive={isParentDragActive}
          dropzoneClassName={styles.dropzone}
          onDelete={onDeleteProfilePicture}
        />
      </ProfileListItem>
    </div>
  );
};

export default BulkProfileImages;
