import { useLazyQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MfaSettings from '../../../components/Settings/MFA';
import { AnalyticsContext, UserContext } from '../../../context';
import { useMutation } from '../../../hooks';
import {
  BEGIN_MFA_REGISTRATION,
  COMPLETE_MFA_REGISTRATION,
  REMOVE_MFA_REGISTRATION,
} from '../../../mutations';
import { GET_CURRENT_USER, GET_CURRENT_USER_Response } from '../../../queries';
import { authenticationService } from '../../../services';
import { AppToaster } from '../../../utils/toaster';

// Is it bad practice to introduce props here?
const MfaSettingsContainer = ({ enforceMode }: any) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const analytics = useContext(AnalyticsContext);
  const [mfaState, setMfaState] = useState<
    | 'ENABLED'
    | 'SAVING'
    | 'DISABLED'
    | 'ERROR'
    | 'REMOVING'
    | 'SHOW-QR'
    | 'LOADING-QR'
  >(user.mfaEnabled ? 'ENABLED' : 'DISABLED');

  const [qrcodeDataUrl, setQrcodeDataUrl] = useState<string | undefined>();
  const [beginMfaRegistration] = useMutation<string>(BEGIN_MFA_REGISTRATION, {
    selector: 'beginMfaRegistration',
    fetchPolicy: 'no-cache',
  });
  const [completeMfaRegistration] = useMutation<string>(
    COMPLETE_MFA_REGISTRATION,
    {
      selector: 'completeMfaRegistration',
      fetchPolicy: 'no-cache',
    }
  );
  const [removeMfaRegistration] = useMutation(REMOVE_MFA_REGISTRATION, {
    selector: 'removeMfaRegistration',
    fetchPolicy: 'no-cache',
  });

  const [getCurrentUser] = useLazyQuery<GET_CURRENT_USER_Response>(
    GET_CURRENT_USER,
    {
      // Note, this is vital to ensure cache is being updated
      fetchPolicy: 'network-only',
    }
  );

  return (
    <MfaSettings
      enforceMode={enforceMode}
      state={mfaState}
      qrcodeDataUrl={qrcodeDataUrl}
      onEnable={async (enabled) => {
        if (enabled) {
          setMfaState('LOADING-QR');
          try {
            analytics.events.settings.mfaRegistrationBegin();
            const result = await beginMfaRegistration();
            if (result) {
              setQrcodeDataUrl(result.data!);
              setMfaState('SHOW-QR');
            }
          } catch (error) {
            analytics.events.settings.mfaRegistrationError();
            setMfaState('ERROR');
          }
        } else {
          analytics.events.settings.mfaDisabled();
          setMfaState('DISABLED');
          setQrcodeDataUrl(undefined);
        }
      }}
      onSave={async (authenticatorCode) => {
        setMfaState('SAVING');
        try {
          const mfaToken = await completeMfaRegistration({
            variables: {
              authenticatorCode,
            },
          });
          if (mfaToken.data) {
            analytics.events.settings.mfaRegistrationComplete();
            authenticationService.mfaRegistered(mfaToken.data);
            setMfaState('ENABLED');
          } else {
            analytics.events.settings.mfaRegistrationError();
            setMfaState('ERROR');
          }
        } catch (error) {
          analytics.events.settings.mfaRegistrationError();
          setMfaState('ERROR');
        }
        await getCurrentUser();
      }}
      onDisable={async (authenticatorCode) => {
        setMfaState('REMOVING');
        try {
          const result = await removeMfaRegistration({
            variables: {
              authenticatorCode,
            },
          });
          if (result.data) {
            analytics.events.settings.mfaDisabled();
            setMfaState('DISABLED');
          } else {
            AppToaster.show({
              message: t('Authenticator Code Invalid'),
              intent: 'danger',
              icon: 'cross',
            });
            setMfaState('ENABLED');
          }
        } catch (error) {
          analytics.events.settings.mfaRegistrationError();
          setMfaState('ENABLED');
        }
        await getCurrentUser();
      }}
    />
  );
};

export default MfaSettingsContainer;
