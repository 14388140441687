import trackEvent from '../trackEvent';

export const overviewEditClicked = (traits = {}) => {
  trackEvent('Profile - Overview Edit Clicked', traits, true);
};

export const sectionEditClicked = (traits = {}) => {
  trackEvent('Profile - Section Edit Clicked', traits);
};

export const hideChecksSaveClicked = (traits = {}) => {
  trackEvent('Profile - Hide Checks Save Clicked', traits);
};

export const sectionPrintClicked = (traits = {}) => {
  trackEvent('Profile - Section Print Clicked', traits, true);
};

export const sectionSettingsClicked = (traits = {}) => {
  trackEvent('Profile - Section Settings Clicked', traits, true);
};

export const sectionUpdated = (traits = {}) => {
  trackEvent('Profile - Section Updated', traits, true);
};

export const printActionClicked = (traits = {}) => {
  trackEvent('Profile - Print Action Clicked', traits, true);
};

export const copyActionClicked = (traits = {}) => {
  trackEvent('Profile - Copy Action Clicked', traits, true);
};

export const transferActionClicked = (traits = {}) => {
  trackEvent('Profile - Transfer Action Clicked', traits, true);
};

export const transferCancelActionClicked = (traits = {}) => {
  trackEvent('Profile - Transfer Cancel Action Clicked', traits, true);
};

export const deleteActionClicked = (traits = {}) => {
  trackEvent('Profile - Delete Action Clicked', traits, true);
};

export const uploadActionClicked = (traits = {}) => {
  trackEvent('Profile - Upload Action Clicked', traits, true);
};

export const tabViewed = (traits = {}) => {
  trackEvent('Profile - Tab Viewed', traits);
};

export const profileTypeChangeClicked = (traits = {}) => {
  trackEvent('Profile - Profile Type Change Clicked', traits, true);
};

export const profileTypeDoesNotExistOnTarget = (traits = {}) => {
  trackEvent('Profile - Profile Type does not Exist on Target', traits, true);
};

export const copySuccessful = (traits = {}) => {
  trackEvent('Profile - Profile was succesfully copied', traits, true);
};

export const pictureDropped = (traits = {}) => {
  trackEvent('Profile - Profile Picture Dropped', traits, true);
};

export const pictureUploadClicked = (traits = {}) => {
  trackEvent('Profile - Profile Picture Upload Clicked', traits, true);
};

export const pictureUpload = (traits = {}) => {
  trackEvent('Profile - Profile Picture Uploaded', traits, true);
};

export const rollbackClicked = (traits = {}) => {
  trackEvent('Profile - Profile Rollback Button Clicked', traits, true);
};

export const rollbackError = (traits = {}) => {
  trackEvent('Profile - Error Rolling Back Profile', traits, true);
};

export const rollbackSuccess = (traits = {}) => {
  trackEvent('Profile - Profile Rolled Back Successfully', traits, true);
};

export const pictureDeleteClicked = (traits = {}) => {
  trackEvent('Profile - Profile Picture Delete Clicked', traits, true);
};

export const activityDeleteClicked = (traits = {}) => {
  trackEvent('Profile - Activity Delete Button Clicked', traits, true);
};

export const activityUpdateClicked = (traits = {}) => {
  trackEvent('Profile - Activity Update Button Clicked', traits, true);
};

export const activityDeleted = (traits = {}) => {
  trackEvent('Profile - Activity Deleted Successfully', traits, true);
};

export const activitiesFiltered = (traits = {}) => {
  trackEvent('Profile - Activities filtered', traits, true);
};

export const addIncidentClicked = (traits = {}) => {
  trackEvent('Profile - Add Incident Clicked', traits, true);
};

export const updateActivityClicked = (traits = {}) => {
  trackEvent('Profile - Update Activity Clicked', traits, true);
};

export const activityUpdated = (traits = {}) => {
  trackEvent('Profile - Activity Updated Successfully', traits, true);
};

export const updateIncidentClicked = (traits = {}) => {
  trackEvent('Profile - Update Incident Clicked', traits, true);
};

export const turnOffReadOnlyClicked = (traits = {}) => {
  trackEvent('Profile - Turn Off ReadOnly Clicked', traits, true);
};
