import { Draggable, Droppable } from 'react-beautiful-dnd';

import cn from 'classnames';
import React from 'react';
import FormIcons from '../../../assets/form-icons/line';
import styles from './styles.module.css';

const FieldLibraryItem = ({
  className,
  component,
  innerRef,
  isDragging,
  ...rest
}) => {
  let Icon = FormIcons[component.inputType];
  if (!Icon) {
    Icon = FormIcons['helptext'];
  }
  return (
    <div
      ref={innerRef}
      className={cn(className, styles.fieldTypeButton)}
      {...rest}
    >
      <div className={styles.fieldTypeImageContainer}>
        <Icon className={styles.fieldTypeImage} />
      </div>
      <div>
        <p className={styles.fieldTypeHeader}>{component.label}</p>
        <p className={styles.fieldTypeDescription}>{component.description}</p>
      </div>
    </div>
  );
};

const getRenderItem =
  (components, className) => (provided, snapshot, rubric) => {
    const component = components[rubric.source.index];
    return (
      <FieldLibraryItem
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isDragging={snapshot.isDragging}
        innerRef={provided.innerRef}
        style={provided.draggableProps.style}
        component={component}
      />
    );
  };

const FieldLibrary = ({ components, arrayTypeFieldText }) => {
  return (
    <Droppable
      droppableId="FIELD_TEMPLATES"
      isDropDisabled={true}
      renderClone={getRenderItem(components)}
      type={arrayTypeFieldText}
    >
      {(provided, snapshot) => (
        <div className={styles.libraryContainer} ref={provided.innerRef}>
          <p className={styles.libraryHeader}>Field Library</p>
          {components &&
            components.map((component, index) => {
              const shouldRenderClone =
                component.id === snapshot.draggingFromThisWith;

              return (
                <React.Fragment key={component.id}>
                  {shouldRenderClone ? (
                    <FieldLibraryItem
                      className={styles.reactDndCopy}
                      component={component}
                    />
                  ) : (
                    <Draggable draggableId={component.id} index={index}>
                      {(provided, snapshot) => (
                        <FieldLibraryItem
                          component={component}
                          innerRef={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        />
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default FieldLibrary;
