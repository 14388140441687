import { Button, Classes, Dialog, HTMLSelect } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import {
  Currency,
  Error,
  Loading,
  Markdown,
} from '@hogwarts/ui-components-core';
import { useForms } from '@hogwarts/ui-components-forms';
import { notEmpty } from '@hogwarts/utils';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateFilter from '../../../components/Filters/Date';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../../context';
import {
  useFeature,
  useMutation,
  usePermission,
  useQuery,
  useQueryResults,
} from '../../../hooks';
import { useBuildExternalChecksReport } from '../../../hooks/useBuildExternalChecksReport';
import { REQUEST_ACCOUNT_SETUP } from '../../../mutations';
import {
  GET_CHECK_ACCOUNT,
  GET_CHECK_ACCOUNTResponse,
  GET_FEDERATION_CURRENT_SPEND,
} from '../../../queries';
import { AppToaster } from '../../../utils';

const useRequestAccount = (): [boolean, () => void] => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const user = useContext(UserContext);
  const analytics = useContext(AnalyticsContext);
  const [requestAccountSetup] = useMutation(REQUEST_ACCOUNT_SETUP);

  const hasFederationPermission = usePermission(
    permissions.ADMIN,
    federation?.id
  );
  const hasOrganisationPermission = usePermission(
    permissions.CHECK_ORDER_BILLING,
    organisation?.id
  );

  const [showForm] = useForms({
    title: t('Request DBS Account'),
    saveText: t('Submit'),
    savingText: t('Submitting'),
    savedText: t('Submitted'),
    successToastMessage: t('Request Submitted'),
    onSave: (values) => {
      analytics.events.checks.requestAccountClicked();
      return requestAccountSetup({
        variables: {
          values,
        },
      });
    },
    initialValues: {
      organisationId: organisation?.id,
      organisationKey: organisation?.key,

      federationId: federation?.id,
      federationKey: federation?.key,

      currentDbsProvider: '',
      expectedDbsChecks: 100,
      currentCost: 52,

      schoolOrTrust: federation && hasFederationPermission ? 'trust' : 'school',

      companyName:
        federation && hasFederationPermission
          ? federation.name
          : organisation?.name,
      contactName: user?.name,
      contactEmail: user?.email,
    },
    fields: [
      {
        key: 'federationId',
        type: 'hidden',
      },
      {
        key: 'federationKey',
        type: 'hidden',
      },
      {
        key: 'organisationId',
        type: 'hidden',
      },
      {
        key: 'organisationKey',
        type: 'hidden',
      },
      {
        key: 'contactName',
        type: 'hidden',
      },
      {
        key: 'contactEmail',
        type: 'hidden',
      },
      {
        type: 'callout',
        intent: 'primary',
        message: t(
          `To get your account setup, please provide us with the following information.\nWe will configure your account with Verifile and be in touch when complete.`
        ),
      },
      {
        type: 'separator',
      },

      federation && !hasFederationPermission
        ? {
            type: 'callout',
            intent: 'danger',
            message: t(
              `To signup for the Trust, you will require Administrator permissions to submit a request\nIf you continue, invoices will be sent to the school`
            ),
          }
        : undefined,

      federation && hasFederationPermission
        ? {
            key: 'schoolOrTrust',
            type: 'singleselect',
            label: t(
              'Who will be the owner of the account (and the bill payer)?'
            ),
            validate: 'required',
            values: [
              {
                id: 'trust',
                value: `Trust - ${federation.name}`,
              },
              hasOrganisationPermission
                ? {
                    id: 'school',
                    value: `School - ${organisation.name}`,
                  }
                : undefined,
            ].filter(notEmpty),
          }
        : undefined,

      {
        key: 'companyName',
        type: 'textbox',
        label: t('Company Name to be shown on DBS Certificates'),
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'directDebit',
        type: 'toggle',
        label: t(
          'Are you happy to setup a Direct Debit facility for the checks via Gocardless with us? (Monthly Billing)'
        ),
      },
      {
        key: 'expectedDbsChecks',
        type: 'numeric',
        label: t(
          'How many checks would you say you do on an average yearly basis?'
        ),
        validate: 'required',
      },
      {
        key: 'currentDbsProvider',
        type: 'textbox',
        label: t('How do you currently do your DBS checks?'),
        validate: 'required',
      },
      {
        key: 'currentCost',
        type: 'numeric',
        label: t(
          'Including all the admin fees, how much does an Enhanced DBS check currently cost the school? GBP £'
        ),
        validate: 'required',
      },

      {
        type: 'separator',
      },

      {
        key: 'notes',
        type: 'textarea',
        label: t(
          'Is there anything you would like to add, request or tell us?'
        ),
      },
    ].filter(notEmpty),
  });

  return [hasFederationPermission || hasOrganisationPermission, showForm];
};

export const useGetCheckAccountQuery = () => {
  const organisation = useContext(OrganisationContext);
  const hasPermission = usePermission(
    permissions.CHECK_ORDER_BILLING,
    organisation.id
  );
  return useQuery<GET_CHECK_ACCOUNTResponse>(GET_CHECK_ACCOUNT, {
    variables: {
      organisationKey: organisation.key,
    },
    skip: !hasPermission,
    selector: 'organisations[0]',
  });
};

const NoAccount = () => {
  const { t } = useTranslation();
  return (
    <Markdown
      content={t(`**Accessing Checking Services**

If you would like to start ordering Enhanced DBS and other background checks through the application, you will first need to contact us to open an account.

We have partnered with [Verifile](https://go.scrtracker.com/verifilehome) who will perform the checks, but checks will be managed and ordered through us via our portal.

When we add your account, you will be assigned a credit limit. This will reset at the beginning of each month when invoices are raised.

All purchases are billed via invoice at the end of each month and must be paid by BACS or Direct Debit using our provider GoCardless after 30 days.`)}
    />
  );
};

const CheckAccountContainer = () => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);

  const [canRequestAccount, requestAccount] = useRequestAccount();

  const hasFedPermission = usePermission(
    permissions.CHECK_ORDER_BILLING,
    federation?.id
  );

  const organisationQuery = useGetCheckAccountQuery();

  const federationQuery = useQuery(GET_FEDERATION_CURRENT_SPEND, {
    variables: {
      federationKey: federation?.key,
    },
    selector: 'federations[0].currentSpend',
    skip: !hasFedPermission || !federation,
  });

  const { loading, error } = useQueryResults({
    organisationQuery,
    federationQuery,
  });

  const allowExternalChecksPrint = usePermission(
    permissions.CHECK_ORDER_READ,
    organisation.id
  );
  const isExternalChecksReportEnabled = useFeature('reports.externalchecks');

  const [
    onReportClick,
    {
      component: ReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
    canPrintReports,
  ] = useBuildExternalChecksReport();
  const { t } = useTranslation();
  const [showSelectDialog, setShowSelectDialog] = useState(false);
  const [selectedType, setSelectedType] = useState('organisation');
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    DateTime.now().minus({ days: 7 }).toJSDate(),
    new Date(),
  ]);
  if (loading) {
    return (
      <SettingsPanel title={t('Credit Account')}>
        <Loading />
      </SettingsPanel>
    );
  }

  if (error) {
    return (
      <SettingsPanel title={t('Credit Account')}>
        <Error />
      </SettingsPanel>
    );
  }

  const { checkAccount, checkAccountStatus, currentSpend } =
    organisationQuery.data!;
  const federationCurrentSpend = federationQuery.data;

  const notFound = checkAccountStatus === 'notfound';

  const handlePrintClick = () => {
    setShowSelectDialog(true);
  };

  const startReportGeneration = (type: string) => {
    if (selectedDateRange[0] > selectedDateRange[1]) {
      AppToaster.show({
        message: t('Invalid Date Range.'),
        intent: 'danger',
      });
      return;
    }

    setShowSelectDialog(false);
    // The print is currently configured for external checks only
    return onReportClick(
      type.toUpperCase(),
      type === 'organisation' ? organisation?.id : federation?.id,
      selectedDateRange
    );
  };
  return (
    <>
      <ReportComponent {...reportProps} />

      <SettingsPanel
        title={t('Credit Account')}
        actions={[
          notFound && {
            text: t('Request Account'),
            icon: 'user-hard-hat',
            intent: 'danger',
            disabled: false,
            allowed: canRequestAccount,
            onClick: () => {
              analytics.events.checks.showRequestAccountClicked();
              return requestAccount();
            },
          },
          isExternalChecksReportEnabled && {
            text: t('Export'),
            icon: 'file-pdf',
            allowed: canPrintReports && allowExternalChecksPrint,
            disabled: reportDisabled,
            intent: 'success',
            onClick: handlePrintClick,
          },
        ]}
      >
        {notFound ? (
          <NoAccount />
        ) : (
          <>
            {checkAccount && (
              <>
                <div className="font-weight-bold">{t('Credit Limit')}</div>
                <Currency className="m-2" {...checkAccount.limit} />
              </>
            )}
            <>
              <div className="font-weight-bold">{`${organisation.name}
              ${t('Spend')}`}</div>
              <Currency className="m-2" {...currentSpend} />
            </>
            {federation && federationCurrentSpend && (
              <>
                <div className="font-weight-bold">{`${federation.name}
                ${t('Spend')}`}</div>
                <Currency className="m-2" {...federationCurrentSpend} />
              </>
            )}
            <>
              <Dialog
                className={Classes.DIALOG}
                isOpen={showSelectDialog}
                onClose={() => setShowSelectDialog(false)}
                title={t('External Checks Report')}
              >
                <div className={Classes.DIALOG_BODY}>
                  <div className="mb-2">
                    <div className="font-weight-bold">Date Range</div>
                    <DateFilter
                      large={false}
                      description={t('Select a date range...')}
                      dateRange={selectedDateRange}
                      setDateRange={setSelectedDateRange}
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold">Report Level</div>
                    <HTMLSelect
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option value="organisation">{organisation.name}</option>
                      {federation && (
                        <option value="federation">{federation.name}</option>
                      )}
                    </HTMLSelect>
                  </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                      className={Classes.BUTTON}
                      text={t('Generate Report')}
                      onClick={() => startReportGeneration(selectedType)}
                      intent="primary"
                    />
                  </div>
                </div>
              </Dialog>
            </>
          </>
        )}
      </SettingsPanel>
    </>
  );
};

export default CheckAccountContainer;
