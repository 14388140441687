import trackEvent from '../trackEvent';

export const viewClicked = (traits = {}) => {
  trackEvent('Insights - View Clicked', traits, true);
};

export const exportClicked = (traits = {}) => {
  trackEvent('Insights - Export Clicked', traits);
};

export const favouriteClicked = (traits = {}) => {
  trackEvent('Insights - Favourite Clicked', traits);
};

export const duplicateClicked = (traits = {}) => {
  trackEvent('Insights - Duplicated Clicked', traits);
};

export const addClicked = (traits = {}) => {
  trackEvent('Insights - Add Report Clicked', traits, true);
};

export const deleteClicked = (traits = {}) => {
  trackEvent('Insights - Delete Report Clicked', traits, true);
};

export const added = (traits = {}) => {
  trackEvent('Insights - Add Clicked', traits);
};

export const exportViewClicked = (traits = {}) => {
  trackEvent('Insights - Export while viewing Clicked', traits);
};

export const editDetail = (traits = {}) => {
  trackEvent('Insights - Edit Details Clicked', traits);
};

export const toggleAutoSave = (traits = {}) => {
  trackEvent('Insights - Toggle AutoSave Clicked', traits);
};

export const addReportGroupClicked = (traits = {}) => {
  trackEvent('Insights - Add Report Group Clicked', traits, true);
};
export const deleteReportGroupClicked = (traits = {}) => {
  trackEvent('Insights - Delete Report Group Clicked', traits, true);
};

export const editReportGroupsClicked = (traits = {}) => {
  trackEvent('Insights - Edit Report Groups Clicked', traits, true);
};
