import { gql } from '@apollo/client';

export const GET_DASHBOARDS = gql`
  query dashboards($organisationKey: String) {
    organisations(key: $organisationKey) {
      id
      dashboards {
        id
        name
        ownerType
        widgets {
          id
          type
          order
          settings
        }
      }
    }
  }
`;
