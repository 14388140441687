import { FormBuilder, FormField } from '@hogwarts/ui-components-forms';
import React, { useState } from 'react';

import { ActionBar } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import styles from '../styles.module.css';

interface ConnectionEditorProps {
  fields: FormField[];
  connection: any;
  onSave: (values: any) => void;
}
export const ConnectionEditor = ({
  fields,
  connection,
  onSave,
}: ConnectionEditorProps) => {
  const [currentValues, setCurrentValues] = useState(connection);
  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="m-1">
        <FormBuilder
          initialValues={connection}
          fields={fields}
          onValuesChanged={(values) => {
            // store values for saving
            setCurrentValues(values);
          }}
        />
      </div>
      <div className="d-flex flex-rows">
        <div className={cn(styles.titleText, 'flex-grow-1')} />
        <ActionBar
          large={false}
          actions={[
            {
              text: 'Save',
              intent: 'primary',
              icon: 'save',
              onClick: () => {
                return onSave(currentValues);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
