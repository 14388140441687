import React from 'react';

import { Markdown as MarkdownCore } from '@hogwarts/ui-components-core';

const Markdown = ({ label }) => {
  return <MarkdownCore content={label} />;
};

export default {
  name: 'Markdown',
  options: [],
  decorators: {
    label: false,
  },
  Component: Markdown,
};
