import React from 'react';
import { DateTime } from 'luxon';
import { TimePicker } from '@blueprintjs/datetime';

// https://github.com/moment/luxon/issues/675
// https://support.google.com/chrome/thread/29828561/chrome-80-shows-timestamp-24-xx-instead-of-00-00?hl=en
const TIME_ISO = {
  hour: '2-digit',
  minute: '2-digit',
  // hour12: false,
  hourCycle: 'h23',
};

const TimeComponent = ({ formField, fieldHelpers, readOnly }) => {
  const parsed = formField.value ? DateTime.fromISO(formField.value) : null;

  return (
    <TimePicker
      selectAllOnFocus
      useAmPm
      value={parsed && parsed.isValid ? parsed.toJSDate() : null}
      onBlur={formField.onBlur}
      onChange={(dateObject) => {
        const date = DateTime.fromJSDate(dateObject);
        const value = date && date.toLocaleString(TIME_ISO);
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(value);
      }}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'Time Picker',
  options: [],
  label: true,
  Component: TimeComponent,
};
