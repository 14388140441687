import {
  AnalyticsContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import {
  Button,
  Currency,
  Error as ErrorComponent,
} from '@hogwarts/ui-components-core';
import { Checkbox, Classes, Drawer, InputGroup } from '@blueprintjs/core';
import { DbsCheckTypeSelector, ExternalCheckSelector } from '../../components';
import React, { useContext, useMemo, useReducer, useState } from 'react';
import { ValidationError, ValidationErrors } from './ValidationErrors';
import { isFeatureEnabled, useFeature } from '../../hooks';
import { sortBy, uniqBy } from 'lodash';

import { AppToaster } from '@/utils/toaster';
import { ConditionGroup } from '@hogwarts/conditionals';
import { FormBuilder } from '@hogwarts/ui-components-forms';
import { GET_CHECK_ACCOUNTResponse } from '../../queries';
import { OrderError } from './OrderError';
import { User } from '../../types';
import { currency } from '@hogwarts/utils';
import { evaluateGroup } from '@hogwarts/scheme-profiles';
import { useGetCheckAccountQuery } from '../settings/CheckAccount';
import { useTranslation } from 'react-i18next';

type StateModes =
  | 'OFFLINE'
  | 'CHECK-SELECTION'
  | 'USER-ENTRY'
  | 'CONTACT-DETAILS'
  | 'DBS-CHECK-SELECTION'
  | 'REVIEW-ORDER'
  | 'ORDER-VALIDATION-ERRORS'
  | 'ORDER-ERRORS';

interface State {
  mode: StateModes;
  selectedChecks: { [key: string]: boolean };
  checkValues: any;

  dbsCheckTypeKey?: string;

  agree: boolean;
  purchaseOrder: string;

  fields?: any;
  initialValues?: any;
  valid?: boolean;
  errors?: ValidationError[];
  error?: string;
}

const initialState: State = {
  mode: 'CHECK-SELECTION',
  selectedChecks: {},
  checkValues: {},
  agree: false,
  purchaseOrder: '',
};

type ActionTypes =
  | 'SELECT_DBS_CHECKTYPE_CLICKED'
  | 'DBS-CHECKTYPE-SELECTED'
  | 'DBS-CHECK-SELECTION-GOBACK'
  | 'PURCHASE-ORDER-CHANGED'
  | 'AGREE-CHANGED'
  | 'CHECKS_SELECTED'
  | 'CHECK_VALUES_CHANGED'
  | 'ACCEPT_CHECK_SELECTION'
  | 'COMPLETED_USER_ENTRY'
  | 'USER_ENTRY_GO_BACK'
  | 'COMPLETED_CONTACT_DETAILS'
  | 'RESET'
  | 'CONTACT_DETAILS_GO_BACK'
  | 'ORDER_ERROR'
  | 'ORDER_FAILURE'
  | 'ORDER_ERRORS_GO_BACK';

interface Action extends Record<string, any> {
  type: ActionTypes;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SELECT_DBS_CHECKTYPE_CLICKED': {
      return {
        ...state,
        mode: 'DBS-CHECK-SELECTION',
      };
    }
    case 'DBS-CHECKTYPE-SELECTED': {
      return {
        ...state,
        mode: 'CHECK-SELECTION',
        selectedChecks: state.dbsCheckTypeKey
          ? {
              ...state.selectedChecks,
              [state.dbsCheckTypeKey]: false,
            }
          : state.selectedChecks,
        dbsCheckTypeKey: action.dbsCheckTypeKey,
      };
    }
    case 'PURCHASE-ORDER-CHANGED': {
      return {
        ...state,
        purchaseOrder: action.purchaseOrder,
      };
    }
    case 'AGREE-CHANGED': {
      return {
        ...state,
        agree: action.agree,
      };
    }
    case 'CHECKS_SELECTED': {
      return {
        ...state,
        fields: action.fields,
        initialValues: action.initialValues,
        checkValues: { ...action.initialValues },
        selectedChecks: action.selected,
      };
    }
    case 'CHECK_VALUES_CHANGED': {
      return {
        ...state,
        valid: action.valid,
        initialValues: { ...action.values },
        checkValues: action.values,
      };
    }
    case 'ACCEPT_CHECK_SELECTION': {
      return {
        ...state,
        mode: 'USER-ENTRY',
      };
    }
    case 'COMPLETED_USER_ENTRY': {
      return {
        ...state,
        mode: 'CONTACT-DETAILS',
      };
    }
    case 'DBS-CHECK-SELECTION-GOBACK': {
      return {
        ...state,
        mode: 'CHECK-SELECTION',
      };
    }
    case 'USER_ENTRY_GO_BACK': {
      return {
        ...state,
        agree: false,
        mode: 'CHECK-SELECTION',
      };
    }
    case 'COMPLETED_CONTACT_DETAILS': {
      return {
        ...state,
        mode: 'REVIEW-ORDER',
      };
    }
    case 'CONTACT_DETAILS_GO_BACK': {
      return {
        ...state,
        mode: 'USER-ENTRY',
      };
    }

    case 'RESET': {
      return {
        ...initialState,
      };
    }
    case 'ORDER_ERROR': {
      return {
        ...state,
        agree: false,
        // This allows the form to show the values we set
        // when/if we go back
        initialValues: state.checkValues,
        error: action.error,
        mode: 'ORDER-ERRORS',
      };
    }
    case 'ORDER_FAILURE': {
      return {
        ...state,
        agree: false,
        // This allows the form to show the values we set
        // when/if we go back
        initialValues: state.checkValues,
        errors: action.errors,
        mode: 'ORDER-VALIDATION-ERRORS',
      };
    }
    case 'ORDER_ERRORS_GO_BACK': {
      return {
        ...state,
        agree: false,
        mode: 'USER-ENTRY',
        errors: [],
      };
    }
    default: {
      throw new Error('Invalid Action');
    }
  }
};

type OrderHandler = (
  orderedChecks: string[],
  checkValues: any,
  purchaseOrder: string,
  userDetails: string,
  agreed: boolean
) => Promise<OrderResult>;

interface Price {
  value: number;
  currency: string;
}
interface CheckType {
  key: string;
  name: string;
  description: string;
  price: Price;
  exclusiveGroup?: string;
  condition?: ConditionGroup;
  display: any;
  requiredFields: any[];
}

interface ContactDetailsProps {
  name: string;
  phone: string;
  email: string;
}
const ConfirmContactDetails = (props: ContactDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {/* Write out why this is required */}
      <p className="font-weight-bold">
        {t(
          'The following contact information will be sent to Verifile along with the order.'
        )}
      </p>
      <p>
        {t(
          'This is so they know who to contact if there is an issue, and allow you to contact them directly if needed.'
        )}
      </p>

      <p className="ml-2">
        <span>{t('Name:')}&nbsp;</span>
        <span className="font-weight-bold">{props.name}</span>
      </p>
      <p className="ml-2">
        <span>{t('Email:')}&nbsp;</span>
        <span className="font-weight-bold">{props.email}</span>
      </p>
      <p className="ml-2">
        <span>{t('Phone:')}&nbsp;</span>
        <span className="font-weight-bold">{props.phone || '(not set)'}</span>
      </p>

      <p>
        {t(
          `If the information is wrong, please update this in your Settings > Preferences`
        )}
      </p>
    </div>
  );
};

interface ReviewOrderProps {
  checks: CheckType[];
  values: any;
  purchaseOrder: string;
  agree: boolean;
  onPurchaseOrderChanged: (purchaseOrder: string) => void;
  onAgreeChanged: (agree: boolean) => void;
  checkAccount?: GET_CHECK_ACCOUNTResponse;
}

const ReviewOrder = ({
  checks,
  checkAccount,
  purchaseOrder,
  onAgreeChanged,
  onPurchaseOrderChanged,
}: ReviewOrderProps) => {
  const { t } = useTranslation();
  const total = checks.reduce(
    (price, cur) => currency.add(price, cur.price),
    currency.ZERO
  );

  return (
    <div>
      <p className="font-weight-bold">{t(`Order Summary`)}</p>

      <div className="m-2">
        {checks.map((check) => (
          <div key={check.key}>{`1x ${check.name}`}</div>
        ))}
      </div>

      <p className="font-weight-bold">
        {t(`Total Price (ex VAT): `)}
        <Currency {...total} />
      </p>

      <p>
        {t(
          `Purchase Order (${
            checkAccount?.checkAccount?.poRequired ? 'required' : 'optional'
          })`
        )}
      </p>
      <InputGroup
        value={purchaseOrder}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          onPurchaseOrderChanged(target.value);
        }}
      ></InputGroup>

      <br />
      <Checkbox
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          const checked = target.checked;
          onAgreeChanged(checked);
        }}
      >
        <span>
          {t('I accept this is')} <b>{t('non refundable')}</b>{' '}
          {t('and I agree to Verifiles')}
        </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.verifile.co.uk/terms-and-conditions"
        >
          {t('Terms and Conditions')}
        </a>
      </Checkbox>
    </div>
  );
};

interface OrderResult {
  id: string;
  validationErrors: string[];
}

interface ShowCheckSelectionProps {
  checkAccountStatus: string;
  selectedChecks: Record<string, boolean>;
  onChecksSelected: (selected: any) => void;
  onSelectDbsCheckType: () => void;
  checkTypes: CheckType[];
}
const ShowCheckSelection = ({
  checkTypes,
  selectedChecks,
  onChecksSelected,
  onSelectDbsCheckType,
  checkAccountStatus,
}: ShowCheckSelectionProps) => {
  const { t } = useTranslation();

  switch (checkAccountStatus) {
    case 'disabled': {
      return (
        <ErrorComponent
          icon="disable"
          title={t('Account Disabled')}
          description={t(
            'The checks feature is currently disabled. Please contact support for more information'
          )}
        />
      );
    }
    case 'blocked': {
      return (
        <ErrorComponent
          icon="disable"
          title={t('Account Blocked')}
          description={t(
            'Your check account has been temporarily disabled. Please contact support for more information'
          )}
        />
      );
    }

    case 'notfound': {
      return (
        <ErrorComponent
          icon="cog"
          title={t('Account not Setup')}
          description={t(
            'You currently do not have an account setup. Please contact support for more information'
          )}
        />
      );
    }
    case 'nocredit': {
      return (
        <ErrorComponent
          icon="credit-card"
          title={t('Out of Credit')}
          description={t(
            'You have exceeded your credit limit. Please contact support'
          )}
        />
      );
    }
    case 'active':
    default: {
      break;
    }
  }

  if (!checkTypes?.length) {
    return <ErrorComponent title={t('No Checks Available')} />;
  }

  return (
    <ExternalCheckSelector
      checkTypes={checkTypes}
      selected={selectedChecks}
      onChange={onChecksSelected}
      onSelectDbsCheckType={onSelectDbsCheckType}
    />
  );
};

const getUserDetails = (user: User) => {
  return `Missing information will be provided by ${[
    user.name,
    user.preferences?.phone,
    user.email,
  ].join(', ')}`;
};

interface OrderCheckComponentProps {
  checkTypes: CheckType[];
  dbsCheckTypes: CheckType[];
  checkAccountStatus: string;
  isOffline: boolean;
  profileData: Record<string, unknown>;
  isOpen: boolean;
  onOrder: OrderHandler;
  onClose: () => void;
}
const OrderCheckComponent = ({
  checkTypes: otherCheckTypes,
  dbsCheckTypes,
  profileData,
  isOpen,
  isOffline,
  onOrder,
  onClose: onCloseCore,
  checkAccountStatus,
}: OrderCheckComponentProps) => {
  let [
    {
      dbsCheckTypeKey,
      selectedChecks,
      fields,
      initialValues,
      checkValues,
      agree,
      purchaseOrder,
      errors,
      error,
      mode,
      valid,
    },
    dispatch,
  ] = useReducer(reducer, { ...initialState });

  const { t } = useTranslation();
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const { data: checkAccount } = useGetCheckAccountQuery();
  const allCheckTypes = useMemo(() => {
    return [...otherCheckTypes, ...dbsCheckTypes];
  }, [dbsCheckTypes, otherCheckTypes]);

  const orderedChecks = useMemo(() => {
    return allCheckTypes?.filter((c) => selectedChecks[c.key]);
  }, [allCheckTypes, selectedChecks]);
  const hasSelectedCheck = orderedChecks?.length > 0;

  const checkTypes = useMemo(() => {
    if (dbsCheckTypes?.length) {
      let dbsCheckType: CheckType | undefined;
      if (dbsCheckTypeKey) {
        dbsCheckType = dbsCheckTypes?.find((d) => d.key === dbsCheckTypeKey);
        if (dbsCheckType) {
          return [dbsCheckType, ...otherCheckTypes];
        }
      }
      return [dbsCheckTypes[0], ...otherCheckTypes];
    }

    return [...otherCheckTypes];
  }, [dbsCheckTypeKey, dbsCheckTypes, otherCheckTypes]);

  if (!otherCheckTypes) return null;

  const onClose = () => {
    dispatch({ type: 'RESET' });
    onCloseCore();
  };

  if (isOffline) {
    mode = 'OFFLINE';
  }

  return (
    <Drawer
      enforceFocus={false}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={t('Order External Check')}
      size={'500px'}
      isOpen={isOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {mode === 'OFFLINE' && (
            <ErrorComponent
              icon="wrench"
              title={t('Verifile is Offline')}
              description={t(
                'Apologies, Verifile is currently offline for maintenance. Please try again in a few hours.'
              )}
            />
          )}
          {mode === 'CHECK-SELECTION' && (
            <ShowCheckSelection
              checkAccountStatus={checkAccountStatus}
              checkTypes={checkTypes}
              selectedChecks={selectedChecks}
              onSelectDbsCheckType={() => {
                dispatch({
                  type: 'SELECT_DBS_CHECKTYPE_CLICKED',
                });
              }}
              onChecksSelected={(selected) => {
                const fields = sortBy(
                  uniqBy(
                    checkTypes.reduce((prev, cur) => {
                      if (!selected[cur.key]) return prev;
                      return [...prev, ...cur.requiredFields];
                    }, [] as any[]),
                    'key'
                  ),
                  (f) => f.order
                );

                const initialValues = fields.reduce((prev, field) => {
                  if (typeof field.defaultValue !== 'undefined') {
                    prev[field.key] = field.defaultValue;
                  }
                  if (profileData[field.key]) {
                    prev[field.key] = profileData[field.key];
                  }
                  if (field.key === 'organisationName') {
                    prev[field.key] = organisation?.name;
                  }
                  return prev;
                }, {});

                dispatch({
                  type: 'CHECKS_SELECTED',
                  selected,
                  fields,
                  initialValues,
                });
              }}
            />
          )}

          {mode === 'DBS-CHECK-SELECTION' && (
            <DbsCheckTypeSelector
              checkTypes={dbsCheckTypes}
              selected={dbsCheckTypeKey || dbsCheckTypes[0].key}
              onChange={(dbsCheckTypeKey) => {
                dispatch({
                  type: 'DBS-CHECKTYPE-SELECTED',
                  dbsCheckTypeKey,
                });
              }}
            />
          )}

          {mode === 'USER-ENTRY' && (
            <FormBuilder
              initialValues={initialValues}
              fields={fields}
              validateAtStart
              onValuesChanged={(
                values: any,
                changed: any,
                props: { isValid: boolean }
              ) => {
                dispatch({
                  type: 'CHECK_VALUES_CHANGED',
                  values,
                  valid: props.isValid,
                });
              }}
            />
          )}

          {mode === 'CONTACT-DETAILS' && (
            <ConfirmContactDetails
              name={user.name}
              email={user.email}
              phone={user.preferences.phone}
            />
          )}

          {mode === 'REVIEW-ORDER' && (
            <ReviewOrder
              checkAccount={checkAccount}
              checks={orderedChecks}
              values={checkValues}
              agree={agree}
              purchaseOrder={purchaseOrder}
              onPurchaseOrderChanged={(purchaseOrder) => {
                if (purchaseOrder) {
                  analytics.events.checks.setPurchaseOrder();
                }
                dispatch({
                  type: 'PURCHASE-ORDER-CHANGED',
                  purchaseOrder,
                });
              }}
              onAgreeChanged={(agree) => {
                if (agree) {
                  analytics.events.checks.agreedToTerms();
                }
                dispatch({
                  type: 'AGREE-CHANGED',
                  agree,
                });
              }}
            />
          )}

          {mode === 'ORDER-VALIDATION-ERRORS' && (
            <ValidationErrors errors={errors} />
          )}
          {mode === 'ORDER-ERRORS' && <OrderError error={error} />}
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        {mode === 'DBS-CHECK-SELECTION' && (
          <Button
            large
            className="ml-1"
            intent="danger"
            onClick={() => {
              dispatch({
                type: 'DBS-CHECK-SELECTION-GOBACK',
              });
            }}
          >
            Cancel
          </Button>
        )}
        {mode === 'CHECK-SELECTION' && (
          <>
            <Button
              icon="user-check"
              large
              disabled={!hasSelectedCheck}
              onClick={() => {
                const selectedCheckTypes = Object.keys(selectedChecks).filter(
                  (c) => selectedChecks[c]
                );
                // set stage to be user details bits (so a form)
                analytics.events.checks.acceptedCheckSelection({
                  selectedCheckTypes,
                });
                dispatch({
                  type: 'ACCEPT_CHECK_SELECTION',
                });
              }}
              intent="success"
            >
              Next: Candidate Details
            </Button>
            <Button
              large
              className="ml-1"
              intent="danger"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {mode === 'USER-ENTRY' && (
          <>
            <Button
              large
              disabled={!valid}
              onClick={() => {
                if (!valid) {
                  return;
                }
                analytics.events.checks.completedUserEntry();
                dispatch({
                  type: 'COMPLETED_USER_ENTRY',
                });
              }}
              intent="success"
            >
              Next: Contact Details
            </Button>
            <Button
              large
              className="ml-1"
              intent="danger"
              onClick={() => {
                dispatch({
                  type: 'USER_ENTRY_GO_BACK',
                });
              }}
            >
              Back
            </Button>
          </>
        )}
        {mode === 'CONTACT-DETAILS' && (
          <>
            <Button
              large
              // disabled={!valid}
              onClick={() => {
                analytics.events.checks.completedContactDetails();
                dispatch({
                  type: 'COMPLETED_CONTACT_DETAILS',
                });
              }}
              intent="success"
            >
              Next: Review Order
            </Button>
            <Button
              large
              className="ml-1"
              intent="danger"
              onClick={() => {
                dispatch({
                  type: 'CONTACT_DETAILS_GO_BACK',
                });
              }}
            >
              Back
            </Button>
          </>
        )}
        {mode === 'REVIEW-ORDER' && (
          <>
            <Button
              icon="pound-sign"
              large
              disabled={
                checkAccount?.checkAccount?.poRequired
                  ? !(agree && purchaseOrder)
                  : !agree
              }
              onClick={async () => {
                try {
                  const order = await onOrder(
                    orderedChecks.map((c) => c.key),
                    checkValues,
                    purchaseOrder,
                    getUserDetails(user),
                    agree
                  );
                  if (order.id) {
                    analytics.events.checks.checkOrdered();
                    AppToaster.show({
                      intent: 'success',
                      icon: 'tick',
                      message: t('Order has been placed!'),
                    });
                    // TODO: Redirect to the checks page!?
                    onClose();
                  } else {
                    dispatch({
                      type: 'ORDER_FAILURE',
                      errors: order.validationErrors,
                    });
                  }
                } catch (e) {
                  const ex = e as Error;
                  dispatch({
                    type: 'ORDER_ERROR',
                    error: ex.message,
                  });
                }
              }}
              intent="success"
            >
              Purchase
            </Button>
            <Button
              large
              className="ml-1"
              intent="danger"
              onClick={() => {
                dispatch({
                  type: 'USER_ENTRY_GO_BACK',
                });
              }}
            >
              Back
            </Button>
          </>
        )}
        {(mode === 'ORDER-ERRORS' || mode === 'ORDER-VALIDATION-ERRORS') && (
          <Button
            large
            className="ml-1"
            intent="danger"
            onClick={() => {
              dispatch({
                type: 'ORDER_ERRORS_GO_BACK',
              });
            }}
          >
            Back
          </Button>
        )}
      </div>
    </Drawer>
  );
};

type useOrderCheckResult = [
  () => void,
  {
    component: typeof OrderCheckComponent; // React.ReactNode;
    props: OrderCheckComponentProps;
  }
];

const useOrderCheck = (
  initialCheckTypes: CheckType[],
  checkAccountStatus: string,
  profileType: string,
  profileData: any,
  onOrder: OrderHandler
): useOrderCheckResult => {
  const [isOpen, setIsOpen] = useState(false);

  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);

  const isOffline = useFeature('checks.offline');

  const [checkTypes, dbsCheckTypes] = useMemo(() => {
    if (!initialCheckTypes) return [[], []];

    const allCheckTypes = initialCheckTypes.filter((checkType) => {
      if (checkType.condition) {
        // Need the parent profile type
        let profileType2 = organisation.scheme.getProfileType(profileType);
        if (profileType2?.parent) {
          profileType2 = organisation.scheme.getProfileType(
            profileType2.parent
          );
        }

        if (!profileType2) {
          return false;
        }

        if (
          isFeatureEnabled(
            `checks.${checkType.key}.${profileType2.key}`,
            organisation
          ) === false
        ) {
          return false;
        }

        return evaluateGroup(
          checkType.condition,
          {
            typeKey$: profileType2.key,
          },
          {
            valid: {},
            values: profileData,
            visible: {},
          },
          { timezone: 'utc' }
        );
      }
      return true;
    });

    return allCheckTypes.reduce<[CheckType[], CheckType[]]>(
      (prev, cur) => {
        if (cur.exclusiveGroup === 'dbs') {
          prev[1].push(cur);
        } else {
          prev[0].push(cur);
        }
        return prev;
      },
      [[], []]
    );
  }, [initialCheckTypes, profileType, organisation, profileData]);

  return [
    () => {
      analytics.events.checks.orderCheckClicked({
        checkAccountStatus,
      });
      setIsOpen(true);
    },
    {
      component: OrderCheckComponent,
      props: {
        isOpen,
        isOffline,
        checkAccountStatus,
        checkTypes,
        dbsCheckTypes,
        onClose: () => {
          setIsOpen(false);
        },
        onOrder,
        profileData,
      },
    },
  ];
};

export default useOrderCheck;
