import { Button, Currency } from '@hogwarts/ui-components-core';

import { Callout } from '@blueprintjs/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import styles from './styles.module.css';

interface CheckType {
  key: string;
  name: string;
  price: {
    value: number;
    currency: string;
  };
  exclusiveGroup?: string;
  description: string;
}

interface MarkdownProps {
  description: string;
}
const Markdown = ({ description }: MarkdownProps) => {
  return (
    <ReactMarkdown
      linkTarget={'_blank'}
      components={{
        a: ({ node, children, ...props }) => {
          const linkProps = props;
          if (props.target === '_blank') {
            linkProps['rel'] = 'noopener noreferrer';
          }
          return <a {...linkProps}>{children}</a>;
        },
      }}
    >
      {description}
    </ReactMarkdown>
  );
};

interface DbsCheckTypeSelectorProps {
  checkTypes: CheckType[];
  selected?: string;
  onChange: (key: string) => void;
}
export const DbsCheckTypeSelector = ({
  checkTypes,
  selected,
  onChange,
}: DbsCheckTypeSelectorProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {checkTypes.map((checkType) => (
        <Callout className="mb-2" key={checkType.key}>
          <div className={cn('d-flex flex-grow-1 align-items-center')}>
            <span
              className={cn(
                'flex-grow-1 font-weight-bold py-2',
                styles.checkboxItem
              )}
            >
              {checkType.name}
            </span>
            <Currency {...checkType.price} className={styles.checkboxItem} />
          </div>

          <div className={cn('flex-row', styles.block)}>
            <Markdown description={checkType.description} />
          </div>

          {checkType.key !== selected ? (
            <Button
              className="mt-2"
              intent={'primary'}
              onClick={() => onChange(checkType.key)}
            >
              {t('Change DBS Check Type')}
            </Button>
          ) : (
            <Button
              className="mt-2"
              intent={'primary'}
              disabled
              onClick={() => {}}
            >
              {t('(Current Selection)')}
            </Button>
          )}
        </Callout>
      ))}
    </div>
  );
};
