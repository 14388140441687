import React, { useContext, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import { useFeature, usePermission } from '../../hooks';

import { parse } from 'qs';
import { routesFactory } from '../../routing';
import { authenticationService } from '../../services';
import { Organisation } from '../../types';
import MfaSettingsContainer from '../settings/MFA';
import RenderDenied from './AccessDenied';
import Content from './Content';
import RenderNotFound from './NotFound';
import RenderSuspended from './Suspended';
import { TermsAndConditions } from './TermsAndConditions';

interface RouteRendererProps {
  query: any;
  user: any;
  organisations: any[];
  organisation: any;
  route: any;
}
const RouteRenderer = ({
  query,
  user,
  organisations,
  organisation,
  route,
  ...props
}: RouteRendererProps) => {
  const allowed = usePermission(route.permission, organisation?.id);
  return (
    <Content hideHeader={route.hideHeader} organisations={organisations}>
      {allowed == null ? (
        <div />
      ) : allowed ? (
        <route.Component
          organisation={organisation}
          organisationKey={organisation.key}
          user={user}
          query={query}
          {...props}
        />
      ) : (
        <RenderDenied />
      )}
    </Content>
  );
};

interface ContentRouteSelectorProps {
  organisations: Organisation[];
}
const ContentRouteSelector = ({ organisations }: ContentRouteSelectorProps) => {
  const location = useLocation();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const routes = useMemo(() => routesFactory(organisation), [organisation]);

  const user = useContext(UserContext);
  const enforceMfaEnabled = useFeature('security.enforcemfa');
  const termsRequired = useFeature('functions.terms_and_conditions_required');

  const requiresSSO = authenticationService.requiresSSO();

  const query = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location]
  );

  if (federation?.attributes?.suspended) {
    return (
      <Content hideHeader={false} organisations={organisations}>
        <RenderSuspended />
      </Content>
    );
  }

  if (organisation?.attributes?.suspended) {
    return (
      <Content hideHeader={false} organisations={organisations}>
        <RenderSuspended />
      </Content>
    );
  }

  // Display MFA settings if MFA is enforced and the user does not have MFA enabled.
  // Need to test requiresSSO somehow.
  if (!requiresSSO && enforceMfaEnabled && !user.mfaEnabled) {
    return (
      <Content
        hideHeader={false}
        organisations={organisations}
        enforceMode={true}
      >
        <MfaSettingsContainer enforceMode={true} />
      </Content>
    );
  }

  if (termsRequired && !user.acceptedTerms) {
    return (
      <Content
        hideHeader={false}
        organisations={organisations}
        enforceMode={true}
      >
        <TermsAndConditions />
      </Content>
    );
  }

  return (
    <Switch>
      {routes.map((route: any, index: number) => (
        <Route
          key={index}
          exact
          path={route.path}
          render={(props: any) => (
            <RouteRenderer
              organisation={organisation}
              organisations={organisations}
              user={user}
              query={query}
              route={route}
              {...props}
            />
          )}
        />
      ))}
      <Route
        component={() => (
          <Content hideHeader={false}>
            <RenderNotFound />
          </Content>
        )}
      />
    </Switch>
  );
};

export default ContentRouteSelector;
