import React from 'react';
import { Select } from '@hogwarts/ui-components-core';
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

const SelectFilter = ({
  loading,
  icon,
  description,
  items,
  selectedItem,
  setSelectedItem,
  className,
  large,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      large={large}
      icon={loading ? <Spinner size="18" /> : icon}
      className={className}
      disabled={loading}
      items={items}
      value={selectedItem}
      valueField="id"
      textField="value"
      fill={false}
      intent={selectedItem ? 'success' : 'none'}
      onChange={setSelectedItem}
      itemsDescription={loading ? t('Loading Items') : description}
    />
  );
};

export default SelectFilter;
