import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsPanel } from '@/components/Settings/SettingsPanel';

const UserSupport = ({ caseOpen, loading, onOpenSupport, onCloseSupport }) => {
  const { t } = useTranslation();

  const actions = [
    caseOpen
      ? {
          text: t('Stop Impersonation'),
          icon: 'save',
          disabled: false,
          intent: 'warning',
          onClick: async () => onCloseSupport(),
        }
      : {
          text: t('Allow Impersonation'),
          icon: 'save',
          disabled: false,
          intent: 'danger',
          onClick: async () => onOpenSupport(),
        },
  ];

  return (
    <SettingsPanel loading={loading} title="Support" actions={actions}>
      <p>
        If you require assistance from the Customer Success team, with your
        permission, we are able to impersonate your login.
      </p>
      <p>
        This will grant us <b>only</b> the access that you have, so you will
        need to contact your Administrators if you require something that is
        above your permission level.
      </p>
    </SettingsPanel>
  );
};

export default UserSupport;
