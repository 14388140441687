import { Checkbox } from '@blueprintjs/core';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectOptions } from './utils';

const CheckListComponent = ({
  formField,
  formik,
  readOnly,
  values: unparsedValues,
  fieldHelpers,
  selectionLimit,
  selectionMinimum,
  showSelectAll,
  selectAllText,
}) => {
  const { t } = useTranslation();
  const values = useMemo(() => selectOptions(unparsedValues), [unparsedValues]);
  const all = formField.value === null;
  const selectedCount = useMemo(() => {
    if (!formField.value) return 0;
    return Object.keys(formField.value).reduce((prev, curr) => {
      return formField.value[curr] ? ++prev : prev;
    }, 0);
  }, [formField.value]);
  return (
    <>
      {showSelectAll && (
        <Checkbox
          key={'select-all'}
          disabled={readOnly}
          checked={all}
          onChange={async (args) => {
            const checked = args.target.checked;
            await fieldHelpers.setTouched(true, false);
            if (checked) {
              await formik.setFieldValue(`${formField.name}`, null);
            } else {
              const allSelected = values.reduce(
                (prev, cur) => ({
                  ...prev,
                  [cur.id]: true,
                }),
                {}
              );

              await formik.setFieldValue(`${formField.name}`, allSelected);
            }
          }}
        >
          {t(selectAllText || 'Select All')}
        </Checkbox>
      )}
      {values.map(({ id, value }) => {
        const checked = all || !!get(formField.value, id);
        return (
          <Checkbox
            className={showSelectAll ? 'ml-2' : null}
            key={id}
            disabled={
              all ||
              readOnly ||
              (selectionMinimum > 0 &&
                checked &&
                selectedCount <= selectionMinimum) ||
              (!checked && selectionLimit <= selectedCount)
            }
            checked={checked}
            onChange={async (args) => {
              const checked = args.target.checked;
              await fieldHelpers.setTouched(true, false);
              await formik.setFieldValue(`${formField.name}.${id}`, checked);
            }}
          >
            {value}
          </Checkbox>
        );
      })}
    </>
  );
};

export default {
  name: 'Check List',
  options: [],
  label: true,
  Component: CheckListComponent,
};
