import { Tag } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import styles from './styles.module.css';

interface Tab {
  key: string;
  name: string;
  badge?: number;
  denied?: boolean;
}
interface TabBarProps {
  className?: string;
  tabs: Tab[];
  activeTab?: string;
  onTabChange?: (tabKey: string) => void;
}
export const TabBar = ({
  tabs = [],
  activeTab,
  onTabChange,
  className,
}: TabBarProps) => {
  return (
    <ul className={cn('nav nav-tabs', styles.tabsContainer, className)}>
      {tabs.map((tab) => (
        <li
          key={tab.key}
          className={cn('nav-item', styles.tab, {
            [styles.activeTab]: tab.key === activeTab,
          })}
          onClick={() => {
            if (tab.denied) return;
            if (onTabChange) {
              onTabChange(tab.key);
            }
          }}
        >
          <div className={styles.tabLink}>
            {tab.denied && <FontAwesomeIcon icon={'lock'} className="mr-2" />}
            {tab.name}
            {!tab.denied && tab.badge != null && (
              <Tag className={styles.tabBadge} round minimal intent="primary">
                {tab.badge}
              </Tag>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
