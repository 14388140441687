import { Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MultiSelectFilter = ({
  loading,
  icon,
  description,
  items,
  setSelectedItems,
  selectedItems,
  className,
  large,
  onClear,
}) => {
  const { t } = useTranslation();

  return (
    <MultiSelect
      placeholder={description}
      large={large}
      icon={loading ? <Spinner size="18" /> : icon}
      className={className}
      disabled={loading}
      items={items}
      valueField="id"
      textField="value"
      fill={false}
      intent={selectedItems?.length ? 'success' : 'none'}
      selectedItems={selectedItems}
      onChange={setSelectedItems}
      itemsDescription={loading ? t('Loading Items') : description}
      onClear={onClear}
    />
  );
};

export default MultiSelectFilter;
