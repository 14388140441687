import permissions from '@hogwarts/permissions';
import { Error, Loading, PageTitle } from '@hogwarts/ui-components-core';
import { useFormState } from '@hogwarts/ui-components-forms';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { StringParam, useQueryParam } from 'use-query-params';
import uuid from 'uuid/v4';
import DashboardLayout from '../../components/Dashboards/DashboardLayout';
import DashboardsSelector from '../../components/Dashboards/DashboardSelector';
import NoDashboards from '../../components/Dashboards/NoDashboards';
import WidgetLibraryDrawer from '../../components/Dashboards/WidgetLibraryDrawer';
import PageHeader from '../../components/PageHeader';
import TimezoneWarning from '../../components/TimezoneWarning';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import { useMutation, usePermission, useQuery } from '../../hooks';
import {
  DELETE_DASHBOARD,
  UPDATE_DASHBOARD_WIDGETS,
  UPDATE_USER_PREFERENCES,
} from '../../mutations';
import { GET_CURRENT_USER, GET_DASHBOARDS } from '../../queries';
import EditDashboardForm from './EditDashboardForm';
import TransfersWarning from './transfersWarning';
import {
  getFederationWidgets,
  getOrganisationWidgets,
  getWidget,
} from './widgets';

const WidgetList = ({
  canEditCurrentDashboard,
  setIsLibraryOpen,
  onWidgetsUpdated,
  widgets: providedWidgets,
}) => {
  const lastValues = useRef({});
  const analytics = useContext(AnalyticsContext);

  const widgets = useMemo(() => {
    return providedWidgets.map((widgetInstance, idx) => {
      const widgetDefinition = getWidget(widgetInstance.type);
      // This prevents recreating of the Components each time
      // they are tweaked
      let Component = lastValues.current[widgetInstance.id];
      if (!Component) {
        Component = SortableElement(widgetDefinition.widgetComponent);
        lastValues.current[widgetInstance.id] = Component;
      }
      return {
        widgetDefinition,
        widgetInstance,
        Component,
      };
    });
  }, [providedWidgets]);

  return (
    <DashboardLayout
      onAddWidget={() => {
        analytics.events.dashboard.addWidgetClicked();
        setIsLibraryOpen(true);
      }}
    >
      {widgets.map(({ widgetDefinition, widgetInstance, Component }, idx) => {
        return (
          <React.Fragment key={`widget-${widgetInstance.id}`}>
            <Component
              index={idx}
              widgetId={widgetInstance.id}
              settings={{
                ...widgetDefinition.defaultSettings,
                ...widgetInstance.settings,
              }}
              onSettingsUpdate={
                canEditCurrentDashboard
                  ? (newSettings) => {
                      analytics.events.dashboard.widgetSettingsUpdated();
                      const updatedWidgets = [...providedWidgets];
                      updatedWidgets[idx].settings = newSettings;
                      onWidgetsUpdated(updatedWidgets);
                    }
                  : null
              }
              onRemoveClick={
                canEditCurrentDashboard
                  ? () => {
                      analytics.events.dashboard.widgetRemoved();
                      const updatedWidgets = [...providedWidgets];
                      updatedWidgets.splice(idx, 1);
                      onWidgetsUpdated(updatedWidgets);
                    }
                  : null
              }
            />
            {/* Reference div for scrolling to after adding new widget */}
            <div id={widgetInstance.id} />
          </React.Fragment>
        );
      })}
    </DashboardLayout>
  );
};

const SortableWidgetList = SortableContainer(WidgetList);

const Dashboards = () => {
  const analytics = useContext(AnalyticsContext);
  const forms = useFormState();
  const history = useHistory();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const user = useContext(UserContext);
  const [currentDashboard, setCurrentDashboard] = useState(null);
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);

  useEffect(() => {
    analytics.events.dashboard.dashboardViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canEditOrganisationDashboards = usePermission(
    permissions.DASHBOARD_UPDATE,
    organisation.id
  );
  const canDeleteOrganisationDashboards = usePermission(
    permissions.DASHBOARD_DELETE,
    organisation.id
  );
  const canEditFederationDashboards = usePermission(
    permissions.DASHBOARD_UPDATE,
    federation?.id
  );
  const canDeleteFederationDashboards = usePermission(
    permissions.DASHBOARD_DELETE,
    federation?.id
  );

  const canDeleteDashboard = (dashboard) => {
    if (!dashboard) {
      return false;
    }
    if (dashboard.ownerType === 'ORGANISATION') {
      return canDeleteOrganisationDashboards;
    } else if (dashboard.ownerType === 'FEDERATION') {
      return canDeleteFederationDashboards;
    }
    return true;
  };

  const canEditDashboard = (dashboard) => {
    if (!dashboard) {
      return false;
    }
    if (dashboard.ownerType === 'ORGANISATION') {
      return canEditOrganisationDashboards;
    } else if (dashboard.ownerType === 'FEDERATION') {
      return canEditFederationDashboards;
    }
    return true;
  };

  const {
    data: dashboards,
    loading,
    error,
  } = useQuery(GET_DASHBOARDS, {
    fetchPolicy: 'network-only',
    selector: 'organisations[0].dashboards',
    variables: {
      organisationKey: organisation.key,
    },
    transform: (data) =>
      data.map(({ __typename, ...dashboard }) => ({
        ...dashboard,
        canEdit: canEditDashboard(dashboard),
        canDelete: canDeleteDashboard(dashboard),
        widgets: dashboard.widgets.map(({ __typename, ...widget }) => widget),
      })),
  });
  const [persistDashboardWidgets] = useMutation(UPDATE_DASHBOARD_WIDGETS, {
    selector: 'updateDashboardWidgets',
  });
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });
  const [deleteDashboard] = useMutation(DELETE_DASHBOARD, {
    refetchQueries: [
      {
        query: GET_DASHBOARDS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const userDefaultDashboardId = user.preferences?.defaultDashboardId;
  const [userSelectedDashboard = userDefaultDashboardId] = useQueryParam(
    'dash',
    StringParam
  );

  useEffect(() => {
    if (!Array.isArray(dashboards)) return;

    if (!dashboards?.length) {
      setCurrentDashboard(null);
    }

    let requestedDashboard;
    if (userSelectedDashboard) {
      requestedDashboard = dashboards.find(
        (d) => d.id === userSelectedDashboard
      );
    }
    if (!requestedDashboard) {
      requestedDashboard = dashboards[0];
    }
    if (requestedDashboard) {
      setCurrentDashboard(requestedDashboard);
    }
  }, [dashboards, userSelectedDashboard]);

  const groupedDashboards = useMemo(() => {
    if (!dashboards) {
      return {
        userDashboards: [],
        organisationDashboards: [],
        federationDashboards: [],
      };
    }
    return dashboards.reduce(
      (prev, curr) => {
        if (curr.ownerType === 'ORGANISATION') {
          prev.organisationDashboards.push(curr);
        } else if (curr.ownerType === 'USER') {
          prev.userDashboards.push(curr);
        } else if (curr.ownerType === 'FEDERATION') {
          prev.federationDashboards.push(curr);
        }
        return prev;
      },
      {
        userDashboards: [],
        organisationDashboards: [],
        federationDashboards: [],
      }
    );
  }, [dashboards]);

  if (error) {
    return <Error />;
  }

  if (loading || (dashboards?.length && !currentDashboard)) {
    return <Loading showLogo />;
  }

  if (!loading && !currentDashboard) {
    return (
      <>
        <PageHeader header={organisation && organisation.name} actions={[]} />
        <NoDashboards
          onAddClick={() => {
            analytics.events.dashboard.addDashboardClicked();
            forms.showForm('addDashboard');
          }}
        />
      </>
    );
  }

  return (
    <>
      <PageTitle title="Dashboard" />
      <PageHeader
        noWrapper
        header={
          <DashboardsSelector
            defaultDashboardId={user.preferences?.defaultDashboardId}
            organisationName={organisation.name}
            currentDashboard={currentDashboard ? currentDashboard.name : null}
            userDashboards={groupedDashboards.userDashboards}
            organisationDashboards={groupedDashboards.organisationDashboards}
            federationDashboards={groupedDashboards.federationDashboards}
            onDashboardSelected={(dashboard) => {
              analytics.events.dashboard.dashboardSwitched();
              history.push(`/${organisation.key}?dash=${dashboard.id}`);
            }}
            onDashboardDelete={async (dashboard) => {
              analytics.events.dashboard.dashboardRemoved();
              await deleteDashboard({
                variables: {
                  dashboardId: dashboard.id,
                },
              });
              // history.push(`/${organisation.key}`);
            }}
            onDashboardEdit={(dashboard) => {
              analytics.events.dashboard.dashboardEditClicked();
              forms.showForm('editDashboard', {
                initialValues: {
                  dashboardId: dashboard.id,
                  name: dashboard.name,
                  ownerType: dashboard.ownerType,
                },
              });
            }}
            onDashboardSetDefault={async (dashboard) => {
              analytics.events.dashboard.dashboardFavourited();
              await updateUserPreferences({
                variables: {
                  updatedPreferences: {
                    defaultDashboardId: dashboard.id,
                  },
                },
              });
            }}
          />
        }
        actions={[
          {
            text: 'Add Widget',
            icon: 'plus',
            allowed: currentDashboard.canEdit,
            disabled: false,
            intent: 'primary',
            onClick: () => {
              analytics.events.dashboard.addWidgetClicked();
              setIsLibraryOpen(true);
            },
          },
        ]}
        notificationChildren={
          <>
            <TimezoneWarning />
            <TransfersWarning
              organisationId={organisation.id}
              organisationKey={organisation.key}
            />
          </>
        }
      />
      <EditDashboardForm />
      <WidgetLibraryDrawer
        isOpen={isLibraryOpen}
        onClose={() => setIsLibraryOpen(false)}
        groups={[
          {
            label: 'Organisation',
            widgets: getOrganisationWidgets(organisation, federation),
          },
          federation && {
            label: 'Federation',
            widgets: getFederationWidgets(organisation, federation),
          },
        ].filter(Boolean)}
        onWidgetSelected={(widget) => {
          analytics.events.dashboard.widgetAdded({
            type: widget.type,
          });
          const newWidgets = [...currentDashboard.widgets];
          newWidgets.push({
            id: uuid(),
            type: widget.type,
            order: (newWidgets[newWidgets.length - 1]?.order ?? 0) + 1,
            settings: widget.defaultSettings(organisation.scheme),
          });

          flushSync(() => {
            setCurrentDashboard({
              ...currentDashboard,
              widgets: newWidgets,
            });
          });

          setIsLibraryOpen(false);

          // Note: This also causes the Dashboards collect to update
          // through apollos cache
          persistDashboardWidgets({
            variables: {
              dashboardId: currentDashboard.id,
              widgets: newWidgets,
            },
          });

          // Scroll to the new widget, with 500ms timeout to prevent side effects
          // Can be improved with alternative method
          setTimeout(() => {
            const lastWidget = newWidgets[newWidgets.length - 1];
            const element = document.getElementById(lastWidget.id);
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
              });
            }
          }, 500);
        }}
      />
      {currentDashboard && Array.isArray(currentDashboard.widgets) && (
        <SortableWidgetList
          widgets={currentDashboard.widgets}
          canEditCurrentDashboard={currentDashboard.canEdit}
          setIsLibraryOpen={setIsLibraryOpen}
          useDragHandle
          axis="xy"
          helperClass="dragging-widget"
          shouldCancelStart={() => {
            if (!currentDashboard.canEdit) {
              // This appears during any sort of interaction with the Widget
              // so I'm disabling it for now
              // I assume its some event propogation issue
              // AppToaster.show(
              //   {
              //     message: "You don't have permission to edit this dashboard",
              //     intent: 'warning',
              //   },
              //   'edit_dashboard_permission',
              // );
            }
            return !currentDashboard.canEdit;
          }}
          onWidgetsUpdated={(updatedWidgets) => {
            if (!currentDashboard.canEdit) {
              return;
            }
            setCurrentDashboard({
              ...currentDashboard,
              widgets: updatedWidgets,
            });
            persistDashboardWidgets({
              variables: {
                dashboardId: currentDashboard.id,
                widgets: updatedWidgets,
              },
            });
          }}
          onSortEnd={({ oldIndex, newIndex }) => {
            if (!currentDashboard.canEdit) {
              return;
            }
            analytics.events.dashboard.widgetsReordered();
            let newWidgetOrder = [...currentDashboard.widgets];
            const startIndex =
              oldIndex < 0 ? newWidgetOrder.length + oldIndex : oldIndex;
            if (startIndex >= 0 && startIndex < newWidgetOrder.length) {
              const endIndex =
                newIndex < 0 ? newWidgetOrder.length + newIndex : newIndex;
              const [item] = newWidgetOrder.splice(oldIndex, 1);
              newWidgetOrder.splice(endIndex, 0, item);
            }
            newWidgetOrder = newWidgetOrder.map((w, idx) => ({
              ...w,
              order: idx + 1,
            }));
            setCurrentDashboard({
              ...currentDashboard,
              widgets: newWidgetOrder,
            });
            persistDashboardWidgets({
              variables: {
                dashboardId: currentDashboard.id,
                widgets: newWidgetOrder,
              },
            });
          }}
        />
      )}
    </>
  );
};

export default Dashboards;
