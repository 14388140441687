import React from 'react';
import { ReactComponent as SIALogo } from '../../../assets/integrations/sia.svg';
import { useTranslation } from 'react-i18next';

const SignInAppNotConnected = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-25">
        <SIALogo />
      </div>
      <div className="my-4">
        <p>
          {t(`Connecting to Sign In App allows you to see the status of synchronised
          profiles inside your visitor system.`)}
        </p>
        <p>
          {t(`Select from the buttons above to connect either your organisation or
          federation to Sign In App.`)}
        </p>
      </div>
    </div>
  );
};

export default SignInAppNotConnected;
