import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SavingSpinnerProps {
  text: string;
  show?: boolean;
}
export const SavingSpinner = ({ text, show }: SavingSpinnerProps) => {
  if (show) {
    return (
      <div className={'col-2'}>
        <FontAwesomeIcon icon="spinner" spin />
        <span className="ml-2">{`${text}...`}</span>
      </div>
    );
  }
  return null;
};
