import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

const CalloutComponent = ({ text, icon, intent, message }) => {
  const { t } = useTranslation();

  const messageComponents = (message || '').split('\n').reduce((prev, text) => {
    return [...prev, <div>{t(text)}</div>, <br />];
  }, []);
  messageComponents.pop();

  return (
    <Callout
      className={'mb-3'}
      intent={intent || Intent.Warning}
      icon={icon}
      title={t(text || '')}
    >
      {messageComponents}
    </Callout>
  );
};

export default {
  name: 'Callout',
  options: [
    {
      key: 'text',
    },
    {
      key: 'message',
    },
    {
      key: 'intent',
    },
    {
      key: 'icon',
    },
  ],
  decorators: {
    label: false,
  },
  Component: CalloutComponent,
};
