import React from 'react';
import LateDatesByProfileList from './lateDatesByProfileList';

const FederationLateDatesByProfileList = {
  type: 'federation-late-dates-by-profile-list',
  name: 'Federation Profiles with Late Dates List',
  requiresFederation: true,
  description:
    'Displays a list of profiles where fields with the Late Date Check are later than the Start Date.',
  widgetComponent: (props) => (
    <LateDatesByProfileList isFederationWidget {...props} />
  ),
  defaultSettings: () => ({
    title: 'Federation Profiles with Late Dates List',
    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,
    show_startdate: true,
    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
};

export default FederationLateDatesByProfileList;
