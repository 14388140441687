import ProfileSummaryWidget from '@/components/Dashboards/Widgets/ProfileSummary';
import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import { useQuery } from '@/hooks';
import { DATASOURCE_QUERY_FEDERATION_PROFILE_SUMMARY } from '@/queries';
import { NonIdealState } from '@blueprintjs/core';
import { useForms } from '@hogwarts/ui-components-forms';
import { keys } from '@hogwarts/utils';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FederationContext, OrganisationContext } from '../../../context';

const FederationProfileSummaryContainer = ({
  settings,
  onSettingsUpdate,
  ...rest
}) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  const { loading, error, data } = useQuery(
    DATASOURCE_QUERY_FEDERATION_PROFILE_SUMMARY,
    {
      skip: !federation,
      selector: 'queryFederationProfileSummary.result',
      variables: {
        federationKey: federation?.key,
      },
      pollInterval: organisation.demo ? 20000 : 60000,
      transform: (data) => {
        let { profileType, allTags, allProfileTypes } = data || {};
        allProfileTypes = keys(allProfileTypes);
        allTags = keys(allTags);
        return { profileType, allProfileTypes, allTags };
      },
    }
  );

  const { profileType, allTags, allProfileTypes } = data || {};

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values:
          allTags?.map((tag) => ({
            id: tag.key,
            value: tag.label,
          })) || [],
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values:
          allProfileTypes?.map((pt) => ({
            id: pt.key,
            value: pt.label,
          })) || [],
      },
    ],
    initialValues: settings,
  });

  const countData = useMemo(() => {
    if (profileType) {
      return Object.keys(profileType).reduce((prev, curr) => {
        if (
          !settings.includedProfileTypes ||
          settings.includedProfileTypes[curr]
        ) {
          const newScores = {};
          Object.keys(profileType[curr]).forEach((tagKey) => {
            if (settings.includedTags[tagKey]) {
              newScores[tagKey] = profileType[curr][tagKey];
            }
          });
          prev[curr] = newScores;
        }
        return prev;
      }, {});
    }
  }, [profileType, settings.includedProfileTypes, settings.includedTags]);

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      {Object.keys(countData || {}).length ? (
        <ProfileSummaryWidget
          tags={allTags}
          profileTypes={allProfileTypes}
          profileCounts={countData}
          iconPack={organisation.attributes?.avatarIconPack}
        />
      ) : (
        <NonIdealState
          icon="cog"
          title={t('Nothing to show')}
          description={t('You may need to adjust your widget settings')}
        />
      )}
    </WidgetWrapper>
  );
};

export default {
  type: 'federation-profile-summary',
  name: 'Federation Profile Types Summary',
  requiresFederation: true,
  description:
    'Display the profile type avatars so that you can quickly see profile counts across the entire Federation.',
  widgetComponent: FederationProfileSummaryContainer,
  defaultSettings: (scheme) => ({
    title: 'Federation Profiles Summary',
    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,
  }),
};
