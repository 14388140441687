import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button } from '@blueprintjs/core';
import { selectOptions } from './utils';

const MultiSelectComponent = ({
  formField,
  fieldHelpers,
  readOnly,
  values: unparsedValues,
  activeIntent,
  inactiveIntent,
}) => {
  const { t } = useTranslation();
  const values = useMemo(() => selectOptions(unparsedValues), [unparsedValues]);

  let selectedValues = {};
  if (Array.isArray(formField.value)) {
    selectedValues = formField.value.reduce(
      (prev, cur) => ({ ...prev, [cur]: true }),
      {}
    );
  }
  return (
    <ButtonGroup disabled={readOnly}>
      {values.map(({ id, value }) => (
        <Button
          disabled={readOnly}
          key={id}
          active={!!selectedValues[id]}
          intent={!!selectedValues[id] ? activeIntent : inactiveIntent}
          onClick={() => {
            selectedValues = {
              ...selectedValues,
              [id]: !selectedValues[id],
            };

            const updated = values.reduce((prev, value) => {
              if (selectedValues[value.id]) {
                return [...prev, value.id];
              }
              return prev;
            }, []);
            fieldHelpers.setTouched(true, false);
            fieldHelpers.setValue(updated);
          }}
        >
          {t(value)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default {
  name: 'Multi-select Buttons',
  options: [],
  label: true,
  Component: MultiSelectComponent,
};
