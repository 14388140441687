import { gql } from '@apollo/client';
import * as fragments from '../fragments';

export const REQUEST_INSTALLATION = gql`
  mutation requestInstallation(
    $organisationKey: String!
    $details: InstallationDetails!
  ) {
    requestInstallation(organisationKey: $organisationKey, details: $details)
  }
`;

export const UPDATE_FEDERATION_PROVIDER_CONFIG = gql`
  ${fragments.DATA_PROVIDER}
  mutation updateFederationProviderConfig(
    $federationId: String!
    $config: ProviderConfigInput
  ) {
    updateFederationProviderConfig(
      federationId: $federationId
      config: $config
    ) {
      id
      key
      name
      enabled
      domain
      branding
      attributes
      provider {
        ...ProviderParts
      }
    }
  }
`;

export const ORGANISATION_DATA_PROVIDER_IMPORT_NOW = gql`
  ${fragments.DATA_PROVIDER}
  mutation queueOrganisationDataProviderImport(
    $organisationId: String!
    $discoveryMode: Boolean!
  ) {
    queueOrganisationDataProviderImport(
      organisationId: $organisationId
      discoveryMode: $discoveryMode
    ) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;

export const FEDERATION_DATA_PROVIDER_IMPORT_NOW = gql`
  ${fragments.DATA_PROVIDER}
  mutation queueFederationDataProviderImport(
    $federationId: String!
    $discoveryMode: Boolean!
  ) {
    queueFederationDataProviderImport(
      federationId: $federationId
      discoveryMode: $discoveryMode
    ) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;

// TODO: Need an "Organisation-Conditional" map from this one
export const CHANGE_FEDERATION_PROVIDER = gql`
  ${fragments.DATA_PROVIDER}
  mutation changeFederationProvider(
    $federationId: String!
    $providerKey: String!
  ) {
    changeFederationProvider(
      federationId: $federationId
      providerKey: $providerKey
    ) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;

export const CHANGE_ORGANISATION_PROVIDER = gql`
  ${fragments.DATA_PROVIDER}
  mutation changeOrganisationProvider(
    $organisationId: String!
    $providerKey: String!
  ) {
    changeOrganisationProvider(
      organisationId: $organisationId
      providerKey: $providerKey
    ) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;

export const UPDATE_ORGANISATION_PROVIDER_CONFIG = gql`
  ${fragments.DATA_PROVIDER}
  mutation updateOrganisationProviderConfig(
    $organisationId: String!
    $config: ProviderConfigInput
  ) {
    updateOrganisationProviderConfig(
      organisationId: $organisationId
      config: $config
    ) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;
