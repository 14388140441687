import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useImperativeHandle } from 'react';

// value: PropTypes.shape({
//   action: PropTypes.func,
//   name: PropTypes.string,
//   size: PropTypes.string,
//   color: PropTypes.string,
// }),

const FontAwesomeCellRenderer = ({ value }, ref) => {
  useImperativeHandle(ref, () => ({
    // getReactContainerClasses() {
    //     return ['custom-tooltip'];
    // },
    getReactContainerStyle() {
      return {
        display: 'inline-block',
        height: '100%',
        width: '100%',
      };
    },
  }));
  const { action, icon, size, color, ...rest } = value || {};
  return (
    <div className={'d-flex align-items-center justify-content-center h-100'}>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        size={size || '1x'}
        style={{ cursor: 'pointer' }}
        {...rest}
        onClick={action}
      />
    </div>
  );
};

export default forwardRef(FontAwesomeCellRenderer);
