import React from 'react';
import { ValidationRules } from '../ValidationRules';

const RenderValidationRules = ({
  fieldKey,
  formField,
  fieldHelpers,
  validationRules,
  readOnly,
  ...props
}) => {
  return (
    <ValidationRules
      id={fieldKey}
      {...props}
      onChange={async (rules) => {
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(rules);
      }}
      validationRules={validationRules}
      disabled={readOnly}
      selectedRules={formField.value}
    />
  );
};

export default {
  name: 'Validation Rules',
  options: [],
  Component: RenderValidationRules,
};
