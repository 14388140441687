import { GridView } from '@/components';
import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { FederationContext } from '@/context';
import { GET_FEDERATIONS } from '@/queries';
import React, { useContext, useMemo } from 'react';
import { useQuery } from '../../hooks';

const OrganisationList = () => {
  const federation = useContext(FederationContext);

  const {
    loading,
    data: organisations,
    error,
  } = useQuery(GET_FEDERATIONS, {
    key: 'organisations',
    selector: 'federations[0].organisations',
    variables: {
      federationKey: federation?.key,
    },
  });

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        label: 'Name',
        width: 600,
        sort: 'asc',
      },
      {
        key: 'key',
        label: 'Key',
      },
      // TODO: Scheme Feature?
      federation?.key === 'cognita' && {
        key: 'attributes.COGNITA_ID',
        label: 'Cognita IDs',
      },
    ].filter(Boolean);
  }, [federation.key]);

  return (
    <SettingsPanel
      title={'Organisations List'}
      loading={loading}
      error={error}
      fixedHeight="750px"
    >
      <GridView
        showSideBar={false}
        sizeColumnsToFit
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
        rows={organisations}
        columns={columns}
      />
    </SettingsPanel>
  );
};

export default OrganisationList;
