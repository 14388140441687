import React from 'react';
import cn from 'classnames';
import { Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortableHandle } from 'react-sortable-hoc';

import styles from './styles.module.css';

const DraggableHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

const RatingSystemButton = ({
  name,
  readyColor,
  notReadyColor,
  ratingSystemDisabled,
  selected,
  dragActive,
  onClick,
  onDoubleClick,
}) => {
  return (
    <div className={styles.systemContainer}>
      <Card
        elevation={1}
        className={cn(styles.systemCard, {
          [styles.buttonHover]: !dragActive,
          [styles.buttonSelected]: selected && !dragActive,
          [styles.systemDisabled]: ratingSystemDisabled,
        })}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <div
          className={styles.systemColour}
          style={{ backgroundColor: readyColor }}
        />
        <div
          className={styles.systemColour}
          style={{ backgroundColor: notReadyColor }}
        />
        <div className={styles.systemContent}>
          <p
            className={cn(styles.systemHeading, {
              [styles.systemHeadingDisabled]: ratingSystemDisabled,
            })}
          >
            {name}
          </p>
          <DraggableHandle />
        </div>
      </Card>
    </div>
  );
};

export default RatingSystemButton;
