import { Button, Card, Intent } from '@blueprintjs/core';
import {
  Condition as ConditionType,
  ConditionGroup,
  getUsedVariables,
  isConditionGroup,
  Operator,
  Variables,
} from '@hogwarts/conditionals';
import { capitalize } from '@hogwarts/utils';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionItem } from './Condition';
import styles from './styles.module.css';
import { FlatCondition, flattenCondition, inflateCondition } from './utils';

const getOperatorText = (operator?: string) => {
  if (!operator) {
    operator = '???';
  }
  const operatorText = capitalize(operator);
  return operatorText;
};

interface OperatorProps {
  operator?: string;
}
const OperatorUI = ({ operator }: OperatorProps) => {
  return (
    <p className="m-1 font-weight-bold text-center">
      {getOperatorText(operator)}
    </p>
  );
};

export interface ConditionalsProps {
  condition?: ConditionGroup;
  onChange: (condition: ConditionGroup) => void;
  readOnly?: boolean;
  variables?: Variables;
  structure?: any;
  defaultOperator: Operator;
  childGroupOperator: Operator;
}

export const Conditionals = ({
  condition: initialCondition,
  defaultOperator,
  childGroupOperator,
  onChange,
  readOnly,
  variables: parentVariables,
  structure,
}: ConditionalsProps) => {
  const conditionIndex = useRef(0);

  const { t } = useTranslation();

  const operator = useMemo(() => {
    if (initialCondition?.conditions.length === 0) {
      return defaultOperator;
    }

    return initialCondition?.operator || defaultOperator;
  }, [defaultOperator, initialCondition]);

  const [currentConditions, setCurrentConditions] = useState(() => {
    const variables = parentVariables || initialCondition?.variables || {};
    const conditions: FlatCondition[] = [];
    for (const condition of (initialCondition?.conditions || []).filter(
      Boolean
    )) {
      if (isConditionGroup(condition)) {
        conditions.push({
          key: `conditiongroup_${conditionIndex.current++}`,
          group: condition,
        });
      } else {
        const flat = flattenCondition(condition, variables);
        conditions.push({
          ...flat,
          key: `condition_${conditionIndex.current++}`,
        });
      }
    }
    return conditions;
  });

  const updateConditions = (conditions: FlatCondition[]) => {
    let allConditions: (ConditionType | ConditionGroup)[] = [];
    let allVariables: Variables = {
      ...initialCondition?.variables,
      ...parentVariables,
    };
    for (const flatCondition of conditions) {
      if (!flatCondition.key) {
        throw new Error('Missing Condition Key');
      }
      if (flatCondition.group) {
        const { variables, ...group } = flatCondition.group;
        allVariables = {
          ...allVariables,
          ...variables,
        };
        allConditions.push(group);
      } else {
        const [condition, variables] = inflateCondition(flatCondition);
        allVariables = {
          ...allVariables,
          ...variables,
        };
        allConditions.push(condition);
      }
    }

    const condition: ConditionGroup = {
      conditions: allConditions,
      variables: allVariables,
      enabled: true,
      operator,
    };

    const usedVariables = getUsedVariables(condition);
    condition.variables = Object.keys(usedVariables).reduce<Variables>(
      (prev, usedVarKey) => {
        return {
          ...prev,
          [usedVarKey]: allVariables[usedVarKey],
        };
      },
      {}
    );

    setCurrentConditions(conditions);
    onChange(condition);
  };

  const addCondition = () => {
    let conditions: FlatCondition[] = [
      ...currentConditions,
      {
        key: `condition_${conditionIndex.current++}`,
      },
    ];
    updateConditions(conditions);
  };
  const addConditionGroup = (operator: Operator) => {
    let conditions: FlatCondition[] = [
      ...currentConditions,
      {
        group: {
          conditions: [{} as ConditionType],
          enabled: true,
          operator,
        },
        key: `conditiongroup_${conditionIndex.current++}`,
      },
    ];
    updateConditions(conditions);
  };

  const conditionGroupChanged = (
    index: number,
    updatedConditionGroup: ConditionGroup
  ) => {
    const conditions = [...currentConditions];
    conditions[index] = {
      key: conditions[index].key,
      group: updatedConditionGroup,
    };
    updateConditions(conditions);
  };
  const conditionChanged = (index: number, updatedCondition: FlatCondition) => {
    const conditions = [...currentConditions];
    conditions[index] = {
      // @ts-ignore unsure why the key would be empty in updatedCondition
      // but I dont want to edit the logic
      key: conditions[index].key,
      ...updatedCondition,
    };
    updateConditions(conditions);
  };
  const deleteCondition = (key: string) => {
    const conditions = currentConditions.filter((c) => c.key !== key);
    updateConditions(conditions);
  };

  return (
    <div style={{ width: '100%' }} className={'w-100 flex-column'}>
      {currentConditions.length > 0 ? (
        currentConditions.map((conditionItem, index) => {
          const last = index === currentConditions.length - 1;
          return (
            <div key={conditionItem.key}>
              {conditionItem.group ? (
                <Card className={styles.cardConditionGroup}>
                  <Conditionals
                    defaultOperator={'and'}
                    childGroupOperator={childGroupOperator}
                    condition={conditionItem.group}
                    variables={parentVariables}
                    onChange={(conditionGroup) => {
                      if (conditionGroup.conditions.length) {
                        conditionGroupChanged(index, conditionGroup);
                      } else {
                        // kill it
                        deleteCondition(conditionItem.key);
                      }
                    }}
                    structure={structure}
                    readOnly={readOnly}
                  />
                </Card>
              ) : (
                <div className="d-flex flex-row flex-grow-1">
                  <ConditionItem
                    structure={structure}
                    onChange={(changedCondition) =>
                      conditionChanged(index, changedCondition)
                    }
                    condition={conditionItem}
                    readOnly={readOnly}
                  />
                  {
                    <Button
                      disabled={readOnly}
                      minimal={false}
                      icon="trash"
                      intent={Intent.DANGER}
                      onClick={() => deleteCondition(conditionItem.key!)}
                    />
                  }
                </div>
              )}
              {!last && <OperatorUI operator={operator} />}
            </div>
          );
        })
      ) : (
        <Button
          icon="add"
          onClick={() => {
            addConditionGroup(childGroupOperator);
          }}
          disabled={readOnly}
        >
          {t('Add a Condition')}
        </Button>
      )}
      {!readOnly && currentConditions.length > 0 && (
        <Button
          icon="add"
          className={'mt-2'}
          onClick={() => {
            const existingGroup =
              currentConditions[currentConditions.length - 1].group;
            if (existingGroup) {
              addConditionGroup(childGroupOperator);
            } else {
              addCondition();
            }
          }}
          disabled={readOnly}
        >
          {getOperatorText(operator)}
        </Button>
      )}
    </div>
  );
};
