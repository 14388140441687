import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoPopover } from '@hogwarts/ui-components-core';

import styles from './styles.module.css';

const SectionScore = ({
  name,
  score,
  stale,
  required,
  readyColor,
  notReadyColor,
}) => {
  const { t } = useTranslation();

  let scorePercentage = Math.trunc(score * 100);

  let color;
  let icon;
  let displayText;

  if (!required) {
    color = '#D0D0D0';
    scorePercentage = 100;
    displayText = `${t(name)} (${t('Not Required')})`;
    icon = (
      <InfoPopover
        monoButton
        content={t(`When this is not required, this is either because there are no
        fields that are required for your school type and this particular profile
        type; or there is an option to mark all of the checks for this profile as
        not required and it is selected.`)}
      />
    );
  } else if (score === 1) {
    color = readyColor;
    displayText = `${t(name)}`;
    icon = <FontAwesomeIcon icon={'check'} />;
  } else if (score < 1) {
    color = notReadyColor;
    displayText = `${scorePercentage}%`;
  }

  return (
    <div className={styles.progressBarContainer}>
      <div
        className={cn(styles.progressBar, 'progress-bar', {
          'progress-bar-animated': stale,
          'progress-bar-striped': stale,
        })}
        style={{
          backgroundColor: color,
          width: `${scorePercentage < 10 ? 10 : scorePercentage}%`,
        }}
      >
        {icon && <span className="mr-2">{icon}</span>}
        <span>{displayText}</span>
      </div>
    </div>
  );
};

SectionScore.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  stale: PropTypes.bool,
  score: PropTypes.number,
  readyColor: PropTypes.string.isRequired,
  notReadyColor: PropTypes.string.isRequired,
};

export default SectionScore;
