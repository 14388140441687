// import log from '@scrtracker/logging';
import React, { useMemo } from 'react';
import { Select } from '@hogwarts/ui-components-core';

const trimLength = (text) => {
  const maxlength = 45;

  if (!text) return text;

  if (text.length > maxlength - 3) {
    return `${text.substring(0, maxlength)}...`;
  }

  return text;
};

const RenderFieldSelect = ({
  fieldKey,
  formField,
  fieldHelpers,
  scheme,
  validate,
  readOnly,
}) => {
  const props = useMemo(() => {
    if (!scheme) return null;
    const showEmpty = validate !== 'required';
    const groupList = scheme.sections
      .filter((s) => s.usable !== false)
      .map((section) => ({
        key: section.key,
        label: section.label,
      }));
    const fieldList = scheme.fields
      .filter((f) => f.dataType !== 'none' && f.usable !== false)
      .map((field) => {
        return {
          sectionKey: field.section,
          key: field.key,
          label: trimLength(field.label),
          dataType: field.dataType,
          inputType: field.inputType,
        };
      });

    if (showEmpty) {
      groupList.unshift({
        key: '__META__',
        // label: '-------------',
      });
      fieldList.unshift({
        sectionKey: '__META__',
        key: null,
        label: '(Remove selection)',
      });
    }

    return {
      groupKey: 'sectionKey',
      groups: groupList,
      items: fieldList,
    };
  }, [scheme, validate]);
  if (!scheme) {
    return <div>{'Error. Scheme not provided'}</div>;
  }
  return (
    <Select
      id={fieldKey}
      {...props}
      valueField="key"
      textField="label"
      showEmpty={false}
      onBlur={formField.onBlur}
      // intent={getErrorIntent(fieldMeta)}
      onChange={async (item) => {
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(item.key);
      }}
      disabled={readOnly}
      large={false}
      fill
      value={formField.value}
    />
  );
};

export default {
  name: 'Field Select',
  options: [],
  Component: RenderFieldSelect,
};
