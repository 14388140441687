import { Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBytes } from '@hogwarts/utils';
import cn from 'classnames';
import React, { useContext } from 'react';
import { AnalyticsContext } from '../../context';
import { useDownloadPolicy } from '../../hooks/useDownloadPolicy';
import { Document } from '../../types';
import { getDocumentIcon } from '../UserProfile/Documents/mimetypes';
import styles from './styles.module.css';

interface DocumentIconProps {
  document: Document;
  hasTooltip?: boolean;
  className?: string;
}

const DocumentIcon = ({
  document,
  hasTooltip,
  className,
}: DocumentIconProps) => {
  const analytics = useContext(AnalyticsContext);
  const getDownloadPolicy = useDownloadPolicy();

  const icon = (
    <FontAwesomeIcon
      icon={getDocumentIcon(document.type)}
      size="lg"
      color="#137cbd"
      className={cn(className, styles.documentIcon)}
      onClick={async () => {
        analytics.events.documents.fileOpened();

        const securityPolicy = await getDownloadPolicy(document.id);
        window.open(
          `https://cdn.filestackcontent.com/${document.filestackHandle}?policy=${securityPolicy.policy}&signature=${securityPolicy.signature}`,
          '_blank'
        );
      }}
    />
  );

  return hasTooltip ? (
    <Tooltip2
      className={className}
      content={`${document.filename} (${formatBytes(document.size)})`}
      placement="top"
    >
      {icon}
    </Tooltip2>
  ) : (
    icon
  );
};

export default DocumentIcon;
