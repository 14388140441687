// @ts-nocheck
import { Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBytes } from '@hogwarts/utils';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridView } from '../../../../components';
import { getDocumentIcon } from '../../../../components/UserProfile/Documents/mimetypes';
import { AnalyticsContext, OrganisationContext } from '../../../../context';
import { useFeature } from '../../../../hooks';
import { useDownloadPolicy } from '../../../../hooks/useDownloadPolicy';
import {
  dateFormatter,
  gridOptions as defaultGridOptions,
} from '../../../profilesGridUtils';
import styles from './styles.module.css';

const gridOptions = {
  ...defaultGridOptions,
  rowSelection: 'single',
  sideBar: false,
  sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 100,
    filter: false,
    editable: false,
    suppressMenu: true,
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
};

const DocumentIcon = ({ data: { documents } }: any) => {
  const getDownloadPolicy = useDownloadPolicy();
  const analytics = useContext(AnalyticsContext);

  if (!documents) {
    return null;
  }

  return (
    <div className={styles.iconWrapper}>
      {documents.map((document) => {
        return (
          <Tooltip2
            key={document.id}
            content={`${document.filename} (${formatBytes(document.size)})`}
            placement="top"
            className="d-flex align-items-center justify-content-center h-100"
          >
            <FontAwesomeIcon
              icon={getDocumentIcon(document.type)}
              size="2x"
              className={styles.documentIcon}
              color="#137cbd"
              onClick={async () => {
                analytics.events.documents.fileOpened();

                const securityPolicy = await getDownloadPolicy(document.id);
                window.open(
                  `https://cdn.filestackcontent.com/${document.filestackHandle}?policy=${securityPolicy.policy}&signature=${securityPolicy.signature}`,
                  '_blank'
                );
              }}
            />
          </Tooltip2>
        );
      })}
    </div>
  );
};

const createFields = (
  organisation: any,
  t: any,
  isSignOffRatingsDisabled: boolean
) => {
  function ratingGetterFactory(ratingSystemKey: any) {
    return function getter({ data }: any) {
      const ratings = data?.ratings;

      if (ratings) {
        const system = ratings.find(
          (r: any) => r.systemKey === ratingSystemKey
        );
        if (system) {
          return system.ready;
        }
      }
      return false;
    };
  }

  const { ratingSystems } = organisation.scheme;

  return [
    {
      key: 'signedOffBy.name',
      label: 'Signed Off By',
      dataField: true,
      width: '100px',
    },
    {
      key: 'signedOffAt',
      label: 'Signed Off At',
      dataField: true,
      valueFormatter: dateFormatter,
      width: '100px',
    },
    {
      key: 'notes',
      label: 'Notes',
      dataField: true,
      wrapText: true,
      autoHeight: true,
      width: '500px',
    },
    {
      key: 'documents',
      label: t('Documents'),
      cellRenderer: DocumentIcon,
      filter: 'agTextColumnFilter',
      minWidth: 50,
      maxWidth: 125,
      suppressSorting: true,
    },
    ...(isSignOffRatingsDisabled
      ? []
      : ratingSystems.map((ratingSystem: any) => {
          return {
            key: `rs_${ratingSystem.key}`,
            valueGetter: ratingGetterFactory(ratingSystem.key),
            label: t(ratingSystem.label),
            cellRenderer: 'booleanRenderer',
            filter: 'agTextColumnFilter',
            minWidth: 50,
            maxWidth: 125,
            suppressSorting: true,
          };
        })),
  ];
};

const SignOffsGridView = ({ signOffs }: any) => {
  const { t } = useTranslation();
  const isSignOffRatingsDisabled = useFeature(
    'reports.disable_sign_off_ratings'
  );

  const organisation = useContext(OrganisationContext);
  const fields = useMemo(
    () => createFields(organisation, t, isSignOffRatingsDisabled),
    [organisation, t, isSignOffRatingsDisabled]
  );

  return (
    <GridView
      {...gridOptions}
      getRowNodeId={(data: any) => data.id}
      groups={[]}
      columns={fields}
      rows={signOffs}
      showSideBar={false}
    />
  );
};

export default SignOffsGridView;
