import { Alert, IconName, Intent, MaybeElement } from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface useAlertProps<TParams> {
  cancelButtonText?: string;
  confirmButtonText?: string;
  icon?: IconName | MaybeElement;
  Content: React.FC<TParams>;
  intent?: Intent;
  onConfirm: (params: TParams) => void;
}
export function useAlert<TParams = {}>({
  cancelButtonText,
  confirmButtonText,
  icon,
  Content,
  intent,
  onConfirm,
}: useAlertProps<Partial<TParams>>): [
  React.FC<Partial<TParams>>,
  (params?: Partial<TParams>) => void
] {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [componentProperties, setProperties] = useState<Partial<TParams>>();
  const Component = useCallback(
    () => (
      <Alert
        canEscapeKeyCancel
        canOutsideClickCancel
        cancelButtonText={t(cancelButtonText || 'Cancel')}
        confirmButtonText={t(confirmButtonText || 'OK')}
        icon={icon || 'trash'}
        intent={intent || Intent.DANGER}
        isOpen={isOpen}
        onCancel={() => {
          setProperties({});
          setIsOpen(false);
        }}
        onConfirm={() => {
          setIsOpen(false);
          // Call the parent thingy
          onConfirm({ ...componentProperties });
        }}
      >
        <Content {...componentProperties} />
      </Alert>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cancelButtonText,
      isOpen,
      confirmButtonText,
      icon,
      intent,
      componentProperties,
    ]
  );

  return [
    Component,
    (params?: Partial<TParams>) => {
      setProperties({ ...params });
      setIsOpen(true);
    },
  ];
}
