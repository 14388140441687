import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useMemoizeArgs = (args) => {
  const ref = useRef();
  const prevArgs = ref.current;
  const argsAreEqual = isEqual(prevArgs, args);
  useEffect(() => {
    if (!argsAreEqual) {
      ref.current = args;
    }
  });
  return argsAreEqual ? prevArgs : args;
};
