import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { Button, Callout, InputGroup, Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import styles from './styles.module.css';

interface MfaSettingsProps {
  qrcodeDataUrl?: string;
  state:
    | 'ENABLED'
    | 'SAVING'
    | 'DISABLED'
    | 'ERROR'
    | 'REMOVING'
    | 'SHOW-QR'
    | 'LOADING-QR';
  onEnable: (checked: boolean) => void;
  onSave: (code: string) => void;
  onDisable: (code: string) => void;
  enforceMode?: boolean;
}
const MfaSettings = ({
  state,
  qrcodeDataUrl,
  onEnable,
  onSave,
  onDisable,
  enforceMode = false,
}: MfaSettingsProps) => {
  const { t } = useTranslation();
  const [authenticatorCode, setAuthenticatorCode] = useState('');

  return (
    <SettingsPanel title={'Multi-Factor Authentication'}>
      <p>
        {enforceMode
          ? t(
              `Your organisation has multi-factor authentication (MFA) enforcement enabled. Please enable MFA to ensure the security of your account and enable access to this organisation.`
            )
          : t(`Multi-Factor Authentication (MFA) allows you to use an authenticator
        app to add a second layer of security to your account. When logging into
        your account with MFA enabled you may be asked to prove your identity
        with a code from this app.`)}
      </p>
      <div className={styles.enableToggleContainer}>
        <p>{t('Enable Multi-Factor Authentication')}</p>
        <Toggle
          disabled={
            state === 'ENABLED' || state === 'SAVING' || state === 'REMOVING'
          }
          checked={state !== 'DISABLED' && state !== 'ERROR'}
          onChange={(e: any) => onEnable(e.target.checked)}
        />
      </div>
      {state === 'LOADING-QR' && (
        <div className={styles.generatingLoader}>
          <Spinner intent="primary" />
          <p>{t('Generating QR Code')}</p>
        </div>
      )}
      {(state === 'SHOW-QR' || state === 'SAVING') && (
        <div>
          <p>
            {t(`First, please scan the following QR code with your chosen
            authenticator app:`)}
          </p>
          <img className={styles.qrcode} src={qrcodeDataUrl} alt="QR Code" />
          <p>
            {t(
              'Next, please enter the code provided by your authenticator app:'
            )}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSave(authenticatorCode);
              setAuthenticatorCode('');
            }}
          >
            <div className={styles.authenticatorCode}>
              <InputGroup
                large
                autoFocus
                autoComplete="one-time-code"
                value={authenticatorCode}
                disabled={state === 'SAVING'}
                onChange={(e) => {
                  setAuthenticatorCode(e.target.value);
                }}
              />
              <Button
                large
                intent="primary"
                disabled={!authenticatorCode}
                loading={state === 'SAVING'}
                type="submit"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      )}
      {state === 'ERROR' && (
        <Callout intent="danger" title={t('Error Enabling MFA')}>
          {t(`Something went wrong while trying to enable MFA. Normally this happens
          when it has taken too long to enter your authenticator code. Please try enabling
          it again, if this continues to happen please contact support.`)}
        </Callout>
      )}
      {(state === 'ENABLED' || state === 'REMOVING') && (
        <>
          <p>
            {t(
              `Please enter the code provided by your authenticator app to disable MFA.`
            )}
          </p>
          <div className={styles.authenticatorCode}>
            <InputGroup
              large
              autoFocus
              autoComplete="one-time-code"
              value={authenticatorCode}
              disabled={state === 'REMOVING'}
              onChange={(e) => {
                setAuthenticatorCode(e.target.value);
              }}
            />
            <Button
              large
              intent="danger"
              disabled={!authenticatorCode}
              loading={state === 'REMOVING'}
              onClick={() => onDisable(authenticatorCode)}
            >
              {t('Disable MFA')}
            </Button>
          </div>
        </>
      )}
    </SettingsPanel>
  );
};

export default MfaSettings;
