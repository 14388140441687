import trackEvent from '../trackEvent';

export const orderCheckClicked = (traits = {}) => {
  trackEvent('Order Check - Clicked', traits);
};

export const checkOrdered = (traits = {}) => {
  trackEvent('Order Check - Order Placed', traits);
};

export const acceptedCheckSelection = (traits = {}) => {
  trackEvent('Order Check - Accepted Check Selection', traits, true);
};

export const completedUserEntry = (traits = {}) => {
  trackEvent('Order Check - Completed User Entry', traits, true);
};

export const completedContactDetails = (traits = {}) => {
  trackEvent('Order Check - Completed Contact Details', traits, true);
};

export const setPurchaseOrder = (traits = {}) => {
  trackEvent('Order Check - Set Purchase Order', traits, true);
};

export const agreedToTerms = (traits = {}) => {
  trackEvent('Order Check - Agreed to Terms', traits, true);
};

export const showRequestAccountClicked = (traits = {}) => {
  trackEvent('Order Check - Show Request Account Clicked', traits, true);
};

export const requestAccountClicked = (traits = {}) => {
  trackEvent('Order Check - Request Account Clicked', traits, true);
};

export const openSummaryReportClicked = (traits = {}) => {
  trackEvent('Order Check - Open Summary Report Clicked', traits, true);
};

export const toggleCheckAccountPreferences = (traits = {}) => {
  trackEvent('Order Check - Toggle Check Account Preferences', traits, true);
};

export const preferencesUpdated = (traits = {}) => {
  trackEvent('Order Check - Preferences Updated', traits, true);
};

export const verifileOrderCancelled = (traits = {}) => {
  trackEvent('Order Check - Verifile Order Cancelled', traits, true);
};
