import { PageHeader } from '@/components';
import {
  CANCEL_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
  DELETE_PROFILE_MUTATION,
  UPDATE_PROFILE_DATA_MUTATION,
} from '@/mutations';
import {
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
} from '@/queries';
import { AppToaster } from '@/utils/toaster';
import permissions from '@hogwarts/permissions';
import { useFormState } from '@hogwarts/ui-components-forms';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AnalyticsContext, OrganisationContext } from '../../context';
import {
  useAlert,
  useBuildReport,
  useFeature,
  useMutation,
  usePermission,
} from '../../hooks';
import { useTurnOffReadOnly } from '../../hooks/useTurnOffReadOnly';
import CopyProfileDialog from './dialogs/copyProfileDialog';
import TransferProfileDialog from './dialogs/transferProfileDialog';

const Header = ({
  tabId,
  profile,
  onOpenDocumentPicker,
  transferRequest,
  children,
  profileReportId,
  incidentsReportId,
  orderCheck,
}) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const checksFeature = useFeature('checks');
  const confidentialDocumentsFeature = useFeature('documents.confidential');
  const incidentTrackingEnabled = useFeature('timeline.incident_tracking');
  const forms = useFormState();

  const [deleteProfile] = useMutation(DELETE_PROFILE_MUTATION, {
    variables: {
      organisationKey: organisation.key,
      id: profile?.id,
    },
  });
  const canReadTargetOrgProfiles = usePermission(
    permissions.PROFILE_READ,
    transferRequest?.targetOrganisation?.id
  );
  const [cancelTransferRequest] = useMutation(
    CANCEL_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
    {
      variables: {
        profileId: profile?.id,
      },
      refetchQueries: [
        {
          query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
          variables: { profileId: profile?.id },
        },
        canReadTargetOrgProfiles && {
          query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
          variables: {
            targetOrganisationId: transferRequest?.targetOrganisation?.id,
          },
        },
      ].filter(Boolean),
    }
  );
  const [editProfile] = useMutation(UPDATE_PROFILE_DATA_MUTATION);
  const history = useHistory();
  const profileCreatePermission = usePermission(
    permissions.PROFILE_CREATE,
    organisation?.id
  );
  const profileCopyPermission = usePermission(
    permissions.PROFILE_COPY,
    organisation?.id
  );
  const profileTransferPermission = usePermission(
    permissions.PROFILE_TRANSFER,
    organisation?.id
  );
  const profileDeletePermission = usePermission(
    permissions.PROFILE_DELETE,
    organisation?.id
  );
  const profileUploadPermission = usePermission(
    permissions.PROFILE_FILE_CREATE,
    organisation?.id
  );
  const allowProfilePrint = usePermission(
    permissions.PROFILE_PRINT,
    organisation.id
  );
  const canOrderChecks = usePermission(
    permissions.CHECK_PLACE_ORDER,
    organisation.id
  );
  const canAddIncident = usePermission(
    permissions.PROFILE_ACTIVITY_CREATE,
    organisation.id
  );

  const [DeleteProfileAlert, openDeleteDialog] = useAlert({
    onConfirm: async () => {
      await deleteProfile();
      history.push(`/${organisation.key}/profiles`);
      AppToaster.show({
        message: t('{{ profileName }} deleted successfully!', {
          profileName: profile.data.name,
        }),
        intent: 'success',
        icon: 'tick',
      });
    },
    Content: () => (
      <>
        <p>
          {t("Are you sure you want to delete {{profileName}}'s profile?", {
            profileName: profile.data.name,
          })}
        </p>
        <p>
          {t(
            'You will not be able to restore it later, this cannot be undone.'
          )}
        </p>
      </>
    ),
  });

  const [
    onReportClick,
    {
      component: ReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
    canPrintReports,
  ] = useBuildReport();

  const [showReadOnlyEdit, ReadOnlyEdit, allowReadOnlyEdit] =
    useTurnOffReadOnly(async (updates) => {
      const variables = updates?.[0];
      if (!variables) return;

      await editProfile({
        variables,
      });
    });

  return (
    <>
      <ReportComponent {...reportProps} />
      <DeleteProfileAlert />
      <CopyProfileDialog profile={profile}>
        {({ openDialog: openCopyDialog }) => (
          <TransferProfileDialog profile={profile}>
            {({ openDialog: openTransferDialog }) => {
              let headerActions = [];
              switch (tabId) {
                case 'documents':
                  headerActions = [
                    confidentialDocumentsFeature && {
                      text: t('Upload Confidential'),
                      icon: 'user-secret',
                      allowed: profileUploadPermission,
                      intent: 'danger',
                      onClick: () => {
                        analytics.events.profile.uploadActionClicked({
                          confidential: true,
                        });
                        onOpenDocumentPicker({ confidential: true });
                      },
                    },
                    {
                      text: t('Upload'),
                      icon: 'upload',
                      allowed: profileUploadPermission,
                      intent: 'primary',
                      onClick: () => {
                        analytics.events.profile.uploadActionClicked();
                        onOpenDocumentPicker();
                      },
                    },
                  ];
                  break;
                case 'history':
                  headerActions = [];
                  break;
                case 'timeline':
                  headerActions = incidentTrackingEnabled
                    ? [
                        {
                          text: t('Print'),
                          icon: 'print',
                          allowed: canPrintReports && allowProfilePrint,
                          disabled: !incidentsReportId || reportDisabled,
                          intent: 'primary',
                          onClick: () => {
                            if (!incidentsReportId) return null;
                            analytics.events.profile.printActionClicked();
                            // The print is currently configured for incidents only
                            return onReportClick(incidentsReportId, undefined, {
                              profileName: profile?.data?.name,
                              profileId: profile?.id,
                              organisationName: organisation?.name,
                              organisationKey: organisation.key,
                            });
                          },
                        },
                        {
                          text: t('Add Incident'),
                          icon: ['far', 'exclamation-triangle'],
                          allowed: canAddIncident,
                          intent: 'success',
                          onClick: () => {
                            analytics.events.profile.addIncidentClicked({
                              profileId: profile?.id,
                            });
                            forms.showForm('addIncident', {
                              initialValues: {
                                profile: profile.id,
                                documents: [],
                              },
                            });
                          },
                        },
                      ]
                    : [];
                  break;
                default:
                  headerActions = [
                    checksFeature && {
                      text: t('Order Check'),
                      allowed: canOrderChecks,
                      icon: 'user-check',
                      intent: 'success',
                      onClick: () => {
                        return orderCheck();
                      },
                    },
                    {
                      text: t('Print'),
                      icon: 'print',
                      allowed: canPrintReports && allowProfilePrint,
                      disabled: !profileReportId || reportDisabled,
                      intent: 'primary',
                      onClick: () => {
                        if (!profileReportId) return null;
                        analytics.events.profile.printActionClicked();
                        return onReportClick(profileReportId, undefined, {
                          profileId: profile?.id,
                          organisationKey: organisation.key,
                        });
                      },
                    },
                    {
                      text: t('Copy'),
                      icon: 'copy',
                      allowed: profileCopyPermission && profileCreatePermission,
                      intent: 'primary',
                      onClick: () => {
                        analytics.events.profile.copyActionClicked();
                        openCopyDialog();
                      },
                    },
                    !!transferRequest
                      ? {
                          text: t('Cancel Transfer'),
                          icon: 'exchange',
                          allowed: profileTransferPermission,
                          intent: 'warning',
                          onClick: () => {
                            analytics.events.profile.transferCancelActionClicked();
                            return cancelTransferRequest();
                          },
                        }
                      : {
                          text: t('Transfer'),
                          icon: 'exchange',
                          allowed: profileTransferPermission,
                          intent: 'primary',
                          onClick: () => {
                            analytics.events.profile.transferActionClicked();
                            openTransferDialog();
                          },
                        },
                    {
                      text: t('Remove Read-Only'),
                      icon: 'unlock',
                      allowed: allowReadOnlyEdit,
                      intent: 'primary',
                      onClick: () => {
                        analytics.events.profile.turnOffReadOnlyClicked();
                        showReadOnlyEdit([profile]);
                      },
                    },
                    {
                      text: t('Delete'),
                      icon: 'trash',
                      allowed: profileDeletePermission,
                      intent: 'danger',
                      onClick: () => {
                        analytics.events.profile.deleteActionClicked();
                        openDeleteDialog();
                      },
                    },
                  ];
                  break;
              }
              return (
                <PageHeader noWrapper actions={headerActions}>
                  {children}
                </PageHeader>
              );
            }}
          </TransferProfileDialog>
        )}
      </CopyProfileDialog>
      <ReadOnlyEdit.Component {...ReadOnlyEdit.props} />
    </>
  );
};

Header.propTypes = {
  profile: PropTypes.object,
};

export default Header;
