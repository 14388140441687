import React from 'react';
import AddButton from '../AddButton';
import ProfileTypeButton from '../ProfileTypeButton';
import SortableList from '../SortableList';
import TabWrapper from './TabWrapper';

const ProfileTypesTab = ({
  profileTypes,
  selectedProfileType,
  onProfileTypeSelected,
  onProfileTypeRequestEdit,
  onProfileTypeOrderUpdate,
  onAddProfileType,
  permissions,
  iconPack,
}) => {
  return (
    <>
      <SortableList
        items={profileTypes.map((profileType) => ({
          component: ProfileTypeButton,
          key: profileType.key,
          name: profileType.label,
          description: profileType.description,
          avatar: profileType.avatar,
          iconPack,
          profileTypeDisabled: !profileType.enabled,
          deleted: profileType.deleted,
          color: profileType.color,
          parentType: profileType.parentItem?.label,
          selected: profileType.key === selectedProfileType?.key,
          onClick: () => onProfileTypeSelected(profileType.key),
          onDoubleClick: () => onProfileTypeRequestEdit(profileType.key),
        }))}
        extraItems={
          permissions?.canAddProfileType && (
            <AddButton height="120px" onClick={onAddProfileType} />
          )
        }
        onOrderUpdate={({ oldIndex, newIndex }) => {
          const movedKey = profileTypes[oldIndex].key;
          const bumpedKey = profileTypes[newIndex].key;
          onProfileTypeOrderUpdate(movedKey, bumpedKey);
        }}
      />
    </>
  );
};

export default TabWrapper(ProfileTypesTab);
