import { NonIdealState } from '@blueprintjs/core';
import { FetchMethod, InfiniteListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckStatusTag } from '../../../../components';
import { ProfileListItem } from '../../../../containers/profileListItem';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching Profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

interface ChecksProps {
  checkOrders: {
    checks: {
      name: string;
      status: string;
    }[];
  }[];
}
const Checks = ({ checkOrders }: ChecksProps) => {
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {checkOrders?.map((order) => {
          return order.checks
            .map((check, index) => {
              // if (check.status?.startsWith('COMPLETED')) return null;
              // if (check.status?.startsWith('CANCELLED')) return null;
              return (
                <tr key={index}>
                  <td>
                    <div className={'ml-2 mr-2'}>{check.name}</div>
                  </td>
                  <td>
                    <CheckStatusTag status={check.status} />
                  </td>
                </tr>
              );
            })
            .filter(Boolean);
        })}
      </tbody>
    </table>
  );
};

interface ProfilesOutstandingChecksListProps {
  fetchMore: FetchMethod;
  iconPack: string;
  onProfileSelected: (profileId: string, profile: any) => void;
}
const ProfilesOutstandingChecksList = ({
  fetchMore,
  iconPack,
  onProfileSelected,
}: ProfilesOutstandingChecksListProps) => {
  return (
    <InfiniteListView
      className="w-100 h-100"
      componentProps={{
        onProfileSelected,
      }}
      fetchMore={fetchMore}
      component={({ item: profile, ...props }: { item: any }) => {
        return (
          <ProfileListItem
            profile={profile}
            onSelected={(id) => onProfileSelected(id, profile)}
            iconPack={iconPack}
            {...props}
          >
            <Checks checkOrders={profile?.checkOrders} />
          </ProfileListItem>
        );
      }}
      emptyComponent={NoRows}
      spreadRowItem={false}
    />
  );
};

export default ProfilesOutstandingChecksList;
