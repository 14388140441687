import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import CheckOrdersList from './CheckOrdersList';
import UserProfileDocuments from './Documents';
import UserProfileHistory from './History';
import UserProfileOverview from './Overview';
import UserProfileSection from './Section';
import UserProfileTimeline from './Timeline';
import './styles.css';
import styles from './styles.module.css';

const UserProfile = ({
  tab,
  overview,
  sections,
  avatarIconPack,
  history,
  diagnostics,
  documents,
  filteredDocuments,
  documentFilters,
  allowEdit,
  allowUpload,
  allowDocumentDelete,
  allowDocumentList,
  allowDocumentOpen,
  allowHideChecks,
  allowSecureDocumentOpen,
  allowReadCheckOrders,
  allowConfirmIdentity,
  enableSecureFiles,
  checkOrders,
  onOverviewEdit,
  onSectionSettings,
  onSectionEdit,
  onSectionPrint,
  onSectionDocumentsClick,
  onDocumentsFilter,
  onDocumentOpen,
  onDocumentDelete,
  onDocumentsUpload,
  onProfilePictureUpload,
  onOpenProfilePicturePicker,
  onChangeSection,
  onChangeProfileType,
  onOpenReport,
  onConfirmIdentity,
  onCancelOrder,
  profile,
  onDeleteProfilePicture,
  onChangeConfidential,
  onShowCheckInfo,
}) => {
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
  });
  return (
    <div className={cn('container-fluid', styles.userProfileContainer)}>
      <div {...getRootProps({ className: 'profile-dropzone' })}>
        <div className="row no-gutters">
          <div
            className={cn('col-5 col-lg-4 col-xl-3', styles.overviewsContainer)}
          >
            <UserProfileOverview
              allowEdit={allowEdit}
              onEdit={onOverviewEdit.bind(this)}
              parentDragActive={isDragActive}
              avatarIconPack={avatarIconPack}
              allowUpload={tab === 'profile' && allowUpload}
              onProfilePictureUpload={({ files }) =>
                onProfilePictureUpload({
                  files,
                })
              }
              onEditProfilePicture={onOpenProfilePicturePicker}
              onDeleteProfilePicture={onDeleteProfilePicture}
              onChangeProfileType={onChangeProfileType}
              {...overview}
            />
          </div>
          <div className="col-7 col-lg-8 col-xl-9 userprofile__tabscontainer">
            {tab === 'profile' && (
              <div className={styles.sectionsContainer}>
                {sections.map((section) => {
                  return (
                    <UserProfileSection
                      key={section.key}
                      color={section.color}
                      canPrint={section.canPrint}
                      allowEdit={allowEdit}
                      allowHideChecks={allowHideChecks}
                      onEdit={
                        onSectionEdit ? () => onSectionEdit(section.key) : null
                      }
                      onPrint={
                        onSectionPrint
                          ? () => onSectionPrint(section.key)
                          : null
                      }
                      onSettings={
                        onSectionSettings
                          ? () => onSectionSettings(section.key)
                          : null
                      }
                      documents={
                        documents
                          ? documents.filter(
                              (document) => document.sectionKey === section.key
                            ).length
                          : 0
                      }
                      onDocumentsClick={onSectionDocumentsClick.bind(this, {
                        sectionKey: section.key,
                      })}
                      parentDragActive={isDragActive}
                      allowUpload={allowUpload}
                      onDocumentsUpload={({ files, confidential }) =>
                        onDocumentsUpload({
                          sectionKey: section.key,
                          files,
                          confidential,
                        })
                      }
                      {...section}
                    />
                  );
                })}
              </div>
            )}
            {tab === 'documents' && (
              <UserProfileDocuments
                documents={filteredDocuments}
                documentFilters={documentFilters}
                allowDocumentDelete={allowDocumentDelete}
                allowDocumentList={allowDocumentList}
                allowDocumentOpen={allowDocumentOpen}
                allowSecureDocumentOpen={allowSecureDocumentOpen}
                enableSecureFiles={enableSecureFiles}
                sections={sections}
                allowUpload={allowUpload}
                parentDragActive={isDragActive}
                onFilter={onDocumentsFilter}
                onOpen={onDocumentOpen}
                onDelete={onDocumentDelete}
                onChangeSection={onChangeSection}
                onDocumentsUpload={({ files, confidential }) =>
                  onDocumentsUpload({ files, confidential })
                }
                onChangeConfidential={onChangeConfidential}
              />
            )}
            {tab === 'checks' && (
              <CheckOrdersList
                allowConfirmIdentity={allowConfirmIdentity}
                allowReadCheckOrders={allowReadCheckOrders}
                orders={checkOrders}
                onOpenReport={onOpenReport}
                onConfirmIdentity={onConfirmIdentity}
                onCancelOrder={onCancelOrder}
                onViewOrderDetails={onShowCheckInfo}
              />
            )}
            {tab === 'history' && (
              <UserProfileHistory
                {...history}
                diagnostics={diagnostics}
                profileId={profile.id}
              />
            )}
            {tab === 'timeline' && (
              <UserProfileTimeline profileId={profile.id} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  tab: PropTypes.string.isRequired,
};

UserProfile.defaultProps = {
  tab: 'profile',
};

export default UserProfile;
