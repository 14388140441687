import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const CombinedScoresChartWidget = ({
  profileTypes,
  ratingSystems,
  scores,
  onProfileClick,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);
  useEffect(
    () => {
      const dfeSystem = ratingSystems.find((r) => r.mandatory);
      const stdSystem = ratingSystems.find((r) => !r.mandatory);
      if (!dfeSystem || !stdSystem) return;
      const newChartData = {
        labels: profileTypes && profileTypes.map((p) => t(p.label)),
        datasets: [
          {
            _ratings: {
              std: true,
            },
            stack: `stack`,
            key: `std_ready`,
            label: t(stdSystem.label),
            borderWidth: 1,
            backgroundColor: get(stdSystem, 'readyColor'),
            hoverBackgroundColor: get(stdSystem, 'readyColor'),
            data: profileTypes.map((p) => {
              if (scores) {
                return scores[p.key].cognitaReady;
              }
              return null;
            }),
          },
          {
            _ratings: {
              std: false,
              dfe: true,
            },
            stack: `stack`,
            key: `dfe_ready`,
            label: t(dfeSystem.label),
            borderWidth: 1,
            backgroundColor: get(dfeSystem, 'readyColor'),
            hoverBackgroundColor: get(dfeSystem, 'readyColor'),
            data: profileTypes.map((p) => {
              if (scores) {
                return scores[p.key].startDateReady;
              }
              return null;
            }),
          },
          {
            _ratings: {
              dfe: false,
            },
            stack: `stack`,
            key: `dfe_notReady`,
            label: t(`Not ready`),
            borderWidth: 1,
            backgroundColor: get(dfeSystem, 'notReadyColor'),
            hoverBackgroundColor: get(dfeSystem, 'notReadyColor'),
            data: profileTypes.map((p) => {
              if (scores) {
                return scores[p.key].notReady;
              }
              return null;
            }),
          },
        ],
      };
      setChartData(newChartData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileTypes, ratingSystems, scores]
  );

  return (
    <div className={styles.scoresChartContainer}>
      {chartData && (
        <Bar
          data={chartData}
          height={500}
          width={630}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    callback: function (value) {
                      return value.trunc(12);
                    },
                  },
                },
              ],
            },
            tooltips: {
              enabled: true,
              callbacks: {
                title: function (tooltipItems, data) {
                  var idx = tooltipItems[0].index;
                  return data.labels[idx];
                },
              },
            },
          }}
          getElementAtEvent={(dataset) => {
            if (!onProfileClick) return;
            if (!dataset || dataset.length === 0) {
              return;
            }
            const { _datasetIndex: datasetIndex, _index: index } = dataset[0];
            const selectedDataSet = chartData.datasets[datasetIndex];
            onProfileClick({
              profileTypeKey: profileTypes[index].key,
              ratings: selectedDataSet._ratings,
            });
          }}
        />
      )}
    </div>
  );
};

export default CombinedScoresChartWidget;
