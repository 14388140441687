const getLink = (currentKey, targetKey, location) => {
  if (location.pathname.startsWith('/admin/')) {
    return `/${targetKey}/`;
  }
  if (location.pathname.indexOf('/profiles/') > -1) {
    return `/${targetKey}/profiles`;
  }

  let pathname = location.pathname;
  if (pathname[pathname.length - 1] !== '/') {
    pathname = `${pathname}/`;
  }

  return (
    pathname.replace(`/${currentKey}/`, `/${targetKey}/`) + location.search
  );
};

export { getLink };
