import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import { Callout, Intent } from '@blueprintjs/core';
import { OrganisationContext } from '@/context';
import { useTranslation } from 'react-i18next';

const TimezoneWarning = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);

  if (!organisation) return null;

  const local = DateTime.local();
  const remote = DateTime.utc().setZone(organisation.timezone);

  if (local.day !== remote.day) {
    return (
      <Callout intent={Intent.WARNING}>
        {t(
          `You are viewing an Organisation that is in a different timezone, the current date there is {{date}}`,
          {
            date: remote.toLocaleString(DateTime.DATE_HUGE),
          }
        )}
      </Callout>
    );
  }
  return null;
};

export default TimezoneWarning;
