import { Callout, Checkbox } from '@blueprintjs/core';
import { Button, Markdown } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';
import { DocumentItem } from './Helpers';

// https://www.gov.uk/government/publications/dbs-identity-checking-guidelines/id-checking-guidelines-for-standardenhanced-dbs-check-applications-from-1-july-2021
const checkValidity = (
  selection: string[],
  documentTypes: DocumentItem[]
): boolean => {
  const primaryLength = documentTypes.filter(
    (p) => p.type === 'primary' && selection.includes(p.id)
  ).length;
  const secondaryLength = documentTypes.filter(
    (p) => p.type === 'secondary' && selection.includes(p.id)
  ).length;
  const financialLength = documentTypes.filter(
    (p) => p.type === 'financial' && selection.includes(p.id)
  ).length;

  if (
    primaryLength >= 1 &&
    primaryLength + secondaryLength + financialLength >= 3
  ) {
    return true;
  }

  if (
    primaryLength === 0 &&
    secondaryLength > 0 &&
    secondaryLength + financialLength >= 3
  ) {
    return true;
  }

  return false;
};

interface DocumentCheckListProps {
  label: string;
  selected: string[];
  documentTypes: DocumentItem[];
  onSelectionChange: (selection: string[]) => void;
}
const DocumentCheckList = ({
  label,
  selected,
  documentTypes,
  onSelectionChange,
}: DocumentCheckListProps) => {
  return (
    <div>
      <div className="font-weight-bold mb-2">{label}</div>
      {documentTypes.map((type) => (
        <div key={type.id} className={'mb-1 ml-1'}>
          <Checkbox
            checked={selected.includes(type.id)}
            label={type.description}
            onChange={(event) => {
              const checked = event.currentTarget.checked;
              if (checked) {
                onSelectionChange([...selected, type.id]);
              } else {
                onSelectionChange(selected.filter((s) => s !== type.id));
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

interface DocumentSelectionProps {
  documents: string[];
  documentTypes: DocumentItem[];
  onSave: (documents: string[]) => void;
}
export const DocumentSelection = ({
  documents: initialDocuments,
  documentTypes,
  onSave,
}: DocumentSelectionProps) => {
  const [selected, setSelected] = useState(() => {
    // clean out any missing documents
    return initialDocuments.filter((document) => {
      return documentTypes.find((d) => d.id === document);
    });
  });

  const isValid = checkValidity(selected, documentTypes);

  return (
    <div>
      <Callout className="mb-2" intent="primary">
        <Markdown
          content={`
Candidate must supply **three** documents, at least one document from the primary group and
two further documents from any of the groups.

If the candidate cannot supply a document from the primary group, at 
least three documents must still be supplied with at least one document
coming from the secondary group.

The resulting documentation must confirm the candidate's name, address and
date of birth.

Read more on our [Help Guide](https://go.scrtracker.com/3RBLCNc)
`}
        />
      </Callout>

      {/* validate: (value, field, values) => checkValidity(values), */}

      <DocumentCheckList
        label="Primary Documents (Group 1)"
        selected={selected}
        documentTypes={documentTypes.filter((d) => d.type === 'primary')}
        onSelectionChange={(selection) => setSelected(selection)}
      />
      <DocumentCheckList
        label="Secondary Documents (Group 2a)"
        selected={selected}
        documentTypes={documentTypes.filter((d) => d.type === 'secondary')}
        onSelectionChange={(selection) => setSelected(selection)}
      />
      <DocumentCheckList
        label="Financial Documents (Group 2b)"
        selected={selected}
        documentTypes={documentTypes.filter((d) => d.type === 'financial')}
        onSelectionChange={(selection) => setSelected(selection)}
      />

      <div>
        {!isValid && (
          <Callout className="mb-2" intent="danger">
            Please see guidance above on document requirements
          </Callout>
        )}
        <Button
          disabled={!isValid}
          text={'Save Selection'}
          large
          icon={'save'}
          intent="success"
          // @ts-ignore
          onClick={(e) => {
            onSave(selected);
          }}
        />
      </div>
    </div>
  );
};
