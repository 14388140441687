import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import {
  UserContext,
  OrganisationContext,
  AnalyticsContext,
  ConfigContext,
} from '@/context';
import authenticationService from '@/services/authentication';

const UserMenu = ({ children }) => {
  const config = useContext(ConfigContext);
  const analytics = useContext(AnalyticsContext);
  const { t } = useTranslation();
  const history = useHistory();
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);

  return (
    <Popover position={Position.BOTTOM}>
      {children}
      <Menu>
        <MenuDivider title={user ? user.name || user.email : t('User')} />
        {organisation && (
          <>
            <MenuItem
              icon="home"
              text={organisation.name}
              onClick={() => {
                analytics.events.header.userOrganisationClicked();
                history.push(`/${organisation.key}`);
              }}
            />
            <MenuItem
              icon="cog"
              text={t('Organisation Settings')}
              onClick={() => {
                analytics.events.header.userOrganisationSettingsClicked();
                history.push(
                  `/${organisation.key}/settings?setting=organisation.users`
                );
              }}
            />
            <MenuDivider />
          </>
        )}
        <MenuItem
          icon="log-out"
          text={t('Sign Out')}
          onClick={() => {
            if (analytics) {
              analytics.events.header.userLogoutClicked();
              analytics.reset();
            }
            authenticationService.logout();
          }}
        />
        <MenuDivider />
        <MenuItem
          icon="info-sign"
          text={`Version ${config.WEB_VERSION}`}
          disabled={true}
        />
      </Menu>
    </Popover>
  );
};

export default UserMenu;
