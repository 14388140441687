import { Button } from '@blueprintjs/core';
import { get } from 'lodash';
import React from 'react';
import styles from './styles.module.css';

const LockableFormDecorator = (Component, decoratorProps) => (props) => {
  const { formik } = props;

  const { lockable, lockpath, disabled } = decoratorProps ?? {};

  const isLocked = get(formik.values, lockpath);

  if (disabled) {
    return <Component {...props} />;
  }

  return (
    <div className={styles.lockableDecoratorContainer}>
      <div className={styles.decoratedComponentWrapper}>
        <Component {...props} />
      </div>
      <div className={styles.lockButton}>
        <Button
          minimal
          disabled={!lockable}
          icon={isLocked ? 'lock' : 'unlock'}
          onClick={() => {
            formik.setFieldValue(lockpath, !isLocked);
            formik.setFieldTouched(lockpath);
          }}
        />
      </div>
    </div>
  );
};

export default LockableFormDecorator;
