import { PageTitle } from '@hogwarts/ui-components-core';
import { notEmpty } from '@hogwarts/utils';
import React, { useCallback, useContext, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { PageHeader } from '../../components';
import {
  SettingItem,
  SettingsLayout,
} from '../../components/Settings/SettingsLayout';
import {
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import { getCategories, itemsFactory as itemsFactoryCore } from './settings';

const Settings = () => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const user = useContext(UserContext);

  const [selected, setSelected] = useQueryParam('setting', StringParam);

  const onSelected = useCallback(
    (selectedTab) => {
      setSelected(selectedTab, 'push');
    },
    [setSelected]
  );

  const categories = useMemo(
    () => getCategories(organisation, federation),
    [organisation, federation]
  );

  const items = useMemo<SettingItem[]>(() => {
    return itemsFactoryCore({
      user,
      organisation,
      federation,
    })
      .map((item) => {
        const category = categories.find((c) => c.key === item.category);
        if (!category) {
          return null;
        }

        let visible = true;
        if (typeof item.visible === 'function') {
          visible = !!item.visible();
        } else if (item.visible != null) {
          visible = !!item.visible;
        }

        if (!visible) {
          return null;
        }

        let restricted = false;
        if (item.permission) {
          restricted = !user.hasPermission(
            item.permission,
            item.resourceId || category.resourceId
          );
        }

        if (restricted && item.hideNoPermission) {
          return null;
        }

        return {
          ...item,
          visible,
          restricted,
        };
      })
      .filter(notEmpty);
  }, [categories, organisation, federation, user]);

  return (
    <>
      <PageTitle title="Settings" />
      <PageHeader header={organisation.name} />

      <div className="container-lg mt-3 h-100">
        <SettingsLayout
          categories={categories}
          items={items}
          selected={selected || undefined}
          onSelected={onSelected}
          // @ts-ignore
          organisation={organisation}
          federation={federation}
        />
      </div>
    </>
  );
};

export default Settings;
