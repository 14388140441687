import { Button, Error, Select } from '@hogwarts/ui-components-core';

import { Spinner } from '@blueprintjs/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export interface SIAGroup {
  id: string;
  label: string;
}
interface SettingsPanelProps {
  title: string;
  subText?: string;
  loading?: boolean;
  error?: boolean;
  fixedHeight?: string;
  children?: React.ReactNode;
  currentGroup?: SIAGroup;
  groups: SIAGroup[];
  disabled: boolean;
  onGroupSelected: (group: SIAGroup) => void;
  onPushAllToGroupClicked: (group: SIAGroup) => void;
}
export const SettingsPanel = ({
  title,
  children,
  loading,
  error,
  fixedHeight,
  onGroupSelected,
  disabled,
  onPushAllToGroupClicked,
  currentGroup,
  groups,
}: SettingsPanelProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className="d-flex flex-row w-100">
          <div className={cn('mr-auto', styles.titleText)}>{t(title)}</div>
          <div className="d-flex align-items-center">
            <Select
              fill
              valueField={'id'}
              textField={'label'}
              items={groups}
              filterable
              onChange={onGroupSelected}
              value={currentGroup?.id}
            />

            <Button
              className="ml-2 pl-4 pr-4"
              icon={'user'}
              disabled={disabled || !currentGroup}
              confirm
              text={
                currentGroup
                  ? t(`Push All to {{groupName}}`, {
                      groupName: currentGroup.label,
                    })
                  : `(Select a Group)`
              }
              intent="danger"
              onClick={() => onPushAllToGroupClicked(currentGroup!)}
            />
          </div>
        </div>
      </div>
      {children && (
        <>
          <div className={styles.divider} />
          <div
            className={styles.cardBody}
            style={fixedHeight ? { height: fixedHeight } : {}}
          >
            {error ? (
              <Error />
            ) : loading ? (
              <Spinner intent="primary" />
            ) : (
              children
            )}
          </div>
        </>
      )}
    </div>
  );
};
