import React, { useContext } from 'react';
import AttributeEditor from '@/components/Settings/AttributeEditor';
import { OrganisationContext } from '@/context';
import { useMutation } from '@/hooks';
import { UPDATE_ORGANISATION_ATTRIBUTES } from '@/mutations';
import { useForms } from '@hogwarts/ui-components-forms';

const AttributeEditorContainer = () => {
  const organisation = useContext(OrganisationContext);

  const { availableAttributes, attributes } = organisation;

  const availableAttributes2 = availableAttributes.filter(
    (a) => a.data?.hidden !== true
  );

  const [updateOrganisationAttributes] = useMutation(
    UPDATE_ORGANISATION_ATTRIBUTES,
    {
      selector: 'updateOrganisationAttributes',
    }
  );

  const [showForm] = useForms({
    title: 'Attribute Editor',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Attributes Updated',
    onSave: async (values) => {
      await updateOrganisationAttributes({
        variables: {
          organisationId: organisation.id,
          values,
        },
      });
    },
    fields: availableAttributes2.map((a) => ({
      key: a.key,
      type: a.inputType,
      label: a.name,
      required: false,
      validate: a.data?.validate,
    })),
    initialValues: {
      ...organisation.attributes,
    },
  });

  return (
    <AttributeEditor
      attributes={availableAttributes2}
      values={attributes}
      showEditor={showForm}
    />
  );
};

export default AttributeEditorContainer;
