import permissionsList, { types } from '@hogwarts/permissions';
import { AvailablePermission } from '../types';

export const hasPermissionFactory =
  (availablePermissions: AvailablePermission[]) =>
  (permission: types.Permission | types.Permission[], resourceId?: string) => {
    let permissions = Array.isArray(permission) ? permission : [permission];
    permissions = permissions.filter(Boolean).map((p) => {
      if (typeof p === 'string') {
        return permissionsList[p];
      }
      return p;
    });

    if (permissions.length === 0) {
      return true;
    }

    for (const permission of permissions) {
      // If no resourceId, assume its looking for system. If the permission doesn't apply
      // to system, then just ignore it.
      if (!resourceId && !permission.resourceTypes.includes('SYSTEM')) {
        return false;
      }

      const allowed = availablePermissions.find((p) => {
        return (
          permission.resourceTypes.includes(p.resourceType) &&
          (!resourceId ||
            p.resourceId === '*' ||
            p.resourceId === resourceId) &&
          (p.permission === '*' || p.permission === permission.key)
        );
      });
      if (!allowed) {
        return false;
      }
    }
    return true;
  };
