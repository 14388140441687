import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import uuid from 'uuid/v4';
import FormBuilderDrawer from './components/FormBuilderDrawer';
import { Form, KeyedForm, OptionallyKeyedForm } from './types';

interface FormState {
  showForm: (key: string, options: any) => void;
  register: (key: string, form: Form) => void;
  unregister: (key: string) => void;
}
const FormContext = React.createContext<FormState | undefined>(undefined);

export const useFormState = (): FormState => {
  const formState = useContext(FormContext);
  if (!formState) {
    throw new Error('Form Context not Set');
  }
  return formState;
};

export const useForms = (
  form: OptionallyKeyedForm
): [
  (options?: Partial<Form>) => void,
  {
    context: FormState;
  }
] => {
  const formState = useContext(FormContext);
  if (!formState) {
    throw new Error('Form Context not Set');
  }

  const keyedForm = useMemo<KeyedForm | undefined>(() => {
    if (Array.isArray(form)) {
      throw new Error('No Longer supported');
      // const result: KeyedForm[] = [];
      // for (const form2 of form) {
      //   result.push({
      //     key: uuid().toString(),
      //     ...form2,
      //   });
      // }
      // return result;
    }
    return {
      key: uuid().toString(),
      ...form,
    };
  }, [form]);

  useEffect(() => {
    if (!keyedForm) return;
    if (Array.isArray(keyedForm)) {
      throw new Error('No longer supported');
      // const keys: string[] = [];
      // for (const form2 of keyedForm) {
      //   let { key, ...form3 } = form2;
      //   if (!key) {
      //     throw new Error(`No key specified for form`);
      //   }
      //   keys.push(key);
      //   formState.register(key, form3);
      // }
      // return () => {
      //   for (const key of keys) {
      //     formState.unregister(key);
      //   }
      // };
    } else {
      let { key, ...form2 } = keyedForm;
      if (!key) {
        throw new Error(`No key specified for form`);
      }
      formState.register(key, form2);
      return () => {
        formState.unregister(key);
      };
    }
  }, [formState, keyedForm]);

  if (!keyedForm || Array.isArray(keyedForm)) {
    throw new Error('Invalid State');
    // return formState;
  }
  return [
    (options?: Partial<Form>) => formState.showForm(keyedForm.key, options),
    { context: formState },
  ];
};

const factory = (dispatch: React.Dispatch<ReducerAction>) => {
  return {
    register(form: string, options: any) {
      dispatch({
        type: 'REGISTER_FORM',
        form,
        options,
      });
      return () => {
        this.unregister(form);
      };
    },
    unregister(form: string) {
      dispatch({
        type: 'UNREGISTER_FORM',
        form,
      });
    },
    showForm(form: string | Form, options: any) {
      if (typeof form === 'string') {
        dispatch({
          type: 'SHOW_FORM',
          form,
          options,
        });
      } else {
        dispatch({
          type: 'SHOW_FORM',
          form: uuid().toString(),
          options: {
            ...form,
            ...options,
          },
        });
      }
    },
    hideForm() {
      dispatch({
        type: 'HIDE_FORM',
      });
    },
  };
};

type FormRegistry = Record<string, Form | undefined>;
interface ReducerState {
  activeForm?: KeyedForm;
  activeFormKey?: string;
  registry: FormRegistry;
}
interface ReducerAction {
  type: string;
  form?: string;
  options?: Partial<Form>;
}

const initialState: ReducerState = {
  activeForm: undefined,
  registry: {},
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  const { type, form, options } = action;
  switch (type) {
    case 'REGISTER_FORM': {
      const registry: FormRegistry = {
        ...state.registry,
        [form!]: {
          ...(options as Form),
        },
      };
      if (state.activeFormKey === form) {
        return {
          ...state,
          registry,
          activeFormKey: form,
          activeForm: {
            ...state.activeForm!,
            ...options,
          },
        };
      }
      return {
        ...state,
        registry,
      };
    }
    case 'UNREGISTER_FORM': {
      return {
        ...state,
        registry: {
          ...state.registry,
          [form!]: undefined,
        },
      };
    }
    case 'SHOW_FORM': {
      const formDetails = state.registry[form!];
      return {
        ...state,
        activeFormKey: form,
        activeForm: {
          ...formDetails!,
          ...options,
        },
      } as ReducerState;
    }
    case 'HIDE_FORM': {
      return {
        ...state,
        activeFormKey: undefined,
        activeForm: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

interface CurrentFormProps {
  isOpen: boolean;
  activeForm?: Form;
  onClose: (params?: any) => void;
}
const CurrentForm = ({
  isOpen,
  activeForm,
  onClose,
}: // title,
// initialValues,
// fields,
// onSave,
// closeOnSave,
// onShow,
// onHide,
// ...rest
CurrentFormProps) => {
  const wasOpen = useRef(isOpen);

  useEffect(() => {
    if (wasOpen.current !== isOpen) {
      wasOpen.current = isOpen;
      if (activeForm?.onShow) {
        activeForm.onShow();
      }
      if (!isOpen && activeForm?.onHide) {
        activeForm.onHide();
      }
    }
  }, [isOpen, activeForm]);

  if (!activeForm) {
    return null;
  }

  return (
    <FormBuilderDrawer
      {...activeForm}
      // saveText={'Create Profile'}
      // savingText={'Creating'}
      // savedText={'Created'}
      initialValues={activeForm.initialValues || {}}
      isOpen={isOpen === true}
      onSave={async (values) => {
        const result = await activeForm.onSave(values);
        if (activeForm.closeOnSave !== false) {
          onClose(result);
        }
        return result;
      }}
      onClose={onClose}
    />
  );
};

export const Forms = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const forms = useMemo(() => factory(dispatch), [dispatch]);

  return (
    <>
      <CurrentForm
        isOpen={!!state.activeForm}
        activeForm={state.activeForm}
        onClose={(...args) => {
          if (state.activeForm?.onClose) {
            state.activeForm.onClose(...args);
          }
          forms.hideForm();
        }}
      />
      <FormContext.Provider value={forms}>{children}</FormContext.Provider>
    </>
  );
};
