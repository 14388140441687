import { Button, Callout } from '@blueprintjs/core';

import { AppToaster } from '@/utils/toaster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Toggle from 'react-toggle';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface PermissionsViewerProps {
  permissions: {
    key: string;
    label: string;
    granted: boolean;
    group: string;
    scrRequired?: boolean;
  }[];
}

export const PermissionsViewer = ({ permissions }: PermissionsViewerProps) => {
  const { t } = useTranslation();
  const [requiredOnly, setRequiredOnly] = React.useState(false);
  const groupedPermissions = permissions.reduce<{
    [key: string]: typeof permissions;
  }>((acc, permission) => {
    const group = permission.group;
    if (!acc[group]) {
      acc[group] = [];
    }
    if (requiredOnly && !permission.scrRequired) {
      return acc;
    }
    acc[group].push(permission);
    return acc;
  }, {});

  const requiredNotGranted = permissions.some(
    (permission) => permission.scrRequired && !permission.granted
  );

  return (
    <div>
      {requiredNotGranted && (
        <>
          <Callout
            intent="danger"
            title={t('Required permissions not granted.')}
            className="mb-2"
          >
            <p>
              {t(
                'Please use the Wonde portal to grant the required permissions. We cannot import data until these permissions are granted.'
              )}
            </p>
            <p>
              {t(
                'If you need your IT team to grant these permissions, you can copy the list of missing permissions with the button below and send it to them.'
              )}
            </p>
            <Button
              intent="primary"
              icon={<FontAwesomeIcon icon="copy" />}
              onClick={() => {
                navigator.clipboard.writeText(
                  permissions
                    .filter((p) => !p.granted)
                    .map(
                      (p) =>
                        `Key: ${p.key} Name: ${p.label}${
                          p.scrRequired ? ' (REQUIRED)' : ''
                        }`
                    )
                    .join('\n')
                );
                AppToaster.show({
                  message: t(`Copied to Clipboard`),
                  intent: 'success',
                  icon: 'tick',
                });
              }}
            >
              {t('Copy Missing Permissions List')}
            </Button>
          </Callout>
          <div className="d-flex align-items-center justify-content-end">
            <span className="mr-2 font-si">{t('Show Required Only')}</span>
            <Toggle
              checked={requiredOnly}
              onChange={() => setRequiredOnly(!requiredOnly)}
            />
          </div>
        </>
      )}
      {!requiredNotGranted && (
        <>
          <Callout
            intent="success"
            title={t('All required fields granted.')}
            className="mb-2"
          >
            {t(
              'We have all of the permissions we need to import data from Wonde. Any listed below that are not enabled are optional, allowing them may improve your experience but imports will run with existing permissions.'
            )}
          </Callout>
        </>
      )}
      {Object.keys(groupedPermissions).map((group) => {
        if (groupedPermissions[group].length) {
          return (
            <div key={group} className="mt-2">
              <div className="font-weight-bold">{group}</div>
              <div className="d-flex flex-column mt-1">
                {groupedPermissions[group].map((permission) => {
                  return (
                    <div key={permission.key} className="mx-2">
                      {permission.scrRequired && !permission.granted && (
                        <FontAwesomeIcon
                          className="mr-1 text-warning"
                          icon="triangle-exclamation"
                        />
                      )}
                      <FontAwesomeIcon
                        className={cn(
                          'mr-1',
                          permission.granted ? 'text-success' : 'text-danger'
                        )}
                        icon={permission.granted ? 'check' : 'xmark'}
                      />
                      {permission.label}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
