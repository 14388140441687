import {
  ColDef,
  ColGroupDef,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import {
  Comparison,
  Condition,
  ConditionGroup,
  Variables,
  mergeAllConditions,
} from '@hogwarts/conditionals';

import { FilterOverride } from '../../types';
import log from '@scrtracker/logging';

let variableCounter = 0;

interface FilterItem {
  filterType: string;
  filter: string;
  filterTo: string;
  values: any[];
  dateFrom: string;
  dateTo: string;
  type: string;
}

const convertToCondition = (
  dataType: string,
  variableName: string,
  filterItem: FilterItem
): [ConditionGroup | Condition, Variables] => {
  const { filterType } = filterItem;
  let comparison: Comparison;
  let variables: Variables = {};

  switch (filterType) {
    case 'number': {
      const { filter: value, type } = filterItem;

      switch (type) {
        case 'notBlank': {
          comparison = ['isNotNull', {}];
          break;
        }
        case 'blank': {
          comparison = ['isNull', {}];
          break;
        }
        case 'lessThanOrEqual':
        case 'lessThan':
        case 'greaterThan':
        case 'greaterThanOrEqual':
        case 'notEqual':
        case 'equals': {
          const valueVariableName = `value${variableCounter++}$`;
          variables[valueVariableName] = {
            type: dataType,
            value: value,
          };
          comparison = [
            type,
            {
              value: valueVariableName,
            },
          ];
          break;
        }

        // case 'inRange': {
        //   const value2 = filterItem.filterTo;

        //   const valueVariableName1 = `value${variableCounter++}$`;
        //   variables[valueVariableName1] = {
        //     type: dataType,
        //     value: value,
        //   };
        //   const valueVariableName2 = `value${variableCounter++}$`;
        //   variables[valueVariableName2] = {
        //     type: dataType,
        //     value: value2,
        //   };

        //   return [
        //     {
        //       when: variableName,
        //       comparison,
        //     },
        //     variables,
        //   ];

        //   comparison = [
        //     type,
        //     {
        //       value: valueVariableName,
        //     },
        //   ];
        //   break;

        // }

        default: {
          break;
        }
      }

      break;
    }
    case 'set': {
      const { values } = filterItem;
      switch (dataType) {
        case 'boolean': {
          comparison = [
            'oneOf',
            {
              values: (values || [])
                .map((v) => {
                  if (v === 'true') return true;
                  if (v === 'false') return false;
                  return undefined;
                })
                .filter((v) => v != null),
            },
          ];
          break;
        }
        default: {
          comparison = [
            'oneOf',
            {
              values,
            },
          ];
          break;
        }
      }

      break;
    }
    case 'text': {
      const { filter: value, type } = filterItem;

      switch (type) {
        case 'notBlank': {
          comparison = ['isNotNull', {}];
          break;
        }
        case 'blank': {
          comparison = ['isNull', {}];
          break;
        }
        case 'startsWith':
        case 'endsWith':
        case 'equals':
        case 'notEqual':
        case 'notContains':
        case 'contains': {
          const valueVariableName = `value${variableCounter++}$`;
          variables[valueVariableName] = {
            type: dataType,
            value: value,
          };
          comparison = [
            type,
            {
              value: valueVariableName,
            },
          ];
          break;
        }
        default: {
          throw new Error(`Unknown type [${type}]`);
        }
      }
      break;
    }
    case 'date': {
      //2020-10-07  00:00:00
      const { dateFrom, dateTo, type } = filterItem;
      const date = dateFrom.substring(0, 10);
      let convertedType: string;
      switch (type) {
        case 'equals': {
          convertedType = 'isDuring';
          break;
        }
        case 'notEqual': {
          convertedType = 'isNotDuring';
          break;
        }
        case 'greaterThan': {
          convertedType = 'laterThan';
          break;
        }
        case 'lessThan': {
          convertedType = 'earlierThan';
          break;
        }
        case 'inRange': {
          const date2 = dateTo.substring(0, 10);

          const date1VariableName = `value${variableCounter++}$`;
          variables[date1VariableName] = {
            type: dataType,
            value: date,
          };
          const date2VariableName = `value${variableCounter++}$`;
          variables[date2VariableName] = {
            type: dataType,
            value: date2,
          };

          return [
            {
              conditions: [
                {
                  when: variableName,
                  comparison: [
                    'exactlyOrLaterThan',
                    {
                      range: 'exact',
                      value: date1VariableName,
                    },
                  ],
                },
                {
                  when: variableName,
                  comparison: [
                    'exactlyOrEarlierThan',
                    {
                      range: 'exact',
                      value: date2VariableName,
                    },
                  ],
                },
              ],
              operator: 'and',
              enabled: true,
            },
            variables,
          ];
        }
        default: {
          throw new Error(`Unknown type [${type}]`);
        }
      }

      const valueVariableName = `value${variableCounter++}$`;
      variables[valueVariableName] = {
        type: dataType,
        value: date,
      };

      comparison = [
        convertedType,
        {
          range: 'exact',
          value: valueVariableName,
        },
      ];

      break;
    }
    default: {
      throw new Error(`Unknown type ${filterType}`);
    }
  }

  return [
    {
      when: variableName,
      comparison,
    },
    variables,
  ];
};

const convertFilterModel = (
  columnDefs: (ColDef | ColGroupDef)[],
  filterModel: any
) => {
  if (!columnDefs) return null;
  if (!filterModel) return null;

  let variables: Variables = {};
  let conditions: (ConditionGroup | Condition)[] = [];
  let result = {
    conditions,
    operator: 'and',
    enabled: true,
  };

  const columns = columnDefs.reduce<Record<string, ColDef>>((prev, col) => {
    if ('colId' in col && col.colId) {
      return {
        ...prev,
        [col.colId]: col,
      };
    }
    return prev;
  }, {});

  for (const field of Object.keys(filterModel)) {
    const filterItem = filterModel[field];

    const column = columns[field];
    if (!column) {
      log.warn('Column not found!!', {
        filterModel,
        field,
        columns,
      });
      continue;
    }

    // @ts-ignore
    const { key: fieldKey, path, type: columnType } = column.meta;

    let variableName = `${field}$`;
    variables[variableName] = {
      type: columnType,
      source: ['get', path || fieldKey],
    };

    if (filterItem.operator) {
      const childCondition: ConditionGroup = {
        conditions: [],
        operator: 'and',
        enabled: true,
      };
      switch (filterItem.operator) {
        case 'OR': {
          childCondition.operator = 'or';
          break;
        }
        case 'AND': {
          childCondition.operator = 'and';
          break;
        }
        default: {
          throw new Error(`Unknown Operator ${filterItem.operator}`);
        }
      }

      const [condition1, variables1] = convertToCondition(
        columnType,
        variableName,
        filterItem.condition1
      );
      childCondition.conditions.push(condition1);

      const [condition2, variables2] = convertToCondition(
        columnType,
        variableName,
        filterItem.condition2
      );
      childCondition.conditions.push(condition2);

      conditions.push(childCondition);

      variables = {
        ...variables,
        ...variables1,
        ...variables2,
      };
    } else {
      const [condition1, variables1] = convertToCondition(
        columnType,
        variableName,
        filterItem
      );
      variables = {
        ...variables,
        ...variables1,
      };
      conditions.push(condition1);
    }
  }

  return {
    ...result,
    variables,
  };
};

export const dataSourceFactory = (
  getMoreRows: ({
    skip,
    limit,
    sort,
    condition,
    filter,
  }: {
    skip: number;
    limit: number;
    sort: any;
    condition: ConditionGroup;
    filter: any;
  }) => Promise<any[]>
): any => {
  let filter: FilterOverride | null = null;

  return {
    // use the server-side row model
    rowModelType: 'serverSide',

    // new in v25
    serverSideStoreType: 'partial',

    // fetch x rows per at a time
    cacheBlockSize: 25,

    // only keep x blocks of rows
    maxBlocksInCache: 50,

    setFilter(updatedFilter: FilterOverride) {
      filter = updatedFilter;
    },

    // grid calls this to get rows
    async getRows(params: IServerSideGetRowsParams) {
      let {
        startRow: skip,
        endRow: limit,
        sortModel,
        filterModel,
      } = params.request;

      let columnDefs = params.api.getColumnDefs();

      if (!columnDefs) {
        params.fail();
        return;
      }

      if (!skip) skip = 0;
      if (!limit) limit = 0;

      limit -= skip;

      // This flattens the column definitions from their groups
      columnDefs = columnDefs.reduce<(ColDef | ColGroupDef)[]>((prev, col) => {
        if ('children' in col && col.children) {
          return [...prev, ...col.children];
        }
        return [...prev, col];
      }, []);

      const filterModelCondition = convertFilterModel(columnDefs, filterModel);
      let condition = mergeAllConditions(
        [
          filterModelCondition,
          typeof filter !== 'boolean' && filter?.condition,
        ].filter(Boolean)
      );

      const sort = sortModel
        ? sortModel.map(({ colId: field, sort: sortType }) => ({
            field,
            desc: sortType !== 'asc',
          }))
        : [];

      let rowData: any[];
      try {
        rowData = await getMoreRows({
          filter,
          condition,
          skip,
          limit,
          sort,
        });
        // result = await queryClient.query({
        //   query,
        //   fetchPolicy: 'no-cache',
        //   variables: {
        //     ...filter,
        //     ...variables,
        //     condition,
        //     skip,
        //     limit,
        //     sort,
        //   },
        // });
      } catch (e) {
        console.log(e);
        params.fail();
        return;
      }

      if (!Array.isArray(rowData)) {
        params.fail();
        return;
      }

      let rowCount: number | undefined;
      if (rowData.length < limit) {
        rowCount = skip + rowData.length;
      }

      if (rowCount === 0) {
        params.api.showNoRowsOverlay();
      } else {
        params.api.hideOverlay();
      }

      params.success({
        rowData,
        rowCount,
      });
    },
    // optional destroy method, if your datasource has state it needs to clean up
    // destroy?(): void;
  };
};
