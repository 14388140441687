import { gql } from '@apollo/client';

export const ADD_DASHBOARD = gql`
  mutation addDashboard($name: String!, $ownerType: String!, $ownerId: ID) {
    addDashboard(name: $name, ownerType: $ownerType, ownerId: $ownerId) {
      id
      name
      ownerType
      widgets {
        id
        type
        order
        settings
      }
    }
  }
`;

export const EDIT_DASHBOARD = gql`
  mutation updateDashboard(
    $dashboardId: ID!
    $name: String
    $ownerType: String
    $ownerId: ID
  ) {
    updateDashboard(
      dashboardId: $dashboardId
      name: $name
      ownerType: $ownerType
      ownerId: $ownerId
    ) {
      id
      name
      ownerType
      widgets {
        id
        type
        order
        settings
      }
    }
  }
`;

export const UPDATE_DASHBOARD_WIDGETS = gql`
  mutation updateDashboardWidgets(
    $dashboardId: ID!
    $widgets: [DashboardWidgetInput!]!
  ) {
    updateDashboardWidgets(dashboardId: $dashboardId, widgets: $widgets) {
      id
      name
      ownerType
      widgets {
        id
        type
        order
        settings
      }
    }
  }
`;

export const DELETE_DASHBOARD = gql`
  mutation deleteDashboard($dashboardId: ID!) {
    deleteDashboard(dashboardId: $dashboardId)
  }
`;
