import React from 'react';
import cn from 'classnames';
import { Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.css';

const AddButton = ({ disabled, height, onClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <Card
        elevation={0}
        className={cn(styles.buttonCard, {
          [styles.enabledButton]: !disabled,
        })}
        onClick={onClick}
        style={{ height }}
      >
        <FontAwesomeIcon className={styles.plusIcon} icon="plus" />
      </Card>
    </div>
  );
};

export default AddButton;
