import { NonIdealState } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatter } from '../../../../hooks';
import ActivityTag from '../../../ActivityTag/ActivityTag';
import DocumentIcon from '../../../DocumentIcon';
import { Activity, getDetail } from '../../../UserProfile/Activity';
import styles from './styles.module.css';

interface IncidentItemProps {
  item: Activity;
  visibleFields: string[];
  onSelected?: (profileId: string) => void;
}

const IncidentItem = ({
  item,
  visibleFields,
  onSelected,
}: IncidentItemProps) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();
  const isVisibleField = (field: string) =>
    !visibleFields.length || visibleFields.includes(field);

  return (
    <div
      key={item.id}
      className={styles.incident}
      onClick={() =>
        onSelected && item.profile?.id && onSelected(item.profile.id)
      }
    >
      <FontAwesomeIcon
        size="2x"
        icon={['far', 'triangle-exclamation']}
        color={item.data.severity.color || '#137cbd'}
      />
      <div className={styles.incidentContent}>
        {item.profile?.id ? (
          <div className={styles.profile}>
            <strong>{getDetail(t, item.profile?.name, 'profile')}</strong>
          </div>
        ) : (
          <div>
            <strong>
              {`${getDetail(t, item.profile?.name, 'profile')} ${t(
                '(Deleted Profile)'
              )}`}{' '}
            </strong>
          </div>
        )}

        <div className={styles.incidentGroup}>
          <div>
            {isVisibleField('category') && (
              <strong>{getDetail(t, item.data.category, 'category')} </strong>
            )}
            {isVisibleField('date') && (
              <small>({formatDate.medium(item.date.toString())})</small>
            )}
          </div>
          {isVisibleField('tags') && !!item.tags?.length && (
            <div className={styles.incidentTags}>
              {item.tags.map((tag, index) => (
                <ActivityTag
                  className={styles.tag}
                  key={index}
                  color={tag.color}
                >
                  {t(tag.name)}
                </ActivityTag>
              ))}
            </div>
          )}
        </div>
        <div className={styles.incidentGroup}>
          <div>
            {isVisibleField('status') && (
              <div>{getDetail(t, item.data.status, 'status')}</div>
            )}
            {isVisibleField('notes') && (
              <div>{getDetail(t, item.data.notes, 'notes')}</div>
            )}
          </div>
          <div className={styles.incidentExtras}>
            {isVisibleField('documents') &&
              !!item.documents?.length &&
              item.documents.map((document) => (
                <div key={document.filestackHandle}>
                  <span>{document.filename}</span>
                  <DocumentIcon
                    className={styles.documentIcon}
                    key={document.filestackHandle}
                    document={document}
                  />
                </div>
              ))}
            {isVisibleField('assignedTo') && !!item.data.assignedTo?.length && (
              <div>{getDetail(t, item.data.assignedTo, 'assignedTo')}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface IncidentsListWidgetProps {
  incidents: Activity[];
  visibleFields: string[];
  onSelected?: (profileId: string) => void;
}

const IncidentsListWidget = ({
  incidents,
  visibleFields,
  onSelected,
}: IncidentsListWidgetProps) => {
  const { t } = useTranslation();

  if (!incidents || incidents.length < 0) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={t('No Incidents Found')}
        description={t<string>(
          'There are no incidents for this user or organisation.'
        )}
      />
    );
  }

  return (
    <ListView
      className="w-100 h-100"
      componentProps={{ visibleFields, onSelected }}
      component={IncidentItem}
      rows={incidents}
    />
  );
};

export default IncidentsListWidget;
