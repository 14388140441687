// import log from '@scrtracker/logging';
import React, { useContext } from 'react';

import { Conditionals } from '../../components';
import { ConditionalScheme } from '../../components/Conditionals';

const RenderConditional = ({
  formField,
  readOnly,
  fieldHelpers,
  scheme: schemeProps,
  excludeFields,
  prioritySection,
}) => {
  let scheme = useContext(ConditionalScheme);
  if (schemeProps) {
    scheme = schemeProps;
  }

  if (!scheme) {
    return <div>{'Error. Scheme not provided'}</div>;
  }
  return (
    <Conditionals
      scheme={scheme}
      excludeFields={excludeFields}
      prioritySection={prioritySection}
      condition={formField.value}
      readOnly={readOnly}
      onChange={async (condition) => {
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(condition, true);
      }}
    />
  );
};

export default {
  name: 'Conditionals',
  options: [],
  Component: RenderConditional,
};
