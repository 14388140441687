import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import { AnalyticsContext, OrganisationContext } from '@/context';
import { useQuery } from '@/hooks';
import { DATASOURCE_QUERY_PROFILESCORESBYRATING } from '@/queries';
import { NonIdealState } from '@blueprintjs/core';
import { useForms } from '@hogwarts/ui-components-forms';
import qs from 'qs';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BarChartWidget from '../../../components/Dashboards/Widgets/BarChart';

const BarChartContainer = ({
  widgetId,
  settings,
  onSettingsUpdate,
  ...rest
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const [showForm] = useForms({
    title: 'Bar Chart Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: async (values) => {
      await onSettingsUpdate(values);
      refetch();
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedRatingSystems',
        type: 'checklist',
        label: 'Include Rating Systems',
        selectionMinimum: 1,
        values: organisation.scheme.ratingSystems.map((rs) => ({
          id: rs.key,
          value: rs.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },
    ],
    initialValues: settings,
  });
  const {
    loading,
    data: chartData,
    error,
    refetch,
  } = useQuery(DATASOURCE_QUERY_PROFILESCORESBYRATING, {
    selector: 'queryProfileScoresByRating.result',
    variables: {
      organisationKey: organisation.key,
      tags: Object.keys(settings.includedTags || {})
        .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
        .filter(Boolean),
    },
    pollInterval: organisation.demo ? 20000 : 60000,
    transform: (data) => {
      const filteredRatingSystems = Object.keys(data).reduce((prev, curr) => {
        if (settings.includedRatingSystems[curr]) {
          prev[curr] = data[curr];
        }
        return prev;
      }, {});
      const filteredProfileTypes = Object.keys(filteredRatingSystems).map(
        (rs) => {
          return {
            key: rs,
            scores: Object.keys(filteredRatingSystems[rs]).reduce(
              (prev, curr) => {
                if (
                  !settings.includedProfileTypes ||
                  settings.includedProfileTypes[curr]
                ) {
                  prev[curr] = filteredRatingSystems[rs][curr];
                }
                return prev;
              },
              {}
            ),
          };
        }
      );
      return filteredProfileTypes.reduce((prev, curr) => {
        prev[curr.key] = curr.scores;
        return prev;
      }, {});
    },
  });

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      {chartData ? (
        <BarChartWidget
          scores={chartData}
          profileTypes={organisation.scheme.profileTypes.filter((pt) => {
            return (
              !settings.includedProfileTypes ||
              settings.includedProfileTypes[pt.key]
            );
          })}
          ratingSystems={organisation.scheme.ratingSystems.filter((rs) => {
            return settings.includedRatingSystems[rs.key];
          })}
          onProfileClick={({ profileTypeKey, rating }) => {
            analytics.events.dashboard.widget.barChart.profileTypeSelected({
              type: profileTypeKey,
              rating,
            });
            const queryFields = {
              profileTypes: profileTypeKey,
              tags: Object.keys(settings.includedTags).filter(
                (t) => !!settings.includedTags[t]
              ),
              [`ratingSystem_${rating.key}`]: rating.state,
            };
            const query = `/${organisation.key}/profiles${qs.stringify(
              queryFields,
              {
                addQueryPrefix: true,
                arrayFormat: 'repeat',
              }
            )}`;
            history.push(query);
          }}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={t('Nothing to Display')}
          description={t('You may need to adjust your Widget settings')}
        />
      )}
    </WidgetWrapper>
  );
};

export default {
  type: 'bar-chart',
  name: 'Profile Scores Bar Chart',
  description:
    'Display a bar chart that gives the score breakdown for your profile types.',
  widgetComponent: BarChartContainer,
  defaultSettings: (scheme) => ({
    title: 'Profile Scores',
    includedTags: {
      active: true,
      onboarding: true,
    },
    includedRatingSystems: scheme.ratingSystems.reduce((prev, curr) => {
      prev[curr.key] = true;
      return prev;
    }, {}),
    includedProfileTypes: null,
  }),
};
