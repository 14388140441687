import React from 'react';

import { SettingsPanel } from '@/components/Settings/SettingsPanel';

import { useTranslation } from 'react-i18next';

const AttributeEditor = ({ showEditor }) => {
  const { t } = useTranslation();

  return (
    <SettingsPanel
      title={'Attribute Editor'}
      actions={[
        {
          text: 'Edit',
          onClick: showEditor,
        },
      ]}
    >
      <div>{t('Press Edit to update Attributes')}</div>
    </SettingsPanel>
  );
};

export default AttributeEditor;
