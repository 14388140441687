import { FormField, useForms } from '@hogwarts/ui-components-forms';
import { useContext, useMemo, useState } from 'react';
import {
  AnalyticsContext,
  OrganisationContext,
} from '../../../../../../context';
import { useMutation, useQuery } from '../../../../../../hooks';

import { sortBy } from 'lodash';
import { useFeature } from '../../../../../../hooks/useFeature';
import { CREATE_PROFILE_MUTATION } from '../../../../../../mutations';
import { GET_ORGANISATION_SIAGROUPS } from '../../../../../../queries';

interface SIAGroup {
  id: string;
  name: string;
  type: string;
}

interface AddProfileFormProps {
  onCreate: (data: any) => void;
}
const AddProfileForm = ({ onCreate }: AddProfileFormProps) => {
  const analytics = useContext(AnalyticsContext);
  const { key: organisationKey, scheme } = useContext(OrganisationContext);

  const isSiaEnabled = useFeature('sia');

  const [createProfile] = useMutation(CREATE_PROFILE_MUTATION, {
    selector: 'createProfile',
  });

  const { data: siaGroups } = useQuery<SIAGroup[]>(GET_ORGANISATION_SIAGROUPS, {
    variables: {
      organisationKey,
    },
    selector: 'organisations[0].siaGroups',
    skip: !isSiaEnabled,
  });

  const [selectedProfileTypeKey, setSelectedProfileTypeKey] = useState(
    scheme.profileTypes.length ? scheme.profileTypes[0].key : null
  );

  const visibleFields = useMemo<FormField[]>(() => {
    const profileTypeField: FormField = {
      key: 'profileTypeKey',
      type: 'singleselect',
      label: 'Profile Type',
      values: scheme.profileTypes.map((p) => ({
        id: p.key,
        value: p.label,
      })),
      validate: 'required',
      showEmpty: false,
    };

    if (!selectedProfileTypeKey) {
      return [profileTypeField];
    }

    const { fields } = scheme.switchProfileType(selectedProfileTypeKey);

    const showFields = sortBy(
      fields.filter((f) => {
        if (f.usable && f.enabled && f.features?.profileAdd?.enabled) {
          return true;
        }
        return false;
      }),
      (f) => f.features.profileAdd.order || 0
    );

    const result = [
      profileTypeField,

      ...showFields.map(
        (field) =>
          ({
            key: field.key,
            label: field.label,
            type: field.inputType,
            placeHolder: field.placeHolder,
            validate: field.features.profileAdd.required ? 'required' : null,
          } as FormField)
      ),
    ];

    if (siaGroups?.length) {
      result.push({
        key: 'siaSuggestedGroupId',
        type: 'singleselect',
        label: 'SIA Group',
        values: siaGroups.map((g) => ({ value: g.name, id: g.id })),
      });
    }

    return result;
  }, [scheme, selectedProfileTypeKey, siaGroups]);

  useForms({
    key: 'addProfile',
    title: 'Add Profile',
    saveText: 'Create Profile',
    savingText: 'Creating',
    savedText: 'Created',
    allowCancel: false,
    initialValues: {
      profileTypeKey: selectedProfileTypeKey,
    },
    fields: visibleFields,
    onValuesChanged: (values) => {
      if (values && values.profileTypeKey !== selectedProfileTypeKey) {
        setSelectedProfileTypeKey(values.profileTypeKey);
      }
    },
    onSave: async (values) => {
      const { profileTypeKey, ...profileData } = values;
      const profile = await createProfile({
        variables: {
          organisationKey,
          profileTypeKey,
          profileData,
        },
      });
      analytics.events.profiles.profileAdded({
        type: profileTypeKey,
      });
      if (onCreate) {
        onCreate(profile.data);
      }
      return profile.data;
    },
  });

  return null;
};

export default AddProfileForm;
