import { NonIdealState } from '@blueprintjs/core';
import { useForms } from '@hogwarts/ui-components-forms';
import { keys } from '@hogwarts/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BarChartWidget from '../../../components/Dashboards/Widgets/BarChart';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { FederationContext, OrganisationContext } from '../../../context';
import { useQuery } from '../../../hooks';
import { DATASOURCE_QUERY_FEDERATION_PROFILESCORESBYRATING } from '../../../queries';

const BarChartContainer = ({
  widgetId,
  settings,
  onSettingsUpdate,
  ...rest
}) => {
  const { t } = useTranslation();
  const federation = useContext(FederationContext);
  const organisation = useContext(OrganisationContext);

  const { loading, data, error, refetch } = useQuery(
    DATASOURCE_QUERY_FEDERATION_PROFILESCORESBYRATING,
    {
      skip: !federation,
      selector: 'queryFederationProfileScoresByRating.result',
      variables: {
        federationKey: federation?.key,
        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),
      },
      pollInterval: organisation.demo ? 20000 : 60000,
      transform: ({ scores, allProfileTypes, allRatingSystems, allTags }) => {
        const filteredRatingSystems = Object.keys(scores).reduce(
          (prev, curr) => {
            if (settings.includedRatingSystems[curr]) {
              prev[curr] = scores[curr];
            }
            return prev;
          },
          {}
        );
        const filteredProfileTypes = Object.keys(filteredRatingSystems).map(
          (rs) => {
            return {
              key: rs,
              scores: Object.keys(filteredRatingSystems[rs]).reduce(
                (prev, curr) => {
                  if (
                    !settings.includedProfileTypes ||
                    settings.includedProfileTypes[curr]
                  ) {
                    prev[curr] = filteredRatingSystems[rs][curr];
                  }
                  return prev;
                },
                {}
              ),
            };
          }
        );
        return {
          scores: filteredProfileTypes.reduce((prev, curr) => {
            prev[curr.key] = curr.scores;
            return prev;
          }, {}),
          allProfileTypes: keys(allProfileTypes),
          allRatingSystems: keys(allRatingSystems),
          allTags: keys(allTags),
        };
      },
    }
  );

  const {
    scores: chartData,
    allRatingSystems,
    allProfileTypes,
    allTags,
  } = data || {};

  const [showForm] = useForms({
    title: 'Bar Chart Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: async (values) => {
      await onSettingsUpdate(values);
      refetch();
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values:
          allTags?.map((tag) => ({
            id: tag.key,
            value: tag.label,
          })) || [],
      },
      {
        type: 'separator',
      },
      {
        key: 'includedRatingSystems',
        type: 'checklist',
        label: 'Include Rating Systems',
        selectionMinimum: 1,
        values:
          allRatingSystems?.map((rs) => ({
            id: rs.key,
            value: rs.label,
          })) || [],
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values:
          allProfileTypes?.map((pt) => ({
            id: pt.key,
            value: pt.label,
          })) || [],
      },
    ],
    initialValues: settings,
  });
  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      {chartData ? (
        <BarChartWidget
          scores={chartData}
          profileTypes={allProfileTypes.filter((pt) => {
            return (
              !settings.includedProfileTypes ||
              settings.includedProfileTypes[pt.key]
            );
          })}
          ratingSystems={allRatingSystems.filter((rs) => {
            return settings.includedRatingSystems[rs.key];
          })}
          // onProfileClick={({ profileTypeKey, rating }) => {
          //   analytics.events.dashboard.widget.barChart.profileTypeSelected({
          //     type: profileTypeKey,
          //     rating,
          //   });
          //   const queryFields = {
          //     profileTypes: profileTypeKey,
          //     tags: Object.keys(settings.includedTags).filter(
          //       (t) => !!settings.includedTags[t]
          //     ),
          //     [`ratingSystem_${rating.key}`]: rating.state,
          //   };
          //   const query = `/${organisation.key}/profiles${qs.stringify(
          //     queryFields,
          //     {
          //       addQueryPrefix: true,
          //       arrayFormat: 'repeat',
          //     }
          //   )}`;
          //   history.push(query);
          // }}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={t('Nothing to Display')}
          description={t('You may need to adjust your Widget settings')}
        />
      )}
    </WidgetWrapper>
  );
};

export default {
  type: 'federation-bar-chart',
  name: 'Federation Profile Scores Bar Chart',
  requiresFederation: true,
  description:
    'Display a bar chart that gives the score breakdown for your profile types across the Federation.',
  widgetComponent: BarChartContainer,
  defaultSettings: (scheme) => ({
    title: 'Federation Profile Scores',
    includedTags: {
      active: true,
      onboarding: true,
    },
    includedRatingSystems: scheme.ratingSystems.reduce((prev, curr) => {
      prev[curr.key] = true;
      return prev;
    }, {}),
    includedProfileTypes: null,
  }),
};
