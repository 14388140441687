import { HTMLTable } from '@blueprintjs/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { AnalyticsContext } from '../../../context';
import { AppToaster } from '../../../utils/toaster';

export interface PreferenceConfig {
  key: string;
  label: string;
  description: string;
  value: boolean;
  disabled?: boolean;
  onUpdate?: (preference: PreferenceConfig) => void;
}
interface PreferencesProps {
  settings: PreferenceConfig[];
  disabled: boolean;
}

export const PreferencesPanel = ({ settings, disabled }: PreferencesProps) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  const handleChange = async (preference: PreferenceConfig) => {
    analytics.events.checks.preferencesUpdated({
      key: preference.key,
      value: !preference.value,
    });
    if (preference.onUpdate) {
      preference.onUpdate(preference);
    }
  };

  return (
    <div>
      <HTMLTable interactive striped style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Description')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {settings?.length === 0 && (
            <tr>
              <td colSpan={3}>
                {t('There appear to be no settings available.')}
              </td>
            </tr>
          )}
          {settings
            ?.map((setting) => {
              return (
                <tr key={setting.key}>
                  <td>{t(setting.label)}</td>
                  <td>{t(setting.description)}</td>
                  <td>
                    <Toggle
                      disabled={disabled || setting.disabled}
                      checked={setting.value}
                      onChange={async () => {
                        try {
                          await handleChange(setting);
                        } catch (error) {
                          AppToaster.show({
                            icon: 'error',
                            intent: 'danger',
                            message: 'Unable to update preferences.',
                          });
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })
            .filter(Boolean)}
        </tbody>
      </HTMLTable>
    </div>
  );
};
