import permissions from '@hogwarts/permissions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PreferenceConfig,
  PreferencesPanel,
} from '../../../components/Settings/PreferencesPanel';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useMutation, usePermission } from '../../../hooks';
import { TOGGLE_CHECK_ACCOUNT_PREFERENCES } from '../../../mutations';
import { useGetCheckAccountQuery } from '../CheckAccount';

export const CheckConfigContainer = () => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const canToggleCheckAccountPreferences = usePermission(
    permissions.TOGGLE_CHECK_ACCOUNT_PREFERENCES,
    organisation.id
  );

  const { data: checkAccount, refetch } = useGetCheckAccountQuery();

  const settings: PreferenceConfig[] = [
    {
      key: 'togglePORequired',
      label: t('Require PO?'),
      description: t(
        'Purchase Order number will be required for future check orders.'
      ),
      value: checkAccount?.checkAccount?.poRequired ?? false,
      onUpdate: async () => {
        await toggleCheckAccountPreferences({
          variables: {
            organisationId: organisation.id,
            key: 'poRequired',
          },
        });
      },
    },
  ];

  const [toggleCheckAccountPreferences] = useMutation(
    TOGGLE_CHECK_ACCOUNT_PREFERENCES,
    {
      selector: 'toggleCheckAccountPreferences',
      onCompleted(key) {
        analytics.events.checks.toggleCheckAccountPreferences({
          key,
        });
        refetch();
      },
    }
  );

  if (!checkAccount?.checkAccount) {
    return null;
  }

  return (
    <SettingsPanel title={t('Preferences')}>
      <PreferencesPanel
        settings={settings}
        disabled={!canToggleCheckAccountPreferences}
      />
    </SettingsPanel>
  );
};

export default CheckConfigContainer;
