import React, { useContext } from 'react';
import groupcallLogo from '../../../../assets/providers/groupcall.png';
import {
  AnalyticsContext,
  ConfigContext,
  OrganisationContext,
} from '../../../../context';
import { ProviderMeta } from '../types';

const PROVIDER_KEY = 'groupcall';

const HelpBlock = () => {
  return (
    <div>
      <p>To book a Groupcall Installation please click Book Installation.</p>

      <p>
        If you already have Groupcall installed, simply click the Authorise
        button above and login using your Groupcall credentials.
      </p>

      <p>
        For further help on this, please see our{' '}
        <a href="https://go.scrtracker.com/3CrZiWd" target="blank">
          help guides.
        </a>
      </p>
    </div>
  );
};

interface ActionsProps {
  status: string;
  connected: boolean;
  children: (actions?: any[]) => JSX.Element;
}
const Actions = ({ connected, status, children }: ActionsProps) => {
  const config = useContext(ConfigContext);
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  let actions = [];
  if (!connected) {
    actions.push({
      intent: 'primary',
      icon: 'sign-in',
      text: 'Authorise',
      onClick: () => {
        analytics.events.dataProvider.authoriseClicked({ PROVIDER_KEY });
        // @ts-ignore
        window.location = `${config.GROUPCALL_AUTHORIZE_URL}?partnerId=${config.GROUPCALL_PARTNER_ID}&transactionId=${organisation.key}`;
      },
    });
  }
  return children(actions);
};

export default {
  key: PROVIDER_KEY,
  name: 'Groupcall Xporter',
  url: 'https://go.scrtracker.com/3pQ7Cax',
  logo: groupcallLogo, // 'https://xporter.groupcall.com/Content/images/xporter_built_on_trust.png?v=1',
  showOptions: {
    showConnection: false,
    showProfileTypeMap: false,
  },
  allowNew: false,
  Actions,
  HelpBlock,
} as ProviderMeta;
