import React, { useCallback, useContext } from 'react';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '../../../context';
import { useMemoizeArgs, useTransformProfiles } from '../../../hooks';

import { useApolloClient } from '@apollo/client';
import { useForms } from '@hogwarts/ui-components-forms';
import { parseDate } from '@hogwarts/validation';
import { useHistory } from 'react-router-dom';
import ProfilesLateDatesWidget from '../../../components/Dashboards/Widgets/ProfilesLateDatesList';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';
import { DATASOURCE_QUERY_LATE_DATES } from '../../../queries';

const LateDatesByProfileList = ({
  settings: proposedSettings,
  onSettingsUpdate,
  isFederationWidget,
  ...rest
}) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const apolloQuery = useApolloClient().query;
  const settings = useMemoizeArgs(proposedSettings);

  const transformProfiles = useTransformProfiles((profile, { scheme }) => {
    const lateCheckFields = profile.checks.map((check) => {
      const dateCheckedField = scheme.getField(check.fieldKey);

      const profileLateFields = [
        {
          key: check.fieldKey,
          sectionKey: dateCheckedField?.section,
          sectionLabel: dateCheckedField?.sectionItem?.label,
          label: dateCheckedField?.label,
          checkDate: parseDate(check.checkDate),
        },
      ];

      return profileLateFields.reduce(
        (prev, { sectionKey, sectionLabel, ...field }) => {
          if (!prev[sectionKey]) {
            return {
              key: sectionKey,
              label: sectionLabel,
              fields: [field],
            };
          }
          prev[sectionKey].fields.push(field);
          return prev;
        },
        {}
      );
    });

    return {
      ...profile,
      lateCheckFields,
    };
  });

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        key: 'condition',
        type: 'conditional',
        label: 'Filter Logic',
        scheme: organisation?.scheme,
      },
      {
        type: 'separator',
      },
      {
        key: 'show_startdate',
        type: 'checkbox',
        label: 'Show Start Date',
        large: false,
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      {
        type: 'separator',
      },
      {
        key: 'includedProfileTypes',
        type: 'checklist',
        label: 'Include Profile Types',
        selectionMinimum: 1,
        showSelectAll: true,
        values: organisation.scheme.profileTypes.map((pt) => ({
          id: pt.key,
          value: pt.label,
        })),
      },
      {
        type: 'separator',
      },

      ...organisation.scheme.ratingSystems.reduce((prev, rs) => {
        return [
          ...prev,
          {
            type: 'title',
            label: rs.label,
          },
          {
            key: `ratings.${rs.key}`,
            type: 'singleselect',
            validate: 'required',
            values: [
              {
                id: 'all',
                value: 'Show All',
              },
              {
                id: 'exclude',
                value: 'Show not ready',
              },
              {
                id: 'include',
                value: 'Show Ready',
              },
            ],
          },
        ];
      }, []),
    ],
    initialValues: {
      includeRatingSystems: {},
      ...settings,
    },
  });

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation) {
        return;
      }

      const variables = {
        organisationKey: organisation.key,
        federationKey: isFederationWidget ? federation?.key : undefined,

        condition: settings.condition,

        profileTypes: Object.keys(settings.includedProfileTypes || {})
          .map((tagKey) =>
            settings.includedProfileTypes[tagKey] ? tagKey : null
          )
          .filter(Boolean),

        tags: Object.keys(settings.includedTags || {})
          .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
          .filter(Boolean),

        ratings: Object.keys(settings.ratings || {})
          .map((systemKey) => {
            switch (settings.ratings[systemKey]) {
              case 'exclude': {
                return {
                  key: systemKey,
                  include: false,
                };
              }
              case 'include': {
                return {
                  key: systemKey,
                  include: true,
                };
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean),
        includeDeleted: false,
        skip,
        limit,
        sort: [{ field: 'name', desc: false }],
      };

      let profiles = await apolloQuery({
        query: DATASOURCE_QUERY_LATE_DATES,
        fetchPolicy: 'no-cache',
        variables: {
          ...variables,
          dynamicCondition: {
            type: 'validationRule',
            rule: 'late_date_check',
          },
        },
      });

      return transformProfiles(profiles?.data?.queryLateDates?.result);
    },
    [
      settings,
      organisation,
      federation?.key,
      transformProfiles,
      apolloQuery,
      isFederationWidget,
    ]
  );

  const fields = [settings.show_startdate ? 'startdate' : ''].filter(Boolean);

  return (
    <WidgetWrapper
      title={settings.title}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      disableScroll
      {...rest}
    >
      <ProfilesLateDatesWidget
        organisationKey={organisation.key}
        fetchMore={fetchMore}
        fields={fields}
        iconPack={organisation.attributes?.avatarIconPack}
        onProfileSelected={(profileId) => {
          analytics.events.dashboard.widget.lateDatesByProfile.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}`);
        }}
      />
    </WidgetWrapper>
  );
};

export default LateDatesByProfileList;
