import { useFormState } from '@hogwarts/ui-components-forms';
import { keys, lookup } from '@hogwarts/utils';
import LockableFormDecorator from '../../components/SchemeEditor/LockableFormDecorator';
import { expandFieldSetup } from '../../components/SchemeEditor/utils';

const customDecoratorRegistry = {
  lockable: LockableFormDecorator,
};

const getProfileTypeRatingFields = (profileType, root, ratingSystems) => {
  const pathPrefix = (path) => {
    if (!profileType) return path;
    return `profileTypeSchemes.${profileType.key}.${path}`;
  };

  const ratingSystemLookup = lookup(ratingSystems);

  const initialValues = keys(root.ratings).reduce((prev, curr) => {
    const system = ratingSystemLookup[curr.key];
    if (!system) return prev;

    return {
      ...prev,
      [pathPrefix(curr.meta.enabled.path)]: curr.enabled,
      [pathPrefix(curr.meta.enabled.lockpath)]: !!curr.lock.enabled,
    };
  }, {});

  const formFields = [
    ...keys(root.ratings).reduce((prev, curr) => {
      const system = ratingSystemLookup[curr.key];
      if (!system) {
        return prev;
      }
      return [
        ...prev,
        {
          key: pathPrefix(curr.meta.enabled.path),
          type: 'hidden',
          readOnly: curr.meta.enabled.locked,
          decorators: {
            lockable: false,
          },
        },
        {
          key: pathPrefix(curr.meta.enabled.lockpath),
          type: 'hidden',
          visible: false,
        },
      ];
    }, []),
  ];

  return [initialValues, formFields];
};

const useFieldEdit = ({
  selectedProfileTypeKey,
  currentScheme: scheme,
  profileTypes,
  ratingSystems,
  inputMetaFields: allInputMetaFields,
  onFieldRestore,
  onFieldDelete,
}) => {
  const forms = useFormState();

  return (field, onFieldUpdate) => {
    const hasDataType = field.dataType !== 'none';

    const inputMetaFields = allInputMetaFields[field.inputType];

    let dataTypes = field.dataType;
    if (!Array.isArray(dataTypes)) {
      dataTypes = [field.dataType];
    }

    const validationRules = (
      (hasDataType && scheme?.validationRules) ||
      []
    ).filter((rule) => {
      if (!rule.dataType || !field.dataType) return false;
      return dataTypes.includes(rule.dataType);
    });

    const pt = scheme.getProfileType(selectedProfileTypeKey);
    const fieldSetup = [
      pt && {
        type: 'callout',
        text: `Any changes you make here will only apply to ${pt.label}`,
        icon: 'warning-sign',
        intent: 'WARNING',
      },

      {
        key: 'label',
        type: 'textarea',
        title: 'Label',
        lockable: true,
        validate: 'required',
      },

      hasDataType &&
        field.inputType !== 'hidden' && {
          key: 'defaultValue',
          type: field.inputType,
          title: 'Default Value',

          // TODO: Could we do something here like
          // on focus, it updates the values?
          // (single-select, values list)

          ...(field.inputMeta || {}),
        },
      hasDataType &&
        field.profileTypeSchemes &&
        validationRules.length && {
          key: 'validation',
          type: 'validationRules',
          title: 'Validation Rules',
          dataType: field.dataType,
          validationRules,
          readValue: (value) => {
            return Object.keys(value || {}).reduce((prev, key) => {
              // remove old null rules
              if (!value[key]) {
                return [
                  ...prev,
                  {
                    key,
                    deleted: true,
                  },
                ];
              }
              return [
                ...prev,
                {
                  key,
                  ...value[key],
                },
              ];
            }, []);
          },
          writeValue: (value, { initialValue }) => {
            const original = lookup(initialValue);
            // Mark any missing rules as null so they will be patched out
            const result = {
              ...Object.keys(original).reduce(
                (prev, cur) => ({
                  ...prev,
                  [cur]: null,
                }),
                {}
              ),
              ...(value
                ? value.reduce((prev, { key, ...rule }) => {
                    prev[key] = rule;
                    return prev;
                  }, {})
                : {}),
            };
            return result;
          },
        },
    ];

    let [initialValues, formFields] = expandFieldSetup(field, fieldSetup);

    if (inputMetaFields && inputMetaFields.length) {
      let fields = [];
      for (const inputMetaField of inputMetaFields) {
        if (inputMetaField.title) {
          fields.push({
            type: 'title',
            label: inputMetaField.title,
            decorators: {
              lockable: false,
            },
          });
        }

        const metaField = {
          ...inputMetaField,
          localKey: inputMetaField.key,
          key: `${field.meta.inputMeta.path}.${inputMetaField.key}`,
          decorators: {
            lockable: false,
          },
        };
        fields.push(metaField);

        if (
          field.inputMeta &&
          typeof field.inputMeta[metaField.localKey] !== 'undefined'
        ) {
          initialValues = {
            ...initialValues,
            [metaField.key]: field.inputMeta[metaField.localKey],
          };
        } else if (typeof inputMetaField.defaultValue !== 'undefined') {
          initialValues = {
            ...initialValues,
            [metaField.key]: inputMetaField.defaultValue,
          };
        }
      }

      formFields.splice(3, 0, ...fields);
    }

    if (field.profileTypeSchemes) {
      initialValues = {
        ...initialValues,
        visibilityAndRatings: {},
      };

      for (const profileType of profileTypes) {
        let profileTypeFieldMetaData =
          field.profileTypeSchemes[profileType.key];

        const enabledField = expandFieldSetup(
          profileTypeFieldMetaData,
          [
            {
              key: 'enabled',
              type: 'hidden',
            },
          ],
          `profileTypeSchemes.${profileType.key}`
        );

        const ratingsFields = getProfileTypeRatingFields(
          profileType,
          profileTypeFieldMetaData,
          ratingSystems
        );

        initialValues = {
          ...initialValues,
          ...enabledField[0],
          ...ratingsFields[0],
        };

        formFields = [...formFields, ...enabledField[1], ...ratingsFields[1]];
      }

      formFields.push({
        type: 'visibilityAndRatings',
        fieldKey1: field.key,
        sectionKey: field.section,
        ratingSystems: ratingSystems,
        profileTypes: profileTypes.filter((p) => p.usable),
        includePathPrefix: true,
        decorators: {
          lockable: false,
        },
        scheme,
      });

      let result = expandFieldSetup(field, [
        {
          type: 'title',
          label: 'Visibility Logic',
          decorators: {
            lockable: false,
          },
        },
        {
          key: 'visibility',
          type: 'conditional',
          decorators: {
            label: false,
          },
          excludeFields: [field.key],
          prioritySection: field.section,
          scheme,
        },
      ]);

      initialValues = {
        ...initialValues,
        ...result[0],
      };

      formFields = [...formFields, ...result[1]];
    } else {
      const profileType = profileTypes.find(
        (p) => p.key === selectedProfileTypeKey
      );

      let enabledField = expandFieldSetup(field, [
        {
          key: 'enabled',
          type: 'hidden',
        },
      ]);

      const ratingsFields = getProfileTypeRatingFields(
        null,
        field,
        ratingSystems
      );

      initialValues = {
        ...initialValues,
        ...enabledField[0],
        ...ratingsFields[0],
      };

      formFields = [...formFields, ...enabledField[1], ...ratingsFields[1]];

      formFields.push({
        type: 'visibilityAndRatings',
        ratingSystems,
        profileTypes,
        showProfileTypes: [profileType],
        decorators: {
          lockable: false,
        },
        scheme,
        fieldKey1: field.key,
        sectionKey: field.section,
        includePathPrefix: false,
      });
    }

    formFields = [
      ...formFields,
      {
        type: 'separator',
        decorators: {
          lockable: false,
        },
      },
      {
        type: 'label',
        label: `Identifier: ${field.key}`,
        decorators: {
          lockable: false,
        },
      },
      {
        type: 'label',
        label: `Owner: ${field.meta.owner}`,
        decorators: {
          lockable: false,
        },
      },
    ];

    const allowDelete =
      field.meta.owned === true && !field.sectionItem?.deleted;

    const unregister = forms.register('field_edit', {
      title: 'Field Editor',
      initialValues: {
        fieldKey: field.key,
        ...initialValues,
      },
      scheme,
      fields: formFields,
      decorators: customDecoratorRegistry,
      size: 512,
    });
    forms.showForm('field_edit', {
      closeOnCancel: true,
      onSave: async (params) => {
        onFieldUpdate({ updated: params });
        unregister();
      },
      allowDelete,
      onDelete: async () => {
        if (field.deleted) {
          return onFieldRestore(field.key);
        }
        return onFieldDelete(field.key);
      },
      deleteText: field.deleted ? 'Restore' : 'Delete',
    });
  };
};

export default useFieldEdit;
