import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';

import styles from './styles.module.css';

const PieChartWidget = ({ scores, ratingSystems, onRatingClick }) => {
  const { t } = useTranslation();
  const chartData = useMemo(
    () => {
      return Object.keys(scores).map((scoreKey) => {
        const ratingSystem = ratingSystems.find((rs) => rs.key === scoreKey);
        const labels = [t(ratingSystem.label), t(`Not ${ratingSystem.label}`)];
        const colors = [ratingSystem.readyColor, ratingSystem.notReadyColor];
        const data = Object.keys(scores[scoreKey]).reduce(
          (prev, curr) => {
            const currentScore = scores[scoreKey][curr];
            return [
              prev[0] + currentScore.valid,
              prev[1] + (currentScore.count - currentScore.valid),
            ];
          },
          [0, 0]
        );
        const datasets = [
          {
            data,
            borderWidth: 1,
            backgroundColor: colors,
          },
        ];

        return { key: scoreKey, labels, datasets };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratingSystems, scores]
  );

  return (
    <>
      {chartData &&
        chartData.map((data, idx, arr) => (
          <div
            key={`pie-${idx}/${arr.length}`}
            className={styles.pieChartContainer}
          >
            <Pie
              data={data}
              height={500}
              width={630 / chartData.length}
              getElementAtEvent={(dataset) => {
                onRatingClick({
                  rating: {
                    key: data.key,
                    state: dataset[0]._index === 0,
                  },
                });
              }}
            />
          </div>
        ))}
    </>
  );
};

export default PieChartWidget;
