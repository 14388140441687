import React from 'react';
import { useTranslation } from 'react-i18next';
import { NonIdealState } from '@blueprintjs/core';

const ProfileListNoRowsOverlay = () => {
  const { t } = useTranslation();
  //
  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching Profiles found')}
      description={
        <div>
          <div>{t("Your filter conditions didn't match any profiles")}</div>
          <div className="mt-3">
            {t(
              "If you haven't yet created a profile, press the Add button or visit the Bulk Importer"
            )}
          </div>
        </div>
      }
    />
  );
};

export default ProfileListNoRowsOverlay;
