import { NonIdealState } from '@blueprintjs/core';
import { Loading } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { rowFactory as itemFactory } from '../utils';
import styles from './styles.module.css';

type FetchMethod = (...args: any[]) => Promise<any>;
const useLastFetch = (fetch: FetchMethod): [FetchMethod, boolean] => {
  const currentInvocation = useRef(0);
  const abort = () => {
    currentInvocation.current++;
    setFetching(false);
  };
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    abort();
  }, [fetch]);
  const wrappedFetch = useCallback(
    async (...args) => {
      abort();
      const identifier = currentInvocation.current;
      setFetching(true);
      try {
        const result = await fetch(...args);
        if (currentInvocation.current !== identifier) {
          throw new Error('Aborted');
        }
        return result;
      } finally {
        if (currentInvocation.current === identifier) {
          setFetching(false);
        }
      }
    },
    [fetch]
  );

  return [wrappedFetch, fetching];
};

const DefaultEmptyComponent = () => {
  return <NonIdealState title={'No items available'} />;
};

interface InfiniteGalleryViewProps {
  className?: string;
  component: React.FunctionComponent<any>;
  componentProps?: object;
  emptyComponent: React.ReactNode;
  spreadRowItem?: boolean;
  border?: boolean;
  divider?: boolean;
  pageSize?: number;
  useWindowScroll?: boolean;
  fetchMore: FetchMethod;
  withControlledProps?: boolean;
}

export const InfiniteGalleryView = ({
  className,
  component,
  componentProps,
  spreadRowItem = false,
  border = false,
  divider = false,
  pageSize = 25,
  useWindowScroll = false,
  fetchMore: fetchMoreCore,
  withControlledProps = false,
}: InfiniteGalleryViewProps) => {
  const [items, setItems] = useState<any[] | null>();
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [fetchMore, loading] = useLastFetch(fetchMoreCore);

  let item;

  if (withControlledProps) {
    const Component = component;
    item = useMemo(
      () => (index: number) =>
        <Component item={items?.[index]} {...componentProps} />,
      [componentProps, items]
    );
  } else {
    item = useMemo(
      () =>
        itemFactory(component, items || [], componentProps, {
          divider,
          spreadRowItem,
        }),
      [divider, component, componentProps, items, spreadRowItem]
    );
  }

  useEffect(() => {
    let nextRows;
    const fetchMoreItems = async () => {
      nextRows = await fetchMore(0, pageSize);

      if (Array.isArray(nextRows) && nextRows.length) {
        setItems([...nextRows]);
      }
    };

    setItems(null);
    setFullyLoaded(false);

    fetchMoreItems().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore]);

  const tryGetMoreItems = useCallback(async () => {
    if (loading || fullyLoaded || !items) return;
    let nextRows;
    try {
      nextRows = await fetchMore(items.length, pageSize);
    } catch (e) {
      return console.error(e);
    }
    if (Array.isArray(nextRows)) {
      if (nextRows.length < pageSize) {
        setFullyLoaded(true);
      }
      if (nextRows.length) {
        setItems([...items, ...nextRows]);
      }
    } else {
      setFullyLoaded(true);
    }
  }, [fetchMore, fullyLoaded, loading, pageSize, items]);

  return items?.length ? (
    <VirtuosoGrid
      useWindowScroll={useWindowScroll}
      totalCount={items.length || 0}
      className={cn(border && styles.listBorders, className)}
      itemContent={item}
      endReached={tryGetMoreItems}
      overscan={pageSize}
      itemClassName={styles.listItem}
      listClassName={styles.list}
      components={{
        ScrollSeekPlaceholder: () => {
          if (!loading || fullyLoaded) return null;
          return <Loading />;
        },
      }}
    />
  ) : (
    <DefaultEmptyComponent />
  );
};
