import { useMemoizeArgs, useRunOnce } from '@hogwarts/ui-components-core';
import { Formik, FormikTouched } from 'formik';
import { get, isEqual, set } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  FormField,
  FormikProps,
  ValidationRuleMethod,
  ValueBucket,
} from '../../types';
import defaultDecoratorRegistry, {
  Decorator,
  DecoratorRegistry,
} from './decorators';

import { isObject } from '@hogwarts/utils';
import { RenderFields } from './RenderFields';

const builtInValidationRules: Record<string, ValidationRuleMethod> = {
  required: (value: any, field: FormField) => {
    if (value == null || value === '') {
      return `${field.label} is required`;
    }
  },
  truthy: (value: any, field: FormField) => {
    if (!value) {
      return `${field.label} is required`;
    }
  },
  email: (value: any, field: FormField) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(value)) {
      return `${field.label} is not a valid email address`;
    }
  },
  name: (value: any, field: FormField) => {
    if (value == null || value === '') {
      return;
    }

    for (const rule of [/[']{2}/, /[-]{2}/, /[ ]{2}/]) {
      if (rule.test(value)) {
        return `${field.label} cannot contain two or more spaces, hyphens or single quotes consecutively.`;
      }
    }

    const regex = /^[A-Za-z][A-Za-z'\- ]*[A-Za-z]$/;
    if (!regex.test(value)) {
      return `${field.label} must contain only letters and spaces, and must start and end with a letter`;
    }
  },
  number: (value: any, field: FormField) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(value)) {
      return `${field.label} must contain only numbers`;
    }
  },
  URL: (value: any, field: FormField) => {
    if (value == null || value === '') {
      return;
    }

    const urlRegex = /^(http(s)?:\/\/)?[\w.-]+\.\w{2,}(\/\S*)?$/;
    if (!urlRegex.test(value)) {
      return `Invalid ${field.label}. Please enter a valid URL.`;
    }
  },
};

const getValidationResult = (
  field: FormField,
  value: any,
  values: ValueBucket
):
  | {
      severity: string;
      message: string;
    }[]
  | undefined => {
  let result;

  let validateMethod = field.validate;

  if (Array.isArray(field.validate)) {
    for (const validate of field.validate) {
      const result = getValidationResult(
        {
          ...field,
          validate,
        },
        value,
        values
      );
      if (result) {
        return result;
      }
    }
    return;
  } else if (typeof field.validate === 'string') {
    const key = field.validate;
    validateMethod = builtInValidationRules[key];
    if (field.required === false) {
      validateMethod = (value: any, field: FormField, values: ValueBucket) => {
        if (value == null || value === '') {
          return;
        }
        return builtInValidationRules[key](value, field, values);
      };
    }
  }

  if (typeof validateMethod === 'function') {
    result = validateMethod(value, field, values);
    if (result != null) {
      if (typeof result === 'string') {
        result = [
          {
            severity: 'error',
            message: result,
          },
        ];
      } else if (!Array.isArray(result)) {
        result = [result];
      }
    }
  }
  return result;
};

const validateFactory = (fields: FormField[]) => (values: ValueBucket) => {
  return (
    fields &&
    fields.filter(Boolean).reduce((prev, field) => {
      if (!field.key) {
        return prev;
      }

      let result = {
        ...prev,
      };

      if (Array.isArray(field.children)) {
        const childValues = get(values, field.key);
        if (Array.isArray(childValues)) {
          for (let index = 0; index < childValues.length; index++) {
            for (const child of field.children) {
              if (!child.key) {
                continue;
              }
              let childValue = childValues[index];
              const childResult = getValidationResult(
                child,
                get(childValue, child.key),
                childValues
              );
              if (childResult) {
                set(result, `${field.key}[${index}].${child.key}`, childResult);
              }
            }
          }
        }
      }

      const fieldResult = getValidationResult(
        field,
        get(values, field.key),
        values
      );
      if (fieldResult) {
        set(result, field.key, fieldResult);
      }

      return result;
    }, {})
  );
};

const getChangedValues = (
  fields: FormField[],
  touched: FormikTouched<any>,
  values: any
) => {
  let changedValues = fields.filter(Boolean).reduce((prev, field) => {
    if (field.key && get(touched, field.key)) {
      set(prev, field.key, get(values, field.key));
    }
    return prev;
  }, {});
  return changedValues;
};

interface MyFormBodyProps {
  fields: FormField[];
  decoratorRegistry: { item: Decorator; key: string }[];
  formikProps: FormikProps;
  children: (component: any, props: any) => JSX.Element;
  props: FormBuilderProps;
  readOnly?: boolean;
  validateAtStart?: boolean;
}
const MyFormBody = ({
  fields: proposedFields,
  decoratorRegistry,
  formikProps,
  props,
  children,
  validateAtStart,
}: MyFormBodyProps) => {
  // const {
  // touched,
  // errors,
  // dirty,
  // handleChange,
  // handleBlur,
  // handleSubmit,
  // handleReset,
  // values,
  // isSubmitting,
  // setFieldValue,
  // setFieldTouched,
  // } = formikProps;
  // isDirty doesnt work on a reset. got fed up with it.
  // Currently this doesnt say null and '' are the same, so doesnt play great for text
  // not sure if thats an actual issue though

  const fields = useMemoizeArgs(proposedFields);
  const values = useMemoizeArgs(formikProps.values);
  const touched = useMemoizeArgs(formikProps.touched);

  const dirty = !isEqual(formikProps.initialValues, values);

  useEffect(() => {
    if (props.onValuesChanged) {
      let changedValues = getChangedValues(fields, touched, values);
      if (Object.keys(changedValues).length === 0) return;
      props.onValuesChanged(values, changedValues, formikProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, touched, fields, formikProps.isValid]);

  useEffect(() => {
    if (validateAtStart && props.onValuesChanged) {
      let changedValues = getChangedValues(fields, touched, values);
      props.onValuesChanged(values, changedValues, formikProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formBodyProps = {
    ...props,
    formikProps: {
      ...formikProps,
      dirty,
    },
    decoratorRegistry,
  };

  if (typeof children === 'function') {
    const tweakedComponent = children(<RenderFields {...formBodyProps} />, {
      ...formikProps,
      dirty,
    });
    if (tweakedComponent) {
      return tweakedComponent;
    }
    return <div>Error</div>;
  }
  return <RenderFields {...formBodyProps} />;
};

interface FormBuilderProps {
  initialValues: ValueBucket;
  enableReinitialize?: boolean;
  onValuesChanged?: (
    initialValues: ValueBucket,
    changedValues: ValueBucket,
    formikProps: any
  ) => void;
  fields: FormField[];
  children?: (component: JSX.Element, props: any) => JSX.Element;
  onSave?: (values: ValueBucket) => void;
  onReset?: () => void;
  decorators?: DecoratorRegistry;
  validateAtStart?: boolean;
  readOnly?: boolean;
  componentProps?: any;
}
const FormBuilder = (props: FormBuilderProps) => {
  let {
    fields,
    children,
    onSave: onSaveUnsafe,
    decorators: customDecoratorRegistry,
    validateAtStart,
    readOnly,
    onReset,
  } = props;

  const [onSave] = useRunOnce(onSaveUnsafe);

  const decoratorRegistry = useMemo<{ item: Decorator; key: string }[]>(() => {
    const registry: DecoratorRegistry = {
      ...defaultDecoratorRegistry,
      ...customDecoratorRegistry,
    };
    // to array and order it.
    const list = Object.keys(registry).reduce<
      { item: Decorator; key: string }[]
    >(
      (prev, key) => [
        ...prev,
        {
          item: registry[key],
          key,
        },
      ],
      []
    );

    // ORDER IT PROPERLY !!
    list.reverse();

    return list;
  }, [customDecoratorRegistry]);

  const initialValues = useMemo(() => {
    const result =
      fields &&
      fields.filter(Boolean).reduce((prev, field) => {
        if (!field.key) return prev;
        let value = get(props.initialValues, field.key);
        if (typeof value === 'undefined') {
          value = null;
        }
        if (typeof field.readValue === 'function') {
          value = field.readValue(value);
        } else if (Array.isArray(value)) {
          value = [...value];
        } else if (isObject(value)) {
          value = { ...value };
        }

        set(prev, field.key, value);
        return prev;
      }, {});
    return result;
  }, [fields, props.initialValues]);

  const validate = useMemo(() => validateFactory(fields), [fields]);

  const [initialErrors, initialTouched] = useMemo(() => {
    if (!initialValues) return [{}, {}];
    if (validateAtStart !== true) {
      return [{}, {}];
    }
    const errors = validate(initialValues);
    const touched = Object.keys(errors).reduce(
      (prev, key) => ({
        ...prev,
        [key]: true,
      }),
      {}
    );
    return [errors, touched];
  }, [initialValues, validate, validateAtStart]);

  if (!initialValues) return null;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={props.enableReinitialize}
      validate={validate}
      initialTouched={initialTouched}
      initialStatus={{}}
      initialErrors={initialErrors}
      onReset={(currentValues, formikProps) => {
        if (props.onValuesChanged) {
          let changedValues = fields.filter(Boolean).reduce((prev, field) => {
            if (
              field.key &&
              get(initialValues, field.key) !== get(currentValues, field.key)
            ) {
              set(prev, field.key, get(initialValues, field.key));
            }
            return prev;
          }, {});
          if (Object.keys(changedValues).length === 0) return;
          props.onValuesChanged(initialValues, changedValues, formikProps);
        }

        if (onReset) {
          onReset();
        }
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (typeof onSave === 'function') {
          const parsed =
            fields &&
            fields.filter(Boolean).reduce((prev, field) => {
              if (!field.key) return prev;
              let value = get(values, field.key);
              if (typeof field.writeValue === 'function') {
                let initialValue = get(initialValues, field.key);
                value = field.writeValue(value, { initialValue });
              }
              set(prev, field.key, value);
              return prev;
            }, {});
          await onSave(parsed);
          resetForm({ values: parsed });
        }
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        return (
          <MyFormBody
            formikProps={formikProps}
            props={props}
            fields={fields}
            decoratorRegistry={decoratorRegistry}
            readOnly={readOnly}
            validateAtStart={validateAtStart}
          >
            {children!}
          </MyFormBody>
        );
      }}
    </Formik>
  );
};

export default FormBuilder;
