import trackEvent from '../trackEvent';

export const incidentTrackingEnabled = (traits = {}) => {
  trackEvent('Incident Tracking - Enabled', traits);
};

export const incidentTrackingDisabled = (traits = {}) => {
  trackEvent('Incident Tracking - Disabled', traits);
};

export const activityAdded = (traits = {}) => {
  trackEvent('Incident Tracking - Activity Added', traits);
};

export const saveClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Save Clicked', traits);
};

export const addIncidentClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Add Incident Clicked', traits);
};

export const deleteAttributeClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Delete Attribute Clicked', traits);
};

export const deleteAttributeConfirmed = (traits = {}) => {
  trackEvent('Incident Tracking - Delete Attribute Confirmed', traits);
};

export const editAttributeClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Edit Attribute Clicked', traits);
};

export const attributeEdited = (traits = {}) => {
  trackEvent('Incident Tracking - Attribute Edited', traits);
};

export const addAttributeClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Add Attribute Clicked', traits);
};

export const attributeAdded = (traits = {}) => {
  trackEvent('Incident Tracking - Attribute Added', traits);
};

export const removeDocumentClicked = (traits = {}) => {
  trackEvent('Incident Tracking - Remove Document Clicked', traits);
};

export const documentRemoved = (traits = {}) => {
  trackEvent('Incident Tracking - Document Removed', traits);
};
