import React, { useEffect, useState } from 'react';
import { H1 } from '@/ui-dom-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Popover } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Button } from '@hogwarts/ui-components-core';

import styles from './styles.module.css';

const DashboardItem = ({
  favourite,
  label,
  canEdit,
  canDelete,
  onSelected,
  onEdit,
  onDelete,
  onSetDefault,
}) => {
  const [isOver, setIsOver] = useState(false);
  const [isDeleteQuestion, setDeleteQuestion] = useState(false);
  return (
    <div
      className={styles.dashboardItemContainer}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => {
        setIsOver(false);
        setDeleteQuestion(false);
      }}
    >
      <div className={styles.dashboardItem} onClick={onSelected}>
        <p>{label}</p>
      </div>
      {canDelete && isOver && isDeleteQuestion ? (
        <Button
          onClick={onDelete}
          intent="danger"
          allowed={canDelete}
          icon={['far', 'trash-alt']}
        >
          Are you sure?
        </Button>
      ) : (
        <ButtonGroup>
          <Button
            minimal
            iconSize="lg"
            icon={favourite ? ['fa', 'star'] : isOver ? ['far', 'star'] : null}
            iconColor={favourite ? '#fecc00' : 'gray'}
            onClick={onSetDefault}
          />
          {canEdit && isOver && !isDeleteQuestion && (
            <Button
              minimal
              allowed={canEdit}
              icon={'pencil'}
              onClick={onEdit}
            />
          )}

          {canDelete && isOver && !isDeleteQuestion && (
            <Button
              minimal
              intent="danger"
              allowed={canDelete}
              icon={['far', 'trash-alt']}
              onClick={() => setDeleteQuestion(true)}
            />
          )}
        </ButtonGroup>
      )}
    </div>
  );
};

const DashboardsSelector = ({
  defaultDashboardId: initialDefaultDashboardId,
  organisationName,
  currentDashboard,
  userDashboards,
  organisationDashboards,
  federationDashboards,
  onDashboardSetDefault,
  onDashboardSelected,
  onDashboardEdit,
  onDashboardDelete,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [defaultDashboardId, setDefaultDashboardId] = useState(
    initialDefaultDashboardId
  );
  useEffect(() => {
    setDefaultDashboardId(initialDefaultDashboardId);
  }, [initialDefaultDashboardId]);

  return (
    <div className={styles.headerContainer}>
      <p className={styles.headerOrganisationName}>{organisationName}</p>
      <Popover
        position="bottom"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div
          className={styles.selectorContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          {currentDashboard && (
            <>
              <H1 className={styles.selectorCurrent}>{currentDashboard}</H1>
              <FontAwesomeIcon
                className={styles.selectorButton}
                icon="chevron-down"
              />
            </>
          )}
        </div>
        <div className={styles.popoverContainer}>
          {userDashboards && !!userDashboards.length && (
            <div>
              <p className={styles.groupHeader}>{t('Your Dashboards')}</p>
              {userDashboards.map((dashboard) => (
                <DashboardItem
                  key={dashboard.id}
                  label={dashboard.name}
                  canEdit={dashboard.canEdit}
                  canDelete={dashboard.canDelete}
                  favourite={defaultDashboardId === dashboard.id}
                  onSetDefault={async () => {
                    setDefaultDashboardId(dashboard.id);
                    await onDashboardSetDefault(dashboard);
                  }}
                  onEdit={() => {
                    onDashboardEdit(dashboard);
                    setIsOpen(false);
                  }}
                  onDelete={() => {
                    onDashboardDelete(dashboard);
                    setIsOpen(false);
                  }}
                  onSelected={() => {
                    onDashboardSelected(dashboard);
                    setIsOpen(false);
                  }}
                />
              ))}
            </div>
          )}
          {organisationDashboards && !!organisationDashboards.length && (
            <div>
              <p className={styles.groupHeader}>
                {t('Shared Organisation Dashboards')}
              </p>
              {organisationDashboards.map((dashboard) => (
                <DashboardItem
                  key={dashboard.id}
                  label={dashboard.name}
                  canEdit={dashboard.canEdit}
                  canDelete={dashboard.canDelete}
                  favourite={defaultDashboardId === dashboard.id}
                  onSetDefault={async () => {
                    setDefaultDashboardId(dashboard.id);
                    await onDashboardSetDefault(dashboard);
                  }}
                  onEdit={() => {
                    onDashboardEdit(dashboard);
                    setIsOpen(false);
                  }}
                  onDelete={() => onDashboardDelete(dashboard)}
                  onSelected={() => {
                    onDashboardSelected(dashboard);
                    setIsOpen(false);
                  }}
                />
              ))}
            </div>
          )}
          {federationDashboards && !!federationDashboards.length && (
            <div>
              <p className={styles.groupHeader}>
                {t('Shared Federation Dashboards')}
              </p>
              {federationDashboards.map((dashboard) => (
                <DashboardItem
                  key={dashboard.id}
                  label={dashboard.name}
                  canEdit={dashboard.canEdit}
                  canDelete={dashboard.canDelete}
                  favourite={defaultDashboardId === dashboard.id}
                  onSetDefault={async () => {
                    setDefaultDashboardId(dashboard.id);
                    await onDashboardSetDefault(dashboard);
                  }}
                  onEdit={() => {
                    onDashboardEdit(dashboard);
                    setIsOpen(false);
                  }}
                  onDelete={() => onDashboardDelete(dashboard)}
                  onSelected={() => {
                    onDashboardSelected(dashboard);
                    setIsOpen(false);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default DashboardsSelector;
