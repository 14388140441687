import {
  Button,
  Classes,
  Dialog,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileTypeIcon } from '../../../../components';
import styles from './styles.module.css';

interface ProfileTypeSummaryProps {
  profileType: {
    key: string;
    label: string;
    avatar: string;
  };
  added: number;
  updated: number;
  invalid: number;
  iconPack?: string;
}
export const ProfileTypeSummary = ({
  profileType,
  iconPack,
  added,
  updated,
  invalid,
}: ProfileTypeSummaryProps) => {
  return (
    <div className={styles.profileTypeContainer}>
      <ProfileTypeIcon
        width="48px"
        avatarKey={profileType.avatar}
        iconPack={iconPack}
      />
      <div className={styles.contentContainer}>
        <p className={styles.profileName}>{profileType.label}</p>
        <div className={styles.tagsContainer}>
          <Tag color={'green'} label={`${added || 0} to add`} />
          <Tag color={'orange'} label={`${updated || 0} to update`} />
          <Tag color={'red'} label={`${invalid || 0} are invalid`} />
        </div>
      </div>
    </div>
  );
};

export interface SummaryItem {
  typeKey: string;
  added: number;
  updated: number;
  invalid: number;
}
export interface BulkImportSummaryCoreProps {
  isOpen: boolean;
  onClose: () => void;
  summary?: SummaryItem[];
  onImport?: () => void;
  error?: boolean;
  loading?: boolean;
  iconPack?: string;
  profileTypes: {
    key: string;
    label: string;
    avatar: string;
  }[];
}
export const BulkImportSummaryCore = ({
  isOpen,
  onClose,
  summary,
  onImport,
  error,
  loading,
  iconPack,
  profileTypes,
}: BulkImportSummaryCoreProps) => {
  const { t } = useTranslation();

  let anyInvalid = true;
  let nothingFound = true;
  if (summary) {
    anyInvalid = !!summary.find((s) => s.invalid > 0);
    nothingFound = !summary.find((s) => s.added + s.updated + s.invalid > 0);
  }

  return (
    <Dialog
      title="Bulk Import Summary"
      icon="info-sign"
      onClose={onClose}
      isOpen={isOpen}
      isCloseButtonShown={true}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        {error ? (
          <NonIdealState
            icon="error"
            title={t('Error loading the Summary')}
            description={t<string>(
              'Please refresh, if this continues then contact support'
            )}
          />
        ) : loading ? (
          <Spinner />
        ) : nothingFound ? (
          <NonIdealState
            icon="help"
            title={t('Nothing to Import')}
            description={t<string>(
              "You haven't got anything to import, try adding some data first"
            )}
          />
        ) : (
          summary && (
            <div>
              {profileTypes.map((profileType) => {
                const profileTypeSummary = summary.find(
                  (s) => s.typeKey === profileType.key
                );
                if (profileTypeSummary) {
                  return (
                    <ProfileTypeSummary
                      key={profileType.key}
                      profileType={profileType}
                      iconPack={iconPack}
                      {...profileTypeSummary}
                    />
                  );
                }
                return null;
              })}
            </div>
          )
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={onImport}
            disabled={nothingFound || anyInvalid}
            large
            intent={Intent.PRIMARY}
          >
            <FontAwesomeIcon className={'mr-1'} icon="cloud-upload" />
            {t('Import')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
