import React from 'react';
import { getAvatar } from '../../assets/people';

interface ProfileTypeIconProps {
  avatarKey: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  iconPack?: string;
}
const ProfileTypeIcon = ({
  avatarKey,
  iconPack = 'default',
  className,
  style,
  width = '100px',
}: ProfileTypeIconProps) => {
  const avatar = getAvatar(avatarKey, iconPack);
  return (
    <img
      className={className}
      src={avatar}
      style={{ width, ...style }}
      alt=""
    />
  );
};

export default ProfileTypeIcon;
