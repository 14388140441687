import { OrganisationSelector } from '@/components';
import { AnalyticsContext, OrganisationContext, UserContext } from '@/context';
import { orderBy } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getLink } from '../utils';

const OrganisationMenu = ({ organisations, children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);

  const defaultOrganisationKey = user?.preferences?.defaultOrganisationKey;

  const mappedOrganisations = useMemo(
    () =>
      orderBy(
        organisations &&
          organisations.map((org) => {
            let disabled = false;
            let label;
            const favourite = org.key === defaultOrganisationKey;
            if (org.key === organisation?.key) {
              disabled = true;
              label = t('Current Organisation');
            }
            return {
              ...org,
              name: org.name?.trim(),
              label,
              icon: null,
              favourite,
              disabled,
            };
          }),
        ['favourite', (o) => o.name.toLowerCase()],
        ['desc', 'asc']
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisation?.key, organisations]
  );

  if (!mappedOrganisations || mappedOrganisations.length === 1) {
    return null;
  }

  return (
    <OrganisationSelector
      organisations={mappedOrganisations}
      onSelected={(selectedOrganisation) => {
        analytics.events.header.changedSchool({
          key: selectedOrganisation.key,
          name: selectedOrganisation.name,
        });
        const url = getLink(
          organisation.key,
          selectedOrganisation.key,
          location
        );
        history.push(url);
      }}
    >
      {children}
    </OrganisationSelector>
  );
};

export default OrganisationMenu;
