import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Error } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ACCEPT_TERMS_AND_CONDITIONS } from '../../mutations';
import { GET_CURRENT_USER } from '../../queries';

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  const [acceptTermsAndConditions] = useMutation(ACCEPT_TERMS_AND_CONDITIONS, {
    refetchQueries: [
      {
        query: GET_CURRENT_USER,
      },
    ],
  });

  const handleAcceptTerms = async () => {
    await acceptTermsAndConditions();
  };

  return (
    <Error
      icon={<FontAwesomeIcon icon={['far', 'file-signature']} />}
      title="Terms and Conditions"
      description={
        <p>
          {t('You must review and accept our ')}
          <a href="https://www.signincentralrecord.com/legal" target="_blank">
            {t('Terms and Conditions')}
          </a>
          {t(
            ' before continuing. If you have any questions or concerns contact us at '
          )}
          <a href="mailto:help@signincentralrecord.com">
            help@signincentralrecord.com
          </a>
          .
        </p>
      }
      action={
        <Button intent="primary" onClick={handleAcceptTerms}>
          {t('Accept Terms')}
        </Button>
      }
    />
  );
};
