import { useEffect, useState } from 'react';
import { GET_JOB, GET_JOB_Response } from '../queries';
import { useLazyQuery } from './useLazyQuery';

export const useJobMonitor = (jobId?: string) => {
  const [status, setStatus] = useState('none');

  const [getJob, { data: jobData, stopPolling }] =
    useLazyQuery<GET_JOB_Response>(GET_JOB, {
      pollInterval: 1000,
      selector: 'jobs[0]',
    });

  useEffect(() => {
    if (!jobId) {
      setStatus('none');
      return;
    }
    getJob({
      variables: {
        id: jobId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  useEffect(() => {
    if (!jobData) return;
    switch (jobData.status) {
      case 'completed': {
        if (stopPolling) {
          stopPolling();
        }
        setStatus('completed');
        break;
      }
      default:
      case 'inprogress':
      case 'queued':
      case 'error': {
        setStatus(jobData.status);
        break;
      }
    }
  }, [jobData, stopPolling]);

  return {
    status,
    result: jobData?.result,
  };
};
