import permissions from '@hogwarts/permissions';
import { useForms } from '@hogwarts/ui-components-forms';
import { FormikBag, FormikConfig } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsContext,
  OrganisationContext,
} from '../../../../../../context';
import { useMutation, usePermission } from '../../../../../../hooks';
import { ADD_SIGN_OFF, DELETE_DOCUMENT } from '../../../../../../mutations';
import { DATASOURCE_QUERY_SIGNOFFS } from '../../../../../../queries';
import { Document } from '../../../../../../types';

interface AddSignOffResponse {
  id: string;
  signedOffBy: {
    id: string;
    name: string;
  };
  signedOffAt: string;
  notes: string;
  ratings: {
    systemKey: string;
    count: number;
    valid: number;
    score: number;
    ready: boolean;
  };
  owner: {
    type: string;
    id: string;
  };
}

interface AddSignOffFormProps {
  onCreate: (signOff: AddSignOffResponse) => {};
  onOpenDocumentPicker: () => boolean;
  isUploadSuccessful: boolean;
  skipDelete: (flag?: boolean) => void;
  documents: Document[];
  resetDocuments: () => void;
  onRemoveDocument: (document: Document) => void;
}

const AddSignOffForm = ({
  onCreate,
  onOpenDocumentPicker,
  documents,
  resetDocuments,
  onRemoveDocument,
}: AddSignOffFormProps) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const [isDocumentAttached, setIsDocumentAttached] = useState(false);
  const [formik, setFormik] = useState<FormikBag<FormikConfig<any>, any>>();
  let isFormSaved = false;

  const [addSignOff] = useMutation<AddSignOffResponse>(ADD_SIGN_OFF, {
    selector: 'addSignOff',
    refetchQueries: [
      {
        query: DATASOURCE_QUERY_SIGNOFFS,
        variables: { organisationKey: organisation.key },
      },
    ],
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    selector: 'deleteDocument',
  });

  const allowDeleteFile = usePermission(
    permissions.ORGANISATION_FILE_DELETE,
    organisation.id
  );

  const removeDocuments = () => {
    resetDocuments();
    if (!isFormSaved) {
      documents.forEach(async (document) => {
        if (allowDeleteFile) {
          await deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
          setIsDocumentAttached(false);
        }
      });
    }
  };

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(
        'documents',
        documents.map((document) => document.id)
      );
    }

    setIsDocumentAttached(!!documents.length);
  }, [formik, documents, setIsDocumentAttached]);

  useForms({
    key: 'addSignOff',
    title: 'Add Sign Off',
    saveText: 'Sign Off',
    savingText: 'Signing Off',
    savedText: 'Signed Off',
    successToastMessage: 'Sign Off Complete',
    allowCancel: true,
    canSave: true,
    initialValues: {
      notes: '',
      documents: [],
    },
    fields: [
      {
        key: 'label',
        type: 'label',
        label: t(
          `You are about to sign off that you have reviewed the current state of ${organisation.name}'s SCR and that you're happy with what you've seen. You can add any notes and documents below and they will be stored along side your approval.`
        ),
      },
      {
        key: 'notes',
        type: 'textarea',
        label: t('Notes'),
      },
      {
        type: 'separator',
      },
      {
        key: 'selectLabel',
        type: 'label',
        label: t('Select documents to attach to this sign off.'),
      },
      {
        key: 'selectButton',
        type: 'button',
        label: t('Select'),
        onClickHandler({ formik: formikBag }: { formik: FormikBag<any, any> }) {
          setFormik(formikBag);
          analytics.events.profile.uploadActionClicked();
          onOpenDocumentPicker();
        },
      },
      {
        key: 'documentsUploaded',
        type: 'documentlist',
        visible: isDocumentAttached,
        allowDelete: allowDeleteFile,
        className: 'withLineBreaks',
        label: t('Documents'),
        documents,
        onRemoveDocument: (document: Document) => {
          analytics.events.activities.removeDocumentClicked({
            documentId: document.id,
          });
          onRemoveDocument(document);
          deleteDocument({
            variables: {
              documentId: document.id,
            },
          });
        },
      },
      {
        key: 'documents',
        type: 'hidden',
      },
    ],
    onSave: async (values) => {
      const { notes } = values;
      const signOff = await addSignOff({
        variables: {
          notes,
          ownerType: 'ORGANISATION',
          ownerId: organisation.id,
          documents,
        },
      });
      if (onCreate) {
        onCreate(signOff.data!);
      }

      isFormSaved = !!signOff.data;
      analytics.events.signoff.signOffComplete();
    },
    onClose: removeDocuments,
    onReset: removeDocuments,
  });

  return null;
};

export default AddSignOffForm;
