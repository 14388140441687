import React, { useContext } from 'react';
import { Activity, ActivityMeta } from './types';

import { Button } from '@hogwarts/ui-components-core';
import { capitalize } from '@hogwarts/utils';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnalyticsContext } from '../../../context';
import { useDateFormatter } from '../../../hooks';
import { User } from '../../../types';
import ActivityTag from '../../ActivityTag/ActivityTag';
import DocumentIcon from '../../DocumentIcon';
import styles from './styles.module.css';

interface ActivityProps extends Activity {
  showConfirmDeleteAlert: (args: {
    activityId: string;
    activityType: string;
  }) => void;
  editActivity: Function;
  showUpdateActivityForm: (name: string, values: Record<string, any>) => void;
}

type dataKey = keyof Activity['data'];

export const getDetail = (translate: Function, field: any, key: string) => {
  if (!field) {
    return `(no ${key})`;
  }
  if (field instanceof Array) {
    switch (key) {
      case 'assignedTo':
        return field.length
          ? field.map((user: User) => <div key={user.id}>{user.name}</div>)
          : translate('Unassigned');
      default:
        return translate('Unhandled array'); // Added to flag that this scenario is not handled
    }
  } else {
    switch (key) {
      case 'status':
        return <ActivityTag color={field.color}>{field.name}</ActivityTag>;
      case 'severity':
      case 'category':
        return translate(field.name);
      default:
        return translate(field) ?? '';
    }
  }
};

export const ActivityItem = ({
  documents,
  id: activityId,
  data,
  owner,
  tags,
  type,
  date,
  showConfirmDeleteAlert,
  showUpdateActivityForm,
}: ActivityProps) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();
  const analytics = useContext(AnalyticsContext);

  return (
    <div className={cn(styles.activity, styles[`activity${capitalize(type)}`])}>
      <header
        className={cn(styles.activityHeader)}
        style={{
          borderLeft: `5px solid ${data.severity?.color}`,
        }}
      >
        <div>
          <div>
            <strong>
              {capitalize(type.replace(/([a-z])([A-Z])/g, '$1 $2'))}
            </strong>
            <span className="ml-2"> {formatDate.medium(date.toString())}</span>
          </div>
          <div>
            {!!tags?.length &&
              tags.map((tag, index) => (
                <ActivityTag
                  className={styles.tag}
                  key={index}
                  color={tag.color}
                >
                  {t(tag.name)}
                </ActivityTag>
              ))}
          </div>
        </div>
        <div>
          <Button
            intent="none"
            icon="edit"
            onClick={() => {
              analytics?.events.profile.activityUpdateClicked();
              showUpdateActivityForm('updateIncident', {
                initialValues: {
                  id: activityId,
                  profile: owner.id, // Only when owner.type === 'PROFILE'
                  notes: data.notes,
                  assignedTo: data.assignedTo?.map((user: User) => user.id),
                  category: data.category?.id,
                  tags: tags?.map((tag: ActivityMeta) => tag.id),
                  date: date,
                  status: data.status?.id,
                  severity: data.severity?.id,
                  documentsUploaded: documents || [],
                  documents:
                    documents?.map(({ id }: { id: string }) => id) || [],
                  removeDocuments: false,
                },
              });
            }}
          >
            {t('Update')}
          </Button>
          <Button
            className="ml-2"
            intent="danger"
            icon="trash"
            onClick={() => {
              analytics?.events.profile.activityDeleteClicked();
              showConfirmDeleteAlert({
                activityId,
                activityType: type,
              });
            }}
          >
            {t('Delete')}
          </Button>
        </div>
      </header>
      <div className={styles.activityBody}>
        <div className={styles.activityBodyContent}>
          <div className={styles.activityBodyDetails}>
            {Object.keys(data)
              .filter(
                (key) =>
                  !['id', 'notes'].includes(key) && !!data[key as dataKey]
              )
              .map((key) => {
                return (
                  <div key={key}>
                    <strong>
                      {t(capitalize(key.replace(/([a-z])([A-Z])/g, '$1 $2')))}
                    </strong>
                    <div>{getDetail(t, data[key as dataKey], key)}</div>
                  </div>
                );
              })}
          </div>

          <div>
            {!!documents?.length && (
              <div className={styles.activityDocuments}>
                <strong>{t('Documents')}</strong>
                {documents.map((document) => (
                  <div key={document.filestackHandle} className="mb-1">
                    <span>{document.filename}</span>
                    <DocumentIcon
                      document={document}
                      className={styles.documentIcon}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {data.notes && (
          <div className={styles.activityNotes}>
            <strong>{t('Notes')}</strong>
            <div className={styles.activityNotesText}>{t(data.notes)}</div>
          </div>
        )}
      </div>
    </div>
  );
};
