import React from 'react';

import { Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

// TODO: ArticleId is now dead, need to migrate to some other system
// wondering if to setup a redirect system for this?

const Article = ({ articleId, intent, title, label }) => {
  const { t } = useTranslation();
  return (
    <Callout
      title={title || 'Guidance'}
      intent={intent || 'primary'}
      icon="help"
    >
      <div>
        <p>{label}</p>

        {articleId && (
          <a
            href={`https://go.scrtracker.com/${articleId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Click to View Article')}
          </a>
        )}
      </div>
    </Callout>
  );
};

export default {
  name: 'Article',
  options: [],
  decorators: {
    label: false,
  },
  Component: Article,
};
