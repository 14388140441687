import trackEvent from '../trackEvent';

export const importNowClicked = (traits = {}) => {
  trackEvent('Data Provider - Import Now Clicked', traits, true);
};

export const importDiscoveryClicked = (traits = {}) => {
  trackEvent('Data Provider - Import Discovery Clicked', traits, true);
};

export const disconnectClicked = (traits = {}) => {
  trackEvent('Data Provider - Disconnect Clicked', traits, true);
};

export const authoriseClicked = (traits = {}) => {
  trackEvent('Data Provider - Authorise Clicked', traits, true);
};

export const showRequestInstallationClicked = (traits = {}) => {
  trackEvent('Data Provider - Show Request Installation Clicked', traits, true);
};

export const requestInstallationClicked = (traits = {}) => {
  trackEvent('Data Provider - Request Installation Clicked', traits, true);
};

export const showSignupFormClicked = (traits = {}) => {
  trackEvent('Data Provider - Show Signup Form Clicked', traits, true);
};

export const saveConnectionClicked = (traits = {}) => {
  trackEvent('Data Provider - Save Connection Clicked', traits, true);
};

export const saveFieldMapClicked = (traits = {}) => {
  trackEvent('Data Provider - Save Field Map Clicked', traits, true);
};

export const saveLocationMapClicked = (traits = {}) => {
  trackEvent('Data Provider - Save Location Map Clicked', traits, true);
};

export const saveProfileTypeMapClicked = (traits = {}) => {
  trackEvent('Data Provider - Save ProfileType Map Clicked', traits, true);
};

export const changedProvider = (traits = {}) => {
  trackEvent('Data Provider - Changed Provider', traits, true);
};
