export const expandFieldSetup = (item, fieldSetup, rootPath) => {
  const joinRootPath = (path) => {
    if (!rootPath) return path;
    return [rootPath, path].join('.');
  };

  const initialValues = fieldSetup.filter(Boolean).reduce((prev, field) => {
    if (!field.key) return prev;
    return {
      ...prev,
      [joinRootPath(item.meta[field.key].path)]: item[field.key],
      [joinRootPath(item.meta[field.key].lockpath)]: !!item.lock[field.key],
    };
  }, {});

  const formFields = fieldSetup
    .filter(Boolean)
    .reduce((prev, formField) => {
      if (!formField) return prev;
      const key = formField.key;
      const title = formField.title;
      const label = formField.label;

      if (!key) {
        return [
          ...prev,
          title && {
            type: 'title',
            label: title,
            decorators: {
              lockable: false,
            },
          },
          {
            ...formField,
            decorators: {
              lockable: false,
            },
          },
        ];
      }

      return [
        ...prev,
        title && {
          type: 'title',
          label: title,
          decorators: {
            lockable: false,
          },
        },
        {
          ...formField,
          key: joinRootPath(item.meta[key].path),
          readOnly: item.meta[key].locked,
          label,
          decorators: {
            label: false,
            lockable:
              formField.lockable !== false
                ? {
                    lockable:
                      !item.meta[key].locked &&
                      item.meta[key].lockable !== false,
                    lockpath: joinRootPath(item.meta[key].lockpath),
                  }
                : false,
          },
        },
        formField.lockable !== false && {
          key: joinRootPath(item.meta[key].lockpath),
          type: 'hidden',
        },
      ];
    }, [])
    .filter(Boolean);

  return [initialValues, formFields];
};

export const convertFields = (fields, item) => {
  const result = [];
  const initialValues = {};
  for (const field of fields) {
    if (field.key && item) {
      const lockpath = item.meta[field.key] && item.meta[field.key].lockpath;
      result.push({
        ...field,
        key: item.meta[field.key].path,
        readOnly: item.meta[field.key].locked === true,
        decorators: {
          lockable: lockpath
            ? {
                lockable: item.meta[field.key].lockable !== false,
                lockpath: item.meta[field.key].lockpath,
              }
            : {
                lockable: false,
              },
        },
      });
      initialValues[item.meta[field.key].path] = item[field.key];

      if (lockpath) {
        result.push({
          key: lockpath,
          type: 'boolean',
          visible: false,
        });
        initialValues[lockpath] = !!item.lock[field.key];
      }
    } else {
      if (field.key) {
        initialValues[field.key] = field.defaultValue;
      }

      result.push({
        ...field,
        decorators: {
          lockable: false,
        },
      });
    }
  }

  return [initialValues, result];
};
