import { Scheme, Field as SchemeField } from '@hogwarts/utils-schemes';

import { parseDate } from '@hogwarts/validation';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';

interface Field {
  name: string;
  from?: string;
  to: string;
  order?: number;
}

interface Group {
  type?: string;
  label: string;
  fields: Field[];
  order: number;
}

export interface ServerVersion {
  id: string;
  organisationChanged: boolean;
  organisation: {
    id: string;
    key: string;
    name: string;
  };
  profileTypeChanged: boolean;
  previousProfileTypeKey: string;
  profileTypeKey: string;
  data: Record<string, unknown>;
  meta: Record<string, unknown>;
  source: string;
  changes: string[];
  user: {
    id: string;
    email: string;
    name: string;
  };
  date: string;
  previousDifferences: {
    field: string;
    index: number;
    action: string;
    from: string;
    to: string;
  }[];
  first: boolean;
}

const formatValue = (field: SchemeField | null, value: any) => {
  if (Array.isArray(value)) {
    return 'Array';
  }
  if (value == null) {
    return 'nothing';
  }
  if (value === '') {
    return 'empty';
  }
  if (field && field.dataType === 'date') {
    const date = parseDate(value);
    if (date.isValid) {
      return date.toLocaleString(DateTime.DATE_MED);
    }
    return value;
  }
  if (value === true) {
    return 'True';
  }
  if (value === false) {
    return 'False';
  }
  if (typeof value === 'string') {
    return `'${value}'`;
  }
  return value;
};

export const transformProfileVersionData =
  (scheme: Scheme) => (versions: ServerVersion[]) => {
    let result: any = [];
    for (const version of versions) {
      const first = !!version.first;
      const userName = version.user?.name;
      const date = version.date;

      if (version.organisationChanged) {
        result.push({
          id: version.id,
          type: 'org_change',
          userName,
          from: null,
          to: version.organisation.name,
          date,
          source: version.source,
          changes: version.changes,
        });
      }
      if (version.profileTypeChanged) {
        let profileType = scheme.getProfileType(version.profileTypeKey);
        const profileTypeName = profileType?.label || version.profileTypeKey;

        let previousProfileTypeName;
        if (version.previousProfileTypeKey) {
          let previousProfileType = scheme.getProfileType(
            version.previousProfileTypeKey
          );
          previousProfileTypeName =
            previousProfileType?.label || version.previousProfileTypeKey;
        }
        result.push({
          id: version.id,
          type: 'type_change',
          from: previousProfileTypeName,
          to: profileTypeName,
          userName,
          date,
          source: version.source,
          changes: version.changes,
        });
      }

      if (version.previousDifferences?.length > 0) {
        const differences = version.previousDifferences;

        let groups: Record<string, Group> = {
          __unknown: {
            // type: null,
            label: 'Other',
            fields: [],
            order: Number.MAX_SAFE_INTEGER,
          },
        };

        for (const difference of differences) {
          const differenceKey = difference.field;

          let field = scheme.getField(differenceKey);
          let section = field?.sectionItem;

          let group: Group;
          if (!field || !section) {
            group = groups.__unknown;
          } else {
            group = groups[field.section];
            if (!group) {
              group = groups[section.key] = {
                label: section.label,
                order: section.order,
                fields: [],
              };
            }
          }

          let to, from;
          switch (difference.action) {
            case 'added': {
              to = formatValue(field, difference.to);
              from = null;
              break;
            }
            case 'deleted': {
              from = formatValue(field, difference.from);
              to = null;
              break;
            }
            default: {
              to = formatValue(field, difference.to);
              from = formatValue(field, difference.from);
              break;
            }
          }

          let label = field
            ? field.label || differenceKey
            : `Deleted Field [${differenceKey}]`;
          if (typeof label === 'string' && label.endsWith('?')) {
            label = label.substring(0, label.length - 1);
          }
          group.fields.push({
            name: label,
            from,
            to,
          });
        }

        let sections = Object.keys(groups).reduce<Group[]>(
          (prev, groupKey) => [
            ...prev,
            {
              key: groupKey,
              ...groups[groupKey],
              fields: sortBy(groups[groupKey].fields, (i) =>
                i.order != null ? i.order : Number.MAX_SAFE_INTEGER
              ),
            },
          ],
          []
        );

        sections = sortBy(
          sections.filter((s) => s.fields.length),
          (g) => g.order
        );

        // changes!
        result.push({
          id: version.id,
          type: first ? 'add' : 'edit',
          userName,
          date,
          sections,
          first,
          source: version.source,
        });
      }
    }

    return result;
  };
