import { useFormState } from '@hogwarts/ui-components-forms';
import { useTranslation } from 'react-i18next';
import LockableFormDecorator from '../../components/SchemeEditor/LockableFormDecorator';
import { expandFieldSetup } from '../../components/SchemeEditor/utils';

const customDecoratorRegistry = {
  lockable: LockableFormDecorator,
};

const useSectionEdit = ({
  profileTypes,
  selectedProfileTypeKey,
  currentScheme: scheme,
  onSectionRestore,
  onSectionDelete,
}) => {
  const { t } = useTranslation();
  const forms = useFormState();

  const selectedProfileType = scheme.getProfileType(selectedProfileTypeKey);

  return (section, onSectionUpdate) => {
    const fieldSetup = [
      selectedProfileType && {
        type: 'callout',
        text: `Any changes you make here will only apply to ${selectedProfileType.label}`,
        icon: 'warning-sign',
        intent: 'WARNING',
      },

      // {
      //   key: 'enabled',
      //   type: 'checkbox',
      //   title: 'Enabled (Admin Only)',
      //   lockable: true,
      // },
      // //
      {
        key: 'label',
        type: 'textbox',
        title: 'Name',
        lockable: true,
        validate: 'required',
      },
      section.profileTypeSchemes && {
        key: 'icon',
        type: 'iconpicker',
        title: 'Icon',
        componentProps: {
          type: 'section-icon',
          heading: t('Section Icon Selector'),
        },
      },
      section.profileTypeSchemes && {
        key: 'color',
        type: 'colorpicker',
        title: 'Colour',
      },
    ];

    let [initialValues, fields] = expandFieldSetup(section, fieldSetup);

    if (section.profileTypeSchemes) {
      for (const profileType of profileTypes) {
        if (!profileType.usable) continue;
        let root = section.profileTypeSchemes[profileType.key];
        if (!root) continue;
        const result = expandFieldSetup(
          root,
          [
            {
              key: 'enabled',
              type: 'hidden',
            },
          ],
          `profileTypeSchemes.${profileType.key}`
        );

        initialValues = {
          ...initialValues,
          ...result[0],
        };

        fields = [...fields, ...result[1]];
      }

      fields.push({
        type: 'sectionVisibility',
        sectionKey: section.key,
        profileTypes: profileTypes.filter((p) => p.usable),
        includePathPrefix: true,
        decorators: {
          lockable: false,
        },
        scheme,
      });
    } else {
    }

    fields = [
      ...fields,
      {
        type: 'separator',
        decorators: {
          lockable: false,
        },
      },
      {
        type: 'label',
        label: `Identifier: ${section.key}`,
        decorators: {
          lockable: false,
        },
      },
      {
        type: 'label',
        label: `Owner: ${section.meta.owner}`,
        decorators: {
          lockable: false,
        },
      },
    ];

    const allowDelete = section.meta.owned === true;

    forms.register('section_edit', {
      title: 'Section Editor',
      initialValues: {
        sectionKey: section.key,
        ...initialValues,
      },
      fields,
      decorators: customDecoratorRegistry,
      size: 512,
    });
    forms.showForm('section_edit', {
      onSave: async (params) => {
        if (onSectionUpdate) {
          onSectionUpdate({ updated: params });
        }
      },
      allowDelete,
      onDelete: async () => {
        if (section.deleted) {
          return onSectionRestore(section.key);
        }
        return onSectionDelete(section.key);
      },
      deleteText: section.deleted ? 'Restore' : 'Delete',
    });
  };
};

export default useSectionEdit;
