import { useFormState } from '@hogwarts/ui-components-forms';
import LockableFormDecorator from '../LockableFormDecorator';
import { convertFields } from '../utils';

const customDecoratorRegistry = {
  lockable: LockableFormDecorator,
};

const getFields = (ratingSystem, { ratingSystems }) => [
  {
    key: 'label',
    type: 'textbox',
    label: 'Name',
    validate: 'required',
  },
  {
    key: 'readyColor',
    type: 'colorpicker',
    label: 'Ready Colour',
  },
  {
    key: 'notReadyColor',
    type: 'colorpicker',
    label: 'Not Ready Colour',
  },
  {
    key: 'enabled',
    type: 'toggle',
    label: 'Enabled',
  },
  {
    key: 'mandatory',
    type: 'toggle',
    label: 'Mandatory',
  },
];

export const useRatingSystemEdit = (ratingSystems) => {
  const forms = useFormState();

  const showEditForm = (ratingSystem, onEdit, onDelete) => {
    const [initialValues, fields] = convertFields(
      getFields(ratingSystem, { ratingSystems }),
      ratingSystem
    );

    const allowDelete = false; // ratingSystem.meta.owned === true;

    forms.showForm({
      title: 'Edit Rating System',
      initialValues,
      fields,
      decorators: customDecoratorRegistry,
      size: 512,
      onSave: async (values) => {
        return onEdit(values);
      },
      allowDelete,
      onDelete,
      deleteText: ratingSystem.deleted ? 'Restore' : 'Delete',
    });
  };

  const showAddForm = (onAdd) => {
    const [initialValues, fields] = convertFields(
      getFields(null, { ratingSystems })
    );

    forms.showForm({
      title: 'Add Rating System',
      initialValues,
      fields,
      decorators: customDecoratorRegistry,
      size: 512,
      onSave: async (values) => {
        return onAdd(values);
      },
    });
  };

  return [showAddForm, showEditForm];
};

// const RatingSystemEditor = ({
//   ratingSystem,
//   onRatingSystemSelected,
//   onRatingSystemUpdate,
// }) => {
//   const { t } = useTranslation();
//   const [initialValues, formFields] = useMemo(
//     () => {
//       const initialValues = {
//         [ratingSystem.meta.label.path]: ratingSystem.label,
//         [ratingSystem.meta.enabled.path]: ratingSystem.enabled,
//         [ratingSystem.meta.mandatory.path]: ratingSystem.mandatory,
//         [ratingSystem.meta.readyColor.path]: ratingSystem.readyColor,
//         [ratingSystem.meta.notReadyColor.path]: ratingSystem.notReadyColor,

//         // include the lock status values
//         [ratingSystem.meta.label.lockpath]: !!ratingSystem.lock.label,
//         [ratingSystem.meta.enabled.lockpath]: !!ratingSystem.lock.enabled,
//         [ratingSystem.meta.mandatory.lockpath]: !!ratingSystem.lock.mandatory,
//         [ratingSystem.meta.readyColor.lockpath]: !!ratingSystem.lock.readyColor,
//         [ratingSystem.meta.notReadyColor.lockpath]: !!ratingSystem.lock
//           .notReadyColor,
//       };
//       const formFields = [

//       ];

//       return [initialValues, formFields];
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [ratingSystem.key],
//   );

//   return (
//     <div className={styles.editorContainer}>
//       <div className={styles.headerContainer}>
//         <p className={styles.editorHeader}>{t('Rating System Editor')}</p>
//         <ButtonGroup minimal>
//           <Button onClick={() => onRatingSystemSelected(null)}>
//             <FontAwesomeIcon icon="times" />
//           </Button>
//         </ButtonGroup>
//       </div>
//       <FormBuilder
//         key={ratingSystem.key}
//         onValuesChanged={(values, changed) => {
//           onRatingSystemUpdate({ updated: changed });
//         }}
//         enableReinitialize
//         initialValues={initialValues}
//         decorators={customDecoratorRegistry}
//         de
//         fields={formFields}
//       />
//     </div>
//   );
// };

// export default RatingSystemEditor;
