import BooleanCellRenderer from './BooleanCellRenderer';
import ColorCellRenderer from './ColorCellRenderer';
import FontAwesomeCellRenderer from './FontAwesomeCellRenderer';
import IndentCellRenderer from './IndentCellRenderer';
import ProfileListNoRowsOverlay from './ProfileListNoRowsOverlay';
import RatingCellRenderer from './RatingCellRenderer';
import TagRenderer from './TagRenderer';
import ValidProfileRenderer from './ValidProfileRenderer';
import WarningCellRenderer from './WarningCellRenderer';

export const frameworkComponents = {
  booleanRenderer: BooleanCellRenderer,
  fontAwesomeRenderer: FontAwesomeCellRenderer,
  indentCellRenderer: IndentCellRenderer,
  profileListNoRowsOverlay: ProfileListNoRowsOverlay,
  ratingRenderer: RatingCellRenderer,
  tagRenderer: TagRenderer,
  warningRenderer: WarningCellRenderer,
  validProfileRenderer: ValidProfileRenderer,
  colorRenderer: ColorCellRenderer,
};
