import SchemeEditor from '@/containers/schemeEditor';
import Settings from '@/containers/settings';
import { get } from 'lodash';
import React from 'react';
import { Organisation } from '../types';
import { Route } from './types';

export const settingsRoutes = (organisation: Organisation): Route[] => [
  {
    path: `/${organisation.key}/settings`,
    Component: () => <Settings />,
  },
  {
    path: `/${organisation.key}/settings/template/:schemeId`,
    Component: (props: any) => {
      const schemeId = get(props, 'match.params.schemeId');

      return <SchemeEditor schemeId={schemeId} />;
    },
  },
];
