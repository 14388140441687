import React from 'react';

import { EditableText } from '@blueprintjs/core';

import { getErrorIntent } from './utils';

const InlineTextBox = ({
  fieldKey,
  formField,
  readOnly,
  label,
  placeHolder,
  fieldHelpers,
  fieldMeta,
}) => {
  const intent = getErrorIntent(fieldMeta);
  return (
    <EditableText
      id={fieldKey}
      className={'w-100'}
      intent={intent}
      onConfirm={(value) => {
        if (value !== null) {
          fieldHelpers.setTouched(true, false);
          fieldHelpers.setValue(value, true);
        }
      }}
      placeholder={placeHolder || label}
      defaultValue={formField.value || ''}
      disabled={readOnly}
      multiline={false}
      type="text"
    />
  );
};

export default {
  name: 'Inline Text Box',
  options: [],
  decorators: {
    label: false,
  },
  Component: InlineTextBox,
};
