import { SettingsPanel } from '@/components/Settings/SettingsPanel';
import { useMutation, useQuery } from '@/hooks';
import { CREATE_API_KEY } from '@/mutations';
import { GET_USER_API_KEY_IDENT } from '@/queries';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ApiKey = () => {
  const { t } = useTranslation();

  const [yourNewKey, setYourNewKey] = useState(null);

  const {
    data: apiKeyIdent,
    loading,
    error,
  } = useQuery(GET_USER_API_KEY_IDENT, { selector: 'user.apiKeyIdent' });

  const [generateApiKey] = useMutation(CREATE_API_KEY, {
    selector: 'createUserApiKey',
    onCompleted: (key) => {
      setYourNewKey(key);
    },
  });

  return (
    <SettingsPanel
      title={'API Key'}
      loading={loading}
      error={error}
      actions={[
        !apiKeyIdent &&
          !yourNewKey && { onClick: generateApiKey, text: 'Generate Key' },
        (apiKeyIdent || yourNewKey) && {
          onClick: generateApiKey,
          text: 'Regenerate Key',
        },
      ]}
    >
      {apiKeyIdent && !yourNewKey && (
        <p className={'mt-3'}>
          {t('Existing Key ') + apiKeyIdent.substring(0, 4) + 'xxxxxxxxxx'}
        </p>
      )}
      {yourNewKey && (
        <>
          <input className={'col-12 mt-3'} readOnly value={yourNewKey} />
          <p className={'mt-3'}>
            {t(
              'Please make a note of the key as it cannot not be displayed again'
            )}
          </p>
        </>
      )}
    </SettingsPanel>
  );
};

export default ApiKey;
