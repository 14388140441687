import { ProgressBar } from '@hogwarts/ui-components-core';
import React from 'react';

const RatingCellRenderer = ({ value: rating, data }) => {
  if (!rating) {
    return null;
  }

  const value = rating?.score || 0;

  let color = rating.readyColor;
  if (value < 1) {
    color = rating.notReadyColor;
  }

  return (
    <div style={{ margin: '5px' }}>
      <ProgressBar
        minimum={30}
        border
        color={color}
        round
        value={value * 100}
      >{`${Math.trunc(value * 100)}%`}</ProgressBar>
    </div>
  );
};

export default RatingCellRenderer;
