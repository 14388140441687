import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  useMutation as useMutationApollo,
} from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';

type ExtendedOptions = {
  transform?: any;
  selector?: string;
};

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext> & ExtendedOptions
): MutationTuple<TData, TVariables, TContext, TCache> {
  let { transform, selector, ...options2 } = options || {};

  const trimData = (data: TData | undefined | null) => {
    let result = data;
    if (selector && result) {
      result = get(result, selector);
    }
    if (result && typeof transform === 'function') {
      result = transform(result);
    }
    return result;
  };

  let onCompleted: ((data: TData) => void) | undefined;
  if (options2.onCompleted) {
    onCompleted = (data) => {
      if (options2.onCompleted) {
        options2.onCompleted(trimData(data) as TData);
      }
    };
  }

  let [originalMutation, params] = useMutationApollo<
    TData,
    TVariables,
    TContext,
    TCache
  >(query, {
    ...options2,
    onCompleted,
  });

  const mutationMethod = async (
    args:
      | MutationFunctionOptions<TData, TVariables, TContext, TCache>
      | undefined
  ) => {
    const result = await originalMutation(args);
    return {
      ...result,
      data: trimData(result?.data) as TData,
    };
  };

  return [mutationMethod, params];
}
