import { Logo } from '@hogwarts/ui-components-core';
import React, { useContext } from 'react';
import SecureImage from '../../../components/SecureImage';
import { FederationContext, OrganisationContext } from '../../../context';
import styles from './styles.module.css';

const LogoContainer = () => {
  const federation = useContext(FederationContext);
  const organisation = useContext(OrganisationContext);

  const federationBrandingLogoUrl = federation?.branding?.logoUrl;
  const organisationBrandingLogoUrl = organisation?.branding?.logoUrl;

  return (
    <Logo
      className={styles.logoContainer}
      LogoOverride={SecureImage}
      logoOverrideSrc={organisationBrandingLogoUrl || federationBrandingLogoUrl}
    />
  );
};

export default LogoContainer;
