import * as fragments from '../fragments';

import { gql } from '@apollo/client';
import { useQuery } from '../hooks';

export const GET_USER_FEDERATIONS = gql`
  query getUserFederations {
    federations {
      id
      key
      name
      enabled
      domain
      branding
      attributes
    }
  }
`;

export const GET_FEDERATIONS = gql`
  ${fragments.DATA_PROVIDER}
  query getFederation($federationKey: String) {
    federations(key: $federationKey) {
      id
      key
      name
      enabled
      domain
      branding
      attributes
      provider {
        ...ProviderParts
      }
      features {
        id
        cacheKey
        enabled
      }
      organisations {
        id
        key
        name
        enabled
        location
        attributes
      }
      organisationGroups {
        id
        name
        enabled
        includeAll
        # this list is big
        organisations {
          id
          key
          name
        }
      }
      userGroups {
        id
        name
        enabled
        includeAll
        # this list is big
        users {
          id
          name
          email
          enabled
        }
      }
      roles {
        id
        name
        permissions {
          key
          description
        }
        system
        resourceType
        featureKey
        category
        federation {
          id
          key
          name
          branding
        }
      }
    }
  }
`;

export const GET_FEDERATION_ORGANISATIONS = gql`
  query getFederationOrganisations($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      federation {
        id
        organisations {
          id
          key
          name
          attributes
        }
      }
    }
  }
`;

export const GET_FEDERATION_USER_GROUP_PERMISSIONS = gql`
  query getFederationUserGroupPermissions($federationKey: String) {
    federations(key: $federationKey) {
      id
      key
      groupPermissions {
        id
        userGroup {
          id
          name
          enabled
          includeAll
        }
        organisationGroup {
          id
          name
          enabled
          includeAll
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_FEDERATION_USERS = gql`
  query getFederationUsers($federationKey: String) {
    federations(key: $federationKey) {
      id
      key
      name
      enabled
      # this list is going to get big. will need pagination asap
      users {
        id
        cacheKey
        name
        email
        enabled
        mfaEnabled
        lastAuth
        lastEmail {
          status
          statusDate
          statusReason
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

export interface GET_FEDERATION_USERS_LIGHTResponse {
  id: string;
  cacheKey: string;
  name: string;
  email: string;
  lastEmail?: {
    status: 'delivered' | 'spam' | 'failed';
  };
}
export const GET_FEDERATION_USERS_LIGHT = gql`
  query getFederationUsers($federationKey: String) {
    federations(key: $federationKey) {
      id
      key
      name
      enabled
      # this list is going to get big. will need pagination asap
      users {
        id
        cacheKey
        name
        email
        lastEmail {
          status
          # statusDate
          # statusReason
        }
      }
    }
  }
`;
export const GET_FEDERATION_USERS_LIGHT_Query = (federationKey: string) =>
  useQuery<GET_FEDERATION_USERS_LIGHTResponse[]>(GET_FEDERATION_USERS_LIGHT, {
    selector: 'federations[0].users',
    variables: {
      federationKey,
    },
  });

export const GET_FEDERATION_ROLES = gql`
  query getFederationRoles($federationKey: String!) {
    federations(key: $federationKey) {
      id
      key
      roles {
        id
        name
        permissions {
          key
          description
        }
        featureKey
        category
        selectable
        resourceType
        federation {
          id
          key
          name
          branding
        }
      }
    }
  }
`;

export const GET_FEDERATION_PROVIDER = gql`
  ${fragments.DATA_PROVIDER}
  query getFederationProvider($federationKey: String) {
    federations(key: $federationKey) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;
