import { isObjectLike, veryCleanObject } from '@hogwarts/utils';
import { Organisation, Profile } from '../../../types';

import { IndividualProfileScheme } from '@hogwarts/utils-schemes';
import { sortBy } from 'lodash';
import { getScores } from './score';

const attributeKeyLookup: Record<string, string | boolean> = {
  sia: 'Sign In App',
  wonde: 'Wonde',
  talend: 'Talend',
  groupcall: 'Groupcall',
  accesspeople: 'Access People',
  itrent: 'iTrent',
  bulkImport: false,
} as const;

interface OverviewProps {
  profile: Profile;
  scheme: IndividualProfileScheme;
  organisation: Organisation;
  profileRating: any;
  transferRequest: any;
  showIdentifiers: boolean;
}
export const getOverview = ({
  profile,
  scheme,
  organisation,
  profileRating,
  transferRequest,
  showIdentifiers,
}: OverviewProps) => {
  const profileType = scheme.getProfileType(profile.typeKey);

  const fields = sortBy(
    scheme.fields.filter((f) => f.features?.profileOverview?.enabled),
    (f) => f.features.profileOverview.order
  ).map((f) => ({
    key: f.key,
    name: f.label,
    // @ts-ignore - unsure why ProfileField isn't being used
    value: f.value,
  }));

  if (showIdentifiers) {
    for (const key of Object.keys(profile.attributes)) {
      if (
        isObjectLike(profile.attributes[key]) &&
        Object.keys(veryCleanObject(profile.attributes[key])).length > 0
      ) {
        if (!attributeKeyLookup[key]) {
          continue;
        }

        let value: any =
          profile.attributes[key]?.id || profile.attributes[key]?.key;

        if (typeof value === 'undefined' || value === null) {
          value = '(Empty)';
        } else if (typeof value === 'object') {
          value = '(Object)';
        }

        fields.push({
          key: `__attribute_${key}__`,
          name: `${attributeKeyLookup[key]}` || key,
          value,
        });
      }
    }
  }

  const tags = sortBy(
    profile.tags
      ? profile.tags.map((tag) => {
          const tagDefinition = scheme.getTag(tag);
          if (tagDefinition) {
            return {
              key: tagDefinition.key,
              label: tagDefinition.label,
              color: tagDefinition.color,
              order: tagDefinition.order,
            };
          }
          return {
            key: tag,
            label: `Unknown tag: ${tag}`,
            color: 'grey',
            order: 9999,
          };
        })
      : [],
    (t) => t.order
  );

  return {
    name: profile.data.name,
    image: profile.data.picture,
    imageMeta: profile.meta.picture,
    profileType: profileType?.label,
    profileTypeAvatar: profileType?.avatar,
    availableProfileTypes: organisation.scheme.profileTypes.map((pt) => {
      const parentItem = pt.parent ? scheme.getProfileType(pt.parent) : null;
      return {
        key: pt.key,
        name: pt.label,
        parentName: parentItem?.label,
        avatar: pt.avatar,
        description: pt.description,
      };
    }),
    organisationName: organisation.name,
    fields,
    tags,
    scores: getScores(profileRating, scheme),
    transferRequest,
  };
};
