import { gql } from '@apollo/client';

export const BEGIN_MFA_REGISTRATION = gql`
  mutation beginMfaRegistration {
    beginMfaRegistration
  }
`;

export const COMPLETE_MFA_REGISTRATION = gql`
  mutation completeMfaRegistration($authenticatorCode: String!) {
    completeMfaRegistration(authenticatorCode: $authenticatorCode)
  }
`;

export const REMOVE_MFA_REGISTRATION = gql`
  mutation removeMfaRegistration($authenticatorCode: String!) {
    removeMfaRegistration(authenticatorCode: $authenticatorCode)
  }
`;

export const VERIFY_MFA_TOKEN = gql`
  mutation verifyMfaToken($authenticatorCode: String!) {
    verifyMfaToken(authenticatorCode: $authenticatorCode)
  }
`;

export const RESET_USER_MFA = gql`
  mutation resetUserMfa(
    $userId: String!
    $ownerType: String!
    $ownerId: String!
  ) {
    resetUserMfa(userId: $userId, ownerType: $ownerType, ownerId: $ownerId)
  }
`;
