import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { DatePicker } from '../../DatePicker';
import { getErrorIntentClass } from './utils';

const DateComponent = ({
  formField,
  readOnly,
  fieldMeta,
  large,
  placeholder,
  fieldHelpers,
}) => {
  return (
    <DatePicker
      inputProps={{
        className: cn(
          large && 'bp3-large',
          getErrorIntentClass(fieldMeta),
        ),
        onBlur: () => {
          fieldHelpers.setTouched(true, false);
        },
      }}
      large
      fill
      shortcuts
      highlightCurrentDay
      showActionsBar
      disabled={readOnly}
      onChange={async (date) => {
        let text = null;
        if (date) {
          text = DateTime.fromJSDate(date)
            .setZone('utc', { keepLocalTime: true })
            .startOf('day')
            .toJSDate()
            .toISOString()
            .substring(0, 10);
        }
        await fieldHelpers.setTouched(true, false);
        await fieldHelpers.setValue(text, true);
      }}
      placeholder={placeholder}
      currentDate={formField.value}
    />
  );
};

export default {
  name: 'Date Picker',
  label: true,
  options: [],
  Component: DateComponent,
};
