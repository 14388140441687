import { ProviderMeta } from '../types';
import accesspeople from './accesspeople';
import groupcall from './groupcall';
import itrent from './itrent';
import cornerstone from './talend';
import wonde from './wonde';

export default [
  wonde,
  groupcall,
  accesspeople,
  itrent,
  cornerstone,
] as ProviderMeta[];
