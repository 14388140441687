// @ts-nocheck TODO

import { Button } from '@hogwarts/ui-components-core';
import React from 'react';
import SaveButton from './SaveButton';

interface ButtonsProps {
  canCancel?: boolean;
  onCancel?: () => void;

  canClose?: boolean;
  onClose?: () => void;

  allowSave?: boolean;
  canSave?: boolean;
  onSave?: () => void;
  isSaving?: boolean;

  allowDelete?: boolean;
  onDelete?: () => void;
  deleteText?: string;

  saveText?: string;
  savingText?: string;
  savedText?: string;
}
const Buttons = ({
  canCancel,
  onCancel,

  canClose,
  onClose,

  allowSave,
  canSave,
  onSave,
  isSaving,

  allowDelete,
  onDelete,
  deleteText,

  saveText,
  savingText,
  savedText,
}: ButtonsProps) => {
  return (
    <div className="d-flex flex-row">
      <SaveButton
        allowed={allowSave}
        canSave={canSave}
        isSaving={isSaving}
        onClick={onSave}
        savingText={savingText}
        savedText={savedText}
        saveText={saveText}
      />

      {canCancel && !isSaving && (
        <Button
          id="cancel"
          icon="undo"
          intent="danger"
          large
          className="ml-1"
          onClick={onCancel}
          text="Cancel"
        />
      )}

      {canClose && !isSaving && (
        <Button
          id="close"
          large
          icon="times-circle"
          className="ml-1"
          onClick={onClose}
          text="Close"
        />
      )}
      <div className="flex-grow-1" />
      {onDelete && !isSaving && (
        <div>
          <Button
            allowed={allowDelete}
            text={deleteText ? deleteText : 'Delete'}
            large
            intent="danger"
            icon="trash"
            confirm
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
};

export default Buttons;
