import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
// @ts-ignore
import { sortableHandle } from 'react-sortable-hoc';
import { DeletePopover } from '@hogwarts/ui-components-core';

import './styles.css';
import styles from './styles.module.css';

const DraggableHeader = sortableHandle(({ title }: { title: string }) => (
  <div className={styles.dragContainer}>
    {title && <p className={styles.headerTitle}>{title}</p>}
  </div>
));

interface Props {
  title: string;
  loading?: boolean;
  error?: boolean;
  unavailable?: boolean;
  message?: string;
  children: React.ReactNode;
  onSettingsClick?: () => any;
  onRemoveClick?: () => any;
  disableScroll?: boolean;
}

const WidgetWrapper = ({
  title,
  loading,
  error,
  unavailable,
  message = '',
  children: widget,
  onSettingsClick,
  onRemoveClick,
  disableScroll,
}: Props) => {
  const { t } = useTranslation();

  return (
    <li className={styles.widgetListContainer}>
      <div className={cn('widget-container', styles.widgetWrapperContainer)}>
        <div className={cn('widget-header', styles.widgetHeader)}>
          <DraggableHeader title={title} />
          <div>
            {onSettingsClick && (
              <Button
                small
                minimal
                icon={<FontAwesomeIcon icon={['far', 'cog']} />}
                onClick={onSettingsClick}
              />
            )}
            {onRemoveClick && (
              <DeletePopover
                buttonComponent={
                  <Button
                    small
                    minimal
                    icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
                  />
                }
                message="Are you sure you want to remove this widget?"
                onRemove={onRemoveClick}
              />
            )}
          </div>
        </div>
        {loading && <NonIdealState icon={<Spinner intent="primary" />} />}
        <div
          className={cn(
            styles.widgetContent,
            !disableScroll && styles.widgetContentScroll
          )}
        >
          {error && (
            <NonIdealState
              icon="error"
              title={t('Error Loading Widget')}
              description={t<string>(message)}
            />
          )}
          {!loading && !error && unavailable && (
            <NonIdealState
              icon="warning-sign"
              title={t('Widget Unavailable')}
              description={t<string>(message)}
            />
          )}
          {!loading && !error && !unavailable && widget}
        </div>
      </div>
    </li>
  );
};

export default WidgetWrapper;
