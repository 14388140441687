import {
  DISCARD_SCHEME_DRAFT,
  PUBLISH_SCHEME_DRAFT,
  UPDATE_SCHEME_DRAFT,
} from '@/mutations';
import { GET_ORGANISATION_DETAIL, GET_SCHEME_TREE_AND_DRAFT } from '@/queries';
// import { useDebugReducer } from './useDebugReducer';
import { OrganisationContext, UserContext } from '@/context';
import { getUniqueKey, schemeHelperFactory } from '@hogwarts/scheme-profiles';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { initialState, reducer } from './reducer';

import { useMutation } from '@/hooks';
import { useQuery } from '@apollo/client';
import { useDebounce } from '@hogwarts/ui-components-core';

const SAVE_DEBOUNCE_TIME = 950;

const isEmptyScheme = (data) => {
  // eslint-disable-next-line no-unused-vars
  const { version, ...items } = data || {};

  let count = 0;
  for (const itemKey of Object.keys(items || {})) {
    count += Object.keys(items[itemKey] || {}).length;
  }

  return count === 0;
};

const getActions = (dispatch, state) => {
  return {
    addField(section, dataType, inputType, inputMeta, label, appearAfterKey) {
      const key = getUniqueKey();
      dispatch({
        type: 'FIELD_ADD',
        key,
        section,
        dataType,
        inputType,
        inputMeta,
        label,
        appearAfterKey,
      });
      return key;
    },
    addArrayField(
      parentKey,
      dataType,
      inputType,
      inputMeta,
      label,
      appearAfterKey
    ) {
      const key = getUniqueKey();
      dispatch({
        type: 'ARRAYFIELD_ADD',
        key,
        parentKey,
        dataType,
        inputType,
        inputMeta,
        label,
        appearAfterKey,
      });
      return key;
    },

    addSection(label) {
      const order =
        Math.max(...state.scheme.sections.map((s) => s.order || 0)) + 10;
      const key = getUniqueKey();
      dispatch({
        type: 'SECTION_ADD',
        key,
        label,
        order,
      });
      return key;
    },

    sectionUpdated(updated) {
      if (Object.keys(updated).length === 0) return;
      dispatch({
        type: 'SECTION_UPDATED',
        updated,
      });
    },

    fieldUpdated(updated) {
      if (Object.keys(updated).length === 0) return;
      dispatch({
        type: 'FIELD_UPDATED',
        updated,
      });
    },

    sectionReorder(movedKey, bumpedKey) {
      dispatch({
        type: 'SECTION_REORDER',
        movedKey,
        bumpedKey,
      });
    },

    profileTypeReorder(movedKey, bumpedKey) {
      dispatch({
        type: 'PROFILE_TYPE_REORDER',
        movedKey,
        bumpedKey,
      });
    },

    fieldReorder(section, movedKey, bumpedKey) {
      dispatch({
        type: 'FIELD_REORDER',
        section,
        movedKey,
        bumpedKey,
      });
    },

    arrayFieldReorder(section, fieldKey, movedKey, bumpedKey) {
      dispatch({
        type: 'ARRAYFIELD_REORDER',
        section,
        fieldKey,
        movedKey,
        bumpedKey,
      });
    },

    sectionDelete(section) {
      dispatch({
        type: 'SECTION_DELETE',
        key: section,
      });
    },

    sectionRestore(section) {
      dispatch({
        type: 'SECTION_RESTORE',
        key: section,
      });
    },

    fieldDelete(field) {
      dispatch({
        type: 'FIELD_DELETE',
        key: field,
      });
    },

    fieldRestore(field) {
      dispatch({
        type: 'FIELD_RESTORE',
        key: field,
      });
    },

    profileTypeUpdated(updated) {
      if (Object.keys(updated).length === 0) return;
      dispatch({ type: 'PROFILE_TYPE_UPDATED', updated });
    },

    addProfileType({ parent, label, description, avatar, enabled, color }) {
      if (!parent) return null;
      const key = getUniqueKey();
      dispatch({
        type: 'PROFILE_TYPE_ADD',
        key,
        parent,
        label,
        description,
        avatar,
        enabled,
        color,
      });
      return key;
    },

    profileTypeDelete(key) {
      dispatch({ type: 'PROFILE_TYPE_DELETE', key });
    },

    profileTypeRestore(key) {
      dispatch({ type: 'PROFILE_TYPE_RESTORE', key });
    },

    ratingSystemReorder(movedKey, bumpedKey) {
      dispatch({
        type: 'RATING_SYSTEM_REORDER',
        movedKey,
        bumpedKey,
      });
    },

    ratingSystemUpdated(updated) {
      if (Object.keys(updated).length === 0) return;
      dispatch({ type: 'RATING_SYSTEM_UPDATED', updated });
    },
  };
};

export const useSchemeLoader = (schemeId, profileTypeKey) => {
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);

  const [state, dispatch] = useReducer(
    reducer(user.id, profileTypeKey),
    initialState
  );

  const refreshSchemeTreeAndDraft = useCallback(
    ({ schemeTree, schemeDraft }) => {
      const scheme = schemeHelperFactory(schemeTree, {
        includeDeleted: true,
        calculateUsage: true,
      });

      const leafScheme = schemeTree[schemeTree.length - 1];
      const schemeName = leafScheme.name;
      const schemeOptions = leafScheme.options;

      dispatch({
        type: 'SCHEME_SETUP',
        scheme,
        schemeName,
        schemeOptions,
        hasChanges: !!schemeDraft?.id,
        changeSet: schemeDraft?.data || {},
      });
    },
    [dispatch]
  );

  const {
    loading,
    error,
    refetch: refreshScheme,
  } = useQuery(GET_SCHEME_TREE_AND_DRAFT, {
    fetchPolicy: 'network-only',
    variables: {
      id: schemeId,
    },
    onCompleted: refreshSchemeTreeAndDraft,
  });

  const [saveDraftScheme, { loading: saving }] = useMutation(
    UPDATE_SCHEME_DRAFT,
    {
      variables: {
        id: schemeId,
      },
    }
  );

  const [discardDraftScheme] = useMutation(DISCARD_SCHEME_DRAFT, {
    variables: {
      id: schemeId,
    },
  });

  const [publishDraftScheme] = useMutation(PUBLISH_SCHEME_DRAFT, {
    variables: {
      id: schemeId,
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation?.key,
        },
      },
    ],
  });

  const discardChanges = useCallback(async () => {
    await discardDraftScheme();
    const { data } = await refreshScheme();
    refreshSchemeTreeAndDraft(data);
    dispatch({
      type: 'DISCARD_CHANGESET',
    });
  }, [discardDraftScheme, dispatch, refreshScheme, refreshSchemeTreeAndDraft]);

  const publishChanges = useCallback(async () => {
    await publishDraftScheme();
    const { data } = await refreshScheme();
    refreshSchemeTreeAndDraft(data);
  }, [publishDraftScheme, refreshScheme, refreshSchemeTreeAndDraft]);

  // TODO: Put this as an Action for the Parent to call?
  useEffect(
    () => {
      if (loading || error) return;
      if (!state.initialised) return;

      dispatch({
        type: 'PROFILE_TYPE_CHANGED',
        profileTypeKey,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileTypeKey]
  );

  const onSaveHandler = useDebounce(async (state) => {
    const shouldSave = !isEmptyScheme(state.changeSet) || state.hasChanges;
    if (shouldSave) {
      console.log('Saving Draft:', { changeSet: state.changeSet });
      await saveDraftScheme({
        variables: {
          data: state.changeSet,
        },
      });
    }
  }, SAVE_DEBOUNCE_TIME);

  useEffect(
    () => {
      if (!state.initialised) return;
      if (!state.changeSet) return;
      onSaveHandler(state);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.changeSet]
  );

  if (loading) {
    return { loading: true, data: null, error: null };
  } else if (error) {
    return { loading: false, data: null, error };
  }

  return {
    ...getActions(dispatch, state),
    discardChanges,
    publishChanges,
    saving,
    loading,
    initialised: state.initialised,
    scheme: state.scheme,
    schemeOptions: state.schemeOptions,
    hasChanges: state.hasChanges,
    error,
  };
};
