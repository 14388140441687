import { Button, HTMLTable, NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { useDateFormatter } from '../../hooks';
import { ParamsButton } from './ParamsButton';
import styles from './styles.module.css';

interface FieldStateComponentProps {
  metaKey: string;
  data: {
    legacyValue: any;
    fieldKey: string;
    fieldLabel: string;
    enabled: boolean;
  };
}
interface MetaComponentProps {
  metaKey: string;
  data: any;
}

const metaComponents: Record<string, React.FC<MetaComponentProps>> = {
  'Field State': ({
    metaKey,
    data: { legacyValue, fieldKey, fieldLabel, enabled },
  }: FieldStateComponentProps) => {
    if (legacyValue) {
      return <p className="mb-0">{`${metaKey}: ${legacyValue}`}</p>;
    }

    return (
      <p className="mb-0">{`Field State: ${fieldLabel || fieldKey} - ${
        enabled ? 'enabled' : 'disabled'
      }`}</p>
    );
  },
  'New Profile Type': ({ metaKey, data: { legacyValue, key, label } }) => {
    return (
      <p className="mb-0">{`${metaKey}: ${legacyValue || label || key}`}</p>
    );
  },
  'Previous Profile Type': ({ metaKey, data: { legacyValue, key, label } }) => {
    return (
      <p className="mb-0">{`${metaKey}: ${legacyValue || label || key}`}</p>
    );
  },
  // No use to the user:
  'Report Id': () => null,
  Impersonation: () => null,
  Source: () => null,
};

interface AuditLogMetaProps {
  metaKey: string;
  metaData: any;
}
const AuditLogMeta = ({ metaKey, metaData }: AuditLogMetaProps) => {
  const { t } = useTranslation();

  const MetaComponent = metaComponents[metaKey];

  if (!MetaComponent) {
    return <p className="mb-0">{`${t(metaKey)}: ${metaData}`}</p>;
  }

  if (typeof metaData === 'string') {
    metaData = { legacyValue: metaData };
  }

  return <MetaComponent metaKey={metaKey} data={metaData || {}} />;
};

interface AuditLogRecord {
  metadata: [string, string][];
  time: string;
  user: {
    name: string;
  };
  params?: {
    profileId: string;
    impersonation: boolean;
    owner?: {
      id?: string;
      type?: string;
    };
  };
  event: string;
}

interface AuditLogProps {
  timezone: string;
  records: AuditLogRecord[];
  loadMore?: () => void;
  loadingMore: boolean;
  goToProfile: (profileId: string) => void;
}
const AuditLog = ({
  timezone,
  records,
  loadMore,
  loadingMore,
  goToProfile,
}: AuditLogProps) => {
  const { t } = useTranslation();

  const dateFormatter = useDateFormatter(timezone);

  if (records.length === 0) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={t('No Audit Logs Found')}
        description={t<string>(
          "You don't appear to have any audit logs to show, perform some actions on the system to generate logs."
        )}
      />
    );
  }
  return (
    <>
      <HTMLTable className={styles.auditLogs} striped>
        <thead>
          <tr>
            <th>{t('Time')}</th>
            <th>{t('User')}</th>
            <th>{t('Event')}</th>
            <th>{t('Properties')}</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record, idx) => (
            <tr key={idx}>
              <td>{dateFormatter.mediumWithTime(record.time)}</td>
              <td>
                {/* {record.user.name !== record.user.email && ( */}
                <p className="mb-0">{record.user.name}</p>
                {/* )} */}
                {/* <p className="mb-0">{record.user.email}</p> */}
                {record.params?.impersonation && (
                  <p className="mb-0">Impersonated by Support</p>
                )}
              </td>
              <td>{record.event}</td>
              <td>
                {record.metadata?.map((m, mIdx) => {
                  return (
                    <AuditLogMeta key={mIdx} metaKey={m[0]} metaData={m[1]} />
                  );
                })}
              </td>
              <td>
                <ParamsButton params={record.params} />
                {record.params?.profileId && (
                  <Button
                    className="mr-1"
                    icon="person"
                    onClick={() => goToProfile(record.params!.profileId)}
                  />
                )}
                {/* We could handle orgs here later */}
                {record.params?.owner &&
                  record.params?.owner.type === 'PROFILE' &&
                  record.params?.owner.id && (
                    <Button
                      className="mr-1"
                      icon="person"
                      onClick={() => goToProfile(record.params!.owner!.id!)}
                    />
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      {loadMore && (
        <div className="d-flex justify-content-center">
          <VisibilitySensor onChange={(isVisible) => isVisible && loadMore()}>
            {loadingMore ? (
              <Button className="mt-3" disabled>
                Loading more...
              </Button>
            ) : (
              <Button className="mt-3" onClick={loadMore}>
                Load More
              </Button>
            )}
          </VisibilitySensor>

          {/* <Button className="mt-3" loading={loadingMore} onClick={loadMore}>
            Load More
          </Button> */}
        </div>
      )}
    </>
  );
};

export default AuditLog;
