import { parseDate } from '@hogwarts/validation';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { UserContext } from '../context';

export const useDateFormatter = (timezone?: string) => {
  // const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);

  const format = (date: string, dateFormat: Intl.DateTimeFormatOptions) => {
    const parsed = parseDate(date, timezone);
    if (!parsed.isValid) return null;
    return parsed.toLocaleString(dateFormat);
  };

  return {
    short: (date: string): string | null => {
      const dateFormat = user?.preferences?.dateFormat || DateTime.DATE_SHORT;
      return format(date, dateFormat);
    },
    medium: (date: string): string | null => {
      const dateFormat = user?.preferences?.dateFormat || DateTime.DATE_MED;
      return format(date, dateFormat);
    },
    mediumWithTime: (date: string): string | null => {
      const dateFormat =
        user?.preferences?.dateFormat || DateTime.DATETIME_MED_WITH_SECONDS;
      return format(date, dateFormat);
    },
    long: (date: string): string | null => {
      const dateFormat = user?.preferences?.dateFormat || DateTime.DATE_FULL;
      return format(date, dateFormat);
    },
    huge: (date: string): string | null => {
      const dateFormat = user?.preferences?.dateFormat || DateTime.DATE_HUGE;
      return format(date, dateFormat);
    },
  };
};
