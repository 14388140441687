import { gql } from '@apollo/client';
import { AvailablePermission } from '../types';

export const GET_CURRENT_USER_WORKFLOWS = gql`
  query getCurrentUserWorkflows($organisationId: ID!, $workflowType: String) {
    user {
      id
      workflows(organisationId: $organisationId, type: $workflowType) {
        id
        cacheKey
        type
        triggerType
        triggerParams
        actionType
        actionParams
        meta
        enabled
      }
    }
  }
`;

export interface GET_CURRENT_USER_Response {
  id: string;
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  enabled: string;
  chatAuth: string;
  mfaEnabled: string;
  permissions: AvailablePermission[];
  support?: {
    open: boolean;
    supportId: string;
    impersonating: boolean;
  };
  preferences: Record<string, any>;
  trackerHubId: string;
  referralCode: string;
  createdAt: string;
  acceptedTerms: boolean;
}
export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    user {
      id
      name
      firstname
      lastname
      email
      enabled
      chatAuth
      mfaEnabled
      permissions {
        cacheKey
        permission
        resourceId
        resourceType
      }
      support {
        open
        supportId
        impersonating
      }
      lastAuth
      lastEmail {
        status
        statusDate
        statusReason
      }
      preferences
      trackerHubId
      referralCode
      createdAt
      acceptedTerms
    }
  }
`;

export const GET_USER_API_KEY_IDENT = gql`
  query getUserApiKeyIdent {
    user {
      id
      apiKeyIdent
    }
  }
`;

export const GET_USER_SUPPORT = gql`
  query getUserSupport {
    support {
      id
      user {
        id
        email
        name
        organisation {
          id
          name
          federation {
            id
            name
          }
        }
      }
    }
  }
`;
