import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OrganisationContext } from '@/context';
import { useQuery } from '@/hooks';
import { GET_ORGANISATION_SCHEMES } from '@/queries';
import SchemeSettings from '../../../components/Settings/Scheme';

const OrganisationSchemeSettingsContainer = () => {
  const organisation = useContext(OrganisationContext);
  const history = useHistory();
  const {
    loading,
    data: schemes,
    error,
  } = useQuery(GET_ORGANISATION_SCHEMES, {
    selector: 'organisations[0].schemes',
    variables: {
      organisationKey: organisation.key,
    },
  });

  return (
    <SchemeSettings
      loading={loading}
      error={error}
      schemes={schemes}
      onSchemeSelected={(schemeId) => {
        history.push(`/${organisation.key}/settings/template/${schemeId}`);
      }}
    />
  );
};

export default OrganisationSchemeSettingsContainer;
