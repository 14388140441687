import { Error, Loading } from '@hogwarts/ui-components-core';
import React, { useContext, useMemo, useState } from 'react';

import { ConditionalScheme } from '@/components/Conditionals';
import { OrganisationContext } from '../../context';
import PageHeader from '@/components/PageHeader';
import ProfileSectionsTab from './tabs/profileSections';
import ProfileTypesTab from './tabs/profileTypes';
import RatingSystemsTab from './tabs/ratingSystems';
import { TabBar } from '../../components';
import styles from './styles.module.css';
import { useFeature } from '@/hooks';
import { useSchemeLoader } from './useSchemeLoader';

const SchemeEditorContainer = ({ schemeId }) => {
  const organisation = useContext(OrganisationContext);

  const [selectedProfileTypeKey, setSelectedProfileTypeKey] = useState(null);
  const [selectedRatingSystemKey, setSelectedRatingSystemKey] = useState(null);

  const [visibilityOptions, setVisibilityOptions] = useState({
    showDeleted: false,
    showUnused: true,
    showParentOwned: true,
  });
  const [currentTab, setCurrentTab] = useState('profile-sections');
  // TODO: Feels like this should be in the profile sections container, but its
  // required for the hook. I wonder if we can introduce state to the hook so that
  // it can be used in the container directly.
  const [viewingProfileType, setViewingProfileType] = useState(null);

  const permissions = {
    canAddProfileType: useFeature('templates.customprofiletypes'),
  };

  const {
    scheme,
    schemeOptions,
    addField,
    addArrayField,
    sectionDelete,
    sectionRestore,
    fieldDelete,
    fieldRestore,
    fieldReorder,
    arrayFieldReorder,
    addSection,
    sectionReorder,
    sectionUpdated,
    fieldUpdated,
    profileTypeReorder,
    loading,
    saving,
    hasChanges,
    error,
    profileTypeUpdated,
    addProfileType,
    profileTypeDelete,
    profileTypeRestore,
    discardChanges,
    publishChanges,
    ratingSystemReorder,
    ratingSystemUpdated,
    initialised,
  } = useSchemeLoader(schemeId, viewingProfileType);

  const tabs = useMemo(
    () =>
      [
        { key: 'profile-sections', name: 'Section / Field Editor' },
        !viewingProfileType &&
          schemeOptions?.disableProfileTypesEdit !== true && {
            key: 'profile-types',
            name: 'Profile Types',
          },
        !viewingProfileType &&
          schemeOptions?.disableRatingSystemsEdit !== true && {
            key: 'rating-systems',
            name: 'Rating Systems',
          },
      ].filter(Boolean),
    [schemeOptions, viewingProfileType]
  );

  if (error) return <Error />;
  if (loading || !initialised) return <Loading showLogo />;

  return (
    <>
      <PageHeader
        noWrapper
        actions={[
          {
            text: 'View',
            icon: 'eye',
            actions: [
              {
                text: visibilityOptions.showDeleted
                  ? 'Hide Deleted Items'
                  : 'Show Deleted Items',
                intent: 'none',
                icon: visibilityOptions.showDeleted ? 'eye-slash' : 'eye',
                allowed: true,
                onClick: () =>
                  setVisibilityOptions({
                    ...visibilityOptions,
                    showDeleted: !visibilityOptions.showDeleted,
                  }),
              },
              {
                text: visibilityOptions.showUnused
                  ? 'Hide Unused Items'
                  : 'Show Unused Items',
                intent: 'none',
                icon: visibilityOptions.showUnused ? 'eye-slash' : 'eye',
                allowed: true,
                onClick: () =>
                  setVisibilityOptions({
                    ...visibilityOptions,
                    showUnused: !visibilityOptions.showUnused,
                  }),
              },
              {
                text: visibilityOptions.showParentOwned
                  ? 'Hide Built-in Items'
                  : 'Show Built-in Items',
                intent: 'none',
                icon: visibilityOptions.showParentOwned ? 'eye-slash' : 'eye',
                allowed: true,
                onClick: () =>
                  setVisibilityOptions({
                    ...visibilityOptions,
                    showParentOwned: !visibilityOptions.showParentOwned,
                  }),
              },
            ],
          },
          {
            disabled: !hasChanges || saving,
            text: 'Discard Changes',
            intent: 'none',
            icon: 'trash',
            allowed: true,
            onClick: () => {
              setSelectedProfileTypeKey(null);
              setSelectedRatingSystemKey(null);
              discardChanges();
            },
          },
          {
            disabled: !hasChanges || saving,
            text: 'Publish Changes',
            intent: 'primary',
            icon: 'check',
            allowed: true,
            onClick: () => {
              setSelectedProfileTypeKey(null);
              setSelectedRatingSystemKey(null);
              publishChanges();
            },
          },
        ]}
      >
        <TabBar
          className={styles.tabBar}
          tabs={tabs}
          activeTab={currentTab}
          onTabChange={(tab) => {
            // TODO: Might want to do some routing stuff here for deeplinking
            setCurrentTab(tab);
          }}
        />
      </PageHeader>
      {(viewingProfileType != null || currentTab === 'profile-sections') && (
        <ConditionalScheme.Provider value={scheme}>
          <ProfileSectionsTab
            currentScheme={scheme}
            visibilityOptions={visibilityOptions}
            ratingSystems={scheme.ratingSystems}
            profileTypes={scheme.profileTypes}
            addField={addField}
            addArrayField={addArrayField}
            sectionDelete={sectionDelete}
            sectionRestore={sectionRestore}
            fieldDelete={fieldDelete}
            fieldRestore={fieldRestore}
            fieldReorder={fieldReorder}
            arrayFieldReorder={arrayFieldReorder}
            addSection={addSection}
            sectionReorder={sectionReorder}
            sectionUpdated={sectionUpdated}
            fieldUpdated={fieldUpdated}
            selectedProfileType={viewingProfileType}
            onSelectedProfileTypeChange={setViewingProfileType}
            permissions={permissions}
          />
        </ConditionalScheme.Provider>
      )}
      {currentTab === 'profile-types' && (
        <ProfileTypesTab
          currentScheme={scheme}
          iconPack={organisation.attributes?.avatarIconPack}
          visibilityOptions={visibilityOptions}
          onProfileTypeReorder={profileTypeReorder}
          onProfileTypeUpdated={profileTypeUpdated}
          onAddProfileType={addProfileType}
          onProfileTypeDelete={profileTypeDelete}
          onProfileTypeRestore={profileTypeRestore}
          permissions={permissions}
          selectedProfileTypeKey={selectedProfileTypeKey}
          onProfileTypeSelected={setSelectedProfileTypeKey}
        />
      )}
      {currentTab === 'rating-systems' && (
        <RatingSystemsTab
          currentScheme={scheme}
          visibilityOptions={visibilityOptions}
          selectedRatingSystemKey={selectedRatingSystemKey}
          onRatingSystemReorder={ratingSystemReorder}
          onRatingSystemUpdated={ratingSystemUpdated}
          onRatingSystemSelected={setSelectedRatingSystemKey}
        />
      )}
    </>
  );
};

export default SchemeEditorContainer;
