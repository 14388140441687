import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '@/context';
import { useMutation, usePermission } from '@/hooks';
import { ADD_DASHBOARD } from '@/mutations';
import { GET_DASHBOARDS } from '@/queries';
import permissions from '@hogwarts/permissions';
import { useForms } from '@hogwarts/ui-components-forms';
import { useContext } from 'react';

const AddDashboardForm = ({ onCreate }) => {
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);
  const analytics = useContext(AnalyticsContext);
  const [addDashboard] = useMutation(ADD_DASHBOARD, {
    selector: 'addDashboard',
    refetchQueries: [
      {
        query: GET_DASHBOARDS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const canCreateOrganisationDashboards = usePermission(
    permissions.DASHBOARD_CREATE,
    organisation?.id
  );
  const canCreateFederationDashboards = usePermission(
    permissions.DASHBOARD_CREATE,
    federation?.id
  );
  const getOwnerOptions = () => {
    const options = [
      { id: 'USER', value: 'Only Me' },
      {
        id: 'ORGANISATION',
        value: organisation.name,
        disabled: !canCreateOrganisationDashboards,
      },
    ];
    if (federation) {
      options.push({
        id: 'FEDERATION',
        value: federation.name,
        disabled: !canCreateFederationDashboards,
      });
    }
    return options;
  };
  useForms({
    key: 'addDashboard',
    title: 'Add Dashboard',
    saveText: 'Create',
    savingText: 'Creating',
    savedText: 'Created',
    allowCancel: false,
    initialValues: {
      ownerType: 'USER',
    },
    fields: [
      {
        key: 'name',
        type: 'textbox',
        label: 'Name',
        validate: 'required',
        maxLength: 40,
      },
      {
        key: 'ownerType',
        type: 'singleselect',
        label: 'Shared with',
        values: getOwnerOptions(),
        validate: 'required',
        help: {
          type: 'info',
          description:
            'Dashboards can be visible to users or organisations. Sharing your dashboard will make it available for anyone else inside your organisation to view. They may need extra permissions to edit or delete them though.',
        },
      },
    ],
    successToastMessage: 'Dashboard Added',
    onSave: async (values) => {
      let ownerId;
      switch (values.ownerType) {
        case 'ORGANISATION': {
          ownerId = organisation.id;
          break;
        }
        case 'FEDERATION': {
          ownerId = federation.id;
          break;
        }
        default: {
          break;
        }
      }
      analytics.events.dashboard.dashboardAdded();
      const dashboard = await addDashboard({
        variables: {
          ...values,
          ownerId,
        },
      });
      await onCreate(dashboard.data);
    },
  });

  return null;
};

export default AddDashboardForm;
