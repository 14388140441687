import { Error, Loading } from '@hogwarts/ui-components-core';
import { orderBy } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserPreferences as UserPreferencesComponent } from '../../../components/Settings/UserPreferences';
import { AnalyticsContext, UserContext } from '../../../context';
import { useMutation, useQuery, useQueryResults } from '../../../hooks';
import { UPDATE_USER_PREFERENCES } from '../../../mutations';
import {
  GET_ALL_LANGUAGES,
  GET_CURRENT_USER,
  GET_LOCATIONS,
  GET_TIMEZONES,
  GET_USER_ORGANISATIONS,
} from '../../../queries';
import { AppToaster } from '../../../utils/toaster';

const UserPreferences = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const analytics = useContext(AnalyticsContext);

  const locationsQuery = useQuery(GET_LOCATIONS, {
    selector: 'locations',
    transform: (locations: { code: string; name: string }[]) => {
      return locations.map((location) => ({
        id: location.code,
        value: location.name,
      }));
    },
  });

  const timezonesQuery = useQuery(GET_TIMEZONES, {
    selector: 'timezones',
    transform: (
      timezones: { code: string; name: string; offset: number }[]
    ) => {
      return orderBy(
        timezones.map((timezone) => ({
          id: timezone.code,
          value: `${timezone.code} (UTC ${
            timezone.offset < 0 ? timezone.offset : `+${timezone.offset}`
          })`,
        })),
        ['offset', 'code']
      );
    },
  });

  const languagesQuery = useQuery(GET_ALL_LANGUAGES, {
    selector: 'languages',
    transform: (
      languages: { key: string; name: string; enabled: boolean }[]
    ) => {
      return languages
        .filter((language) => language.enabled)
        .map((language) => ({
          id: language.key,
          value: language.name,
        }));
    },
  });

  const organisationsQuery = useQuery(GET_USER_ORGANISATIONS, {
    selector: 'organisations',
    transform: (organisations: { key: string; name: string }[]) => {
      return orderBy(
        organisations.map((org) => ({
          id: org.key,
          value: org.name?.trim(),
        })),
        (o) => o.value.toLowerCase()
      );
    },
  });

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  });

  const { loading, error } = useQueryResults({
    languagesQuery,
    organisationsQuery,
    timezonesQuery,
    locationsQuery,
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <UserPreferencesComponent
      organisations={organisationsQuery.data}
      languages={languagesQuery.data}
      timezones={timezonesQuery.data}
      // locations={data.locationsQuery}
      firstname={user.firstname}
      lastname={user.lastname}
      preferences={user.preferences || {}}
      onSavePreferences={async ({ firstname, lastname, ...values }) => {
        analytics.events.users.userPreferencesSave();
        await updateUserPreferences({
          variables: {
            firstname,
            lastname,
            updatedPreferences: {
              ...values,
            },
          },
        });
        AppToaster.show({
          message: t(`Save successful`),
          intent: 'success',
          icon: 'tick',
        });
      }}
    />
  );
};

export default UserPreferences;
