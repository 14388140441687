import React, { useContext } from 'react';
import { OrganisationContext, AnalyticsContext } from '@/context';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import permissions from '@hogwarts/permissions';
import ProfileTransfersList from '../../../components/ProfileTransfers/List';
import PageHeader from '../../../components/PageHeader';
import { Loading } from '@hogwarts/ui-components-core';
import { NonIdealState, Button } from '@blueprintjs/core';
import { usePermission, useMutation } from '../../../hooks';
import {
  CANCEL_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
  ACCEPT_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION,
} from '@/mutations';
import {
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
  GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
} from '@/queries';

const ProfileTransfers = () => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const organisation = useContext(OrganisationContext);
  const { loading, error, data } = useQuery(
    GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
    {
      variables: { targetOrganisationId: organisation.id },
    }
  );
  const canAcceptTransfers = usePermission(
    permissions.PROFILE_ACCEPT_TRANSFER,
    organisation.id
  );
  const [approveTransfer] = useMutation(
    ACCEPT_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION
  );
  const [rejectTransfer] = useMutation(
    CANCEL_REQUEST_CHANGE_PROFILE_ORGANISATION_MUTATION
  );
  return (
    <>
      <PageHeader header={t('Transfer Requests')} />
      {loading && <Loading showLogo />}
      {error && (
        <NonIdealState
          icon="error"
          title={t('Error Loading Transfer Requests')}
        />
      )}
      {data && data.profileOrganisationChangeRequests.length === 0 && (
        <NonIdealState
          icon="search"
          title={t('No Transfer Requests Pending')}
          action={
            <Button
              intent="primary"
              onClick={() => {
                history.push(`/${organisation.key}/profiles`);
              }}
            >
              {t('Back To Profiles')}
            </Button>
          }
        />
      )}
      {data && data.profileOrganisationChangeRequests.length > 0 && (
        <ProfileTransfersList
          destinationOrganisation={organisation.name}
          transfers={data.profileOrganisationChangeRequests}
          canAcceptTransfers={canAcceptTransfers}
          onApprove={(profileId) => {
            analytics.events.transfers.transferApproved();
            approveTransfer({
              variables: {
                profileId,
                organisationId: organisation.id,
              },
              refetchQueries: [
                {
                  query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
                  variables: { profileId },
                },
                {
                  query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
                  variables: { targetOrganisationId: organisation.id },
                },
              ],
            });
          }}
          onReject={(profileId) => {
            analytics.events.transfers.transferRejected();
            rejectTransfer({
              variables: {
                profileId,
              },
              refetchQueries: [
                {
                  query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILE_ALERT,
                  variables: { profileId },
                },
                {
                  query: GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
                  variables: { targetOrganisationId: organisation.id },
                },
              ],
            });
          }}
        />
      )}
    </>
  );
};

export default ProfileTransfers;
