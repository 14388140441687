import { Button } from '@blueprintjs/core';
import { Error } from '@hogwarts/ui-components-core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RenderDenied = () => {
  const history = useHistory();
  return (
    <Error
      icon="lock"
      title="Sorry! Access Denied"
      description="You do not have the required permissions to view this page"
      action={
        <Button onClick={() => history.push('/')} intent="primary">
          Dashboard
        </Button>
      }
    />
  );
};

export default RenderDenied;
