import { usePermission } from '@/hooks';
import permissions from '@hogwarts/permissions';
import React from 'react';
import ReactJson from 'react-json-view';

const Diagnostics = ({ item, permissions: additionalPermissions }) => {
  // return <ReactJson name={false} collapsed={1} src={item} />;
  const allowed = usePermission(
    [permissions.DIAGNOSTIC_VIEW, ...(additionalPermissions || [])].filter(
      Boolean
    )
  );
  return allowed && <ReactJson name={false} collapsed={1} src={item} />;
};

export default Diagnostics;
