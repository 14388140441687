import { Tag } from '@hogwarts/ui-components-core';
import React from 'react';

const TagCellRenderer = ({ value: tags, data }) => {
  if (!data) return null;
  if (!Array.isArray(tags)) return null;
  if (!data.scheme) return null;
  return tags
    .map((tag) => {
      if (typeof tag === 'string') {
        tag = data.scheme.getTag(tag);
      }
      if (!tag) return null;
      const label = tag?.label || tag.key;
      return <Tag key={tag.key} color={tag.color} label={label} />;
    })
    .filter(Boolean);
};

export default TagCellRenderer;
