import trackEvent from '../trackEvent';

export const userPreferencesSave = (traits = {}) => {
  trackEvent('User - Preferences Saved', traits, true);
};

export const impersonationOpen = (traits = {}) => {
  trackEvent('User - Impersonation Open', traits, true);
};

export const impersonationClose = (traits = {}) => {
  trackEvent('User - Impersonation Close (Button)', traits, true);
};

export const impersonationCloseIcon = (traits = {}) => {
  trackEvent('User - Impersonation Close (Icon)', traits, true);
};

export const deleteUserClicked = (traits = {}) => {
  trackEvent('User - Delete User Clicked', traits, true);
};

export const userDeleted = (traits = {}) => {
  trackEvent('User - User Deleted', traits, true);
};

export const resetUserMfaClicked = (traits = {}) => {
  trackEvent('User - Reset MFA Clicked', traits, true);
};

export const resetUserMfaCompleted = (traits = {}) => {
  trackEvent('User - Reset MFA Completed', traits, false);
};

export const resetUserMfaFailed = (traits = {}) => {
  trackEvent('User - Reset MFA Failed', traits, true);
};

export const disableImpersonationButton = (traits = {}) => {
  trackEvent('User - Disable Impersonation Button', traits, true);
};
