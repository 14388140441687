import { NonIdealState, Tag } from '@blueprintjs/core';
import { ListView } from '@hogwarts/ui-components-list';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

interface Check {
  key: string;
  heading?: string;
  label: string;
  expiringSoonCount: number;
  expiredCount: number;
}
interface ExpiredCheckProps {
  item: Check;
  last: boolean;
  onCheckSelected: (item: any) => void;
}
const ExpiredCheck = ({
  item: check,
  last,
  onCheckSelected,
}: ExpiredCheckProps) => {
  return (
    <div
      key={check.key}
      className={cn(
        styles.profileContainer,
        last && styles.profileContainerLast
      )}
      onClick={() => onCheckSelected(check)}
    >
      <div className={'flex-grow-1'}>
        {check.heading && (
          <p className={cn(styles.profileName, 'font-weight-bold')}>
            {check.heading}
          </p>
        )}
        <p className={cn(styles.profileName, 'ml-2')}>{check.label}</p>
      </div>

      <div className={'flex-column'}>
        {check.expiringSoonCount === 0 && check.expiredCount === 0 && (
          <div>
            <Tag
              fill
              className={'mb-1'}
              intent={'success'}
            >{`Nothing due`}</Tag>
          </div>
        )}

        {check.expiringSoonCount > 0 && (
          <div>
            <Tag
              fill
              className={'mb-1'}
              intent={'warning'}
            >{`${check.expiringSoonCount} expiring soon`}</Tag>
          </div>
        )}

        {check.expiredCount > 0 && (
          <div>
            <Tag
              fill
              className={'mb-1'}
              intent={'danger'}
            >{`${check.expiredCount} expired`}</Tag>
          </div>
        )}
      </div>
    </div>
  );
};

interface ExpiredDatesCheckListWidgetProps {
  checks: Check[];
  onCheckSelected: () => void;
}
const ExpiredDatesCheckListWidget = ({
  checks,
  onCheckSelected,
}: ExpiredDatesCheckListWidgetProps) => {
  const { t } = useTranslation();

  if (!checks || checks.length === 0) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={t('No Expiring Checks found')}
        description={t<string>(
          'To add an Expiring Check, please see our Help Guides'
        )}
      />
    );
  }
  return (
    <ListView
      className="w-100 h-100"
      componentProps={{
        onCheckSelected,
      }}
      rows={checks}
      component={ExpiredCheck}
      spreadRowItem={false}
    />
  );
};

export default ExpiredDatesCheckListWidget;
