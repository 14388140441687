import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '../../context';
import {
  CREATE_FEDERATION_USER_MUTATION,
  DELETE_FEDERATION_USER_MUTATION,
  RESET_USER_MFA,
  UPDATE_FEDERATION_USER_MUTATION,
} from '@/mutations';
import { GET_FEDERATION_ROLES, GET_FEDERATION_USERS } from '../../queries';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  isFeatureEnabled,
  useAlert,
  useFeature,
  useMutation,
  useQuery,
  useQueryResults,
} from '../../hooks';

import { AppToaster } from '@/utils/toaster';
import { GridView } from '../../components';
import { SettingsPanel } from '../../components/Settings/SettingsPanel';
import { useFormState } from '@hogwarts/ui-components-forms';
import { useTranslation } from 'react-i18next';

const UserManagement = () => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const federation = useContext(FederationContext);
  const organisation = useContext(OrganisationContext);
  const isMfaResetEnabled = useFeature('security.mfareset');

  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'Are you sure you want to delete this user? This operation cannot be undone.'
        )}
      </p>
    ),
    onConfirm: ({ userId }) => {
      deleteUser({ variables: { id: userId } });
      analytics.events.users.deleteUserClicked({ userId });
    },
  });

  const [ConfirmResetMfaAlert, showConfirmResetMfaAlert] = useAlert({
    icon: 'unlock',
    intent: 'warning',
    confirmButtonText: t('Reset MFA'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'Are you sure you want to reset MFA for this user? This operation cannot be undone.'
        )}
      </p>
    ),
    onConfirm: ({ userId, ownerType, ownerId }) => {
      resetUserMfa({ variables: { userId, ownerType, ownerId } });
      analytics.events.users.resetUserMfaClicked({
        userId,
        ownerType,
        ownerId,
      });
    },
  });

  const rolesQuery = useQuery(GET_FEDERATION_ROLES, {
    selector: 'federations[0].roles',
    variables: {
      federationKey: federation.key,
    },
    transform: (roles) => {
      return roles.filter((r) => {
        if (r.resourceType !== 'FEDERATION') return false;
        if (r.featureKey && !isFeatureEnabled(r.featureKey, organisation)) {
          return false;
        }
        return true;
      });
    },
  });

  const usersQuery = useQuery(GET_FEDERATION_USERS, {
    selector: 'federations[0].users',
    variables: {
      federationKey: federation.key,
    },
  });

  const { loading, error } = useQueryResults({
    rolesQuery,
    usersQuery,
  });

  const [addUser] = useMutation(CREATE_FEDERATION_USER_MUTATION, {
    variables: {
      federationId: federation.id,
    },
    refetchQueries: [
      {
        query: GET_FEDERATION_USERS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const [editUser] = useMutation(UPDATE_FEDERATION_USER_MUTATION, {
    variables: {
      federationId: federation.id,
    },
  });

  const [deleteUser] = useMutation(DELETE_FEDERATION_USER_MUTATION, {
    variables: {
      federationId: federation.id,
    },
    refetchQueries: [
      {
        query: GET_FEDERATION_USERS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
  });

  const [resetUserMfa] = useMutation(RESET_USER_MFA, {
    selector: 'resetUserMfa',
    refetchQueries: [
      {
        query: GET_FEDERATION_USERS,
        variables: {
          federationKey: federation.key,
        },
      },
    ],
    onCompleted: (data) => {
      AppToaster.show({
        message: t('MFA has been reset for user successfully.'),
        intent: 'success',
      });
      analytics.events.users.resetUserMfaCompleted({
        userId: data.id,
      });
    },
    onError: (error) => {
      AppToaster.show({
        message: t(error.message),
        intent: 'danger',
      });
      analytics.events.users.resetUserMfaFailed({
        error: error.message,
      });
    },
  });

  const forms = useFormState();

  const roles = rolesQuery.data;
  const users = usersQuery.data;

  const rolesField = useMemo(
    () => ({
      key: 'roles',
      label: 'Roles',
      type: 'roles',
      roles,
      readValue: (values) => {
        if (!values) return {};
        return values.reduce((prev, cur) => ({ ...prev, [cur.id]: true }), {});
      },
      writeValue: (values) => {
        return roles
          .filter((role) => values[role.id] === true)
          .map((role) => role.id);
      },
    }),
    [roles]
  );

  useEffect(() => {
    const fields = [
      {
        key: 'firstname',
        label: 'First Name',
        validate: 'required',
        maxLength: 50,
      },
      {
        key: 'lastname',
        label: 'Last Name',
        validate: 'required',
        maxLength: 50,
      },
      {
        key: 'email',
        label: 'Email',
        validate: 'email',
        maxLength: 255,
      },
      rolesField,
    ];
    forms.register('adduser', {
      title: 'Add User',
      fields,
      onSave: async (values) => {
        await addUser({
          variables: {
            ...values,
          },
        });
      },
    });
    forms.register('edituser', {
      title: 'Edit User',
      fields: [
        {
          key: 'id',
          type: 'hidden',
        },
        {
          key: 'name',
          label: 'Name',
          readOnly: true,
        },
        {
          key: 'email',
          label: 'Email',
          readOnly: true,
        },
        rolesField,
      ],
      onSave: async (values) => {
        await editUser({
          variables: {
            ...values,
          },
        });
      },
    });
    return () => {
      forms.unregister('adduser');
      forms.unregister('edituser');
    };
  }, [addUser, editUser, forms, roles, rolesField]);

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        valueGetter: (params) => {
          return params.data.name;
        },
        label: 'Name',
        width: 200,
        sort: 'asc',
      },
      !federation?.domain && {
        key: 'mfaEnabled',
        label: 'MFA',
        valueGetter: ({ data: user }) => {
          if (user.mfaEnabled) {
            return {
              icon: 'lock',
              color: 'green',
            };
          }
          return {
            icon: 'lock-open',
            color: 'red',
          };
        },
        width: 100,
        cellRenderer: 'fontAwesomeRenderer',
        sortable: false,
      },
      {
        key: 'email',
        valueGetter: (params) => params.data.email,
        label: 'Email',
        width: 200,
      },
      {
        key: 'roles',
        label: 'Roles',
        valueGetter: ({ data: user }) => {
          return user.roles.map((r) => t(r.name)).join(' | ');
        },
        width: 350,
      },
      // {
      //   key: 'lastAuth',
      //   valueGetter: (params) => params.data.lastAuth,
      //   label: 'Last Seen',
      //   width: 150,
      // },
      // {
      //   key: 'lastEmail',
      //   valueGetter: (params) => params.data.lastEmail?.status,
      //   label: 'Email Status',
      //   width: 150,
      // },
    ].filter(Boolean);
  }, [federation, t]);

  return (
    <>
      <ConfirmDeleteAlert />
      <ConfirmResetMfaAlert />
      <SettingsPanel
        loading={loading}
        error={error}
        title="Federation Users"
        fixedHeight="750px"
        actions={[
          {
            text: 'Add User',
            icon: 'user-plus',
            onClick: () => {
              forms.showForm('adduser');
            },
          },
        ]}
      >
        <GridView
          sizeColumnsToFit
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
          }}
          showSideBar={false}
          contextMenuItems={(nodes) => {
            if (nodes.length !== 1) return null;

            let menuItems = [
              {
                name: 'Delete User',
                action: (users) => {
                  showConfirmDeleteAlert({ userId: users[0].data.id });
                },
              },
            ];

            if (isMfaResetEnabled) {
              menuItems.push({
                name: 'Reset MFA',
                action: (users) => {
                  showConfirmResetMfaAlert({
                    userId: users[0].data.id,
                    ownerType: 'FEDERATION',
                    ownerId: federation.id,
                  });
                },
              });
            }

            return menuItems;
          }}
          rows={users}
          columns={columns}
          onRowDoubleClicked={(row) => {
            const { id, name, email, roles } = row.data;
            forms.showForm('edituser', {
              initialValues: {
                id,
                name,
                email,
                roles,
              },
            });
          }}
        />
      </SettingsPanel>
    </>
  );
};

export default UserManagement;
