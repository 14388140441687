import * as Sentry from '@sentry/react';

import { environmentTypes, getEnvironment } from '@/services';
import { isCentralTeam, isSupportAdminDomain } from '@hogwarts/utils';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import { TrackMethod, initialise } from './trackEvent';

import log from '@scrtracker/logging';
import { useLocation } from 'react-router-dom';
import events from './events';

let isProduction = getEnvironment().environment === environmentTypes.PRODUCTION;
if (!isProduction) {
  log.info('Disabling Analytics for Non-Production Environment');
}

declare global {
  interface Window {
    analytics: {
      page: (pageName: string, traits: any) => void;
      identify: (userId: string, traits: any, other: any) => void;
      reset: () => void;
      group: (groupId: string, traits: any) => void;
      track: TrackMethod;
    };
    Intercom: (method: string, meta?: any) => void;
    _chatlio: {
      identify: (userId: string, traits: any) => void;
    };
  }
}

const segmentContext = {
  page:
    isProduction && window.analytics
      ? (pageName: string, traits: any) => {
          window.analytics.page(pageName, traits);
        }
      : (pageName: string, traits: any) => {
          log.debug('Analytics: Page', { pageName, traits });
        },
  identify:
    isProduction && window.analytics
      ? (userId: string, traits: any, integrations: any) => {
          window.analytics.identify(userId, traits, { integrations });
          if (window.Intercom) {
            window.Intercom('update', { user_id: userId, ...traits });
          }
        }
      : (userId: string, traits: any) => {
          log.debug('Analytics: Identify', { userId, traits });
        },
  group:
    isProduction && window.analytics
      ? (groupId: string, traits: any) => {
          window.analytics.group(groupId, traits);
        }
      : (groupId: string, traits: any) => {
          log.debug('Analytics: Group', { groupId, traits });
        },
  reset:
    isProduction && window.analytics
      ? () => {
          window.analytics.reset();
          if (window.Intercom) {
            window.Intercom('shutdown');
          }
        }
      : () => {
          log.debug('Analytics: Reset');
        },
};

interface LocationProperties {
  path: string;
}

const trackLocation = (locationProperties: LocationProperties) => {
  segmentContext.page(locationProperties.path, locationProperties);
};
const trackUser = (
  userProperties: any,
  organisationProperties: any,
  federationProperties: any
) => {
  let chatlioIdent: any = {};
  if (userProperties.id) {
    const { id: userId, auth, ...userTraits } = userProperties;
    segmentContext.identify(userId, userTraits, {
      Intercom: {
        user_hash: auth,
      },
    });

    Sentry.setUser({
      id: userProperties.id,
      name: userProperties.name,
      email: userProperties.email,
    });

    chatlioIdent.id = userProperties.id;
    chatlioIdent.name = userProperties.name;
    chatlioIdent.email = userProperties.email;
  }
  let vitallyFederationLink: any = {};
  if (federationProperties.id) {
    segmentContext.group(federationProperties.id, {
      ...federationProperties,
      level: 'Organization', // Vitally Level
    });

    // This is for vitally "organizations", which links our orgs and feds.
    vitallyFederationLink.organization = federationProperties.id;

    Sentry.setTag('federation.id', federationProperties.id);
    Sentry.setTag('federation.key', federationProperties.key);
    Sentry.setTag('federation.name', federationProperties.name);

    chatlioIdent.federationId = federationProperties.id;
    chatlioIdent.federationKey = federationProperties.key;
    chatlioIdent.federationName = federationProperties.name;
  }
  if (organisationProperties.id) {
    segmentContext.group(organisationProperties.id, {
      ...organisationProperties,
      ...vitallyFederationLink,
      level: 'Account', // Vitally Level
    });

    Sentry.setTag('organisation.id', organisationProperties.id);
    Sentry.setTag('organisation.key', organisationProperties.key);
    Sentry.setTag('organisation.name', organisationProperties.name);

    chatlioIdent.organisationId = organisationProperties.id;
    chatlioIdent.organisationKey = organisationProperties.key;
    chatlioIdent.organisationName = organisationProperties.name;
  }

  if (window._chatlio) {
    if (chatlioIdent) {
      const { id, ...traits } = chatlioIdent;
      window._chatlio.identify(id, traits);
    }
  }
};

interface AnalyticsProps {
  children: React.ReactNode;
}
export const Analytics = ({ children }: AnalyticsProps) => {
  const location = useLocation();
  const user = useContext(UserContext);
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  useEffect(() => {
    // Clear out everything incase anything persists from previous session
    segmentContext.reset();
  }, []);

  const impersonating = user?.support?.impersonating;

  if (isProduction) {
    initialise((eventName, traits, config) => {
      if (impersonating) return;
      window.analytics.track(eventName, traits, config);
    });
  } else {
    initialise((eventName, traits, config) => {
      log.debug('Analytics: Track', { eventName, traits, config });
    });
  }

  const locationProperties = useMemo(
    () => ({
      path: location.pathname,
    }),
    [location.pathname]
  );

  const userProperties = useMemo(
    () => ({
      id: user ? user.trackerHubId : null,
      auth: user ? user.chatAuth : null,
      name: user ? user.name : null,
      email: user ? user.email : null,
      scrEmployeeUser: user ? isSupportAdminDomain(user.email) : null,
      created_at: user ? user.createdAt : null,
    }),
    [user]
  );

  const organisationProperties = useMemo(
    () => ({
      id: organisation ? organisation.id : null,
      key: organisation ? organisation.key : null,
      name: organisation ? organisation.name : null,
      location: organisation ? organisation.location : null,
      timezone: organisation ? organisation.timezone : null,
      demo: organisation ? !!organisation.attributes?.demo_organisation : false,
      trial: organisation ? !!organisation.attributes?.trial : false,
      centralTeam: isCentralTeam(organisation),
      type: 'organisation',
    }),
    [organisation]
  );

  const federationProperties = useMemo(
    () => ({
      id: federation ? federation.id : null,
      key: federation ? federation.key : null,
      name: federation ? federation.name : null,
      demo: federation ? !!federation.attributes?.demo_federation : false,
      trial: federation ? !!federation.attributes?.trial : false,
      type: 'federation',
    }),
    [federation]
  );

  useEffect(() => {
    if (impersonating) return;
    trackLocation(locationProperties);
    trackUser(userProperties, organisationProperties, federationProperties);
  }, [
    impersonating,
    federationProperties,
    locationProperties,
    organisationProperties,
    userProperties,
  ]);
  useEffect(() => {
    if (impersonating) return;
    trackUser(userProperties, organisationProperties, federationProperties);
  }, [
    impersonating,
    federationProperties,
    organisationProperties,
    userProperties,
  ]);

  return (
    <AnalyticsContext.Provider value={{ events, reset: segmentContext.reset }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default Analytics;
