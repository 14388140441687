import React from 'react';

const ColorCellRenderer = ({ value }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: value,
        borderRadius: '6px',
        border: '1px solid #ccc',
      }}
    />
  );
};

export default ColorCellRenderer;
