import { useLazyQuery } from '@apollo/client';
import { MultiSelect, useDebounce } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MultiSuggestComponent = ({
  formField,
  searchQuery,
  valueField,
  textField,
  organisation,
  fieldHelpers,
}) => {
  const { t } = useTranslation();
  let value = formField.value;

  const [performSearch, { data }] = useLazyQuery(searchQuery, {
    fetchPolicy: 'no-cache',
  });

  const SEARCH_DEBOUNCE_TIME = 450;

  const handleSearch = useDebounce((searchValue) => {
    performSearch({
      variables: {
        searchTerm: searchValue,
        organisationKey: organisation.key,
      },
    });
  }, SEARCH_DEBOUNCE_TIME);

  return (
    <MultiSelect
      items={data?.profileSearch ?? []}
      valueField={valueField}
      textField={textField}
      onQueryChange={handleSearch}
      onChange={(item) => {
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(item);
      }}
      placeholder={t('Perform a search...')}
      selectedItems={value}
      resetOnSelect
    />
  );
};

export default {
  name: 'Multi-Suggest',
  options: [],
  label: true,
  Component: MultiSuggestComponent,
};
