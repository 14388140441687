import { NonIdealState } from '@blueprintjs/core';
import { FetchMethod, InfiniteListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Profile,
  ProfileListItem,
  ProfileRatings,
} from '../../../../containers/profileListItem';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching Profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

interface ProfilesListWidgetProps {
  showAvatar: boolean;
  iconPack: string;
  fields: string[];
  fetchMore: FetchMethod;
  onProfileSelected: (profileId: string, profile: Profile) => void;
}
const ProfilesListWidget = ({
  showAvatar,
  iconPack,
  fields,
  fetchMore,
  onProfileSelected,
}: ProfilesListWidgetProps) => {
  return (
    <InfiniteListView
      className="w-100 h-100"
      componentProps={{
        showAvatar,
        fields,
        onProfileSelected,
      }}
      fetchMore={fetchMore}
      component={({ item: profile, ...props }: { item: any }) => (
        <ProfileListItem
          profile={profile}
          onSelected={onProfileSelected}
          fields={fields}
          iconPack={iconPack}
          {...props}
        >
          <ProfileRatings ratings={profile.ratings} />
        </ProfileListItem>
      )}
      emptyComponent={NoRows}
      spreadRowItem={false}
    />
  );
};

export default ProfilesListWidget;
