import React, { forwardRef, useImperativeHandle } from 'react';
import { Tooltip } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

// Stolen: Needs adding to components
const SeverityIcon = ({ severity, ...props }) => {
  switch (severity) {
    case 'warning': {
      return (
        <FontAwesomeIcon
          icon="exclamation-triangle"
          color="orange"
          {...props}
        />
      );
    }
    case 'error': {
      return <FontAwesomeIcon icon="times-circle" color="red" {...props} />;
    }
    default: {
      return null;
    }
  }
};

const ValidProfileRenderer = ({ value, data }, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    // getReactContainerClasses() {
    //     return ['custom-tooltip'];
    // },
    getReactContainerStyle() {
      return {
        display: 'inline-block',
        height: '100%',
        width: '100%',
      };
    },
  }));

  if (value == null) {
    // show a neutral thing - or a plus icon?
    return null;
  }

  let [valid, errors] = value;

  let backgroundColor, icon, hasMore;
  if (!valid) {
    // red with cross
    backgroundColor = 'red';
    icon = 'times';
  } else {
    // green with tick!
    backgroundColor = 'green';
    icon = 'check';
  }

  if (errors && errors.length > 4) {
    hasMore = errors.length - 4;
    errors = errors.slice(0, 4);
  }

  return (
    <Tooltip
      className="w-100 h-100"
      targetClassName="w-100 h-100"
      wrapperTagName="div"
      disabled={false}
      content={
        valid ? (
          <div>
            <FontAwesomeIcon color="green" icon="check-circle" /> No validation
            Errors!
          </div>
        ) : (
          <div>
            {errors &&
              errors.map((error, index) => {
                return (
                  <div key={index}>
                    <SeverityIcon severity={error.severity} />{' '}
                    {`${error.field.sectionItem.label} - ${
                      error.field.label
                    } - ${t(error.message)}`}
                  </div>
                );
              })}
            {hasMore && <div>{`... and ${hasMore} more`}</div>}
          </div>
        )
      }
    >
      <div
        className="w-100 h-100 d-flex flex-row flex-grow-1 justify-content-center align-items-center"
        // style={{ backgroundColor }}
      >
        <FontAwesomeIcon icon={icon} size="2x" color={backgroundColor} />
      </div>
    </Tooltip>
  );
};

export default forwardRef(ValidProfileRenderer);
