import { Button, Popover } from '@blueprintjs/core';
import React, { useContext } from 'react';
import ReactJson from 'react-json-view';
import { UserContext } from '../../context';

interface ParamsButtonProps {
  params: any;
}
export const ParamsButton = ({ params }: ParamsButtonProps) => {
  const user = useContext(UserContext);

  if (params && user?.isSupportAdmin) {
    return (
      <Popover position="bottom-right">
        <Button icon="wrench" />
        <div className="p-2">
          <ReactJson name={false} collapsed={1} src={params} />
        </div>
      </Popover>
    );
  }
  return null;
};
