interface TimelineFilterOptions {
  id: string;
  value: any;
}

export interface TimelineActivityFilters {
  type: string;
  id: string;
  description: string;
  items?: TimelineFilterOptions[];
  icons?: string;
}

export interface TimelineFilter {
  activityTypes: TimelineFilterOptions[];
  tags: TimelineFilterOptions[];
  categories: TimelineFilterOptions[];
  severities: TimelineFilterOptions[];
  statuses: TimelineFilterOptions[];
  assignedTo: TimelineFilterOptions[];
}

export const getTimelineFilters = ({
  activityTypes,
  tags,
  categories,
  severities,
  statuses,
  assignedTo,
}: TimelineFilter): TimelineActivityFilters[] => {
  return [
    {
      type: 'select',
      id: 'type',
      description: 'Activity Type',
      items: activityTypes,
    },
    {
      type: 'date',
      id: 'dateRange',
      description: 'Date',
    },
    {
      type: 'select',
      id: 'category',
      description: 'Category',
      items: categories,
    },
    {
      type: 'select',
      id: 'severity',
      description: 'Severity',
      items: severities,
    },
    {
      type: 'select',
      id: 'status',
      description: 'Status',
      items: statuses,
    },
    {
      type: 'multiselect',
      id: 'assignedTo',
      description: 'Assigned To',
      items: assignedTo,
    },
    {
      type: 'multiselect',
      id: 'tags',
      description: 'Tags',
      items: tags,
    },
  ];
};
