import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import permissions from '@hogwarts/permissions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useFeature,
  useFederationFeature,
  useMutation,
  usePermission,
} from '../../../hooks';
import { GET_FEDERATIONS, GET_ORGANISATION_DETAIL } from '../../../queries';
import { TOGGLE_FEATURE } from '../../../queries/features';
import styles from './styles.module.css';

const IncidentsEnabler = () => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const orgEnabled = useFeature('timeline.incident_tracking');
  const fedEnabled = useFederationFeature('timeline.incident_tracking');
  const orgPermission = usePermission(
    permissions.FEATURE_CONTROL,
    organisation.id
  );
  const fedPermission = usePermission(
    permissions.FEATURE_CONTROL,
    organisation.federation?.id
  );

  const [enableOrgIncidents] = useMutation(TOGGLE_FEATURE, {
    selector: 'toggleFeature',
    variables: {
      key: 'timeline.incident_tracking',
      ownerType: 'ORGANISATION',
      ownerId: organisation.id,
      enabled: !orgEnabled,
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });
  const [enableFedIncidents] = useMutation(TOGGLE_FEATURE, {
    selector: 'toggleFeature',
    variables: {
      key: 'timeline.incident_tracking',
      ownerType: 'FEDERATION',
      ownerId: organisation.federation?.id,
      enabled: !fedEnabled,
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION_DETAIL,
        variables: {
          organisationKey: organisation.key,
        },
      },
      {
        query: GET_FEDERATIONS,
        variables: {
          federationKey: organisation.federation?.key,
        },
      },
    ],
  });

  return (
    <>
      <SettingsPanel title="Incident Tracking">
        <div className="pb-4">
          <div>
            {t(`We're excited to introduce you to our new Incident Tracking feature,
            a powerful tool designed to enhance your organisation's ability to
            address and record low-level concerns effectively. This feature is
            not just a functional addition; it's a crucial step in safeguarding
            your environment. Whether dealing with medical situations,
            conflicts, safety concerns, or harassment, the ability to document
            these incidents comprehensively is vital for maintaining a safe and
            responsive workplace.`)}
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://go.scrtracker.com/incident-tracking-docs"
          >
            {t('Learn more about Incident Tracking')}
          </a>
        </div>
        {organisation.federation && (
          <div className={`${styles.toggleControl} pb-2`}>
            {!fedPermission && (
              <FontAwesomeIcon icon="lock" className={'pr-1'} />
            )}
            {t('Enable Incident Tracking for your Federation')}
            <Toggle
              className="ml-2"
              checked={fedEnabled}
              disabled={!fedPermission}
              onChange={() => {
                fedEnabled
                  ? analytics.events.activities.incidentTrackingDisabled({
                      level: 'fed',
                    })
                  : analytics.events.activities.incidentTrackingEnabled({
                      level: 'fed',
                    });
                enableFedIncidents();
              }}
            />
          </div>
        )}
        <div className={styles.toggleControl}>
          {!orgPermission && <FontAwesomeIcon icon="lock" className={'pr-1'} />}
          {t('Enable Incident Tracking for your Organisation')}
          <Toggle
            className="ml-2"
            checked={fedEnabled || orgEnabled}
            disabled={fedEnabled || !orgPermission}
            onChange={() => {
              orgEnabled
                ? analytics.events.activities.incidentTrackingDisabled({
                    level: 'org',
                  })
                : analytics.events.activities.incidentTrackingEnabled({
                    level: 'org',
                  });
              enableOrgIncidents();
            }}
          />
        </div>
      </SettingsPanel>
    </>
  );
};

export default IncidentsEnabler;
