import trackEvent from '../trackEvent';

export const dashboardViewed = (traits = {}) => {
  trackEvent('Dashboard - Dashboard Viewed', traits);
};

export const addDashboardClicked = (traits = {}) => {
  trackEvent('Dashboard - Add Dashboard Clicked', traits, true);
};

export const dashboardAdded = (traits = {}) => {
  trackEvent('Dashboard - Dashboard Added', traits);
};

export const dashboardRemoved = (traits = {}) => {
  trackEvent('Dashboard - Dashboard Removed', traits, true);
};

export const dashboardFavourited = (traits = {}) => {
  trackEvent('Dashboard - Dashboard Favourited', traits, true);
};

export const dashboardSwitched = (traits = {}) => {
  trackEvent('Dashboard - Dashboard Switched', traits, true);
};

export const dashboardEditClicked = (traits = {}) => {
  trackEvent('Dashboard - Edit Clicked', traits, true);
};

export const dashboardDetailChanged = (traits = {}) => {
  trackEvent('Dashboard - Detail Changed', traits, true);
};

export const addWidgetClicked = (traits = {}) => {
  trackEvent('Dashboard - Add Widget Clicked', traits, true);
};

export const widgetSettingsUpdated = (traits = {}) => {
  trackEvent('Dashboard - Widget Settings Updated', traits, true);
};

export const widgetRemoved = (traits = {}) => {
  trackEvent('Dashboard - Widget Removed', traits, true);
};

export const widgetAdded = (traits = {}) => {
  trackEvent('Dashboard - Widget Added', traits);
};

export const widgetsReordered = (traits = {}) => {
  trackEvent('Dashboard - Widgets Reordered', traits, true);
};

export const widget = {
  barChart: {
    profileTypeSelected: (traits = {}) =>
      trackEvent('Bar Chart Widget - Profile Type Selected', traits, true),
  },
  combinedScoresChart: {
    profileTypeSelected: (traits = {}) =>
      trackEvent(
        'Combined Scores Chart Widget - Profile Type Selected',
        traits,
        true
      ),
  },
  organisationList: {
    organisationSelected: (traits = {}) =>
      trackEvent(
        'Organisation List Widget - Organisation Selected',
        traits,
        true
      ),
  },
  pieChart: {
    ratingSelected: (traits = {}) =>
      trackEvent('Pie Chart Widget - Rating Selected', traits, true),
  },
  profilesList: {
    profileSelected: (traits = {}) =>
      trackEvent('Profiles List Widget - Profile Selected', traits, true),
  },
  profilesSummary: {
    profileTypeSelected: (traits = {}) =>
      trackEvent(
        'Profiles Summary Widget - Profile Type Selected',
        traits,
        true
      ),
  },
  expiredChecksByCheck: {
    profileSelected: (traits = {}) =>
      trackEvent(
        'Expired Checks by Check Widget - Profile Selected',
        traits,
        true
      ),
  },
  expiredChecksByProfile: {
    profileSelected: (traits = {}) =>
      trackEvent(
        'Expired Checks by Profile Widget - Profile Selected',
        traits,
        true
      ),
  },
  lateDatesByProfile: {
    profileSelected: (traits = {}) =>
      trackEvent(
        'Late Dates by Profile Widget - Profile Selected',
        traits,
        true
      ),
  },
};
