import { Callout, Intent } from '@blueprintjs/core';
import { Form } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { FormField, FormikProps } from '../../types';
import FormFieldWrapper from './FormFieldWrapper';

export interface RenderFieldsProps {
  fields: FormField[];
  readOnly?: boolean;
  decoratorRegistry: { key: string; item: any }[];
  formikProps: FormikProps;
  // Unsure where this is coming from
  componentProps?: any;
}
export const RenderFields = ({
  fields,
  componentProps,
  decoratorRegistry,
  readOnly: everythingReadOnly,
  formikProps,
}: RenderFieldsProps) => {
  // eslint-disable-next-line
  const [showDebug, setShowDebug] = useState(
    // eslint-disable-next-line no-undef
    window.location.hostname === 'localhost'
  );

  useEffect(() => {
    // Check for duplicate keys. Throw an error if discovered.
    fields.reduce<Record<string, boolean>>((prev, field) => {
      if (!field?.key) return prev;
      if (prev[field.key]) {
        throw new Error(`Duplicate Key ${field.key}`);
      }
      return { ...prev, [field.key]: true };
    }, {});
  }, [fields]);

  const visibleFields = useMemo(() => {
    return fields?.filter(
      (field) => field && field.visible !== false && field.type !== 'hidden'
    );
  }, [fields]);

  if (!fields || fields.length === 0) {
    return (
      <Callout intent={Intent.WARNING} title={'Nothing to Edit'}>
        {'There are no fields specified for editing'}
      </Callout>
    );
  }

  return (
    <Form>
      {visibleFields.map((field, index) => {
        let { key, type, label, meta, readOnly, ...fieldComponentProps } =
          field;

        // Readonly can be specified in multiple places, but
        // it should be set if any of the values are set to true
        readOnly = !!(
          everythingReadOnly ||
          readOnly ||
          componentProps?.readOnly
        );
        key = key || `generated_index_${index}`;
        return (
          <FormFieldWrapper
            key={key}
            type={type}
            label={label}
            fieldKey={key}
            field={field}
            className={field.className}
            readOnly={readOnly}
            componentProps={{
              ...meta,
              ...componentProps,
              ...fieldComponentProps,
              formikProps,
            }}
            decoratorRegistry={decoratorRegistry}
            decorators={field.decorators}
            isSubmitting={formikProps.isSubmitting}
          />
        );
      })}

      {showDebug && (
        <ReactJson
          name={false}
          theme="summerfruit:inverted"
          collapsed={0}
          src={{
            values: formikProps.values,
            touched: formikProps.touched,
            errors: formikProps.errors,
            dirty: formikProps.dirty,
          }}
        />
      )}
    </Form>
  );
};
