import {
  Card,
  HTMLTable,
  Icon,
  NonIdealState,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import React, { useContext } from 'react';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useDateFormatter, useFeature, usePermission } from '../../../hooks';
import { Check, CheckOrder } from '../Checks/types';

import { AppToaster } from '@/utils/toaster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import permissions from '@hogwarts/permissions';
import { Button } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';
import { CheckStatusTag } from '../../../components';
import styles from './styles.module.css';

interface CheckTypesProps {
  checks: Check[];
}
const CheckTypes = ({ checks }: CheckTypesProps) => {
  return (
    <div>
      {checks?.map((c) => {
        // I know this is hardcoded, I just needed a quick fix to solve a customer problem
        if (c.extraInfo?.CheckCriminalDisclosureApplicationNumber) {
          return (
            <div key={c.cacheKey} className="d-flex flex-column">
              <div>{`${c.name}`}</div>
              <div>{`Application No. ${c.extraInfo?.CheckCriminalDisclosureApplicationNumber}`}</div>
            </div>
          );
        }
        return <div key={c.cacheKey}>{`${c.name}`}</div>;
      })}
    </div>
  );
};

interface CheckStatusesProps {
  checks: Check[];
}
const CheckStatuses = ({ checks }: CheckStatusesProps) => {
  return (
    <div className="d-flex flex-column">
      {checks?.map((c) => (
        <CheckStatusTag key={c.cacheKey} status={c.status} />
      ))}
    </div>
  );
};

interface TableProps {
  orders: CheckOrder[];
  onOpenReport: (props: any) => void;
  onConfirmIdentity: (order: CheckOrder) => void;
  onCancelOrder: (uniqueOrderKey: string) => boolean;
  allowConfirmIdentity: boolean;
  viewOrderDetails: (order: CheckOrder) => void;
}
const Table = ({
  orders,
  onOpenReport,
  onConfirmIdentity,
  allowConfirmIdentity,
  onCancelOrder,
  viewOrderDetails,
}: TableProps) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const allowCancelOrder = useFeature('checks.cancel_verifile_order');
  const canCancelOrderPermission = usePermission(
    permissions.CHECK_CANCEL_ORDER,
    organisation?.id
  );

  return (
    <HTMLTable className="w-100">
      <thead>
        <tr>
          <th>{t('Order Id')}</th>
          <th>{t('Order Date')}</th>
          {/* <th>{t('Status')}</th> */}
          <th>{t('Checks')}</th>
          <th>{t('Status')}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {orders &&
          orders.map((order) => {
            const isOrderOpenWithVerifile =
              order.status !== 'VERIFYING CANDIDATE' &&
              order.status !== 'COMPLETED' &&
              order.status !== 'CANCELLED';

            return (
              <tr key={`order-${order.id}`}>
                <td>
                  <Tooltip
                    content={t(
                      'Click to view additional order info and status'
                    )}
                  >
                    {viewOrderDetails ? (
                      <div
                        onClick={() => viewOrderDetails(order)}
                        className={styles.ordersButton}
                      >
                        {order.orderId} <Icon icon="info-sign" />
                      </div>
                    ) : (
                      <div>{order.orderId}</div>
                    )}
                  </Tooltip>
                </td>
                <td>{formatDate.medium(order.orderedAt)}</td>
                {/* <td>
                {order.open ? (
                  <Tag intent="warning">{t('Open')}</Tag>
                ) : (
                  <Tag intent="success">{t('Complete')}</Tag>
                )}
              </td> */}
                <td>
                  <CheckTypes checks={order.checks} />
                </td>
                <td>
                  <CheckStatuses checks={order.checks} />
                </td>
                <td className={styles.buttonsContainer}>
                  {order.document && (
                    // @ts-ignore
                    <Button
                      intent="success"
                      onClick={async () => {
                        await onOpenReport({
                          documentId: order.document,
                        });
                      }}
                    >
                      {t('Open Report')}
                    </Button>
                  )}
                  {order.orderId && order.awaitingConfirmId && (
                    // @ts-ignore
                    <Button
                      intent="primary"
                      allowed={allowConfirmIdentity}
                      onClick={async () => {
                        if (order.orderId) {
                          await onConfirmIdentity(order);
                        }
                      }}
                    >
                      {t('Confirm Identity')}
                    </Button>
                  )}
                  {allowCancelOrder &&
                    (isOrderOpenWithVerifile ||
                      order.status === 'VERIFYING CANDIDATE') && (
                      <Button
                        confirm
                        intent="danger"
                        allowed={canCancelOrderPermission}
                        onClick={async () => {
                          if (order.uniqueKey) {
                            const result = await onCancelOrder(order.uniqueKey);

                            if (isOrderOpenWithVerifile) {
                              analytics.events.checks.verifileOrderCancelled({
                                orderId: order.orderId,
                                success: result,
                              });

                              const toastMessage = t(
                                result
                                  ? 'Order successfully cancelled'
                                  : 'Unable to cancel order'
                              );

                              AppToaster.show({
                                intent: result ? 'success' : 'danger',
                                message: toastMessage,
                              });
                            }
                          }
                        }}
                      >
                        {t('Cancel Order')}
                      </Button>
                    )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </HTMLTable>
  );
};

interface OrdersListProps {
  orders: CheckOrder[];
  onOpenReport: (props: any) => void;
  onConfirmIdentity: (order: CheckOrder) => void;
  allowReadCheckOrders: boolean;
  allowConfirmIdentity: boolean;
  onCancelOrder: (uniqueOrderKey: string) => boolean;
  onViewOrderDetails: (order: CheckOrder) => void;
}
const OrdersList = ({
  orders,
  onOpenReport,
  onConfirmIdentity,
  allowReadCheckOrders,
  allowConfirmIdentity,
  onCancelOrder,
  onViewOrderDetails,
}: OrdersListProps) => {
  const { t } = useTranslation();

  if (!allowReadCheckOrders) {
    return (
      <Card>
        <NonIdealState
          className="my-5"
          icon={
            <FontAwesomeIcon color="red" icon={['far', 'exclamation-circle']} />
          }
          title={t('Access Denied')}
          description={t<string>(
            'You do not have permission to view Profile Check Orders. Please contact your system administrator'
          )}
        />
      </Card>
    );
  }

  if (!orders) {
    return (
      <Card>
        <Spinner />
      </Card>
    );
  }

  if (orders.length === 0) {
    return (
      <Card>
        <NonIdealState
          icon="saved"
          title={t('No check orders found')}
          description={t<string>(
            "You haven't ordered any checks for this profile yet."
          )}
        />
      </Card>
    );
  }

  return (
    <Card>
      <Table
        orders={orders}
        onOpenReport={onOpenReport}
        onConfirmIdentity={onConfirmIdentity}
        allowConfirmIdentity={allowConfirmIdentity}
        onCancelOrder={onCancelOrder}
        viewOrderDetails={onViewOrderDetails}
      />
    </Card>
  );
};

export default OrdersList;
