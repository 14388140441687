import { isObject } from '@hogwarts/utils';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../types';
import UnknownComponent from './UnknownComponent';
import componentRegistry from './components';
import styles from './styles.module.css';

interface FormFieldWrapperProps {
  label?: string;
  type: string;
  readOnly: boolean;
  fieldKey: string;
  isSubmitting: boolean;
  field: FormField;

  componentProps: any;
  decoratorRegistry: { key: string; item: any }[];
  decorators: any;

  className?: string;
}
const FormFieldWrapper = ({
  label,
  type,
  readOnly,
  fieldKey,
  isSubmitting,
  componentProps,
  decoratorRegistry,
  decorators: overridenDecorators,
  className,
}: FormFieldWrapperProps) => {
  const [formField, fieldMeta, fieldHelpers] = useField(fieldKey);

  const formik = useFormikContext();
  const { t } = useTranslation();

  const Component = useMemo(() => {
    const { Component: FieldComponent, decorators: defaultDecoratorSetup } =
      componentRegistry[type || 'textbox'] || {};

    if (!FieldComponent) {
      console.error('Unknown field type: ' + type, { fieldKey, type });
      return () => <UnknownComponent type={type} />;
    }

    let decoratorSetup = {
      ...defaultDecoratorSetup,
      ...overridenDecorators,
    };

    if (!decoratorRegistry) return FieldComponent;
    return decoratorRegistry.reduce(
      (prev, { key: decoratorKey, item: decoratorHoc }) => {
        let decoratorProps = decoratorSetup && decoratorSetup[decoratorKey];

        // Allows for disabling of a decorator.
        if (decoratorProps === false) return prev;

        if (!decoratorHoc) {
          throw new Error(`Unknown Decorator [${decoratorKey}]`);
        }

        if (typeof decoratorProps !== 'undefined') {
          if (!isObject(decoratorProps)) {
            decoratorProps = { [decoratorKey]: decoratorProps };
          }
        }

        return decoratorHoc(prev, decoratorProps);
      },
      FieldComponent
    );
  }, [type, decoratorRegistry, overridenDecorators]);

  return (
    <div
      className={cn(
        styles.formBuilderComponent,
        className && styles[className]
      )}
    >
      <Component
        {...componentProps}
        key={fieldKey}
        fieldKey={fieldKey}
        formik={formik}
        t={t}
        formField={formField}
        fieldMeta={fieldMeta}
        fieldHelpers={fieldHelpers}
        readOnly={!!(isSubmitting || readOnly)}
        label={label}
        decoratorRegistry={decoratorRegistry}
      />
    </div>
  );
};

export default FormFieldWrapper;
