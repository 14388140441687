import { Drawer, NonIdealState } from '@blueprintjs/core';
import { useDrawerSize } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetLibraryItem from '../WidgetLibraryItem';
import styles from './styles.module.css';

interface Widget {
  type: string;
  name: string;
  description: string;
}
interface WidgetGroup {
  label: string;
  widgets: Widget[];
}
interface WidgetLibraryDrawerProps {
  isOpen: boolean;
  groups: WidgetGroup[];
  onClose: () => void;
  onWidgetSelected: (widget: Widget) => void;
}
const WidgetLibraryDrawer = ({
  isOpen,
  groups,
  onClose,
  onWidgetSelected,
}: WidgetLibraryDrawerProps) => {
  const { t } = useTranslation();
  const size = useDrawerSize(undefined);

  return (
    <Drawer
      title={t('Add Widget')}
      size={size}
      hasBackdrop={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.libraryContainer}>
        {groups?.map((group) => {
          if (!group.widgets.length) return null;

          return (
            <div key={group.label}>
              {groups.length > 1 && (
                <div className="font-weight-bold">{group.label}</div>
              )}
              {group.widgets.map((item) => (
                <WidgetLibraryItem
                  key={item.type}
                  {...item}
                  onClick={() => {
                    onWidgetSelected(item);
                  }}
                />
              ))}
            </div>
          );
        })}
      </div>
      {(!groups || groups.length === 0) && (
        <NonIdealState title={t('No Widgets Found')} />
      )}
    </Drawer>
  );
};

export default WidgetLibraryDrawer;
