import { gql } from '@apollo/client';

export const GET_CHECKACCOUNT_STATUS = gql`
  query getAvailableChecks($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      checkAccountStatus
    }
  }
`;

export const GET_AVAILABLE_CHECKS = gql`
  query getAvailableChecks($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      checkTypes {
        id
        key
        name
        description
        requiredFields
        exclusiveGroup
        condition
        display
        price {
          value
          currency
        }
      }
    }
  }
`;

export const GET_FEDERATION_CURRENT_SPEND = gql`
  query getCheckAccount($federationKey: String!) {
    federations(key: $federationKey) {
      id
      currentSpend {
        value
        currency
      }
    }
  }
`;

export interface GET_CHECK_ACCOUNTResponse {
  checkAccountStatus: 'disabled' | 'notfound' | 'blocked' | 'nocredit';
  currentSpend: {
    value: number;
    currency: string;
  };
  checkAccount: {
    id: string;
    limit: {
      value: number;
      currency: string;
    };
    blocked: boolean;
    poRequired: boolean;
  };
}

export const GET_CHECK_ACCOUNT = gql`
  query getCheckAccount($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      checkAccountStatus
      currentSpend {
        value
        currency
      }
      checkAccount {
        id
        limit {
          value
          currency
        }
        blocked
        poRequired
      }
    }
  }
`;

export interface GET_UNINVOICED_CHECK_ORDERS_Response {
  id: string;
  orderId: string;
  profileId: string;
  orderedAt: string;
  open: boolean;
  status: string;
  purchaseOrder?: string;
  awaitingConfirmId: string;
  checks: {
    id: string;
    cacheKey: string;
    key: string;
    type: string;
    name: string;
    price: {
      value: number;
      currency: string;
    };
    status: string;
  }[];
  price: {
    value: number;
    currency: string;
  };
}

export const GET_UNINVOICED_CHECK_ORDERS = gql`
  query checkOrders(
    $organisationKey: String!
    $cursor: String
    $limit: Int
    $filter: CheckOrdersFilter
  ) {
    organisations(key: $organisationKey) {
      id
      checkOrders(limit: $limit, cursor: $cursor, filter: $filter) {
        id
        orderId
        profileId
        orderedAt
        open
        status
        purchaseOrder
        awaitingConfirmId
        checks {
          id
          cacheKey
          key
          type
          name
          price {
            value
            currency
          }
          status
        }
        price {
          value
          currency
        }
      }
    }
  }
`;

export const GET_CHECK_ORDERS_STATUSES = gql`
  query checkOrdersStatuses($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      checkOrdersStatuses
    }
  }
`;

export interface GET_CHECK_INVOICES_Response {
  id: string;
  date: string;
  reference?: string;
  externalCode?: string;
  externalId?: string;
  externalLink?: string;
  summaryDocument?: {
    id: string;
    handle: string;
  };
  price: {
    value: number;
    currency: string;
  };
}
export const GET_CHECK_INVOICES = gql`
  query checkInvoices($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      checkInvoices {
        id
        date
        reference
        externalCode
        externalId
        externalLink
        summaryDocument {
          id
          filestackHandle
        }
        price {
          value
          currency
        }
      }
    }
  }
`;

export const GET_PROFILE_CHECK_ORDERS = gql`
  query profileCheckOrders($organisationKey: String!, $profileId: ID!) {
    organisations(key: $organisationKey) {
      id
      profiles(id: $profileId) {
        id
        checkOrders {
          id
          uniqueKey
          orderId
          jobRoleId
          orderedAt
          open
          status
          awaitingConfirmId
          document
          checks {
            id
            cacheKey
            key
            type
            price {
              value
              currency
            }
            status
            documents
            awaitingConfirmId
            extraInfo
          }
          completedAt
          candidate
        }
      }
    }
  }
`;
