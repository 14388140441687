import React, { useContext, useState } from 'react';
import {
  AnalyticsContext,
  OrganisationContext,
  FederationContext,
} from '@/context';
import { SearchDrawer } from '@/components';
import permissions from '@hogwarts/permissions';
import { usePermission } from '@/hooks';
import { useLazyQuery } from '@apollo/client';
import { PROFILE_SEARCH } from '@/queries';
import { useHistory } from 'react-router-dom';

const SearchContainer = ({ children }) => {
  const [isCleared, setIsCleared] = useState(false);

  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  const history = useHistory();

  const searchAllowed = usePermission(
    permissions.PROFILE_READ,
    organisation?.id
  );

  const [performSearch, { loading, error, data }] = useLazyQuery(
    PROFILE_SEARCH,
    { fetchPolicy: 'no-cache' }
  );
  const organisationProfiles = [];
  const federationProfiles = federation ? [] : null;
  if (data && Array.isArray(data.profileSearch)) {
    data.profileSearch.forEach((profile) => {
      const profileType = organisation.scheme.getProfileType(profile.typeKey);

      if (profile.organisationKey === organisation.key) {
        organisationProfiles.push({
          ...profile,
          profileType,
        });
      } else if (federation && profile.federation === federation.id) {
        federationProfiles.push({
          ...profile,
          profileType,
        });
      } else {
        console.warn('Unexpected profile not in organisation or federation');
      }
    });
  }

  return (
    <SearchDrawer
      allowed={searchAllowed}
      isLoading={loading}
      error={!!error}
      organisationName={organisation.name}
      federationName={federation && federation.name}
      organisationProfiles={isCleared ? [] : organisationProfiles}
      federationProfiles={
        isCleared ? (federation ? [] : null) : federationProfiles
      }
      onSearchTermChange={async (searchTerm) => {
        analytics.events.search.termSearched({ searchTerm });
        setIsCleared(false);
        performSearch({
          variables: {
            searchTerm: searchTerm,
            organisationKey: organisation.key,
            federationKey: federation && federation.key,
          },
        });
      }}
      onSelected={(profile) => {
        analytics.events.dashboard.widget.profilesList.profileSelected();
        history.push(`/${profile.organisationKey}/profiles/${profile.id}`);
      }}
      onClear={() => {
        analytics.events.search.searchCleared();
        setIsCleared(true);
      }}
    >
      {children}
    </SearchDrawer>
  );
};

export default SearchContainer;
