import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const BooleanCellRenderer = ({ value }) => {
  return (
    <div className={'d-flex align-items-center justify-content-center h-100'}>
      {value ? (
        <FontAwesomeIcon icon={'check'} size={'2x'} color="green" />
      ) : (
        <FontAwesomeIcon icon={'times'} size={'2x'} color="red" />
      )}
    </div>
  );
};

export default BooleanCellRenderer;
