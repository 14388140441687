import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Label } from '@blueprintjs/core';

const HyperlinkComponent = ({ url, label }) => {
  const { t } = useTranslation();
  return (
    <a href={url} target="blank">
      {label ? t(label) : url}
    </a>
  );
};

export default {
  name: 'Hyperlink',
  options: [
    {
      url: {
        label: 'URL',
        type: 'textbox',
        required: true,
      },
    },
    {
      label: {
        label: 'Label',
        type: 'textbox',
        required: true,
      },
    },
  ],
  decorators: {
    label: false,
  },
  Component: HyperlinkComponent,
};
