import { DATASOURCE_QUERY_PROFILES } from '@/queries';
import { useApolloClient } from '@apollo/client';
import { dataSourceFactory as dataSourceFactoryCore } from '@hogwarts/ui-components-grid';
import { get } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { OrganisationContext } from '../../context';
import { useMemoizeArgs, useTransformProfiles } from '../../hooks';

const SELECTOR = 'data.queryProfiles.result';

interface DataSourceFactoryProps {
  variables?: any;
}
export const useDataSourceFactory = ({
  variables: variablesCore,
}: DataSourceFactoryProps = {}) => {
  const organisation = useContext(OrganisationContext);
  const apolloClient = useApolloClient();

  const transform = useTransformProfiles();

  const variables = useMemoizeArgs(variablesCore);

  const getMoreRows = useCallback(
    async ({
      filter,
      condition,
      skip,
      limit,
      sort,
    }: {
      skip: number;
      limit: number;
      sort?: any;
      condition?: any;
      filter?: any;
    }) => {
      let result: any = await apolloClient.query({
        query: DATASOURCE_QUERY_PROFILES,
        fetchPolicy: 'no-cache',
        variables: {
          ...filter,
          ...variables,
          organisationKey: organisation.key,
          transform,
          condition,
          skip,
          limit,
          sort,
        },
      });
      result = get(result, SELECTOR);

      if (!Array.isArray(result)) {
        return null;
      }

      if (typeof transform === 'function') {
        result = transform(result);
      }

      return result;
    },
    [apolloClient, organisation, transform, variables]
  );

  return useMemo(() => dataSourceFactoryCore(getMoreRows), [getMoreRows]);
};
