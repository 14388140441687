import { Button, Label } from '@blueprintjs/core';
import { formatBytes } from '@hogwarts/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DocumentListComponent = ({
  documents,
  onRemoveDocument,
  allowDelete,
}) => {
  return documents.map((document) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Label
        onClick={() => {
          if (typeof onClickHandler === 'function') {
            onClickHandler(rest);
          }
        }}
        style={{
          marginBottom: 0,
        }}
      >
        {`${document.filename} (${formatBytes(document.size)})`}
      </Label>

      {allowDelete && (
        <Button
          icon="trash"
          minimal
          intent="danger"
          onClick={() => onRemoveDocument(document)}
        />
      )}
    </div>
  ));
};

export default {
  name: 'DocumentList',
  decorators: {
    label: false,
  },
  Component: DocumentListComponent,
};
