import log from '@scrtracker/logging';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { Error } from '@hogwarts/ui-components-core';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// https://reactjs.org/docs/error-boundaries.html
class ComponentErrorAlert extends React.Component {
  state = {
    visible: false,
  };
  static propTypes = {
    children: PropTypes.node,
  };
  componentDidCatch(error, info) {
    Sentry.captureException(error);
    log.error('Error', error);
    log.debug(info);
    // Display fallback UI
    // You can also log the error to an error reporting service
    this.setState({
      visible: true,
      error,
      info,
    });
  }
  onDismiss = () => {
    this.setState({ visible: false });
  };
  render() {
    const { history } = this.props;
    const { visible } = this.state;
    return (
      <>
        {visible && (
          <Error
            title="Oops, Sorry. Something went wrong!"
            action={
              <Button onClick={() => history.push('/')} intent="PRIMARY">
                Dashboard
              </Button>
            }
          />
        )}
        {!visible && this.props.children}
      </>
    );
  }
}

export default withRouter(ComponentErrorAlert);
