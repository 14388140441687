import React from 'react';
import { useTranslation } from 'react-i18next';
import { NonIdealState, Button } from '@blueprintjs/core';

import styles from './styles.module.css';

const NoDashboards = ({ onAddClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.noDashboardsContainer}>
      <NonIdealState
        icon="help"
        title={t('No Dashboards Found')}
        description={t(
          "It appears that you haven't got any dashboards available to you. If you click below we can create your first one."
        )}
        action={
          <Button large onClick={onAddClick} intent="primary">
            {t('Add Dashboard')}
          </Button>
        }
      />
    </div>
  );
};

export default NoDashboards;
