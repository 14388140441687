import {
  CellRendererSelectorFunc,
  Column,
  ColumnGroup,
  ValueFormatterFunc,
  ValueSetterFunc,
} from '@hogwarts/ui-components-grid';
import { Scheme } from '@hogwarts/utils-schemes';
import cellStyles from './cellStyles.module.css';
import * as formatters from './formatters';
export const EMPTY_CELL_VALUE = '';

// export interface Group {
//   key: string;
//   label: string;
// }

// export interface Field {

// }

export const getAllColumnsForScheme = (
  scheme: Scheme,
  {
    valueSetter,
    cellRendererSelector,
    defaultSortField,
    dontIncludeCalculatedFields,
    ignoreReadOnly,
  }: {
    valueSetter: ValueSetterFunc;
    cellRendererSelector: CellRendererSelectorFunc;
    defaultSortField?: string;
    dontIncludeCalculatedFields?: boolean;
    ignoreReadOnly?: boolean;
  }
): [ColumnGroup[], Column[]] => {
  const groups: ColumnGroup[] = [];
  const fields: Column[] = [];

  const isInUse = (item: any) => {
    // TODO: We have a problem here.
    // When the scheme is for a selectedProfileType, it doesnt use "inUse"
    // it needs to use enabled
    if (scheme.selectedProfileTypeKey) {
      return item.enabled;
    }
    return item.inUse;
  };

  const getCellField = (params: any) => {
    if (!params.data) return null;
    let rowScheme = params.data.scheme || scheme;
    const field1 = rowScheme.getField(params.colDef.meta?.key);
    if (
      !field1 ||
      field1.sectionItem?.enabled !== true ||
      field1.enabled !== true
    ) {
      return null;
    }
    return field1;
  };

  const removeValueFormatterFactory = (
    formatter: ValueFormatterFunc
  ): ValueFormatterFunc => {
    return (params) => {
      const field1 = getCellField(params);
      if (!field1) {
        return EMPTY_CELL_VALUE;
      }
      if (field1.visible === false) {
        return EMPTY_CELL_VALUE;
      }
      if (formatter) {
        return formatter(params);
      }
      return EMPTY_CELL_VALUE;
    };
  };

  for (const section of scheme.sections) {
    if (!isInUse(section)) continue;
    groups.push({
      key: section.key,
      label: section.label,
    });

    for (const field of section.fields) {
      if (!isInUse(field)) continue;

      if (dontIncludeCalculatedFields && field.calculation) {
        continue;
      }

      if (field.features?.advancedEditor?.enabled === false) {
        continue;
      }

      let valueFormatter: ValueFormatterFunc | undefined = undefined;
      switch (field.dataType) {
        case 'none': {
          continue;
        }
        case 'array': {
          // TODO: Skip these for now
          // But in future, offer a '...' button for editing?
          continue;
        }
        case 'date': {
          valueFormatter = formatters.dateFormatter;
          break;
        }
        case 'boolean': {
          valueFormatter = formatters.booleanFormatter;
          break;
        }
        default: {
          break;
        }
      }

      if (valueFormatter) {
        valueFormatter = removeValueFormatterFactory(valueFormatter);
      }

      switch (field.inputType) {
        case 'hidden': {
          // continue;
          break;
        }
        case 'component': {
          console.log(field);
          throw new Error('Invalid InputType');
        }
        default: {
          break;
        }
      }

      // TODO: Could calculate the visibility in here
      // based on selectors etc?
      // Or pass in the function?!

      fields.push({
        key: field.key,
        group: section.key,
        meta: {
          key: field.key,
          path: `data.${field.key}`,
          field,
        },
        valueGetter: ({ data: profile }: any) => {
          if (!profile?.data) return null;

          const value = profile.data[field.key];

          if (field.dataType === 'date' && value?.toISODate) {
            return value.toISODate();
          }

          return value;
        },
        valueSetter,
        valueFormatter: valueFormatter!,
        type: field.dataType,
        label: field.label,
        width: 150,
        editable: (params) => {
          const dataError = params.data?.dataError;
          if (dataError) {
            return false;
          }
          if (!params.data?.data) {
            return false;
          }
          const field1 = getCellField(params);
          if (!field1) {
            return false;
          }
          if (field1.readOnly === true) {
            return false;
          }
          if (field1.visible === false) {
            return false;
          }
          return true;
        },
        cellRendererSelector: (params) => {
          if (typeof cellRendererSelector === 'function') {
            const field1 = getCellField(params);
            if (!field1) return;
            return cellRendererSelector({
              ...params,
              context: field1,
            });
          }
          return;
        },
        cellClassRules: {
          [cellStyles.gridFieldDisabled]: (params: any) => {
            return !getCellField(params);
          },
          [cellStyles.gridFieldNotVisible]: (params: any) => {
            const field1 = getCellField(params);
            return field1 && field1.visible === false;
          },
          [cellStyles.gridFieldReadonly]: (params: any) => {
            if (ignoreReadOnly) return false;
            const field1 = getCellField(params);
            return field1 && field1.readOnly === true;
          },
        },
        cellClass: 'scr-grid-cell-divider',
        sort: field.key === defaultSortField ? 'asc' : undefined,
      });
    }
  }
  return [groups, fields];
};
