import { Button, Intent } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeletePopover } from '@hogwarts/ui-components-core';
import React from 'react';

const ButtonRenderer = ({ data, colDef: { meta } }: any) => {
  if (meta.deleteConfirm) {
    return (
      <DeletePopover
        disabled={
          typeof meta.disabled === 'function'
            ? meta.disabled({ data })
            : !!meta.disabled
        }
        buttonComponent={
          <Button
            style={{
              height: '30px',
              minWidth: '75px',
            }}
            intent={Intent.DANGER}
            disabled={
              typeof meta.disabled === 'function'
                ? meta.disabled({ data })
                : !!meta.disabled
            }
            icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
          >
            {meta.label}
          </Button>
        }
        message="Are you sure you want to do this?"
        onRemove={() => meta.onClick(data)}
      />
    );
  }

  return (
    <Button
      style={{
        height: '30px',
        minWidth: '75px',
      }}
      disabled={
        typeof meta.disabled === 'function'
          ? meta.disabled({ data })
          : !!meta.disabled
      }
      intent={meta.intent || Intent.PRIMARY}
      onClick={() => {
        meta.onClick(data);
      }}
    >
      {/* <FontAwesomeIcon icon={'check'} size={'2x'} color="green" /> */}
      {meta.label}
    </Button>
  );
};

export default ButtonRenderer;
