import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

import styles from './styles.module.css';

const BarChartWidget = ({
  scores,
  profileTypes,
  ratingSystems,
  onProfileClick,
}) => {
  const { t } = useTranslation();
  const chartData = useMemo(
    () => {
      const labels = profileTypes.map((p) => t(p.label));
      let datasets;
      datasets = ratingSystems.reduce(
        (prev, rating) => [
          ...prev,
          {
            key: `${rating.key}_ready`,
            stack: rating.key,
            label: t(rating.label),
            borderWidth: 1,
            backgroundColor: rating.readyColor,
            hoverBackgroundColor: rating.readyColor,
            data: profileTypes.map((p) => {
              if (scores) {
                const ratingScore = scores[rating.key];
                if (ratingScore) {
                  const score = ratingScore[p.key];
                  if (score) {
                    return score.valid;
                  }
                }
              }
              return null;
            }),
          },
          {
            key: `${rating.key}_notReady`,
            stack: rating.key,
            label: t(`Not ${rating.label}`),
            borderWidth: 1,
            backgroundColor: rating.notReadyColor,
            hoverBackgroundColor: rating.notReadyColor,
            data: profileTypes.map((p) => {
              if (scores) {
                const ratingScore = scores[rating.key];
                if (ratingScore) {
                  const score = ratingScore[p.key];
                  if (score) {
                    return score.count - score.valid;
                  }
                }
              }
              return null;
            }),
          },
        ],
        []
      );

      return {
        labels,
        datasets,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scores, profileTypes, ratingSystems]
  );

  return (
    <div className={styles.barChartContainer}>
      {scores && (
        <Bar
          data={chartData}
          height={500}
          width={630}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    callback: function (value) {
                      return value.trunc(12);
                    },
                  },
                },
              ],
            },
            tooltips: {
              enabled: true,
              callbacks: {
                title: function (tooltipItems, data) {
                  var idx = tooltipItems[0].index;
                  return data.labels[idx];
                },
              },
            },
          }}
          getElementAtEvent={(dataset) => {
            if (!onProfileClick) return;
            if (!dataset || dataset.length === 0) {
              return;
            }
            const { _datasetIndex: datasetIndex, _index: index } = dataset[0];
            const [ratingKey, state] =
              chartData.datasets[datasetIndex].key.split('_');
            onProfileClick({
              profileTypeKey: profileTypes[index].key,
              rating: {
                key: ratingKey,
                state: state === 'ready' ? true : false,
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default BarChartWidget;
