import { GridOptions } from '@ag-grid-community/core';

export const gridOptions: GridOptions = {
  // loadingOverlayComponent
  // loadingOverlayComponentParams

  // enablePivot: false,
  // enableRowGroup: false,

  // rowDragManaged: true,
  // singleClickEdit: true,
  groupIncludeTotalFooter: false,
  groupIncludeFooter: false,

  defaultColDef: {
    sortable: true,
    resizable: true,
    cellClass: 'scr-grid-cell-divider',
    width: 150,
    filter: true,
    editable: true,
    floatingFilter: true,
    // suppressMenu: true
    menuTabs: ['generalMenuTab'],
  },

  sideBar: {
    toolPanels: [
      // {
      //   id: 'columns',
      //   labelDefault: 'Fields',
      //   labelKey: 'columns',
      //   iconKey: 'columns',
      //   toolPanel: 'agColumnsToolPanel',
      //   toolPanelParams: {
      //     suppressRowGroups: true,
      //     suppressValues: true,
      //     suppressPivots: true,
      //     suppressPivotMode: true,
      //     suppressSideButtons: true,
      //     // suppressColumnFilter: true,
      //     // suppressColumnSelectAll: true,
      //     // suppressColumnExpandAll: true,
      //   },
      // },
    ],
    defaultToolPanel: 'columns',
  },
};

export const editingGridOptions: GridOptions = {
  ...gridOptions,

  // https://www.ag-grid.com/javascript-grid-range-selection/#range-selection-example-suppress-multi
  enableRangeSelection: true,
  suppressMultiRangeSelection: true,
  rowSelection: 'multiple',

  // excel like behaviour:
  enterMovesDown: true,
  enterMovesDownAfterEdit: true,

  // https://www.ag-grid.com/javascript-grid-range-selection-fill-handle/
  enableFillHandle: true,
  suppressClearOnFillReduction: true,

  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 50,
};
