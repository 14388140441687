import { NonIdealState, Tag } from '@blueprintjs/core';
import { InfiniteListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileListItem } from '../../../../containers/profileListItem';
import { useDateFormatter } from '../../../../hooks';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching Profiles found')}
      description={t("Your filter conditions didn't match any profiles")}
    />
  );
};

const Checks = ({ profile }) => {
  const formatDate = useDateFormatter();

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {Object.keys(profile.expiringFields).map((sectionKey) => {
          const section = profile.expiringFields[sectionKey];

          return [
            <tr key={`section-${sectionKey}`}>
              <td colSpan={2}>
                <div className={'mr-2 font-weight-bold'}>{section.label}</div>
              </td>
            </tr>,
            ...section.fields.map((field) => (
              <tr key={`field-${field.key}`}>
                <td>
                  <div className={'ml-2 mr-2'}>{field.label}</div>
                </td>
                <td>
                  {field.expired && (
                    <Tag intent="danger" fill>
                      {`Expired: ${formatDate.medium(field.expiryDate)}`}
                    </Tag>
                  )}
                  {field.expiringSoon && (
                    <Tag intent="warning" fill>
                      {`Expiring: ${formatDate.medium(field.expiryDate)}`}
                    </Tag>
                  )}
                </td>
              </tr>
            )),
          ];
        })}
      </tbody>
    </table>
  );
};

const ProfilesListWidget = ({ fetchMore, onProfileSelected, iconPack }) => {
  return (
    <InfiniteListView
      className="w-100 h-100"
      componentProps={{
        onProfileSelected,
      }}
      fetchMore={fetchMore}
      component={({ item: profile, ...props }) => {
        return (
          <ProfileListItem
            profile={profile}
            onSelected={onProfileSelected}
            iconPack={iconPack}
            {...props}
          >
            <Checks profile={profile} />
          </ProfileListItem>
        );
      }}
      emptyComponent={NoRows}
      spreadRowItem={false}
    />
  );
};

export default ProfilesListWidget;
