import { ReactComponent as Button } from './button.svg';
import { ReactComponent as CheckBox } from './checkbox.svg';
import { ReactComponent as CheckList } from './checklist.svg';
import { ReactComponent as Computed } from './computed.svg';
import { ReactComponent as Date } from './date.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Hidden } from './hidden.svg';
import { ReactComponent as Label } from './label.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Money } from './money.svg';
import { ReactComponent as MultiSelect } from './multiselect.svg';
import { ReactComponent as Numeric } from './numeric.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Role } from './role.svg';
import { ReactComponent as Separator } from './separator.svg';
import { ReactComponent as SingleSelect } from './singleselect.svg';
import { ReactComponent as Tabs } from './tabs.svg';
import { ReactComponent as Text } from './text.svg';
import { ReactComponent as TextArea } from './textarea.svg';
import { ReactComponent as Time } from './time.svg';
import { ReactComponent as Title } from './title.svg';
import { ReactComponent as Toggle } from './toggle.svg';
import { ReactComponent as User } from './user.svg';

export default {
  button: Button,
  checkbox: CheckBox,
  checklist: CheckList,
  computed: Computed,
  datepicker: Date,
  email: Email,
  helptext: Help,
  hidden: Hidden,
  label: Label,
  location: Location,
  money: Money,
  multiselect: MultiSelect,
  numeric: Numeric,
  phone: Phone,
  role: Role,
  separator: Separator,
  singleselect: SingleSelect,
  tabs: Tabs,
  textbox: Text,
  textarea: TextArea,
  time: Time,
  title: Title,
  toggle: Toggle,
  user: User,
};
