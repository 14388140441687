import { gql } from '@apollo/client';

export const ADD_CATEGORY = gql`
  mutation addCategory($values: CategoryInput!) {
    addCategory(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($values: UpdateCategoryInput!) {
    updateCategory(values: $values) {
      id
      name
      description
      color
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;
