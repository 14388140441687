import trackEvent from '../trackEvent';

export const mfaRegistrationBegin = (traits = {}) => {
  trackEvent('Settings - MFA - Registration Begin', traits, true);
};

export const mfaRegistrationComplete = (traits = {}) => {
  trackEvent('Settings - MFA - Registration Complete', traits, true);
};

export const mfaRegistrationError = (traits = {}) => {
  trackEvent('Settings - MFA - Registration Error', traits, true);
};

export const mfaDisabled = (traits = {}) => {
  trackEvent('Settings - MFA - Disabled', traits, true);
};

export const referralsEnabled = (traits = {}) => {
  trackEvent('Settings - Referrals - Enabled', traits, true);
};

export const referralsCodeCopied = (traits = {}) => {
  trackEvent('Settings - Referrals - Code Copied', traits, true);
};

export const referralsCodeShared = (traits = {}) => {
  trackEvent('Settings - Referrals - Code Shared', traits, true);
};

export const orderUpdated = (traits = {}) => {
  trackEvent('Settings - Verifile - Checks Order Updated', traits, true);
};

export const updateOrderClicked = (traits = {}) => {
  trackEvent('Settings - Verifile - Update Checks Order Clicked', traits, true);
};
