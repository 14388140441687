import trackEvent from '../trackEvent';

export const sectionCountClicked = (traits = {}) => {
  trackEvent('Documents - Section Count Clicked', traits, true);
};

export const uploadClicked = (traits = {}) => {
  trackEvent('Documents - Upload Clicked', traits, true);
};

export const fileDropped = (traits = {}) => {
  trackEvent('Documents - File Dropped', traits, true);
};

export const fileUploaded = (traits = {}) => {
  trackEvent('Documents - File Uploaded', traits);
};

export const sectionChanged = (traits = {}) => {
  trackEvent('Documents - Section Changed', traits, true);
};

export const fileDeleted = (traits = {}) => {
  trackEvent('Documents - File Deleted', traits, true);
};

export const filtered = (traits = {}) => {
  trackEvent('Documents - Filtered', traits, true);
};

export const fileOpened = (traits = {}) => {
  trackEvent('Documents - File Opened', traits);
};

export const confidentialChanged = (traits = {}) => {
  trackEvent('Documents - Confidential Changed', traits, true);
};
