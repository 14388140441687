import { NonIdealState } from '@blueprintjs/core';
// @ts-ignore
import { ListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import styles from './styles.module.css';

type Role = { id: string; name: string };
type Organisation = { id: string; name: string; roles: Role[] };
type User = {
  id: string;
  name: string;
  email: string;
  organisations: Organisation[];
};

type UserItemProps = { item: User };
export const UserItem = ({ item: user }: UserItemProps) => {
  // TODO: User SSO/MFA/Password status
  // TODO: User last login
  // TODO: Sort order? Or filter?

  return (
    <div key={user.id} className={styles.userContainer}>
      <div className="flex-1" style={{ minWidth: '200px' }}>
        <div className={styles.userName}>{user.name}</div>
        <div className={styles.userEmail}>{user.email}</div>
      </div>
      <div>
        {user.organisations.map((o, oindex) => {
          return (
            <div
              className={oindex < user.organisations.length - 1 ? 'mb-2' : ''}
            >
              <div className={styles.organisationName}>{o.name}</div>
              <div className={styles.userOrganisationRoles}>{`${o.roles
                .map((r) => r.name)
                .join(' | ')}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type UsersListProps = { users: User[] };
export const UsersList = ({ users }: UsersListProps) => {
  const { t } = useTranslation();

  if (!users || users.length === 0) {
    return <NonIdealState icon="zoom-out" title={t('No Users found')} />;
  }
  return (
    <ListView
      className="w-100 h-100"
      rows={users}
      component={UserItem}
      spreadRowItem={false}
    />
  );
};
