import OrganisationsListWidget from '@/components/Dashboards/Widgets/OrganisationsList';
import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import {
  AnalyticsContext,
  FederationContext,
  OrganisationContext,
} from '@/context';
import { useQuery } from '@/hooks';
import { DATASOURCE_QUERY_FEDERATIONORGANISATIONS } from '@/queries';
import { useForms } from '@hogwarts/ui-components-forms';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const OrganisationsListContainer = ({
  settings,
  onSettingsUpdate,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const federation = useContext(FederationContext);

  const {
    loading,
    data: organisations,
    error,
  } = useQuery(DATASOURCE_QUERY_FEDERATIONORGANISATIONS, {
    skip: !federation,
    selector: 'queryFederationOrganisations.result',
    variables: {
      federationKey: federation?.key,
      tags: Object.keys(settings.includedTags || {})
        .map((tagKey) => (settings.includedTags[tagKey] ? tagKey : null))
        .filter(Boolean),
      // profileTypes: Object.keys(settings.includedProfileTypes || {})
      //   .map(
      //     (profileTypeKey) =>
      //       settings.includedProfileTypes[profileTypeKey]
      //         ? profileTypeKey
      //         : null,
      //   )
      //   .filter(Boolean),
    },
    pollInterval: organisation.demo ? 20000 : 60000,
  });

  const [showForm] = useForms({
    title: 'Widget Settings',
    saveText: 'Save',
    savingText: 'Saving',
    savedText: 'Saved',
    successToastMessage: 'Widget Updated',
    onSave: onSettingsUpdate,
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: 'Title',
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'showRatings',
        type: 'checkbox',
        label: 'Show Ratings',
        large: false,
      },
      {
        key: 'showRatingPercentages',
        type: 'checkbox',
        label: 'Show Rating Percentages',
        large: false,
      },
      {
        type: 'separator',
      },
      {
        key: 'includedTags',
        type: 'checklist',
        label: 'Include Tags',
        selectionMinimum: 1,
        values: organisation.scheme.tags.map((tag) => ({
          id: tag.key,
          value: tag.label,
        })),
      },
      // {
      //   type: 'separator',
      // },
      // {
      //   key: 'includedProfileTypes',
      //   type: 'checklist',
      //   label: 'Include Profile Types',
      //   showSelectAll: true,
      //   selectionMinimum: 1,
      //   values: organisation.scheme.profileTypes.map((pt) => ({
      //     id: pt.key,
      //     value: pt.label,
      //   })),
      // },
    ],
    initialValues: settings,
  });

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={error}
      onSettingsClick={onSettingsUpdate ? () => showForm() : null}
      {...rest}
    >
      <OrganisationsListWidget
        currentOrganisationKey={organisation.key}
        organisations={organisations}
        showRatingPercentages={settings.showRatingPercentages}
        showRatings={settings.showRatings}
        onOrganisationSelected={(organisationKey) => {
          analytics.events.dashboard.widget.organisationList.organisationSelected(
            {
              organisationKey,
            }
          );
          // take the query params!
          history.push(`/${organisationKey}${location.search}`);
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'organisations-list',
  name: 'Organisations List',
  requiresFederation: true,
  description:
    'Displays a list of organisations available to you and lets you switch between them.',
  widgetComponent: OrganisationsListContainer,
  defaultSettings: (scheme) => ({
    title: 'Organisations List',
    showRatings: true,
    showPercentages: false,
    includedTags: {
      active: true,
      onboarding: true,
    },
    includedProfileTypes: null,
  }),
};
