import { Button, Intent } from '@blueprintjs/core';
import { ControlBuilder } from '@hogwarts/ui-components-forms';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid/v4';

const getUniqueKey = (prefix: string): string =>
  `${prefix || ''}${uuid().replace('-', '')}`;

interface ValidationRule {
  key: string;
  label: string;
  type: string;
  params: Record<string, any>;
  deleted: boolean;
}
interface ValidationRuleProps {
  validationRules: ValidationRule[];
  onChange: (values: Record<string, any>) => void;
  disabled: boolean;
  rule: any;
}
const ValidationRuleItem = ({
  validationRules,
  rule: initialValues,
  disabled,
  onChange,
}: ValidationRuleProps) => {
  const [currentValues, setCurrentValues] = useState<any>(initialValues);

  const selectedRule = validationRules?.find(
    (r) => r.key === currentValues.rule
  );

  let extraFields: any[] = [];
  if (selectedRule?.params) {
    extraFields = Object.keys(selectedRule.params).reduce(
      (prev, key) =>
        [
          ...prev,
          {
            key,
            ...selectedRule.params[key],
          },
        ] as any,
      []
    );
  }

  const fields = [
    {
      key: 'key',
      type: 'hidden',
    },
    {
      key: 'type',
      type: 'hidden',
    },
    {
      key: 'rule',
      type: 'singleselect',
      valueField: 'id',
      textField: 'label',
      values: validationRules.map((r) => ({
        id: r.key,
        label: r.label || r.key,
      })),
    },
    ...extraFields,
    // {
    //   key: 'dismissable',
    //   type: 'checkbox',
    //   label: 'Can be dismissed',
    //   defaultValue: selectedRule?.dismissable,
    //   visible: !!selectedRule,
    // },
    // {
    //   key: 'failRatings',
    //   type: 'checkbox',
    //   label: 'Fails scoring',
    //   defaultValue: selectedRule?.failRatings,
    //   visible: !!values.rule,
    // },
    // {
    //   key: 'failInput',
    //   type: 'checkbox',
    //   label: 'Fails Input',
    //   defaultValue: selectedRule?.failInput,
    //   visible: !!values.rule,
    // },
    // {
    //   key: 'severity',
    //   type: 'singleselect',
    //   label: 'Severity',
    //   defaultValue: selectedRule?.severity,
    //   values: [
    //     {
    //       id: 'info',
    //       value: 'Information',
    //     },
    //     {
    //       id: 'warning',
    //       value: 'Warning',
    //     },
    //     {
    //       id: 'error',
    //       value: 'Error',
    //     },
    //   ],
    //   visible: !!selectedRule,
    // },
  ];

  // console.log({
  //   currentValues,
  //   fields,
  //   initialValues,
  // });

  return (
    <div className="d-flex flex-column flex-grow-1">
      <ControlBuilder
        readOnly={disabled}
        fields={fields}
        initialValues={initialValues}
        onValuesChanged={(values: any) => {
          // detect any extra fields and add them?
          setCurrentValues(values);
          if (onChange) {
            onChange(values);
          }
        }}
      />
      {/* <Diagnostics item={values} /> */}
    </div>
  );
};

interface ValidationRulesProps {
  validationRules: ValidationRule[];
  disabled: boolean;
  onChange: (values: Record<string, any>) => void;
  selectedRules: any[];
}
export const ValidationRules = ({
  validationRules,
  selectedRules: initialSelectedRules,
  disabled,
  onChange,
}: ValidationRulesProps) => {
  const { t } = useTranslation();

  const [currentRules, setCurrentRules] = useState<
    { key: string; deleted?: boolean }[]
  >((initialSelectedRules || []).filter((rule) => !rule.deleted));

  const updatedRules = (rules: any) => {
    setCurrentRules(rules);
    onChange(rules);
  };

  const addRule = () => {
    updatedRules([
      ...currentRules,
      {
        key: getUniqueKey('vrule_'),
        type: 'rule',
        rule: null,
      },
    ]);
  };
  const deleteRule = (ruleKey: string) => {
    updatedRules(currentRules.filter((r) => r.key !== ruleKey));
  };
  const ruleChanged = (index: number, changedRule: any) => {
    const rules = [...currentRules];
    rules[index] = changedRule;
    updatedRules(rules);
  };
  return (
    <div style={{ width: '100%' }} className={'w-100 flex-column'}>
      {currentRules.length > 0
        ? currentRules.map((ruleItem, index: number) => {
            const last = index === currentRules.length - 1;
            return (
              <div key={ruleItem.key}>
                <div className="d-flex flex-row flex-grow-1">
                  <ValidationRuleItem
                    validationRules={validationRules}
                    onChange={(changedRule) => {
                      ruleChanged(index, changedRule);
                    }}
                    disabled={disabled}
                    rule={ruleItem}
                  />
                  {
                    <Button
                      disabled={disabled}
                      minimal={false}
                      icon="trash"
                      intent={Intent.DANGER}
                      onClick={() => deleteRule(ruleItem.key)}
                    />
                  }
                </div>
                {!last && (
                  <p className="m-1 font-weight-bold text-center">And</p>
                )}
              </div>
            );
          })
        : validationRules.length > 0 && (
            <Button disabled={disabled} icon="add" onClick={addRule}>
              {t('Add a Rule')}
            </Button>
          )}

      {/* Adding Multi rules are currently disabled */}
      {/* {validationRules.length > 0 &&
        currentRules.length > 0 && (
          <Button
            disabled={disabled}
            icon="add"
            className={'mt-2'}
            onClick={addRule}
          >
            {t('Add another Rule')}
          </Button>
        )}

      <ReactJson
        name={false}
        collapsed={2}
        src={{
          currentRules,
          validationRules,
        }}
      /> */}
    </div>
  );
};
