import defaultImage from '@/assets/profile-default.png';
import { useAlert } from '@/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SecureImage from '../../SecureImage';
import styles from './styles.module.css';

interface ProfilePictureProps {
  image: string;
  imageMeta?: any;
  allowEdit: boolean;
  onEdit: (profile?: any) => void;
  isDragActive?: boolean;
  dropzoneClassName?: string;
  onDelete: () => void;
}

const ProfilePicture = ({
  image,
  allowEdit,
  onEdit,
  isDragActive,
  dropzoneClassName,
  onDelete,
}: ProfilePictureProps) => {
  const { t } = useTranslation();
  const [showFunctions, setShowFunctions] = useState(false);
  const [ConfirmDeleteAlert, showConfirmDeleteAlert] = useAlert({
    icon: 'trash',
    intent: 'danger',
    confirmButtonText: t('Delete'),
    cancelButtonText: t('Cancel'),
    Content: () => (
      <p>
        {t(
          'This will permanently delete this profile picture, are you sure you wish to continue?'
        )}
      </p>
    ),
    onConfirm: () => onDelete(),
  });

  return !isDragActive || !allowEdit ? (
    <>
      <ConfirmDeleteAlert />
      <div
        className={styles.profileTopContainer}
        onMouseEnter={() => setShowFunctions(allowEdit && true)}
        onMouseLeave={() => setShowFunctions(false)}
        onTouchEnd={() => {
          if (allowEdit && onEdit) {
            return onEdit();
          }
        }}
      >
        <div className={styles.profileImageContainer}>
          <div className={styles.profileContainer}>
            <SecureImage
              className={styles.profileImage}
              fallbackSrc={defaultImage}
              src={image}
              noLoading
            />
            {allowEdit && showFunctions && (
              <div className={styles.editButton}>
                <Button icon="camera" onClick={onEdit} />
                {image && (
                  <Button
                    intent="danger"
                    icon="trash"
                    onClick={showConfirmDeleteAlert}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={cn(styles.dropzone, dropzoneClassName)}>
      <FontAwesomeIcon icon={['far', 'upload']} size="2x" />
      <p className={styles.dropzoneText}>{`Drop new photo here`}</p>
    </div>
  );
};

export default ProfilePicture;
