import React from 'react';

import VisibilityAndRatings from '@/components/SchemeEditor/VisibilityAndRatings';

import { schemePatcher, schemeHelperFactory } from '@hogwarts/scheme-profiles';

const getSectionData = (schemeData, sectionKey) => {
  if (!schemeData) return {};
  const { profileTypes, ratingSystems, sections } = schemeData;
  return {
    profileTypes,
    ratingSystems,
    sections: {
      [sectionKey]: {
        ...(sections && sections[sectionKey]),
      },
    },
  };
};

const buildMetaData = (scheme, allProfileTypes, sectionKey, values) => {
  let layers = [];
  for (const part of scheme.parts) {
    const layer = {
      ...part,
    };
    layer.data = {
      ...getSectionData(layer.data, sectionKey),
      profileTypeSchemes: {
        ...allProfileTypes.reduce(
          (prev, profileType) => ({
            ...prev,
            [profileType.key]: getSectionData(
              layer.data.profileTypeSchemes &&
                layer.data.profileTypeSchemes[profileType.key],
              sectionKey
            ),
          }),
          {}
        ),
      },
    };
    layers.push(layer);
  }

  const draftLayer = layers[layers.length - 1];
  draftLayer.data = schemePatcher(draftLayer.data, {
    ...values,
  });

  const scheme2 = schemeHelperFactory(layers);

  const result = {};
  for (const profileType of allProfileTypes) {
    const profileTypeScheme = scheme2.switchProfileType(profileType.key);
    result[profileType.key] = profileTypeScheme.getSection(sectionKey);
  }

  return result;
};

const Component = ({
  readOnly,
  profileTypes,
  fieldKey1: fieldKey,
  sectionKey,
  includePathPrefix,
  formik,
  scheme,
}) => {
  let values = {};

  const metaData = buildMetaData(
    scheme,
    profileTypes,
    sectionKey,
    formik.values
  );

  const pathPrefix = (profileTypeKey, path) => {
    if (!path) throw new Error('Youve messed up');
    // TODO: We dont want to prefix this if we're Viewing a Profile Type!
    if (!includePathPrefix) {
      return path;
    }
    return `profileTypeSchemes.${profileTypeKey}.${path}`;
  };

  for (const profileType of profileTypes) {
    const profileTypeMetaData = metaData[profileType.key];
    if (!profileTypeMetaData) continue;

    const locked = profileTypeMetaData.meta.enabled.locked;
    const enabled = profileTypeMetaData.enabled;
    const lock = profileTypeMetaData.lock.enabled;

    values[profileType.key] = {
      enabled,
      lock,
      locked,
    };
  }

  const onEnabledChangeHandler = ({ profileTypeKey, enabled }) => {
    if (!profileTypeKey) return;
    const profileTypeFieldMeta = metaData[profileTypeKey];
    const path = pathPrefix(
      profileTypeKey,
      profileTypeFieldMeta.meta.enabled.path
    );
    formik.setFieldValue(path, enabled);
    formik.setFieldTouched(path);
  };

  const onLockChangeHandler = ({ profileTypeKey, lock }) => {
    if (!profileTypeKey) return;
    const profileTypeFieldMeta = metaData[profileTypeKey];
    const path = pathPrefix(
      profileTypeKey,
      profileTypeFieldMeta.meta.enabled.lockpath
    );
    formik.setFieldValue(path, lock);
    formik.setFieldTouched(path);
  };

  return (
    <VisibilityAndRatings
      onEnabledChange={onEnabledChangeHandler}
      onLockChange={onLockChangeHandler}
      values={values}
      profileTypes={profileTypes}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'Section Visibility',
  options: [],
  Component,
};
