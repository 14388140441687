import * as fragments from '../fragments';

import { gql } from '@apollo/client';
import { DateTime } from 'luxon';

export const GET_USER_ORGANISATIONS = gql`
  query getUserOrganisations {
    organisations {
      id
      key
      name
      enabled
      location
      timezone
      attributes
      federation {
        id
        key
        name
      }
    }
  }
`;

export const GET_ORGANISATION_IMPORT_ID = gql`
  query getOrganisationImportId($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      currentImportId
    }
  }
`;

export const GET_ORGANISATION_DETAIL = gql`
  ${fragments.DATA_PROVIDER}
  query getOrganisationDetail($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      key
      name
      enabled
      location
      timezone
      attributes
      availableAttributes {
        id
        key
        name
        dataType
        inputType
        scopes
        data
        owner {
          type
        }
      }
      branding
      features {
        id
        cacheKey
        enabled
      }
      scheme {
        id
        version
        cacheKey
        data
      }
      federation {
        id
        key
        name
      }
      groups {
        id
        name
        enabled
      }
      roles {
        id
        name
        permissions {
          key
          description
        }
        featureKey
        category
        resourceType
        federation {
          id
          key
          name
          branding
        }
      }
      provider {
        ...ProviderParts
      }
      siaConnection {
        id
        ownerType
        ownerId
        connected
        configurationOptions
        enabled
      }
    }
  }
`;

export const GET_ORGANISATION_PROVIDER = gql`
  ${fragments.DATA_PROVIDER}
  query getOrganisationProvider($organisationKey: String) {
    organisations(key: $organisationKey) {
      id
      provider {
        ...ProviderParts
      }
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query getOrganisation($organisationKey: String) {
    organisations(key: $organisationKey) {
      id
      key
      name
      enabled
      location
      timezone
      attributes
      federation {
        id
        key
        name
        branding
      }
      # groups {
      #   id
      #   name
      #   enabled
      # }
      # roles {
      #   id
      #   name
      #   permissions {
      #     key
      #     description
      #   }
      #   featureKey
      #   category
      #   system
      #   resourceType
      #   federation {
      #     id
      #     key
      #     name
      #     branding
      #   }
      # }
      # provider {
      #   cacheKey
      #   key
      #   connected
      #   status
      # messages {
      #   label
      #   value
      # }
      # fieldMap {
      #   source
      #   target
      #   readonly
      #   transform
      # }
      # sourceFields {
      #   key
      #   dataType
      # }
      # }
    }
  }
`;

export interface GET_ORGANISATION_USERSResponse {
  id: string;
  cacheKey: string;
  name: string;
  email: string;
  enabled: boolean;
  mfaEnabled: boolean;
  lastAuth?: DateTime;
  lastEmail?: {
    status: string;
    statusDate: DateTime;
    statusReason: string;
  };
  roles: {
    id: string;
    name: string;
  };
}
export const GET_ORGANISATION_USERS = gql`
  query getOrganisationUsers($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      key
      name
      enabled
      users {
        id
        cacheKey
        name
        email
        enabled
        mfaEnabled
        lastAuth
        lastEmail {
          status
          statusDate
          statusReason
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_ORGANISATION_ROLES = gql`
  query getOrganisationRoles($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      key
      roles {
        id
        name
        permissions {
          key
          description
        }
        system
        featureKey
        category
        resourceType
        selectable
        federation {
          id
          key
          name
          branding
        }
      }
    }
  }
`;

export const GET_ORGANISATION_AUDIT = gql`
  query getOrganisationAudit(
    $organisationKey: String!
    $skip: Int!
    $limit: Int!
  ) {
    organisations(key: $organisationKey) {
      id
      key
      audit(skip: $skip, limit: $limit) {
        action
        section
        date
        inputs
        profile {
          id
          name
        }
        user {
          id
          cacheKey
          name
          email
        }
      }
    }
  }
`;

export const GET_ORGANISATION_SIAGROUPS = gql`
  query getOrganisationSiaGroups($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      key
      siaGroups {
        id
        name
        type
      }
    }
  }
`;

export const GET_SIA_IMPORT_STATUS = gql`
  query getSiaImportStatus($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      siaImportStatus {
        busy
        status {
          label
          value
        }
      }
    }
  }
`;

export const GET_ORGANISATION_ACTIVITY_ATTRIBUTES = gql`
  query getOrganisationIncidentAttributes($organisationKey: String!) {
    organisations(key: $organisationKey) {
      id
      name
      tags {
        id
        name
        description
        color
      }
      categories {
        id
        name
        description
        color
      }
      severities {
        id
        name
        description
        color
      }
      statuses {
        id
        name
        description
        color
      }
    }
  }
`;
