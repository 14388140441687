import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.css';

function H3({ className, children }) {
  return <h3 className={cn(styles.h3, className)}>{children}</h3>;
}

H3.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default H3;
