import {
  Condition,
  ConditionGroup,
  isConditionGroup,
  isVariable,
  Variables,
} from '@hogwarts/conditionals';
import { get } from 'lodash';

const VALUE_FIELD = 'key';
const TEXT_FIELD = 'label';

interface Field {
  key: string;
  type: string;
  label: string;
  valueField: string;
  textField: string;
  values: { id: string; label: string };
  readOnly?: boolean;
  meta?: any;
  visible?: boolean;
  defaultValue?: unknown;
}

interface Builder {
  add: (field: Field) => void;
}

const convertVariable = (possibleVariable: any, variables: Variables) => {
  if (!isVariable(possibleVariable)) {
    return {
      source: 'value',
      value: possibleVariable,
    };
  }

  if (!variables.hasOwnProperty(possibleVariable)) {
    return null;
  }
  let value = variables[possibleVariable];

  const type = get(value, 'type');
  const source = get(value, 'source');

  if (!Array.isArray(source)) {
    return null;
  }

  return {
    value: [...source, type].join('/'),
    source: source[0],
  };
};

const convertParameters = (
  items: Record<string, any>,
  variables: Variables
) => {
  return Object.keys(items || {}).reduce((prev, cur) => {
    const converted = convertVariable(items[cur], variables);
    if (!converted) {
      return prev;
    }
    const { value, source } = converted;
    return {
      ...prev,
      [cur]: value,
      [`${cur}_source`]: source,
    };
  }, {});
};

export const convertFunction = (value: any, variables: Variables) => {
  if (typeof value === 'undefined') {
    return [];
  }

  if (typeof value === 'string') {
    return [value, {}];
  }

  if (!Array.isArray(value)) {
    // broken condition
    debugger;
    return [];
  }

  let [functionName, functionParams] = value;

  return [functionName, convertParameters(functionParams, variables)];
};

// const convertOperator = (value?: string): string => {
//   if (!value) return 'and';
//   return value;
// };

const seperateVariable = (value?: string): [string?, Variables?] => {
  if (value == null) {
    return [];
  }
  const split = value.split('/');
  if (split.length === 3) {
    // TODO: Should get the suffix from library
    const variableName = `${split[1]}$`;
    return [
      variableName,
      {
        [variableName]: {
          type: split[2],
          source: [split[0], split[1]],
        },
      },
    ];
  }
  return [value];
};

const inflateFunction = (
  name: string,
  func?: string | [string, Record<string, any>]
) => {
  if (func == null) return [];

  if (typeof func === 'string') {
    func = [func, {}];
  }

  let [functionName, functionParams] = func;

  if (functionName == null) return [];

  let sourceSuffix = '_source';
  let paramsResult = {};
  let variableResult: Record<string, any> = {};
  for (const key of Object.keys(functionParams || {}).filter(
    (k) => !k.endsWith(sourceSuffix)
  )) {
    let source = functionParams[`${key}${sourceSuffix}`];
    if (source == null) {
      source = 'value';
    }

    const value = functionParams[key];

    switch (source) {
      case 'value': {
        paramsResult = {
          ...paramsResult,
          [key]: value,
        };
        continue;
      }
      default: {
        const [variableValue, variableValueBlock] = seperateVariable(value);
        paramsResult = {
          ...paramsResult,
          [key]: variableValue,
        };
        variableResult = {
          ...variableResult,
          ...variableValueBlock,
        };
      }
    }
  }

  return [
    {
      [name]: [functionName, paramsResult],
    },
    variableResult,
  ];
};

const cleanConditionGroupInternal = (
  group: ConditionGroup
): ConditionGroup | null => {
  const result: ConditionGroup = { ...group, conditions: [] };
  for (let condition of group.conditions) {
    if (isConditionGroup(condition)) {
      const cleanGroup = cleanConditionGroupInternal(condition);
      if (cleanGroup && cleanGroup.conditions?.length === 0) {
        continue;
      }
    }
    result.conditions.push(condition);
  }
  if (result.conditions.length) {
    return result;
  }
  return null;
};

export const cleanConditionGroup = (group: ConditionGroup) => {
  const result: ConditionGroup = {
    ...group,
    conditions: [],
  };
  for (const condition of group.conditions) {
    if (isConditionGroup(condition)) {
      const cleaned = cleanConditionGroupInternal(condition);
      if (cleaned && cleaned.conditions.length) {
        result.conditions.push(cleaned);
      }
    } else {
      result.conditions.push(condition);
    }
  }

  return result;
};

export interface FlatCondition {
  key: string;
  when?: string;
  when_source?: string;
  compute?: any;
  comparison?: any;
  group?: ConditionGroup;
}
export const inflateCondition = (
  flat: FlatCondition
): [Condition, Variables] => {
  if (flat.group) {
    throw new Error('Cannot inflate ConditionGroup');
  }

  const [whenValue, whenVariables] = seperateVariable(flat.when);

  let variableResult = { ...whenVariables };
  let valueResult: Condition = { when: whenValue! };

  for (const functionName of ['compute', 'comparison']) {
    // @ts-ignore
    const func = flat[functionName];
    const [values, variables] = inflateFunction(functionName, func);
    if (values != null) {
      variableResult = {
        ...variableResult,
        ...variables,
      };
      valueResult = {
        ...valueResult,
        ...values,
      };
    }
  }
  return [valueResult, variableResult];
};

export const flattenCondition = (
  condition: Condition,
  variables: Variables
): Omit<FlatCondition, 'key'> => {
  const convertedWhen = convertVariable(condition.when, variables);
  const comparison = convertFunction(condition.comparison, variables);
  const compute = convertFunction(condition.compute, variables);
  // const operator = convertOperator(condition.operator);
  return {
    when: convertedWhen?.value,
    when_source: convertedWhen?.source,
    comparison,
    compute,
    // operator,
  };
};

const selectOptions = (values: any, valueField = 'id', textField = 'value') => {
  if (typeof values === 'string') {
    return values
      .split('\n')
      .filter(Boolean)
      .map((value) => ({
        [valueField]: value,
        [textField]: value,
      }));
  }

  if (Array.isArray(values)) {
    if (values.length > 0 && typeof values[0] === 'string') {
      return values.map((value) => ({
        [valueField]: value,
        [textField]: value,
      }));
    }

    return values;
  }
};

const booleanComparison = {
  key: 'comparison[0]',
  type: 'singleselect',
  values: [
    {
      key: 'isTrue',
      label: 'is selected',
    },
    {
      key: 'isFalse',
      label: 'is not selected',
    },
  ],
};

const commonStringComparisonValues = [
  {
    key: 'contains',
    label: 'contains',
    next: {
      key: 'comparison[1].value',
      type: 'textbox',
      options: {
        placeHolder: 'Enter partial value to match (not case sensitive)',
      },
    },
  },
  {
    key: 'notContains',
    label: 'does not contain',
    next: {
      key: 'comparison[1].value',
      type: 'textbox',
      options: {
        placeHolder:
          'Enter partial value to exclude from match (not case sensitive)',
      },
    },
  },
  {
    key: 'startsWith',
    label: 'starts with',
    next: {
      key: 'comparison[1].value',
      type: 'textbox',
      options: {
        placeHolder: 'Enter partial value to match (not case sensitive)',
      },
    },
  },
  {
    key: 'endsWith',
    label: 'ends with',
    next: {
      key: 'comparison[1].value',
      type: 'textbox',
      options: {
        placeHolder: 'Enter partial value to match (not case sensitive)',
      },
    },
  },
  {
    key: 'isNull',
    label: 'is empty',
  },
  {
    key: 'isNotNull',
    label: 'is not empty',
  },
];

const oneOfComparison = (values: any) => ({
  key: 'comparison[0]',
  type: 'singleselect',
  values: [
    {
      key: 'equals',
      label: 'is equal to',
      next: {
        key: 'comparison[1].value',
        type: 'singleselect',
        values,
        options: {
          valueField: 'id',
          textField: 'value',
        },
      },
    },
    {
      key: 'notEqual',
      label: 'is not equal to',
      next: {
        key: 'comparison[1].value',
        type: 'singleselect',
        values,
        options: {
          valueField: 'id',
          textField: 'value',
        },
      },
    },
    {
      key: 'oneOf',
      label: 'is one of',
      next: {
        key: 'comparison[1].values',
        type: 'multiselect',
        values,
        options: {
          valueField: 'id',
          textField: 'value',
        },
      },
    },
    {
      key: 'notOneOf',
      label: 'is not one of',
      next: {
        key: 'comparison[1].values',
        type: 'multiselect',
        values,
        options: {
          valueField: 'id',
          textField: 'value',
        },
      },
    },
    ...commonStringComparisonValues,
  ],
});

const stringComparison = {
  key: 'comparison[0]',
  type: 'singleselect',
  values: [
    {
      key: 'equals',
      label: 'is equal to',
      next: {
        key: 'comparison[1].value',
        type: 'textbox',
        options: {
          placeHolder: 'Enter expected value (not case sensitive)',
        },
      },
    },
    {
      key: 'notEqual',
      label: 'is not equal to',
      next: {
        key: 'comparison[1].value',
        type: 'textbox',
        options: {
          placeHolder: 'Enter value (not case sensitive)',
        },
      },
    },
    ...commonStringComparisonValues,
  ],
};

const numberComparison = {
  key: 'comparison[0]',
  type: 'singleselect',
  values: [
    {
      key: 'equals',
      label: 'is equal to',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter expected value',
        },
      },
    },
    {
      key: 'notEqual',
      label: 'is not equal to',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter value',
        },
      },
    },

    {
      key: 'greaterThan',
      label: 'is more than',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter value',
        },
      },
    },
    {
      key: 'greaterThanOrEqual',
      label: 'is more or equal to',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter value',
        },
      },
    },

    {
      key: 'lessThan',
      label: 'is less than',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter value',
        },
      },
    },
    {
      key: 'lessThanOrEqual',
      label: 'is less or equal to',
      next: {
        key: 'comparison[1].value',
        type: 'numeric',
        options: {
          placeHolder: 'Enter value',
        },
      },
    },

    {
      key: 'isNull',
      label: 'is empty',
    },
    {
      key: 'isNotNull',
      label: 'is not empty',
    },
  ],
};

const dateComparison = ({ fieldList, options }: any) => ({
  key: 'comparison[0]',
  type: 'singleselect',
  values: [
    {
      key: 'isDuring',
      label: 'is',
    },
    {
      key: 'isNotDuring',
      label: 'is not',
    },
    {
      key: 'laterThan',
      label: 'is later than',
    },
    {
      key: 'earlierThan',
      label: 'is earlier than',
    },
    {
      key: 'exactlyOrLaterThan',
      label: 'is exactly or later than',
    },
    {
      key: 'exactlyOrEarlierThan',
      label: 'is exactly or earlier than',
    },
    {
      key: 'isNull',
      label: 'is empty',
      next: false,
    },
    {
      key: 'isNotNull',
      label: 'is not empty',
      next: false,
    },
  ],
  next: {
    key: 'comparison[1]',
    type: 'relativedate',
    values: fieldList,
    options,
  },
});

interface Structure {
  key: string;
  label: string;
  type: string;
  options: any;
  values: any;
  next: ({
    values,
    previous,
    builder,
  }: {
    values: any;
    previous: any;
    builder: Builder;
  }) => any;
}

interface GroupItem {
  key: string;
  label: string;
}
interface FieldItem {
  sectionKey?: string;
  key: string;
  label: string;
  dataType: string;
  inputType: string;
  values?: any[];
  icon?: any;
}

export const fieldStructureFactory = (
  fieldList: FieldItem[],
  groupList?: GroupItem[]
): Structure => {
  return {
    key: 'when',
    label: 'Select Field',
    type: 'singleselect',
    options: {
      groupKey: groupList ? 'sectionKey' : null,
      groups: groupList,
    },
    values: fieldList,

    next({ values, previous, builder }) {
      if (!previous) return;
      switch (previous.dataType) {
        case 'date': {
          return dateComparison({
            options: {
              groupKey: groupList ? 'sectionKey' : null,
              groups: groupList,
            },
            fieldList: fieldList.filter((f) => f.dataType === 'date'),
          });
        }
        case 'boolean': {
          return booleanComparison;
        }
        case 'string': {
          if (previous.values) {
            return oneOfComparison(selectOptions(previous.values));
          }
          return stringComparison;
        }
        case 'number': {
          return numberComparison;
        }
        default:
        case 'array': {
          return null;
        }
      }
    },
  };
};

const parseStructure = (
  current: Structure,
  {
    values,
    builder,
  }: {
    values: any;
    builder: Builder;
  }
) => {
  let selectedValue = get(values, current.key);

  let lookupValues = current.values;
  if (!Array.isArray(lookupValues)) {
    lookupValues = null;
  } else if (lookupValues.find((l) => l.error)) {
    lookupValues = lookupValues.filter((l) => !l.error);
  }

  if (lookupValues && typeof selectedValue !== 'undefined') {
    const foundValue = lookupValues.find((v: any) => v.key === selectedValue);
    if (typeof foundValue === 'undefined') {
      // problem!
      selectedValue = {
        key: selectedValue,
        label: `${selectedValue} (Invalid Choice)`,
        error: true,
      };
      lookupValues.push(selectedValue);
    } else {
      selectedValue = foundValue;
    }
  }

  builder.add({
    key: current.key,
    type: current.type,
    label: current.label,
    values: lookupValues,
    valueField: VALUE_FIELD,
    textField: TEXT_FIELD,
    ...current.options,
  });

  if (typeof selectedValue === 'undefined') {
    return;
  }

  let next = selectedValue?.next;
  if (next !== false && !next) next = current.next;
  if (typeof next === 'function') {
    next = next({
      previous: selectedValue,
      values,
      builder,
    });
  }

  if (next) {
    parseStructure(next, { values, builder });
  }
};

export const getFieldList = (structure: Structure, values: any): Field[] => {
  const fields: Field[] = [
    // {
    //   key: 'compute',
    //   type: 'hidden',
    // },
  ];

  const builder: Builder = {
    add(field: Field) {
      fields.push(field);
    },
  };

  if (structure) {
    parseStructure(structure, { values, builder });
  }

  return fields;
};
