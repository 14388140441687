import React from 'react';
import TabWrapper from './TabWrapper';
import RatingSystemButton from '../RatingSystemButton';
import SortableList from '../SortableList';

const RatingSystemsTab = ({
  ratingSystems,
  selectedRatingSystem,
  onRatingSystemSelected,
  onRatingSystemOrderUpdate,
  onRatingSystemRequestEdit,
}) => {
  return (
    <>
      <SortableList
        items={ratingSystems.map((ratingSystem) => ({
          component: RatingSystemButton,
          key: ratingSystem.key,
          name: ratingSystem.label,
          readyColor: ratingSystem.readyColor,
          notReadyColor: ratingSystem.notReadyColor,
          ratingSystemDisabled: !ratingSystem.enabled,
          selected: ratingSystem.key === selectedRatingSystem?.key,
          onClick: () => onRatingSystemSelected(ratingSystem.key),
          onDoubleClick: () => onRatingSystemRequestEdit(ratingSystem.key),
        }))}
        onOrderUpdate={({ oldIndex, newIndex }) => {
          const movedKey = ratingSystems[oldIndex].key;
          const bumpedKey = ratingSystems[newIndex].key;
          onRatingSystemOrderUpdate(movedKey, bumpedKey);
        }}
      />
    </>
  );
};

export default TabWrapper(RatingSystemsTab);
