import trackEvent from '../trackEvent';

export const saveConnectionClicked = (traits = {}) => {
  trackEvent('SIA - Save Connection Clicked', traits, true);
};

export const saveFieldMapClicked = (traits = {}) => {
  trackEvent('SIA - Save FieldMap Clicked', traits, true);
};

export const saveProfileTypeMapClicked = (traits = {}) => {
  trackEvent('SIA - Save ProfileTypeMap Clicked', traits, true);
};
