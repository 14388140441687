/* eslint-disable */
import React, { useMemo } from 'react';

import {
  ArrayParam,
  ObjectParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

export const StoreQueryParams = (FilterBar) => (props) => {
  const { filters, onFilter } = props;

  const queryParams = useMemo(() => {
    return filters.reduce((prev, filter) => {
      switch (filter.type) {
        case 'date':
          return {
            ...prev,
            [filter.id]: ArrayParam,
          };
        case 'multiselect':
          return {
            ...prev,
            [filter.id]: ObjectParam,
          };
        case 'select':
        default: {
          return {
            ...prev,
            [filter.id]: StringParam,
          };
        }
      }
    }, {});
  }, [filters]);

  const [filterState, setQuery] = useQueryParams(queryParams);

  const storage = useMemo(
    () => ({
      get(id) {
        return filterState[id];
      },
      getAll() {
        return filterState;
      },
      set(id, value) {
        const newState = { ...filterState, [id]: value };
        setQuery(newState);
        if (onFilter) onFilter(newState);
      },
      get hasState() {
        return !!Object.keys(filterState).filter(
          (key) => filterState[key] != null
        ).length;
      },
      clear(id) {
        let updatedQuery;
        if (id == null) {
          updatedQuery = filters.reduce(
            (prev, filter) => ({ ...prev, [filter.id]: undefined }),
            {}
          );
        } else {
          updatedQuery = { ...filterState, [id]: undefined };
        }
        setQuery(updatedQuery);
        if (onFilter) onFilter(updatedQuery);
      },
    }),
    [filters]
  );

  return <FilterBar storage={storage} {...props} />;
};
