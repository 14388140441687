import { Button as BPButton, Button, NonIdealState } from '@blueprintjs/core';
import { Currency } from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import FilterBar from '../../../components/Filters/Bar';
import { useDateFormatter } from '../../../hooks';
import { CheckStatusTag } from '../../CheckStatusTag';
import styles from './styles.module.css';

interface FilterItem {
  value: string;
  id: string;
}

interface Filter {
  type: string;
  id: string;
  icon?: string;
  description?: string;
  items: FilterItem[];
  loading?: boolean;
}

export interface CheckOrder {
  id: string;
  orderId: string;
  orderedAt: string;
  price: {
    value: number;
    currency: string;
  };
  checks: {
    id: string;
    name: string;
  }[];
  status: string;
  profileId?: string;
  purchaseOrder?: string;
}

export interface CheckOrdersProps {
  filters: Filter[];
  onFilter: (filter: FilterItem) => void;
  checkOrders: CheckOrder[];
  onShowProfileChecks: (profileId: string) => void;
  onOrderUpdate: (order: CheckOrder) => void;
  loadMore?: () => void;
  loadingMore?: boolean;
}

interface CheckTypesProps {
  checks: {
    id: string;
    name: string;
  }[];
}

interface CheckOrdersComponentProps {
  loadMore?: () => void;
  loadingMore?: boolean;
  checkOrders: CheckOrder[];
  onShowProfileChecks: (profileId: string) => void;
  onOrderUpdate: (order: CheckOrder) => void;
}

const CheckTypes = ({ checks }: CheckTypesProps) => {
  return (
    <div>
      {checks?.map((c, i) => {
        return <div key={`${i}-${c.id}`}>{`#${c.id} - ${c.name}`}</div>;
      })}
    </div>
  );
};

const CheckOrdersComponent = ({
  loadMore,
  loadingMore,
  checkOrders,
  onShowProfileChecks,
  onOrderUpdate,
}: CheckOrdersComponentProps) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  return (
    <div className={styles.container}>
      <table className="table table-striped m-0">
        <thead>
          <tr>
            <th>{t('Order Id')}</th>
            <th>{t('Order Date')}</th>
            <th>{t('Status')}</th>
            <th>{t('Checks')}</th>
            <th>{t('Price')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {checkOrders.map((order) => {
            return (
              <tr key={`order-${order.id}`}>
                <td>{order.orderId}</td>
                <td>{formatDate.medium(order.orderedAt)}</td>
                <td>
                  <CheckStatusTag status={order.status} />
                </td>
                <td>
                  <CheckTypes checks={order.checks} />
                </td>
                <td>
                  <Currency {...order.price} />
                </td>
                <td>
                  <Button
                    onClick={() => onOrderUpdate(order)}
                    className={styles.button}
                    icon="edit"
                  >
                    {t('Edit Order')}
                  </Button>
                  {order.profileId && (
                    <Button
                      onClick={() => {
                        onShowProfileChecks(order.profileId!);
                      }}
                    >
                      {t('View Profile')}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loadMore && (
        <div className="d-flex justify-content-center">
          <VisibilitySensor onChange={(isVisible) => isVisible && loadMore()}>
            {loadingMore ? (
              <BPButton className="mt-3" disabled>
                Loading more...
              </BPButton>
            ) : (
              <BPButton className="mt-3" onClick={loadMore}>
                Load More
              </BPButton>
            )}
          </VisibilitySensor>
        </div>
      )}
    </div>
  );
};

export const CheckOrders = ({
  onFilter,
  checkOrders,
  onShowProfileChecks,
  loadMore,
  loadingMore,
  onOrderUpdate,
  filters,
}: CheckOrdersProps) => {
  const { t } = useTranslation();
  return (
    <>
      <FilterBar filters={filters} onFilter={onFilter} />
      {checkOrders?.length ? (
        <CheckOrdersComponent
          checkOrders={checkOrders}
          onShowProfileChecks={onShowProfileChecks}
          loadMore={loadMore}
          loadingMore={loadingMore}
          onOrderUpdate={onOrderUpdate}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={t('No Orders')}
          description={
            <>
              {t(
                'Place an order by viewing a profile and selecting the Order Check button'
              )}
            </>
          }
        />
      )}
    </>
  );
};
