import { Button, Classes, Dialog } from '@blueprintjs/core';
import { Select } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';
import styles from './styles.module.css';

interface SelectionSelectionDialogProps {
  isOpen: boolean;
  onClose: any;
  sections: any;
  currentKey?: string;
  onSelected: any;
}
const SelectionSelectionDialog = ({
  isOpen,
  onClose,
  sections,
  currentKey,
  onSelected,
}: SelectionSelectionDialogProps) => {
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(currentKey);
  return (
    <Dialog
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      usePortal
      onClose={onClose}
      title={t('Section Selection')}
      isOpen={isOpen}
    >
      <div className={Classes.DIALOG_BODY}>
        {sections && (
          <>
            <p>
              <strong>
                {t('Please select the new section from the list below:')}
              </strong>
            </p>
            <div className="d-flex justify-content-center my-4">
              <Select
                className={styles.select}
                large={false}
                items={sections}
                value={selectedKey}
                valueField="key"
                textField="name"
                popoverProps={{
                  portalClassName: 'sectionSelector',
                  minimal: true,
                }}
                onChange={(section: any) => {
                  setSelectedKey(section.key);
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>{t('Close')}</Button>
          <Button
            intent="primary"
            disabled={!selectedKey}
            onClick={onSelected.bind(this, selectedKey)}
          >
            {t('Change')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectionSelectionDialog;
