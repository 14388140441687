export default {
  key: 'riskAssessmentClosed',
  label: 'Determines if the Risk Assessment is Closed or not',
  output: 'boolean',
  execute: ({ data }, params) => {
    // THIS IS A LEGACY FUNCTION

    let fprefix = 'ra_';

    const check = (prefix: string, person: string) => {
      if (
        data[`${fprefix}${prefix}_${person}_confirm`] &&
        data[`${fprefix}${prefix}_${person}_confirm_name`] &&
        data[`${fprefix}${prefix}_${person}_confirm_date`]
      ) {
        return true;
      }
      return false;
    };

    let {
      ra_type,
      ra_user_closed,
      ra_required,
      ra_signoff_count,
      ra_open_count,
    } = data;

    let riskAssessmentOpen = true;
    if (ra_required) {
      switch (ra_type) {
        case 'Reference Check':
        case 'Disclosure': {
          return check('disclosure', '1') && check('disclosure', '2');
        }
        default: {
          switch (ra_user_closed) {
            case 'yes': {
              return check('signoff', '1');
            }
            case 'yes3': {
              return check('multi', '1') && check('multi', '2');
            }
            default: {
              // its still open!
              return false;
            }
          }
        }
      }
    }

    return !riskAssessmentOpen;
  },
};
