import React, { useMemo } from 'react';
import lineIcons from '../../assets/sections/line';
import fillIcons from '../../assets/sections/fill';
import styled from 'styled-components';

const FillDiv = styled.div`
  svg .cls-1 {
    fill: ${(props) => props.color};
  }
`;
const LineDiv = styled.div`
  svg .cls-1 {
    stroke: ${(props) => props.color};
    stroke-width: 7px;
  }
`;

const SectionTypeIcon = ({
  iconKey,
  iconStyle,
  color = '#128abc',
  className,
  onClick,
}) => {
  const [IconComponent, ContainerDiv] = useMemo(() => {
    switch (iconStyle) {
      case 'fill': {
        return [fillIcons[iconKey] ?? fillIcons['default'], FillDiv];
      }
      case 'line':
      default: {
        return [lineIcons[iconKey] ?? lineIcons['default'], LineDiv];
      }
    }
  }, [iconKey, iconStyle]);

  return (
    <ContainerDiv className={className} color={color} onClick={onClick}>
      <IconComponent />
    </ContainerDiv>
  );
};

export default SectionTypeIcon;
