import React from 'react';

import { RoleSelection } from '@hogwarts/ui-components-core';

const RoleSelectionComponent = ({
  roles,
  formField,
  fieldHelpers,
  readOnly,
}) => {
  return (
    <RoleSelection
      roles={roles}
      selected={formField.value}
      onBlur={formField.onBlur}
      onChange={(value) => {
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(value);
      }}
      disabled={readOnly}
      icons={false}
    />
  );
};

export default {
  name: 'Role Selection',
  options: [],
  decorators: {
    label: false,
  },
  Component: RoleSelectionComponent,
};
