import { NonIdealState } from '@blueprintjs/core';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styles from './styles.module.css';
import { rowFactory } from './utils';

const DefaultEmptyComponent = () => {
  return <NonIdealState title={'No items available'} />;
};

interface ListViewProps {
  className?: string;
  rows: any[];
  emptyComponent?: React.ComponentType;
  component: React.FC<any>;
  componentProps?: any;
  spreadRowItem?: boolean;
  border?: boolean;
  divider?: boolean;
  scrollToBottomEnabled?: boolean;
}

export const ListView = ({
  className,
  rows,
  emptyComponent = DefaultEmptyComponent,
  component,
  componentProps,
  spreadRowItem = false,
  border = false,
  divider = false,
  scrollToBottomEnabled = false,
}: ListViewProps) => {
  const row = useMemo(
    () =>
      rowFactory(component, rows, componentProps, {
        divider,
        spreadRowItem,
      }),
    [divider, component, componentProps, rows, spreadRowItem]
  );

  const virtuosoRef = useRef<any>(null);
  const prevRowsLength = useRef<number>(rows.length); // Keep track of previous rows length

  // We only scroll on row increment to prevent scrolling on page load
  useEffect(() => {
    if (scrollToBottomEnabled && rows.length === prevRowsLength.current + 1) {
      const timeoutId = setTimeout(() => {
        scrollToBottom();
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [rows]);

  useEffect(() => {
    prevRowsLength.current = rows.length; // Update previous rows length after each render
  });

  const scrollToBottom = () => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: rows.length - 1,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Virtuoso
      ref={virtuosoRef}
      followOutput={scrollToBottomEnabled ? 'smooth' : false}
      data={rows}
      totalCount={rows.length}
      className={cn(styles.list, border && styles.listBorders, className)}
      itemContent={row}
      components={{
        EmptyPlaceholder: emptyComponent,
      }}
    />
  );
};
