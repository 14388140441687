import { NonIdealState } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatter } from '../../../../hooks';
import { Document } from '../../../../types';
import DocumentIcon from '../../../DocumentIcon';
import styles from './styles.module.css';

interface SignOff {
  id: string;
  signedOffBy: {
    id: string;
    email: string;
    name: string;
  };
  signedOffAt: string;
  notes: string;
  documents?: Document[];
}

const SignOffItem = ({ item }: { item: SignOff }) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  return (
    <div key={item.id} className={styles.signOffItem}>
      <FontAwesomeIcon size="2x" icon={['far', 'thumbs-up']} />
      <div className={styles.detailsList}>
        <span>
          <strong>{t('Signed Off By: ')}</strong>
          {item.signedOffBy.name}
        </span>
        {item.notes && (
          <span>
            <strong>{t('Notes: ')}</strong>
            {item.notes}
          </span>
        )}
        {!!item.documents?.length && (
          <span>
            <strong>{t('Documents: ')}</strong>
            {item.documents.map((document) => (
              <DocumentIcon key={document.id} document={document} hasTooltip />
            ))}
          </span>
        )}
      </div>
      <div className={styles.signOffDate}>
        {formatDate.medium(item.signedOffAt)}
      </div>
    </div>
  );
};

interface SignOffsListWidgetProps {
  signOffs: SignOff[];
}
const SignOffsListWidget = ({ signOffs }: SignOffsListWidgetProps) => {
  const { t } = useTranslation();

  if (!signOffs || signOffs.length < 0) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={t('No Sign Offs Found')}
        description={t<string>(
          'There are no sign offs for this organisation to list.'
        )}
      />
    );
  }

  return (
    <ListView className="w-100 h-100" rows={signOffs} component={SignOffItem} />
  );
};

export default SignOffsListWidget;
