import { Select } from '@hogwarts/ui-components-core';
import React, { useMemo } from 'react';
import { getErrorIntent, selectOptions } from './utils';

const SelectComponent = ({
  fieldKey,
  formField,
  readOnly,
  fieldMeta,
  fieldHelpers,
  values: unparsedValues,
  groups,
  groupKey,
  validate,
  showEmpty,
  itemsDescription,
}) => {
  let value = formField.value;
  if (value != null && typeof value === 'object' && value.id) {
    value = value.id;
  }
  const values = useMemo(() => {
    return selectOptions(unparsedValues);
  }, [unparsedValues]);

  return (
    <Select
      id={fieldKey}
      groupKey={groupKey}
      groups={groups}
      valueField="id"
      textField="value"
      showEmpty={showEmpty === false ? false : validate !== 'required'}
      itemsDescription={itemsDescription}
      onBlur={formField.onBlur}
      intent={getErrorIntent(fieldMeta)}
      onChange={(item) => {
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(item.id);
      }}
      items={values}
      disabled={readOnly}
      large={false}
      fill
      value={value}
    />
  );
};

export default {
  name: 'Single Select',
  options: [],
  label: true,
  Component: SelectComponent,
};
