import React, { useContext } from 'react';
import { OrganisationContext, UserContext } from '../../../../context';
import {
  AddMenu,
  HelpMenu,
  OrganisationMenu,
  SearchMenu,
  UserMenu,
  useSupportMenu,
} from './items';

import { IconBar } from '@hogwarts/ui-components-core';

const IconBarContainer = ({
  organisations,
  showSearch,
  enforceMode = false,
}) => {
  const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);

  const supportMenu = useSupportMenu();

  let icons = [];
  if (enforceMode) {
    icons = [
      {
        component: OrganisationMenu,
        componentProps: { organisations },
        name: 'Change School',
        icon: 'school',
      },
      {
        component: UserMenu,
        componentProps: { user, organisation },
        name: 'User',
        icon: 'user',
      },
      {
        name: 'Help',
        icon: 'comment-question',
        component: HelpMenu,
      },
    ];
  } else {
    icons = [
      supportMenu,
      {
        component: AddMenu,
        componentProps: {},
        name: 'Add',
        icon: 'plus',
      },
      {
        component: SearchMenu,
        componentProps: { showSearch },
        name: 'Search',
        icon: 'search',
      },
      {
        component: OrganisationMenu,
        componentProps: { organisations },
        name: 'Change School',
        icon: 'school',
      },
      {
        component: UserMenu,
        componentProps: { user, organisation },
        name: 'User',
        icon: 'user',
      },
      {
        name: 'Help',
        icon: 'comment-question',
        component: HelpMenu,
      },
    ];
  }

  return <IconBar icons={icons} />;
};

export default IconBarContainer;
