import {
  Callout,
  Classes,
  Drawer,
  Intent,
  Position,
  Toaster,
} from '@blueprintjs/core';
import { useDrawerSize } from '@hogwarts/ui-components-core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, ValueBucket } from '../../types';
import FormBuilder from '../FormBuilder';
import Buttons from './Buttons';

const AppToaster = Toaster.create({
  position: Position.TOP,
});

export interface FormBuilderDrawerProps {
  isOpen: boolean;
  initialValues: Record<string, any>;
  size?: any;
  title: string;
  fields: FormField[];
  onSave: (values: ValueBucket) => any;
  onClose: (params?: any) => void;
  onDelete: () => void;
  onReset: () => void;
  allowDelete: boolean;
  deleteText: string;
  allowSave: boolean;
  allowCancel: boolean;
  closeOnCancel: boolean;
  componentProps: any;
  canSave: boolean;
  saveText?: string;
  savingText?: string;
  savedText?: string;
  onValuesChanged: () => void;
  decorators: any;
  successToastMessage: string;
  validateAtStart: boolean;
  enableReinitialize?: boolean;
}
const FormBuilderDrawer = ({
  isOpen,
  initialValues,
  size: desiredSize,
  title,
  fields,
  onSave,
  onClose,
  allowDelete,
  onDelete,
  deleteText,
  allowSave,
  allowCancel,
  closeOnCancel,
  componentProps,
  canSave,
  saveText,
  savingText,
  savedText,
  onValuesChanged,
  onReset,
  decorators,
  successToastMessage,
  validateAtStart,
  enableReinitialize,
}: FormBuilderDrawerProps) => {
  const { t } = useTranslation();
  const [lastActionError, setLastActionError] = useState<any>(null);
  const wasOpen = useRef(isOpen);
  const size = useDrawerSize(desiredSize);

  return (
    <FormBuilder
      fields={fields}
      initialValues={initialValues}
      componentProps={componentProps}
      onValuesChanged={onValuesChanged}
      decorators={decorators}
      validateAtStart={validateAtStart}
      enableReinitialize={enableReinitialize}
      onSave={async (values) => {
        try {
          await onSave(values);

          AppToaster.show({
            message: t(successToastMessage ?? `Save successful`),
            intent: 'success',
            icon: 'tick',
          });
          setLastActionError(null);
        } catch (e) {
          setLastActionError(e);
          throw e;
        }
      }}
      onReset={onReset}
    >
      {(component, props) => {
        if (isOpen !== wasOpen.current && !isOpen && props.dirty) {
          wasOpen.current = isOpen;
          props.resetForm();
        }
        let canCancel = props.isSubmitting
          ? false
          : allowCancel !== false && props.dirty;
        return (
          <Drawer
            enforceFocus={false}
            canEscapeKeyClose={!props.dirty}
            canOutsideClickClose={!props.dirty}
            title={title}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
          >
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>
                <>
                  {lastActionError && (
                    <Callout
                      intent={Intent.DANGER}
                      title={t('Error occurred whilst saving')}
                    >
                      {lastActionError.message
                        .split('\n')
                        .map((message: string) => (
                          <p>{t(message)}</p>
                        ))}
                    </Callout>
                  )}
                  {component}
                </>
              </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>
              <Buttons
                saveText={saveText}
                savingText={savingText}
                savedText={savedText}
                canClose={!canCancel && onClose != null}
                onClose={() => {
                  onClose();
                  setLastActionError(null);
                }}
                canCancel={canCancel}
                onCancel={() => {
                  props.resetForm();
                  setLastActionError(null);
                  if (closeOnCancel) {
                    onClose();
                  }
                }}
                allowSave={allowSave}
                canSave={canSave || !!props.dirty}
                onSave={() => {
                  if (props.isValid) {
                    console.log('SubmitForm: Fields are Valid');
                    props.submitForm();
                  } else {
                    console.log('SubmitForm: Fields are not Valid');
                    // all fields need to be touched
                    // so that the error messages will be shown
                    const touched = fields.reduce(
                      (prev, cur) => ({
                        ...prev,
                        [cur.key]: true,
                      }),
                      {}
                    );
                    props.setTouched(touched);
                  }
                }}
                isSaving={props.isSubmitting}
                allowDelete={allowDelete}
                deleteText={deleteText}
                onDelete={
                  onDelete
                    ? async () => {
                        await onDelete();
                        setLastActionError(null);
                        onClose();
                      }
                    : undefined
                }
              />
            </div>
          </Drawer>
        );
      }}
    </FormBuilder>
  );
};

export default FormBuilderDrawer;
