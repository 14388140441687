import React, { useMemo } from 'react';
import {
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
  Button,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import SelectionMenu from './SelectionMenu';

import styles from './styles.module.css';

const isAllSelected = (available, selected) => {
  let allSelected = true;
  if (!Array.isArray(selected)) {
    return false;
  }
  for (const option of available) {
    if (!selected.includes(option.id)) {
      allSelected = false;
      break;
    }
  }
  return allSelected;
};

const AddMenu = ({ availableFilters, onAdd }) => {
  const { t } = useTranslation();
  return (
    <Menu>
      <MenuDivider title={t('Available Filters')} />
      {availableFilters.map((item) => (
        <MenuItem
          key={item.id}
          icon="filter"
          text={item.name}
          onClick={() => onAdd(item.id)}
        />
      ))}
    </Menu>
  );
};

const ProseFilter = ({
  terms,
  filters,
  activeFilters,
  onSelectionChange,
  onAddFilter,
  onRemoveFilter,
}) => {
  const { t } = useTranslation();
  const [currentFilters, availableFilters] = useMemo(() => {
    let currentFilters = [];
    let availableFilters = [];
    if (filters) {
      for (const filter of filters) {
        if (activeFilters.includes(filter.id)) {
          currentFilters.push(filter);
        } else {
          availableFilters.push(filter);
        }
      }
    }
    return [currentFilters, availableFilters];
  }, [activeFilters, filters]);

  return (
    <div className={styles.filterContainer}>
      <p className={styles.filterText}>
        {terms.prefix}
        {currentFilters.map((filter, idx, arr) => {
          let filterParts = [];
          if (idx > 0 && filter.terms.joining)
            filterParts.push(` ${filter.terms.joining}`);
          if (filter.terms.prefix) filterParts.push(` ${filter.terms.prefix}`);
          if (
            isAllSelected(filter.available, filter.selected) &&
            filter.terms.allSelected
          ) {
            filterParts.push(' ');
            filterParts.push(
              <Popover key={filter.id} position="bottom">
                <span className={styles.filterOption}>
                  {filter.terms.allSelected}
                </span>
                <SelectionMenu
                  available={filter.available}
                  selected={filter.selected}
                  options={filter.selectOptions}
                  onSelectionChange={onSelectionChange.bind(this, filter.id)}
                  onRemove={
                    onRemoveFilter && onRemoveFilter.bind(this, filter.id)
                  }
                />
              </Popover>
            );
          } else {
            filterParts.push(' ');
            if (filter.component) {
              filterParts.push(
                <Popover key={filter.id} position="bottom">
                  <span key={filter.id} className={styles.filterOption}>
                    {filter.available
                      .filter((a) => filter.selected.includes(a.id))
                      .map((a, idx, arr) => {
                        if (idx === 0 || idx < arr.length - 1) {
                          return [
                            <filter.component
                              key={`${filter.id}:${a.id}`}
                              {...a}
                              onClick={() => {}}
                            />,
                          ];
                        } else {
                          return [
                            ` and `,
                            <filter.component
                              key={`${filter.id}:${a.id}`}
                              {...a}
                              onClick={() => {}}
                            />,
                          ];
                        }
                      })
                      .flat()}
                  </span>
                  <SelectionMenu
                    available={filter.available}
                    selected={filter.selected}
                    options={filter.selectOptions}
                    onSelectionChange={onSelectionChange.bind(this, filter.id)}
                    onRemove={
                      onRemoveFilter && onRemoveFilter.bind(this, filter.id)
                    }
                  />
                </Popover>
              );
            } else {
              filterParts.push(
                <Popover key={filter.id} position="bottom">
                  <span key={filter.id} className={styles.filterOption}>
                    {filter.available
                      .filter((a) => filter.selected.includes(a.id))
                      .map((a, idx, arr) => {
                        if (idx === 0) {
                          return a.label;
                        } else if (idx < arr.length - 1) {
                          return `, ${a.label}`;
                        } else {
                          return ` ${t('and')} ${a.label}`;
                        }
                      })
                      .join('')}
                  </span>
                  <SelectionMenu
                    available={filter.available}
                    selected={filter.selected}
                    options={filter.selectOptions}
                    onSelectionChange={onSelectionChange.bind(this, filter.id)}
                    onRemove={
                      onRemoveFilter && onRemoveFilter.bind(this, filter.id)
                    }
                  />
                </Popover>
              );
            }
          }
          if (filter.terms.suffix) filterParts.push(` ${filter.terms.suffix}`);
          if (idx !== arr.length - 1) {
            filterParts.push(',');
          } else {
            filterParts.push('.');
          }
          return filterParts;
        })}
      </p>
      {onAddFilter && availableFilters.length > 0 && (
        <Popover position="bottom">
          <Button icon="add" intent="primary" minimal />
          <AddMenu availableFilters={availableFilters} onAdd={onAddFilter} />
        </Popover>
      )}
    </div>
  );
};

export default ProseFilter;
