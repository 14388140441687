import {
  Button,
  Callout,
  Card,
  IconName,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isObjectLike } from '@hogwarts/utils';
// import styles from './styles.module.css';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import FilterBar from '@/components/Filters/Bar';
import VisibilitySensor from 'react-visibility-sensor';
import { useFeature } from '../../../hooks';
import HistoryRollback from './HistoryRollback';

const formatDate = (date: string, timezone: string): string => {
  let parsed = DateTime.fromISO(date, { zone: timezone });
  if (parsed.isValid) {
    return parsed.toLocaleString(DateTime.DATETIME_MED);
  }
  return `(Invalid Date - ${date})`;
};

const formatValue = (value: any): string => {
  if (Array.isArray(value)) {
    return 'Array';
  }
  if (isObjectLike(value)) {
    return 'Object';
  }
  return `${value}`;
};

const types: Record<
  string,
  { title?: string; icon: IconName; intent: Intent }
> = {
  org_change: {
    title: 'Changed Organisation',
    icon: 'home',
    intent: Intent.WARNING,
  },
  type_change: {
    title: 'Changed Profile Type',
    icon: 'person',
    intent: Intent.WARNING,
  },
  edit: {
    icon: 'edit',
    intent: Intent.NONE,
  },
  delete: {
    title: 'Deleted',
    icon: 'delete',
    intent: Intent.DANGER,
  },
  add: {
    title: 'Created',
    icon: 'add',
    intent: Intent.PRIMARY,
  },
  unknown: {
    title: 'Unknown',
    icon: 'error',
    intent: Intent.DANGER,
  },
};

interface FieldChange {
  from?: string;
  to: string;
  name: string;
}

interface HistoryItem {
  id: string;
  type: string;
  date: string;
  userName: string;
  source: string;
  first?: boolean;
  from?: string;
  to: string;
  changes: string[];
  sections?: {
    key: string;
    label?: string;
    fields: FieldChange[];
  }[];
}

interface RenderFieldProps {
  field: FieldChange;
}
const RenderField = ({ field }: RenderFieldProps) => {
  if (field.from == null) {
    return (
      <div>
        <span>
          • Set <strong>{field.name}</strong>
        </span>
        <span> to </span>
        <span className="text-success">
          <strong>{formatValue(field.to)}</strong>
        </span>
      </div>
    );
  }

  if (field.to == null) {
    return (
      <div>
        <span>
          • Changed <strong>{field.name}</strong>
        </span>
        <span> from </span>
        <span className="text-muted">{formatValue(field.from)}</span>
        <span> to </span>
        <span className="text-success">
          <strong>nothing</strong>
        </span>
      </div>
    );
  }

  return (
    <div>
      <li>
        Changed <strong>{field.name}</strong>
      </li>
      <span> from </span>
      <span className="text-muted">{formatValue(field.from)}</span>
      <span> to </span>
      <span className="text-success">
        <strong>{formatValue(field.to)}</strong>
      </span>
    </div>
  );
};

interface EditChangesProps {
  item: HistoryItem;
}
const EditChanges = ({ item }: EditChangesProps) => {
  if (!item || !item.sections) return null;
  return (
    <div>
      {item.sections.map((section, index) => (
        <div key={index}>
          <h6>{section.label || section.key}</h6>
          <div className="mb-2">
            {section.fields.map((field, index) => (
              <RenderField key={index} field={field} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

interface ProfileTypeChangedProps {
  from?: string;
  to: string;
}
const ProfileTypeChanged = ({ from, to }: ProfileTypeChangedProps) => {
  return (
    <div>
      {from && (
        <>
          <span> from </span>
          <span>
            <strong className="text-muted">{from}</strong>
          </span>
          <span> to </span>
        </>
      )}
      <span className="text-success">
        <strong>{to}</strong>
      </span>
    </div>
  );
};

interface OrganisationChangedProps {
  from?: string;
  to: string;
}
const OrganisationChanged = ({ from, to }: OrganisationChangedProps) => {
  return (
    <div>
      {from && (
        <>
          <span>from </span>
          <span>
            <strong className="text-muted">{from}</strong>
          </span>
          <span> to </span>
        </>
      )}
      <span className="text-success">
        <strong>{to}</strong>
      </span>
    </div>
  );
};

interface RenderDetailProps {
  item: HistoryItem;
}
const RenderDetail = ({ item }: RenderDetailProps) => {
  switch (item.type) {
    case 'add':
    case 'edit': {
      return <EditChanges item={item} />;
    }
    case 'type_change': {
      return <ProfileTypeChanged from={item.from} to={item.to} />;
    }
    case 'org_change': {
      return <OrganisationChanged from={item.from} to={item.to} />;
    }
    default: {
      return null;
    }
  }
};

interface UserProfileHistoryProps {
  permission: any;
  loading: boolean;
  fetchMore: (cursor: any) => Promise<void>;
  changes: HistoryItem[];
  timezone: string;
  diagnostics?: boolean;
  profileId: string;
  refetch: () => Promise<void>;
}
const UserProfileHistory = ({
  permission,
  loading,
  fetchMore,
  changes,
  timezone,
  diagnostics,
  profileId,
  refetch,
}: UserProfileHistoryProps) => {
  const { t } = useTranslation();
  const [loadingMore, setLoadingMore] = useState(false);

  const isProfileRollbackEnabled = useFeature('functions.history_rollback');

  const loadNext = async () => {
    const lastVersion = changes[changes.length - 1];
    const cursor = lastVersion.date;
    try {
      setLoadingMore(true);
      await fetchMore(cursor);
    } finally {
      setLoadingMore(false);
    }
  };

  if (!permission) {
    return (
      <Card elevation={1}>
        <NonIdealState
          className="my-5"
          icon={
            <FontAwesomeIcon color="red" icon={['far', 'exclamation-circle']} />
          }
          title={t('Access Denied')}
          description={t<string>(
            'You do not have permission to view Profile History. Please contact your system administrator'
          )}
        />
      </Card>
    );
  }

  if (loading) {
    return (
      <Card elevation={1}>
        <NonIdealState icon={<Spinner />} />
      </Card>
    );
  }

  if (!changes || changes.length === 0) {
    return (
      <Card elevation={1}>
        <NonIdealState
          icon={<FontAwesomeIcon icon={'question-circle'} />}
          title={t('No History Found')}
          description={t<string>(
            "It looks like you haven't done anything to this profile yet."
          )}
        />
      </Card>
    );
  }

  const hasMore = changes[changes.length - 1].first !== true;
  return (
    <>
      <Card elevation={1}>
        {changes.map((item, index) => (
          <div key={index}>
            <Callout
              className={'mb-3'}
              {...(types[item.type] || types.unknown)}
            >
              <div className="d-flex flex-row">
                <div style={{ width: '200px' }}>
                  <div className={'mb-1 text-nowrap'}>
                    {formatDate(item.date, timezone)}
                  </div>
                  <div>
                    <small className="text-muted">by</small>{' '}
                    <strong>
                      {item.userName ? item.userName : 'Deleted User'}
                    </strong>
                  </div>
                  {diagnostics && (
                    <>
                      <div>
                        <small>{item.source}</small>
                      </div>
                      <div>
                        <small>{item.id}</small>
                      </div>
                    </>
                  )}
                  {isProfileRollbackEnabled && index !== 0 && (
                    <HistoryRollback
                      profileId={profileId}
                      versionId={item.id}
                      refetch={refetch}
                    />
                  )}
                </div>

                <RenderDetail key={item.id} item={item} />
              </div>
            </Callout>
          </div>
        ))}
      </Card>

      {hasMore && (
        <VisibilitySensor onChange={(isVisible) => isVisible && loadNext()}>
          {loadingMore ? (
            <Button disabled>Loading more...</Button>
          ) : (
            <Button onClick={loadNext}>Load More</Button>
          )}
        </VisibilitySensor>
      )}
    </>
  );
};

export default UserProfileHistory;
