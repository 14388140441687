import {
  ApolloClient,
  ApolloError,
  FetchResult,
  MutationHookOptions,
  useApolloClient,
} from '@apollo/client';
import {
  calculateFieldValidity,
  convertValue,
} from '@hogwarts/scheme-profiles';
import { lookup } from '@hogwarts/utils';
import { Issue, Scheme } from '@hogwarts/utils-schemes';
import { emptyValue } from '@hogwarts/validation';
import { get } from 'lodash';
import { set as setfp } from 'lodash/fp';
import { useCallback } from 'react';
import uuid from 'uuid/v4';
import { useLazyQuery, useMutation } from '../../../hooks';
import {
  DELETE_BULK_IMPORT_PROFILES_MUTATION,
  UPDATE_BULK_IMPORT_PROFILES_MUTATION,
} from '../../../mutations';
import { GET_BULK_IMPORT_PROFILES } from '../../../queries';
import {
  BulkImportProfile,
  BulkImportProfileWithId,
  EnrichedProfile,
} from './types';

const calculateProfile = (
  scheme: Scheme,
  profile: BulkImportProfile
): EnrichedProfile => {
  let valid = true;
  const allReasons: Issue[] = [];

  const profileData = {
    ...profile.data,
  };

  for (const field of scheme.fields) {
    // Check field is available
    if (
      field.enabled === false ||
      !field.sectionItem ||
      field.sectionItem?.enabled === false
    ) {
      continue;
    }

    let value = profile.data[field.key];

    if (emptyValue(value)) continue;

    value = convertValue(field.dataType, value, {
      timezone: 'utc',
      dateFormat: 'string',
    });

    profileData[field.key] = value;

    // const failInputRules = (field.validation || []).filter(
    //   (rule) => rule.failInput === true,
    // );

    const [fieldValid, reasons] = calculateFieldValidity(
      scheme,
      field,
      value,
      profileData,
      'utc'
    );

    if (!fieldValid) {
      const fieldReasons = reasons
        .filter((r) => r.failInput === true)
        .map((r) => ({ ...r, field }));
      if (fieldReasons.length) {
        valid = false;
        allReasons.push(...fieldReasons);
      }
    }
  }

  return {
    ...profile,
    data: profileData,
    scheme: null,
    reasons: allReasons,
    valid,
  };
};

const transformData = (
  parentScheme: Scheme,
  profileTypeKey: string,
  data: BulkImportProfile[],
  rowCount: number
): BulkImportProfile[] => {
  const scheme = parentScheme.switchProfileType(profileTypeKey);

  let index = 0;
  let rows: EnrichedProfile[] = [];
  for (const profile of data) {
    rows.push(calculateProfile(scheme, profile));
    // find the biggest index
    index = Math.max(index, profile.index);
  }

  while (rows.length < rowCount) {
    rows.push({
      key: uuid(),
      index: ++index,
      typeKey: profileTypeKey,
      scheme: null,
      data: {},
    });
  }

  return rows;
};

const updateCacheAfterDelete = (
  client: ApolloClient<any>,
  {
    query,
    variables,
    selector,
  }: {
    query: any;
    variables: Record<string, any>;
    selector: string;
  },
  deletedItems: string[]
) => {
  let beforeCache;
  try {
    beforeCache = client.readQuery({
      query,
      variables,
    });
  } catch (e) {
    // Swallow these. Issue in inMemoryCache where it throws error
    // if the values are not in the cache. REALLY annoying
    // log.error('Error Reading Query (Expected)', e);
    console.warn('Error Reading Query (Expected)');
    return;
  }

  if (beforeCache) {
    let existingItems: BulkImportProfileWithId[] = get(beforeCache, selector);
    if (Array.isArray(existingItems)) {
      const updatedItems = existingItems.filter(
        (item) => !deletedItems.includes(item.key)
      );
      const updatedCacheData = setfp(selector, updatedItems, beforeCache);

      if (updatedCacheData) {
        client.writeQuery({
          query,
          data: updatedCacheData,
          variables,
        });
      }
    }
  }
};

const updateCacheAfterUpdate = (
  client: ApolloClient<any>,
  {
    query,
    variables,
    selector,
  }: {
    query: any;
    variables: Record<string, any>;
    selector: string;
  },
  newItems: BulkImportProfileWithId[]
) => {
  let beforeCache;
  try {
    beforeCache = client.readQuery({
      query,
      variables,
    });
  } catch (e) {
    // Swallow these. Issue in inMemoryCache where it throws error
    // if the values are not in the cache. REALLY annoying
    // log.error('Error Reading Query (Expected)', e);
    console.warn('Error Reading Query (Expected)');
    return;
  }

  if (beforeCache) {
    let existingItems: BulkImportProfileWithId[] = get(beforeCache, selector);
    if (Array.isArray(existingItems)) {
      const updatedItems = [...existingItems];
      // im assuming ID is ok here?
      const knownKeys = lookup(existingItems, 'id');
      for (const newItem of newItems) {
        if (knownKeys[newItem.id]) {
          // already exists (apollo deals with updating this right?)
          continue;
        }
        // new item, add it!!
        updatedItems.push(newItem);
      }

      const updatedCacheData = setfp(selector, updatedItems, beforeCache);

      if (updatedCacheData) {
        client.writeQuery({
          query,
          data: updatedCacheData,
          variables,
        });
      }
    }
  }
};

type ResultTuple = [
  (profileTypeKey: string) => void,
  (
    options: MutationHookOptions<
      any,
      Partial<{
        organisationKey: string;
        profileTypeKey: string;
        profiles: BulkImportProfile[];
      }>
    >
  ) => Promise<FetchResult<any>>,
  (organisationKey: string, keys: string[]) => void,
  {
    loading: boolean;
    isSaving: boolean;
    error?: ApolloError;
    savedError?: ApolloError;
  }
];

interface useGetBulkImportProfilesProps {
  organisationKey: string;
  scheme: Scheme;
  profileTypeKey: string;
  onRowsUpdated: (profiles: EnrichedProfile[]) => void;
  onRowsDeleted: (keys: string[]) => void;
  onRowsInitialised: (profiles: BulkImportProfile[]) => void;
  rowCount: number;
}
export const useGetBulkImportProfiles = ({
  organisationKey,
  scheme,
  profileTypeKey,
  onRowsUpdated,
  onRowsDeleted,
  onRowsInitialised,
  rowCount,
}: useGetBulkImportProfilesProps): ResultTuple => {
  const apolloClient = useApolloClient();

  const [getBulkImportProfiles, { loading, error }] = useLazyQuery<
    any,
    {
      organisationKey: string;
      typeKey?: string;
    }
  >(GET_BULK_IMPORT_PROFILES, {
    fetchPolicy: 'cache-first',
    selector: 'organisations[0].bulkImportProfiles',
  });

  const [updateBulkImportProfiles, { loading: isSaving, error: savedError }] =
    useMutation<
      any,
      Partial<{
        organisationKey: string;
        profileTypeKey: string;
        profiles: BulkImportProfile[];
      }>
    >(UPDATE_BULK_IMPORT_PROFILES_MUTATION, {
      selector: 'updateBulkImportProfiles',
      variables: {
        organisationKey,
      },
      onCompleted: async (profiles: BulkImportProfileWithId[]) => {
        updateCacheAfterUpdate(
          apolloClient,
          {
            query: GET_BULK_IMPORT_PROFILES,
            selector: 'organisations[0].bulkImportProfiles',
            variables: {
              organisationKey,
              typeKey: profileTypeKey,
            },
          },
          profiles
        );
        const scheme2 = scheme.switchProfileType(profileTypeKey);
        const updated: EnrichedProfile[] = [];
        for (const profile of profiles) {
          updated.push(calculateProfile(scheme2, profile));
        }
        if (onRowsUpdated) {
          onRowsUpdated(updated);
        }
      },
      onError: () => {},
    });

  const [deleteBulkImportProfilesCore] = useMutation<
    any,
    Partial<{
      organisationKey: string;
      keys: string[];
    }>
  >(DELETE_BULK_IMPORT_PROFILES_MUTATION, {
    variables: {
      organisationKey,
      keys: [] as string[],
    },
  });

  const deleteBulkImportProfiles = async (
    organisationKey: string,
    keys: string[]
  ) => {
    await deleteBulkImportProfilesCore({
      variables: {
        organisationKey,
        keys,
      },
    });
    updateCacheAfterDelete(
      apolloClient,
      {
        query: GET_BULK_IMPORT_PROFILES,
        selector: 'organisations[0].bulkImportProfiles',
        variables: {
          organisationKey,
          typeKey: profileTypeKey,
        },
      },
      keys
    );
    if (onRowsDeleted) {
      onRowsDeleted(keys);
    }
  };

  const loadProfileTypeData = useCallback(
    async (profileTypeKey: string) => {
      if (!profileTypeKey) {
        return;
      }

      const result = await getBulkImportProfiles({
        variables: {
          organisationKey,
          // no need to be getting all of the rows in one hit.
          // just show whats needed.
          typeKey: profileTypeKey,
        },
      });

      if (result.data) {
        const data = result.data as {
          organisations: { bulkImportProfiles: BulkImportProfileWithId[] }[];
        };

        const profiles = data?.organisations?.[0]?.bulkImportProfiles;

        const transformed = transformData(
          scheme,
          profileTypeKey,
          profiles,
          rowCount
        );

        if (onRowsInitialised) {
          onRowsInitialised(transformed);
        }
      }
    },
    [
      getBulkImportProfiles,
      onRowsInitialised,
      organisationKey,
      rowCount,
      scheme,
    ]
  );

  return [
    loadProfileTypeData,
    updateBulkImportProfiles,
    deleteBulkImportProfiles,
    {
      loading,
      error,
      isSaving,
      savedError,
    },
  ];
};
