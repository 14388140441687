import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@blueprintjs/core';

const TitleComponent = ({ label }) => {
  const { t } = useTranslation();
  return (
    <Label
      style={{
        marginBottom: 0,
      }}
    >
      <b>{t(label)}</b>
    </Label>
  );
};

export default {
  name: 'Title',
  options: [
    {
      label: {
        label: 'Label',
        type: 'textbox',
        required: true,
      },
    },
  ],
  decorators: {
    label: false,
  },
  Component: TitleComponent,
};
