import { useForms } from '@hogwarts/ui-components-forms';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import WidgetWrapper from '../../../components/Dashboards/WidgetWrapper';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IncidentsListWidget } from '../../../components/Dashboards/Widgets/IncidentsList';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useFeature,
  useLazyQuery,
  useMemoizeArgs,
  useQuery,
} from '../../../hooks';
import { isFeatureEnabled } from '../../../hooks/useFeature';
import {
  DATASOURCE_QUERY_ACTIVITIES,
  GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
  GET_ORGANISATION_USERS,
} from '../../../queries';
import { Organisation, User } from '../../../types';

const WIDGET_FEATUREKEY = 'timeline.incident_tracking';
const nonIncidentSpecificFields = ['date', 'tags'];

interface IncidentsListProps {
  settings: any;
  onSettingsUpdate: any;
}

interface ActivityFilter extends Record<string, any> {
  type: string;
}

const getStoredFilters = (settings: Record<string, any>) => {
  return Object.keys(settings).reduce<ActivityFilter>(
    (acc, key) => {
      if (!['title', 'visibleFields'].includes(key)) {
        const settingsFilterValue = settings[key]?.length
          ? settings[key]
          : undefined;

        if (nonIncidentSpecificFields.includes(key)) {
          acc[key] = settingsFilterValue;
        } else {
          acc.data = {
            ...(acc.data || {}),
            [key]: settingsFilterValue,
          };
        }
      }

      return acc;
    },
    { type: 'incident' }
  );
};

const IncidentsList = ({
  settings: proposedSettings,
  onSettingsUpdate,
  ...rest
}: IncidentsListProps) => {
  interface IncidentFieldOptions {
    statuses: IncidentField[];
    severities: IncidentField[];
    categories: IncidentField[];
    tags: IncidentField[];
  }

  interface IncidentField {
    id: string;
    value: string;
  }

  const { t } = useTranslation();
  const isRestricted = !useFeature(WIDGET_FEATUREKEY);
  const organisation = useContext(OrganisationContext);
  const settings = useMemoizeArgs(proposedSettings);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFieldOptionsLoaded, setIsFieldOptionsLoaded] = useState(false);
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const [getActivityMeta, { data: activityMeta }] = useLazyQuery(
    GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
    {
      selector: 'organisations[0]',
      variables: {
        organisationKey: organisation.key,
      },
    }
  );

  const availableFieldOptions: IncidentFieldOptions = useMemo(() => {
    const initialAvailableFieldOptions: IncidentFieldOptions = {
      statuses: [],
      severities: [],
      categories: [],
      tags: [],
    };

    return Object.keys(activityMeta || {}).reduce(
      (accumulatedMeta, currentMeta) => {
        const currentMetaValue = activityMeta[currentMeta];
        if (Array.isArray(currentMetaValue)) {
          accumulatedMeta[currentMeta as keyof IncidentFieldOptions] =
            currentMetaValue.map((metaValue) => ({
              id: metaValue.id,
              value: metaValue.name,
            }));
        }

        return accumulatedMeta;
      },
      initialAvailableFieldOptions
    );
  }, [activityMeta]);

  const { data: users } = useQuery(GET_ORGANISATION_USERS, {
    selector: 'organisations[0].users',
    variables: {
      organisationKey: organisation.key,
    },
  });

  const {
    loading,
    data: incidents,
    error,
    refetch,
  } = useQuery(DATASOURCE_QUERY_ACTIVITIES, {
    selector: 'queryActivities.result',
    fetchPolicy: 'no-cache',
    variables: {
      organisationKey: organisation.key,
      filter: getStoredFilters(settings),
    },
    pollInterval: organisation.demo ? 20000 : 60000,
  });

  interface IncidentField {
    id: string;
    value: string;
  }

  const availableFields: IncidentField[] = [
    {
      id: 'date',
      value: t('Show Date'),
    },
    {
      id: 'tags',
      value: t('Show Tags'),
    },
    {
      id: 'documents',
      value: t('Show Documents'),
    },

    {
      id: 'assignedTo',
      value: t('Show Assigned To'),
    },
    {
      id: 'severity',
      value: t('Show Severity'),
    },
    {
      id: 'status',
      value: t('Show Status'),
    },
    {
      id: 'category',
      value: t('Show Category'),
    },
    {
      id: 'notes',
      value: t('Show Notes'),
    },
  ];

  const [showForm] = useForms({
    title: t('Widget Settings'),
    saveText: t('Save'),
    savingText: t('Saving'),
    savedText: t('Saved'),
    successToastMessage: t('Widget Updated'),
    onClose: () => setIsFormVisible(false),
    onSave: async (values: Record<string, any>) => {
      await onSettingsUpdate(values);

      refetch({
        filter: getStoredFilters(values),
      });
    },
    fields: [
      {
        key: 'title',
        type: 'textbox',
        label: t('Title'),
        validate: 'required',
      },
      {
        type: 'separator',
      },
      {
        key: 'date',
        type: 'datepicker',
        label: t('Date'),
      },
      {
        key: 'tags',
        type: 'bpmultiselect',
        label: t('Tags'),
        values: availableFieldOptions.tags,
      },
      {
        type: 'separator',
      },
      {
        key: 'assignedTo',
        type: 'bpmultiselect',
        label: t('Assigned To'),
        values: users?.map((user: User) => ({
          id: user.id,
          value: user.name,
        })),
      },
      {
        key: 'status',
        type: 'singleselect',
        label: t('Status'),
        values: availableFieldOptions.statuses,
      },
      {
        key: 'severity',
        type: 'singleselect',
        label: t('Severity'),
        values: availableFieldOptions.severities,
      },
      {
        key: 'category',
        type: 'singleselect',
        label: t('Category'),
        values: availableFieldOptions.categories,
      },
      {
        type: 'separator',
      },
      {
        key: 'visibleFields',
        type: 'checklist',
        selectionMinimum: 1,
        showSelectAll: true,
        values: availableFields,
      },
    ],
    initialValues: settings,
  });

  const visibleFields = useMemo(
    () =>
      Object.keys(settings.visibleFields || {}).filter(
        (key) => settings.visibleFields[key]
      ),
    [settings.visibleFields]
  );

  useEffect(() => {
    if (isFormVisible) {
      showForm();
    }
  }, [isFormVisible, showForm]);

  return (
    <WidgetWrapper
      title={settings.title}
      loading={loading}
      error={!!error}
      unavailable={isRestricted}
      disableScroll
      message={
        isRestricted
          ? t('This widget is not enabled for your Organisation.')
          : undefined
      }
      onSettingsClick={
        onSettingsUpdate
          ? async () => {
              if (!isFieldOptionsLoaded) {
                await getActivityMeta();
                setIsFieldOptionsLoaded(true);
              }
              setIsFormVisible(true);
            }
          : undefined
      }
      {...rest}
    >
      <IncidentsListWidget
        incidents={incidents}
        visibleFields={visibleFields}
        onSelected={(profileId) => {
          analytics.events.search.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}/timeline`);
        }}
      />
    </WidgetWrapper>
  );
};

export default {
  type: 'incidents-list',
  name: 'Incidents List',
  description: 'List of Incidents for the organisation/user',
  widgetComponent: IncidentsList,
  defaultSettings: () => ({
    title: 'Incidents',
  }),
  restricted: (organisation: Organisation) => {
    return isFeatureEnabled(WIDGET_FEATUREKEY, organisation);
  },
};
