import React from 'react';
import { Card, Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const ProfileTransfer = ({
  sourceOrganisation,
  destinationOrganisation,
  profile,
  canAcceptTransfers,
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.profileTransferContainer}>
      <div className={styles.contentContainer}>
        <p className={styles.name}>
          {`${profile.firstname} ${profile.lastname}`}
        </p>
        <p>{profile.employment_position}</p>
        <p className={styles.transfer}>
          {sourceOrganisation}{' '}
          <FontAwesomeIcon className="mx-1" icon="arrow-right" />{' '}
          {destinationOrganisation}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          intent="success"
          disabled={!canAcceptTransfers}
          icon={canAcceptTransfers ? null : 'lock'}
          onClick={onApprove}
        >
          {t('Approve')}
        </Button>
        <Button
          intent="danger"
          icon={canAcceptTransfers ? null : 'lock'}
          disabled={!canAcceptTransfers}
          onClick={onReject}
        >
          {t('Reject')}
        </Button>
      </div>
    </Card>
  );
};

export default ProfileTransfer;
