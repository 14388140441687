import { Spinner } from '@blueprintjs/core';
import {
  ActionBar,
  ActionWithChildren,
  Error,
} from '@hogwarts/ui-components-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

interface SettingsPanelProps {
  title: string;
  subText?: string;
  actions?: (ActionWithChildren | undefined | boolean | null)[];
  loading?: boolean;
  error?: boolean;
  fixedHeight?: string;
  children?: React.ReactNode;
}
export const SettingsPanel = ({
  title,
  subText,
  actions,
  children,
  loading,
  error,
  fixedHeight,
}: SettingsPanelProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className="d-flex flex-column flex-grow-1">
          <div className={styles.titleText}>{t(title)}</div>
          {subText && <div className={styles.subText}>{t(subText)}</div>}
        </div>
        {!loading && !error && actions && (
          <ActionBar large={false} actions={actions} />
        )}
      </div>
      {children && (
        <>
          <div className={styles.divider} />
          <div
            className={styles.cardBody}
            style={fixedHeight ? { height: fixedHeight } : {}}
          >
            {error ? (
              <Error />
            ) : loading ? (
              <Spinner intent="primary" />
            ) : (
              children
            )}
          </div>
        </>
      )}
    </div>
  );
};
