import { gql } from '@apollo/client';

export const ADD_ACTIVITY = gql`
  mutation addActivity($activity: AddActivityInput!) {
    addActivity(activity: $activity) {
      id
      data
      tags {
        id
        name
        color
      }
      date
      type
      documents {
        id
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($activity: UpdateActivityInput!) {
    updateActivity(activity: $activity) {
      id
      data
      tags {
        id
        name
        color
      }
      date
      type
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation Mutation($id: ID!) {
    deleteActivity(id: $id)
  }
`;
