import {
  LazyQueryHookOptions,
  OperationVariables,
  QueryTuple,
  useLazyQuery as useLazyQueryApollo,
} from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';

type ExtendedOptions = {
  transform?: any;
  selector?: string;
};

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> & ExtendedOptions
): QueryTuple<TData, TVariables> {
  const { transform, selector, ...options2 } = options || {};

  const [queryInvoke, queryResult] = useLazyQueryApollo(query, options2);

  const selectAndTransformDataBlock = useCallback(
    (data: TData | undefined, options?: ExtendedOptions) => {
      const { transform, selector } = options || {};

      if (selector && data) {
        data = get(data, selector);
      }

      if (data && typeof transform === 'function') {
        data = transform(data, options);
      }

      return data;
    },
    []
  );

  return useMemo<QueryTuple<TData, TVariables>>(() => {
    const data2 = selectAndTransformDataBlock(queryResult.data, options);

    return [queryInvoke, { ...queryResult, data: data2 }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult]);
}
