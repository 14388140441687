import permissions from '@hogwarts/permissions';
import { useContext, useState } from 'react';
import { ReportDialog, useJobMonitor, useMutation } from '.';
import { OrganisationContext } from '../context';
import {
  EXPORT_EXTERNAL_CHECKS_REPORT,
  exportExternalChecksReportType,
} from '../mutations';
import { usePermission } from './usePermission';

interface ReportDialogProps {
  opened?: boolean;
  status: string;
  result?: {
    id: string;
    filename: string;
    url: string;
  };
  setOpened: (state: boolean) => void;
}

export const useBuildExternalChecksReport = (): [
  (ownerType: string, ownerId: string, dateRange: [Date, Date]) => void,
  {
    disabled: boolean;
    component: typeof ReportDialog;
    props: ReportDialogProps;
  },
  boolean
] => {
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const organisation = useContext(OrganisationContext);
  const canPrintReports = usePermission(
    permissions.PROFILE_PRINT,
    organisation.id
  );

  const [opened, setOpened] = useState<boolean | undefined>(undefined);

  const [exportReport] = useMutation<string, exportExternalChecksReportType>(
    EXPORT_EXTERNAL_CHECKS_REPORT,
    {
      selector: 'exportExternalChecksReport',
      onCompleted: (newJobId) => {
        setJobId(newJobId);
      },
    }
  );

  const { status, result } = useJobMonitor(jobId);

  const start = async (
    ownerType: string,
    ownerId: string,
    dateRange: [Date, Date]
  ) => {
    if (!canPrintReports) return;
    setOpened(false);
    await exportReport({
      variables: {
        ownerType,
        ownerId,
        dateRange,
      },
    });
  };
  return [
    start,
    {
      disabled: !canPrintReports || status === 'queued',
      component: ReportDialog,
      props: { opened, status, result, setOpened },
    },
    canPrintReports,
  ];
};
