import LateDatesByProfileList from './lateDatesByProfileList';

const OrganisationLateDatesByProfileList = {
  type: 'late-dates-by-profile-list',
  name: 'Profiles with Late Dates List',
  description:
    'Displays a list of profiles where fields with the Late Date Check are later than the Start Date.',
  widgetComponent: LateDatesByProfileList,
  defaultSettings: () => ({
    title: 'Profiles with Late Dates List',
    includedTags: { active: true, onboarding: true },
    includedProfileTypes: null,
    show_startdate: true,
    ratings: {
      dfe: 'all',
      std: 'all',
    },
  }),
};

export default OrganisationLateDatesByProfileList;
