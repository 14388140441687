import { UserContext } from '@/context';
import { MenuItem } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Select } from '@blueprintjs/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FederationContext } from '../../context';
import { highlightText } from '../../utils';
import './styles.css';

const filterOrganisation = (query, organisation, _index, exactMatch) => {
  const normalizedTitle = organisation.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};
const OrganisationItem = ({
  organisation,
  handleClick,
  modifiers,
  query,
  userContext,
  federation,
}) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const mfaRequired =
    (federation?.attributes?.mfaEnforced ||
      organisation?.attributes?.mfaEnforced) &&
    !userContext.mfaEnabled;

  const icon = mfaRequired ? (
    <Tooltip2
      content={`You must enable MFA to access ${organisation.name}.`}
      placement="left"
      intent="warning"
      compact
    >
      <FontAwesomeIcon icon={'lock'} />
    </Tooltip2>
  ) : (
    <FontAwesomeIcon icon={organisation.icon} />
  );

  return (
    <MenuItem
      icon={icon}
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={organisation.label}
      labelClassName={'ml-5'}
      key={organisation.key}
      onClick={handleClick}
      text={highlightText(organisation.name, query)}
    />
  );
};

const OrganisationSelector = ({
  organisations,
  onSelected,
  small,
  children,
}) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const federation = useContext(FederationContext);

  return (
    <Select
      items={organisations}
      itemRenderer={(organisation, props) =>
        OrganisationItem({
          ...props,
          organisation,
          userContext,
          federation,
        })
      }
      noResults={<MenuItem disabled text={t('No results found')} />}
      itemPredicate={filterOrganisation}
      itemDisabled={(item) => item.disabled}
      onItemSelect={(item) => {
        if (!item.disabled) {
          onSelected(item);
        }
      }}
      popoverProps={{
        portalClassName: 'organisationSelector',
        minimal: small,
      }}
    >
      {children}
    </Select>
  );
};

export default OrganisationSelector;
