import {
  CellValueChangedEvent,
  ColumnApi,
  GridOptions,
  RowNode,
} from '@ag-grid-community/core';
import { Intent } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import { Action, ActionBar, Error } from '@hogwarts/ui-components-core';
import { ApiParams, ContextMenuItems } from '@hogwarts/ui-components-grid';
import cn from 'classnames';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import uuid from 'uuid/v4';
import { GridView, PageHeader } from '../../../components';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useAlert,
  useJobMonitor,
  useMutation,
  usePermission,
  useQuery,
} from '../../../hooks';
import {
  DELETE_ALL_BULK_IMPORT_PROFILES_MUTATION,
  QUEUE_BULK_IMPORT_MUTATION,
} from '../../../mutations';
import {
  GET_BULK_IMPORT_PROFILES,
  GET_ORGANISATION_IMPORT_ID,
} from '../../../queries';
import { AppToaster } from '../../../utils/toaster';
import { editingGridOptions } from '../../profilesGridUtils';
import { BulkImportSummary } from './BulkImportSummary';
import { ImportingModal } from './ImportingModal';
import { ProfileTypeSelector } from './ProfileTypeSelector';
import { SavingSpinner } from './SavingSpinner';
import { getBulkImporterColumns } from './getBulkImporterColumns';
import styles from './styles.module.css';
import { BulkImportProfile, Profile } from './types';
import { useGetBulkImportProfiles } from './useGetBulkImportProfiles';

const DEFAULT_ROWCOUNT = 25;

const gridOptions: GridOptions = {
  ...editingGridOptions,

  defaultColDef: {
    ...editingGridOptions.defaultColDef,
    width: 100,
    filter: false,
    editable: false,
    suppressMenu: true,
  },

  // https://www.ag-grid.com/javascript-grid/data-update-transactions/
  suppressModelUpdateAfterUpdateTransaction: true,
  suppressChangeDetection: true,

  isRowSelectable: (rowNode) => {
    // TODO: Could use this to not allow any
    // empty rows to be checked?
    return true;
  },
};

const getRowNodeId = (data: Profile) => data.key;

const getFullName = (profile: Profile) => {
  const firstname = (profile.data.firstname || '').trim();
  const lastname = (profile.data.lastname || '').trim();
  const fullname = `${firstname} ${lastname}`.trim();
  if (fullname === '') return '(No Name)';
  return fullname;
};

const onPostSort = (rows: any[], { columnApi }: { columnApi: ColumnApi }) => {
  const sortModel = columnApi.getColumnState().filter((col) => col.sort);
  if (!sortModel.length) {
    rows.sort((a, b) => a.index - b.index);
    return;
  }

  function isEmpty(node: RowNode) {
    return !node.data.id;
  }

  function move(toIndex: number, fromIndex: number) {
    rows.splice(toIndex, 0, rows.splice(fromIndex, 1)[0]);
  }

  let nextInsertPos = 0;

  for (let i = 0; i < rows.length; i++) {
    if (!isEmpty(rows[i])) {
      move(nextInsertPos, i);
      nextInsertPos++;
    }
  }
};

const useOpenState = (
  initialOpen = false
): [
  (state?: boolean) => void,
  {
    isOpen: boolean;
    onClose: () => void;
  }
] => {
  const [isOpen, setOpen] = useState(!!initialOpen);
  const properties = {
    isOpen,
    onClose: () => {
      setOpen(false);
    },
  };
  const open = (override: boolean = true) => {
    setOpen(override);
  };
  return [open, properties];
};

const useHasRunningJob = (): boolean => {
  const ATTRIBUTEKEY = 'bulkImport_jobId';

  const organisation = useContext(OrganisationContext);

  const [currentBulkImportJobId, setCurrentBulkImportJobId] = useState<string>(
    organisation.attributes?.[ATTRIBUTEKEY]
  );

  const { data: serverCurrentImportId } = useQuery(GET_ORGANISATION_IMPORT_ID, {
    // loosely monitor to see if anyone else is doing an import
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    selector: 'organisations[0].currentImportId',
    variables: {
      organisationKey: organisation.key,
    },
  });

  useEffect(() => {
    setCurrentBulkImportJobId(serverCurrentImportId);
  }, [serverCurrentImportId]);

  const { status } = useJobMonitor(currentBulkImportJobId);
  switch (status) {
    case 'inprogress':
    case 'queued': {
      return true;
    }
    default:
    case 'error':
    case 'completed': {
      return false;
    }
  }
};

const BulkImporter = () => {
  // const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const [{ api: gridApi }, setGridApi] = useState<Partial<ApiParams>>({});
  const [showBulkImportSummary, bulkImportSummaryProps] = useOpenState();

  let [desiredProfileTypeKey] = useQueryParam('type', StringParam);

  const profileTypeKey = useMemo(
    () => {
      let profileTypeKey1 = desiredProfileTypeKey;
      if (!profileTypeKey1) {
        profileTypeKey1 = organisation.scheme.profileTypes[0].key;
      }
      return profileTypeKey1;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desiredProfileTypeKey]
  );

  const onRowsUpdated = useCallback(
    (profiles: Profile[]) => {
      if (!gridApi) return;
      let rowNodes = [];
      for (const profile of profiles) {
        const rowNode = gridApi.getRowNode(profile.key);
        if (rowNode) {
          rowNodes.push(rowNode);
        }
      }
      if (profiles) {
        gridApi.applyTransaction({
          update: profiles,
        });
        gridApi.refreshCells({
          rowNodes,
          force: true,
        });
      }
    },
    [gridApi]
  );

  const onRowsDeleted = useCallback(
    (keys: string[]) => {
      if (!gridApi) return;
      const rowNodes = [];
      for (const key of keys) {
        const rowNode = gridApi.getRowNode(key);
        if (rowNode) {
          rowNodes.push(rowNode);
        }
      }
      if (rowNodes.length) {
        gridApi.applyTransaction({
          remove: rowNodes.map((n) => n.data),
        });
      }
    },
    [gridApi]
  );

  const onRowsInitialised = useCallback(
    (profiles) => {
      gridApi?.setRowData(profiles);
      gridApi?.hideOverlay();
    },
    [gridApi]
  );

  const [
    loadProfileTypeData,
    updateBulkImportProfiles,
    deleteBulkImportProfiles,
    { loading, error, isSaving, savedError },
  ] = useGetBulkImportProfiles({
    organisationKey: organisation.key,
    profileTypeKey,
    scheme: organisation.scheme,
    onRowsUpdated,
    onRowsDeleted,
    onRowsInitialised,
    rowCount: organisation.attributes?.bulkimport_rowcount || DEFAULT_ROWCOUNT,
  });

  useEffect(() => {
    // Need to wait until gridApi is set
    // then we can load the data
    if (gridApi) {
      gridApi.setRowData([]);
      loadProfileTypeData(profileTypeKey);
    }
  }, [gridApi, loadProfileTypeData, profileTypeKey]);

  let [groups, columns] = useMemo(() => {
    let profileTypeScheme =
      organisation.scheme.switchProfileType(profileTypeKey);

    let [groups1, columns1] = getBulkImporterColumns(profileTypeScheme);

    columns1?.unshift(
      // {
      //   key: 'index',
      //   label: 'Index',
      //   minWidth: 75,
      //   maxWidth: 75,
      //   lockVisible: true,
      //   lockPosition: true,
      //   pinned: true,
      //   lockPinned: true,
      //   editable: false,
      //   type: 'number',
      // },
      {
        key: '__validator',
        label: 'Valid',
        minWidth: 75,
        maxWidth: 75,
        lockVisible: true,

        // This causes a bug where suddenly none of the columns are
        // able to be moved about.

        // lockPosition: true,
        pinned: true,

        cellStyle: {
          padding: '0px',
          lineHeight: '25px',
        },
        cellRenderer: 'validProfileRenderer',
        editable: false,
        valueGetter: ({ data }: any) => {
          if (!data || !data.id) {
            return null;
          }
          return [data.valid, data.reasons];
        },
      }
    );
    return [groups1, columns1];
  }, [organisation, profileTypeKey]);

  const [deleteAllProfiles] = useMutation(
    DELETE_ALL_BULK_IMPORT_PROFILES_MUTATION,
    {
      selector: 'deleteAllBulkImportProfiles',
      variables: {
        organisationKey: organisation.key,
      },
      refetchQueries: organisation.scheme.profileTypes.map((profileType) => ({
        query: GET_BULK_IMPORT_PROFILES,
        variables: {
          organisationKey: organisation.key,
          typeKey: profileType.key,
        },
      })),
      onCompleted: () => {
        if (!gridApi) return;
        const rows = [];
        let index = 0;
        const rowCount =
          organisation.attributes?.bulkimport_rowcount || DEFAULT_ROWCOUNT;
        while (rows.length < rowCount) {
          rows.push({
            key: uuid(),
            index: index++,
            typeKey: profileTypeKey,
            scheme: null,
            data: {},
          });
        }
        gridApi.setRowData(rows);
      },
      onError: () => {},
    }
  );

  const { t } = useTranslation();

  const hasImportPermission = usePermission(
    [permissions.PROFILE_CREATE, permissions.BULK_IMPORT_EXECUTE],
    organisation.id
  );
  const hasDeleteRowPermission = usePermission(
    permissions.BULK_IMPORT_DELETE_SOME,
    organisation.id
  );
  const hasDeleteAllPermission = usePermission(
    permissions.BULK_IMPORT_DELETE_ALL,
    organisation.id
  );

  const [queueBulkImport] = useMutation<string>(QUEUE_BULK_IMPORT_MUTATION, {
    selector: 'queueBulkImport',
    variables: {
      organisationKey: organisation.key,
    },
    refetchQueries: [
      {
        query: GET_ORGANISATION_IMPORT_ID,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const [importQueued, setImportQueued] = useState(false);
  const currentlyRunning = useHasRunningJob();

  useEffect(() => {
    if (importQueued && !currentlyRunning) {
      // redirect to profiles list (soon, redirect to a transaction list!?)
      history.push(`/${organisation.key}/profiles`);
    }
    // Important that we only monitor currentlyRunning here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyRunning]);

  const beginImport = async () => {
    analytics.events.bulkImporter.importClicked();

    if (currentlyRunning) return;

    // Close the dialog
    showBulkImportSummary(false);

    // Queue the import
    const jobId = await queueBulkImport();
    if (jobId.data) {
      setImportQueued(true);
    }
  };

  const [ClearSomeAlert, confirmClearSome] = useAlert<{
    nodes: RowNode[];
  }>({
    onConfirm: async ({ nodes }) => {
      if (!nodes) return;
      analytics.events.bulkImporter.deleteRowExecuted();
      await deleteBulkImportProfiles(
        organisation.key,
        nodes.map((n) => n.data.key)
      );
      AppToaster.show({
        message: t(`Deleted ${nodes.length} rows`),
        intent: 'success',
      });
    },
    confirmButtonText: 'Confirm',
    // title: t<string>('Are you sure?'),
    Content: () => (
      <>
        <p>{t('This will delete the selected rows and cannot be undone.')}</p>
        <p>{t('It will NOT delete Imported Profiles')}</p>
      </>
    ),
  });

  const [ClearAllAlert, confirmClearAll] = useAlert({
    onConfirm: async () => {
      analytics.events.bulkImporter.clearAllExecuted();
      await deleteAllProfiles();
      AppToaster.show({
        message: t('Bulk Importer has been cleared'),
        intent: 'success',
      });
    },
    confirmButtonText: 'Proceed',
    // title: t<string>('Are you sure?'),
    Content: () => (
      <>
        <p>
          {t(
            'This will CLEAR ALL of your Bulk Import Data. It is not reversible.'
          )}
        </p>
        <p>{t('It will NOT delete Imported Profiles')}</p>
      </>
    ),
  });

  const actions = useMemo<Action[]>(
    () => [
      {
        tooltip:
          'This will run the import and create the profiles. If you have already ran the import, this will update the existing profiles',
        allowed: hasImportPermission,
        text: 'Import',
        intent: Intent.SUCCESS,
        icon: 'upload',
        onClick: () => {
          analytics.events.bulkImporter.importSummaryClicked();
          showBulkImportSummary();
        },
      },
      {
        allowed: hasDeleteAllPermission,
        text: 'Clear All',
        tooltip:
          'This will erase all items in the Bulk Importer. Use it when you are happy with your previous import, and want to start a new one',
        icon: ['fal', 'trash'],
        onClick: () => {
          analytics.events.bulkImporter.clearAllClicked();
          confirmClearAll();
        },
      },
      {
        allowed: true,
        text: 'Close',
        icon: ['fal', 'times-circle'],
        onClick: () => {
          analytics.events.bulkImporter.closeClicked();
          history.push(`/${organisation.key}`);
        },
      },
    ],
    [
      analytics.events.bulkImporter,
      confirmClearAll,
      hasDeleteAllPermission,
      hasImportPermission,
      history,
      organisation,
      showBulkImportSummary,
    ]
  );

  const onBatchCellValueChanged = async (
    allParams: CellValueChangedEvent[]
  ) => {
    const batch: Record<string, BulkImportProfile> = {};
    // group all the changes by the row unique key
    for (const params of allParams) {
      let {
        data: { key, index, typeKey },
        // @ts-ignore Aggrid issue where they dont let you tag on properties yet
        colDef: { meta },
        newValue,
      } = params;

      if (typeof newValue === 'undefined') {
        newValue = null;
      }

      if (!batch[key]) {
        batch[key] = {
          index,
          key,
          typeKey,
          data: {},
        };
      }

      batch[key] = {
        ...batch[key],
        data: {
          ...batch[key].data,
          [meta.key]: newValue,
        },
      };
    }

    // format it for the mutation
    const profiles = Object.keys(batch).reduce<BulkImportProfile[]>(
      (prev, key) => [...prev, batch[key]],
      []
    );

    updateBulkImportProfiles({
      variables: {
        profiles,
      },
    });
  };

  const onCellValueChanged = async (event: CellValueChangedEvent) => {
    return onBatchCellValueChanged([event]);
  };

  if (error) {
    return <Error title="Error loading the Bulk Import Profiles." />;
  }

  if (!columns) return null;

  return (
    <>
      <ClearAllAlert />
      <ClearSomeAlert />
      <PageHeader noWrapper />
      <BulkImportSummary {...bulkImportSummaryProps} onImport={beginImport} />
      <ImportingModal isOpen={currentlyRunning} />
      <div
        className={cn(
          styles.page_header,
          'd-flex justify-content-between align-items-center'
        )}
      >
        <span>{organisation?.name}</span>
        <div>
          <ActionBar actions={actions} />
        </div>
      </div>
      <div className={'ml-2 mt-2'}>
        <div className={'col-10'}>
          <ProfileTypeSelector
            profileTypes={organisation?.scheme?.profileTypes}
          />
        </div>
        <SavingSpinner text={'Saving'} show={isSaving} />
        <SavingSpinner text={'Loading'} show={loading} />

        {savedError && <div className={'col-2'}>Error Saving.</div>}
      </div>

      <GridView
        allowEdit={true}
        contextMenuItems={(nodes) => {
          const profileNodes = nodes.filter((n) => n.data.id);
          return [
            ...(profileNodes.length === 1
              ? [
                  {
                    name: getFullName(profileNodes[0].data),
                    disabled: true,
                  },
                ]
              : nodes.length > 0
              ? [
                  {
                    name: {
                      message: `Selected {{count}} row${
                        nodes.length > 1 ? 's' : ''
                      }`,
                      count: nodes.length,
                    },
                    disabled: true,
                  },
                ]
              : []),
            'separator',
            'copy',
            'copyWithHeaders',
            hasDeleteRowPermission && {
              name: {
                message: `Delete {{count}} row${
                  nodes.length > 1 ? 's' : ''
                }...`,
                count: nodes.length,
              },
              action: (nodes) => {
                confirmClearSome({ nodes });
              },
            },
          ] as ContextMenuItems;
        }}
        onGridReady={setGridApi}
        getRowNodeId={getRowNodeId}
        {...gridOptions}
        groups={groups}
        columns={columns}
        onBatchCellValueChanged={onBatchCellValueChanged}
        onCellValueChanged={onCellValueChanged}
        onPostSort={onPostSort}
      />
    </>
  );
};

export default BulkImporter;
