import { HTMLTable } from '@blueprintjs/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SIALogo } from '../../../assets/integrations/sia.svg';
import styles from './styles.module.css';

export interface SignInAppConnectedProps {
  apiKey?: string;
  federation: boolean;
  status?: { label: string; value: string }[];
}

const SignInAppConnected = ({
  apiKey,
  federation,
  status,
}: SignInAppConnectedProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="w-25">
        <SIALogo />
      </div>
      <div className="my-4">
        <p className={'mt-3'}>
          {federation ? 'Connected to Federation' : 'Connected to Organisation'}
        </p>
      </div>
      {apiKey ? (
        <div className="my-4">
          <p className={'mt-3 font-weight-bold'}>
            {t(
              'Please make a note of the following key as it cannot not be displayed again'
            )}
          </p>
          <input className={'col-12'} readOnly value={apiKey} />
        </div>
      ) : (
        <div className="my-4">
          <p className={'mt-3'}>
            {t(
              'Please use the credentials provided when connection was enabled.'
            )}
          </p>
        </div>
      )}
      {status && (
        <HTMLTable condensed className="mt-3">
          <tbody>
            {status &&
              status.map(({ label, value }, index) => (
                <tr key={index}>
                  <td className="text-nowrap">
                    <b>{label}:</b>
                  </td>
                  <td>
                    <div className={styles.messageValueContainer}>
                      <div className={styles.messageValue}>{value}</div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </HTMLTable>
      )}
    </div>
  );
};

export default SignInAppConnected;
