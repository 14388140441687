import React, { useState } from 'react';

import { NonIdealState } from '@blueprintjs/core';
import { Button } from '@hogwarts/ui-components-core';
import { InfiniteListView } from '@hogwarts/ui-components-list';
import { useTranslation } from 'react-i18next';
import { ProfileListItem } from '../../ProfileListItem';

interface Profile {
  id: string;
  typeKey: string;
  data: Record<string, unknown>;
  name: string;
  organisation: {
    name: string;
  };
  profileType?: {
    key: string;
    label: string;
  };
  tags?: {
    key: string;
    label: string;
    color: string;
  }[];
  ratings: {
    key: string;
    label: string;
    ready: boolean;
    readyColor: string;
    notReadyColor: string;
  }[];
}

interface BulkSyncSignInAppProps {
  fetchMore: any;
  onPushProfile: (profileId: string) => void;
  disabled?: boolean;
  groupName?: string;
}

const NoRows = () => {
  const { t } = useTranslation();
  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No Profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

const BulkSyncSignInApp = ({
  fetchMore,
  onPushProfile,
  disabled,
  groupName,
}: BulkSyncSignInAppProps) => {
  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState<Record<string, boolean>>({});
  return (
    <InfiniteListView
      className="w-100 h-100"
      componentProps={{}}
      fetchMore={fetchMore}
      component={({ item: profile, ...props }: { item: Profile }) => {
        return (
          <ProfileListItem
            id={profile.id}
            name={profile.name}
            orgName={profile.organisation?.name}
            type={profile.profileType?.label || profile.typeKey}
            tags={profile.tags}
            {...props}
          >
            <Button
              intent="primary"
              disabled={disabled || inProgress[profile.id]}
              onClick={() => {
                setInProgress({
                  [profile.id]: true,
                });
                onPushProfile(profile.id);
              }}
            >
              {!groupName
                ? t('(Select a Group)')
                : !inProgress[profile.id]
                ? t('Push to {{groupName}}', {
                    groupName,
                  })
                : t('Pushed!')}
            </Button>
          </ProfileListItem>
        );
      }}
      emptyComponent={NoRows}
      spreadRowItem={false}
    />
  );
};

export default BulkSyncSignInApp;
