import { NonIdealState, Tag } from '@blueprintjs/core';
import { FetchMethod, InfiniteListView } from '@hogwarts/ui-components-list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Profile,
  ProfileListItem,
} from '../../../../containers/profileListItem';
import { useDateFormatter } from '../../../../hooks';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

interface LateCheckField {
  key: string;
  label: string;
  checkDate: string;
}

interface LateField {
  key: string;
  label: string;
  fields: LateCheckField[];
}

interface LateChecksProps {
  lateFields: LateField[];
}

const LateChecks = ({ lateFields }: LateChecksProps) => {
  const formatDate = useDateFormatter();

  return (
    <table style={{ width: '100%' }} className="mb-4">
      <tbody>
        {lateFields?.map(({ key, label, fields }) => [
          <tr key={`section-${key}`}>
            <td colSpan={2}>
              <div className={'mr-2 font-weight-bold'}>{label}</div>
            </td>
          </tr>,
          ...fields.map((field) => (
            <tr key={`field-${field.key}`}>
              <td>
                <div className={'ml-2 mr-2 mb-2'}>{field.label}</div>
              </td>
              <td>
                <Tag intent="danger" fill className="mb-2">
                  {formatDate.medium(field.checkDate)}
                </Tag>
              </td>
            </tr>
          )),
        ])}
      </tbody>
    </table>
  );
};

interface ProfilesLateDatesWidgetProps {
  showAvatar: boolean;
  iconPack: string;
  fields: string[];
  fetchMore: FetchMethod;
  onProfileSelected: (profileId: string, profile: Profile) => void;
}
const ProfilesLateDatesWidget = ({
  showAvatar,
  iconPack,
  fields,
  fetchMore,
  onProfileSelected,
}: ProfilesLateDatesWidgetProps) => {
  return (
    <InfiniteListView
      className="w-100 h-100"
      componentProps={{
        showAvatar,
        fields,
        onProfileSelected,
      }}
      fetchMore={fetchMore}
      component={({ item: profile, ...props }: { item: any }) => (
        <ProfileListItem
          profile={profile}
          onSelected={onProfileSelected}
          fields={fields}
          iconPack={iconPack}
          {...props}
        >
          <LateChecks lateFields={profile.lateCheckFields} />
        </ProfileListItem>
      )}
      emptyComponent={NoRows}
      spreadRowItem={false}
    />
  );
};

export default ProfilesLateDatesWidget;
