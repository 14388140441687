import React from 'react';

import VisibilityAndRatings from '@/components/SchemeEditor/VisibilityAndRatings';

import { schemePatcher, schemeHelperFactory } from '@hogwarts/scheme-profiles';

const getSectionAndField = (schemeData, sectionKey, fieldKey) => {
  if (!schemeData) return {};
  const { profileTypes, ratingSystems, sections, fields } = schemeData;
  return {
    profileTypes,
    ratingSystems,
    sections: {
      [sectionKey]: {
        ...(sections && sections[sectionKey]),
      },
    },
    fields: {
      [fieldKey]: {
        ...(fields && fields[fieldKey]),
      },
    },
  };
};

const buildFieldMetaData = (
  scheme,
  allProfileTypes,
  sectionKey,
  fieldKey,
  values
) => {
  // This rebuilds the entire scheme but only for the section and field that
  // we are currently editing.

  // I feel this code could be moved to useFieldEdit but I think there is a bigger
  // piece of work to be done around all of this anyway, which would involve
  // migrating a version of this

  let layers = [];
  for (const part of scheme.parts) {
    const layer = {
      ...part,
    };
    layer.data = {
      ...getSectionAndField(layer.data, sectionKey, fieldKey),
      profileTypeSchemes: {
        ...allProfileTypes.reduce(
          (prev, profileType) => ({
            ...prev,
            [profileType.key]: getSectionAndField(
              layer.data.profileTypeSchemes &&
                layer.data.profileTypeSchemes[profileType.key],
              sectionKey,
              fieldKey
            ),
          }),
          {}
        ),
      },
    };
    layers.push(layer);
  }

  const draftLayer = layers[layers.length - 1];
  draftLayer.data = schemePatcher(draftLayer.data, {
    ...values,
  });

  const scheme2 = schemeHelperFactory(layers);

  const fieldMetaData = {};
  for (const profileType of allProfileTypes) {
    const profileTypeScheme = scheme2.switchProfileType(profileType.key);
    fieldMetaData[profileType.key] = profileTypeScheme.getField(fieldKey);
  }

  return fieldMetaData;
};

const Component = ({
  readOnly,
  profileTypes,
  showProfileTypes,
  ratingSystems,
  fieldKey1: fieldKey,
  sectionKey,
  includePathPrefix,
  formik,
  scheme,
}) => {
  let values = {};

  const fieldMetaData = buildFieldMetaData(
    scheme,
    profileTypes,
    sectionKey,
    fieldKey,
    !includePathPrefix
      ? {
          profileTypeSchemes: {
            [showProfileTypes[0].key]: {
              ...formik.values,
            },
          },
        }
      : formik.values
  );

  const pathPrefix = (profileTypeKey, path) => {
    if (!path) throw new Error('Youve messed up');
    // TODO: We dont want to prefix this if we're Viewing a Profile Type!
    if (!includePathPrefix) {
      return path;
    }
    return `profileTypeSchemes.${profileTypeKey}.${path}`;
  };

  for (const profileType of profileTypes) {
    const profileTypeMetaData = fieldMetaData[profileType.key];
    if (!profileTypeMetaData) continue;

    const locked = profileTypeMetaData.meta.enabled.locked;
    const enabled = profileTypeMetaData.enabled;
    const lock = profileTypeMetaData.lock.enabled;

    values[profileType.key] = {
      enabled,
      lock,
      locked,
      ratings: {
        ...ratingSystems.reduce((prev, system) => {
          const rating =
            profileTypeMetaData.ratings &&
            profileTypeMetaData.ratings[system.key];
          if (!rating) return prev;
          return {
            ...prev,
            [system.key]: {
              enabled: rating.enabled,
              lock: rating.lock.enabled,
              locked: rating.meta.enabled.locked,
              condition: rating.condition,
            },
          };
        }, {}),
      },
    };
  }

  const onEnabledChangeHandler = ({
    profileTypeKey,
    ratingSystemKey,
    enabled,
  }) => {
    if (!profileTypeKey) return;
    const profileTypeFieldMeta = fieldMetaData[profileTypeKey];
    if (!ratingSystemKey) {
      const path = pathPrefix(
        profileTypeKey,
        profileTypeFieldMeta.meta.enabled.path
      );
      formik.setFieldValue(path, enabled);
      formik.setFieldTouched(path);
    } else {
      const ratings =
        profileTypeFieldMeta.ratings &&
        profileTypeFieldMeta.ratings[ratingSystemKey];
      if (!ratings) return;

      const path = pathPrefix(profileTypeKey, ratings.meta.enabled.path);
      formik.setFieldValue(path, enabled);
      formik.setFieldTouched(path);
    }
  };

  const onLockChangeHandler = ({ profileTypeKey, ratingSystemKey, lock }) => {
    if (!profileTypeKey) return;
    const profileTypeFieldMeta = fieldMetaData[profileTypeKey];
    if (!ratingSystemKey) {
      const path = pathPrefix(
        profileTypeKey,
        profileTypeFieldMeta.meta.enabled.lockpath
      );
      formik.setFieldValue(path, lock);
      formik.setFieldTouched(path);
    } else {
      const ratings =
        profileTypeFieldMeta.ratings &&
        profileTypeFieldMeta.ratings[ratingSystemKey];
      if (!ratings) return;

      const path = pathPrefix(profileTypeKey, ratings.meta.enabled.lockpath);
      formik.setFieldValue(path, lock);
      formik.setFieldTouched(path);
    }
  };

  return (
    <VisibilityAndRatings
      onEnabledChange={onEnabledChangeHandler}
      onLockChange={onLockChangeHandler}
      values={values}
      profileTypes={showProfileTypes || profileTypes}
      ratingSystems={ratingSystems}
      disabled={readOnly}
    />
  );
};

export default {
  name: 'Visibility and Ratings',
  options: [],
  Component,
};
