import WidgetWrapper from '@/components/Dashboards/WidgetWrapper';
import React from 'react';
import BarChartWidget from './barChart';
import CombinedScoresChart from './combinedScoresChart';
import ExpiredChecksByCheckList from './expiredChecksByCheckList';
import ExpiredChecksByProfileList from './expiredChecksByProfileList';
import FederationBarChartWidget from './federationBarChart';
import FederationLateDatesByProfileList from './federationLateDatesByProfileList';
import FederationOutstandingChecksByProfileList from './federationOutstandingChecksByProfileList';
import FederationProfileSummaryWidget from './federationProfileSummary';
import FederationProfilesList from './federationProfilesList';
import IncidentsList from './incidentsList';
import OrganisationLateDatesByProfileList from './organisationLateDatesByProfileList';
import OrganisationsListWidget from './organisationsList';
import OutstandingChecksByProfileList from './outstandingChecksByProfileList';
import PieChartWidget from './pieChart';
import ProfileSummaryWidget from './profileSummary';
import ProfilesListWidget from './profilesList';
import SignOffList from './signOffList';

const widgets = [
  ProfileSummaryWidget,
  OrganisationsListWidget,
  CombinedScoresChart,
  BarChartWidget,
  PieChartWidget,
  ProfilesListWidget,
  FederationProfileSummaryWidget,
  FederationBarChartWidget,
  ExpiredChecksByProfileList,
  ExpiredChecksByCheckList,
  OutstandingChecksByProfileList,
  FederationOutstandingChecksByProfileList,
  SignOffList,
  FederationProfilesList,
  OrganisationLateDatesByProfileList,
  FederationLateDatesByProfileList,
  IncidentsList,
];

const UnknownWidget = ({ settings, ...rest }) => {
  return (
    <WidgetWrapper
      {...rest}
      title={settings.title}
      error="Unknown Widget"
      onSettingsClick={null}
    />
  );
};

export const getAllWidgets = (organisation, federation) => {
  let filteredWidgets = widgets.filter((w) => {
    if (w.requiresFederation === true && !federation) {
      return false;
    }
    if (typeof w.restricted === 'function') {
      return w.restricted(organisation);
    }
    return true;
  });
  return filteredWidgets;
};

export const getOrganisationWidgets = (organisation) => {
  let filteredWidgets = widgets.filter((w) => {
    if (w.requiresFederation === true) {
      return false;
    }
    if (typeof w.restricted === 'function') {
      return w.restricted(organisation);
    }
    return true;
  });
  return filteredWidgets;
};

export const getFederationWidgets = (organisation, federation) => {
  let filteredWidgets = widgets.filter((w) => {
    if (w.requiresFederation !== true) {
      return false;
    }
    if (typeof w.restricted === 'function') {
      return w.restricted(organisation, federation);
    }
    return true;
  });
  return filteredWidgets;
};

export const getWidget = (type) =>
  widgets.find((w) => w.type === type) ?? {
    widgetComponent: UnknownWidget,
  };
