import React, { useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  AnalyticsContext,
  OrganisationContext,
  UserContext,
} from '../../../context';
import {
  DataProvider,
  FieldMap,
  LocationMap,
  ProfileTypeMap,
} from '../../../types';

import { Error } from '@hogwarts/ui-components-core';
import { useForms } from '@hogwarts/ui-components-forms';
import { Scheme } from '@hogwarts/utils-schemes';
import { DataProviderComponent } from '../../../components/Settings/DataProvider';
import { useMutation } from '../../../hooks';
import { REQUEST_INSTALLATION } from '../../../mutations';
import { ProviderMeta } from './types';

export interface DataProviderConfig {
  fieldMap: FieldMap;
}
const useRequestInstallation = (provider?: ProviderMeta) => {
  const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);
  const analytics = useContext(AnalyticsContext);
  const [requestInstallation] = useMutation(REQUEST_INSTALLATION);

  const [showForm] = useForms({
    title: 'Installation',
    saveText: 'Submit',
    savingText: 'Submitting',
    savedText: 'Submitted',
    successToastMessage: 'Request Submitted',
    onSave: (values) => {
      analytics.events.dataProvider.requestInstallationClicked({
        providerKey: provider?.key,
      });
      return requestInstallation({
        variables: {
          organisationKey: organisation.key,
          details: values,
        },
      });
    },
    initialValues: {
      providerKey: provider?.key,
      schoolName: organisation?.name,
      schoolPostCode: null,
      contactName: user?.name,
      contactEmail: user?.email,
    },
    fields: [
      {
        key: 'providerKey',
        type: 'hidden',
      },
      {
        type: 'callout',
        intent: 'primary',
        message: `To get your installation setup and booked, please provide us with the following information.\nWe will submit this to the provider, who will then be in touch.`,
      },
      {
        type: 'hyperlink',
        label:
          'If you do not know this information, you can find it on the government website here',
        // url: 'https://get-information-schools.service.gov.uk/',
        url: `https://get-information-schools.service.gov.uk/Establishments/Search?SelectedTab=Establishments&SearchType=Text&SearchType=Text&TextSearchModel.Text=${organisation?.name}&OpenOnly=true&TextSearchModel.AutoSuggestValue=&f=true&b=1&b=4`,
      },
      {
        type: 'separator',
      },
      {
        key: 'schoolName',
        type: 'textbox',
        label: 'School Name',
        validate: 'required',
      },
      {
        key: 'schoolPostCode',
        type: 'textbox',
        label: 'School Postcode',
        maxLength: 10,
        validate: 'required',
      },
      {
        key: 'leaCode',
        type: 'textbox',
        label: 'LEA Code',
        placeHolder:
          'LEA Code is the first 3 digit of your schools ESTAB number',
        maxLength: 3,
        validate: 'required',
      },

      {
        key: 'dfesCode',
        type: 'textbox',
        label: 'DFES Code',
        placeHolder: 'DFES is the last 4 digits of your school ESTAB number',
        maxLength: 4,
        validate: 'required',
      },

      {
        type: 'separator',
      },

      {
        key: 'contactName',
        type: 'textbox',
        label: 'Contact Name',
        placeHolder:
          'Who is the person to get in touch with regarding installation?',
        validate: 'required',
      },
      {
        key: 'contactEmail',
        type: 'textbox',
        label: 'Contact Email',
        validate: 'required',
      },

      {
        key: 'contactMobile',
        type: 'textbox',
        label: 'Mobile Number (for Password resets) or Landline',
      },

      {
        key: 'notes',
        type: 'textarea',
        label: 'Notes',
      },
    ],
  });

  return showForm;
};
interface DataProviderContainerProps {
  providers: ProviderMeta[];
  scheme: Scheme;
  selectedProviderKey?: string;
  config?: DataProvider;
  showOrganisationMap?: boolean;
  organisations?: {
    id: string;
    name: string;
  }[];
  canImportNow: boolean;
  canDisconnect: boolean;
  changeProvider: (newKey: string) => void;
  onSaveFieldMap: (fieldMap: any) => void;
  onSaveLocationMap: (locationMap: LocationMap) => void;
  onSaveProfileTypeMap: (profileTypeMap: ProfileTypeMap) => void;
  onSaveConnection: (connection: any) => void;
  onImport: () => void;
  onImportDiscovery: () => void;
  onDisconnect: () => void;
}

const DataProviderContainer = ({
  providers,
  selectedProviderKey,
  changeProvider,
  showOrganisationMap,
  organisations,
  config,
  scheme,
  canImportNow,
  canDisconnect,
  onDisconnect,
  onSaveFieldMap,
  onSaveLocationMap,
  onSaveProfileTypeMap,
  onSaveConnection,
  onImport,
  onImportDiscovery,
}: DataProviderContainerProps) => {
  const { connected, status } = config || {};
  const provider = providers.find((p) => p.key === selectedProviderKey);

  const [activeTab, setActiveTab] = useQueryParam('tab', StringParam);
  const requestInstallation = useRequestInstallation(provider);
  const analytics = useContext(AnalyticsContext);

  if (selectedProviderKey && !provider && connected) {
    return (
      <Error title={`Connected to Unknown Provider '${selectedProviderKey}'`} />
    );
  }

  let standardActions: any[] = [];
  if (!connected && providers?.length > 0) {
    standardActions.push(
      provider && {
        text: 'Book Installation',
        icon: 'user-hard-hat',
        intent: 'danger',
        disabled: false,
        onClick: () => {
          analytics.events.dataProvider.showRequestInstallationClicked({
            providerKey: provider.key,
          });
          return requestInstallation();
        },
      }
    );
    standardActions.push({
      text: 'Change Provider',
      icon: 'exchange',
      disabled: false,
      actions: providers
        .filter((provider) => provider.allowNew !== false)
        .map((provider) => ({
          key: provider.key,
          text: provider.name,
          onClick: async () => {
            return changeProvider(provider.key);
          },
        })),
    });
  }
  if (connected) {
    let disabled = false;
    if (status === 'queued' || status === 'inprogress') {
      disabled = true;
    }
    standardActions.push({
      intent: 'primary',
      text: 'Import',
      icon: 'sync',
      allowed: canImportNow,
      disabled,
      actions: [
        {
          intent: 'PRIMARY',
          text: 'Import Now',
          allowed: canImportNow,
          icon: 'sync',
          disabled,
          onClick: () => {
            analytics.events.dataProvider.importNowClicked({
              PROVIDER_KEY: provider!.key,
            });
            return onImport();
          },
        },
        {
          intent: 'PRIMARY',
          text: 'Discovery Mode',
          allowed: canImportNow,
          icon: 'sync',
          disabled,
          onClick: () => {
            analytics.events.dataProvider.importDiscoveryClicked({
              PROVIDER_KEY: provider!.key,
            });
            return onImportDiscovery();
          },
        },
      ],
    });
    standardActions.push({
      intent: 'danger',
      text: 'Disconnect',
      icon: 'times-circle',
      disabled,
      allowed: canDisconnect,
      confirm: true,
      onClick: () => {
        analytics.events.dataProvider.disconnectClicked({
          PROVIDER_KEY: provider?.key,
        });
        return onDisconnect();
      },
    });
  }

  return provider?.Actions ? (
    <provider.Actions connected={connected!} status={status!}>
      {(actions: any) => (
        <DataProviderComponent
          {...provider.showOptions}
          connected={connected!}
          activeTab={activeTab!}
          config={config}
          onTabChange={(key) => {
            setActiveTab(key);
          }}
          scheme={scheme}
          providers={providers}
          showOrganisationMap={
            showOrganisationMap &&
            provider.showOptions?.showOrganisationMap !== false
          }
          organisations={showOrganisationMap ? organisations : []}
          providerKey={selectedProviderKey}
          actions={[...actions!, ...standardActions]}
          onSaveFieldMap={onSaveFieldMap}
          onSaveLocationMap={onSaveLocationMap}
          onSaveProfileTypeMap={onSaveProfileTypeMap}
          onSaveConnection={onSaveConnection}
          HelpBlock={provider.HelpBlock && !connected && <provider.HelpBlock />}
        />
      )}
    </provider.Actions>
  ) : (
    <DataProviderComponent
      {...provider?.showOptions}
      connected={connected!}
      activeTab={activeTab!}
      config={config}
      onTabChange={(key) => {
        setActiveTab(key);
      }}
      providers={providers}
      providerKey={selectedProviderKey}
      scheme={scheme}
      showOrganisationMap={
        showOrganisationMap &&
        connected &&
        provider?.showOptions?.showOrganisationMap !== false
      }
      showFieldMap={connected}
      showProfileTypeMap={connected}
      organisations={showOrganisationMap && connected ? organisations : []}
      actions={[...standardActions]}
      onSaveFieldMap={onSaveFieldMap}
      onSaveLocationMap={onSaveLocationMap}
      onSaveProfileTypeMap={onSaveProfileTypeMap}
      onSaveConnection={onSaveConnection}
      HelpBlock={provider?.HelpBlock && !connected && <provider.HelpBlock />}
    />
  );
};

export default DataProviderContainer;
