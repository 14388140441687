import { Callout, FormGroup } from '@blueprintjs/core';
import {
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from 'react-beautiful-dnd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormIcons from '../../../assets/form-icons/line';
import React from 'react';
import cn from 'classnames';
import styles from './styles.module.css';

const DraggableHandle = () => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon icon="grip-vertical" />
  </div>
);
const DraggableHandle2 = (props: DraggableProvidedDragHandleProps) => (
  <div className={styles.dragHandle} {...props}>
    <FontAwesomeIcon icon="grip-vertical" />
  </div>
);

const FieldItem = (props: {
  inputType: string;
  label: string;
  inUse: Boolean;
}) => {
  // @ts-ignore
  let Icon = FormIcons[props.inputType];
  if (!Icon) {
    Icon = FormIcons['helptext'];
  }
  return (
    <div
      className={cn(styles.fieldContent, {
        [styles.fieldContentDisabled]: !props.inUse,
      })}
    >
      <div className={styles.fieldImageContainer}>
        <Icon className={styles.fieldImage} />
      </div>
      {props.label}
    </div>
  );
};

interface Field {
  key: string;
  label: string;
  inputType: string;
  inUse: boolean;
  arrayFields?: Field[];
  deleted?: boolean;
}

const SortableField = ({
  index,
  field,
  selectedFieldKey,
  onFieldSelected,
  onFieldEdit,
}: {
  index: number;
  field: Field;
  selectedFieldKey: string;
  onFieldSelected: (key: string) => void;
  onFieldEdit: (key: string) => void;
}) => {
  return (
    <Draggable draggableId={field.key} index={index}>
      {(provided: any) => {
        const dragActive = true;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn(styles.fieldContainer, {
              [styles.enabledContainer]: !dragActive,
              [styles.selectedContainer]: selectedFieldKey?.includes(field.key),
              [styles.fieldDeleted]: field.deleted,
              [styles.fieldDisabled]: !field.inUse,
            })}
            onClick={() => onFieldSelected(field.key)}
            onDoubleClick={() => onFieldEdit(field.key)}
          >
            <FieldItem
              inUse={field.inUse}
              label={field.label}
              inputType={field.inputType}
            />
            <DraggableHandle />
          </div>
        );
      }}
    </Draggable>
  );
};

const grid = 8;
const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  margin: '10px 0',
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,
});

interface ArrayItemsProps {
  field: Field;
  selectedFieldKey?: string;
  arrayFields: Field[];
  onFieldSelected: (key: string) => void;
  onFieldEdit: (key: string) => void;
}
const ArrayItems = (props: ArrayItemsProps) => {
  return (
    <Droppable
      droppableId={`ARRAYFIELD.${props.field.key}`}
      type={`ARRAYFIELD.${props.field.key}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {props.arrayFields?.map((arrayField, idx) => (
            <Draggable
              key={arrayField.key}
              draggableId={arrayField.key}
              index={idx}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                  className={cn(
                    'd-flex flex-columns justify-content-between',
                    styles.fieldContainer,
                    {
                      [styles.selectedContainer]:
                        props.selectedFieldKey?.includes(arrayField.key),
                      // [styles.enabledContainer]: !dragActive,
                      [styles.fieldDeleted]: arrayField.deleted,
                      [styles.fieldDisabled]: !arrayField.inUse,
                    }
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    // Parent key at end of string
                    props.onFieldSelected(
                      `${arrayField.key}.${props.field.key}`
                    );
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                    props.onFieldEdit(arrayField.key);
                  }}
                >
                  <FieldItem
                    key={arrayField.key}
                    inUse={arrayField.inUse}
                    label={arrayField.label}
                    inputType={arrayField.inputType}
                  />
                  <DraggableHandle2 {...provided.dragHandleProps!} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const SortableArrayFields = ({
  index,
  field,
  selectedFieldKey,
  onFieldSelected,
  onFieldEdit,
  visibilityOptions,
}: {
  index: number;
  field: Field;
  selectedFieldKey: string;
  onFieldSelected: (key: string) => void;
  onFieldEdit: (key: string) => void;
  visibilityOptions: {
    showDeleted: boolean;
    showUnused: boolean;
    showParentOwned: boolean;
  };
}) => {
  return (
    <Draggable draggableId={field.key} index={index}>
      {(provided: any) => {
        const dragActive = true;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={cn(styles.fieldContainer, {
              [styles.enabledContainer]: !dragActive,
              [styles.selectedContainer]: selectedFieldKey?.includes(field.key),
              [styles.fieldDeleted]: field.deleted,
              [styles.fieldDisabled]: !field.inUse,
            })}
            onClick={() => onFieldSelected(field.key)}
            onDoubleClick={() => onFieldEdit(field.key)}
          >
            <div className="d-flex flex-column w-100">
              <FieldItem
                inUse={field.inUse}
                label={field.label}
                inputType={field.inputType}
              />
              <ArrayItems
                selectedFieldKey={selectedFieldKey}
                field={field}
                arrayFields={
                  field.arrayFields
                    ? field.arrayFields
                        .filter(
                          (f) => visibilityOptions.showDeleted || !f.deleted
                        )
                        .filter((f) => visibilityOptions.showUnused || f.inUse)
                    : []
                }
                onFieldSelected={onFieldSelected}
                onFieldEdit={onFieldEdit}
              />
            </div>
            <DraggableHandle />
          </div>
        );
      }}
    </Draggable>
  );
};

const SortableFieldList = ({
  sortableFields,
  onFieldEdit,
  onFieldSelected,
  selectedFieldKey,
  visibilityOptions,
}: {
  sortableFields: Field[];
  onFieldSelected: (key: string) => void;
  onFieldEdit: (key: string) => void;
  selectedFieldKey: string;
  visibilityOptions: {
    showDeleted: boolean;
    showUnused: boolean;
    showParentOwned: boolean;
  };
}) => {
  return (
    <Droppable type="FIELDS" droppableId="FIELDS">
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          style={{
            minHeight: '300px',
          }}
        >
          {sortableFields.reduce<JSX.Element[]>((prev, field, idx) => {
            if (field.arrayFields) {
              return [
                ...prev,
                <SortableArrayFields
                  key={field.key}
                  index={idx}
                  field={field}
                  onFieldEdit={onFieldEdit}
                  onFieldSelected={onFieldSelected}
                  selectedFieldKey={selectedFieldKey}
                  visibilityOptions={visibilityOptions}
                />,
              ];
            }

            return [
              ...prev,
              <SortableField
                key={field.key}
                index={idx}
                field={field}
                onFieldEdit={onFieldEdit}
                onFieldSelected={onFieldSelected}
                selectedFieldKey={selectedFieldKey}
              />,
            ];
          }, [])}
        </div>
      )}
    </Droppable>
  );
};

export const FieldTab = ({
  fields,
  onFieldEdit,
  onFieldSelected,
  selectedFieldKey,
  visibilityOptions,
}: {
  fields: Field[];
  onFieldSelected: (key: string) => void;
  onFieldEdit: (key: string) => void;
  selectedFieldKey: string;
  visibilityOptions: {
    showDeleted: boolean;
    showUnused: boolean;
    showParentOwned: boolean;
  };
}) => {
  return (
    <FormGroup>
      {fields && fields.length === 0 && (
        <Callout>
          This section currently has no fields. Add them from the Field Library
        </Callout>
      )}
      {fields && (
        <SortableFieldList
          sortableFields={fields}
          onFieldSelected={onFieldSelected}
          onFieldEdit={onFieldEdit}
          selectedFieldKey={selectedFieldKey}
          visibilityOptions={visibilityOptions}
        />
      )}
    </FormGroup>
  );
};
