import { GridView, PageHeader, TimezoneWarning } from '@/components';
import { AnalyticsContext, OrganisationContext, UserContext } from '@/context';
import { useQuery } from '@/hooks';
import { GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST } from '@/queries';
import { Tag } from '@blueprintjs/core';
import { PageTitle } from '@hogwarts/ui-components-core';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '../../../hooks';
import { UPDATE_USER_PREFERENCES } from '../../../mutations';
import {
  dateFormatter,
  gridOptions as defaultGridOptions,
  useDataSourceFactory,
} from '../../profilesGridUtils';
import ProfilesFilterBar from '../filterBar';

const gridOptions = {
  ...defaultGridOptions,
  noRowsOverlayComponent: 'profileListNoRowsOverlay',
  rowSelection: 'single',
  sideBar: false,
  sizeColumnsToFit: true,
  defaultColDef: {
    ...defaultGridOptions.defaultColDef,
    width: 100,
    filter: false,
    floatingFilter: false,
    editable: false,
    suppressMenu: true,
  },
  suppressRowClickSelection: true,
  suppressCellSelection: true,
};

// Can we mark certain fields, then map the labels
// from the scheme (and the data type etc)
const createFields = (
  organisation,
  defaultProfileSortTab = [
    {
      sortKey: 'firstname',
      sortOrder: 'asc',
    },
  ]
) => {
  function ratingGetterFactory(ratingSystemKey) {
    return function getter({ data }) {
      const ratings = data?.ratings;
      if (ratings) {
        const system = ratings.find((r) => r.key === ratingSystemKey);
        if (system) {
          return system.ready;
        }
      }
      return false;
    };
  }

  const { ratingSystems } = organisation.scheme;

  const getFieldLabel = (fieldKey) => {
    const field = organisation.scheme.getField(fieldKey);
    return field?.label || fieldKey;
  };

  const getSortData = (fieldKey) => {
    const sortedColumn = defaultProfileSortTab.find(
      ({ sortKey }) => sortKey === fieldKey
    );

    if (sortedColumn) {
      return {
        sort: sortedColumn.sortDirection,
        sortIndex: sortedColumn.sortIndex,
      };
    }

    return {};
  };

  return [
    {
      key: 'tags',
      label: 'Tags',
      minWidth: 40,
      maxWidth: 125,
      cellRenderer: 'tagRenderer',
      cellStyle: {
        lineHeight: '25px',
        padding: '1px',
      },
      sortable: false,
    },
    {
      key: 'firstname',
      label: getFieldLabel('firstname'),
      dataField: true,
      ...getSortData('firstname'),
    },
    {
      key: 'lastname',
      label: getFieldLabel('lastname'),
      dataField: true,
      ...getSortData('lastname'),
    },
    {
      key: 'typeKey',
      label: 'Profile Type',
      minWidth: 150,
      valueFormatter: (params) => {
        const typeKey = params.value;
        const profileType = organisation.scheme.getProfileType(typeKey);
        if (!profileType) return typeKey;
        return profileType.label;
      },
      ...getSortData('typeKey'),
    },
    {
      key: 'employment_position',
      label: getFieldLabel('employment_position'),
      dataField: true,
      ...getSortData('employment_position'),
    },
    {
      key: 'startdate',
      valueFormatter: dateFormatter,
      label: getFieldLabel('startdate'),
      dataField: true,
      ...getSortData('startdate'),
    },
    {
      key: 'leavedate',
      valueFormatter: dateFormatter,
      label: getFieldLabel('leavedate'),
      dataField: true,
      ...getSortData('leavedate'),
    },
    ...ratingSystems.map((ratingSystem) => ({
      key: `rating_${ratingSystem.key}`,
      valueGetter: ratingGetterFactory(ratingSystem.key),
      label: ratingSystem.label,
      cellRenderer: 'booleanRenderer',
      filter: 'agTextColumnFilter',
      minWidth: 50,
      maxWidth: 125,
      ...getSortData(`rating_${ratingSystem.key}`),
    })),
  ].map((field) => {
    if (!field.dataField) return field;
    return {
      ...field,
      valueGetter: ({ data: rowData }) => {
        let profileData = rowData?.data;
        if (!profileData) {
          return null;
        }
        return profileData[field.key];
      },
    };
  });
};

const ProfilesList = () => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const user = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();
  const defaultProfileSortTab = user?.preferences?.defaultProfileSortTab;

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);

  const fields = useMemo(
    () => createFields(organisation, defaultProfileSortTab),
    [organisation, defaultProfileSortTab]
  );

  const showAdvancedEditor = useCallback(() => {
    history.push(`/${organisation.key}/editor${location.search}`);
  }, [history, location.search, organisation.key]);

  const showProfilePage = useCallback(
    (params) => {
      const { id } = params.data || {};
      if (id) {
        history.push(`/${organisation.key}/profiles/${id}`);
      }
    },
    [history, organisation.key]
  );

  const showTransfersPage = useCallback(
    (params) => {
      history.push(`/${organisation.key}/profiles/transfers`);
    },
    [history, organisation.key]
  );

  const [condition, setCondition] = useState(null);

  const { data: profileTransfers } = useQuery(
    GET_PROFILE_TRANSFER_REQUEST_FOR_PROFILES_LIST,
    {
      fetchPolicy: 'cache-and-network',
      selector: 'profileOrganisationChangeRequests',
      variables: { targetOrganisationId: organisation.id },
      pollInterval: organisation.demo ? 20000 : 60000,
    }
  );

  const actions = useMemo(
    () => {
      const actions = [];
      if (profileTransfers?.length > 0) {
        actions.push({
          text: 'Transfer Requests',
          icon: 'exchange',
          allowed: true,
          intent: 'warning',
          rightIcon: (
            <Tag round intent="primary">
              {profileTransfers.length}
            </Tag>
          ),
          onClick: () => {
            analytics.events.profiles.transfersClicked();
            showTransfersPage();
          },
        });
      }
      actions.push({
        text: 'Advanced Editor',
        icon: 'table',
        allowed: true,
        intent: 'none',
        onClick: () => {
          analytics.events.profiles.advancedEditorClicked();
          showAdvancedEditor();
        },
      });
      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, profileTransfers, showAdvancedEditor]
  );

  const onFilterHandler = useCallback((filters) => {
    const ratingFilter = [];
    for (const filterKey of Object.keys(filters)) {
      if (filterKey.includes('ratingSystem')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: filters[filterKey][0],
        });
      }
    }
    setCondition({
      profileTypes: filters.profileTypes ?? [],
      tags: filters.tags ?? [],
      ratings: ratingFilter,
      condition: null,
    });
  }, []);

  const dataSource = useDataSourceFactory();

  const updateSortPreference = useCallback(
    async (sortModel) => {
      const sortColumns = sortModel.columnApi
        .getColumnState()
        .filter(({ sort }) => sort)
        .map(({ colId, sort, sortIndex }) => ({
          sortKey: colId,
          sortDirection: sort,
          sortIndex,
        }));

      await updateUserPreferences({
        variables: {
          updatedPreferences: {
            defaultProfileSortTab: sortColumns,
          },
        },
      });
    },
    [updateUserPreferences]
  );

  if (!fields) return null;

  return (
    <>
      <PageTitle title="Profiles" />
      <PageHeader
        header={organisation && organisation.name}
        actions={actions}
        notificationChildren={<TimezoneWarning />}
      />
      <>
        <ProfilesFilterBar onFilter={onFilterHandler} />
        <GridView
          dataSource={dataSource}
          allowEdit={false}
          onRowClicked={showProfilePage}
          {...gridOptions}
          groups={[]}
          filter={condition}
          columns={fields}
          showSideBar={false}
          onSortChanged={updateSortPreference}
        />
      </>
    </>
  );
};

export default ProfilesList;
