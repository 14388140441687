import {
  Button,
  Drawer,
  InputGroup,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { useDebounce, useDrawerSize } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchResultTabs from './resultTabs';

const SAVE_DEBOUNCE_TIME = 450;

const SearchDrawer = ({
  allowed,
  isLoading,
  error,
  organisationName,
  federationName,
  organisationProfiles,
  federationProfiles,
  onSearchTermChange,
  onSelected,
  onClear,
  children,
  defaultHasSearched,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(defaultHasSearched);
  const size = useDrawerSize();

  const renderContent = () => {
    if (isLoading) {
      return (
        <NonIdealState
          icon={<Spinner intent="primary" />}
          title={t('Searching')}
        />
      );
    }
    if (error) {
      return (
        <NonIdealState
          icon="error"
          title={t('Error Searching')}
          description={t(
            'Sorry we were unable to perform your search, if this continues please contact support.'
          )}
        />
      );
    }
    if (
      organisationProfiles.length > 0 ||
      (federationProfiles && federationProfiles.length > 0)
    ) {
      return (
        <SearchResultTabs
          organisationName={organisationName}
          organisationProfiles={organisationProfiles}
          federationName={federationName}
          federationProfiles={federationProfiles}
          onSelected={(profile) => {
            setIsOpen(false);
            setSearchTerm('');
            onClear();
            setHasSearched(false);
            onSelected(profile);
          }}
        />
      );
    }
    if (
      hasSearched &&
      organisationProfiles.length === 0 &&
      (!federationProfiles || federationProfiles.length === 0)
    ) {
      return (
        <NonIdealState
          icon="search"
          title={t('Not Found')}
          description={t(
            "Sorry we weren't able to find any profiles for that search term."
          )}
        />
      );
    }
    if (!allowed) {
      return (
        <NonIdealState
          icon="lock"
          title={t('Permission Denied')}
          description={t(
            'Searching requires permission to read profiles, please speak with your organisation administrator.'
          )}
        />
      );
    }
    return (
      <NonIdealState title={t('Start typing to search your organisation')} />
    );
  };

  const onSearchHandler = useDebounce((searchTerm) => {
    if (searchTerm === '') {
      onClear();
      setHasSearched(false);
    } else if (searchTerm && searchTerm.length >= 2) {
      setHasSearched(true);
      onSearchTermChange(searchTerm);
    }
  }, SAVE_DEBOUNCE_TIME);

  return (
    <>
      {children({ openDrawer: () => setIsOpen(true) })}
      <Drawer
        autoFocus
        canEscapeKeyClose
        canOutsideClickClose
        isOpen={isOpen}
        hasBackdrop={false}
        size={size}
        onClose={() => {
          setIsOpen(false);
          setSearchTerm('');
          onClear();
          setHasSearched(false);
        }}
      >
        <div className="m-4">
          <InputGroup
            large
            autoFocus
            placeholder={t('Search...')}
            leftIcon="search"
            rightElement={
              hasSearched && (
                <Button
                  disabled={!hasSearched}
                  icon="cross"
                  intent="none"
                  minimal={true}
                  onClick={() => {
                    setSearchTerm('');
                    onClear();
                    setHasSearched(false);
                  }}
                />
              )
            }
            disabled={!allowed}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              onSearchHandler(e.target.value);
            }}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                organisationProfiles &&
                organisationProfiles.length >= 1
              ) {
                onSelected(organisationProfiles[0]);
              }
            }}
          />
        </div>
        {renderContent()}
      </Drawer>
    </>
  );
};

export default SearchDrawer;
