import { convertValue } from '@hogwarts/scheme-profiles';
import { Column, ColumnGroup } from '@hogwarts/ui-components-grid';
import { Scheme } from '@hogwarts/utils-schemes';
import { addProfileTypeField, addRatingField, addTagField } from './addCols';
import { getAllColumnsForScheme } from './getColumnsFromScheme';

export const getAllColumns = (
  scheme: Scheme,
  options = {
    defaultSortField: 'firstname',
    ignoreReadOnly: false,
  }
): [ColumnGroup[], Column[]] => {
  const { ratingSystems } = scheme;

  const [schemeGroups, schemeFields] = getAllColumnsForScheme(scheme, {
    defaultSortField: options.defaultSortField,
    ignoreReadOnly: options.ignoreReadOnly,
    // @ts-ignore
    valueSetter: ({ colDef: { meta }, data: profile, oldValue, newValue }) => {
      if (newValue === oldValue) {
        return false;
      }
      let converted = convertValue(meta.type, newValue, {
        timezone: 'utc',
      });
      if (!profile.data) {
        // this occurs if the row has a data error
        // we need to make these rows readonly!
        return;
      }
      profile.data[meta.key] = converted;
      return true;
    },
    cellRendererSelector: ({ context: field }) => {
      if (field.reasons?.length) {
        return {
          component: 'warningRenderer',
          params: {
            reasons: field.reasons,
          },
        };
      }
      return;
    },
  });

  return [
    [
      {
        key: '__ratings',
        label: 'Ratings',
      },
      ...schemeGroups,
    ],
    [
      {
        ...addTagField(),
        key: 'tags',
        label: 'Tags',
        group: '__ratings',
      },

      ...(ratingSystems &&
        ratingSystems.map((r) => ({
          key: `rating_${r.key}`,
          group: '__ratings',
          label: r.label,

          ...addRatingField(r.key),
        }))),

      {
        key: 'typeKey',
        label: 'Profile Type',
        group: 'profile',
        ...addProfileTypeField(scheme),
      },

      ...schemeFields,
    ],
  ];
};
