import React, { useMemo } from 'react';
import RatingSystemsTab from '../../../components/SchemeEditor/Tabs/RatingSystems';
import { useRatingSystemEdit } from '../../../components/SchemeEditor/RatingSystemEditor';
import { sortByOrder } from '../utils';

const RatingSystemsContainer = ({
  currentScheme,
  visibilityOptions,
  selectedRatingSystemKey,
  onRatingSystemReorder,
  onRatingSystemSelected,
  onRatingSystemUpdated,
}) => {
  const orderedRatingSystems = useMemo(() => {
    if (currentScheme.ratingSystems) {
      return currentScheme.ratingSystems
        .filter((rs) => visibilityOptions.showDeleted || !rs.deleted)
        .filter((rs) => visibilityOptions.showUnused || rs.inUse)
        .filter((rs) => visibilityOptions.showParentOwned || rs.meta.owned)
        .sort(sortByOrder);
    }
    return [];
  }, [
    currentScheme.ratingSystems,
    visibilityOptions.showDeleted,
    visibilityOptions.showUnused,
    visibilityOptions.showParentOwned,
  ]);
  const selectedRatingSystem = useMemo(() => {
    let result;
    if (selectedRatingSystemKey) {
      result = currentScheme.getRatingSystem(selectedRatingSystemKey);
    }
    return result;
  }, [currentScheme, selectedRatingSystemKey]);

  const [, showEditForm] = useRatingSystemEdit(orderedRatingSystems);

  return (
    <RatingSystemsTab
      ratingSystems={orderedRatingSystems}
      selectedRatingSystem={selectedRatingSystem}
      onRatingSystemSelected={onRatingSystemSelected}
      onRatingSystemOrderUpdate={onRatingSystemReorder}
      onRatingSystemRequestEdit={() => {
        return showEditForm(selectedRatingSystem, onRatingSystemUpdated);
      }}
    />
  );
};

export default RatingSystemsContainer;
