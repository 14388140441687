import { registerComponent } from '@hogwarts/ui-components-forms';

import article from './components/CustomFormComponents/article';
import conditional from './components/CustomFormComponents/conditional';
import conditional2 from './components/CustomFormComponents/conditional2';
import fieldMap from './components/CustomFormComponents/fieldMap';
import fieldselect from './components/CustomFormComponents/fieldselect';
import iconPicker from './components/CustomFormComponents/IconPicker';
import riskassessmentnotify from './components/CustomFormComponents/riskassessmentnotify';
import sectionVisibility from './components/CustomFormComponents/sectionVisibility';
import validationRules from './components/CustomFormComponents/validationRules';
import visibilityAndRatings from './components/CustomFormComponents/visibilityAndRatings';

export default () => {
  registerComponent('riskassessmentnotify', riskassessmentnotify);
  registerComponent('conditional', conditional);
  registerComponent('conditional2', conditional2);
  registerComponent('iconpicker', iconPicker);
  registerComponent('fieldselect', fieldselect);
  registerComponent('validationRules', validationRules);
  registerComponent('visibilityAndRatings', visibilityAndRatings);
  registerComponent('sectionVisibility', sectionVisibility);
  registerComponent('fieldMap', fieldMap);
  registerComponent('article', article);
};
