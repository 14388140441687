import { Card, Checkbox, NonIdealState } from '@blueprintjs/core';
import React, { useMemo, useState } from 'react';

interface FieldSelectionProps {
  sections: {
    key: string;
    label: string;
    fields: {
      key: string;
      label: string;
      visible: boolean;
      locked: boolean;
    }[];
  }[];
  onChange: (changes: Record<string, boolean>) => void;
  emptyText: string;
}
export const FieldSelection = ({
  sections,
  onChange,
  emptyText,
}: FieldSelectionProps) => {
  const initialState = useMemo(() => {
    const initialState: Record<string, boolean> = {};
    for (const section of sections) {
      for (const field of section.fields) {
        const { key, visible } = field;
        initialState[key] = visible;
      }
    }
    return initialState;
  }, [sections]);
  const [changes, setChanges] = useState<Record<string, boolean>>({});
  const state = { ...initialState, ...changes };

  if (!sections?.length) {
    return <NonIdealState icon="zoom-out" title={emptyText} />;
  }

  return (
    <>
      {sections &&
        sections.map(({ fields, ...section }) => {
          return (
            <div key={section.key}>
              <Card>
                <h5>{section.label}</h5>
                <div>
                  {fields.map(({ locked, ...field }) => {
                    const visible = state[field.key];
                    return (
                      <div key={field.key} className={'row no-gutters'}>
                        <div className={'col'}>{field.label}</div>
                        <div className={'col-auto'}>
                          <Checkbox
                            large
                            disabled={locked}
                            checked={visible}
                            indeterminate={visible == null}
                            onChange={(event) => {
                              const checked = !!event.currentTarget.checked;
                              const updatedChanges = {
                                ...changes,
                                [field.key]: checked,
                              };
                              if (
                                updatedChanges[field.key] ===
                                initialState[field.key]
                              ) {
                                delete updatedChanges[field.key];
                              }
                              setChanges(updatedChanges);
                              if (onChange) {
                                onChange(updatedChanges);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </div>
          );
        })}
    </>
  );
};
