import React from 'react';
import styles from './styles.module.css';

interface WidgetLibraryItemProps {
  name: string;
  description: string;
  onClick: () => void;
}
const WidgetLibraryItem = ({
  name,
  description,
  onClick,
}: WidgetLibraryItemProps) => {
  return (
    <div className={styles.libraryItem} onClick={onClick}>
      <p className={styles.libraryItemHeading}>{name}</p>
      <p className={styles.libraryItemDescription}>{description}</p>
    </div>
  );
};

export default WidgetLibraryItem;
