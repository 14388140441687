import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const DashboardLayout = ({ children, onAddWidget }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.dashboardListContainer}>
      <div className={styles.dashboardLayoutContainer}>
        {!children || children.length === 0 ? (
          <div className={styles.blankDashboardContainer}>
            <NonIdealState
              icon="new-object"
              title={t('Blank Dashboard')}
              description={t(
                'Your dashboard is waiting for you to add your first widget'
              )}
            />
          </div>
        ) : (
          children
        )}
      </div>
    </ul>
  );
};

export default DashboardLayout;
