import { Callout, Card } from '@blueprintjs/core';
import { Tag } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ProfileTypeIcon from '../../ProfileTypeIcon';
import ProfileTypePicker from '../../ProfileTypePicker';
import ProfilePicture from '../ProfilePicture';
import Score from '../Score';
import styles from './styles.module.css';

const UserProfileOverview = ({
  name,
  image,
  fields,
  tags,
  scores,
  profileType,
  profileTypeAvatar,
  availableProfileTypes,
  organisationName,
  allowEdit,
  parentDragActive,
  transferRequest,
  onProfilePictureUpload,
  onChangeProfileType,
  onEditProfilePicture,
  onDeleteProfilePicture,
  avatarIconPack,
}) => {
  const { t } = useTranslation();
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      if (allowEdit) {
        onProfilePictureUpload({ files });
      }
    },
  });

  return (
    <div
      {...getRootProps({
        className: cn(styles.overviewCardContainer, `overview-dropzone`),
      })}
    >
      <Card
        elevation={1}
        className={cn(styles.overview, {
          [styles.dropzoneActive]: isDragActive && allowEdit,
        })}
      >
        <div className={styles.overviewContainer}>
          <ProfileTypePicker
            profileTypes={availableProfileTypes}
            position="bottom-left"
            onSelected={onChangeProfileType}
            iconPack={avatarIconPack}
          >
            {({ openMenu }) => (
              <div
                className={styles.profileTypeDetails}
                onClick={
                  allowEdit && availableProfileTypes?.length ? openMenu : null
                }
              >
                <ProfileTypeIcon
                  avatarKey={profileTypeAvatar}
                  iconPack={avatarIconPack}
                  width="40px"
                />
                <div className="flex-grow-1 ml-2">
                  <p className={styles.profileType}>{profileType}</p>
                  <p className={styles.organisationName}>{organisationName}</p>
                </div>
              </div>
            )}
          </ProfileTypePicker>
          <>
            <ProfilePicture
              onEdit={onEditProfilePicture}
              allowEdit={allowEdit}
              image={image}
              isDragActive={parentDragActive}
              onDelete={onDeleteProfilePicture}
            />

            <p className={styles.profileName}>{name}</p>
            <div className={styles.tags}>
              {tags &&
                tags.map((tag) => (
                  <Tag key={`tag-${tag.key || tag.label}`} {...tag} />
                ))}
            </div>
            <table>
              <tbody>
                {fields.map((field, idx) => (
                  <tr className={styles.field} key={field.key}>
                    <td className={styles.fieldName}>{field.name}</td>
                    <td className={styles.fieldValue}>{field.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!!transferRequest && (
              <Callout intent="warning" icon="exchange">
                {t('Transfer to {{targetOrg}} in progress.', {
                  targetOrg: transferRequest.targetOrganisation.name,
                })}
              </Callout>
            )}
          </>
        </div>
        {(!parentDragActive || !allowEdit) && (
          <div>
            {scores.map((score, idx) => (
              <Score key={idx} {...score} />
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

UserProfileOverview.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      preview: PropTypes.bool,
    })
  ),
  scores: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  onDeleteProfilePicture: PropTypes.func.isRequired,
};

export default UserProfileOverview;
