import React from 'react';

import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import { DropdownList } from 'react-widgets';

export const Filters = ({ profileTypes, ratingSystems }) => {
  const { t } = useTranslation();

  let [selectedProfileType = null, setProfileType] = useQueryParam(
    'profileType',
    StringParam
  );
  let [selectedRatingSystem = null, setRatingSystem] = useQueryParam(
    'ratingSystem',
    StringParam
  );

  return (
    <div className="row">
      <div className="d-flex col-md-6 col-lg-5">
        <div className="d-flex pr-2 align-items-center justify-content-center">{`${t(
          'Filter by Profile Type'
        )}:`}</div>
        <div className="flex-grow-1">
          <DropdownList
            valueField={'key'}
            textField={'name'}
            value={selectedProfileType}
            data={[
              {
                id: null,
                key: null,
                name: t('All'),
              },
              ...profileTypes.map((o) => ({
                id: o.id,
                key: o.key,
                name: t(o.label),
              })),
            ]}
            onChange={(profileType) => {
              setProfileType(profileType.key);
            }}
          />
        </div>
      </div>
      <div className="d-flex col-md-6 col-lg-5">
        <div className="d-flex pr-2 align-items-center justify-content-center">{`${t(
          'Filter by Rating System'
        )}:`}</div>
        <div className="flex-grow-1">
          <DropdownList
            valueField={'key'}
            textField={'name'}
            value={selectedRatingSystem}
            data={[
              {
                id: null,
                key: null,
                name: t('All'),
              },
              ...ratingSystems.map((o) => ({
                id: o.id,
                key: o.key,
                name: t(o.label),
              })),
            ]}
            onChange={(ratingSystem) => {
              setRatingSystem(ratingSystem.key);
            }}
          />
        </div>
      </div>
    </div>
  );
};
