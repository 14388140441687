import { Dialogs } from '@/components';
import { AnalyticsContext, OrganisationContext, UserContext } from '@/context';
import { useMutation, usePermission, useQuery } from '@/hooks';
import { COPY_PROFILE_TO_ORGANISATION_MUTATION } from '@/mutations';
import { GET_PROFILETYPE_EXISTS, GET_USER_ORGANISATIONS } from '@/queries';
import { AppToaster } from '@/utils/toaster';
import { useApolloClient } from '@apollo/client';
import permissions from '@hogwarts/permissions';
import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const CopyProfileContainer = ({
  children,
  profile: { id: profileId, typeKey: profileTypeKey, name: profileName },
}) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const organisation = useContext(OrganisationContext);
  const user = useContext(UserContext);
  const apolloClient = useApolloClient();

  const { loading, error, data } = useQuery(GET_USER_ORGANISATIONS);
  const [isOpen, setIsOpen] = useState(false);
  const [targetOrg, setTargetOrg] = useState(null);

  const [copyProfile] = useMutation(COPY_PROFILE_TO_ORGANISATION_MUTATION, {
    selector: 'copyProfileToOrganisation',
  });

  const canReadTargetOrgProfiles = usePermission(
    permissions.PROFILE_READ,
    targetOrg?.id
  );

  const onCopy = async () => {
    const profileTypeExists = get(
      await apolloClient.query({
        query: GET_PROFILETYPE_EXISTS,
        fetchPolicy: 'no-cache',
        variables: {
          organisationId: targetOrg.id,
          profileTypeKey,
        },
      }),
      'data.profileTypeExists'
    );

    if (!profileTypeExists) {
      analytics.events.profile.profileTypeDoesNotExistOnTarget();

      AppToaster.show({
        message: t(
          'Cannot copy when the Profile Type does not exist on the Target Organisation'
        ),
        intent: 'danger',
        icon: 'cross',
        timeout: 0,
      });
      return;
    }

    const profile = await copyProfile({
      variables: {
        profileId,
        organisationId: targetOrg.id,
      },
    });
    if (profile.data) {
      analytics.events.profile.copySuccessful();
      AppToaster.show({
        message: t('{{ profileName }} copied successfully!', {
          profileName,
        }),
        intent: 'success',
        icon: 'tick',
      });
      if (canReadTargetOrgProfiles) {
        history.push(`/${targetOrg.key}/profiles/${profile.data.id}`);
      }
    } else {
      AppToaster.show({
        message: t('Error copying profile!'),
        intent: 'danger',
        icon: 'cross',
      });
    }
  };

  let mappedOrganisations;
  if (data && data.organisations) {
    mappedOrganisations = data.organisations
      .map((org) => {
        const mappedOrg = {
          id: org.id,
          key: org.key,
          name: org.name,
        };
        if (org.key === organisation.key) {
          mappedOrg.disabled = true;
          mappedOrg.label = t('Current Organisation');
        }

        if (!user.hasPermission(permissions.PROFILE_CREATE, org.id)) {
          mappedOrg.disabled = true;
          mappedOrg.label = t('Requires Permission');
        }
        return mappedOrg;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <>
      {children({ openDialog: () => setIsOpen(true) })}
      <Dialogs.CopyProfile
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        targetOrg={targetOrg}
        onTargetOrgSelected={(target) => setTargetOrg(target)}
        onCopy={onCopy}
        loading={loading}
        error={error}
        profileName={profileName}
        organisations={mappedOrganisations}
      />
    </>
  );
};

export default CopyProfileContainer;
