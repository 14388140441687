import { NonIdealState } from '@blueprintjs/core';
import { Button, Currency, Error, Loading } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import { sortBy } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsPanel } from '../../../components/Settings/SettingsPanel';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import { useDateFormatter, useQuery } from '../../../hooks';
import { useDownloadPolicy } from '../../../hooks/useDownloadPolicy';
import {
  GET_CHECK_INVOICES,
  GET_CHECK_INVOICES_Response,
} from '../../../queries';
import { useGetCheckAccountQuery } from '../CheckAccount';
import styles from './styles.module.css';

export interface CheckInvoicesProps {
  checkInvoices: {
    id: string;
    date: string;
    reference?: string;
    externalCode?: string;
    externalId?: string;
    externalLink?: string;
    summaryDocument?: {
      id: string;
      filestackHandle: string;
    };
    price: {
      value: number;
      currency: string;
    };
  }[];
  onShowExternalInvoice: (url: string) => void;
  onShowSummaryReport: (documentId: string, filestackHandle: string) => void;
}
export const CheckInvoicesComponent = ({
  checkInvoices,
  onShowExternalInvoice,
  onShowSummaryReport,
}: CheckInvoicesProps) => {
  const formatDate = useDateFormatter();

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <table className={cn('table table-striped m-0', styles.invoiceTable)}>
        <thead>
          <tr>
            <th style={{ whiteSpace: 'nowrap' }}>{t('Invoice Date')}</th>
            <th style={{ whiteSpace: 'nowrap' }}>{t('Number')}</th>
            <th style={{ whiteSpace: 'nowrap' }}>{t('Reference')}</th>
            <th style={{ whiteSpace: 'nowrap' }}>{t('Amount (ex VAT)')}</th>
            <th style={{ whiteSpace: 'nowrap' }}>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {checkInvoices.map((invoice) => {
            return (
              <tr key={`invoice-${invoice.id}`}>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {formatDate.medium(invoice.date)}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{invoice.externalCode}</td>
                <td style={{ width: '99%' }}>{invoice.reference || ''}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <Currency {...invoice.price} />
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {invoice.externalLink && (
                    <Button
                      icon={['far', 'file-invoice']}
                      onClick={() =>
                        onShowExternalInvoice(invoice.externalLink!)
                      }
                    >
                      {t('View Invoice')}
                    </Button>
                  )}
                  {invoice.summaryDocument && (
                    <Button
                      icon={['far', 'file-lines']}
                      onClick={() => {
                        return onShowSummaryReport(
                          invoice.summaryDocument!.id,
                          invoice.summaryDocument!.filestackHandle
                        );
                      }}
                    >
                      {t('Open Report')}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CheckInvoicesContainer = () => {
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const getDownloadPolicy = useDownloadPolicy();
  const { data: checkAccount } = useGetCheckAccountQuery();

  const {
    loading,
    data: checkInvoices,
    error,
  } = useQuery(GET_CHECK_INVOICES, {
    variables: {
      organisationKey: organisation.key,
    },
    selector: 'organisations[0].checkInvoices',
    transform(checkInvoices: GET_CHECK_INVOICES_Response[]) {
      return sortBy(checkInvoices, 'orderedAt').reverse();
    },
  });

  if (!checkAccount?.checkAccount) {
    return null;
  }

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!checkInvoices?.length) {
    return (
      <SettingsPanel title="Invoices" fixedHeight="500px">
        <NonIdealState icon="zoom-out" title="No Invoices" />
      </SettingsPanel>
    );
  }

  return (
    <SettingsPanel title="Invoices" fixedHeight="500px">
      <CheckInvoicesComponent
        checkInvoices={checkInvoices}
        onShowExternalInvoice={(url) => {
          window.open(url, '_blank');
        }}
        onShowSummaryReport={async (documentId, filestackHandle) => {
          analytics.events.checks.openSummaryReportClicked();
          if (documentId) {
            const securityPolicy = await getDownloadPolicy(documentId);
            window.open(
              `https://cdn.filestackcontent.com/${filestackHandle}?policy=${securityPolicy.policy}&signature=${securityPolicy.signature}`,
              '_blank'
            );
          }
        }}
      />
    </SettingsPanel>
  );
};

export default CheckInvoicesContainer;
