import { gql } from '@apollo/client';

export const TOGGLE_FEATURE = gql`
  mutation toggleFeature(
    $key: String!
    $ownerType: String!
    $ownerId: String!
    $enabled: Boolean!
  ) {
    toggleFeature(
      key: $key
      ownerType: $ownerType
      ownerId: $ownerId
      enabled: $enabled
    ) {
      id
      cacheKey
      label
      enabled
    }
  }
`;
