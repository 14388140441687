import { Card, NonIdealState, Spinner } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import permissions from '@hogwarts/permissions';
import { useFormState } from '@hogwarts/ui-components-forms';
import { capitalize } from '@hogwarts/utils';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTimelineFilters } from '../../../containers/userProfile/utils';
import { TimelineFilter } from '../../../containers/userProfile/utils/timelineFilters';
import { AnalyticsContext, OrganisationContext } from '../../../context';
import {
  useFeature,
  useMutation,
  usePermission,
  useQuery,
} from '../../../hooks';
import { DELETE_ACTIVITY, UPDATE_ACTIVITY } from '../../../mutations';
import {
  DATASOURCE_QUERY_ACTIVITIES,
  GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
} from '../../../queries';
import { ActivityMetadata } from '../../../types';
import { UploadOrganisationDocument } from '../../DocumentUpload';
import { Activity, ActivityMeta } from '../Activity';
import UpdateIncidentForm from '../Activity/UpdateIncidentForm';
import Timeline from './Timeline';

export const UserProfileTimeline = ({ profileId }: { profileId: string }) => {
  const { t } = useTranslation();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const hasTimelinePermission = usePermission(
    permissions.PROFILE_ACTIVITY_READ,
    organisation.id
  );
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>();
  const { showForm: showUpdateActivityForm } = useFormState();
  const isTimelineEnabled = useFeature('timeline.incident_tracking');
  const UpdateIncidentFormWithUpload =
    UploadOrganisationDocument(UpdateIncidentForm);

  const { data, loading, error } = useQuery(DATASOURCE_QUERY_ACTIVITIES, {
    variables: {
      profileId: profileId,
      organisationKey: organisation.key,
    },
    skip: !profileId,
  });

  const { data: activityMeta } = useQuery(
    GET_ORGANISATION_ACTIVITY_ATTRIBUTES,
    {
      selector: 'organisations[0]',
      variables: {
        organisationKey: organisation.key,
        skip: 0,
        limit: 0,
      },
    }
  );

  const [editActivity] = useMutation(UPDATE_ACTIVITY, {
    refetchQueries: [
      {
        query: DATASOURCE_QUERY_ACTIVITIES,
        variables: {
          profileId: profileId,
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const [removeActivity] = useMutation(DELETE_ACTIVITY, {
    refetchQueries: [
      {
        query: DATASOURCE_QUERY_ACTIVITIES,
        variables: {
          profileId: profileId,
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const activities: Activity[] = data?.queryActivities?.result;

  const filterOptions = useMemo(() => {
    const filters: TimelineFilter = {
      activityTypes: [],
      assignedTo: [],
      tags: [],
      categories: [],
      severities: [],
      statuses: [],
    };

    if (activityMeta) {
      const { tags, categories, severities, statuses } = activityMeta;

      const addToFilter = (
        items: ActivityMetadata[],
        filter: keyof TimelineFilter
      ) => {
        if (items) {
          items.forEach((item) => {
            filters[filter].push({
              value: item.name,
              id: item.id,
            });
          });
        }
      };

      addToFilter(tags, 'tags');
      addToFilter(categories, 'categories');
      addToFilter(severities, 'severities');
      addToFilter(statuses, 'statuses');

      if (activities) {
        activities.forEach((activity) => {
          if (
            !filters.activityTypes.some((filter) => filter.id === activity.type)
          ) {
            filters.activityTypes.push({
              value: capitalize(activity.type),
              id: activity.type,
            });
          }
          const assignedTo = activity.data.assignedTo;
          if (assignedTo) {
            assignedTo.forEach((item) => {
              if (!filters.assignedTo.some((filter) => filter.id === item.id)) {
                filters.assignedTo.push({
                  value: item.name,
                  id: item.id,
                });
              }
            });
          }
        });
      }
      return filters;
    }
  }, [activityMeta, activities]);

  const filteredActivities = useMemo(() => {
    let filtered = activities || [];
    const activeFilters = currentFilters || {};

    for (const key in activeFilters) {
      const filterValue = activeFilters[key];

      if (filterValue) {
        if (typeof filterValue === 'object') {
          filtered = filtered.filter((activity) => {
            const topLevelArray = (
              activity[key as keyof Activity] as Array<any>
            )?.map((item) => item.id || item);
            const detailsArray = (
              activity.data[key as keyof Activity['data']] as Array<any>
            )?.map((item) => item.id || item);

            return Object.values(filterValue).some(
              (filter: any) =>
                (Array.isArray(topLevelArray) &&
                  topLevelArray.includes(filter)) ||
                (Array.isArray(detailsArray) && detailsArray.includes(filter))
            );
          });
        }

        if (key === 'dateRange') {
          if (filterValue[0]) {
            const startDate = new Date(filterValue[0]).setHours(0, 0, 0, 0);

            if (filterValue[1]) {
              const endDate = new Date(filterValue[1]).setHours(0, 0, 0, 0);

              filtered = activities.filter((activity) => {
                const date = new Date(activity.date).setHours(0, 0, 0, 0);
                return (
                  new Date(activity.date).setHours(0, 0, 0, 0) >= startDate &&
                  date <= endDate
                );
              });
            } else {
              filtered = activities.filter(
                (activity) =>
                  new Date(activity.date).setHours(0, 0, 0, 0) >= startDate
              );
            }
          }
        }

        if (typeof filterValue === 'string') {
          filtered = filtered.filter((activity) => {
            const dataValue = activity.data[key as keyof Activity['data']];
            const activityValue = activity[key as keyof Activity];

            return (
              (
                (typeof dataValue === 'object'
                  ? (dataValue as ActivityMeta).id
                  : dataValue) || activityValue
              )
                ?.toString()
                .replace(/\s/g, '')
                .toLowerCase() === filterValue
            );
          });
        }
      }
    }

    return filtered;
  }, [currentFilters, activities]);

  if (loading) {
    return (
      <Card>
        <Spinner className="my-5" />
      </Card>
    );
  }

  if (error || !activities) {
    return (
      <Card>
        <NonIdealState
          icon={
            <FontAwesomeIcon color="red" icon={['far', 'exclamation-circle']} />
          }
          title={t('Error Loading Activities')}
          description={t<string>(
            'Try reloading the page, or contact us at help@scrtracker.com if the problem persists.'
          )}
        />
      </Card>
    );
  }

  return isTimelineEnabled ? (
    <>
      <UpdateIncidentFormWithUpload editActivity={editActivity} />
      <Timeline
        activities={filteredActivities}
        allowTimeline={hasTimelinePermission}
        removeActivity={removeActivity}
        editActivity={editActivity}
        timelineFilters={getTimelineFilters(filterOptions!)}
        onFilter={(filters: any) => {
          setCurrentFilters(filters);
          analytics.events.profile.activitiesFiltered({ filters });
        }}
        showUpdateActivityForm={showUpdateActivityForm}
      />
    </>
  ) : null;
};

export default UserProfileTimeline;
