import log from '@scrtracker/logging';

let trackFn: TrackMethod = () => {
  log.warn('Analytics Track called before initialisation');
};

export type TrackMethod = (
  eventKey: string,
  traits?: any,
  config?: any
) => void;

export const initialise = (newTrackFn: TrackMethod) => {
  trackFn = newTrackFn;
};

const trackEvent = (eventKey: string, traits: any, detailed = false) => {
  if (detailed) {
    // Doesnt send to Intercom
    trackFn(eventKey, traits, {
      integrations: {
        Intercom: false,
      },
    });
  } else {
    // Sends to Intercom
    trackFn(eventKey, traits);
  }
};

export default trackEvent;
