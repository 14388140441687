import { ValueFormatterParams } from '@ag-grid-community/core';
import { parseDate } from '@hogwarts/validation';
import { DateTime } from 'luxon';

export const dateFormatter = (params: ValueFormatterParams): string => {
  let { value } = params;
  if (value == null) return '';
  const parsed = parseDate(value);
  if (parsed.isValid) {
    return parsed.toLocaleString(DateTime.DATE_MED);
  }
  return value;
};

export const booleanFormatter = (params: ValueFormatterParams): string => {
  const { value } = params;
  if (value === true) return 'true';
  if (value === false) return 'false';
  return value;
};
