import { Field, Scheme } from '@hogwarts/utils-schemes';
import { booleanFormatter, dateFormatter } from './formatters';

import { Column } from '@hogwarts/ui-components-grid';
import { DateTime } from 'luxon';
import { applyProfileTemplate } from '@hogwarts/scheme-profiles';
import cellStyles from './cellStyles.module.css';
import { parseDate } from '@hogwarts/validation';
import { stripBlankLines } from '@hogwarts/utils';

const EMPTY_CELL_VALUE = '';

export const ratingGetterFactory = (ratingSystemKey: string) => {
  return function getter({ data }: any) {
    let rating = data?.ratings;
    if (!rating) return null;
    rating = rating.find((r: any) => r.key === ratingSystemKey);
    return rating;
  };
};

export const addSchemeField = (
  fieldKey: string,
  scheme: Scheme,
  dataType?: string
) => {
  const getCellField = (params: any) => {
    if (!params.data) return null;
    let rowScheme = params.data.scheme || scheme;
    const field1 = rowScheme.getField(params.colDef.meta?.key);
    if (
      !field1 ||
      field1.sectionItem?.enabled !== true ||
      field1.enabled !== true
    ) {
      return null;
    }
    return field1;
  };

  const removeValueFormatterFactory = (formatter: any) => {
    return (params: any) => {
      const field1 = getCellField(params);
      if (!field1) {
        return EMPTY_CELL_VALUE;
      }
      if (field1.visible === false) {
        return EMPTY_CELL_VALUE;
      }
      if (formatter) {
        return formatter(params);
      }
      return null;
    };
  };

  let valueFormatter;
  switch (dataType) {
    case 'date': {
      valueFormatter = dateFormatter;
      break;
    }
    case 'boolean': {
      valueFormatter = booleanFormatter;
      break;
    }
    default: {
      break;
    }
  }

  valueFormatter = removeValueFormatterFactory(valueFormatter);

  return {
    sortable: true,
    valueFormatter,
    valueGetter: ({ data: profile }: any) => {
      if (!profile?.data) return null;

      const value = profile.data[fieldKey];

      if (dataType === 'date' && value?.toISODate) {
        return value.toISODate();
      }

      return stripBlankLines(value);
    },
    cellRendererSelector: (params: any) => {
      const field = getCellField(params);
      if (!field) return null;
      if (field.reasons?.length) {
        return {
          component: 'warningRenderer',
          params: {
            reasons: field.reasons,
          },
        };
      }
      return null;
    },
    cellClassRules: {
      [cellStyles.gridFieldDisabled]: (params: any) => {
        return !getCellField(params);
      },
      [cellStyles.gridFieldNotVisible]: (params: any) => {
        const field1 = getCellField(params);
        return field1 && field1.visible === false;
      },
      // [cellStyles.gridFieldReadonly]: (params) => {
      //   if (ignoreReadOnly) return false;
      //   const field1 = getCellField(params);
      //   return field1 && field1.readOnly === true;
      // },
    },
    cellClass: 'scr-grid-cell-divider',
  };
};

type ColumnResult = Omit<Column, 'key' | 'label'>;

export const addTagField = (): ColumnResult => {
  return {
    editable: false,
    valueGetter: ({ data: profile }) => profile?.tags || [],
    cellRenderer: 'tagRenderer',
    cellStyle: {
      lineHeight: '25px',
      padding: '1px',
    },
    lockPosition: true,
    filter: false,
    minWidth: 40,
    width: 110,
    maxWidth: 150,
  };
};

export const addProfileTypeField = (scheme: Scheme): ColumnResult => {
  // const values = scheme.profileTypes.map((p) => ({
  //   id: p.key,
  //   value: p.label,
  // }));
  return {
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    valueGetter: ({ data, colDef }) => {
      const typeKey = data && data[colDef.field!];
      if (!typeKey) return null;
      return scheme.getProfileType(typeKey);
    },
    valueSetter: ({ data: profile, oldValue, newValue }) => {
      if (typeof newValue === 'string') {
        // locate by key or label
        const lookup = scheme.profileTypes.find(
          (p) =>
            p.key.toLowerCase() === newValue ||
            p.label?.toLowerCase() === newValue
        );
        if (!lookup) {
          return false;
        }
        profile.typeKey = lookup.key;
        return true;
      }

      if (newValue.key === oldValue?.key) {
        return false;
      }
      profile.typeKey = newValue.key;
      return true;
    },
    valueFormatter: ({ value: profileType }) => {
      if (!profileType) {
        return 'Unknown';
      }
      return profileType.label;
    },
    meta: {
      formatForSave: (value: any) => {
        return value?.key;
      },
      values: scheme.profileTypes,
    },
    cellEditorParams: {
      values: scheme.profileTypes,
      formatValue: (profileType: any) => {
        return profileType.label;
      },
    },
    filter: false,
  };
};

export const addRatingField = (systemKey: string): ColumnResult => {
  return {
    editable: false,
    valueGetter: ratingGetterFactory(systemKey),
    cellRenderer: 'ratingRenderer',
    width: 100,
    cellStyle: {
      lineHeight: '25px',
      padding: '0px',
    },
  };
};

export const addTemplateField = (
  references: unknown[],
  template: unknown
): ColumnResult => {
  return {
    sortable: false,
    // cellEditor: 'agRichSelectCellEditor',
    valueGetter: ({ data: profile }) => {
      if (references?.length) {
        for (const fieldKey of references) {
          const field = profile.scheme.getField(fieldKey);

          if (!field) {
            console.warn(`Unknown field [${fieldKey}]`);
            return 'N/A'; // 'unknown field';
          }

          const section = profile.scheme.getSection(field.section);
          if (!section || !section.visible) {
            return 'N/A'; // `Section [${field.section}] not vis`;
          }

          if (!field.visible) {
            return 'N/A'; // `field [${fieldKey}] not vis`;
          }
        }
      }

      return stripBlankLines(applyProfileTemplate(template, profile));
    },
    filter: false,
  };
};

const formatValue = (field: Field | null, value: any) => {
  if (Array.isArray(value)) {
    return '';
  }
  if (value == null) {
    return '';
  }
  if (value === '') {
    return '';
  }
  if (field && field.dataType === 'date') {
    const date = parseDate(value);
    if (date.isValid) {
      return date.toLocaleString(DateTime.DATE_MED);
    }
    return value;
  }
  if (value === true) {
    return 'True';
  }
  if (value === false) {
    return 'False';
  }
  if (typeof value === 'string') {
    return value;
  }
  return '#Error';
};

export const addConcatField = (
  fields: string[],
  join: string
): ColumnResult => {
  return {
    sortable: false,
    // cellEditor: 'agRichSelectCellEditor',
    valueGetter: ({ data: profile }) => {
      if (!fields?.length) {
        return '';
      }

      const result = [];

      for (const fieldKey of fields) {
        const field = profile.scheme.getField(fieldKey);
        if (!field) {
          continue;
        }

        const section = profile.scheme.getSection(field.section);
        if (!section || !section.visible) {
          continue;
        }

        if (!field.visible) {
          continue;
        }

        const value = formatValue(field, profile.data?.[fieldKey]);

        result.push(value);
      }

      const text = result
        .filter((r) => {
          if (typeof r === 'string') {
            if (r.trim() === '') {
              return false;
            }
            return true;
          }
          return r != null;
        })
        .join(join || '\n');

      return stripBlankLines(text);
    },
    filter: false,
  };
};
